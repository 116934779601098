import { Trans, useTranslation } from 'react-i18next'
import cx from 'classnames'

interface PrivacyPolicyConstantsProps {}

const PrivacyPolicyConstants: React.FC<PrivacyPolicyConstantsProps> = () => {
  const [t] = useTranslation('translation')

  function getTrans(text: string){
    return (

    <Trans 
    i18nKey={text}
    components={{
      br: <br />,
    }}
    />
    )
  }
  const getGoogleURL = () => {
    const currentLang = localStorage.getItem('i18nextLng')
    if (currentLang === 'en') return 'http://www.google.com/intl/en/policies/privacy/'
    return 'http://www.google.com/intl/ja/policies/privacy/'
  }


  return (
    <div className="policy distance-line-to-content distance-content-to-button">
    <h2 className="policy__ttle">{getTrans(t('policy.privacy_policy.sub_title'))}</h2>
    <div className="policy__lead">
        <p>{getTrans(t('policy.privacy_policy.sub_title_1', { abc: `"the Company") will handiveinformation about users (hereinafter referred to as "User Information") onthe website, products, and service "AVATARIUM" (hereinafter referred toas "the Service")` }))}</p>
            </div>
            
            <div className="policy__sec">
                <h3 className="policy__label">{getTrans(t('policy.privacy_policy.describe.article_1.title'))}</h3>
                <div className="policy__content">
                    <ol className="policy__list--num">
            <li>{getTrans(t(`policy.privacy_policy.describe.article_1._1`))}</li>
            <li>{getTrans(t(`policy.privacy_policy.describe.article_1._2`, { abc: `"AVATARIUM"`, xyz: `"Policy"` }))}</li>
            <li>{getTrans(t(`policy.privacy_policy.describe.article_1._3`, { abc: `"Partner Services"`, xyz: `"External Services"` }))}</li>
                    </ol>
                </div>
            </div>
            
            
            <div className="policy__sec">
                <h3 className="policy__label">{getTrans(t('policy.privacy_policy.describe.article_2.title'))}</h3>
                <div className="policy__content">
                    <ol className="policy__list--num">
            <li>
            {getTrans(t(`policy.privacy_policy.describe.article_2._1`))}
            <ol className="policy__list--num-parentheses">
                <li>
                {getTrans(t(`policy.privacy_policy.describe.article_2._1_1`))}
                <ol
                    className={cx('policy__list--katakana', {
                    list_style_katakana2: localStorage.getItem('i18nextLng') === 'en',
                    })}
                >
                    <li>
                    {getTrans(t(`policy.privacy_policy.describe.article_2._1_1_1`))}
                    <p>{getTrans(t(`policy.privacy_policy.describe.article_2._1_1_1_1`, { abc: `"User Information"` }))}</p>
                    </li>
                    <li>
                    {getTrans(t(`policy.privacy_policy.describe.article_2._1_1_2`))}
                    <p>{getTrans(t(`policy.privacy_policy.describe.article_2._1_1_2_1`, { abc: `"Log Information"` }))}</p>
                    </li>
                    <li>
                    {getTrans(t(`policy.privacy_policy.describe.article_2._1_1_3`))}
                    <p>{getTrans(t(`policy.privacy_policy.describe.article_2._1_1_3_1`, { abc: `"cookies"` }))}</p>
                    </li>
                    <li>
                    {getTrans(t(`policy.privacy_policy.describe.article_2._1_1_4`))}
                    <p>{getTrans(t(`policy.privacy_policy.describe.article_2._1_1_4_1`))}</p>
                    </li>
                    <li>
                    {getTrans(t(`policy.privacy_policy.describe.article_2._1_1_5`))}
                    <p>{getTrans(t(`policy.privacy_policy.describe.article_2._1_1_5_1`, { abc: `"Location Information"` }))}</p>
                    </li>
                </ol>
                </li>
                <li>
                {getTrans(t(`policy.privacy_policy.describe.article_2._1_2`))}
                <p>{getTrans(t(`policy.privacy_policy.describe.article_2._1_2_1`, { abc: `"Transmission Data"`, xyz: `"Linked Data"` }))}</p>
                </li>
            </ol>
            </li>
            <li>{getTrans(t(`policy.privacy_policy.describe.article_2._2`))}</li>
                    </ol>
                </div>
            </div>
            
            
            <div className="policy__sec">
                <h3 className="policy__label">{getTrans(t('policy.privacy_policy.describe.article_3.title'))}</h3>
                <div className="policy__content">
                    <ol className="policy__list--num">
                        <li>{getTrans(t(`policy.privacy_policy.describe.article_3._1`))}</li>
                    </ol>
                    <div className="policy__flame">
                <table className="policy__table">
                    <thead>
                        <tr>
                        <th>{getTrans(t('policy.privacy_policy.table.thread_col_1'))}</th>
                        <th>{getTrans(t('policy.privacy_policy.table.thread_col_2'))}</th>
                        <th>{getTrans(t('policy.privacy_policy.table.thread_col_3'))}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="whitespace">{getTrans(t('policy.privacy_policy.table.row_1_col_1'))}</td>
                        <td>{getTrans(t('policy.privacy_policy.table.row_1_col_2'))}</td>
                        <td>{getTrans(t('policy.privacy_policy.table.row_1_col_3'))}</td>
                        </tr>
                        <tr>
                        <td className="whitespace">{getTrans(t('policy.privacy_policy.table.row_2_col_1'))}</td>
                        <td>{getTrans(t('policy.privacy_policy.table.row_2_col_2'))}</td>
                        <td>{getTrans(t('policy.privacy_policy.table.row_2_col_3'))}</td>
                        </tr>
                        <tr>
                        <td className="whitespace">{getTrans(t('policy.privacy_policy.table.row_3_col_1'))}</td>
                        <td>{getTrans(t('policy.privacy_policy.table.row_3_col_2'))}</td>
                        <td>{getTrans(t('policy.privacy_policy.table.row_3_col_3'))}</td>
                        </tr>
                    </tbody>
                </table>
                    </div>
        <ol className="policy__list--num start">
            <li>{getTrans(t(`policy.privacy_policy.describe.article_3._2`))}</li>
            <li>{getTrans(t(`policy.privacy_policy.describe.article_3._3`, { abc: `"anonymously processed information" ` }))}</li>
        </ol>
                </div>
            </div>
            
            <div className="policy__sec">
                <h3 className="policy__label">{getTrans(t('policy.privacy_policy.describe.article_4.title'))}</h3>
                <div className="policy__content">
        <ol className="policy__list--num">
            <li>
            {getTrans(t(`policy.privacy_policy.describe.article_4._1`))}
            <ol className="policy__list--num-parentheses">
                <li>{getTrans(t(`policy.privacy_policy.describe.article_4._1_1`))}</li>
                <li>{getTrans(t(`policy.privacy_policy.describe.article_4._1_2`))}</li>
                <li>{getTrans(t(`policy.privacy_policy.describe.article_4._1_3`))}</li>
                <li>{getTrans(t(`policy.privacy_policy.describe.article_4._1_4`))}</li>
                <li>{getTrans(t(`policy.privacy_policy.describe.article_4._1_5`))}</li>
            </ol>
            </li>
            <li>
            {getTrans(t(`policy.privacy_policy.describe.article_4._2`))}
            <ol className="policy__list--num-parentheses">
                <li>{getTrans(t(`policy.privacy_policy.describe.article_4._2_1`))}</li>
                <li>{getTrans(t(`policy.privacy_policy.describe.article_4._2_2`))}</li>
                <li>{getTrans(t(`policy.privacy_policy.describe.article_4._2_3`))}</li>
                <li>{getTrans(t(`policy.privacy_policy.describe.article_4._2_4`))}</li>
            </ol>
            </li>
        </ol>
                </div>
            </div>
            
            
            <div className="policy__sec">
                <h3 className="policy__label">{getTrans(t('policy.privacy_policy.describe.article_5.title'))}</h3>
                <div className="policy__content">
                    <p>{getTrans(t('policy.privacy_policy.describe.article_5.des'))}</p>
                </div>
            </div>

            <div className="policy__sec">
                <h3 className="policy__label">{getTrans(t('policy.privacy_policy.describe.article_6.title'))}</h3>
                <div className="policy__content">
                    <p>{getTrans(t('policy.privacy_policy.describe.article_6.des'))}</p>
                </div>
            </div>

            <div className="policy__sec">
                <h3 className="policy__label">{getTrans(t('policy.privacy_policy.describe.article_7.title'))}</h3>
                <div className="policy__content">
                    <p>{getTrans(t('policy.privacy_policy.describe.article_7.des'))}</p>
                    <p>
                        {getTrans(t('policy.privacy_policy.describe.article_7.link'))}{' '}
            <a className="text-link" href={getGoogleURL()} target="_blank" rel="noopener noreferrer" >
            {getGoogleURL()}
            </a>
        </p>
                </div>
            </div>


            <div className="policy__sec">
                <h3 className="policy__label">{getTrans(t('policy.privacy_policy.describe.article_8.title'))}</h3>
                <div className="policy__content">
        <ol className="policy__list--num">
            <li>
            {getTrans(t(`policy.privacy_policy.describe.article_8._1`))}{' '}
            <ol className="policy__list--num-parentheses">
                <li>{getTrans(t(`policy.privacy_policy.describe.article_8._1_1`))}</li>
                <li>
                {getTrans(t(`policy.privacy_policy.describe.article_8._1_2`))}{' '}
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.privacy_policy.describe.article_8._1_2_1`, { abc: `"ad-serving businesses"`, xyz: `"ad-serving companies"` }))}</li>
                    <li>{getTrans(t(`policy.privacy_policy.describe.article_8._1_2_2`))}</li>
                </ol>
                </li>
            </ol>
            </li>
        </ol>
                </div>
            </div>
            

            <div className="policy__sec">
                <h3 className="policy__label">{getTrans(t('policy.privacy_policy.describe.article_9.title'))}</h3>
                <div className="policy__content">
        <ol className="policy__list--num">
            <li>{getTrans(t(`policy.privacy_policy.describe.article_9._1`))}</li>
            <li>{getTrans(t(`policy.privacy_policy.describe.article_9._2`))}</li>
        </ol>
                </div>
            </div>

            <div className="policy__sec">
                <h3 className="policy__label">{getTrans(t('policy.privacy_policy.describe.article_10.title'))}</h3>
                <div className="policy__content">
                    <p>
                        {getTrans(t('policy.privacy_policy.describe.article_10.des'))}
                    </p>
                </div>
            </div>

            <div className="policy__sec">
                <h3 className="policy__label">{getTrans(t('policy.privacy_policy.describe.article_11.title'))}</h3>
                <div className="policy__content">
        <ol className="policy__list--num">
            <li>{getTrans(t(`policy.privacy_policy.describe.article_11._1`))}</li>
            <li>{getTrans(t(`policy.privacy_policy.describe.article_11._2`))}</li>
        </ol>
                </div>
            </div>
            
            <div className="policy__sec">
                <h3 className="policy__label">{getTrans(t('policy.privacy_policy.describe.article_12.title'))}</h3>
                <div className="policy__content">
                    <p>{getTrans(t('policy.privacy_policy.describe.article_12.des_1'))}</p>
                    <p>
                        {getTrans(t('policy.privacy_policy.describe.article_12.des_2'))}<br/>
                        Email&nbsp;<a className="text-link" href="mailto:avatarium_support@pocket-rd.com">avatarium_support@pocket-rd.com</a><br/>
                        {getTrans(t('policy.privacy_policy.describe.article_12.sub_link'))}
                    </p>
                </div>
            </div>
    </div>
  )
}
export default PrivacyPolicyConstants
