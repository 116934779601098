import { SwitchLanguages } from '../../modules/switch-languages'

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="content__footer">
      <SwitchLanguages position="footer" />
    </div>
  )
}

export default Footer
