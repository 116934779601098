import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconHair4: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67 44.4486C62.7365 40.2988 62.4396 37.4238 62.0481 31.6584C60.955 14.6306 46.1938 9.73521 37.1222 8.50218C33.7203 8.05038 28.3084 7.52922 25.9411 8.78367C25.9411 8.78367 28.1695 10.1789 27.9662 11.0213C26.2056 11.067 24.4784 11.5184 22.9156 12.341C19.3688 14.2528 16.3349 17.0112 14.0762 20.3776C11.8175 23.7441 10.402 27.6173 9.95195 31.6625C9.2474 37.4176 9.26351 40.3029 5 44.4527C7.41558 46.0845 11.1396 44.7893 11.1396 44.7893C10.7934 48.8382 7.51623 53.1635 7.51623 53.1635C13.3096 55.6724 17.0034 53.0829 17.0034 53.0829C17.1508 55.0751 16.8389 57.0747 16.0926 58.9237C18.1959 58.9437 20.2742 58.4609 22.1587 57.5142C27.9481 65.1143 31.9177 62.9838 35.7756 62.9838H36.2214C40.0793 62.9838 44.0449 65.1164 49.8343 57.5091C51.7169 58.4611 53.7965 58.9443 55.9004 58.9186C55.1653 57.0739 54.8618 55.0817 55.0137 53.0982C55.0137 53.0982 58.7075 55.6775 64.5009 53.1788C64.5009 53.1788 61.2278 48.8331 60.8775 44.7842C60.8604 44.7842 64.5844 46.0794 67 44.4486Z"
        fill="#111947"
      />
      <path
        d="M55.0495 33.7103C53.5639 21.4626 46.3242 14.6387 36.228 14.6387C26.1319 14.6387 18.9365 21.4718 17.4066 33.7103C13.0636 33.9061 11.9102 37.0218 11.9102 41.0758C11.9102 45.1298 16.3669 49.7713 17.8485 49.9824C18.4624 50.0772 19.0019 50.1384 19.5424 50.1864C23.0732 58.2893 29.7533 63.9995 35.2296 63.9995H37.2798C42.7763 63.9995 49.4363 58.2882 52.9519 50.1864C53.4927 50.1556 54.0308 50.0875 54.5623 49.9824C56.0479 49.7713 60.5006 45.3165 60.5006 41.0758C60.5006 36.8352 59.3824 33.9061 55.0495 33.7103Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M30.0774 44.4482C31.7316 44.4482 33.0727 43.0893 33.0727 41.4131C33.0727 39.7368 31.7316 38.3779 30.0774 38.3779C28.4231 38.3779 27.082 39.7368 27.082 41.4131C27.082 43.0893 28.4231 44.4482 30.0774 44.4482Z"
        fill="#111947"
      />
      <path
        d="M43.2024 44.4482C44.8566 44.4482 46.1977 43.0893 46.1977 41.4131C46.1977 39.7368 44.8566 38.3779 43.2024 38.3779C41.5481 38.3779 40.207 39.7368 40.207 41.4131C40.207 43.0893 41.5481 44.4482 43.2024 44.4482Z"
        fill="#111947"
      />
      <path
        d="M54.9067 32.7416C54.9067 32.7416 44.4241 34.814 37.0354 26.2145C37.0354 26.2145 37.4068 32.1899 36.2181 34.3632C30.7468 33.7207 20.7634 29.3659 22.3285 17.2487C22.9968 11.875 28.9492 11.463 35.3858 11.5375C46.1905 11.6721 58.2281 19.7005 54.9067 32.7416Z"
        fill="#111947"
      />
    </svg>
  )
}

export default IconHair4
