import SwitchLanguagesTop from '../../modules/switch-languages-top'

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="menuMobiles-content__footer">
      <SwitchLanguagesTop />
    </div>
  )
}

export default Footer
