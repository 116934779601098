import React from 'react'

interface AAndTProps {
  height?: string
  width?: string
}

const AAndT: React.FC<AAndTProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="AT" width={width || "176"} height={height || "170"} viewBox="0 0 176 170">
      <defs>
        <style>{'.cls-1{fill:#12c4c4}'}</style>
      </defs>
      <path id="Path_772" d="M15.041 23.485h-1.079l21.451 51.8H0l1 1h35.909z" className="cls-1" />
      <path id="Path_773" d="M46.7 99.886H0v1h46.027L52.434 116h1.081z" className="cls-1" />
      <path id="Path_774" d="M85.953 116H87L34.732 0h-1.095z" className="cls-1" />
      <path id="Path_775" d="M101 47h74v25.4h-43.939V170h1V73.4H176V46h-75z" className="cls-1" />
    </svg>
  )
}

export default AAndT
