import React from 'react'

interface CameraIconProps {
  height?: string
  width?: string
}

const CameraIcon: React.FC<CameraIconProps> = ({ height, width }) => {
  return (
    <svg
      id="photo_camera_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path id="Path_178" data-name="Path 178" d="M0,0H16V16H0Z" fill="none" />
      <g id="Group_298" data-name="Group 298" transform="translate(1.333 2)">
        <circle
          id="Ellipse_21"
          data-name="Ellipse 21"
          cx="2.133"
          cy="2.133"
          r="2.133"
          transform="translate(4.533 4.533)"
          fill="#fff"
        />
        <path
          id="Path_179"
          data-name="Path 179"
          d="M6.667,2,5.447,3.333H3.333A1.337,1.337,0,0,0,2,4.667v8A1.337,1.337,0,0,0,3.333,14H14a1.337,1.337,0,0,0,1.333-1.333v-8A1.337,1.337,0,0,0,14,3.333H11.887L10.667,2Zm2,10A3.333,3.333,0,1,1,12,8.667,3.335,3.335,0,0,1,8.667,12Z"
          transform="translate(-2 -2)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default CameraIcon
