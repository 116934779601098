import React from 'react'

interface PlayingHoopProps {
  height?: string
  width?: string
}

const PlayingHoop: React.FC<PlayingHoopProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="72" viewBox="0 0 80 72">
      <g id="Group_10886" data-name="Group 10886" transform="translate(-560 -1978.699)">
        <g id="Group_10885" data-name="Group 10885">
          <path
            id="Path_2016"
            data-name="Path 2016"
            d="M629.094,2006.3a128.473,128.473,0,0,0-21.884-2.864,10.52,10.52,0,1,0-14.418,0,128.719,128.719,0,0,0-21.91,2.867,4.605,4.605,0,0,0-3.451,5.431l.038.175a4.61,4.61,0,0,0,5.5,3.384,110.364,110.364,0,0,1,14.784-2.245v7.8c-12.219.977-12.224,2.856-12.224,3.676,0,2.166,5.984,3.2,12.224,3.694v14.811h3v-14.613c3.757.2,7.269.237,9.243.237s5.486-.035,9.243-.237v14.613h3v-14.811c6.239-.492,12.223-1.528,12.223-3.694,0-.82-.005-2.7-12.223-3.676v-7.8a110.388,110.388,0,0,1,14.772,2.242,4.611,4.611,0,0,0,5.519-3.391l.012-.05,0-.016,0-.012A4.632,4.632,0,0,0,629.094,2006.3Zm-36.614-10.5a7.52,7.52,0,1,1,7.52,7.521A7.528,7.528,0,0,1,592.48,1995.8Zm-14.563,28.742c1.037-.453,3.83-1.185,9.84-1.679v3.337C582.612,2025.772,579.189,2025.123,577.917,2024.537Zm44.165,0c-1.272.586-4.695,1.234-9.839,1.658v-3.337C618.252,2023.352,621.045,2024.084,622.082,2024.537Zm7.547-13.388,0,.011a1.613,1.613,0,0,1-1.947,1.206,113.3,113.3,0,0,0-16.834-2.431l-1.6-.111V2026.4c-2.728.157-5.814.252-9.243.252s-6.515-.1-9.243-.252v-16.578l-1.6.111a113.286,113.286,0,0,0-16.846,2.434,1.622,1.622,0,0,1-1.218-.2,1.6,1.6,0,0,1-.717-1.006v-.005a1.614,1.614,0,0,1,1.177-1.925,125.848,125.848,0,0,1,28.431-2.913h.038a125.666,125.666,0,0,1,28.407,2.908A1.636,1.636,0,0,1,629.629,2011.149Z"
            fill="#12c4c4"
          />
          <rect
            id="Rectangle_3552"
            data-name="Rectangle 3552"
            width="2"
            height="5.832"
            transform="translate(599 2037.199)"
            fill="#12c4c4"
          />
        </g>
        <g id="長方形_3523" data-name="長方形 3523">
          <rect
            id="Rectangle_3553"
            data-name="Rectangle 3553"
            width="80"
            height="72"
            transform="translate(560 1978.699)"
            fill="none"
          />
        </g>
      </g>
    </svg>
  )
}

export default PlayingHoop
