import React from 'react'

interface JapaneseFlagProps {
  height?: string
  width?: string
}

const JapaneseIcon: React.FC<JapaneseFlagProps> = ({ height, width }) => {
  return (
    <svg
    width={width || "18"}
    height={height || "18"}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 18A9 9 0 109 0a9 9 0 000 18z" fill="#F0F0F0" />
    <path d="M9 13a4 4 0 100-8 4 4 0 000 8z" fill="#D80027" />
  </svg>
  )
}

export default JapaneseIcon;