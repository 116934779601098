import firebase from 'firebase/app'
import 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyA3EFrwyr3G5Uuj7vQ54jU5i9kQZuHQF7A',
  authDomain: 'avatar-7a68c.firebaseapp.com',
  projectId: 'avatar-7a68c',
  storageBucket: 'avatar-7a68c.appspot.com',
  messagingSenderId: '177601444916',
  appId: '1:177601444916:web:76c01237a74e78f9ddde61',
}

firebase.initializeApp(firebaseConfig)
let messaging: any = null
if (firebase.messaging.isSupported()) messaging = firebase.messaging()
export { messaging }
export const getFirebaseToken = async () => {
  return messaging
    .getToken({
      vapidKey:
        'BLkCFBA6KjL-HsdqGd2fSfPdxtCADqR-A62TR5kflIJlGBCxB-1mQ7MPCqRthNlL3NGQIY7ZRlsfpdLIl1ix6RY',
    })
    .then((deviceToken: any) => {
      if (deviceToken) {
        return new Promise((resolve) => {
          resolve(deviceToken)
        })
      }
      return new Promise((reject) => {
        reject('No registration token available. Request permission to generate one.')
      })
    })
    .catch((err: any) => {
      return new Promise((reject) => {
        reject(`An error occurred while retrieving token. ${err}`)
      })
    })
}

export const onMessageListener = () => {
  return new Promise((resolve) => {
    messaging.onMessage((payload: any) => {
      resolve(payload)
    })
  })
}
