import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Logo, defaultAvatar, CloseIcon2 } from '../../../../../assets/images'
import { MAX_AVATAR_COUNT } from '../../../../../utils/const'
import { useCallback, useRef, useEffect, useState} from 'react'
import { GET_PROFILE_IMAGE } from '../../../../../utils/endpoints'
import axios from '../../../../../utils/axios'

interface HeaderProps {
  toggleMenu?: () => void
}

const Header: React.FC<HeaderProps> = ({ toggleMenu }) => {
  const [t] = useTranslation('translation')
  const userData = useSelector((state: any) => state.signin.userData)
  const headerRef: any = useRef()
  const [profileImage, setProfileImage] = useState('')

  useEffect(() => {
    const wheel = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
    try {
      axios.get(GET_PROFILE_IMAGE, {responseType: 'blob'}).then(res => {
        setProfileImage(URL.createObjectURL(res?.data))
      })
    } catch (error) {
    }
    headerRef?.current?.addEventListener(wheel, preventDefault, false)
  }, [headerRef])

  const preventDefault = useCallback((e) => {
    e.preventDefault()
  }, [])

  return (
    <div className="content__header" ref={headerRef}>
      <div className="content__header--close-icon">
        <div className="content__header--close-icon_logo">
          <Logo />
        </div>
        <div onClick={toggleMenu} style={{ marginRight: '2rem' }}>
          <CloseIcon2 />
        </div>
      </div>
      <div className="content__header--top">
        <img
          className="content__header--img"
          style={{ cursor: 'pointer' }}
          src={profileImage || defaultAvatar}
          alt="" />
        <div className="content__header--top__information">
          <div className="content__header--username">{userData?.name}</div>
        </div>
      </div>
      <div className="content__header--bottom">
        <span className="content__header--bottom__item">
          {t('menu.avatar')}
          <span className="content__header--bottom__item--qty">{userData?.avatar_count} / {MAX_AVATAR_COUNT}</span>
        </span>
      </div>
    </div>
  )
}
export default Header
