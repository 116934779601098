import React from 'react'

interface FourDotsIconMediumProps {
  height?: string
  width?: string
}

const FourDotsIconMedium: React.FC<FourDotsIconMediumProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g id="icon_4dots" transform="translate(-542 -953)">
        <circle
          id="Ellipse_10"
          data-name="Ellipse 10"
          cx="5"
          cy="5"
          r="5"
          transform="translate(542 953)"
          fill="#12c4c4"
        />
        <circle
          id="Ellipse_13"
          data-name="Ellipse 13"
          cx="5"
          cy="5"
          r="5"
          transform="translate(562 973)"
          fill="#12c4c4"
        />
        <circle
          id="Ellipse_11"
          data-name="Ellipse 11"
          cx="5"
          cy="5"
          r="5"
          transform="translate(562 953)"
          fill="#ff6c6c"
        />
        <circle
          id="Ellipse_12"
          data-name="Ellipse 12"
          cx="5"
          cy="5"
          r="5"
          transform="translate(542 973)"
          fill="#ff6c6c"
        />
      </g>
    </svg>
  )
}

export default FourDotsIconMedium
