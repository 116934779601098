import React from 'react'

interface CalendarCancelProps {
  height?: string
  width?: string
  color?: string
}

const CalendarCancel: React.FC<CalendarCancelProps> = ({ height, width }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.36494 4H16.2437C16.6938 4 17.1254 4.18262 17.4437 4.50768C17.7619 4.83274 17.9407 5.27362 17.9407 5.73333V11.8693C16.6641 11.6503 15.3537 11.9353 14.2752 12.6667H11.1528V16.1333H12.0692C11.9758 16.7227 11.9758 17.3033 12.0692 17.8667H4.36494C3.91487 17.8667 3.48324 17.684 3.165 17.359C2.84676 17.0339 2.66797 16.593 2.66797 16.1333V5.73333C2.66797 4.78 3.43161 4 4.36494 4ZM4.36494 7.46667V10.9333H9.45585V7.46667H4.36494ZM11.1528 7.46667V10.9333H16.2437V7.46667H11.1528ZM4.36494 12.6667V16.1333H9.45585V12.6667H4.36494ZM17.5165 13.5333C15.3952 13.5333 13.6983 15.2667 13.6983 17.4333C13.6983 19.6 15.3952 21.3333 17.5165 21.3333C19.6377 21.3333 21.3346 19.6 21.3346 17.4333C21.3346 15.2667 19.6377 13.5333 17.5165 13.5333ZM17.5165 20.0333C16.108 20.0333 14.971 18.872 14.971 17.4333C14.971 16.948 15.0983 16.4973 15.3274 16.1333L18.7892 19.6693C18.4328 19.9033 17.9916 20.0333 17.5165 20.0333ZM19.7055 18.7333L16.2437 15.1973C16.6001 14.9633 17.0413 14.8333 17.5165 14.8333C18.9249 14.8333 20.0619 15.9947 20.0619 17.4333C20.0619 17.9187 19.9346 18.3693 19.7055 18.7333Z"
        fill="#9496AB"
      />
    </svg>
  )
}

export default CalendarCancel
