import * as React from 'react';

export default class MenuDropDown extends React.PureComponent<any, {}> {
    render() {
        const { width, height } = this.props;
        return (
            <svg width={width || "11"} height={height || "7"} viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.79289 5.79289L0.707107 1.70711C0.0771419 1.07714 0.523309 0 1.41421 0H9.58579C10.4767 0 10.9229 1.07714 10.2929 1.70711L6.20711 5.79289C5.81658 6.18342 5.18342 6.18342 4.79289 5.79289Z" fill="white" />
            </svg>
        );
    }
}