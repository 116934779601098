import * as React from 'react'

interface DropdownIconUpProps {
  height?: string
  width?: string
}
const DropdownIconUp: React.FC<DropdownIconUpProps> = ({ height, width }) => {
  return (
    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 8.75L9 1.25L1.5 8.75"
        stroke="white"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DropdownIconUp
