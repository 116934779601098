import * as React from 'react';

interface WithDrawIconProps {
   height?: string;
   width?: string;
};
const WithDrawIcon: React.FC<WithDrawIconProps> = ({ height, width }) => {
   return (
      <svg width={width || "22"} height={height || "28"} viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M20.4286 0H1.57143C1.15466 0 0.754961 0.1475 0.460261 0.41005C0.165561 0.672601 0 1.0287 0 1.4V14L7.85714 8.4V12.6H17.2857V15.4H7.85714V19.6L0 14V26.6C0 26.9713 0.165561 27.3274 0.460261 27.59C0.754961 27.8525 1.15466 28 1.57143 28H20.4286C20.8453 28 21.245 27.8525 21.5397 27.59C21.8344 27.3274 22 26.9713 22 26.6V1.4C22 1.0287 21.8344 0.672601 21.5397 0.41005C21.245 0.1475 20.8453 0 20.4286 0Z" fill="#9496AB"/>
      </svg>
   );
};

export default WithDrawIcon