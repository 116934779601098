import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/atoms/buttons'
import { CANCEL_BOOKING } from '../../../../utils/endpoints'
import { useDispatch } from 'react-redux'
import { TabTypes } from '../../../stores/actions/checkTab'
import { HISTORY_BOOKING } from '../../../../utils/endpoints'
import { CloseIcon } from '../../../../assets/images'
import { ERROR_TYPE, POST } from '../../../../utils/const'

interface DeleteBookingModalProps {
  selected: any
  closeModal: (arg?: boolean) => void
  onInit: (arg?: boolean) => void
  items: any[]
  callApi: any
  notify: any
}

const DeleteBookingModal: React.FC<DeleteBookingModalProps> = ({
  selected,
  closeModal,
  items,
  onInit,
  callApi,
  notify,
}) => {
  const [t] = useTranslation('translation')
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const date = items.filter((item) => {
    return item.reservation_id === selected
  })
  const cancelBooking = async (id: any) => {
    try {
      setLoading(true)
      const params = {
        reservation_id: id,
      }
      const result = await callApi(CANCEL_BOOKING, POST, params)
      if (result) {
        const fetchHistoryAfter = async () => {
          try {
            const result = await callApi(HISTORY_BOOKING)
            if (result) {
              onInit()
            }
          } catch (error) {
          }
        }
        fetchHistoryAfter()

        dispatch({ type: TabTypes.CHECK_TAB, payload: 2 })
      }
    } catch (error) {
      const { message } = error || {}
      if (message) notify(message, ERROR_TYPE)
    }
    setLoading(false)
    closeModal()
  }
  const renderDeteleItem = date.map((item) => {
    return (
      <>
        <button
          className="booking__delete-modal-booking__close close-icon"
          onClick={() => closeModal(false)}
        >
          <CloseIcon />
        </button>
        <div className="booking__delete-modal-booking__header">
          <div className="booking__delete-modal-booking__title pop-text-bold-big">
            {t('delete_booking_modal.Do you want to cancel booking')}
          </div>
        </div>
        <div className="booking__delete-modal-booking__content">
          <div className="booking__delete-modal-booking__confirm-info">
            <p className="pop-big-body-text">
              {t('delete_booking_modal.Shooting date and time')}
              {item.shooting_time}
            </p>
            <p className="pop-big-body-text">
              {t('delete_booking_modal.Address')}
              {item.scanner_name}, {item.address}
            </p>
          </div>
          <div className="booking__delete-modal-booking__send-noti padding-modal-content-to-line padding-modal-content-to-btn">
            <p className="pop-large-body-text">
              {t(
                'delete_booking_modal.After you cancel booking, we will sent you a confirm email.'
              )}
            </p>
            <p className="pop-large-body-text">{t('delete_booking_modal.Please check content.')}</p>
          </div>
          <div className="booking__delete-modal-booking__resolved-buttons">
            <Button
              disabled={!!loading}
              className="cancel shortnormal"
              onClick={() => closeModal()}
            >
              {t('delete_booking_modal.No')}
            </Button>
            <Button
              className="shortnormal"
              onClick={() => (!!loading ? null : cancelBooking(selected))}
            >
              {t('delete_booking_modal.Yes')}
            </Button>
          </div>
        </div>
      </>
    )
  })
  return <>{renderDeteleItem}</>
}

export default DeleteBookingModal
