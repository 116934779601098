import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AvatarIcon, AvatarIconSort } from '../../../../assets/images/svgs'
import { editOptionStyles } from './constants'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import AvatarIconReverseSort from '../../../../assets/images/svgs/avatar-icon-reverse-sort'
import { GET_LIST_AVATAR } from '../../../../utils/endpoints'
import Wrapper from '../../components/modules/_wrapper'
import AvatarDetailComponent from '../../components/modules/control-avatar/detail'
import { EDITED, MOBILE, ORIGINAL, ROUTER_URL, SCANNER } from '../../../../utils/const'
import axios from '../../../../utils/axios'

interface AvatarIconProps {
  setIsLoading: any
}
const ControlAvatar: React.FC<AvatarIconProps> = ({ setIsLoading }) => {

  const title = 'アバター管理 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'アバター管理のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
  }
  
  const [t] = useTranslation('translation')

  const [isReversedSort, setIsReversedSort] = useState(false)
  const [avatarInformations, setAvatarInformations] = useState<any>(
    {'avatar_list': [], 'message': t(''), 'avatar_type': '', 'model_type': ''}
  )

  const avatar_types = [
    { value: '', name: 'sort', label: t('control-avatar.value_default') },
    { value: ORIGINAL, name: 'sort', label: t('control-avatar.value_input1') },
    { value: EDITED, name: 'sort', label: t('control-avatar.value_input2') },
  ]
  const model_types = [
    { value: '', name: 'sort', label: t('control-avatar.value_default') },
    { value: SCANNER, name: 'sort', label: t('control-avatar.value_scanner') },
    { value: MOBILE, name: 'sort', label: t('control-avatar.value_mobile') },
  ]

  const sortListAvatar = () => {
    setIsReversedSort((prevState) => !prevState)
  }

  const getListAvatar = async (value: {[type: string]: string}) => {
    setIsLoading(true)
    let avatar_list: any = []
    let message = ''
    let avatar_type = value['avatar']
    let model_type = value['model']

    setAvatarInformations({
      'avatar_list': avatar_list,
      'message': t(message),
      'avatar_type': avatar_type,
      'model_type': model_type
    })
    const params = {
      page: 1,
      per_page: 100,
      avatar_type: avatar_type,
      model_type: model_type
    }
    try {
      const result = await axios.get(GET_LIST_AVATAR, { params })
      if (result) {
        avatar_list = result.data.data.list_avatar
        for (let i=0; i < avatar_list.length; i++) {
          await axios.get(avatar_list[i]['avatar_path'], {responseType: 'blob',}).then(res => {
            avatar_list[i]['image_avatar'] = URL.createObjectURL(res?.data)
            }
          )
        }
        if (avatar_list.length === 0) {
          message = 'detail-avatar.empty_avatar'
        }
      }
    } catch (error) {
    } finally {
      setAvatarInformations({
        'avatar_list': avatar_list,
        'message': t(message),
        'avatar_type': avatar_type,
        'model_type': model_type
      })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getListAvatar({'avatar': '', 'model': ''})
  }, [localStorage.getItem('i18nextLng')])

  const onChangeAvatarType = (e: any) => {
    getListAvatar({'avatar': e?.value, 'model': avatarInformations['model_type']})
  }
  

  const onChangeModelType = (e: any) => {
    getListAvatar({'avatar': avatarInformations['avatar_type'], 'model': e?.value})
  }

  const renderSelectAvatarTypeFields = (
    <div className="input_sort">
      <Select
        name="sort"
        placeholder={t('control-avatar.placeholder_input')}
        options={avatar_types}
        styles={editOptionStyles()}
        isSearchable={false}
        onChange={onChangeAvatarType}
        value={avatar_types.find((o) => o.value === avatarInformations['avatar_type']) || avatar_types[0]}
      />
    </div>
  )

  const renderSelectModelTypeFields = (
    <div className="input_sort">
      <Select
        name="sort"
        placeholder={t('control-avatar.placeholder_input')}
        options={model_types}
        styles={editOptionStyles()}
        isSearchable={false}
        onChange={onChangeModelType}
        value={model_types.find((o) => o.value === avatarInformations['model_type']) || model_types[0]}
      />
    </div>
  )

  const list = [
    {
      name: t('control-avatar.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('control-avatar.bread_crumb_active'),
      link: ROUTER_URL.CONTROL_AVATAR,
    },
  ]
  return (
    <Wrapper
      Icon={AvatarIcon}
      title={t('control-avatar.title_header')}
      breadcrum={list}
      className="control-avatar"
    >
      <div className="wrap-sort distance-pulldown-to-image">
        <div className="wrap-sort-relative distance-pulldown-to-image">
          <div className="left">    
            {renderSelectModelTypeFields}
          </div>
          <div className="right">
            <div className="right-relative">
              <div className="wrap-sort_icon" onClick={sortListAvatar}>
                {isReversedSort ? <AvatarIconReverseSort /> : <AvatarIconSort />}
              </div>
            </div>
            <div className="right-relative">
              {renderSelectAvatarTypeFields}
            </div>
          </div>
        </div>
      </div>
      {avatarInformations["avatar_list"].length === 0 ? (
        <div style={{ textAlign: 'center', marginTop: '5rem', display: 'block', color: '#111947' }}>
          {avatarInformations["message"]}
        </div>
      ) : (
        ''
      )}
      <div className="control-avatar_content">
        {[...avatarInformations["avatar_list"]]
          ?.sort((a: any, b: any) =>
            !isReversedSort
              ? a.avatar_name.localeCompare(b.avatar_name)
              : b.avatar_name.localeCompare(a.avatar_name)
          )
          .map(({ avatar_id, avatar_path, avatar_date, avatar_name, avatar_update_id, model_type, image_avatar}: any) => (
            <div key={avatar_path}>
              <Link
                to={{
                  pathname: avatar_update_id
                    ? `${ROUTER_URL.CONTROL_AVATAR_EDITED}/${model_type}/${avatar_id}`
                    : `${ROUTER_URL.CONTROL_AVATAR}/${model_type}/${avatar_id}`,
                  state: { type: avatar_update_id, model_type: model_type },
                }}
              >
                <AvatarDetailComponent
                  image={image_avatar}
                  date={avatar_date}
                  content={avatar_name}
                  isExistContent={true}
                />
              </Link>
            </div>
          ))}
      </div>
    </Wrapper>
  )
}

export default ControlAvatar
