import React from 'react'

interface ClockIconProps {
  height?: string
  width?: string
  color?: string
}

const ClockIcon: React.FC<ClockIconProps> = ({ height, width, color = '#ff6c6c' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4525 8.35582L18.5302 7.27854L17.4697 6.21814L16.3168 7.37057C15.2636 6.62449 14.0345 6.16467 12.75 6.03616V4.49934H14.25V3H9.75V4.49934H11.25V6.03616C9.96551 6.16467 8.73644 6.62449 7.68319 7.37057L6.53025 6.21814L5.46975 7.27854L6.5475 8.35582C5.54149 9.4205 4.86948 10.7564 4.61448 12.1985C4.35947 13.6407 4.53263 15.1259 5.11257 16.4707C5.69252 17.8156 6.65384 18.9612 7.87783 19.7661C9.10182 20.5711 10.5349 21 12 21C13.4651 21 14.8982 20.5711 16.1222 19.7661C17.3462 18.9612 18.3075 17.8156 18.8874 16.4707C19.4674 15.1259 19.6405 13.6407 19.3855 12.1985C19.1305 10.7564 18.4585 9.4205 17.4525 8.35582V8.35582ZM12 19.4927C10.8133 19.4927 9.65327 19.141 8.66658 18.482C7.67989 17.823 6.91085 16.8863 6.45673 15.7904C6.0026 14.6946 5.88378 13.4887 6.11529 12.3253C6.3468 11.162 6.91825 10.0933 7.75736 9.25459C8.59648 8.41585 9.66557 7.84466 10.8295 7.61325C11.9933 7.38184 13.1997 7.5006 14.2961 7.95453C15.3925 8.40845 16.3295 9.17715 16.9888 10.1634C17.6481 11.1497 18 12.3092 18 13.4954C17.9982 15.0854 17.3655 16.6098 16.2407 17.7341C15.1158 18.8585 13.5907 19.4909 12 19.4927V19.4927Z"
        fill="#FF6C6C"
      />
      <path
        d="M12 8.99512V13.4931H7.5C7.5 14.3827 7.76392 15.2524 8.25839 15.9921C8.75285 16.7318 9.45566 17.3083 10.2779 17.6487C11.1002 17.9892 12.005 18.0783 12.8779 17.9047C13.7508 17.7312 14.5526 17.3028 15.182 16.6737C15.8113 16.0446 16.2399 15.2432 16.4135 14.3706C16.5872 13.4981 16.498 12.5937 16.1575 11.7718C15.8169 10.9499 15.2401 10.2474 14.5001 9.75317C13.76 9.25892 12.89 8.99512 12 8.99512V8.99512Z"
        fill="#FF6C6C"
      />
    </svg>
  )
}

export default ClockIcon
