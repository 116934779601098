import React from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTER_URL } from '../../../../utils/const'
import Wrapper from "../../components/modules/_wrapper";

interface SigninNoLoginProps {
}

const SigninNoLogin: React.FC<SigninNoLoginProps> = () => {

  const title = 'ログイン | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'ログインのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [t] = useTranslation('translation')


  const list = [
    {},
  ]
  return (
    <Wrapper
      breadcrum={list}
      className="help"
      classNameBtn={'normal'}
    >
      <div className="wrapper__content distance-line-to-content distance-middle">

        <div className="export-complete base_style center_wrap comp_wrap">
          <h2 className="distance-h2-to-text">{t('common.already_login')}</h2>

          <div className="btn_wrap">
            <a className="button-custom" href={ROUTER_URL.SCAN_BOOKING}>{t('common.button_my_page')}</a>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default SigninNoLogin
