export const textFields = [
  {
    name: 'name',
    type: 'text',
    isRequire: true,
    placeholder: 'contact.name_placeholder',
    helpText: false,
    label: 'contact.name_label',
  },
  {
    name: 'email',
    type: 'email',
    isRequire: true,
    placeholder: 'contact.email_placeholder',
    helpText: false,
    label: 'contact.email_label',
  },
]

export const defaultValues = {
  name: '',
  email: '',
  itemRequired: '',
  contentRequired: '',
}
