import { useLocation, useHistory } from 'react-router-dom'
import { ROUTER_URL } from '../../../../../utils/const'

interface PageImageProps {
  children?: any
  history?: any
}

export const PageImage: React.FC<PageImageProps> = () => {
  const history: any = useHistory()
  const location: any = useLocation()
  return (
    <div
      style={{
        display: 'flex',
        minWidth: '100vw',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#0e0e0e',
        padding: '20px 0',
      }}
    >
      <img
        src={
          (process?.env?.REACT_APP_BACKEND_URL) +
          '/avatar/get-thumbnail' +
          location?.search
        }
        alt=""
        onError={() => history.push(ROUTER_URL.HOME)}
      />
    </div>
  )
}

export default PageImage
