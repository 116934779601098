import MoonLoader from 'react-spinners/MoonLoader'
interface ConfirmEmailProps {
  size?: number
}

const Loading: React.FC<ConfirmEmailProps> = ({ size }) => {
  return (
    <div
      className="default-padding"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <MoonLoader size={size || 200} loading={true} color={'#12c4c4'} />
    </div>
  )
}

export default Loading
