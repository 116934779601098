import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { WarningIcon } from '../../../../assets/images/svgs'
import Wrapper from '../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../utils/const'

interface ActiveEmailProps {}

const ActiveEmail: React.FC<ActiveEmailProps> = () => {
  const [, i18n] = useTranslation()
  const history: any = useHistory()
  const location: any = useLocation()

  const list = [
    {
      name: i18n.t('signup.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('signup.breacrum-active-email'),
      link: ROUTER_URL.ACTIVE_EMAIL,
    },
  ]

  return (
    <Wrapper
      breadcrum={list}
      buttonLink={ROUTER_URL.SIGNIN}
      buttonText={i18n.t('active_mail.button_confirm')}
      className="active-mail"
      typeBreadcrums={1}
      isNoMarginTop={false}
    >
      <div className="active-mail__container">
        <div
          className="distance-h2-to-text"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <h2 className="title-h2">{i18n.t('active_email_update.title')}</h2>
        </div>
        <div className="active-mail__content">
          <div className="active-mail__already-sent distance-text-to-noti" id="active-title-email">
            <p className="active-message">
              <Trans
                i18nKey="active_mail.message"
                values={{ email: location?.state?.email }}
                components={{
                  b: <b />,
                  br: <br />,
                  span: <span className="password__describe-content1_link" />,
                }}
              />
            </p>
            <p className="font-weight-bold distance-p-to-p active-message">
              {i18n.t('active_mail.content_active_4')}
            </p>
          </div>
          <div className="password__warning password__warning-noti  distance-line-noti-to-btn">
            <div
              className="password__warning-icon password__warning-icon_noti  padding-title-noti"
              id="active-warning-icon"
            >
              <WarningIcon />
              <div className="text-header-noti distance-h4-to-icon">
                {i18n.t('forgot_password_noti.title_email_err')}
              </div>
            </div>
            <div
              className="password__warning-content password__warning-content_noti padding-content-noti padding-content-confirm-email"
              id="fgPW"
            >
              <p className="password__warning-content_noti-text1">
                <Trans
                  i18nKey="forgot_password_noti.warning_1"
                  components={{
                    br: <br />,
                  }}
                />
              </p>
              <p>
                <Trans
                  i18nKey="forgot_password_noti.warning_2"
                  components={{
                    br: <br />,
                    span: <span className="text-link"  onClick={() => history.push(ROUTER_URL.CONTACT)} />,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default ActiveEmail
