import React from 'react';
interface WarningIconProps {
    width?:number,
    height?:number
}

const WarningIcon: React.FC<WarningIconProps> = ({width , height}) => {
   return (
    <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={10} cy={10} r={9.5} stroke="#9496AB" />
    <circle cx={10} cy={14} r={1} fill="#9496AB" />
    <path
      d="M9.386 12h1.229l.325-4.876L11 5H9l.06 2.124L9.386 12z"
      fill="#9496AB"
    />
  </svg>
   )
}

export default WarningIcon;