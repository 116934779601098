import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { LogoDark, MenuIcon, SignupIconTop, LoginIconTop } from '../../../../../../assets/images'
import { SwitchLanguages } from '../../switch-languages'
import { MenuMobile } from '../../../block/menuMobile'
import { Button } from '../../../atoms/buttons'
import { ROUTER_URL } from '../../../../../../utils/const'

interface HeaderAuthenticationProps {}

const HeaderAuthentication: React.FC<HeaderAuthenticationProps> = () => {
  const [, i18n] = useTranslation('translation')
  const [open, setOpen] = useState(false)
  let location = useLocation()
  let history = useHistory()
  const renderRegisterButton = () => (
    <Button
      onClick={() => history.push({ pathname: ROUTER_URL.SIGNUP, state: { email: 111 } })}
      className="header-auth__register outline auth-button"
    >
      <SignupIconTop />
      <span>{i18n.t('header_top.signup')}</span>
    </Button>
  )

  const toggleMenuMobiles = () => {
    setOpen((open) => !open)
  }

  const renderLoginButton = () => (
    <Button
      onClick={() => history.push(ROUTER_URL.SIGNIN)}
      className="header-auth__login secondary auth-button"
    >
      <LoginIconTop />
      <span>Log In</span>
    </Button>
  )

  const renderGroupAuthButtons = () => {
    switch (location.pathname) {
      default:
        return (
          <React.Fragment>
            {renderRegisterButton()}
            {renderLoginButton()}
          </React.Fragment>
        )
    }
  }

  const renderMenuIcon = (
    <div onClick={toggleMenuMobiles} className="header-auth_menu">
      <MenuIcon />
    </div>
  )
  return (
    <React.Fragment>
      {open ? <div className="offset"></div> : null}
      {!open ? (
        <div className="header-auth">
          <Link to={ROUTER_URL.HOME}>
            <div className="logo">
              <LogoDark />
            </div>
          </Link>
          <div className="header-auth__more-selection">
            <div className="header-auth__select-language">
              <SwitchLanguages />
            </div>
            <div className="header-auth__group-auth-buttons">{renderGroupAuthButtons()}</div>
            {renderMenuIcon}
          </div>
        </div>
      ) : null}
      <div>
        <MenuMobile open={open} toggleMenuMobiles={toggleMenuMobiles} />
      </div>
    </React.Fragment>
  )
}

export default HeaderAuthentication
