import ReactModal from 'react-modal'

interface ModalProps {
  children?: any
  show: boolean
  toggleModal?: () => void
  modalStyles: string
  data?: any
}
export const Modal: React.FC<ModalProps> = ({ children, show, toggleModal, modalStyles }) => {
  return (
    <ReactModal
      isOpen={show}
      contentLabel="onRequestClose Example"
      onRequestClose={toggleModal}
      className={modalStyles}
      shouldCloseOnOverlayClick={true}
      overlayClassName="react-modal-overlay"
      ariaHideApp={false}
      onAfterOpen={() => (document.body.style.overflow = 'hidden')}
      onAfterClose={() => (document.body.style.overflow = 'unset')}
    >
      {children}
    </ReactModal>
  )
}
