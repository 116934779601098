import classnames from 'classnames'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Bell } from '../../../../../../assets/images'
import { NotificationsModal } from '../../modal-notifications'
import { GET_NOTI } from '../../../../../../utils/endpoints'
import { Menu } from '../../../block/menu'
import { SwitchLanguages } from '../../switch-languages'
import ClickOutside from '../../../atoms/clickoutsite/ClickOutside'
import LogoImage from '../../../../../../assets/images/svgs/logo.svg'
import { GET, ROUTER_URL } from '../../../../../../utils/const'
import { onMessageListener } from '../../../../../../utils/firebase'

interface HeaderProps {
  stateNotify?: any
  callApi?: any
  onLogout: any
}

interface StateProperties {
  title: string
  message: string
  notify_time: string
  status: number
}

const Header: React.FC<HeaderProps> = ({ stateNotify, callApi, onLogout }) => {
  const [open, setOpen] = useState(false)
  const [openNoti, setOpenNoti] = useState(false)
  const [notis, setNotis] = useState<StateProperties[]>([])
  const [unread, setUnread] = useState(0)

  const headerRef: any = useRef()
  const logoRef: any = useRef()
  const langRef: any = useRef()
  const notiRef: any = useRef()
  const avatarRef: any = useRef()

  const preventDefault = useCallback((e) => {
    if (e.target === headerRef?.current) {
      e.preventDefault()
    }
  }, [])

  const preventDefault2 = useCallback((e) => {
    e.preventDefault()
  }, [])

  useEffect(() => {
    const wheel = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
    const listRef = [logoRef, langRef, notiRef, avatarRef]

    if (open || openNoti) {
      headerRef?.current?.addEventListener(wheel, preventDefault, false)
      listRef.forEach((ref: any) => ref?.current?.addEventListener(wheel, preventDefault2, false))
    } else {
      headerRef?.current?.removeEventListener(wheel, preventDefault, false)
      listRef.forEach((ref: any) =>
        ref?.current?.removeEventListener(wheel, preventDefault2, false)
      )
    }
  }, [open, openNoti])

  onMessageListener().then((payload: any) => {
    if (payload) {
      getNotisDB()
    }
  })

  function isStateChange() {
    let state = document.visibilityState
    if (state === 'visible') getNotisDB()
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', isStateChange, true)
    return () => document.removeEventListener('visibilitychange', isStateChange, true)
  }, [])

  useEffect(() => {
    getNotisDB()
  }, [stateNotify, localStorage.getItem('i18nextLng')])

  const toggleNoti = () => {
    setOpenNoti((openNoti) => !openNoti)
    setOpen(false)
  }
  const toggleMenu = () => {
    setOpen((open) => !open)
    setOpenNoti(false)
  }

  const hideNotiMenu = () => {
    setOpen(false)
    setOpenNoti(false)
  }

  const getNotisDB = useCallback(async () => {
    const access_token = localStorage.getItem('access_token')
    try {
      if (access_token) {
        const notisDB = await callApi(
          GET_NOTI,
          GET,
          {
            params: {
              page: 1,
              per_page: 100,
            },
          },
          false
        )
        const { list_notify, total_is_not_readed } = notisDB.data.data
        setNotis(list_notify)
        setUnread(total_is_not_readed)
      }
    } catch (error) {
    }
  }, [])

  return (
    <>
      <div className={classnames('header-wrap', { fixed: open })} ref={headerRef}>
        <Link to={ROUTER_URL.HOME}>
          <div className="logo" ref={logoRef}>
            <img src={LogoImage} alt="" />
          </div>
        </Link>
        <div className="account">
          <div className="wrap-status" ref={langRef}>
            <SwitchLanguages position="header" />
          </div>
          <ClickOutside onClickOutside={hideNotiMenu} className="toggleMenu">
            <div className="header-wrap__bell">
              <div onClick={toggleNoti} ref={notiRef}>
                <Bell className="bell" />
                <div className="header-wrap__bell--noti">{unread > 99 ? '99ᐩ' : unread}</div>
              </div>
              <NotificationsModal
                openNoti={openNoti}
                toggleNoti={toggleNoti}
                getNotisDB={getNotisDB}
                callApi={callApi}
                notis={notis}
              />
            </div>
            <div style={{ position: 'relative' }}>
              <div onClick={toggleMenu} className="avatar-top">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30 30">
                      <rect y="25" width="30" height="2"></rect>
                      <rect y="14" width="30" height="2"></rect>
                      <rect y="3" width="30" height="2"></rect>
                  </svg>
              </div>
              <Menu open={open} toggleMenu={toggleMenu} onLogout={onLogout} />
            </div>
          </ClickOutside>
        </div>
      </div>
    </>
  )
}

export default Header
