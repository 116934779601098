import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { WarningIcon } from '../../../../assets/images/svgs'
import { useLocation, useHistory } from 'react-router-dom'
import Wrapper from '../../components/modules/_wrapper'
import { Button } from '../../components/atoms/buttons'
import { ROUTER_URL } from '../../../../utils/const'

interface ForgotPasswordProps {}

const ForgotPasswordNotification: React.FC<ForgotPasswordProps> = () => {

  const title = '確認メールを送付 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = '確認メールを送付のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
  }
  
  const [, i18n] = useTranslation('translation')
  const location: any = useLocation()
  const history: any = useHistory()

  const renderBtnPassword = (
    <Button className="m-auto" onClick={() => history.push(ROUTER_URL.HOME)}>
      {i18n.t('forgot_password_noti.back_to_home')}
    </Button>
  )
  const list = [
    {
      name: i18n.t('forgot_password.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('signin.login'),
      link: ROUTER_URL.SIGNIN,
    },
    {
      name: i18n.t('reset_password.breadcrumb_active_notification'),
      link: ROUTER_URL.FORGOT_PASSWORD_NOTIFICATION,
    },
  ]

  return (
    <Wrapper breadcrum={list} className="password " typeBreadcrums={1} isNoMarginTop={false}>
      <div className="active-mail__container">
        <h2 className="title-h2 distance-h2-to-text">{i18n.t('forgot_password_noti.title')}</h2>
        <div className="password__describe distance-text-to-noti" id="forgot-title">
          <div className="password__describe-content1">
            <p>
              <Trans
                i18nKey="forgot_password_noti.message"
                values={{ email: location?.state?.email }}
                components={{
                  b: <b />,
                  br: <br />,
                  span: <span className="password__describe-content1_link" />,
                }}
              />
            </p>
            <p className="password__describe-content3 distance-p-to-p active-message">
              {i18n.t('forgot_password_noti.detail_2')}
            </p>
          </div>
          <div className="password__describe-content2"></div>
          <div className=" distance-p-to-p"></div>
        </div>
        <div className="password__warning password__warning-noti distance-line-noti-to-btn">
          <div
            className="password__warning-icon password__warning-icon_noti  padding-title-noti"
            id="forgot-warning-icon"
          >
            <WarningIcon />
            <div className="text-header-noti distance-h4-to-icon">
              {i18n.t('forgot_password_noti.title_email_err')}
            </div>
          </div>
          <div
            className="password__warning-content password__warning-content_noti padding-content-noti padding-content-confirm-email"
            id="fgPW"
          >
            <p className="password__warning-content_noti-text1">
              <Trans
                i18nKey="forgot_password_noti.warning_1"
                components={{
                  br: <br />,
                }}
              />
            </p>
            <div className="password__warning-content_noti-text">
              <p>
                <Trans
                  i18nKey="forgot_password_noti.warning_2"
                  components={{
                    br: <br />,
                    span: <span className="text-link"  onClick={() => history.push(ROUTER_URL.CONTACT)} />,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
        {renderBtnPassword}
      </div>
    </Wrapper>
  )
}
export default ForgotPasswordNotification
