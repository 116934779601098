import React from "react";

interface FacebookLogoProps {
  height?: string;
  width?: string;
}

const FacebookLogo: React.FC<FacebookLogoProps> = ({ height, width }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="13" fill="white" />
      <path
        d="M15.1463 26V15.9787H18.4024L18.8902 12.0563H15.1463V9.55808C15.1463 8.42503 15.4495 7.65299 17.0154 7.65299H19V4.15421C18.6547 4.10656 17.4701 4 16.0917 4C13.2136 4 11.2439 5.82205 11.2439 9.16959V12.0563H8V15.9787H11.2439V26H15.1463Z"
        fill="#3975EA"
      />
    </svg>
  );
};

export default FacebookLogo;
