import axios from 'axios';
import { IUser } from "../models";

export class SignFactory {
    public async getAuth(username: string, password: string): Promise<IUser> {
        return new Promise<IUser>((resolve, reject) => {
            axios
                .get("https://jsonplaceholder.typicode.com/todos/1")
                .then(response => {
                    resolve({
                        id: 1,
                        name: 'Tester',
                        username: 'testing',
                        email: 'test@opentechiz.com',
                        token: 'abcdefghijklmnopqxyz'
                    });
                })
                .catch(e => {
                    resolve({
                        id: 1,
                        name: 'Tester',
                        username: 'testing',
                        email: 'test@opentechiz.com',
                        token: 'abcdefghijklmnopqxyz'
                    });
                });
        });
    }
}