import { AnyAction, Reducer } from 'redux';
import { IDisplayMessageState, initialDisplayMessageState } from '../states';
import { SystemTypes } from '../actions';

export const SystemReducer: Reducer = (state: IDisplayMessageState = initialDisplayMessageState, action: AnyAction) => {
    switch (action.type) {
        case SystemTypes.SYSTEM_SHOW_MESSAGE: {
            return {
                ...state,
                message: action.message,
                type: action.msgType,
            };
        }
        case SystemTypes.SYSTEM_HIDE_MESSAGE: {
            return {
                ...state,
                message: '',
            };
        }
        default:
            return state;
    }
};
