import * as React from 'react';

interface MenuIconProps {
   height?: string;
   width?: string;
};
const MenuIconClose: React.FC<MenuIconProps> = ({ height, width }) => {
   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M1 1L23 23" stroke="#12C4C4" strokeWidth="2"/>
         <path d="M1 23L23 0.999999" stroke="#12C4C4" strokeWidth="2"/>
      </svg>
   );
};

export default MenuIconClose
