import React from 'react';

interface EyeHideIconProps {
    height?: string;
    width?: string;
  }
  
  const EyeHideIcon: React.FC<EyeHideIconProps> = ({
    height,
    width,
  }) => {
    return (
      <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#prefix__clip0)" fill="#9496AB">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.983 3.16c-.9-.403-1.9-.66-2.983-.66-3.979 0-6.785 3.407-7.806 4.873a1.103 1.103 0 00.004 1.254c.48.683 1.35 1.789 2.543 2.774l2.324-2.323a3.059 3.059 0 01-.136-1.682c.12-.6.419-1.15.857-1.584a3.143 3.143 0 011.603-.846 3.168 3.168 0 011.666.121l1.928-1.928zm-3.626 7.868a3.151 3.151 0 002.857-.84 3.075 3.075 0 00.843-2.86l2.487-2.487c1.046.918 1.816 1.9 2.258 2.527a1.1 1.1 0 01-.003 1.263C14.757 10.097 11.913 13.5 8 13.5c-.937 0-1.81-.192-2.611-.504l1.968-1.968z"
        />
        <rect
          x={14.848}
          y={0.707}
          width={1}
          height={20}
          rx={0.5}
          transform="rotate(45 14.848 .707)"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
    );
  };

export default EyeHideIcon;