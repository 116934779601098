import * as React from 'react'

interface MailIconProps {
  height?: string
  width?: string
}
const MailIcon: React.FC<MailIconProps> = ({ height, width }) => {
  return (
    <svg width={width || 28} height={height || 28}  viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9021 18.0321C16.6257 18.1822 16.3153 18.2609 15.9998 18.2609C15.6842 18.2609 15.3738 18.1822 15.0975 18.0321L2 10.9102V24.2419C2.00074 24.9736 2.29598 25.6752 2.82092 26.1926C3.34586 26.7101 4.05762 27.0011 4.8 27.0018H27.2C27.9424 27.0011 28.6541 26.7101 29.1791 26.1926C29.704 25.6752 29.9993 24.9736 30 24.2419V10.9102L16.9021 18.0321Z"
        fill="#9496AB"
      />
      <path
        d="M27.2 4H4.8C4.05762 4.00073 3.34586 4.29174 2.82092 4.80916C2.29598 5.32659 2.00074 6.02816 2 6.75991V9.05983C1.99997 9.14213 2.02234 9.22292 2.06477 9.2938C2.10721 9.36467 2.16816 9.42303 2.24127 9.46278L15.7746 16.8225C15.8436 16.8601 15.9212 16.8797 16 16.8797C16.0788 16.8797 16.1564 16.8601 16.2254 16.8225L29.7587 9.46278C29.8318 9.42303 29.8928 9.36467 29.9352 9.2938C29.9777 9.22292 30 9.14213 30 9.05983V6.75991C29.9993 6.02816 29.704 5.32659 29.1791 4.80916C28.6541 4.29174 27.9424 4.00073 27.2 4Z"
        fill="#9496AB"
      />
    </svg>
  )
}

export default MailIcon
