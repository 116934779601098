import * as React from 'react'

interface LockIconProps {
  height?: string
  width?: string
}
const LockIcon: React.FC<LockIconProps> = ({ height, width }) => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40" cy="40" r="40" fill="#F3F3F3" />
      <path
        d="M56 54.6667C56 55.5507 55.6629 56.3986 55.0627 57.0237C54.4626 57.6488 53.6487 58 52.8 58H27.2C26.3513 58 25.5374 57.6488 24.9373 57.0237C24.3371 56.3986 24 55.5507 24 54.6667V38C24 37.1159 24.3371 36.2681 24.9373 35.643C25.5374 35.0179 26.3513 34.6667 27.2 34.6667H30.4V28C30.4 25.3478 31.4114 22.8043 33.2118 20.9289C35.0121 19.0536 37.4539 18 40 18C42.5461 18 44.9879 19.0536 46.7882 20.9289C48.5886 22.8043 49.6 25.3478 49.6 28V34.6667H52.8C53.6487 34.6667 54.4626 35.0179 55.0627 35.643C55.6629 36.2681 56 37.1159 56 38V54.6667Z"
        fill="#111947"
      />
      <path
        d="M40 24C38.9391 24 37.9217 24.3863 37.1716 25.0739C36.4214 25.7616 36 26.6942 36 27.6667V34.5H44V27.6667C44 26.6942 43.5786 25.7616 42.8284 25.0739C42.0783 24.3863 41.0609 24 40 24Z"
        fill="#F3F3F3"
      />
    </svg>
  )
}

export default LockIcon
