import React from "react";

interface GoogleLogoProps {
  height?: string;
  width?: string;
}

const GoogleLogo: React.FC<GoogleLogoProps> = ({ width, height }) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="38" height="38" rx="4" fill="white" />
      <path
        d="M13.9892 20.876L13.3626 23.2151L11.0726 23.2635C10.3882 21.9941 10 20.5418 10 18.9984C10 17.506 10.363 16.0986 11.0063 14.8594H11.0068L13.0456 15.2332L13.9387 17.2597C13.7518 17.8047 13.6499 18.3897 13.6499 18.9984C13.6499 19.6591 13.7696 20.2921 13.9892 20.876Z"
        fill="#FBBB00"
      />
      <path
        d="M27.8421 17.3184C27.9455 17.8628 27.9994 18.425 27.9994 18.9997C27.9994 19.644 27.9316 20.2726 27.8026 20.8788C27.3645 22.9419 26.2197 24.7434 24.6338 26.0182L24.6333 26.0177L22.0654 25.8867L21.7019 23.6179C22.7542 23.0008 23.5766 22.035 24.0098 20.8788H19.1973V17.3184H24.08H27.8421Z"
        fill="#518EF8"
      />
      <path
        d="M24.6335 26.0173L24.634 26.0178C23.0917 27.2576 21.1324 27.9993 18.9997 27.9993C15.5723 27.9993 12.5924 26.0836 11.0723 23.2644L13.9889 20.877C14.7489 22.9054 16.7056 24.3494 18.9997 24.3494C19.9857 24.3494 20.9094 24.0828 21.7021 23.6175L24.6335 26.0173Z"
        fill="#28B446"
      />
      <path
        d="M24.7442 12.072L21.8286 14.459C21.0082 13.9462 20.0385 13.6499 18.9995 13.6499C16.6536 13.6499 14.6602 15.1602 13.9383 17.2613L11.0064 14.861H11.0059C12.5037 11.9731 15.5212 10 18.9995 10C21.1832 10 23.1855 10.7779 24.7442 12.072Z"
        fill="#F14336"
      />
    </svg>
  );
};

export default GoogleLogo;
