import * as React from 'react';

interface AvatarIconProps {
   height?: string;
   width?: string;
};
const AvatarDownIcon: React.FC<AvatarIconProps> = ({ height, width }) => {
   return (
      <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M18.5763 7.83851C18.2941 6.18289 17.4187 4.68257 16.1098 3.61108C14.8008 2.53959 13.1455 1.9683 11.4456 2.00136C9.74574 2.03442 8.1145 2.66963 6.8493 3.79119C5.5841 4.91274 4.76922 6.44594 4.55322 8.11124C3.66639 8.47712 2.93544 9.13528 2.48487 9.9736C2.0343 10.8119 1.89198 11.7786 2.08215 12.7089C2.27233 13.6392 2.78323 14.4756 3.52784 15.0757C4.27245 15.6758 5.20471 16.0024 6.16582 16H17.8302C18.8709 16.0009 19.8743 15.6172 20.6429 14.9244C21.4114 14.2315 21.8896 13.2797 21.9831 12.2562C22.0767 11.2327 21.7789 10.2117 21.1484 9.39412C20.5179 8.57654 19.6004 8.0216 18.5763 7.83851ZM12.8312 15.9885H11.1649V11.8748C11.1649 11.6566 11.2526 11.4474 11.4089 11.2931C11.5651 11.1388 11.7771 11.0521 11.998 11.0521C12.219 11.0521 12.4309 11.1388 12.5872 11.2931C12.7434 11.4474 12.8312 11.6566 12.8312 11.8748V15.9885Z" fill="#9496AB"/>
         <path d="M14.6267 17.7147H12.751V16H11.2505V17.7147H9.37476C9.30274 17.7148 9.23227 17.7386 9.17176 17.7832C9.11124 17.8278 9.06325 17.8914 9.0335 17.9663C9.00375 18.0413 8.99351 18.1244 9.004 18.2058C9.01449 18.2872 9.04527 18.3635 9.09265 18.4255L11.7186 21.8549C11.7538 21.9005 11.7971 21.937 11.8457 21.962C11.8942 21.9871 11.9468 22 12 22C12.0532 22 12.1058 21.9871 12.1543 21.962C12.2029 21.937 12.2461 21.9005 12.2814 21.8549L14.9073 18.4255C14.9546 18.3636 14.9854 18.2875 14.9959 18.2062C15.0065 18.125 14.9964 18.042 14.9668 17.9671C14.9372 17.8922 14.8895 17.8286 14.8292 17.7839C14.7689 17.7391 14.6986 17.7151 14.6267 17.7147Z" fill="#9496AB"/>
      </svg>
   );
};

export default AvatarDownIcon