import React from 'react'

interface CosmeticsProps {
  height?: string
  width?: string
}

const Cosmetics: React.FC<CosmeticsProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="72" viewBox="0 0 80 72">
      <g id="Group_10880" data-name="Group 10880" transform="translate(-260 -1978.699)">
        <rect
          id="Rectangle_3547"
          data-name="Rectangle 3547"
          width="80"
          height="72"
          transform="translate(260 1978.699)"
          fill="none"
        />
        <path
          id="Path_2011"
          data-name="Path 2011"
          d="M329.609,2013.6c-.328-.282-1.974-1.49-6.273-1.9a23.438,23.438,0,1,0-46.738-.835,9.417,9.417,0,0,0-1.206-.18,1.5,1.5,0,0,0-1.618,1.495v12.9h-1.305v18.653h12.965v-15.855a23.347,23.347,0,0,0,10.8,4.761v6.893H290.6v3h18.8v-3h-5.633v-6.9a23.269,23.269,0,0,0,9.8-4.012l.31,1.332v13.78h12.965v-13.791l3.444-15.757Zm-52.835.412a6.541,6.541,0,0,1,4.16,4.428,9.068,9.068,0,0,1,.3,2.329v4.309h-4.461Zm5.659,26.719h-6.965v-12.653h6.965Zm18.334-1.2h-1.535v-6.606c.26.009.521.02.781.02s.5-.024.754-.032ZM284.635,2023c-.137-.156-.269-.317-.4-.477v-1.751a12.086,12.086,0,0,0-.4-3.113,9.531,9.531,0,0,0-4.087-5.511,20.434,20.434,0,1,1,40.59-.589h-.075c-.577,0-1.109.017-1.622.04A18.755,18.755,0,0,0,292,1992.546l.852,1.811a16.766,16.766,0,0,1,22.312,8.034,16.886,16.886,0,0,1,1.44,9.368c-3.869.461-5.385,1.57-5.7,1.84l-.69.592,1.654,7.118a16.724,16.724,0,0,1-4.5,3.256l.882,1.794a18.725,18.725,0,0,0,4.146-2.794l.427,1.836A20.455,20.455,0,0,1,284.635,2023Zm39.21,17.732H316.88v-9.453h6.965Zm.293-12.453h-7.567l-2.967-12.762a23.59,23.59,0,0,1,13.322,0Z"
          fill="#12c4c4"
        />
      </g>
    </svg>
  )
}

export default Cosmetics
