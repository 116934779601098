import { useFormContext, Controller } from 'react-hook-form'
import Select from 'react-select'
import { styler } from './constants'
import { useBreakpoint } from '../../../../../customize/hooks/useBreakpoint'

interface SelectInput3Props {
  name: string
  label?: string
  placeholder?: string
  options: any[]
  asterisk?: boolean
  styles?: any
}

export const SelectInput3: React.FC<SelectInput3Props> = ({
  name,
  label,
  placeholder = 'Typing here...',
  options,
  asterisk,
  styles,
}) => {
  const point = useBreakpoint()
  const { errors, control } = useFormContext()
  const isError = !!errors?.[name] ?? false
  const message = errors?.[name]?.message ?? null

  return (
    <div className="input">
      {label ? <span className="input__label">{label}</span> : null}
      {asterisk && <span className="input__asterisk">*</span>}
      <Controller
        name={name}
        control={control}
        render={(field) => (
          <Select
            {...field}
            inputRef={field.ref}
            isSearchable={false}
            options={options}
            placeholder={placeholder}
            styles={styles || styler(isError, point)}
          />
        )}
      />
      {message ? <span className="form-field-error-message">{message}</span> : null}
    </div>
  )
}
