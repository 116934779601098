import { useFormContext } from 'react-hook-form'

interface CheckboxInputProps {
  name: string
  label?: any
  isRequire?: boolean
  onChangeProps?: Function | any
}

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  name,
  label,
  onChangeProps,
}) => {
  const { errors, register } = useFormContext()
  const message = errors?.[name]?.message ?? null
  return (
    <div className="checkbox">
      <input
        value="true"
        type="checkbox"
        ref={register}
        onChange={onChangeProps}
        name={name}
        id="checkbox1"
      />
      {label}
      {message ? <span className="form-field-error-message is-center">{message}</span> : null}
    </div>
  )
}
