interface EditProfileIconProps {
  height?: string;
  width?: string;
}

const EditProfileIcon: React.FC<EditProfileIconProps> = ({
  height,
  width,
}) => {
  return (
    <svg
      width={width || '28'}
      height={height || '28'}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6458 14.875C16.7817 14.875 19.8717 10.7179 19.8717 7C19.8717 3.14038 16.63 0 12.6458 0C8.66164 0 5.41992 3.14038 5.41992 7C5.41992 10.7179 8.50989 14.875 12.6458 14.875Z"
        fill="#9496AB "
      />
      <path
        d="M15.5303 23.6058L21.4591 17.8622C19.4187 17.2086 16.5157 16.625 12.6453 16.625C7.54474 16.625 4.11695 17.6382 2.13886 18.4879C0.84001 19.0461 0 20.3009 0 21.6851V26.25C0 26.733 0.40465 27.125 0.903237 27.125H14.6216L15.5303 23.6058Z"
        fill="#9496AB "
      />
      <path
        d="M24.7293 17.1684L17.161 24.5L16.2578 28L19.8708 27.125L27.439 19.7934C28.1832 19.0724 28.1896 17.8955 27.439 17.1684C26.6902 16.4439 25.4771 16.4439 24.7293 17.1684Z"
        fill="#9496AB "
      />
    </svg>
  );
};

export default EditProfileIcon;
