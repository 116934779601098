import { FC, useCallback, useRef, useEffect } from 'react'
import { CloseIcon2, NotificationIcon } from '../../../../../assets/images/svgs'
import NotificationComponent from '../notification'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ROUTER_URL } from '../../../../../utils/const'
interface NotificationProps {
  openNoti?: boolean
  toggleNoti?: any
  notis?: {
    id?: any
    title: string
    notify_time: string
    message: string
    status: any
    friend_id?: number
    friend_status?: string
  }[]
  notify?: Function
  getNotisDB?: Function
  callApi: any
}

export const NotificationsModal: FC<NotificationProps> = ({
  openNoti,
  toggleNoti,
  notis,
  callApi,
  getNotisDB,
}) => {
  const [, i18n] = useTranslation('translation')

  const history = useHistory()
  const headerRef: any = useRef()
  const footerRef: any = useRef()
  const contentRef: any = useRef()

  useEffect(() => {
    const wheel = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
    const listRef = [headerRef, footerRef]

    if (openNoti) {
      listRef.forEach((ref: any) => ref?.current?.addEventListener(wheel, preventDefault, false))
    } else {
      listRef.forEach((ref: any) => ref?.current?.removeEventListener(wheel, preventDefault, false))
    }
  }, [openNoti])

  useEffect(() => {
    contentRef?.current?.addEventListener(
      'wheel',
      function (e: any) {
        const height = contentRef?.current?.clientHeight,
          scrollHeight = contentRef?.current?.scrollHeight,
          scrollTop = contentRef?.current?.scrollTop,
          d = e.wheelDelta
        if (
          ((Math.floor(scrollTop) === scrollHeight - height ||
            Math.ceil(scrollTop) === scrollHeight - height) &&
            d < 0) ||
          (scrollTop === 0 && d > 0)
        ) {
          e.preventDefault()
        }
      },
      false
    )
  }, [contentRef?.current, openNoti])

  const preventDefault = useCallback((e) => {
    e.preventDefault()
  }, [])

  const renderRegister = (
    <div className="noti-wrap__register" ref={footerRef}>
      <button className="button-custom" onClick={goToNoti}>
        {i18n.t('notifications.button')}
      </button>
    </div>
  )

  function goToNoti() {
    toggleNoti()
    history.push(ROUTER_URL.NOTIFICATIONS)
  }

  return openNoti ? (
    <div className="wrap-modal-noti">
      <div onClick={toggleNoti} className="opacity"></div>
      <div className="noti-wrap">
        <div className="wrap-modal-noti__header">
          <div onClick={toggleNoti} style={{ marginRight: '2rem', marginLeft: 'auto' }}>
            <CloseIcon2 />
          </div>
        </div>
        <div className="noti-wrap_header" ref={headerRef}>
          <div className="noti-wrap_header-title">
            <NotificationIcon />
            <div className="box-noti" style={{ marginLeft: 0 }}>
              <p className="box-noti-ttle">{i18n.t('notifications.title_header')}</p>
            </div>
          </div>
        </div>
        <div className="noti-wrap_content" onClick={toggleNoti} ref={contentRef}>
          {!!notis?.length ? (
            notis?.map(
              ({ title, notify_time, message, status, id, friend_id, friend_status }, index) => {
                return (
                  <div
                    key={id}
                    onClick={() =>
                      history.push(
                        !!friend_id
                          ? `${ROUTER_URL.PROFILE}/${friend_id}`
                          : `${ROUTER_URL.DETAIL_NOTIFICATION}/${id}`
                      )
                    }
                  >
                    <NotificationComponent
                      textTitle={title}
                      date={notify_time}
                      textContent={message}
                      callApi={callApi}
                      status={status}
                      getNotisDB={getNotisDB}
                      key={index}
                      toggleNoti={toggleNoti}
                      friend_id={friend_id}
                      friend_status={friend_status}
                    />
                  </div>
                )
              }
            )
          ) : (
            <div
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                color: '#111947',
                paddingTop: '1rem',
              }}
            >
              {i18n.t('notifications.null')}
            </div>
          )}
        </div>
        {renderRegister}
      </div>
    </div>
  ) : null
}
