import Wrapper from '../../components/modules/_wrapper'
import { Trans, useTranslation } from 'react-i18next'
import { WarningIcon } from '../../../../assets/images/svgs'
import { useLocation } from 'react-router-dom'
import React from 'react'
import MobileHeader from '../../components/block/mobile/header'

interface SignupConfirmProps {}

const SignupConfirmMobile: React.FC<SignupConfirmProps> = () => {
  const [, i18n] = useTranslation('translation')
  const location: any = useLocation()

  return (
    <>
      <MobileHeader />
      <Wrapper
        breadcrum={[]}
        className="password "
      >
        <h2 className="password__title title-h2 distance-h2-to-text ">
          {i18n.t('signup.confirm.title')}
        </h2>
        <div className="password__describe distance-text-to-noti" id="active-title-email">
          <p>
            <Trans
              i18nKey="signup.confirm.message"
              values={{ email: location?.state?.email }}
              components={{
                span: <span className="password__describe-content1_link" />,
                br: <br />,
                b: <b />,
              }}
            />
          </p>
          <p className="password__describe-content3 distance-p-to-p">
            {i18n.t('signup.confirm.detail_2')}
          </p>
        </div>
        <div className="password__warning password__warning-noti  distance-text-to-btn">
          <div
            className="password__warning-icon password__warning-icon_noti padding-title-noti"
            id="forgot-warning-icon"
          >
            <WarningIcon />
            <div className="text-header-noti  distance-h4-to-icon">
              {i18n.t('forgot_password_noti.title_email_err')}
            </div>
          </div>
          <div
            className="password__warning-content password__warning-content_noti padding-content-noti padding-content-confirm-email"
            id="fgPW"
          >
            <p className="password__warning-content_noti-text1">
              <Trans
                i18nKey="signup.confirm.warning_1"
                values={{ value: `"Spam mail folder"` }}
                components={{ br: <br /> }}
              />
            </p>
          </div>
        </div>
      </Wrapper>
    </>
  )
}
export default SignupConfirmMobile