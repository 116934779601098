import * as React from 'react'

interface MenuProps {
  height?: string
  width?: string
}
const MenuCard: React.FC<MenuProps> = ({ height, width }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10H22V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V10ZM15.5834 16.6045C15.1248 17.1515 14.4363 17.4993 13.6667 17.4993C12.286 17.4993 11.1667 16.3801 11.1667 14.9993C11.1667 13.6186 12.286 12.4993 13.6667 12.4993C14.4363 12.4993 15.1248 12.8472 15.5834 13.3942C16.042 12.8472 16.7304 12.4993 17.5001 12.4993C18.8808 12.4993 20.0001 13.6186 20.0001 14.9993C20.0001 16.3801 18.8808 17.4993 17.5001 17.4993C16.7304 17.4993 16.042 17.1515 15.5834 16.6045Z"
        fill="#9496AB"
      />
      <path d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V8H2V6Z" fill="#9496AB" />
    </svg>
  )
}

export default MenuCard
