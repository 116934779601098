import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconHair1: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.3409 39.8053C62.7304 22.6137 51.9668 9 36.0025 9C20.0383 9 9.27369 22.6137 8.66316 39.8053C8.27242 50.8047 5 55.897 5 55.897C11.9681 59.156 26.7012 62.0077 36.0025 62.0077C45.3039 62.0077 60.0319 59.156 67 55.897C67.001 55.8939 63.7316 50.8037 63.3409 39.8053Z"
        fill="#111947"
      />
      <path
        d="M54.6273 33.3668C53.1345 21.3959 45.9965 14.7168 36.0388 14.7168C26.0811 14.7168 18.9033 21.3959 17.4106 33.3668C13.1104 33.5522 11.9688 36.6086 11.9688 40.5673C11.9688 44.5261 16.3839 49.0857 17.841 49.2762C18.4515 49.3709 18.9827 49.4269 19.5179 49.4717C23.0223 57.3933 29.5855 62.9999 35.0365 62.9999H37.039C42.4808 62.9999 49.0542 57.4177 52.5576 49.4962C53.0935 49.4662 53.6268 49.4008 54.1541 49.3006C55.6163 49.0969 60.0264 44.7359 60.0264 40.5918C60.0264 36.4477 58.9172 33.5481 54.6273 33.3668Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M29.9172 43.8642C31.5543 43.8642 32.8813 42.5359 32.8813 40.8974C32.8813 39.2589 31.5543 37.9307 29.9172 37.9307C28.2802 37.9307 26.9531 39.2589 26.9531 40.8974C26.9531 42.5359 28.2802 43.8642 29.9172 43.8642Z"
        fill="#111947"
      />
      <path
        d="M42.9133 43.8642C44.5504 43.8642 45.8774 42.5359 45.8774 40.8974C45.8774 39.2589 44.5504 37.9307 42.9133 37.9307C41.2763 37.9307 39.9492 39.2589 39.9492 40.8974C39.9492 42.5359 41.2763 43.8642 42.9133 43.8642Z"
        fill="#111947"
      />
      <path
        d="M57.9888 31.6283L48.7424 31.6589C48.8177 31.1578 46.9749 26.313 43.0441 22.6394C43.0441 22.6394 45.9574 29.514 45.7579 31.6589L14.0156 31.6283C15.3822 23.3788 25.2646 11.7063 35.1805 11.7012C45.8749 11.6961 54.3002 16.0154 57.9888 31.6283Z"
        fill="#111947"
      />
    </svg>
  )
}

export default IconHair1
