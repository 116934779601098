import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import SettingIcon from '../../../../../../assets/images/svgs/setting-icon'
import PasswordModal from './password-modal'
import ProfileImageModal from './profile-image-modal'
import { Modal } from '../../../../components/modules/modal'
import { nanoid } from 'nanoid'

import { RadioInput } from '../../../../components/atoms/radio-input'
import { TextInput } from '../../../../components/atoms/text-input'
import { useTranslation } from 'react-i18next'
import { radioFields, emailFields, nameFields} from './constants'
import { useDispatch, useSelector } from 'react-redux'
import { UPDATE_INFO } from '../../../../../../utils/endpoints'
import { DETAIL_ACCOUNT, EDIT_PROFILE_IMAGE, GET_PROFILE_IMAGE} from '../../../../../../utils/endpoints'
import { SigninActionsTypes } from '../../../../../stores'
import Wrapper from '../../../../components/modules/_wrapper'
import { Button } from '../../../../components/atoms/buttons'
import { EMAIL_PATTERN, ERROR_TYPE, POST, ROUTER_URL, SUCCESS } from '../../../../../../utils/const'
import * as Yup from 'yup'
import { useYupValidationResolver } from '../../../signup/constants'
import { isEmpty } from 'lodash'
import axios from '../../../../../../utils/axios'
import { setUserDetail } from '../../../../../stores/actions/signin'
import { defaultAvatar } from '../../../../../../assets/images'

const AccountSetting: React.FC = ({ notify, callApi }: any) => {

  const title = 'アカウント設定 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'アカウント設定のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [t, i18n] = useTranslation('translation')
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [showProfileImageModal, setShowProfileImageModal] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [dataOrigin, setDataOrigin] = useState<any>({})
  const [nameMail, setNameMail] = useState('')
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false)
  const [profileImage, setProfileImage] = useState('')

  const lang = useSelector((state: any) => state.signin.lang)

  let defaultValues = {
    user_name: '',
    email: '',
    sex: ''
  }

  const validate: any = Yup.object({
    sex: Yup.string().required(),
    email: Yup.string()
      .required(t('account_setting.email_required'))
      .matches(EMAIL_PATTERN, t('account_setting.invalid_email'))
      .max(254, t('account_setting.email_max'))
      .notOneOf([''], t('account_setting.email_required')),
      user_name: Yup.string()
      .required(t('validations.user_name'))
      .max(32, t('validations.user_name_max')),
  })

  const methods = useForm<any>({
    defaultValues,
    resolver: useYupValidationResolver(validate),
  })

  const togglePasswordModal = () => {
    setShowPasswordModal((showPasswordModal) => !showPasswordModal)
  }

  const toggleProfileImageModal = () => {
    setShowProfileImageModal((showProfileImageModal) => !showProfileImageModal)
  }

  const { handleSubmit, setValue, clearErrors, getValues, errors } = methods

  const onSubmit = async (values: any, errors: any) => {
    try {
      clearErrors()
      const params = {
        customer_name: values.user_name,
        customer_email: values.email,
        customer_sex: values.sex,
      }
      const response = await callApi(UPDATE_INFO, POST, params)
      await onGetUserData()

      const mail = getValues('email')
      const sex = getValues('sex')
      if (dataOrigin.mail_address === mail && dataOrigin.sex === sex) {
        notify(response?.data?.message[0]?.message, SUCCESS, t('account_setting.title_success'))
      }
      if (dataOrigin.mail_address === mail && dataOrigin.sex !== sex) {
        setDataOrigin((prevState: any) => ({ ...prevState, sex }))
        notify(response?.data?.message[0]?.message, SUCCESS, t('account_setting.title_success'))
      } else if (dataOrigin.mail_address !== getValues('email')) {
        dispatch({ type: SigninActionsTypes.SIGN_OUT })
        history.push({
          pathname: ROUTER_URL.ACTIVE_EMAIL,
          state: {
            email: values.email,
          },
        })
      }
    } catch (error) {
      const err = error?.message
      notify(err, ERROR_TYPE)
    }
  }

  useEffect(() => {
    fetchDetailAccount()
  }, [])

  function getProfileImage() {
    axios.get(GET_PROFILE_IMAGE, {responseType: 'blob'}).then(res => {
      setProfileImage(URL.createObjectURL(res?.data))
    })
  }

  const fetchDetailAccount = async () => {
    try {
      clearErrors()
      const result = await callApi(DETAIL_ACCOUNT)
      if (result) {
        const detail = result.data.data
        setDataOrigin(detail)
        defaultValues.user_name = detail.name
        defaultValues.email = detail.mail_address
        defaultValues.sex = detail.sex
        setValue('user_name', detail.name, { shouldValidate: false })
        setValue('email', detail.mail_address, { shouldValidate: false })
        setValue('sex', detail.sex, { shouldValidate: false })
        getProfileImage()
        setNameMail(detail.mail_address)
      }
    } catch (error) {
    }
  }

  const onSavingImage = async (blob: any) => {
    const param = new FormData()

    const id = nanoid()
    param.append('upload', blob, `imageFilename_${id}.png`)

    try {
      const result = await callApi(EDIT_PROFILE_IMAGE, POST, param)
      const { data } = result?.data || {}
      if (data) {
        getProfileImage()
        await onGetUserData()
      }
    } catch (error) {
      if (error?.message) notify(error?.message, ERROR_TYPE)
    }
  }

  const onGetUserData = async () => {
    try {
      const data = await callApi(DETAIL_ACCOUNT)
      dispatch(setUserDetail(data?.data?.data || {}))
      localStorage.setItem('userData', JSON.stringify(data?.data?.data))
    } catch (error) {
    }
  }

  function onValidate() {
    setIsFirstLoad(true)
    const listField = ['email', 'user_name']
    listField.forEach((item: any) => {
      const value = getValues(item) + ''
      setValue(item, value.trim() || '')
    })
    handleSubmit(onSubmit)()
  }

  const list = [
    {
      name: t('account_setting.home'),
      link: ROUTER_URL.HOME
    },
    {
      name: t('account_setting.account_settings'),
      link: ROUTER_URL.SETTINGS,
    },
  ]

  useEffect(() => {
    if (isFirstLoad && !isEmpty(errors)) {
      clearErrors()
      handleSubmit(onSubmit)()
    }
  }, [lang])


  const renderProfileImageField = (
    <div className="account-setting__sidebar-area">
      <div className="account-setting__choose-area">
        <div className="account-setting__choose">
        <img src={profileImage || defaultAvatar} alt="my-avatar" className="account-setting__avatar-img" />
        <ProfileImageModal
            isShowModal={showProfileImageModal}
            toggleModal={toggleProfileImageModal}
            onSavingImage={onSavingImage}
          />
        <Button
          className="account-setting__choose-button"
          onClick={toggleProfileImageModal}
        >
          {i18n.t('account_setting.choose_file')}
        </Button>
        </div>
      </div>
      <div className="account-setting__content-describe">
        <p>
          {i18n.t('account_setting.image_description_1')}
        </p>
      </div>
    </div>
  )


  const renderNameField = (
    <div className="account-setting__name-field">
      {nameFields.map(({ name, type, isRequire, placeholder }) => {
        return (
          <React.Fragment key={name}>
            <p className="account-setting__name-label">{t('account_setting.user_name')}</p>
            <TextInput
              type={type}
              name={name}
              isRequire={isRequire}
              placeholder={t(placeholder)}
              isFocus={true}
            />
          </React.Fragment>
        )
      })}
    </div>
  )

  const renderEmailField = (
    <div className="account-setting__email-field">
      {emailFields.map(({ name, type, isRequire, placeholder }) => {
        return (
          <React.Fragment key={name}>
            <p className="account-setting__email-label">{t('account_setting.email_address')}</p>
            <TextInput
              type={type}
              name={name}
              isRequire={isRequire}
              placeholder={t(placeholder)}
              isFocus={true}
            />
          </React.Fragment>
        )
      })}
    </div>
  )

  const renderPasswordField = (
    <div className="account-setting__password-field">
      <p className="account-setting__password-label">{t('account_setting.password')}</p>
      <div className="account-setting__password-area">
        <div className="account-setting__password-dots">
          <span className="account-setting__password-dot"></span>
          <span className="account-setting__password-dot"></span>
          <span className="account-setting__password-dot"></span>
          <span className="account-setting__password-dot"></span>
          <span className="account-setting__password-dot"></span>
          <span className="account-setting__password-dot"></span>
          <span className="account-setting__password-dot"></span>
        </div>
        <div className="text-link" onClick={togglePasswordModal}>
          {t('account_setting.password_change')}
        </div>
      </div>
    </div>
  )

  const renderGenderField = (
    <div className="account-setting__gender-field">
      <p className="account-setting__gender-label">{t('account_setting.gender')}</p>
      <div className="account-setting__gender-radios">
        {radioFields.map(({ name, type, label, value, isRequire }) => {
          return (
            <div key={label} className="account-setting__gender-couple">
              <RadioInput isRequire={isRequire} name={name} type={type} value={value} />
              <label htmlFor="" className="label_input-radio">
                {t(label)}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )

  const renderResolvedButtons = (
    <div className="account-setting__resolved-buttons">
      <Button className="shortnormal cancel" onClick={fetchDetailAccount} type="button">
        {t('account_setting.cancel')}
      </Button>
      <Button className="shortnormal" type="button" onClick={onValidate}>
        {t('account_setting.save')}
      </Button>
    </div>
  )

  const renderWithdraw = (
    <div className="account-setting__withdraw">
      <span
        className="text-link"
        onClick={() => history.push({ pathname: ROUTER_URL.WITHDRAW, state: { email: nameMail } })}
      >
        {t('account_setting.withdraw')}
      </span>
    </div>
  )
  return (
    <Wrapper
      breadcrum={list}
      Icon={SettingIcon}
      title={t('account_setting.title')}
      className="account-setting"
      isNoMarginTop={true}
    >
      <Modal modalStyles="booking__modal-booking" show={showPasswordModal} toggleModal={togglePasswordModal}>
        <PasswordModal onTogglePasswordModal={togglePasswordModal} notify={notify} callApi={callApi} />
      </Modal>

      <div className="account-setting__container">
        <div className="account-setting__inner-container">
          <FormProvider {...methods}>
            <form>
              <div className="distance-form-to-btn account-setting__content">
                {renderProfileImageField}
                <div className="account-setting__content">
                  <div className="account-setting__change-area">
                    {renderNameField}
                    {renderEmailField}
                    {renderPasswordField}
                    {renderGenderField}
                  </div>
                </div>
              </div>
              {renderResolvedButtons}
              {renderWithdraw}
            </form>
          </FormProvider>
        </div>
      </div>
    </Wrapper>
  )
}

export default AccountSetting
