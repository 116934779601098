import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconDateDetail: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 5.10449H4C3.46973 5.10504 2.96133 5.32318 2.58637 5.71104C2.21141 6.09891 2.00053 6.6248 2 7.17332V19.9311C2.00053 20.4796 2.21141 21.0055 2.58637 21.3934C2.96133 21.7812 3.46973 21.9994 4 21.9999H20C20.5303 21.9994 21.0387 21.7812 21.4136 21.3934C21.7886 21.0055 21.9995 20.4796 22 19.9311V7.17332C21.9995 6.6248 21.7886 6.09891 21.4136 5.71104C21.0387 5.32318 20.5303 5.10504 20 5.10449ZM21.3333 19.9311C21.3333 20.2969 21.1929 20.6477 20.9428 20.9064C20.6928 21.165 20.3536 21.3103 20 21.3103H4C3.64638 21.3103 3.30724 21.165 3.05719 20.9064C2.80714 20.6477 2.66667 20.2969 2.66667 19.9311V9.58696H21.3333V19.9311Z"
        fill="#9496AB"
      />
      <path
        d="M17.6663 2.68961C17.6663 2.50671 17.5961 2.33131 17.4711 2.20198C17.3461 2.07266 17.1765 2 16.9997 2C16.8229 2 16.6533 2.07266 16.5283 2.20198C16.4032 2.33131 16.333 2.50671 16.333 2.68961V4.41363H17.6663V2.68961Z"
        fill="#9496AB"
      />
      <path
        d="M7.66634 2.68961C7.66634 2.50671 7.5961 2.33131 7.47108 2.20198C7.34605 2.07266 7.17649 2 6.99967 2C6.82286 2 6.65329 2.07266 6.52827 2.20198C6.40325 2.33131 6.33301 2.50671 6.33301 2.68961V4.41363H7.66634V2.68961Z"
        fill="#9496AB"
      />
    </svg>
  )
}

export default IconDateDetail
