import { useState, useEffect } from 'react'
import classnames from 'classnames'
import Footer from './footer'
import Header from './header'
import Body from './body'
import { TABLET_MIN_SIZE } from '../../../../../utils/const'
import useWindowSize from '../../../../../customize/hooks/useWindowSize'
interface MenuProps {
  toggleMenuMobiles: () => void
  open: boolean
}

export const MenuMobile: React.FC<MenuProps> = ({ open, toggleMenuMobiles }) => {
  const size = useWindowSize()
  const [shouldRender, setRender] = useState(open)

  useEffect(() => {
    if (size.width >= TABLET_MIN_SIZE && open) {
      toggleMenuMobiles()
      setRender(false)
    }
    if (open) {
      document.body.style.overflow = 'hidden'
      setRender(true)
    }else {
      document.body.style.overflow = 'unset'
    }
  }, [size, open])

  const handleonAnimationEnd = () => {
    if (!open) setRender(false)
  }

  return shouldRender ? (
    <div
      style={{ position: 'fixed', top: '0', right: '0', height: '100%' }}
      onAnimationEnd={handleonAnimationEnd}
      className={classnames('menu', { 'fade-in': open, 'fade-out': !open })}
    >
      <div className="triangle"></div>
      <div className="menuMobiles">
        <Header toggleMenuMobiles={toggleMenuMobiles} />
        <Body toggleMenuMobiles={toggleMenuMobiles} />
        <Footer />
      </div>
    </div>
  ) : null
}
