import React from 'react'

interface LeftArrowProps {
  height?: string
  width?: string
}

const LeftArrow: React.FC<LeftArrowProps> = ({ height, width }) => {
  return (
    <svg
      id="arrow_back_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
    >
      <path id="Path_243" data-name="Path 243" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_244"
        data-name="Path 244"
        d="M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z"
        fill="#12c4c4"
      />
    </svg>
  )
}

export default LeftArrow
