export function clearStorage() {
  const ls: any = localStorage
  let arr: any = []
  for (let i = 0; i < ls.length; i++) {
    if (ls.key(i).indexOf('i18nextLng') === -1) {
      arr.push(localStorage.key(i))
    }
  }

  for (let i = 0; i < arr.length; i++) {
    localStorage.removeItem(arr[i])
  }
}

export const b64toBlob = (b64Data: any, contentType?: any, sliceSize = 512) => {
  let b64 = b64Data.replace(/^data:image\/[a-z]+;base64,/, '')
  const byteCharacters = atob(b64)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType || 'image/png' })
  return blob
}

/**
 * ページの head 要素を設定します。
 * @param title ページタイトル
 * @param description
 * @param keywords
 * @param og_image og:image の URL です。スネークケースは既存コードに合わせています。
 */
export const setupHead = (title: string, description: string, keywords: string, og_image: string) => {
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
}
