import React from 'react'

interface NewsBannerIconProps {
    height?: string
    width?: string
}

export const RadioUnCheck: React.FC<NewsBannerIconProps> = ({ height, width }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="11.5" fill="white" stroke="#12C4C4" />
        </svg>
    )
}

export const RadioCheck: React.FC<NewsBannerIconProps> = ({ height, width }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z" fill="white" stroke="#12C4C4" />
            <path d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5Z" fill="#12C4C4" stroke="#12C4C4" />
        </svg>
    )
}



