import React from 'react'

interface CloseIconProps {
  height?: string
  width?: string
}

const CloseIcon2: React.FC<CloseIconProps> = ({ height, width }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L21 21" stroke="white" strokeWidth="2" />{' '}
      <path d="M1 21L21 1" stroke="white" strokeWidth="2" />{' '}
    </svg>
  )
}

export default CloseIcon2
