import { FC, useState, useEffect, useRef } from 'react'
import { useBreakpoint } from '../../../../../customize/hooks/useBreakpoint'
import useWindowSize from '../../../../../customize/hooks/useWindowSize'
import { AvatarIconDate, AvatarIconHover } from '../../../../../assets/images/svgs'

interface AvatarComponentProps {
  image: string
  date?: string
  content?: string
  height?: string
  heightMobile?: string
  isExistContent?: boolean
}
const AvatarDetailComponent: FC<AvatarComponentProps> = ({
  image,
  date,
  content,
  height,
  isExistContent, // aimenext update : isExistContent = true -> have content in Avatar
}) => {
  const device = useBreakpoint()
  const size = useWindowSize()

  const ref = useRef<any>()

  const [h, setH] = useState<any>()

  useEffect(() => {
    const width = ref?.current?.clientWidth
    setH((width * 4) / 3)
  }, [size, device])

  return (
    <div
      className="wrap-render_avatar-detail"
      style={{ backgroundImage: `url(${image})`, height: height || h }}
      ref={ref}
    >
      {isExistContent && (
        <>
          <div className="wrap-icon"></div>
          <div className="wrap-content">
            <div className="content2">
              <div className="date">
                <div className="date-icon">
                  <AvatarIconDate />
                </div>
                <span className="text-date">
                  {date}
                </span>
              </div>
              <div className="avatar">
                <div className="avatar-icon">
                  <AvatarIconHover />
                </div>
                <span className="text-avatar">
                  {content}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default AvatarDetailComponent
