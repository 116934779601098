import * as React from 'react'

export default class Logo extends React.PureComponent<any, {}> {
  render() {
    return (
      <svg
        width="137"
        height="24"
        viewBox="0 0 137 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M109.795 6.31543C109.422 6.31599 109.065 6.46899 108.801 6.74089C108.537 7.0128 108.389 7.38142 108.389 7.76596V16.3975C108.389 17.4102 108.35 18.4691 107.876 19.3217C107.557 19.8514 107.113 20.2884 106.585 20.5914C106.057 20.8945 105.462 21.0535 104.858 21.0535C104.253 21.0535 103.659 20.8945 103.131 20.5914C102.603 20.2884 102.158 19.8514 101.839 19.3217C101.366 18.467 101.325 17.4081 101.325 16.3954V7.76596C101.325 7.38125 101.177 7.01231 100.913 6.74028C100.649 6.46825 100.291 6.31543 99.9183 6.31543C99.5453 6.31543 99.1875 6.46825 98.9237 6.74028C98.6599 7.01231 98.5117 7.38125 98.5117 7.76596V17.0902C98.5117 18.9554 99.0343 20.4586 100.106 21.6691C100.949 22.6123 102.456 23.7365 104.857 23.7365C107.258 23.7365 108.766 22.6123 109.609 21.6691C110.681 20.4544 111.202 18.9554 111.202 17.0902V7.76596C111.201 7.38142 111.053 7.0128 110.789 6.74089C110.525 6.46899 110.168 6.31599 109.795 6.31543Z"
            fill="white"
          />
          <path
            d="M30.2127 7.28095C30.0647 7.05275 29.8592 6.87044 29.6183 6.75358C29.3774 6.63672 29.1102 6.58973 28.8454 6.61765C28.5805 6.64557 28.328 6.74734 28.115 6.91205C27.902 7.07675 27.7366 7.29817 27.6364 7.55252L24.274 16.1588C24.1811 16.3892 24.0241 16.5861 23.8228 16.7246C23.6215 16.863 23.385 16.9369 23.143 16.9369C22.901 16.9369 22.6645 16.863 22.4632 16.7246C22.2619 16.5861 22.1049 16.3892 22.012 16.1588L18.6578 7.55252C18.588 7.36554 18.4827 7.19479 18.3481 7.05026C18.2135 6.90573 18.0524 6.79032 17.874 6.71077C17.6957 6.63123 17.5037 6.58915 17.3095 6.587C17.1152 6.58485 16.9224 6.62266 16.7424 6.69824C16.5625 6.77381 16.399 6.88563 16.2614 7.02714C16.1238 7.16866 16.015 7.33704 15.9413 7.52243C15.8676 7.70782 15.8305 7.90651 15.8321 8.10688C15.8337 8.30725 15.8741 8.50527 15.9508 8.68937L21.7936 23.0746C21.9052 23.3486 22.0929 23.5825 22.3329 23.747C22.573 23.9115 22.8548 23.9993 23.143 23.9993C23.4312 23.9993 23.713 23.9115 23.9531 23.747C24.1931 23.5825 24.3808 23.3486 24.4924 23.0746L30.3352 8.68937C30.429 8.46238 30.4668 8.21502 30.4454 7.9693C30.4241 7.72358 30.3441 7.48712 30.2127 7.28095Z"
            fill="white"
          />
          <path
            d="M56.721 6.60991H46.9667C46.6071 6.5997 46.2582 6.73687 45.9965 6.99139C45.7348 7.2459 45.5815 7.597 45.5703 7.9678C45.5815 8.33878 45.7347 8.6901 45.9964 8.94494C46.258 9.19979 46.6069 9.33744 46.9667 9.3278H49.2124C49.5072 9.31813 49.7938 9.42915 50.0095 9.63663C50.2252 9.84411 50.3526 10.1312 50.3638 10.4352V22.6457C50.375 23.0167 50.5282 23.368 50.7898 23.6228C51.0515 23.8777 51.4004 24.0153 51.7602 24.0057H51.9194C52.279 24.0153 52.6277 23.8776 52.889 23.6227C53.1503 23.3678 53.3031 23.0165 53.3138 22.6457V10.4352C53.3249 10.1308 53.4526 9.84341 53.6687 9.63587C53.8849 9.42833 54.172 9.31756 54.4672 9.3278H56.7129C57.0727 9.33744 57.4215 9.19979 57.6832 8.94494C57.9449 8.6901 58.0981 8.33878 58.1093 7.9678C58.098 7.5985 57.9459 7.24869 57.686 6.99442C57.426 6.74014 57.0792 6.60196 56.721 6.60991Z"
            fill="white"
          />
          <path
            d="M87.1895 21.7782L84.2069 17.8203C84.1078 17.6898 84.0381 17.5383 84.0028 17.3766C83.9675 17.215 83.9674 17.0472 84.0027 16.8856C84.0354 16.7297 84.102 16.5835 84.1975 16.458C84.293 16.3326 84.4149 16.2311 84.5539 16.1614C85.348 15.7615 86.0142 15.1353 86.4737 14.357C86.9333 13.5787 87.1668 12.6808 87.1467 11.7698C87.1467 10.9466 86.9425 8.894 84.9929 7.63084C83.8823 6.90452 82.5104 6.60979 80.2586 6.60979H78.4212C78.0526 6.60071 77.6954 6.74239 77.4277 7.00389C77.16 7.26538 77.0034 7.62548 76.9922 8.00557V22.6035C77.0034 22.9836 77.16 23.3437 77.4277 23.6052C77.6954 23.8667 78.0526 24.0083 78.4212 23.9993H78.5049C78.8736 24.0083 79.2308 23.8667 79.4985 23.6052C79.7662 23.3437 79.9228 22.9836 79.934 22.6035V16.9845H80.228L84.8683 23.4077C85.0059 23.5931 85.1833 23.7431 85.3867 23.8458C85.59 23.9484 85.8137 24.001 86.0402 23.9993C86.3092 24.0043 86.5743 23.931 86.8047 23.7876C87.0351 23.6442 87.2216 23.4366 87.3426 23.1887C87.4508 22.9632 87.4939 22.7104 87.4667 22.4603C87.4396 22.2103 87.3434 21.9735 87.1895 21.7782ZM84.3008 11.7887C84.3008 14.1172 82.0163 14.4645 80.6547 14.4645H79.9279V9.27505H80.7343C83.0922 9.27505 84.3008 10.1235 84.3008 11.7887Z"
            fill="white"
          />
          <path
            d="M38.2332 5.18466C38.9113 5.19592 39.5659 4.92903 40.0533 4.44266C40.5406 3.95629 40.8207 3.29025 40.832 2.59098C40.813 1.89458 40.5315 1.23327 40.0471 0.747605C39.5628 0.261944 38.9138 -0.00976563 38.2383 -0.00976562C37.5627 -0.00976562 36.9138 0.261944 36.4294 0.747605C35.9451 1.23327 35.6635 1.89458 35.6445 2.59098C35.6558 3.2884 35.9345 3.95283 36.4196 4.4389C36.9048 4.92497 37.5568 5.19311 38.2332 5.18466Z"
            fill="#12C4C4"
          />
          <path
            d="M46.1235 21.9178L39.4151 7.44624C39.3185 7.20362 39.1542 6.99609 38.943 6.85003C38.7318 6.70397 38.4833 6.62598 38.2289 6.62598C37.9746 6.62598 37.7261 6.70397 37.5149 6.85003C37.3037 6.99609 37.1394 7.20362 37.0428 7.44624L30.3324 21.9178C30.2301 22.1377 30.1838 22.3808 30.1977 22.6242C30.2117 22.8677 30.2854 23.1034 30.412 23.3094C30.5512 23.5344 30.7464 23.7168 30.9772 23.8376C31.208 23.9584 31.466 24.0131 31.7243 23.9961C31.9826 23.9792 32.2318 23.8911 32.4459 23.741C32.6599 23.591 32.8311 23.3845 32.9414 23.1431L34.1786 20.3768C34.2751 20.1638 34.4285 19.9836 34.621 19.8576C34.8134 19.7315 35.0367 19.6648 35.2647 19.6652H41.1851C41.413 19.6648 41.6364 19.7315 41.8288 19.8576C42.0212 19.9836 42.1747 20.1638 42.2711 20.3768L43.5083 23.1431C43.6187 23.3845 43.7898 23.591 44.0039 23.741C44.2179 23.8911 44.4671 23.9792 44.7254 23.9961C44.9837 24.0131 45.2417 23.9584 45.4725 23.8376C45.7033 23.7168 45.8985 23.5344 46.0377 23.3094C46.1653 23.104 46.2401 22.8685 46.2551 22.625C46.2701 22.3816 46.2248 22.1382 46.1235 21.9178ZM36.4896 15.122L37.1551 13.6483C37.2504 13.4379 37.402 13.2598 37.592 13.1351C37.7821 13.0103 38.0027 12.944 38.2279 12.944C38.4532 12.944 38.6738 13.0103 38.8638 13.1351C39.0538 13.2598 39.2054 13.4379 39.3007 13.6483L39.9663 15.122C40.048 15.3037 40.084 15.5038 40.0708 15.7036C40.0576 15.9034 39.9956 16.0966 39.8907 16.2652C39.7832 16.4415 39.6341 16.5868 39.4574 16.6877C39.2807 16.7886 39.0822 16.8417 38.8802 16.842H37.5757C37.3747 16.8428 37.1767 16.7913 37.0001 16.6923C36.8234 16.5933 36.6739 16.4501 36.5651 16.2757C36.4588 16.1058 36.3961 15.9107 36.3828 15.7089C36.3696 15.5071 36.4064 15.3052 36.4896 15.122Z"
            fill="#12C4C4"
          />
          <path
            d="M8.03226 5.18466C8.70931 5.19423 9.36242 4.92659 9.84843 4.4404C10.3344 3.95421 10.6137 3.28914 10.625 2.59098C10.606 1.89458 10.3245 1.23327 9.8401 0.747605C9.35575 0.261944 8.70681 -0.00976562 8.03124 -0.00976562C7.35567 -0.00976562 6.70673 0.261944 6.22238 0.747605C5.73803 1.23327 5.45645 1.89458 5.4375 2.59098C5.44878 3.28951 5.72832 3.9549 6.21477 4.44115C6.70122 4.9274 7.35486 5.19479 8.03226 5.18466Z"
            fill="#FF6C6C"
          />
          <path
            d="M15.9241 21.9169L9.21975 7.44537C9.1228 7.20291 8.95822 6.9956 8.74687 6.84973C8.53552 6.70385 8.28693 6.62598 8.03261 6.62598C7.7783 6.62598 7.5297 6.70385 7.31836 6.84973C7.10701 6.9956 6.94243 7.20291 6.84548 7.44537L0.137075 21.9169C0.0348301 22.1368 -0.011507 22.3799 0.00242096 22.6234C0.0163489 22.8668 0.0900845 23.1026 0.216694 23.3085C0.355913 23.5335 0.551093 23.7159 0.781903 23.8367C1.01271 23.9575 1.27071 24.0123 1.52901 23.9953C1.78732 23.9783 2.03648 23.8902 2.25055 23.7402C2.46461 23.5901 2.63575 23.3836 2.74612 23.1422L3.98328 20.3759C4.07976 20.1629 4.23323 19.9828 4.42565 19.8567C4.61806 19.7306 4.8414 19.6639 5.06936 19.6643H10.9897C11.2177 19.6639 11.441 19.7306 11.6335 19.8567C11.8259 19.9828 11.9793 20.1629 12.0758 20.3759L13.313 23.1422C13.4234 23.3836 13.5945 23.5901 13.8086 23.7402C14.0226 23.8902 14.2718 23.9783 14.5301 23.9953C14.7884 24.0123 15.0464 23.9575 15.2772 23.8367C15.508 23.7159 15.7032 23.5335 15.8424 23.3085C15.9693 23.1027 16.0434 22.8671 16.0577 22.6236C16.072 22.3802 16.026 22.137 15.9241 21.9169ZM9.69542 16.2643C9.58576 16.4402 9.43455 16.5844 9.256 16.6835C9.07744 16.7825 8.87741 16.8332 8.67467 16.8306H7.38035C7.17934 16.8314 6.98138 16.7799 6.80475 16.6809C6.62813 16.5819 6.47854 16.4387 6.3698 16.2643C6.26525 16.0956 6.20353 15.9025 6.19033 15.7027C6.17713 15.5029 6.21287 15.3029 6.29427 15.1212L6.9598 13.6475C7.05513 13.437 7.20671 13.2589 7.39673 13.1342C7.58676 13.0094 7.80735 12.9432 8.03261 12.9432C8.25788 12.9432 8.47847 13.0094 8.66849 13.1342C8.85852 13.2589 9.01009 13.437 9.10543 13.6475L9.77096 15.1212C9.85272 15.3028 9.88867 15.5029 9.87547 15.7027C9.86226 15.9026 9.80033 16.0957 9.69542 16.2643Z"
            fill="#FF6C6C"
          />
          <path
            d="M65.9424 5.18397C66.6198 5.1941 67.2735 4.92671 67.7599 4.44046C68.2464 3.95421 68.5259 3.28882 68.5372 2.59028C68.5106 1.89899 68.2256 1.24513 67.7419 0.765722C67.2582 0.286312 66.6133 0.0185547 65.9424 0.0185547C65.2715 0.0185547 64.6267 0.286312 64.143 0.765722C63.6593 1.24513 63.3743 1.89899 63.3477 2.59028C63.3589 3.28882 63.6385 3.95421 64.1249 4.44046C64.6114 4.92671 65.265 5.1941 65.9424 5.18397Z"
            fill="#FF6C6C"
          />
          <path
            d="M73.8293 21.9163L67.1209 7.44678C67.0252 7.20334 66.8611 6.99492 66.6498 6.84818C66.4385 6.70143 66.1896 6.62305 65.9348 6.62305C65.6801 6.62305 65.4311 6.70143 65.2198 6.84818C65.0085 6.99492 64.8445 7.20334 64.7487 7.44678L58.0403 21.9163C57.9378 22.136 57.8911 22.379 57.9046 22.6224C57.9182 22.8658 57.9916 23.1017 58.1179 23.3078C58.2571 23.5328 58.4523 23.7152 58.6831 23.836C58.9139 23.9568 59.1719 24.0116 59.4302 23.9946C59.6885 23.9776 59.9377 23.8895 60.1517 23.7395C60.3658 23.5894 60.5369 23.3829 60.6473 23.1415L61.8845 20.3752C61.9809 20.1622 62.1344 19.9821 62.3268 19.856C62.5192 19.7299 62.7426 19.6632 62.9705 19.6636H68.8909C69.1191 19.6633 69.3427 19.7301 69.5354 19.8561C69.7281 19.9821 69.882 20.1622 69.979 20.3752L71.2162 23.1415C71.3263 23.383 71.4972 23.5896 71.7111 23.7397C71.925 23.8898 72.174 23.9779 72.4322 23.9949C72.6904 24.0119 72.9483 23.9571 73.179 23.8363C73.4097 23.7154 73.6047 23.5329 73.7436 23.3078C73.8712 23.1024 73.946 22.8669 73.961 22.6235C73.976 22.38 73.9307 22.1367 73.8293 21.9163ZM67.5966 16.2636C67.4874 16.4399 67.3362 16.5845 67.1576 16.6836C66.9789 16.7827 66.7787 16.8331 66.5759 16.8299H65.2713C65.0703 16.8307 64.8724 16.7792 64.6957 16.6802C64.5191 16.5812 64.3695 16.438 64.2608 16.2636C64.1562 16.0949 64.0946 15.9016 64.0818 15.7018C64.0689 15.5019 64.1052 15.302 64.1873 15.1205L64.8508 13.6468C64.9461 13.4363 65.0977 13.2582 65.2877 13.1335C65.4777 13.0087 65.6983 12.9425 65.9236 12.9425C66.1489 12.9425 66.3694 13.0087 66.5595 13.1335C66.7495 13.2582 66.9011 13.4363 66.9964 13.6468L67.6619 15.1205C67.7458 15.3011 67.7837 15.5008 67.7723 15.7007C67.7609 15.9007 67.7004 16.0944 67.5966 16.2636Z"
            fill="#FF6C6C"
          />
          <path
            d="M93.1934 6.68652C92.7987 6.68708 92.4203 6.84889 92.1409 7.13652C91.8616 7.42416 91.7042 7.8142 91.7031 8.22126V22.4655C91.7174 22.8629 91.8805 23.2392 92.1583 23.5152C92.436 23.7912 92.8067 23.9454 93.1924 23.9454C93.5781 23.9454 93.9488 23.7912 94.2265 23.5152C94.5043 23.2392 94.6674 22.8629 94.6817 22.4655V8.22126C94.6811 7.81439 94.5242 7.42435 94.2452 7.13665C93.9662 6.84895 93.588 6.68708 93.1934 6.68652Z"
            fill="#12C4C4"
          />
          <path
            d="M93.0635 -0.000698198C92.3864 -0.0108356 91.7331 0.25627 91.2467 0.742067C90.7603 1.22786 90.4806 1.89271 90.4688 2.59088C90.4953 3.28218 90.7804 3.93603 91.2641 4.41544C91.7478 4.89485 92.3926 5.16261 93.0635 5.16261C93.7344 5.16261 94.3792 4.89485 94.8629 4.41544C95.3466 3.93603 95.6316 3.28218 95.6582 2.59088C95.6464 1.89271 95.3667 1.22786 94.8803 0.742067C94.3939 0.25627 93.7405 -0.0108356 93.0635 -0.000698198Z"
            fill="#12C4C4"
          />
          <path
            d="M136.245 21.921L130.764 7.53569C130.67 7.26488 130.497 7.03061 130.269 6.86483C130.041 6.69905 129.769 6.60984 129.49 6.60938C129.21 6.60872 128.936 6.69736 128.707 6.86317C128.477 7.02899 128.303 7.26387 128.208 7.53569L122.726 21.921C122.64 22.1585 122.61 22.4142 122.64 22.6662C122.671 22.9181 122.76 23.1587 122.9 23.3673C123.021 23.5598 123.187 23.7182 123.382 23.8285C123.577 23.9387 123.796 23.9972 124.019 23.9989C124.301 23.996 124.575 23.9037 124.804 23.7346C125.034 23.5655 125.207 23.3278 125.301 23.0536L128.447 14.4452C128.521 14.2173 128.662 14.0191 128.851 13.8786C129.04 13.7382 129.267 13.6626 129.5 13.6626C129.733 13.6626 129.96 13.7382 130.149 13.8786C130.338 14.0191 130.48 14.2173 130.554 14.4452L133.7 23.0536C133.793 23.3278 133.967 23.5655 134.196 23.7346C134.425 23.9037 134.7 23.996 134.982 23.9989C135.204 23.9976 135.423 23.9392 135.618 23.829C135.814 23.7187 135.979 23.56 136.1 23.3673C136.236 23.1562 136.32 22.9144 136.345 22.6625C136.37 22.4106 136.336 22.1562 136.245 21.921Z"
            fill="white"
          />
          <path
            d="M121.22 5.18218C122.653 5.18218 123.815 4.02189 123.815 2.5906C123.815 1.15931 122.653 -0.000976562 121.22 -0.000976562C119.787 -0.000976562 118.625 1.15931 118.625 2.5906C118.625 4.02189 119.787 5.18218 121.22 5.18218Z"
            fill="#FF6C6C"
          />
          <path
            d="M127.975 21.9239L122.491 7.55132C122.396 7.28101 122.223 7.04725 121.995 6.88159C121.768 6.71594 121.496 6.62637 121.217 6.625C120.938 6.62577 120.666 6.7151 120.439 6.88083C120.211 7.04657 120.038 7.28068 119.943 7.55132L114.462 21.9239C114.371 22.1608 114.338 22.4169 114.365 22.6701C114.392 22.9233 114.478 23.1658 114.617 23.3766C114.738 23.5693 114.904 23.728 115.099 23.8383C115.294 23.9485 115.513 24.0069 115.736 24.0082C116.018 24.005 116.292 23.9126 116.521 23.7436C116.75 23.5745 116.924 23.3369 117.018 23.0629L120.164 14.465C120.239 14.2372 120.381 14.0394 120.571 13.8993C120.761 13.7592 120.988 13.6839 121.221 13.6839C121.454 13.6856 121.68 13.7618 121.869 13.9022C122.058 14.0425 122.2 14.2399 122.275 14.4671L125.421 23.065C125.514 23.339 125.687 23.5767 125.916 23.7458C126.145 23.9149 126.419 24.0073 126.701 24.0103C126.924 24.009 127.143 23.9506 127.338 23.8404C127.534 23.7301 127.7 23.5715 127.822 23.3787C127.96 23.1675 128.047 22.9245 128.073 22.6709C128.1 22.4174 128.066 22.1609 127.975 21.9239Z"
            fill="#FF6C6C"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="136.35" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}
