import * as React from 'react'

interface MenuProps {
  height?: string
  width?: string
}
const MenuCalendar: React.FC<MenuProps> = ({ height, width }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 4.16309H4C3.46973 4.16363 2.96133 4.38104 2.58637 4.76759C2.21141 5.15414 2.00053 5.67827 2 6.22494V18.9397C2.00053 19.4864 2.21141 20.0105 2.58637 20.397C2.96133 20.7836 3.46973 21.001 4 21.0015H20C20.5303 21.001 21.0387 20.7836 21.4136 20.397C21.7886 20.0105 21.9995 19.4864 22 18.9397V6.22494C21.9995 5.67827 21.7886 5.15414 21.4136 4.76759C21.0387 4.38104 20.5303 4.16363 20 4.16309ZM21.3333 18.9397C21.3333 19.3042 21.1929 19.6539 20.9428 19.9116C20.6928 20.1694 20.3536 20.3143 20 20.3143H4C3.64638 20.3143 3.30724 20.1694 3.05719 19.9116C2.80714 19.6539 2.66667 19.3042 2.66667 18.9397V8.63043H21.3333V18.9397Z"
        fill="#9496AB"
      />
      <path
        d="M17.7212 1.67077C17.7212 1.49313 17.6502 1.32277 17.524 1.19716C17.3978 1.07154 17.2266 1.00098 17.0481 1.00098C16.8696 1.00098 16.6984 1.07154 16.5721 1.19716C16.4459 1.32277 16.375 1.49313 16.375 1.67077V3.34526H17.7212V1.67077Z"
        fill="#9496AB"
      />
      <path
        d="M7.6235 1.67077C7.6235 1.49313 7.55258 1.32277 7.42636 1.19716C7.30013 1.07154 7.12893 1.00098 6.95042 1.00098C6.77191 1.00098 6.60071 1.07154 6.47448 1.19716C6.34826 1.32277 6.27734 1.49313 6.27734 1.67077V3.34526H7.6235V1.67077Z"
        fill="#9496AB"
      />
      <path
        d="M10.5636 15.2193L11.2622 15.9591C12.3439 15.4199 13.2455 14.6553 13.8817 13.7378L13.0084 13.1451C12.9061 13.0756 12.8338 12.9792 12.8028 12.8712C12.7718 12.7631 12.7838 12.6494 12.837 12.5477L13.633 11.0268C13.69 10.9178 13.7909 10.8294 13.9176 10.7776C14.0443 10.7257 14.1886 10.7137 14.3247 10.7437L15.8401 11.0772C15.9815 11.1086 16.1044 11.1829 16.1855 11.286C16.2667 11.389 16.3005 11.5138 16.2805 11.6366C16.0146 13.2433 15.1394 14.7328 13.7873 15.8801C12.4352 17.0273 10.6797 17.7699 8.78608 17.9955C8.64133 18.0125 8.49427 17.9838 8.37276 17.9149C8.25126 17.8461 8.16375 17.7418 8.12681 17.6218L7.7337 16.3362C7.69842 16.2207 7.71258 16.0983 7.77365 15.9908C7.83473 15.8833 7.93876 15.7976 8.06725 15.7491L9.85944 15.0737C9.97927 15.0285 10.1134 15.0183 10.2408 15.0447C10.3682 15.071 10.4817 15.1324 10.5636 15.2193Z"
        fill="#9496AB"
      />
      <defs>
        <clipPath id="clip0">
          <rect width="10" height="8" fill="white" transform="matrix(-1 0 0 1 17 10)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MenuCalendar
