import React from 'react'

interface CloseIconProps {
  height?: string
  width?: string
}

const CloseIcon: React.FC<CloseIconProps> = ({ height, width }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2L16 16"
        stroke="#111947"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 16L16 2"
        stroke="#111947"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CloseIcon
