import React from 'react'

interface DropdownIconDownProps {
  height?: string
  width?: string
}

const DropdownIconDown: React.FC<DropdownIconDownProps> = ({ height, width }) => {
  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1L7 7L13 1"
        stroke="#111947"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DropdownIconDown
