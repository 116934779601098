import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ROUTER_URL } from '../../../../utils/const'
import { RE_DOWNLOAD_AVATAR } from '../../../../utils/endpoints'
import { saveBlobAsFile } from '../../../../utils/file'
import ReDownloadResult from './result'
import ReDownloadLoading from './loading'
import axios from '../../../../utils/axios'

interface ReDownloadProps {
}

const ReDownload: React.FC<ReDownloadProps> = () => {
  const DISPLAY_STATUS: {[key: string]: number} = {"nologin": 1, "loading": 2, "success": 3, "error": 4}
  const location: any = useLocation()

  const isSignedIn = useSelector((state: any) => state.signin.isSignedIn)
  const [displayStatus, setDisplayStatus] = useState(DISPLAY_STATUS["loading"])

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const avatar_id = urlParams.get('avatar_id')
    const avatar_type = urlParams.get('avatar_type')
    const edit_type = urlParams.get('edit_type')
    const file_format = urlParams.get('file_format')
    const key = urlParams.get('key')
    const param = { avatar_id, avatar_type, edit_type, file_format, key }
    if (!isSignedIn && !localStorage.getItem('access_token')) {
      setDisplayStatus(DISPLAY_STATUS["nologin"])
    } else {
      setDisplayStatus(DISPLAY_STATUS["loading"])
      onDownload(param)
    }
  }, [])

  async function onDownload(param: any) {
    try {
      const response = await axios.get(RE_DOWNLOAD_AVATAR, {
        params: param,
        responseType: 'blob'
      })
      if (response) {
        saveBlobAsFile(response.data, response.headers['content-disposition'].split('=').pop())
      }
      setDisplayStatus(DISPLAY_STATUS["success"])
    } catch (error) {
      setDisplayStatus(DISPLAY_STATUS["error"])
    }
  }

  const RenderStatus = ({status}: {status: number}) => {

    switch(status) {
      case DISPLAY_STATUS["loading"]:
        return <ReDownloadLoading />
      case DISPLAY_STATUS["nologin"]:
        return <ReDownloadResult
                  btn_text='re_download.login_btn'
                  btn_link={ROUTER_URL.SIGNIN}
                  content_title='re_download.nologin_title'
                  content1='re_download.nologin_content1'
                  content2='re_download.nologin_content2' />
      case DISPLAY_STATUS["success"]:
        return <ReDownloadResult
                  btn_text='re_download.avatar_management_btn'
                  btn_link={ROUTER_URL.CONTROL_AVATAR}
                  content_title='re_download.success_title'
                  content1='re_download.success_content1'
                  content2='re_download.success_content2' />
      case DISPLAY_STATUS["error"]:
        return <ReDownloadResult
                  btn_text='re_download.avatar_management_btn'
                  btn_link={ROUTER_URL.CONTROL_AVATAR}
                  content_title='re_download.error_title'
                  content1='re_download.error_content1'
                  content2='re_download.error_content2' />
      default:
        return <ReDownloadLoading />
    }
  }
  return (
    <RenderStatus status={displayStatus} />
  )
}
export default ReDownload
