import { Fragment } from 'react'
interface ItemProps {
  icon: any
  title: string
}

const Item: React.FC<ItemProps> = ({ icon, title }) => {
  return (
    <Fragment>
      <span className="content__body--part__item--icon"> {icon}</span>
      <span className="content__body--part__item--title">{title}</span>
    </Fragment>
  )
}

export default Item
