import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const CheckBooking: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '33'}
      height={height || '33'}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33Z"
        fill="#12C4C4"
      />
      <path
        d="M24.4622 11.0254L13.5122 21.9744L8.53516 16.9974"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckBooking
