import { AnyAction, Reducer } from 'redux';
import { initialListFriend, FriendState } from '../states';
import { FriendTypes } from '../actions';

export const ListFriendReducer: Reducer = (state: FriendState = initialListFriend, action: AnyAction) => {
    switch (action.type) {
        case FriendTypes.GET_ALL_LIST_FRIEND:
            return {
                friend: true,
                received_request: false,
                sent_request: false,
                data: action.payload
            }
        case FriendTypes.GET_RECEIVED_FRIEND:
            return {
                friend: false,
                received_request: true,
                sent_request: false,
                data: action.payload
            }
        case FriendTypes.GET_SENT_FRIEND:
            return {
                friend: false,
                received_request: false,
                sent_request: true,
                data: action.payload
            }
        default:
            return state
    }
};
