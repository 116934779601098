import { IMessageType } from './actions'

export const initialRequestState: IAppRequestState = {
  data: null,
  isFetching: false,
  error: '',
}

export interface IAppRequestState {
  data: any
  isFetching: boolean
  error: string
}

export interface ISigninState {
  data: any
  isFetching: boolean
  error: string
  isSignedIn: boolean
}

export const initialSigninState: ISigninState = {
  data: null,
  isFetching: false,
  error: '',
  isSignedIn: false,
}

export interface IDisplayMessageState {
  message: string
  type: IMessageType
}

export const initialDisplayMessageState: IDisplayMessageState = {
  type: 'success',
  message: '',
}

export interface TabBookingState {
  message: number
}

export const initialTabState: TabBookingState = {
  message: 1
}

export interface SaveHistoryBookingState {
  history: any
}

export const initialHistoryState: SaveHistoryBookingState = {
  history: null
}

export interface AvatarState {
  empty: boolean,
  origin: boolean,
  edited: boolean,
  data: any
}

export const initialListAvatar : AvatarState = {
  empty: false,
  origin: false,
  edited: false,
  data: null
}
export interface DownloadState {
  FPX: boolean,
  All: boolean,
  PLY: boolean,
  data: any
}
export const initialListTypeDownLoad : DownloadState = {
  FPX: false,
  All: false,
  PLY: false,
  data: null
}

export interface SaveInfoCreaditState {
  data: any
}

export const initialSaveInfoCreadit : SaveInfoCreaditState = {
  data: null
}


export interface IApplicationState {
  signin: ISigninState
  systemMessage: IDisplayMessageState,
  tab: TabBookingState,
  history: SaveHistoryBookingState,
  listAvatar: AvatarState,
  download: DownloadState,
  creadit: SaveInfoCreaditState,
  listFriend: FriendState
}


export interface FriendState {
  friend: boolean,
  received_request: boolean,
  sent_request: boolean,
  data: any
}

export const initialListFriend : FriendState = {
  friend: false,
  received_request: false,
  sent_request: false,
  data: null
}