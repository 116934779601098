import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SecondaryContentComponent from './secondary-content-component'
import Wrapper from '../../../components/modules/_wrapper'
import { NEWS_DETAIL, NEWS_RECOMMEND } from '../../../../../utils/endpoints'
import { ERROR_TYPE, GET, ROUTER_URL } from '../../../../../utils/const'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { createPortal } from 'react-dom'

interface DetailNewsProps {
  id: string
  notify?: Function
  callApi: any
}
const DetailNews: React.FC<DetailNewsProps> = ({ notify = () => {}, callApi }) => {
  const [, i18n] = useTranslation('translation')
  const [detailNew, setDetailNew] = useState<any>({})
  const [listRecommend, setListRecommend] = useState([])
  const url_string = window.location.href
  const id = url_string.substring(url_string.lastIndexOf('/') + 1)
  const history = useHistory()

  const list = [
    {
      name: i18n.t('news.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('news.news-list'),
      link: ROUTER_URL.NEWS,
    },
    {
      name: detailNew?.title,
      link: `${ROUTER_URL.NEWS}/${id}`,
    },
  ]
  useEffect(() => {
    getRecommendNew()
    getDetailNew()
  }, [id, localStorage.getItem('i18nextLng')])

  const getDetailNew = async () => {
    try {
      const result = await callApi(`${NEWS_DETAIL}/${id}`)
      if (result.status === 200) {
        setDetailNew(result.data.data)

        const title = result.data.data.title +' | ニュース | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
        const description = result.data.data.content.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'').substr(0,150);
        
        const og_image = result.data.data.thumbnail;

        const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';

        // title
        document.title = title;

        // title以外のmeta
        const headData = document.head.children;

        for (let i = 0; i < headData.length; i++) {
          const nameVal = headData[i].getAttribute('name');
          const propertyVal = headData[i].getAttribute('property');
          if (nameVal !== null) {
            if (nameVal.indexOf('keywords') !== -1) {
              headData[i].setAttribute('content', keywords);
            }
            if (nameVal.indexOf('description') !== -1) {
              headData[i].setAttribute('content', description);
            }
          }
          if (propertyVal !== null) {
            // OGP(og:title)の設定
            if (propertyVal.indexOf('og:title') !== -1) {
              headData[i].setAttribute('content', title);
            }
            if (propertyVal.indexOf('og:description') !== -1) {
              headData[i].setAttribute('content', description);
            }
            if (propertyVal.indexOf('og:image') !== -1) {
              headData[i].setAttribute('content', og_image);
            }
          }
        }
        
      }
    } catch (error) {
      if (error?.message) {
        history.push(ROUTER_URL.NEWS)
      }
    }
  }

  const getRecommendNew = async () => {
    const params = {
      news_id: id,
    }
    try {
      const result = await callApi(NEWS_RECOMMEND, GET, { params })
      if (result.status === 200) {
        setListRecommend(result.data.data.list_news)
      }
    } catch (error) {
    }
  }

  const onDirect = (id: any) => () => {
    if (window.navigator.onLine) history.push(`${ROUTER_URL.NEWS}/${id}`)
    else {
      notify(i18n.t('notifications.err_network'), ERROR_TYPE)
    }
  }
  return (
    <Wrapper className="detail-news" breadcrum={list}>
      <div className="detail-news__container">
        <div className="detail-news__primary">
          <div className="detail-news__header">
            <h1 className="title-h1">{detailNew['title']}</h1>
            <p className="detail-news__date ">
              {localStorage.getItem('i18nextLng') === 'ja'
                ? moment(new Date(detailNew['display_from_date'])).format('YYYY年MM月DD日')
                : moment(new Date(detailNew['display_from_date'])).format('MM/DD/YYYY')}
            </p>
          </div>
          <div
            className="detail-news__body"
            dangerouslySetInnerHTML={{ __html: detailNew['content'] }}
          ></div>

          <div className="detail-news__foot">
            <Link to={ROUTER_URL.NEWS} style={{ textDecoration: 'none' }}>
              <button className="button-custom detail-news__back-button ">
                {i18n.t('detail-news.return')}
              </button>
            </Link>
          </div>
        </div>
        <div className="detail-news__secondary">
          {listRecommend?.map((item) => (
            <SecondaryContentComponent
              key={item['id']}
              id={item['id']}
              date={item['display_from_date']}
              title={item['title']}
              thumbnail={item['thumbnail']}
              onDirect={onDirect(item['id'])}
            />
          ))}
        </div>
      </div>
    </Wrapper>
  )
}

export default DetailNews

export const Iframe = ({ children, styleSelector, title, ...props }: any) => {
  const [contentRef, setContentRef] = useState<any>(null)
  const [height, setHeight] = useState<any>()
  const mountNode = contentRef?.contentWindow?.document.body

  useEffect(() => {
    if (!contentRef) {
      return
    }
    const win = contentRef?.contentWindow
    const linkEls = win?.parent?.document?.querySelectorAll(styleSelector)
    if (!!linkEls?.length) {
      linkEls.forEach((el: any) => {
        win?.document?.head?.appendChild(el)
      })
    }
  }, [contentRef, styleSelector])

  function onInit() {
    const win = contentRef?.contentWindow
    setHeight(win?.document?.body?.scrollHeight + 'px')
  }

  return (
    <iframe title={title} {...props} ref={setContentRef} style={{ height }} onLoad={onInit}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  )
}
