import React from 'react'

interface BarCodeCustomizationProps {
  height?: string
  width?: string
}

const BarCodeCustomization: React.FC<BarCodeCustomizationProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "140"} height={height || "66"} viewBox="0 0 140 66">
      <defs>
        <style>
          {'.cls-1,.cls-3{isolation:isolate}.cls-2,.cls-3{fill:#12c4c4}.cls-3{opacity:.5}'}
        </style>
      </defs>
      <g id="グループ_10227" transform="translate(-1579 -244)">
        <g id="No-le114" className="cls-1">
          <g id="Group_10659" className="cls-1">
            <path
              id="Path_973"
              d="M1585.949 244.078v6.844h-.81l-4.151-5.1v5.1H1580v-6.844h.81l4.151 5.1v-5.1z"
              className="cls-2"
            />
            <path
              id="Path_974"
              d="M1589.828 247.5a3.68 3.68 0 1 1 3.686 3.5 3.5 3.5 0 0 1-3.686-3.5zm6.364 0a2.689 2.689 0 1 0-2.678 2.63 2.584 2.584 0 0 0 2.678-2.63z"
              className="cls-2"
            />
            <path id="Path_975" d="M1600.654 247.832h2.659v.811h-2.659z" className="cls-2" />
            <path
              id="Path_976"
              d="M1607.279 244.078h.988v5.994h3.745v.85h-4.733z"
              className="cls-2"
            />
            <path
              id="Path_977"
              d="M1620.535 250.072v.85h-5.02v-6.844h4.882v.85h-3.897v2.1h3.47v.831h-3.47v2.21z"
              className="cls-2"
            />
            <path
              id="Path_978"
              d="M1626.084 244.078v6.844h-.979v-5.994h-1.561v-.85z"
              className="cls-2"
            />
            <path
              id="Path_979"
              d="M1632.1 244.078v6.844h-.978v-5.994h-1.562v-.85z"
              className="cls-2"
            />
            <path
              id="Path_980"
              d="M1642 249.24h-1.314v1.682h-.959v-1.682h-3.844v-.684l3.538-4.478h1.067l-3.388 4.322h2.658v-1.487h.929v1.487H1642z"
              className="cls-2"
            />
          </g>
        </g>
        <g id="グループ_173">
          <path
            id="長方形_59"
            d="M0 0H1.013V50H0z"
            className="cls-3"
            transform="translate(1579 260)"
          />
          <path
            id="長方形_58"
            d="M0 0H0.554V50H0z"
            className="cls-3"
            transform="translate(1582.593 260)"
          />
          <path
            id="長方形_32"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1586.187 260)"
          />
          <path
            id="長方形_54"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1589.781 260)"
          />
          <path
            id="長方形_55"
            d="M0 0H1.013V50H0z"
            className="cls-3"
            transform="translate(1593.375 260)"
          />
          <path
            id="長方形_56"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1597.428 260)"
          />
          <path
            id="長方形_57"
            d="M0 0H7.757V50H0z"
            className="cls-3"
            transform="translate(1601.021 260)"
          />
          <path
            id="長方形_50"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1611.818 260)"
          />
          <path
            id="長方形_51"
            d="M0 0H1.013V50H0z"
            className="cls-2"
            transform="translate(1615.412 260)"
          />
          <path
            id="長方形_52"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1619.465 260)"
          />
          <path
            id="長方形_53"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1623.059 260)"
          />
          <path
            id="長方形_42"
            d="M0 0H2.026V50H0z"
            className="cls-3"
            transform="translate(1626.653 260)"
          />
          <path
            id="長方形_43"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1631.719 260)"
          />
          <path
            id="長方形_44"
            d="M0 0H1.108V50H0z"
            className="cls-2"
            transform="translate(1635.313 260)"
          />
          <path
            id="長方形_45"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1639.46 260)"
          />
          <path
            id="長方形_46"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1643.054 260)"
          />
          <path
            id="長方形_47"
            d="M0 0H2.217V50H0z"
            className="cls-2"
            transform="translate(1646.648 260)"
          />
          <path
            id="長方形_48"
            d="M0 0H1.108V50H0z"
            className="cls-3"
            transform="translate(1651.905 260)"
          />
          <path
            id="長方形_49"
            fill="#12c4c4"
            d="M0 0H5.541V50H0z"
            opacity="0.3"
            transform="translate(1656.052 260)"
            style={{ isolation: 'isolate' }}
          />
          <path
            id="長方形_31"
            d="M0 0H1.108V50H0z"
            className="cls-2"
            transform="translate(1664.633 260)"
          />
          <path
            id="長方形_41"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1668.781 260)"
          />
          <path
            id="長方形_40"
            d="M0 0H1.013V50H0z"
            className="cls-3"
            transform="translate(1672.375 260)"
          />
          <path
            id="長方形_30"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1676.428 260)"
          />
          <path
            id="長方形_29"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1680.022 260)"
          />
          <path
            id="長方形_28"
            d="M0 0H1.013V50H0z"
            className="cls-2"
            transform="translate(1683.616 260)"
          />
          <path
            id="長方形_27"
            d="M0 0H1.013V50H0z"
            className="cls-3"
            transform="translate(1687.669 260)"
          />
          <path
            id="長方形_374"
            d="M0 0H2.026V50H0z"
            className="cls-3"
            transform="translate(1691.722 260)"
          />
          <path
            id="長方形_375"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1696.788 260)"
          />
          <path
            id="長方形_376"
            d="M0 0H1.108V50H0z"
            className="cls-2"
            transform="translate(1700.381 260)"
          />
          <path
            id="長方形_377"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1704.529 260)"
          />
          <path
            id="長方形_378"
            d="M0 0H0.554V50H0z"
            className="cls-2"
            transform="translate(1708.123 260)"
          />
          <path
            id="長方形_379"
            d="M0 0H2.217V50H0z"
            className="cls-2"
            transform="translate(1711.717 260)"
          />
          <path
            id="長方形_26"
            d="M0 0H2.026V50H0z"
            className="cls-2"
            transform="translate(1716.974 260)"
          />
        </g>
      </g>
    </svg>
  )
}

export default BarCodeCustomization
