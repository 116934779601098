import * as React from 'react';

interface LocationIconProps {
   height?: string;
   width?: string;
};
const LocationIcon : React.FC<LocationIconProps> = ({ height, width }) => {
   return (
      <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M18.5673 5.48837C17.113 3.25581 14.7123 2 12.0115 2C9.33383 2 6.93313 3.25581 5.43269 5.48837C3.93224 7.67442 3.58599 10.4651 4.50934 12.907C4.76326 13.5581 5.15568 14.2326 5.66352 14.8372L11.5037 21.7674C11.6422 21.907 11.7807 22 11.9885 22C12.1962 22 12.3347 21.907 12.4732 21.7674L18.3365 14.8372C18.8443 14.2326 19.2598 13.5814 19.4907 12.907C20.414 10.4651 20.0678 7.67442 18.5673 5.48837ZM12.0115 13.7209C10.0263 13.7209 8.3874 12.0698 8.3874 10.0698C8.3874 8.06977 10.0263 6.4186 12.0115 6.4186C13.9967 6.4186 15.6357 8.06977 15.6357 10.0698C15.6357 12.0698 14.0198 13.7209 12.0115 13.7209Z" fill="#9496AB "/>
      </svg>
   );
};

export default LocationIcon