import React from 'react'

interface WomanProps {
  height?: string
  width?: string
}

const Woman: React.FC<WomanProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="72" viewBox="0 0 80 72">
      <g id="Group_10879" data-name="Group 10879" transform="translate(-160 -1978.699)">
        <g id="長方形_3520" data-name="長方形 3520">
          <rect
            id="Rectangle_3546"
            data-name="Rectangle 3546"
            width="80"
            height="72"
            transform="translate(160 1978.699)"
            fill="none"
          />
        </g>
        <g id="Group_10878" data-name="Group 10878">
          <path
            id="Path_2009"
            data-name="Path 2009"
            d="M215.681,2009.381a7.624,7.624,0,0,0,6.47-4.31c1.113-2.34,1.112-5.076,1.112-7.348v-1.5h-8.942a16.975,16.975,0,0,0-31.642,0h-6.441l-1.5.075v1.5c0,2.2,0,4.933,1.112,7.273a7.362,7.362,0,0,0,5.4,4.141,13.869,13.869,0,0,1-3.239,7.587c-2.164,2.66-5.677,7.884-4.9,12.984a9.592,9.592,0,0,0,3.926,6.274,40.215,40.215,0,0,0-1.147,7.531l3,.077a38,38,0,0,1,2.838-12.413c3.279-7.593,8.731-11.579,16.2-11.882.192.007.38.03.574.03.284,0,.562-.029.843-.043,7.686.166,13.281,4.159,16.623,11.895a38.044,38.044,0,0,1,2.839,12.413l3-.077a40.312,40.312,0,0,0-1.163-7.592,8.975,8.975,0,0,0,3.639-5.793c.727-4.755-2.682-9.6-5.31-12.83A14.035,14.035,0,0,1,215.681,2009.381Zm-16.834,6.96c-.293,0-.567.023-.853.033a14.011,14.011,0,0,1-13.422-12.545c0-.321.006-.641.006-.975h-.055c0-.152-.023-.3-.023-.454,0-.135.016-.265.02-.4a20.725,20.725,0,0,0,11.535-6.055,22.189,22.189,0,0,0,16.214,8.919,14.006,14.006,0,0,1-12.963,11.494C199.151,2016.356,199.005,2016.341,198.847,2016.341Zm21.4-17.118a11.564,11.564,0,0,1-.811,4.56c-.472.993-1.4,2.264-3.981,2.609q-.041-1.05-.049-2.251a16.688,16.688,0,0,0-.22-4.918ZM198.5,1988.4a14,14,0,0,1,13.973,13.475,19.151,19.151,0,0,1-14.973-9.16l-1.115-1.849-1.344,1.69a17.777,17.777,0,0,1-10.074,6.318A14.012,14.012,0,0,1,198.5,1988.4Zm-19.94,15.383a11.545,11.545,0,0,1-.81-4.56h4.059a16.967,16.967,0,0,0-.309,3.177c0,.476.033.945.072,1.412-.009.849-.03,1.638-.064,2.373A4.275,4.275,0,0,1,178.56,2003.783Zm-.6,29.11a6.243,6.243,0,0,1-1.878-3.563c-.442-2.89,1.11-6.767,4.258-10.638a16.671,16.671,0,0,0,3.631-7.519,17.082,17.082,0,0,0,7.127,6.505C183.718,2020.442,179.912,2027.039,177.956,2032.893Zm43.358-3.143a5.534,5.534,0,0,1-1.6,3.066c-2-5.937-5.894-12.626-13.493-15.289a17.09,17.09,0,0,0,6.715-6.19,16.729,16.729,0,0,0,3.709,7.929C220.2,2023.636,221.725,2027.065,221.314,2029.75Z"
            fill="#12c4c4"
          />
          <path
            id="Path_2010"
            data-name="Path 2010"
            d="M198.2,2030.255l-6.806-6.044-1.328,1.5,8.06,7.157,9-7.121-1.241-1.568Z"
            fill="#12c4c4"
          />
        </g>
      </g>
    </svg>
  )
}

export default Woman
