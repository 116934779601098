import React from 'react'

interface EditIconProps {
  height?: string
  width?: string
}

const EditIcon: React.FC<EditIconProps> = ({ height, width }) => {
  return (
    <svg
      id="edit_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path id="Path_131" data-name="Path 131" d="M0,0H16V16H0Z" fill="none" />
      <path
        id="Path_132"
        data-name="Path 132"
        d="M3,12.5V15H5.5l7.373-7.373-2.5-2.5ZM14.807,5.693a.664.664,0,0,0,0-.94l-1.56-1.56a.664.664,0,0,0-.94,0l-1.22,1.22,2.5,2.5,1.22-1.22Z"
        transform="translate(-1 -0.999)"
        fill="#fff"
      />
    </svg>
  )
}

export default EditIcon
