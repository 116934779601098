import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootSaga } from './sagas';
import { ApplicationState } from './reducers';

const initialAppState = {};
const configureApplicationStore = (initialState = initialAppState) => {
    // create the composing function for our middlewares
    const composeEnhancers = composeWithDevTools({});
    // create the redux-saga middleware
    const sagaMiddleware = createSagaMiddleware();

    const appStore = createStore(ApplicationState, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));

    sagaMiddleware.run(rootSaga);

    return appStore;
};

export default configureApplicationStore;

export * from './actions';
export * from './states';