import React from 'react'

interface ShieldProps {
  height?: string
  width?: string
}

const Shield: React.FC<ShieldProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="72" viewBox="0 0 80 72">
      <g id="Group_10882" data-name="Group 10882" transform="translate(-360 -1978.699)">
        <g id="長方形_3521" data-name="長方形 3521">
          <rect
            id="Rectangle_3548"
            data-name="Rectangle 3548"
            width="80"
            height="72"
            transform="translate(360 1978.699)"
            fill="none"
          />
        </g>
        <g id="Group_10881" data-name="Group 10881">
          <path
            id="Path_2012"
            data-name="Path 2012"
            d="M399.986,1987.2l-22.745,4.821.068,4.194c.023,1.277.244,12.765,1.224,22.408a29.131,29.131,0,0,0,2.077,8.559l-7.486,7.485,6.929,6.93,6.312-6.312a31.52,31.52,0,0,0,12.258,6.259l1.372.4,1.374-.4c12.023-3.488,18.588-10.94,20.07-22.783.65-5.2.958-11.546,1.105-16.152l1.967-1.882.9-11.394-10.6.841Zm-18.466,31.146c-.986-9.712-1.2-21.679-1.211-22.182l-.028-1.721,19.721-4.18,11.741,2.354L385.82,2019.7l-.737-.738-2.122,2.121,1.873,1.872-1.917,1.917A26.3,26.3,0,0,1,381.52,2018.346Zm-4.154,16.321,9.589-9.589,2.687,2.688-9.589,9.589Zm41.1-16.279c-1.318,10.524-7.183,17.156-17.928,20.273l-.54.157-.538-.156a28.869,28.869,0,0,1-10.969-5.5l3.275-3.275,1.9,1.9,2.121-2.121-.739-.739,24.386-23.34C419.258,2009.64,418.964,2014.38,418.463,2018.388Zm3.684-25.786-.536,6.747-28.687,27.456-1.8-1.8,13.27-13.27-1.414-1.414-13.27,13.27-1.77-1.769,27.456-28.687Z"
            fill="#12c4c4"
          />
          <path
            id="Path_2013"
            data-name="Path 2013"
            d="M417.032,2017.416l-1.984-.248c-1.048,8.379-6.05,14.229-14.468,16.918l.608,1.9C410.4,2033.047,415.881,2026.624,417.032,2017.416Z"
            fill="#12c4c4"
          />
          <path
            id="Path_2014"
            data-name="Path 2014"
            d="M385.039,2012.76c-.461-6.452-.64-12.873-.692-15.1l15.429-3.373-.426-1.953-17.034,3.723.014.818c0,.08.142,8.026.713,16.024Z"
            fill="#12c4c4"
          />
        </g>
      </g>
    </svg>
  )
}

export default Shield
