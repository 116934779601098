import i18n from 'i18next'
import { en, ja } from './languages'
import LngDetector from 'i18next-browser-languagedetector'

const resources = {
  en: {
    translation: en,
  },
  ja: {
    translation: ja,
  },
}

i18n.use(LngDetector).init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('i18nextLng') || 'ja', // language to use
  resources,
})

export default i18n
