import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Button } from '../../components/atoms/buttons'
import { Maps } from '../../components/modules/maps'
import { Modal } from '../../components/modules/modal'
import BookingModal from './booking-modal'
import { selectInputStyler } from './constants'
import { Link } from 'react-router-dom'
import {
  IconGroup1,
  IconGroup2,
  IconGroup3,
  IconGroup4,
  IconGroup5,
  IconHair7,
  IconHair8,
  IconHair10,
  IconHair9,
  CheckBooking,
  CancelBooking,
} from '../../../../assets/images'
import { GET, ROUTER_URL } from '../../../../utils/const'
import { CHECK_RESERVED, CHECK_SCANNED } from '../../../../utils/endpoints'

interface BookingFormProps {
  areas: object[]
  selectedArea: any
  addressList: object[]
  setSelectedArea: any
  setSelectedAddress: any
  selectedAddress: any
  onResetArea: Function
  notify?: any
  notifyModal?: any
  callApi: any
}

const MAX_LATITUDE = 90
const MAX_LONGITUDE = 180

const BookingForm: React.FC<BookingFormProps> = ({
  areas,
  selectedArea,
  addressList,
  setSelectedArea,
  setSelectedAddress,
  selectedAddress,
  onResetArea,
  notify,
  notifyModal,
  callApi,
}) => {
  const [t] = useTranslation('translation')
  const [show, setShow] = useState(false)
  const [checked, setChecked] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [available, setAvailable] = useState(false)
  const [scanned, setScanned] = useState(false)
  const [loaded, setLoaded] = useState(false)


  const onButtonClick = () => {
    checkAvailable()
    toggleModal()
  }
  const toggleModal = () => {
    setMessageError(false)
    if (checked) {
      setShow((show) => !show)
    } else {
      setMessageError(true)
    }
  }

  function onBookingSuccess({ time, address }: any) {
    toggleModal()
    onResetArea()
    document.body.scrollTo(0, 0)
    const content = (
      <div className="content-form-booking">
        <p className="pop-big-body-text">{t('booking_form.modal_booking.content1', { time })}</p>
        <p className="distance-text-to-line pop-big-body-text">
          {t('booking_form.modal_booking.content2', { address })}
        </p>
        <p className="pop-large-body-text text-pre-line">
          {t('booking_form.modal_booking.content3')}
        </p>
      </div>
    )
    notifyModal({
      title: t('booking_form.modal_booking.title'),
      content: () => content,
      isBooking: true,
    })
  }

  const checkAvailable = async () => {
    try {
      const params = {}
      const result = await callApi(CHECK_RESERVED, GET, { params: params })
      setAvailable(Boolean(result.data.data))
    } catch (error){
    }
  } 

  const checkScanned = async () => {    
    try {
      const params = {}
      const result = await callApi(CHECK_SCANNED, GET, { params: params })
      setScanned(Boolean(result.data.data))
    } catch (error){
      setScanned(true)
    }
  } 

  useEffect(() => {
    if (!loaded) {
      checkScanned()
      setLoaded(true)
    }
  })

  const renderBody = (
    <div className="booking__form--body">
      <div className="booking__form--body__wrapper">
        <div className="booking__form--body__wrapper--left">
          <h3 className="title-h3 distance-h3-to-text">{t('booking.scanner_location')}</h3>
          <div className="booking__form--body__describe">
            <p>
              {t('booking.map_describe')}
              <br />
              {t('booking.map_describe2')}
            </p>
          </div>
          <div className="booking__form--body__input2">
            <div className="booking__form--body__input--main">
              <Select
                styles={selectInputStyler()}
                placeholder={t('booking.choose_location')}
                options={areas}
                value={selectedArea || null}
                onChange={setSelectedArea}
                noOptionsMessage={() => t('booking_form.no_option_country')}
              />
            </div>
          </div>
          <div className="booking__form--body__input">
            <div className="booking__form--body__input--main">
              <Select
                styles={selectInputStyler()}
                placeholder={t('booking.choose_scanner')}
                options={addressList}
                onChange={setSelectedAddress}
                value={selectedAddress || null}
                noOptionsMessage={() => t('booking_form.no_option_scanner')}
              />
            </div>
          </div>
          <div className="booking__form--body__check">
            <Link
              to={{
                pathname: ROUTER_URL.SCANNER,
                state: { ...selectedAddress, area: selectedArea?.value },
              }}
              className="text-link"
            >
              {t('booking.scanner_check')}
            </Link>
          </div>
        </div>
        <div className="booking__form--body__map">
          <div className="booking__form--body__map__content">
            <Maps lat={selectedAddress?.lat} lng={selectedAddress?.lng} />
          </div>
          {!(
            Number.isNaN(selectedAddress?.lat * 1) ||
            selectedAddress?.lat > MAX_LATITUDE ||
            selectedAddress?.lat < MAX_LATITUDE * -1 ||
            Number.isNaN(selectedAddress?.lng * 1) ||
            selectedAddress?.lng > MAX_LONGITUDE ||
            selectedAddress?.lng < MAX_LONGITUDE * -1
          ) ? (
            <div className="text-right">
              <span
                className="text-link"
                onClick={() =>
                  window.open(
                    `http://www.google.com/maps/place/${selectedAddress?.lat},${selectedAddress?.lng}`,
                    '_blank'
                  )
                }
              >
                {t('scanner.detail_map')}
              </span>
            </div>
          ) : (
            <span className="form-field-error-message">{t('scanner.errror_message')}</span>
          )}
        </div>
      </div>
    </div>
  )

  const renderFooter = (
    <div className="booking__form--footer">
      <h3 className="title-h3  distance-h3-to-text">{t('booking.precautions')}</h3>
      <div className="booking__form--footer__describe">
        <p>{t('booking.footer_describe')}</p>
      </div>
      <div className="booking__form--footer__clothes">
        <div className="booking__form--footer__clothes-header">
          <h4 className="title-h4 distance-line-to-h4 distance-h4-to-text">
            {t('booking.footer.clothes.title')}
          </h4>
          <p>{t('booking.footer.clothes.title_detail')}</p>
        </div>
        <div className="booking__form--footer__clothes-container">
          <div className="booking__form--footer__clothes-wrapContent">
            <div className="booking__form--footer__clothes-content margin-noti-left">
              <div className="booking__form--footer__clothes-content--box1">
                <div className="booking__form--footer__clothes-content--box1__header">
                  <h4 className="title-h4">{t('booking_form.header_text')}</h4>
                </div>
                <div className="booking__form--footer__clothes-content--box1__content">
                  <div className="booking__form--footer__clothes-content--box1__content-wrap1">
                    <div className="icon-group">
                      <IconGroup1 />
                    </div>
                    <p className="text-group">{t('booking_form.content1')}</p>
                  </div>
                  <div className="booking__form--footer__clothes-content--box1__content-wrap2">
                    <div className="icon-group">
                      <IconGroup2 />
                    </div>
                    <p className="text-group">{t('booking_form.content2')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="booking__form--footer__clothes-content">
              <div className="booking__form--footer__clothes-content--box2">
                <div className="booking__form--footer__clothes-content--box1__header">
                  <h4 className="title-h4">{t('booking_form.content3')}</h4>
                </div>
                <div className="booking__form--footer__clothes-content--box2__content">
                  <div className="booking__form--footer__clothes-content--box2__content-wrap1">
                    <div className="icon-group">
                      <IconGroup3 />
                    </div>
                    <div className="text-group__padding">
                      <p className="text-group">
                        {t('booking_form.content4')}
                        <br />
                        {t('booking_form.content5')}
                        <br />
                        {t('booking_form.content6')}
                      </p>
                    </div>
                  </div>
                  <div className="booking__form--footer__clothes-content--box2__content-wrap1">
                    <div className="icon-group">
                      <IconGroup4 />
                    </div>
                    <div className="text-group__padding">
                      <p className="text-group">
                        {t('booking_form.content7')}
                        <br />
                        {t('booking_form.content8')}
                        <br />
                        {t('booking_form.content9')}
                      </p>
                    </div>
                  </div>
                  <div className="booking__form--footer__clothes-content--box2__content-wrap1">
                    <div className="icon-group">
                      <IconGroup5 />
                    </div>
                    <div className="text-group__padding">
                      <p className="text-group">
                        {t('booking_form.content10')}
                        <br />
                        {t('booking_form.content11')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="booking__form--footer__clothes-detail">
            <div className="booking__form--footer__clothes-detail--content">
              <p>
                ※{t('booking_form.clothes_content1')}
                <br />※ {t('booking_form.clothes_content2')}
              </p>
            </div>
          </div>
          <div className="booking__form--footer__clothes-warning">
            <div className="booking__form--footer__clothes-warning--header">
              <h4 className="title-h4 distance-line-to-h4 distance-h4-to-text">
                {t('booking_form.warning_title')}
              </h4>
              <p> {t('booking_form.warning_content1')}</p>
            </div>
            <div className="booking__form--footer__clothes-warning--wrap">
              <div className="booking__form--footer__clothes-warning2--content">
                <div className="booking__form--footer__clothes-warning2--content__headerContainer">
                  <div className="booking__form--footer__clothes-warning2--content__header">
                    <h4 className="title-h4">{t('booking_form.warning_title2')}</h4>
                  </div>
                  <div className="booking__form--footer__clothes-warning2--content__container">
                    <div className="booking__form--footer__clothes-warning2--content__container-box">
                      <div className="check-booking">
                        <CheckBooking />
                      </div>
                      <div className="box1">
                        <div>
                          <IconHair7 />
                        </div>
                        <div className="box1-text">
                          <div className="box1-text__padding">
                            <Trans
                              i18nKey="booking_form.warning_content2"
                              components={{
                                br: <br />,
                                span: <span className="box-text_err" />,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="box1">
                        <div>
                          <IconHair8 />
                        </div>
                        <div className="box1-text">
                          <div className="box1-text__padding">
                            <Trans
                              i18nKey="booking_form.warning_content4"
                              components={{
                                br: <br />,
                                span: <span className="box-text_err" />,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="booking__form--footer__clothes-warning2--content__headerContainer">
                  <div className="booking__form--footer__clothes-warning2--content__header">
                    <h4 className="title-h4">{t('booking_form.warning_title3')}</h4>
                  </div>
                  <div className="booking__form--footer__clothes-warning2--content__container">
                    <div className="booking__form--footer__clothes-warning2--content__container-box is-error">
                      <div className="check-booking">
                        <CancelBooking />
                      </div>
                      <div className="box1">
                        <div>
                          <IconHair9 />
                        </div>
                        <div className="box1-text">
                          <div className="box1-text__padding">
                            <Trans
                              i18nKey="booking_form.warning_content7"
                              components={{
                                br: <br />,
                                span: <span className="box-text_err" />,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="box1">
                        <div>
                          <IconHair10 />
                        </div>
                        <div className="box1-text">
                          <div className="box1-text__padding">
                            <Trans
                              i18nKey="booking_form.warning_content9"
                              components={{
                                br: <br />,
                                span: <span className="box-text_err" />,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="booking__form--footer__board">{t('booking.board_content')}</div>
      <div className="booking__form--footer__link1">
        {localStorage.getItem('i18nextLng') === 'ja' ? (
          <>
            {t('booking_form.board_content')}
            <Link
              to={{
                pathname: ROUTER_URL.BOOKING_ATTENTION,
                state: {
                  ...selectedAddress,
                  area: selectedArea?.value,
                  scanner_id: selectedAddress?.value,
                },
              }}
              className="text-link"
            >
              {t('booking_form.board_link')}
            </Link>{' '}
          </>
        ) : (
          <>
            {t('booking_form.board_content')}
            <Link
              to={{
                pathname: ROUTER_URL.BOOKING_ATTENTION,
                state: {
                  ...selectedAddress,
                  area: selectedArea?.value,
                  scanner_id: selectedAddress?.value,
                },
              }}
              className="text-link"
            >
              {t('booking_form.board_link')}
            </Link>{' '}
          </>
        )}
      </div>
      <div className="booking__form--footer__checkbox checkbox">
        <div className="booking__form--footer__checkbox--checkbox">
          <input
            className="booking__form--footer__checkbox--input"
            type="checkbox"
            id="vehicle1"
            name="vehicle1"
            checked={checked}
            onChange={({ target: { checked } }: any) => setChecked(checked)}
          />
          <div className="booking__form--footer__checkbox--content">
            <span>{t('booking_form.checkbox_content')}</span>
            <span className="booking__form--footer__checkbox--content_bold">
              {t('booking_form.checkbox_content2')}
            </span>
            <span>{t('booking_form.checkbox_content3')}</span>
          </div>
        </div>
        {messageError && (
          <p>
            <span className="form-field-error-message">
              {t('booking_form.error_message_check')}
            </span>
          </p>
        )}
      </div>
      <Button className="m-auto" onClick={onButtonClick} disabled={scanned}>
        {t('booking.button')}
      </Button>
    </div>
  )

  return (
    <div className="booking__form distance-tab-to-text">
      <Modal
        modalStyles="booking__modal-booking padding-modal-in-h2"
        show={show}
        toggleModal={toggleModal}
      >
        <BookingModal
          toggleModal={toggleModal}
          addressList={addressList}
          selectedAddress={selectedAddress}
          onBookingSuccess={onBookingSuccess}
          callApi={callApi}
          notify={notify}
          available={available}
        />
      </Modal>
      <div className="booking__form--explanation padding-content-to-line distance-content-to-line">
        <p>
          {t('booking.booking_explanation', { abc: `"Make a Reservation"` })}
          <br />
          {t('booking.booking_explanation2')}
        </p>
      </div>
      {renderBody}
      {renderFooter}
    </div>
  )
}

export default BookingForm
