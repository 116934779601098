import { AnyAction, Reducer } from 'redux'
import { ISigninState, initialSigninState } from '../states'
import { SigninActionsTypes } from '../actions'

export const SigninReducer: Reducer = (
  state: ISigninState = initialSigninState,
  action: AnyAction
) => {
  switch (action.type) {
    case SigninActionsTypes.GET_SIGNIN_ATTEMPT: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case SigninActionsTypes.GET_SIGNIN_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: '',
        isSignedIn: true,
      }
    }
    case SigninActionsTypes.GET_SIGNIN_FAIL: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      }
    }
    case SigninActionsTypes.CHANGE_SIGNIN_STATE: {
      return {
        ...state,
        isSignedIn: action.payload,
      }
    }
    case SigninActionsTypes.SIGN_OUT_SUCCESS: {
      return {
        ...initialSigninState,
      }
    }
    case SigninActionsTypes.SET_USER_DETAIL: {
      return {
        ...state,
        userData: action.payload,
      }
    }
    case SigninActionsTypes.SET_LANG: {
      return {
        ...state,
        lang: action.payload,
      }
    }
    default:
      return state
  }
}
