import React from 'react'

interface LineChatLogoProps {
  height?: string
  width?: string
}

const LineChatLogo: React.FC<LineChatLogoProps> = ({ height, width }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.5 0H6.5C2.91015 0 0 2.91015 0 6.5V23.5C0 27.0899 2.91015 30 6.5 30H23.5C27.0899 30 30 27.0899 30 23.5V6.5C30 2.91015 27.0899 0 23.5 0Z"
        fill="#00C300"
      />
      <path
        d="M30 13.3482C30 6.53842 23.2715 1 14.9983 1C6.72503 1 0 6.53842 0 13.3482C0 19.4544 5.33814 24.5679 12.5438 25.5397C13.0347 25.6477 13.6972 25.8672 13.8654 26.2921C13.9851 26.7469 14.0016 27.2233 13.9135 27.6854L13.6938 28.9638C13.6286 29.347 13.3917 30.4686 14.9914 29.7824C16.5911 29.0962 23.6492 24.6236 26.7834 20.9488C28.9564 18.5105 30 16.0722 30 13.3482Z"
        fill="white"
      />
      <path
        d="M11.9551 10.06H10.9012C10.8238 10.06 10.7496 10.0912 10.6948 10.1467C10.6401 10.2022 10.6094 10.2776 10.6094 10.3561V16.9743C10.6094 17.0528 10.6401 17.1281 10.6948 17.1837C10.7496 17.2392 10.8238 17.2704 10.9012 17.2704H11.9551C12.0325 17.2704 12.1067 17.2392 12.1614 17.1837C12.2161 17.1281 12.2469 17.0528 12.2469 16.9743V10.3561C12.2469 10.2776 12.2161 10.2022 12.1614 10.1467C12.1067 10.0912 12.0325 10.06 11.9551 10.06Z"
        fill="#00C300"
      />
      <path
        d="M19.1845 10.06H18.1546C18.0773 10.06 18.003 10.0912 17.9483 10.1467C17.8936 10.2022 17.8628 10.2776 17.8628 10.3561V14.2957L14.8522 10.1889L14.8316 10.161L14.8144 10.1436H14.7973H14.7801H13.6473C13.5699 10.1436 13.4957 10.1748 13.4409 10.2303C13.3862 10.2858 13.3555 10.3612 13.3555 10.4397V17.0579C13.3555 17.1364 13.3862 17.2117 13.4409 17.2673C13.4957 17.3228 13.5699 17.354 13.6473 17.354H14.6977C14.7754 17.354 14.85 17.3229 14.9053 17.2675C14.9605 17.2121 14.9921 17.1368 14.993 17.0579V13.0487L17.9899 17.1554C18.0088 17.1857 18.0347 17.2108 18.0654 17.2286H18.0826H18.1031C18.1181 17.2471 18.1354 17.2635 18.1546 17.2774H19.1845C19.2619 17.2774 19.3361 17.2462 19.3908 17.1906C19.4456 17.1351 19.4763 17.0598 19.4763 16.9813V10.363C19.4763 10.2845 19.4456 10.2092 19.3908 10.1537C19.3361 10.0982 19.2619 10.067 19.1845 10.067"
        fill="#00C300"
      />
      <path
        d="M9.41552 15.6228H6.55593V10.3561C6.55593 10.2776 6.52519 10.2022 6.47046 10.1467C6.41574 10.0912 6.34152 10.06 6.26413 10.06H5.21367C5.13628 10.06 5.06206 10.0912 5.00734 10.1467C4.95262 10.2022 4.92188 10.2776 4.92188 10.3561V16.9743C4.92182 17.0504 4.95 17.1238 5.00083 17.1798C5.05603 17.2314 5.12834 17.26 5.20337 17.2599H9.41552C9.49291 17.2599 9.56713 17.2288 9.62185 17.1732C9.67658 17.1177 9.70732 17.0424 9.70732 16.9639V15.9189C9.70732 15.8404 9.67658 15.765 9.62185 15.7095C9.56713 15.654 9.49291 15.6228 9.41552 15.6228Z"
        fill="#00C300"
      />
      <path
        d="M25.023 11.7041C25.0613 11.7041 25.0993 11.6964 25.1347 11.6816C25.1701 11.6667 25.2022 11.6449 25.2293 11.6174C25.2564 11.5899 25.2779 11.5573 25.2926 11.5213C25.3073 11.4854 25.3148 11.4469 25.3148 11.408V10.3561C25.3148 10.2776 25.2841 10.2022 25.2293 10.1467C25.1746 10.0912 25.1004 10.06 25.023 10.06H20.8006C20.7249 10.0602 20.6523 10.0901 20.598 10.1436C20.5716 10.1707 20.5508 10.2027 20.5366 10.238C20.5225 10.2733 20.5154 10.311 20.5156 10.3491V16.9673C20.5154 17.0054 20.5225 17.0432 20.5366 17.0784C20.5508 17.1137 20.5716 17.1458 20.598 17.1729C20.6523 17.2263 20.7249 17.2563 20.8006 17.2565H25.023C25.1004 17.2565 25.1746 17.2253 25.2293 17.1697C25.2841 17.1142 25.3148 17.0389 25.3148 16.9604V15.9189C25.3148 15.8404 25.2841 15.765 25.2293 15.7095C25.1746 15.654 25.1004 15.6228 25.023 15.6228H22.1463V14.4907H25.023C25.1004 14.4907 25.1746 14.4595 25.2293 14.404C25.2841 14.3485 25.3148 14.2732 25.3148 14.1947V13.1357C25.3148 13.0969 25.3073 13.0584 25.2926 13.0224C25.2779 12.9865 25.2564 12.9539 25.2293 12.9264C25.2022 12.8989 25.1701 12.8771 25.1347 12.8622C25.0993 12.8473 25.0613 12.8397 25.023 12.8397H22.1463V11.7041H25.023Z"
        fill="#00C300"
      />
    </svg>
  )
}

export default LineChatLogo
