import React, { useState, useEffect } from 'react'
import { placeholderImage } from '../../../../assets/images'
import moment from 'moment'
interface NewsGridComponentProps {
  id: number
  date: string
  title: string
  content: string
  thumbnail: string
  onDirect: Function
}

const NewsGridComponent: React.FC<NewsGridComponentProps> = ({
  id,
  date,
  title,
  content,
  thumbnail,
  onDirect,
}) => {
  const [img, setImg] = useState<any>()

  useEffect(() => {
    setImg(thumbnail)
  }, [thumbnail])

  return (
    <div className="news__grid-area">
      <div className="news__grid-hero-image">
        <img
          onClick={() => onDirect(id)}
          src={!img ? placeholderImage : img}
          alt="hero"
          onError={() => setImg(placeholderImage)}
        />
      </div>
      <div className="news__grid-date-publish">
        {localStorage.getItem('i18nextLng') === 'ja'
          ? moment(new Date(date).toISOString()).format('YYYY年MM月DD日')
          : moment(new Date(date).toISOString()).format('MM/DD/YYYY')}
      </div>
      <div className="news__grid-boxTitle">
        <div className="news__grid-title">
          <div onClick={() => onDirect(id)}>
            {title}
          </div>
        </div>
      </div>
      <div className="news__grid-content" ><p>{content}</p></div>
    </div>
  )
}

export default NewsGridComponent
