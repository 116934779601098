import * as React from 'react'

interface TShirtProps {
  height?: string
  width?: string
}
const TShirt: React.FC<TShirtProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '98'}
      height={height || '86'}
      viewBox="0 0 98 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.7802 24.5494C61.2994 24.1451 60.7318 23.8672 60.1255 23.7393L62.8835 2H61.8099L59.0575 23.6653C58.285 23.7281 57.5488 24.032 56.9457 24.5372L48.9963 31.3199L41.0449 24.5364C40.4422 24.0305 39.7058 23.7265 38.933 23.6646L36.1869 2H35.1085L37.8629 23.7422C37.2581 23.8698 36.6918 24.1467 36.2118 24.5494L28.2617 31.3357L29.7524 72H68.2451L69.7357 31.3357L61.7802 24.5494Z"
        fill="#111947"
      />
      <path
        opacity="0.6"
        d="M79.1497 8.3088C74.8876 4.31189 69.3663 2.06844 63.6232 2H59.1062L59.0667 2.06176L49.994 13.1785C49.7207 13.4322 49.367 13.5724 49.0003 13.5724C48.6337 13.5724 48.28 13.4322 48.0067 13.1785L38.9146 2.06176C38.898 2.04245 38.883 2.0218 38.8696 2H34.3672C28.6307 2.07332 23.1171 4.31644 18.86 8.3088L3 26.2391L16.5295 40.2901L24.0694 33.2697L25.2183 64.818H72.7685L73.9265 33.2697L81.4663 40.2901L95 26.2391L79.1497 8.3088Z"
        fill="#999999"
      />
      <path
        d="M79.1497 8.3088C74.8876 4.31189 69.3663 2.06844 63.6232 2H59.1062L59.0667 2.06176L49.994 13.1785C49.7207 13.4322 49.367 13.5724 49.0003 13.5724C48.6337 13.5724 48.28 13.4322 48.0067 13.1785L38.9146 2.06176C38.898 2.04245 38.883 2.0218 38.8696 2H34.3672C28.6307 2.07332 23.1171 4.31644 18.86 8.3088L3 26.2391L16.5295 40.2901L24.0694 33.2697L25.2183 64.818H72.7685L73.9265 33.2697L81.4663 40.2901L95 26.2391L79.1497 8.3088Z"
        stroke="#111947"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default TShirt
