import {
  SignIn,
  SigninNoLogin,
  SignUp,
  SignupNoLogin,
  Settings,
  Booking,
  DetailAvatar,
  Confirm,
  ControlAvatar,
  ExportComplete,
  ControlExport,
  RegistClusterComplete,
  ForgotPassWord,
  ForgotPasswordNoLogin,
  Notifications,
  DetailNotification,
  ResetPassword,
  News,
  DetailNews,
  ForgotPasswordNotification,
  DownLoadAvatarUser,
  ManageCredit,
  AddCreadit,
  WithDraw,
  ActiveEmailError,
  ActiveEmail,
  ActiveEmailSuccess,
  TermsOfService,
  TermsOfServiceMobile,
  SitePolicy,
  PrivacyPolicy,
  PrivacyPolicyMobile,
  ResetPasswordSuccess,
  DownloadAvatarEdited,
  DetailAvatarEdited,
  Top,
  ExpiredResetPassword,
  ConFirmEmail,
  Contact,
  Scanner,
  BookingAttention,
  CheckResetPassword,
  ChangeEmail,
  SignupSuccess,
  SignupError,
  SignupConfirm,
  SignupMobile,
  SignupConfirmMobile,
  WithDrawNoti,
  ReDownload,
  HelpIndex,
  HelpQa,
  HelpAboutAvatarium,
  HelpHowTo,
  HelpFlowScanner,
  HelpFlowScannerMobile,
  HelpExport,
  ProvisionalUserForm,
  ProvisionalUserConfirm
} from '../views/pages'
import { ROUTER_URL, NONE_LOGIN, REQUIRED_LOGIN } from '../../utils/const'
import { lazy } from 'react';
const HelpBookingAttention = lazy(() => import("../views/pages/help/booking-attention"));

export const routesRequired = [
  {
    path: ROUTER_URL.SCAN_BOOKING,
    exact: true,
    name: 'Scan Booking',
    component: Booking,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.NOTIFICATIONS,
    exact: true,
    name: 'Notifications',
    component: Notifications,
    auth: REQUIRED_LOGIN,
  },
  {
    path: `${ROUTER_URL.DETAIL_NOTIFICATION}/:id`,
    exact: true,
    name: 'Notifications',
    component: DetailNotification,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.SETTINGS,
    exact: true,
    name: 'Settings',
    component: Settings,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.CONTROL_AVATAR_DETAIL,
    exact: true,
    name: 'Control Avatar Detail',
    component: DetailAvatar,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.CONTROL_AVATAR,
    exact: true,
    name: 'Control Avatar',
    component: ControlAvatar,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.EXPORT_COMPLETE,
    exact: true,
    name: 'Export Complete',
    component: ExportComplete,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.CONTROL_EXPORT,
    exact: true,
    name: 'Control Export',
    component: ControlExport,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.REGIST_CLUSTER_COMPLETE,
    exact: true,
    name: 'Regist Cluster Complete',
    component: RegistClusterComplete,
    auth: REQUIRED_LOGIN,
  },
  {
    path: `${ROUTER_URL.DOWNLOAD_AVATAR}/:model_type/:id`,
    exact: true,
    name: 'Download Avatar',
    component: DownLoadAvatarUser,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.WITHDRAW,
    exact: true,
    name: 'Withdraw',
    component: WithDraw,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.MANAGE_CREDIT,
    exact: true,
    name: 'Manage Credit',
    component: ManageCredit,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.ADD_CREADIT,
    exact: true,
    name: 'Add Creadit',
    component: AddCreadit,
    auth: REQUIRED_LOGIN,
  },
  {
    path: `${ROUTER_URL.CONTROL_AVATAR_EDITED}/:model_type/:id`,
    exact: true,
    name: 'Control Avatar Edited',
    component: DetailAvatarEdited,
    auth: REQUIRED_LOGIN,
  },
  {
    path: `${ROUTER_URL.DOWNLOAD_AVATAR_EDITED}/:model_type/:id`,
    exact: true,
    name: 'Download Avatar Edited',
    component: DownloadAvatarEdited,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.BOOKING_ATTENTION,
    exact: true,
    name: 'Booking Attention',
    component: BookingAttention,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.SCANNER,
    exact: true,
    name: 'Scanner',
    auth: REQUIRED_LOGIN,
    component: Scanner,
  },
  {
    path: ROUTER_URL.SIGNIN,
    exact: true,
    name: 'Signin',
    component: SigninNoLogin,
  },
  {
    path: ROUTER_URL.FORGOT_PASSWORD,
    exact: true,
    name: 'Forgot PassWord',
    component: ForgotPasswordNoLogin,
    auth: REQUIRED_LOGIN,
  },
  {
    path: ROUTER_URL.SIGNUP,
    exact: true,
    name: 'Sign Up',
    component: SignupNoLogin,
    auth: REQUIRED_LOGIN,
  },
]

export const routesNonLogin = [
  {
    path: ROUTER_URL.FORGOT_PASSWORD,
    exact: true,
    name: 'Forgot PassWord',
    component: ForgotPassWord,
    auth: NONE_LOGIN,
  },
  {
    path: ROUTER_URL.RESET_PASSWORD,
    exact: true,
    name: 'Reset Password',
    component: ResetPassword,
    auth: NONE_LOGIN,
  },
  {
    path: ROUTER_URL.RESET_PASSWORD_EXPIRED,
    exact: true,
    name: 'Expired Reset Password',
    component: ExpiredResetPassword,
    auth: NONE_LOGIN,
  },
  {
    path: ROUTER_URL.FORGOT_PASSWORD_NOTIFICATION,
    exact: true,
    name: 'Forgot Password Notification',
    component: ForgotPasswordNotification,
    auth: NONE_LOGIN,
  },
  {
    path: ROUTER_URL.CHECK_RESET_PASSWORD,
    exact: true,
    name: 'Check Reset Password',
    component: CheckResetPassword,
    auth: NONE_LOGIN,
  },
  {
    path: ROUTER_URL.RESET_PASSWORD_SUCCESS,
    exact: true,
    name: 'Reset Password Success',
    component: ResetPasswordSuccess,
    auth: NONE_LOGIN,
  },
  {
    path: ROUTER_URL.SIGNUP,
    exact: true,
    name: 'Sign Up',
    component: SignUp,
    auth: NONE_LOGIN,
  },
  {
    path: ROUTER_URL.SIGNUP_CONFIRM,
    exact: true,
    name: 'Signup Confirm',
    component: SignupConfirm,
    auth: NONE_LOGIN,
  },
  {
    path: ROUTER_URL.SIGNIN,
    exact: true,
    name: 'Signin',
    component: SignIn,
    auth: NONE_LOGIN,
  },
]
export const routes = [
  {
    path: ROUTER_URL.NEWS_DETAIL,
    exact: true,
    name: 'News Detail',
    component: DetailNews,
  },
  {
    path: ROUTER_URL.NEWS,
    exact: true,
    name: 'News',
    component: News,
  },
  {
    path: ROUTER_URL.TERM_OF_SERVICE,
    exact: true,
    name: 'Term Of Service',
    component: TermsOfService,
  },
  {
    path: ROUTER_URL.TERM_OF_SERVICE_MOBILE,
    exact: true,
    name: 'Term Of Service',
    component: TermsOfServiceMobile,
  },
  {
    path: ROUTER_URL.COMMERCE,
    exact: true,
    name: 'Commerce',
    component: SitePolicy,
  },
  {
    path: ROUTER_URL.PRIVACY_POLICY,
    exact: true,
    name: 'Privacy Policy',
    component: PrivacyPolicy,
  },
  {
    path: ROUTER_URL.PRIVACY_POLICY_MOBILE,
    exact: true,
    name: 'Privacy Policy',
    component: PrivacyPolicyMobile,
  },
  {
    path: ROUTER_URL.CONTACT,
    exact: true,
    name: 'Contact',
    component: Contact,
  },
  {
    path: ROUTER_URL.ACTIVE_EMAIL,
    exact: true,
    name: 'Active Email',
    component: ActiveEmail,
  },
  {
    path: ROUTER_URL.ACTIVE_EMAIL_SUCCESS,
    exact: true,
    name: 'Active Email Success',
    component: ActiveEmailSuccess,
  },
  {
    path: ROUTER_URL.ACTIVE_EMAIL_ERR,
    exact: true,
    name: 'Active Email Success',
    component: ActiveEmailError,
  },
  {
    path: ROUTER_URL.CHANGE_EMAIL,
    exact: true,
    name: 'Change Email',
    component: ChangeEmail,
  },
  {
    path: ROUTER_URL.SIGNUP_SUCCESS,
    exact: true,
    name: 'Signup Success',
    component: SignupSuccess,
  },
  {
    path: ROUTER_URL.SIGNUP_ERROR,
    exact: true,
    name: 'Signup Error',
    component: SignupError,
  },
  {
    path: ROUTER_URL.SIGNUP_MOBILE,
    exact: true,
    name: 'Sign Up',
    component: SignupMobile,
    auth: NONE_LOGIN,
  },
  {
    path: ROUTER_URL.SIGNUP_CONFIRM_MOBILE,
    exact: true,
    name: 'Signup Confirm',
    component: SignupConfirmMobile,
    auth: NONE_LOGIN,
  },
  {
    path: ROUTER_URL.CONFIRM_EMAIL,
    exact: true,
    name: 'Confirm Email',
    component: ConFirmEmail,
  },

  {
    path: ROUTER_URL.HOME,
    exact: true,
    name: 'Home',
    component: Top,
  },
  {
    path: ROUTER_URL.WITHDRAW_NOTI,
    exact: true,
    name: 'Withdraw Noti',
    component: WithDrawNoti,
  },
  {
    path: ROUTER_URL.RE_DOWNLOAD,
    exact: true,
    name: 'Re Download',
    component: ReDownload,
  },
  {
    path: ROUTER_URL.HELP_INDEX,
    exact: true,
    name: 'Help top',
    component: HelpIndex,
  },
  {
    path: ROUTER_URL.HELP_ABOUT_AVATARIUM,
    exact: true,
    name: 'Help about avatarium',
    component: HelpAboutAvatarium,
  },
  {
    path: ROUTER_URL.HELP_QA,
    exact: true,
    name: 'Help and question',
    component: HelpQa,
  },
  {
    path: ROUTER_URL.HELP_HOWTO,
    exact: true,
    name: 'Help howto',
    component: HelpHowTo,
  },
  {
    path: ROUTER_URL.HELP_FLOW_SCANNER,
    exact: true,
    name: 'Help flow scanner',
    component: HelpFlowScanner,
  },
  {
    path: ROUTER_URL.HELP_FLOW_SCANNER_MOBILE,
    exact: true,
    name: 'Help flow scanner mobile',
    component: HelpFlowScannerMobile,
  },
  {
    path: ROUTER_URL.HELP_EXPORT,
    exact: true,
    name: 'Help export',
    component: HelpExport,
  },
  {
    path: ROUTER_URL.HELP_BOOKING_ATTENTION,
    exact: true,
    name: 'Help booking attension',
    component: HelpBookingAttention,
  },
  {
    path: ROUTER_URL.PROVISIONAL_USER,
    exact: true,
    name: 'Event User Form',
    component: ProvisionalUserForm,
  },
  {
    path: ROUTER_URL.PROVISIONAL_USER_CONFIRM,
    exact: true,
    name: 'Event User Form Success',
    component: ProvisionalUserConfirm,
  }
]
