import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AvatarIconSmall,
  EditIcon,
  LocationIcon,
  AvatarDownIcon,
  AvatarUpIcon,
  IconDateDetail,
  TickIcon,
} from '../../../../../assets/images/svgs'
import { Button } from '../../../components/atoms/buttons'
import { Modal } from '../../../components/modules/modal'
import Wrapper from '../../../components/modules/_wrapper'
import AvatarDetailComponent from '../../../components/modules/control-avatar/detail'
import { TextInput } from '../../../components/atoms/text-input'
import { FormProvider, useForm } from 'react-hook-form'
import { validator } from './constants'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import {
  DELETE_AVATAR,
  GET_AVATAR_DETAIL,
  RENAME_USER,
  EXPORT_AVATAR,
  IS_EXPORTED,
} from '../../../../../utils/endpoints'
import DeleteModal from './../delete-modal'
import { DELETE, EDITED, ERROR_TYPE, GET, MOBILE, POST, ROUTER_URL } from '../../../../../utils/const'
import cx from 'classnames'
import MessageModal from '../../../components/modules/modal/message-modal'
import axios from '../../../../../utils/axios'
interface DetailAvatarProps {
  notify: any
  callApi: any
  isLoading: boolean
  setIsLoading: Function
}

const DetailAvatarEdited: React.FC<DetailAvatarProps> = ({ notify, callApi, isLoading, setIsLoading }) => {
  const [imageAvatar, setImageAvatar] = useState('')
  const [t] = useTranslation('translation')

  const [isInput, setInput] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modelType, setModelType] = useState('')
  const [showModalFail, setShowModalFail] = useState(false)
  const [errorTitle, setErrorTitle] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const [editId, setEditId] = useState('')
  const [avatarSource, setAvatarSource] = useState({})
  const [avatarSourceImage, setAvatarSourceImage] = useState('')
  const [avatarEdit, setAvatarEdit] = useState({})
  const url_string = window.location.href
  const location: any = useLocation()
  const [valueInput, setValueInput] = useState('')
  const id = url_string.substring(url_string.lastIndexOf('/') + 1)
  const history = useHistory()
  const params: any = useParams()
  const [avatarNameEdit, setAvatarNameEdit] = useState('')
  const [checked, setChecked] = useState(false)
  const [exported, setExportd] = useState(false)

  const methods = useForm({
    mode: 'onChange',
    resolver: validator(t),
  })
  const { handleSubmit, setValue, getValues } = methods

  const toggleModal = () => {
    setShowModal((showModal) => !showModal)
  }

  const toggleModalFail = () => {    
    setShowModalFail((showModalFail) => !showModalFail)
  }

  useEffect(() => {
    setIsLoading(true)
    if (!checked) {
      setChecked(true)
      checkExportd()         
    }
    const fetchListAvatarEdited = async () => {
      const paramApi = {
        avatar_type: EDITED,
        avatar_id: params['id'],
        model_type:  location?.state?.model_type?  location?.state?.model_type : params['model_type'],
      }
      try {        
        const result = await axios.get(GET_AVATAR_DETAIL, { params: paramApi })
        if (result) {
          await axios.get(result.data.data.avatar.s3_path, {responseType: 'blob',}).then(res => {
            setImageAvatar(URL.createObjectURL(res?.data))
          })
          await axios.get(result.data.data.source_avatar.s3_path, {responseType: 'blob',}).then(res => {
            setAvatarSourceImage(URL.createObjectURL(res?.data))
          })
          const edit_id = result.data.data.avatar.id
          setEditId(edit_id)
          setValue('name', result.data.data.avatar.avatar_name, { shouldValidate: false })
          setAvatarNameEdit(result.data.data.avatar.avatar_name)
          setAvatarEdit(result.data.data.avatar)
          setAvatarSource(result.data.data.source_avatar)
          setModelType(params['model_type'])

          const title = result.data.data.avatar.avatar_name +' | 編集アバター詳細 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
          const description = '編集アバター詳細のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
          const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
          const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

          // title
          document.title = title;

          // title以外のmeta
          const headData = document.head.children;

          for (let i = 0; i < headData.length; i++) {
            const nameVal = headData[i].getAttribute('name');
            const propertyVal = headData[i].getAttribute('property');
            if (nameVal !== null) {
              if (nameVal.indexOf('keywords') !== -1) {
                headData[i].setAttribute('content', keywords);
              }
              if (nameVal.indexOf('description') !== -1) {
                headData[i].setAttribute('content', description);
              }
            }
            if (propertyVal !== null) {
              // OGP(og:title)の設定
              if (propertyVal.indexOf('og:title') !== -1) {
                headData[i].setAttribute('content', title);
              }
              if (propertyVal.indexOf('og:description') !== -1) {
                headData[i].setAttribute('content', description);
              }
              if (propertyVal.indexOf('og:image') !== -1) {
                headData[i].setAttribute('content', og_image);
              }
            }
          }
          
        }
      } catch (error) {
        if (error?.message) {
          history.push(ROUTER_URL.CONTROL_AVATAR)
          notify(t('detail-avatar.no_avatar_edit'), ERROR_TYPE)
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchListAvatarEdited()
  }, [params?.id, localStorage.getItem('i18nextLng')])

  const checkExportd = async () => {    
    try {
      const params = {avatar_id: id, avatar_type: EDITED}
      const result = await axios.get(IS_EXPORTED, { params: params })
      setExportd(result?.data.data)    
    } catch (error){
    }
  } 

  useEffect(() => {
  })


  const deleteAvatar = async () => {
    if (isLoading) return
    const params = {
      avatar_type: EDITED,
      avatar_id: id,
    }
    try {
      await callApi(DELETE_AVATAR, DELETE, { params })
      history.push(ROUTER_URL.CONTROL_AVATAR)
    } catch (error) {
      let message = error?.message || ''
      message = message.replace('\\n', '\n')
      if (message) {
        notify(message, ERROR_TYPE)
      }
    }
  }

  const handleReName = (e: any) => {
    setValueInput(e.target.value || '')
  }
  const onSubmit = async () => {
    setInput(true)
    const name = valueInput?.trim() || getValues('name')
    const params = {
      avatar_id: editId,
      avatar_name: name,
      avatar_type: EDITED,
    }
    try {
      const result = await callApi(RENAME_USER, POST, params)
      if (result?.data) {
        setInput(false)
        setAvatarNameEdit(name)
      }
    } catch (error) {
      let message = error?.message || ''
      message = message.replace('\\n', '\n')
      if (message) {
        notify(message, ERROR_TYPE)
      }
    }
  }


  function onValidate(e?: any) {
    const listField = [
      'name',
    ]
    listField.forEach((item: any) => {
      const value = getValues(item) + ''
      setValue(item, value.trim() || '')
    })
    handleSubmit(onSubmit)()
  }

  const exportAvater = async (values: any) => {
    try {
      const params = {avatar_id: id, avatar_type: EDITED, model_type: MOBILE}
      const result = await callApi(EXPORT_AVATAR, GET, { params })
      const state = result?.data.data
      if (state === 'success') {
        history.push(ROUTER_URL.EXPORT_COMPLETE)
        }
      else {
        let title
        let message
        if (state === 'converting') {
          title = 'home.modal_title_error'
          message = 'notifications.err_network'
        }
        else if (state === 'no_token') {
          title = 'control-export.export_fail_title'
          message = 'control-export.export_error_no_token'
        }
        else if (state === 'error') {
          title = 'control-export.export_fail_title'
          message = 'control-export.export_error'
        }
        else {
          title = 'control-export.export_fail_title'
          message = 'control-export.export_fail_message'
        }
        setErrorTitle(title)
        setErrorMessage(message)
        toggleModalFail()
        }
    } catch (error) {
    }
  }

  const list = [
    {
      name: t('control-avatar.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('detail-avatar.bread_crumb2'),
      link: ROUTER_URL.CONTROL_AVATAR,
    },
    {
      name: t('detail-avatar.bread_crumb_active'),
      link: ROUTER_URL.CONTROL_AVATAR_EDITED,
    },
  ]

  const renderImageMain = (
    <AvatarDetailComponent
      image={imageAvatar}
      date="2021/02/25"
      content="アバター名を記の記載"
    />
  )

  const renderNameAvatar = (
    <div className="wrap-content1_avatar name_avatar">
      <div className="box-content">
        <div className="box-content_icon">
          <AvatarIconSmall />
        </div>
        <div
          className={cx('box-input', {
            isDisable: !isInput,
            isEN: localStorage.getItem('i18nextLng') === 'en',
          })}
        >
          <TextInput
            type="text"
            isRequire={true}
            name="name"
            isDisabled={!isInput}
            isFocus={isInput}
            id={1}
            handleChange={handleReName}
            isShowBorder={false}
            placeholder=""
          />
        </div>
      </div>
      <button
        className="button-edit"
        type="button"
        onClick={() => (isInput ? onValidate() : setInput(true))}
      >
        {isInput ? <TickIcon /> : <EditIcon />}
      </button>
    </div>
  )
  const renderDateAvatar = (
    <div className="wrap-content1_date">
      <div className="box-content">
        <div className="box-content_date">
          <div className="box-content_icon">
            <IconDateDetail height="24" width="24" />
          </div>
          <p className="title-h4">{t('detail-avatar.date_upload')}</p>
        </div>
        <div className="box-date_content">
          <p>{avatarEdit && avatarEdit['created_time']}</p>
        </div>
      </div>
    </div>
  )

  const renderDownAvatar = (
    <div className="box-content">
      <div className="box-content_icon">
        <AvatarDownIcon />
      </div>
      <div className="box-input">
        <p className="title-h4 distance-line-to-h4">{t('detail-avatar.download')}</p>
      </div>
    </div>
  )

  const renderPlayRegister = (
    <div className="detail-avatar__register">
      <Button className="small" type="button" onClick={exportAvater} disabled={exported || modelType !== MOBILE} >
        {t('detail-avatar.button_play')}
      </Button>
    </div>
  )

  const renderDownloadRegister = (
    <div className="detail-avatar__register">
      <Button
        className="small"
        type="button"
        onClick={() =>
          history.push({
            pathname: `${ROUTER_URL.DOWNLOAD_AVATAR_EDITED}/${modelType}/${id}`,
            state: { id: id, img: avatarEdit && imageAvatar, name: avatarNameEdit },
          })
        }
        disabled={modelType===MOBILE}
      >
        {t('detail-avatar.button_download')}
      </Button>
    </div>
  )

  const renderCheckRegister = (
    <div className="detail-avatar__register btn-check">
      <Button
        onClick={() =>
          history.push(
            avatarSource['avatar_type'] === EDITED
              ? `${ROUTER_URL.CONTROL_AVATAR_EDITED}/${avatarSource['model_type']}/${avatarSource['id']}`
              : `${ROUTER_URL.CONTROL_AVATAR}/${avatarSource['model_type']}/${avatarSource['id']}`
          )
        }
      > 
        {t('detail-avatar.button_check_avatar')}
      </Button>
    </div>
  )

  const renderBtnDelete = (
    <div className="detail-avatar__register">
      <Button onClick={toggleModal} className="small cancel" type="button">
        {t('detail-avatar.btn_delete')}
      </Button>
    </div>
  )

  const renderInforAvavar = (
    <div className="box-content2">
      <div className="box-infor">
        <div className="box-infor_img">
          <h2 className="box-infor_ttle is-sp">{t('detail-avatar.title_check_avatar')}</h2>
          <AvatarDetailComponent image={avatarSourceImage}  />
        </div>
        <div className="box-infor_content">
          <h2 className="box-infor_ttle is-pc">{t('detail-avatar.title_check_avatar')}</h2>
          <div className="box-avatar">
            <div>
              <AvatarIconSmall />
            </div>
            <div className="box-text">
              <p className="title-h4">{avatarSource['avatar_name']}</p>
            </div>
          </div>
          <div className="box-avatar">
            <div>
              {avatarSource['avatar_type'] === EDITED ? (
                <IconDateDetail height="24" width="24" />
              ) : (
                <LocationIcon height="24" width="24" />
              )}
            </div>
            <div className="box-text">
              <p className="title-h4">
                {avatarSource['avatar_type'] === EDITED
                  ? avatarSource &&
                    `${t('detail-avatar.date_upload')} ${avatarSource['created_time']}`
                  : avatarSource && avatarSource['scanner_name']}
              </p>
            </div>
          </div>
        </div>
        <div className="box-button">{renderCheckRegister}</div>
      </div>
    </div>
  )

  return (
    <Wrapper breadcrum={list} className="detail-avatar" isNoMarginTop={true}>
      <Modal
        modalStyles="booking__modal-booking padding-modal-in-h2"
        show={showModal}
        toggleModal={toggleModal}
      >
        <DeleteModal name={avatarNameEdit} toggleModal={toggleModal} deleteAvatar={deleteAvatar} />
      </Modal>
      <MessageModal
        custom_title = {errorTitle}
        custom_message = {errorMessage}
        show={showModalFail}
        toggleModal={toggleModalFail}
      >
      </MessageModal>
      <div className="detail-avatar_wrap  detail-avatar_wrap--avatar">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="wrap-content1">
              {renderImageMain}
              <div className="wrap-content1-box">
                {renderNameAvatar}
                {renderDateAvatar}
                <div className="box-upload">
                  <div className="wrap-upload">
                    <div className="box-content1">
                      <div className="box-content_icon">
                        <AvatarUpIcon />
                      </div>
                      <div className="box-input">
                        <p className="title-h4 ">{t('detail-avatar.play')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="box-upload_button">
                    <div className="box-upload_content">
                      <p>{t('detail-avatar.detail_play')}</p>
                    </div>
                  </div>
                  <div className="box-upload_content_btn">{renderPlayRegister}</div>
                </div>
                <div>
                  <div className="box-down wrap-content1_avatar">
                    {renderDownAvatar}
                    <div className="box-down_button">{renderDownloadRegister}</div>
                    <div className="box-down_text">
                      <p>{t('detail-avatar.down_play_mobile')}</p>
                    </div>
                  </div>
                </div>
                <div className="wrap-content1_avatar box_delete">{renderBtnDelete}</div>
              </div>
            </div>
            {renderInforAvavar}
          </form>
        </FormProvider>
      </div>
    </Wrapper>
  )
}

export default DetailAvatarEdited
