import { useEffect, useState, FC } from 'react'
import useWindowSize from '../../../../customize/hooks/useWindowSize'
import axios from '../../../../utils/axios'
import {
  AAndR,
  AAndT,
  AAndV,
  AManText,
  AppStoreTop,
  BarCodeCustomization,
  BoxContent,
  BoxContentMobile,
  CameraIcon,
  CircleContent,
  Communications,
  DecorCustomization,
  Dots2x9Content,
  Dots5x7Content,
  Dots8x7Spread,
  DownArrow,
  EditIcon,
  FourDotsIconMedium,
  FourDotsIconSmall,
  Games,
  InspirationText,
  LeftArrow,
  LinesContent,
  PhysicalText,
  Streaming,
} from './svgs/export'
import { GooglePlay, placeholderImage } from '../../../../assets/images'
import classNames from 'classnames'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useScroll } from '../../../../customize/hooks/useScroll'
import { NEWS_HOME } from '../../../../utils/endpoints'
import { useDispatch, useSelector } from 'react-redux'
import { checkSignin, IApplicationState } from '../../../stores'
import { heroImageTapList, heroImageHoverList, breakPoint } from './constants'
import { InView } from 'react-intersection-observer'
import CustomizationImages from './components/customization-images'
import TopHeader from './components/top-header'
import { Trans, useTranslation } from 'react-i18next'
import { Footer } from '../../components/modules/templates'
import { ROUTER_URL } from '../../../../utils/const'
import moment from 'moment'

interface TopProps {
}


const Top: FC<TopProps> = () => {
  const screenSize = useWindowSize()
  const [heroPictureTap, setHeroPictureTap] = useState([true, true, true])
  const [heroPictureHover, setHeroPictureHover] = useState([true, true, true])
  const [listNews, setListNews] = useState<any>(null)
  const [inViewList, setInViewList] = useState([true, false, false, false])
  const [isVideoEnd, setIsVideoEnd] = useState(false)
  const [downloadHiding, setDownloadHiding] = useState(false)
  const { scrollY } = useScroll()
  const history = useHistory()
  const location: any = useLocation()
  const [t, i18n] = useTranslation('translation')
  const dispatch = useDispatch()
  const isSignedIn = useSelector((state: IApplicationState) => state.signin.isSignedIn)

  const isScrollOverRequiredPixels = (pixel: number) => {
    return scrollY > pixel
  }

  const title = 'AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }

  const navigationList = [
    {
      id: 0,
      label: [t('top.content_label_a'), t('top.content_label_b')],
      linkTo: '#top__content',
    },
    {
      id: 1,
      label: [t('top.spread_label_a'), t('top.spread_label_b') + t('top.spread_label_c')],
      linkTo: '#top__spread',
    },
    {
      id: 2,
      label: [
        t('top.customization_label_a'),
        t('top.customization_label_b') + t('top.customization_label_c'),
      ],
      linkTo: '#top__customization',
    },
    {
      id: 3,
      label: [t('top.news')],
      linkTo: '#top__news',
    },
  ]

  useEffect(() => {
    const { pathname } = location
    const token = localStorage.getItem('access_token')
    if (token) {
      dispatch(checkSignin(true))
      if (pathname !== ROUTER_URL.HOME) history.push(ROUTER_URL.HOME)
      return
    }
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const access_token = urlParams.get('access_token') ?? ''
    const refresh_token = urlParams.get('refresh_token') ?? ''
    if (access_token) {
      localStorage.setItem('access_token', access_token)
      dispatch(checkSignin(true))
      history.push(ROUTER_URL.SCAN_BOOKING)
    }
    if (refresh_token) {
      localStorage.setItem('refresh_token', refresh_token)
    }
  }, [])

  useEffect(() => {
    fetchNewsList()
  }, [i18n.language])

  useEffect(() => {
    const element = document.querySelector(location?.state?.section)
    if (element) {
      const pos = element.getBoundingClientRect().top + window.scrollY
      scrollToSmoothly(pos, 500)
    }

  }, [location])

  function scrollToSmoothly(pos: any, time: any) {
    var currentPos = window.pageYOffset
    let start: any = null
    if (time === null) time = 500
    pos = +pos
    time = +time
    window.requestAnimationFrame(function step(currentTime) {
      start = !start ? currentTime : start
      var progress = currentTime - start
      if (currentPos < pos) {
        window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos)
      } else {
        window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time)
      }
      if (progress < time) {
        window.requestAnimationFrame(step)
      } else {
        window.scrollTo(0, pos)
      }
    })
  }

  function onInit() {
    window.scrollTo(0, 0)
    fetchNewsList()
  }

  const fetchNewsList = async () => {
    try {
      const response = await axios.get(NEWS_HOME)
      if (response?.data?.data) {
        setListNews(response?.data?.data)
      }
    } catch (error) {
    }
  }

  const onChangeHeroPictureTap = (id: number) => {
    const newHeroPictureTap = heroPictureTap
    setHeroPictureTap(
      Object.keys(newHeroPictureTap).map((item) =>
        item !== id.toString() ? newHeroPictureTap[item] : !newHeroPictureTap[item]
      )
    )
  }

  const onChangeHeroPictureHover = (id: number) => {
    const newHeroPictureHover = heroPictureHover
    setHeroPictureHover(
      Object.keys(newHeroPictureHover).map((item) =>
        item !== id.toString() ? newHeroPictureHover[item] : !newHeroPictureHover[item]
      )
    )
  }

  const handleInView = (inView: boolean, id: any) => {
    if (inView === true) {
      const newInViewList = inViewList
      setInViewList(newInViewList.map((each, index) => (id === index ? true : false)))
    }
  }

  const handleScrollIntoView = (linkTo: string) => {
    let element = document.querySelector(linkTo)
    element?.scrollIntoView({ behavior: 'smooth' })
  }

  const renderNavigationContent = (
    <div className="top__navigation">
      {navigationList.map((item) => (
        <div
          key={item.id}
          className="top__navigation-couple"
          onClick={() => handleScrollIntoView(item.linkTo)}
        >
          <div
            className={classNames('top__navigation-circle', {
              'top__navigation-circle--active': inViewList[item.id] === true,
            })}
          ></div>
          <p className="top__navigation-label">
            {item.label.map((eachLabel) => (
              <span key={eachLabel}>{eachLabel}</span>
            ))}
          </p>
        </div>
      ))}
    </div>
  )

  const renderNavigation = (
    <>
      {screenSize.width > breakPoint
        ? (isScrollOverRequiredPixels(900) || isVideoEnd) && renderNavigationContent
        : isScrollOverRequiredPixels(800) && renderNavigationContent}
    </>
  )

  const renderDownload = (
    <>
      {screenSize.width > breakPoint
        ? (isScrollOverRequiredPixels(900) || isVideoEnd) && (
            <div className="top__download">
              <div
                className={classNames('top__download-content', {
                  'top__download-content--hiding': downloadHiding,
                })}
                onMouseEnter={() => setDownloadHiding(false)}
                onMouseLeave={() => setDownloadHiding(true)}
              >
                <div className="top__download-indicator">
                  <LeftArrow />
                  <p className="top__download-text">app download</p>
                </div>
                <div className="top__download-badges">
                  <AppStoreTop width="13.5rem" height="4rem" />
                  <GooglePlay width="13.5rem" height="4rem" />
                </div>
              </div>
            </div>
          )
        : isScrollOverRequiredPixels(800) && (
            <div className="top__download">
              <div className="top__download-indicator">
                <DownArrow />
                <div
                  onClick={() => handleScrollIntoView('#top__content-third-hero')}
                  className="top__download-text"
                >
                  app download
                </div>
              </div>
            </div>
          )}
    </>
  )

  const renderImageInContent = (id: number) => (
    <>
      {screenSize.width > breakPoint ? (
        <img
          src={
            heroPictureHover[id] ? heroImageHoverList[id].list[0] : heroImageHoverList[id].list[1]
          }
          width="650"
          height="500"
          alt="hero"
          onMouseEnter={() => onChangeHeroPictureHover(id)}
          onMouseLeave={() => onChangeHeroPictureHover(id)}
        />
      ) : (
        <img
          id={id === 2 ? 'top__content-third-hero' : 'null'}
          src={heroPictureTap[id] ? heroImageTapList[id].list[0] : heroImageTapList[id].list[1]}
          width="650"
          height="500"
          alt="hero"
          onClick={() => onChangeHeroPictureTap(id)}
        />
      )}
    </>
  )

  const renderContentFirstPart = (
    <div className="top__content-first">
      <div className="top__content-first-hero top__content-hero">
        <div className="top__content-hero-wrapper">
          <div className="top__content-first-av">
            <AAndV />
          </div>
          {renderImageInContent(0)}
          <div className="top__content-first-quote">
            <InspirationText />
          </div>
        </div>
      </div>
      <div className="top__content-first-information">
        <h3 className="top__content-first-label">
          <span>{t('top.content_first_label')}</span>
        </h3>
        <p className="top__content-first-text top__content-text">{t('top.content_first_text')}</p>
        <Link to={isSignedIn ? ROUTER_URL.SCAN_BOOKING : ROUTER_URL.SIGNUP}>
          <button className="top__content-first-button top__button">
            <EditIcon />
            <p>{t('top.content_first_button')}</p>
          </button>
        </Link>
      </div>
    </div>
  )

  const renderContentSecondPart = (
    <div className="top__content-second">
      <div className="top__content-second-hero top__content-hero">
        <div className="top__content-hero-wrapper">
          <div className="top__content-second-at">
            {screenSize.width > breakPoint ? <AAndT /> : <AAndT width="7.5rem" height="8.5rem" />}
          </div>
          {renderImageInContent(1)}
          <div className="top__content-second-quote">
            <PhysicalText />
          </div>
        </div>
      </div>
      <div className="top__content-second-information">
        <h3 className="top__content-second-label">{t('top.content_second_label')}</h3>
        <p className="top__content-second-text top__content-text">{t('top.content_second_text')}</p>
        <Link to={ROUTER_URL.SCAN_BOOKING}>
          <button className="top__content-second-button top__button">
            <CameraIcon />
            <p>{t('top.content_second_button')}</p>
          </button>
        </Link>
      </div>
      <div className="top__content-second-dots">
        <Dots2x9Content />
      </div>
    </div>
  )

  const renderContentThirdPart = (
    <div className="top__content-third">
      <div className="top__content-third-lines">
        {screenSize.width > breakPoint ? <LinesContent /> : null}
      </div>
      <div className="top__content-third-dots">
        <Dots5x7Content />
      </div>
      <div className="top__content-third-hero top__content-hero">
        <div className="top__content-hero-wrapper">
          <div className="top__content-third-ar">
            <AAndR />
          </div>
          {renderImageInContent(2)}
          <div className="top__content-third-quote">
            <AManText />
          </div>
        </div>
      </div>
      <div className="top__content-third-information">
        <h3 className="top__content-third-label">{t('top.content_third_label')}</h3>
        <p className="top__content-third-text top__content-text">{t('top.content_third_text')}</p>
        <div className="top__content-third-buttons">
          <AppStoreTop width="13.5rem" height="4rem" />
          <GooglePlay width="13.5rem" height="4rem" />
          <br />
          <br />
          <span>
            <Trans
              i18nKey="top.content_register_customer"
              components={{
                a: <a href={isSignedIn ? ROUTER_URL.SCAN_BOOKING : ROUTER_URL.SIGNUP} className="text-link-signup" />,
              }}
            />
          </span>
        </div>
      </div>
      <div className="top__content-third-circle">
        <CircleContent />
      </div>
    </div>
  )

  const renderContent = (
    <div className="top__content" id="top__content">
      <div className="top__content-box1">
        {screenSize.width > breakPoint ? <BoxContent /> : <BoxContentMobile />}
      </div>
      <div className="top__content-description">
        <span className="top__fourDots-icon">
          <FourDotsIconMedium />
        </span>
        <h2 className="top__content-label">
          <span>{t('top.content_label_a')}</span>
          <span>{t('top.content_label_b')}</span>
        </h2>
      </div>
      {renderContentFirstPart}
      {renderContentSecondPart}
      {renderContentThirdPart}
    </div>
  )

  const renderSpread = (
    <div className="top__spread" id="top__spread">
      <div className="top__spread-description">
        <span className="top__fourDots-icon">
          {screenSize.width > breakPoint ? <FourDotsIconMedium /> : <FourDotsIconSmall />}
        </span>
        <h2 className="top__spread-label">
          <span>{t('top.spread_label_a')}</span>
          <span>
            {t('top.spread_label_b')}
            <span>{t('top.spread_label_c')}</span>
          </span>
        </h2>
      </div>
      <div className="top__spread-inner">
        <div className="top__spread-content">
          <div className="top__spread-communication top__spread-grid">
            <div className="top__spread-communication-header top__spread-grid-header">
              <Communications />
              <div className="top__spread-communication-title top__spread-grid-title">
                Communication
              </div>
            </div>
            <div className="top__spread-grid-info">
              <h3 className="top__spread-communication-label top__spread-grid-label">
                {t('top.spread_communication_label')}
              </h3>
              <p className="top__spread-grid-text">{t('top.spread_communication_text')}</p>
            </div>
          </div>
          <div className="top__spread-games top__spread-grid">
            <div className="top__spread-games-header top__spread-grid-header">
              <Games />
              <div className="top__spread-games-title top__spread-grid-title">Games</div>
            </div>
            <div className="top__spread-grid-info">
              <h3 className="top__spread-games-label top__spread-grid-label">
                {t('top.spread_games_label')}
              </h3>
              <p className="top__spread-grid-text">{t('top.spread_games_text')}</p>
            </div>
          </div>
          <div className="top__spread-videos top__spread-grid">
            <div className="top__spread-videos-header top__spread-grid-header">
              <Streaming />
              <div className="top__spread-videos-title top__spread-grid-title">Streaming</div>
            </div>
            <div className="top__spread-grid-info">
              <h3 className="top__spread-videos-label top__spread-grid-label">
                {t('top.spread_streaming_label')}
              </h3>
              <p className="top__spread-grid-text">{t('top.spread_streaming_text')}</p>
            </div>
          </div>
        </div>
        <p className="top__spread-services">{t('top.spread_services')}</p>
        <div className="top__spread-coming-soon">
          <p className="top__spread-coming-soon-title">Coming soon</p>
          <p className="top__spread-coming-soon-date">{t('top.spread_coming_soon_text')}</p>
        </div>
        <div className="top__spread-dots">
          <Dots8x7Spread />
        </div>
      </div>
    </div>
  )

  const renderCustomization = (
    <div className="top__customization" id="top__customization">
      <div className="top__customization-title">
        <span className="top__fourDots-icon">
          <FourDotsIconMedium />
        </span>
        <h2 className="top__customization-label">
          <span>{t('top.customization_label_a')}</span>
          <span>
            {t('top.customization_label_b')}
            <span>{t('top.customization_label_c')}</span>
          </span>
        </h2>
      </div>
      <div className="top__customization-container">
        <div className="top__customization-inner-container">
          <div className="top__customization-decor">
            <DecorCustomization />
          </div>
          <CustomizationImages />
        </div>
      </div>
      <div className="top__customization-barCode">
        <BarCodeCustomization />
      </div>
    </div>
  )

  const renderNews = (
    <div className="top__news" id="top__news">
      <div className="top__news-inner">
        <div className="top__news-title">
          <span className="top__fourDots-icon">
            <FourDotsIconSmall />
          </span>
          <h2>News</h2>
        </div>
        <div className="top__news-content">
          {listNews?.slice(0, 3).map((item: any) => (
            <Link to={`${ROUTER_URL.NEWS}/${item.id}`} key={item.id}>
              <div className="top__news-grid" key={item.id}>
                <div className="top__news-grid-image">
                  <img
                    src={item.thumbnail === '' ? placeholderImage : item.thumbnail}
                    alt={item.title}
                  />
                </div>
                <h2 className="top__news-grid-title">{item.title}</h2>
                <p
                  className="top__news-grid-text"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
                <p className="top__news-grid-date">{
                  localStorage.getItem('i18nextLng') === 'ja'
                  ? moment(new Date(item.display_from_date).toISOString()).format('YYYY年MM月DD日')
                  : moment(new Date(item.display_from_date).toISOString()).format('MM/DD/YYYY')}</p>
              </div>
            </Link>
          ))}
        </div>
        <div className="top__news-button-area">
          <Link to={ROUTER_URL.NEWS}>
            <button className="top__news-button top__button">
              <p>{t('top.news_button')}</p>
            </button>
          </Link>
        </div>
      </div>
    </div>
  )

  const listInnerContainerPart = [
    { id: 0, render: renderContent },
    { id: 1, render: renderSpread },
    { id: 2, render: renderCustomization },
    { id: 3, render: renderNews },
  ]

  const renderInnerContainerPart = (
    <>
      {listInnerContainerPart.map((item, id) => (
        <InView
          key={id}
          as="div"
          threshold={id === 0 ? 0.1 : 0.2}
          onChange={(inView) => handleInView(inView, id)}
        >
          {item.render}
        </InView>
      ))}
    </>
  )

  return (
    <div className="top__container responsive-top-screen">
      <TopHeader
        onReload={onInit}
        isVideoEnd={isVideoEnd}
        isScrollOverRequiredPixels={isScrollOverRequiredPixels}
        setIsVideoEnd={setIsVideoEnd}
        scrollY={scrollY}
      />
      {renderNavigation}
      {renderDownload}
      <div
        className={classNames('top__default-padding', {
          'top__english-version': i18n.language === 'en',
        })}
      >
        {renderInnerContainerPart}
        <div className="top__footer">
          <Footer onReload={onInit} logo="logo-dark" />
        </div>
      </div>
    </div>
  )
}

export default Top
