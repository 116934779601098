import { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BrowserPersistance from '../../../../utils/BrowserPersistence'
import { REGISTER } from '../../../../utils/endpoints'
import { ERROR_TYPE, POST, ROUTER_URL } from '../../../../utils/const'
import { validator, textFields, defaultValues } from './constants'
import { useSelector } from 'react-redux'
import { TextInput } from './../../components/atoms/text-input/index'
import { SelectInput } from './../../components/atoms/select-input/index'
import { CheckboxInput } from '../../components/atoms/checkbox-input'
import {
  Button
} from '../../components/atoms/buttons'
import { Breadcrumb } from './../../components/atoms/breadcrumb/index'
import { isEmpty } from 'lodash'

const localStorage = new BrowserPersistance()

interface SignupProps {
  notify: any
  callApi: any
}

const Signup: React.FC<SignupProps> = ({ notify, callApi }) => {

  const title = '会員登録 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = '会員登録のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [t] = useTranslation('translation')
  const [errorApi, setErrorApi] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false)

  const lang = useSelector((state: any) => state.signin.lang)

  const [errNoti, setErrNoti] = useState('')

  const options = [
    { value: 'male', name: 'sex', label: t('signup.sex_male') },
    { value: 'female', name: 'sex', label: t('signup.sex_female') },
  ]
  const location = window.location.search
  const urlParams = new URLSearchParams(location)
  const no_error = urlParams.get('error')
  useEffect(() => {
    if (no_error === 'no_email_error') {
      notify(t('signup.no_email_error'), ERROR_TYPE)
    }
    if (no_error === 'email_exist_error') {
      notify(t('signup.email_exist_error'), ERROR_TYPE)
    }
  }, [])

  const history = useHistory()
  const methods = useForm<any>({
    defaultValues,
    resolver: validator(t),
  })
  const { handleSubmit, setValue, getValues, clearErrors, errors } = methods

  useEffect(() => {
    if (!isEmpty(errors) || errNoti) onValidate()
  }, [localStorage.getItem('i18nextLng')])

  function onValidate(e?: any) {
    e?.preventDefault()
    const listField = ['email']
    listField.forEach((item: any) => {
      const value = getValues(item) + ''
      setValue(item, value.trim() || '')
    })
    handleSubmit(onSubmit)()
    setIsFirstLoad(true)
  }

  const onSubmit = async (values: any) => {
    if (loading) return
    setLoading(true)
    const params = {
      login_id: values.login_id,
      login_pw: values.login_pw,
      login_pw_cf: values.login_pw_cf,
      mail_address: values.email,
      policy_agree: true,
      sex: values.sex,
    }
    try {
      const result = await callApi(REGISTER, POST, params)
      if (result.status === 200) {
        history.push({ pathname: ROUTER_URL.SIGNUP_CONFIRM, state: { email: values.email } })
      }
      setLoading(false)

      localStorage.setItem('token', result.data.token)
    } catch (error) {
      if (error?.message) {
        setErrorApi(error)
        setErrNoti(error?.message)
      }
      setLoading(false)
    }
  }

  function resetError() {
    setErrNoti('')
    setErrorApi({})
  }

  const renderTextFields = (
    <div className="signup__block">
      {textFields.map(({ name, type, isRequire, placeholder, helpText }) => {
        return (
          <TextInput
            key={name}
            type={type}
            name={name}
            isRequire={isRequire}
            placeholder={t(placeholder)}
            helpText={helpText}
            errorsBorder={errorApi?.field === name}
            handleChange={resetError}
          />
        )
      })}
    </div>
  )

  const renderSelectFields = (
    <div className="signup__block distance-form-to-checkbox">
      <SelectInput
        name="sex"
        placeholder={t('signup.sex_label')}
        options={options}
        onChangeProps={() => setErrNoti('')}
      />
      {errNoti && (
        <div className="error-message">
          <span>※{errNoti}</span>
        </div>
      )}
    </div>
  )

  const checkboxLabel = (
    <label className="checkbox__label">
      <span className="checkbox__label--sub">{t('signup.accept_first')}</span>{' '}
      <a className="text-link" href={ROUTER_URL.TERM_OF_SERVICE} target="_blank" rel="noopener noreferrer" >
        {t('signup.accept_highlight_first')}
      </a>{' '}
      <span className="checkbox__label--sub">{t('signup.accept_second')}</span>{' '}
      <a className="text-link" href={ROUTER_URL.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer" >
        {t('signup.accept_highlight_second')}
      </a>{' '}
      <span className="checkbox__label--sub">{t('signup.accept_third')}</span>
    </label>
  )

  const renderCheckboxFields = (
    <>
      <div
        className="signup__checkbox distance-checkbox-to-btn"
        style={{ marginLeft: 0, maxWidth: '50rem' }}
      >
        <CheckboxInput onChangeProps={() => setErrNoti('')} label={checkboxLabel} name="accept" />
      </div>
    </>
  )

  useEffect(() => {
    if (isFirstLoad) {
      clearErrors()
      handleSubmit(onSubmit)()
    }
  }, [lang])

  const renderRegister = (
    <div className="signup__register distance-btn-to-line">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button type="button" onClick={onValidate}>
          {t('signup.register_uppercase')}
        </Button>
      </div>
    </div>
  )

  const list = [
    {
      name: t('signup.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('signup.signup'),
      link: ROUTER_URL.SIGNIN,
    },
  ]
  const renderBreadcrumb = (
    <div className="signup__breadcrumb">
      <Breadcrumb crumbs={list} />
    </div>
  )

  return (
    <div className="signup default-padding">
      {renderBreadcrumb}
      <div className="signup-form">
        <h2 className="title-h2 distance-h2-to-form">{t('signup.describe')}</h2>
        <FormProvider {...methods}>
          <form onSubmit={onValidate}>
            {renderTextFields}
            {renderSelectFields}
            {renderCheckboxFields}
            {renderRegister}
          </form>
        </FormProvider>
        <div className="signup__aside">
          <div
            className="text-normal-second"
            style={{ textAlign: 'center', fontWeight: 400 }}
            id="text"
          >
            <span>{t('signup.refer_login_first')}</span>
            <Link to={ROUTER_URL.SIGNIN} className="text-link">
              <span>{t('signup.log_in')}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup
