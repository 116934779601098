import React from 'react'

interface TwitterLogoProps {
  width?: string
  height?: string                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
}
const TwitterLogo: React.FC<TwitterLogoProps> = ({ width, height }) => {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width={width || '48'} height={height || '48'}><path fill="#03a9f4" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"/><path fill="#fff" d="M36,17.12c-0.882,0.391-1.999,0.758-3,0.88c1.018-0.604,2.633-1.862,3-3 c-0.951,0.559-2.671,1.156-3.793,1.372C31.311,15.422,30.033,15,28.617,15C25.897,15,24,17.305,24,20v2c-4,0-7.9-3.047-10.327-6 c-0.427,0.721-0.667,1.565-0.667,2.457c0,1.819,1.671,3.665,2.994,4.543c-0.807-0.025-2.335-0.641-3-1c0,0.016,0,0.036,0,0.057 c0,2.367,1.661,3.974,3.912,4.422C16.501,26.592,16,27,14.072,27c0.626,1.935,3.773,2.958,5.928,3c-1.686,1.307-4.692,2-7,2 c-0.399,0-0.615,0.022-1-0.023C14.178,33.357,17.22,34,20,34c9.057,0,14-6.918,14-13.37c0-0.212-0.007-0.922-0.018-1.13 C34.95,18.818,35.342,18.104,36,17.12"/></svg>

    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35 40H5C2.24 40 0 37.76 0 35V5C0 2.24 2.24 0 5 0H35C37.76 0 40 2.24 40 5V35C40 37.76 37.76 40 35 40Z"
        fill="#1D9BF0"
      />
      <path
        d="M15.3617 30.16C24.7917 30.16 29.9517 22.34 29.9517 15.57C29.9517 15.35 29.9517 15.13 29.9417 14.91C30.9417 14.19 31.8117 13.28 32.5017 12.25C31.5817 12.66 30.5917 12.93 29.5517 13.06C30.6117 12.43 31.4217 11.42 31.8117 10.22C30.8217 10.81 29.7217 11.23 28.5517 11.46C27.6117 10.46 26.2817 9.83997 24.8117 9.83997C21.9817 9.83997 19.6817 12.14 19.6817 14.97C19.6817 15.37 19.7317 15.76 19.8117 16.14C15.5517 15.93 11.7717 13.88 9.24172 10.78C8.80172 11.54 8.55172 12.42 8.55172 13.36C8.55172 15.14 9.46172 16.71 10.8317 17.63C9.99172 17.6 9.20172 17.37 8.51172 16.99C8.51172 17.01 8.51172 17.03 8.51172 17.06C8.51172 19.54 10.2817 21.62 12.6217 22.09C12.1917 22.21 11.7417 22.27 11.2717 22.27C10.9417 22.27 10.6217 22.24 10.3117 22.18C10.9617 24.22 12.8617 25.7 15.1017 25.74C13.3417 27.12 11.1317 27.94 8.73172 27.94C8.32172 27.94 7.91172 27.92 7.51172 27.87C9.77172 29.31 12.4717 30.16 15.3617 30.16Z"
        fill="white"
      />
    </svg>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
  )
}

export default TwitterLogo
