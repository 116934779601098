import { useTranslation } from 'react-i18next'
import { Button } from '../../components/atoms/buttons'
import { CloseIcon } from '../../../../assets/images'

interface DeleteModalProps {
  toggleModal: () => void
  deleteAvatar: () => void
  name?: string
}

const DeleteModal: React.FC<DeleteModalProps> = ({ toggleModal, deleteAvatar, name }) => {
  const [t] = useTranslation('translation')
  return (
    <>
      <div className="detail-avatar__delete-modal">
        <div className="detail-avatar__delete-modal_header">
          <div className="detail-avatar__delete-modal_header-text">
            <div className="pop-text-bold-big">{t('detail-avatar.delete_title')}</div>
          </div>
          <button
            className="detail-avatar__delete-modal_header-icon close-icon"
            onClick={toggleModal}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="detail-avatar__delete-modal_name distance-margin-modal-h2">
          <p style={{ textAlign: 'center' }} className="pop-big-body-text">
            「 {name} 」
          </p>
        </div>
        <div className="detail-avatar__delete-modal_content padding-modal-content-to-line distance-modal-content-to-btn">
          <p className="pop-large-body-text">{t('detail-avatar.delete_content1')}</p>
          <p className="pop-large-body-text">{t('detail-avatar.delete_content2')}</p>
          <p className="pop-large-body-text">{t('detail-avatar.delete_content3')}</p>
          <p className="pop-large-body-text">{t('detail-avatar.delete_content4')}</p>
        </div>
        <div className="detail-avatar__delete-modal_button">
          <Button className="cancel shortnormal" onClick={toggleModal}>
            {t('detail-avatar.delete_btn1')}
          </Button>
          <Button className="shortnormal" onClick={deleteAvatar}>
            {t('detail-avatar.delete_btn2')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default DeleteModal
