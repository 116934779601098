import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconGroup3: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" y="11" width="138" height="138" rx="9" fill="white" />
      <rect x="11" y="11" width="138" height="138" rx="9" stroke="#FF6C6C" strokeWidth="2" />
      <path
        d="M62.9875 94.7726H79.3745C79.4595 94.7721 79.5409 94.7379 79.6009 94.6776C79.6608 94.6172 79.6945 94.5356 79.6945 94.4506V75.4796L83.6845 98.4096C83.6919 98.4516 83.7077 98.4918 83.7308 98.5277C83.7539 98.5636 83.7839 98.5945 83.8191 98.6187C83.8542 98.6429 83.8939 98.6599 83.9357 98.6686C83.9775 98.6773 84.0206 98.6777 84.0625 98.6696L92.5355 97.0076C92.6172 96.9887 92.6882 96.9385 92.7332 96.8678C92.7783 96.7972 92.7938 96.7116 92.7765 96.6296C92.0545 93.2216 85.8015 63.1786 84.3575 54.2556C82.9135 45.3326 77.0665 43.5556 76.3835 43.3766C76.3408 43.3658 76.2961 43.3658 76.2535 43.3766L73.0155 43.9296L62.9265 45.4636C62.8504 45.474 62.7807 45.5117 62.7303 45.5696C62.6799 45.6276 62.6523 45.7018 62.6525 45.7786C62.6526 45.7022 62.6251 45.6283 62.5752 45.5704C62.5252 45.5126 62.4561 45.4746 62.3805 45.4636L52.3045 43.9246L49.0665 43.3716C49.0231 43.3608 48.9778 43.3608 48.9345 43.3716C48.2515 43.5496 42.3925 45.3396 40.9635 54.2506C39.5345 63.1616 33.2665 93.2166 32.5455 96.6246C32.5371 96.666 32.5369 96.7088 32.5451 96.7503C32.5533 96.7918 32.5696 96.8312 32.5931 96.8664C32.6167 96.9015 32.6469 96.9317 32.6822 96.955C32.7174 96.9784 32.757 96.9946 32.7985 97.0026L41.2705 98.6666C41.3122 98.6745 41.3551 98.674 41.3966 98.6652C41.4382 98.6564 41.4776 98.6394 41.5125 98.6152C41.5474 98.591 41.5771 98.5601 41.5999 98.5243C41.6228 98.4884 41.6383 98.4485 41.6455 98.4066L45.6455 75.4796V94.4506C45.6455 94.5356 45.6791 94.6172 45.7391 94.6776C45.799 94.7379 45.8804 94.7721 45.9655 94.7726H62.9955H62.9875Z"
        fill="white"
        stroke="#111948"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M45.6367 80.6006V62.4716" stroke="#111948" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M57.293 48.3396V62.6176" stroke="#111948" strokeWidth="2" strokeMiterlimit="10" />
      <path
        d="M62.6641 48.1836L73.7891 36.0316L76.3291 43.3586C75.0801 48.6316 62.6641 48.1836 62.6641 48.1836Z"
        fill="white"
        stroke="#111948"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M73.354 35.3276C72.006 34.2566 68.933 32.6406 62.665 32.6406C56.397 32.6406 53.328 34.2566 51.978 35.3276C51.8483 35.4308 51.7411 35.5594 51.663 35.7055C51.5849 35.8517 51.5375 36.0122 51.5237 36.1774C51.51 36.3425 51.5301 36.5087 51.583 36.6658C51.6358 36.8228 51.7202 36.9674 51.831 37.0906L61.406 47.6186C61.5653 47.7936 61.7594 47.9334 61.9758 48.029C62.1923 48.1247 62.4263 48.1741 62.663 48.1741C62.8996 48.1741 63.1337 48.1247 63.3501 48.029C63.5666 47.9334 63.7607 47.7936 63.92 47.6186L73.506 37.0906C73.6165 36.9673 73.7007 36.8227 73.7533 36.6656C73.806 36.5086 73.8261 36.3425 73.8123 36.1775C73.7985 36.0124 73.7512 35.8519 73.6733 35.7058C73.5953 35.5597 73.4884 35.431 73.359 35.3276H73.354Z"
        fill="white"
        stroke="#111948"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M79.6953 80.6007V62.4717" stroke="#111948" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M68.0391 48.3398V62.6178" stroke="#111948" strokeWidth="2" strokeMiterlimit="10" />
      <path
        d="M62.66 48.1836L51.542 36.0316L49 43.3586C50.251 48.6316 62.66 48.1836 62.66 48.1836Z"
        fill="white"
        stroke="#111948"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M130.203 116.993L131.451 114.733L123.823 81.7982C123.776 81.5662 123.731 81.3412 123.686 81.1292C122.179 73.6692 122.179 71.2692 111.248 67.8292L101.181 69.1532L91.1129 67.8262C80.1829 71.2692 80.1829 73.6692 78.6679 81.1372C78.6259 81.3492 78.5809 81.5742 78.5329 81.8062L70.9059 114.732L72.1539 116.992C71.5399 120.116 71.0859 122.404 70.9059 123.23C70.8875 123.315 70.9035 123.404 70.9504 123.477C70.9972 123.549 71.0712 123.601 71.1559 123.619L78.8289 125.346C78.8723 125.355 78.917 125.354 78.9603 125.345C79.0036 125.336 79.0447 125.319 79.0811 125.294C79.1176 125.269 79.1487 125.237 79.1727 125.199C79.1967 125.162 79.2131 125.121 79.2209 125.077L80.6459 118.62L82.9139 117.273L84.9139 100.11L85.0139 99.6632L84.6139 123.163H117.729L117.329 99.6632L117.439 100.12L119.439 117.283L121.709 118.63L123.129 125.084C123.137 125.128 123.153 125.169 123.177 125.206C123.201 125.244 123.232 125.276 123.269 125.301C123.305 125.326 123.346 125.343 123.39 125.352C123.433 125.361 123.477 125.362 123.521 125.353L131.196 123.629C131.239 123.621 131.28 123.605 131.317 123.581C131.353 123.557 131.385 123.526 131.409 123.49C131.434 123.454 131.45 123.413 131.459 123.37C131.467 123.327 131.467 123.283 131.458 123.24C131.273 122.404 130.819 120.117 130.203 116.993Z"
        fill="white"
        stroke="#111948"
        strokeWidth="2"
        strokeLinejoin="bevel"
      />
      <path
        d="M111.248 67.7042V62.0232C111.247 61.7153 111.125 61.4203 110.907 61.2028C110.689 60.9853 110.393 60.8632 110.085 60.8632H92.2733C91.9656 60.8632 91.6706 60.9854 91.453 61.203C91.2355 61.4205 91.1133 61.7156 91.1133 62.0232V67.7042C91.1134 67.7447 91.1184 67.785 91.1283 67.8242V67.8412C91.1369 67.8732 91.1494 67.9041 91.1653 67.9332C91.3199 68.1815 91.5006 68.4124 91.7043 68.6222C91.7156 68.6333 91.7262 68.645 91.7363 68.6572C91.8483 68.7692 91.9683 68.8822 92.0953 68.9892C92.1823 69.0592 92.2753 69.1292 92.3693 69.1992C92.4193 69.2362 92.4643 69.2762 92.5193 69.3142C92.8136 69.5197 93.1213 69.7055 93.4403 69.8702L93.5103 69.9072C93.7923 70.0522 94.0963 70.1862 94.4103 70.3142C94.8844 70.5082 95.3692 70.6751 95.8623 70.8142C96.0793 70.8762 96.3043 70.9312 96.5313 70.9862C96.5963 71.0012 96.6583 71.0182 96.7233 71.0312C96.7883 71.0442 96.8983 71.0662 96.9733 71.0832C97.7252 71.2379 98.486 71.3461 99.2513 71.4072C99.7253 71.4472 100.212 71.4742 100.706 71.4822H101.592C102.092 71.4822 102.573 71.4472 103.049 71.4072C103.813 71.3461 104.573 71.2379 105.323 71.0832C105.41 71.0662 105.5 71.0512 105.585 71.0312L105.867 70.9642L106.301 70.8572C106.845 70.7117 107.379 70.5334 107.901 70.3232C108.215 70.1962 108.52 70.0612 108.801 69.9162C108.838 69.8992 108.873 69.8762 108.911 69.8562C109.051 69.7812 109.19 69.7062 109.32 69.6292C109.395 69.5842 109.462 69.5372 109.532 69.4922C109.618 69.4399 109.702 69.3831 109.782 69.3222C109.852 69.2652 109.882 69.2452 109.932 69.2072C110.024 69.1372 110.119 69.0672 110.204 68.9972C110.331 68.8902 110.454 68.7772 110.563 68.6652L110.595 68.6302C110.819 68.4211 111.016 68.1861 111.184 67.9302C111.226 67.8622 111.248 67.784 111.248 67.7042Z"
        fill="white"
        stroke="#111948"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M101.434 62.7792C106.499 62.7792 110.097 61.1302 110.097 60.8632C110.097 60.5962 106.499 58.9492 101.434 58.9492H100.934C95.8695 58.9492 92.2695 60.5962 92.2695 60.8632C92.2695 61.1302 95.8695 62.7792 100.94 62.7792H101.434Z"
        fill="white"
        stroke="#111948"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M84.8516 110.224L85.2306 89.7051"
        stroke="#111948"
        strokeWidth="2"
        strokeLinejoin="bevel"
      />
      <path
        d="M117.5 110.224L117.121 89.7051"
        stroke="#111948"
        strokeWidth="2"
        strokeLinejoin="bevel"
      />
      <path
        d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33Z"
        fill="#FF6C6C"
      />
      <path
        d="M18.2434 16.4706L23.5814 11.1326C23.7047 11.0182 23.8035 10.8802 23.8722 10.7267C23.9409 10.5732 23.9779 10.4075 23.9811 10.2394C23.9842 10.0713 23.9535 9.90429 23.8906 9.74834C23.8278 9.59239 23.7342 9.45071 23.6154 9.33175C23.4965 9.2128 23.355 9.11902 23.1991 9.05601C23.0432 8.993 22.8762 8.96206 22.7081 8.96504C22.54 8.96802 22.3742 9.00485 22.2207 9.07333C22.0671 9.14182 21.929 9.24056 21.8144 9.36364L16.4744 14.7016L11.1364 9.36364C11.0209 9.24474 10.8829 9.15 10.7304 9.08491C10.5779 9.01983 10.414 8.9857 10.2482 8.98451C10.0824 8.98333 9.91808 9.01511 9.76468 9.078C9.61129 9.1409 9.47192 9.23366 9.35469 9.35089C9.23746 9.46812 9.1447 9.60749 9.0818 9.76088C9.01891 9.91428 8.98713 10.0786 8.98831 10.2444C8.9895 10.4102 9.02363 10.5741 9.08871 10.7266C9.1538 10.8791 9.24854 11.0171 9.36744 11.1326L14.7054 16.4706L9.36744 21.8106C9.13837 22.0464 9.01128 22.3628 9.01363 22.6914C9.01598 23.0201 9.14759 23.3347 9.38001 23.5671C9.61242 23.7995 9.92697 23.9311 10.2556 23.9334C10.5843 23.9358 10.9007 23.8087 11.1364 23.5796L16.4744 18.2416L21.8144 23.5776C22.052 23.7955 22.3645 23.9132 22.6868 23.9063C23.0091 23.8994 23.3162 23.7683 23.5442 23.5404C23.7722 23.3125 23.9035 23.0055 23.9106 22.6832C23.9177 22.3609 23.8002 22.0484 23.5824 21.8106L18.2434 16.4706Z"
        fill="white"
      />
    </svg>
  )
}

export default IconGroup3
