import React, { useEffect } from 'react'
import Card from '../../../../../assets/images/svgs/card-credit'
import { Button } from '../../../components/atoms/buttons'
import { Modal } from '../../../components/modules/modal'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Cleave from 'cleave.js/react'
import Wrapper from '../../../components/modules/_wrapper'
import {
  INFO_CREADIT,
  CARD_DEFAULT,
  DELETE_CARD,
  ADD_PAYMENT_CARD,
} from '../../../../../utils/endpoints'
import DeleteCredit from '../delete-credit-modal'
import { ERROR_TYPE, POST, ROUTER_URL } from '../../../../../utils/const'

interface ManageCreditProps {
  notify?: Function
  callApi: any
}

interface CreditType {
  brand: string
  series: string
  'user-name': string
  'expired-date': string
  'security-number': string
}

const ManageCredit: React.FC<ManageCreditProps> = ({ notify = () => {}, callApi }) => {

  const title = 'クレジット | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'クレジットについてのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const initialCreditList = [
    {
      brand: 'MasterCard',
      series: '1234123412343469',
      'user-name': 'TRINH MINH',
      'expired-date': '09/21',
      'security-number': '195',
    },
    {
      brand: 'VISA',
      series: '12341234123569',
      'user-name': 'VU BAO',
      'expired-date': '09/21',
      'security-number': '195',
    },
  ]
  const [t] = useTranslation('translation')
  const [creditList, setCreditList] = React.useState(initialCreditList)
  const [listCardCredit, setListCardCredit] = React.useState([])
  const [cardId, setCardId] = React.useState('')
  const [showModal, setShowModal] = React.useState(false)
  const [numberCard, setNumberCard] = React.useState('')
  const [defautNumber, setDefaultNumber] = React.useState('')
  const [brandCard, setBrandCard] = React.useState('')
  const [defaultBrandCard, setDefaultBrandCard] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [isLoadingSetting, setisLoadingSetting] = React.useState(false)
  const registerBrand = 'VISA'
  const defaultValues = {
    'user-name': '',
    series: '',
    'expired-date': '',
    'security-number': '',
  }
  const list = [
    {
      name: t('manage_credit.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('manage_credit.manage_credit'),
      link: ROUTER_URL.MANAGE_CREDIT,
    },
  ]

  const validator = (t: any) => {
    return yupResolver(
      Yup.object().shape({
        series: Yup.string().required(t('manage-credit.error_field_1')),
        'expired-date': Yup.string().required(t('manage-credit.error_field_2')),
        'security-number': Yup.string().required(t('manage-credit.error_field_3')),
      })
    )
  }
  const methods = useForm({
    defaultValues,
    resolver: validator(t),
  })
  const toggleModal = () => {
    setShowModal((showModal) => !showModal)
  }
  React.useEffect(() => {
    getListCardCredit()
  }, [])
  const getListCardCredit = async () => {
    try {
      const result = await callApi(INFO_CREADIT)
      if (result.status === 200) {
        for (let card of result.data.data) {
          if (card.card_default) {
            setCardId(card.card_id)
            setDefaultNumber(card.card_number)
            setDefaultBrandCard(card.card_brand)
          }
        }
        setListCardCredit(result.data.data)
      }
    } catch (error) {
    }
  }
  const { handleSubmit, control, errors, reset } = methods
  const errorMessageSeries = errors?.series?.message
  const errorMessageExpiredDate = errors?.['expired-date']?.message
  const errorMessageSecurityNumber = errors?.['security-number']?.message

  const registerInformationList = [
    {
      name: 'series',
      label: t('manage_credit.series'),
      placeholder: '1234 - 1234 - 1234 - 1234',
      isRequired: true,
      options: { creditCard: true, delimiter: ' - ' },
      errorMessage: errorMessageSeries,
    },
    {
      name: 'expired-date',
      label: t('manage_credit.expired_date'),
      placeholder: 'MM/YY',
      isRequired: true,
      options: { date: true, datePattern: ['m', 'y'] },
      errorMessage: errorMessageExpiredDate,
    },
    {
      name: 'security-number',
      label: t('manage_credit.security_number'),
      placeholder: '000',
      isRequired: true,
      options: { numericOnly: true, blocks: [4] },
      errorMessage: errorMessageSecurityNumber,
    },
  ]
  const onSubmit = async (data: CreditType, event: any) => {
    if (isLoading) return
    setIsLoading(true)
    onAddCredit({
      ...data,
      brand: registerBrand,
      series: data.series.split(' - ').join(''),
    })
    const params = {
      card_cvv: data['security-number'],
      card_exp_month: data['expired-date'],
      card_number: data['series'].split(' - ').join(''),
    }
    try {
      const result = await callApi(ADD_PAYMENT_CARD, POST, params)
      if (result.status === 200) {
        getListCardCredit()
      }
    } catch (error: any) {
      notify(error?.message, ERROR_TYPE)
    }
    reset()
    setIsLoading(false)
  }

  const onDeleteCardCredit = async () => {
    const params = {
      card_id: cardId,
    }
    try {
      const result = await callApi(DELETE_CARD, POST, params)
      if (result.status === 200) {
        getListCardCredit()
        toggleModal()
      }
    } catch (error: any) {
      toggleModal()
      notify(error?.message, ERROR_TYPE)
    }
  }
  const onAddCredit = (credit: CreditType) => {
    const newCreditList = creditList.concat(credit)
    setCreditList(newCreditList)
  }

  const onAddDefaultCardCredit = async () => {
    if (isLoadingSetting) return
    setisLoadingSetting(true)
    const params = {
      card_id: cardId,
    }
    try {
      const result = await callApi(CARD_DEFAULT, POST, params)
      if (result.status === 200) {
        getListCardCredit()
      }
    } catch (error: any) {
      notify(error?.message, ERROR_TYPE)
    }
    setisLoadingSetting(false)
  }
  const getValueCard = (cardId: any, numberCard: any, brandCard: any) => {
    setCardId(cardId)
    setNumberCard(numberCard)
    setBrandCard(brandCard)
  }
  useEffect(() => {
  }, [localStorage.getItem('i18nextLng')])

  const renderSelectCardArea = (
    <div className="manage-credit__select">
      <div className="manage-credit__selectBox">
        <p className="manage-credit__select-description">{t('manage_credit.description')}</p>
        {listCardCredit.length === 0 ? (
          <div className="manage-credit__select-bars--text">
            <div className="manage-credit__select-bars--textbox">
              <p>{t('manage_credit.null')}</p>
            </div>
          </div>
        ) : null}
        <div className="manage-credit__select-bars">
          {listCardCredit?.map((item: any) => {
            return (
              <div
                key={item['card_id']}
                className={classNames('manage-credit__select-bar', {
                  'manage-credit__select-active': item['card_default'],
                })}
                onClick={() =>
                  getValueCard(item['card_id'], item['card_number'], item['card_brand'])
                }
              >
                <div className="manage-credit__select-couple">
                  {item['card_default'] ? (
                    <div className="card_default-active">{t('manage_credit.default')}</div>
                  ) : null}
                  <span className="radio__input">
                    <input
                      type="radio"
                      name="credit"
                      checked={item['card_id'] === cardId}
                      className="input__radio"
                      value={item['card_id']}
                    />
                    <span className="radio__control"></span>
                  </span>
                  <label>{item['card_brand']}</label>
                </div>
                <div className="manage-credit__select-series">
                  <span>****-****-****-{item['card_number'].slice(12, 16)}</span>
                </div>
              </div>
            )
          })}
        </div>
        {listCardCredit.length !== 0 ? (
          <div className="manage-credit__select-buttons">
            <Button
              className="shortnormal cancel"
              onClick={() => toggleModal()}
              disabled={isLoadingSetting}
            >
              {t('manage_credit.erase')}
            </Button>
            <Button className="shortnormal" onClick={onAddDefaultCardCredit}>
              {t('manage_credit.edit')}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )


  const renderRegisterInformation = (
    <div className="manage-credit__register-information">
      {registerInformationList.map((item) => (
        <div key={item.name}>
          {item.label && (
            <div className="credit-text-input__wrapper">
              <p className="credit-text-input__label">{item.label}</p>
              {item.isRequired && <span className="input__asterisk">*</span>}
            </div>
          )}
          <Controller
            name={item.name}
            control={control}
            as={
              <Cleave
                placeholder={item.placeholder}
                className="credit-text-input__field"
                options={item.options}
                type="tel"
              />
            }
          />
          {item.errorMessage && (
            <span className="form-field-error-message">{item.errorMessage}</span>
          )}
        </div>
      ))}
    </div>
  )

  const renderRegisterArea = (
    <div className="manage-credit__register">
      <div className="manage-credit__registerBox">
        <h2 className="title-h3">{t('manage_credit.register_title')}</h2>
        <div className="manage-credit__register-content">
          {renderRegisterInformation}
        </div>
        <div className="manage-credit__register-button-area">
          <Button type="submit" className="manage-credit__register-button resolved-button">
            {t('manage_credit.add')}
          </Button>
        </div>
      </div>
    </div>
  )
  return (
    <Wrapper
      breadcrum={list}
      Icon={Card}
      title={t('manage_credit.title')}
      className="manage-credit__container"
      isNoMarginTop={true}
    >
      <Modal
        modalStyles="booking__modal-booking padding-modal-in-h2"
        show={showModal}
        toggleModal={toggleModal}
      >
        <DeleteCredit
          toggleModal={toggleModal}
          numberCard={numberCard}
          defaultNumber={defautNumber}
          deleteCard={onDeleteCardCredit}
          brandCard={brandCard}
          defaultBrandCard={defaultBrandCard}
        />
      </Modal>
      {renderSelectCardArea}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>{renderRegisterArea}</form>
      </FormProvider>
    </Wrapper>
  )
}

export default ManageCredit
