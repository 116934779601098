import * as React from 'react'

interface PhysicalTextProps {
  height?: string
  width?: string
}
const PhysicalText: React.FC<PhysicalTextProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "240"} height={height || "58"} viewBox="0 0 240 58">
      <defs>
        <style>{'.cls-1{isolation:isolate}.cls-2{fill:#12c4c4}'}</style>
      </defs>
      <g
        id="Like_the_physical_the_psychical_is_not_necessarily_in_reality_what_it_appears_to_us_to_be."
        className="cls-1"
        transform="translate(0 .006)"
      >
        <g id="Group_10635" className="cls-1" transform="translate(.733 -.006)">
          <path
            id="Path_697"
            d="M.733.081h1V6.3h3.812v.882H.733z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_698"
            d="M7.9.081h1v7.1h-1z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_699"
            d="M14.5 4.026l-1.284 1.319v1.836h-1v-7.1h1v3.966L17.034.081h1.145l-3 3.2 3.191 3.9H17.2z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_700"
            d="M25.738 6.3v.882h-5.1V.081H25.6v.882h-3.961v2.181h3.527v.863h-3.527V6.3z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_701"
            d="M33.861.963h-2.41V.081h5.816v.882h-2.411v6.218h-1z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_702"
            d="M45.609.081v7.1h-1V4.016h-4.043v3.165h-1.005v-7.1h1.005v3.052h4.039V.081z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_703"
            d="M54.022 6.3v.882h-5.1V.081h4.962v.882h-3.961v2.181h3.527v.863h-3.527V6.3z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_704"
            d="M66.425 2.556c0 1.542-1.105 2.474-2.933 2.474h-1.738v2.151h-1v-7.1H63.5c1.82 0 2.925.934 2.925 2.475zm-1 0c0-1.014-.672-1.593-1.958-1.593h-1.713v3.185h1.708c1.286 0 1.958-.578 1.958-1.592z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_705"
            d="M75.149.081v7.1h-1V4.016h-4.043v3.165h-1v-7.1h1v3.052h4.039V.081z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_706"
            d="M81.152 4.726v2.455h-1V4.706L77.365.081h1.074l2.251 3.742L82.95.081h1z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_707"
            d="M85.365 6.369l.372-.791a3.6 3.6 0 0 0 2.32.822c1.216 0 1.738-.477 1.738-1.1 0-1.735-4.259-.639-4.259-3.276C85.536.934 86.38 0 88.228 0a4.1 4.1 0 0 1 2.26.629l-.331.811a3.6 3.6 0 0 0-1.929-.578c-1.2 0-1.708.508-1.708 1.126 0 1.734 4.26.649 4.26 3.255 0 1.085-.864 2.019-2.723 2.019a4.151 4.151 0 0 1-2.692-.893z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_708"
            d="M93.446.081h1v7.1h-1z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_709"
            d="M97.185 3.631A3.573 3.573 0 0 1 100.912 0a3.459 3.459 0 0 1 2.672 1.106l-.652.638a2.6 2.6 0 0 0-1.98-.844 2.668 2.668 0 0 0-2.762 2.731 2.668 2.668 0 0 0 2.762 2.728 2.6 2.6 0 0 0 1.98-.852l.652.639a3.474 3.474 0 0 1-2.684 1.116 3.571 3.571 0 0 1-3.715-3.631z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_710"
            d="M110.531 5.406h-3.737l-.774 1.775h-1.034l3.184-7.1h1l3.194 7.1H111.3zm-.352-.812l-1.517-3.478-1.517 3.478z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_711"
            d="M114.6.081h1V6.3h3.808v.882H114.6z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_712"
            d="M122.825 6.541a2.061 2.061 0 0 1-.171.731l-.472 1.41h-.632l.371-1.511a.645.645 0 0 1-.421-.63.66.66 0 0 1 .672-.689.65.65 0 0 1 .653.689z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_713"
            d="M130.846.963h-2.411V.081h5.816v.882h-2.411v6.218h-.994z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_714"
            d="M142.593.081v7.1h-1V4.016h-4.043v3.165h-1.005v-7.1h1.005v3.052h4.038V.081z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_715"
            d="M151.006 6.3v.882h-5.1V.081h4.963v.882h-3.958v2.181h3.526v.863h-3.526V6.3z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_716"
            d="M163.409 2.556c0 1.542-1.1 2.474-2.933 2.474h-1.738v2.151h-1v-7.1h2.743c1.819 0 2.928.934 2.928 2.475zm-1 0c0-1.014-.673-1.593-1.959-1.593h-1.708v3.185h1.708c1.282 0 1.955-.578 1.955-1.592z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_717"
            d="M165.543 6.369l.372-.791a3.6 3.6 0 0 0 2.321.822c1.215 0 1.738-.477 1.738-1.1 0-1.735-4.26-.639-4.26-3.276 0-1.1.844-2.029 2.693-2.029a4.107 4.107 0 0 1 2.26.629l-.332.811a3.6 3.6 0 0 0-1.928-.578c-1.2 0-1.708.508-1.708 1.126 0 1.734 4.259.649 4.259 3.255 0 1.085-.864 2.019-2.722 2.019a4.154 4.154 0 0 1-2.693-.888z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_718"
            d="M176.166 4.726v2.455h-1V4.706L172.378.081h1.075l2.247 3.742 2.26-3.742h.994z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_719"
            d="M180.3 3.631A3.572 3.572 0 0 1 184.025 0a3.462 3.462 0 0 1 2.675 1.106l-.653.638A2.6 2.6 0 0 0 184.066.9a2.668 2.668 0 0 0-2.766 2.731 2.668 2.668 0 0 0 2.763 2.728 2.6 2.6 0 0 0 1.979-.852l.653.639a3.476 3.476 0 0 1-2.683 1.116 3.57 3.57 0 0 1-3.712-3.631z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_720"
            d="M195.382.081v7.1h-1V4.016h-4.038v3.165h-1v-7.1h1v3.052h4.038V.081z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_721"
            d="M198.692.081h1v7.1h-1z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_722"
            d="M202.431 3.631A3.573 3.573 0 0 1 206.158 0a3.462 3.462 0 0 1 2.673 1.106l-.653.638A2.6 2.6 0 0 0 206.2.9a2.668 2.668 0 0 0-2.763 2.728 2.668 2.668 0 0 0 2.763 2.731 2.6 2.6 0 0 0 1.979-.852l.653.639a3.476 3.476 0 0 1-2.683 1.116 3.571 3.571 0 0 1-3.718-3.631z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_723"
            d="M215.777 5.406h-3.737l-.774 1.775h-1.034l3.184-7.1h1l3.194 7.1h-1.06zm-.352-.812l-1.517-3.478-1.517 3.478z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_724"
            d="M219.85.081h1V6.3h3.808v.882h-4.808z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_725"
            d="M230.916.081h1v7.1h-1z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
          <path
            id="Path_726"
            d="M234.585 6.369l.372-.791a3.6 3.6 0 0 0 2.321.822c1.215 0 1.737-.477 1.737-1.1 0-1.735-4.259-.639-4.259-3.276 0-1.09.844-2.024 2.692-2.024a4.1 4.1 0 0 1 2.26.629l-.331.811a3.6 3.6 0 0 0-1.929-.578c-1.2 0-1.708.508-1.708 1.126 0 1.734 4.26.649 4.26 3.255 0 1.085-.864 2.019-2.722 2.019a4.154 4.154 0 0 1-2.693-.893z"
            className="cls-2"
            transform="translate(-.733 .006)"
          />
        </g>
        <g id="Group_10636" className="cls-1" transform="translate(.738 25.361)">
          <path
            id="Path_727"
            d="M6.781 25.45v7.1h-.824l-4.219-5.294v5.294h-1v-7.1h.824l4.22 5.294V25.45z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_728"
            d="M9.519 29a3.59 3.59 0 0 1 3.746-3.631 3.632 3.632 0 1 1 0 7.262A3.59 3.59 0 0 1 9.519 29zm6.469 0a2.653 2.653 0 0 0-2.723-2.728 2.728 2.728 0 0 0 0 5.456A2.653 2.653 0 0 0 15.988 29z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_729"
            d="M21.026 26.332h-2.411v-.882h5.816v.882h-2.41v6.218h-1z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_730"
            d="M36.673 25.45v7.1h-.823l-4.22-5.294v5.294h-1v-7.1h.824l4.219 5.294V25.45z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_731"
            d="M45.087 31.668v.882h-5.1v-7.1h4.963v.882h-3.962v2.181h3.526v.863h-3.526v2.292z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_732"
            d="M47.311 29a3.573 3.573 0 0 1 3.727-3.631 3.459 3.459 0 0 1 2.672 1.106l-.653.638a2.592 2.592 0 0 0-1.979-.841 2.728 2.728 0 0 0 0 5.456 2.6 2.6 0 0 0 1.979-.852l.653.639a3.474 3.474 0 0 1-2.682 1.116A3.571 3.571 0 0 1 47.311 29z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_733"
            d="M61.45 31.668v.882h-5.1v-7.1h4.96v.882h-3.959v2.181h3.527v.863h-3.527v2.292z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_734"
            d="M63.634 31.738l.372-.791a3.6 3.6 0 0 0 2.321.822c1.216 0 1.738-.477 1.738-1.095 0-1.734-4.26-.639-4.26-3.276 0-1.095.844-2.029 2.693-2.029a4.107 4.107 0 0 1 2.26.629l-.332.811a3.611 3.611 0 0 0-1.928-.578c-1.2 0-1.708.508-1.708 1.126 0 1.734 4.259.649 4.259 3.255 0 1.085-.864 2.019-2.722 2.019a4.154 4.154 0 0 1-2.693-.893z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_735"
            d="M71.072 31.738l.372-.791a3.6 3.6 0 0 0 2.321.822c1.215 0 1.738-.477 1.738-1.095 0-1.734-4.26-.639-4.26-3.276 0-1.095.844-2.029 2.693-2.029A4.107 4.107 0 0 1 76.2 26l-.332.811a3.611 3.611 0 0 0-1.928-.578c-1.2 0-1.708.508-1.708 1.126 0 1.734 4.259.649 4.259 3.255 0 1.085-.864 2.019-2.722 2.019a4.154 4.154 0 0 1-2.7-.9z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_736"
            d="M83.633 30.775H79.9l-.774 1.775h-1.037l3.184-7.1h1l3.194 7.1h-1.06zm-.351-.812l-1.517-3.478-1.517 3.478z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_737"
            d="M92.408 32.55l-1.517-2.18c-.141.009-.291.019-.442.019h-1.738v2.161h-1v-7.1h2.743c1.828 0 2.933.934 2.933 2.475a2.238 2.238 0 0 1-1.547 2.241l1.66 2.384zm-.03-4.625c0-1.014-.673-1.593-1.959-1.593h-1.708v3.2h1.708c1.286-.005 1.959-.592 1.959-1.607z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_738"
            d="M96.21 25.45h1v7.1h-1z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_739"
            d="M100.522 25.45h1v6.218h3.807v.882h-4.812z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_740"
            d="M109.647 30.1v2.45h-1v-2.475l-2.787-4.625h1.075l2.25 3.742 2.26-3.742h1z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_741"
            d="M118.554 25.45h1v7.1h-1z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_742"
            d="M128.913 25.45v7.1h-.823l-4.219-5.294v5.294h-1v-7.1h.824l4.219 5.294V25.45z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_743"
            d="M140.825 32.55l-1.517-2.18c-.141.009-.291.019-.442.019h-1.738v2.161h-1v-7.1h2.742c1.828 0 2.934.934 2.934 2.475a2.239 2.239 0 0 1-1.547 2.241l1.667 2.384zm-.03-4.625c0-1.014-.673-1.593-1.959-1.593h-1.708v3.2h1.708c1.286-.005 1.959-.592 1.959-1.607z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_744"
            d="M149.73 31.668v.882h-5.1v-7.1h4.963v.882h-3.958v2.181h3.526v.863h-3.526v2.292z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_745"
            d="M157.037 30.775H153.3l-.774 1.775h-1.034l3.184-7.1h1l3.194 7.1h-1.055zm-.351-.812l-1.517-3.478-1.517 3.478z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_746"
            d="M161.111 25.45h1.005v6.218h3.807v.882h-4.812z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_747"
            d="M168.277 25.45h1.005v7.1h-1.005z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_748"
            d="M173.986 26.332h-2.411v-.882h5.816v.882h-2.41v6.218h-1z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
          <path
            id="Path_749"
            d="M182.3 30.1v2.45h-1v-2.475l-2.79-4.625h1.075l2.25 3.742 2.265-3.742h.994z"
            className="cls-2"
            transform="translate(-.738 -25.366)"
          />
        </g>
        <g id="Group_10637" className="cls-1" transform="translate(0 50.513)">
          <path
            id="Path_750"
            d="M10.669 50.819l-2.351 7.1H7.263l-1.919-5.67-1.918 5.67H2.351L0 50.819h1.035l1.9 5.771 1.979-5.771h.924l1.94 5.8 1.938-5.8z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_751"
            d="M19.144 50.819v7.1h-1v-3.164H14.1v3.164h-1v-7.1h1v3.053h4.038v-3.053z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_752"
            d="M26.934 56.144H23.2l-.774 1.775h-1.037l3.185-7.1h1l3.194 7.1h-1.06zm-.351-.812l-1.517-3.478-1.517 3.478z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_753"
            d="M32.052 51.7h-2.41v-.882h5.816v.882h-2.411v6.218h-.995z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_754"
            d="M41.652 50.819h1v7.1h-1z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_755"
            d="M47.36 51.7h-2.41v-.882h5.816v.882h-2.411v6.218h-.995z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_756"
            d="M61.44 56.144H57.7l-.773 1.775H55.9l3.184-7.1h.995l3.2 7.1h-1.065zm-.351-.812l-1.517-3.478-1.517 3.478z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_757"
            d="M71.19 53.294c0 1.542-1.105 2.474-2.934 2.474h-1.738v2.151h-1v-7.1h2.742c1.825 0 2.93.934 2.93 2.475zm-1 0c0-1.014-.673-1.593-1.959-1.593h-1.713v3.185h1.708c1.286 0 1.959-.577 1.959-1.592z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_758"
            d="M79.542 53.294c0 1.542-1.105 2.474-2.933 2.474h-1.738v2.151h-1v-7.1h2.743c1.823 0 2.928.934 2.928 2.475zm-1 0c0-1.014-.673-1.593-1.959-1.593h-1.712v3.185h1.708c1.286 0 1.959-.577 1.959-1.592z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_759"
            d="M87.322 57.037v.882h-5.1v-7.1h4.962v.881h-3.961v2.181h3.526v.863h-3.526v2.292z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_760"
            d="M94.63 56.144h-3.737l-.774 1.775h-1.034l3.184-7.1h1l3.194 7.1H95.4zm-.352-.812l-1.517-3.478-1.517 3.478z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_761"
            d="M103.4 57.919l-1.517-2.18c-.141.009-.291.019-.442.019h-1.734v2.161h-1v-7.1h2.742c1.829 0 2.934.934 2.934 2.475a2.239 2.239 0 0 1-1.547 2.241l1.667 2.384zm-.03-4.625c0-1.014-.673-1.593-1.959-1.593h-1.7v3.2h1.708c1.285 0 1.959-.592 1.959-1.607z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_762"
            d="M106.563 57.107l.372-.791a3.6 3.6 0 0 0 2.32.822c1.216 0 1.738-.477 1.738-1.1 0-1.734-4.259-.639-4.259-3.276 0-1.1.844-2.029 2.692-2.029a4.1 4.1 0 0 1 2.26.629l-.331.811a3.612 3.612 0 0 0-1.929-.578c-1.2 0-1.708.508-1.708 1.126 0 1.734 4.26.649 4.26 3.255 0 1.085-.864 2.019-2.723 2.019a4.151 4.151 0 0 1-2.692-.893z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_763"
            d="M119.94 51.7h-2.411v-.882h5.816v.882h-2.41v6.218h-1z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_764"
            d="M124.967 54.369A3.739 3.739 0 1 1 128.714 58a3.59 3.59 0 0 1-3.747-3.631zm6.469 0a2.733 2.733 0 1 0-2.722 2.728 2.652 2.652 0 0 0 2.722-2.728z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_765"
            d="M139.018 54.856v-4.037h1v4c0 1.573.713 2.282 1.979 2.282s1.989-.709 1.989-2.282v-4h.975v4.037c0 2.059-1.115 3.144-2.974 3.144s-2.969-1.085-2.969-3.144z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_766"
            d="M147.582 57.107l.372-.791a3.6 3.6 0 0 0 2.32.822c1.216 0 1.738-.477 1.738-1.1 0-1.734-4.259-.639-4.259-3.276 0-1.1.844-2.029 2.692-2.029a4.1 4.1 0 0 1 2.26.629l-.331.811a3.612 3.612 0 0 0-1.929-.578c-1.2 0-1.708.508-1.708 1.126 0 1.734 4.26.649 4.26 3.255 0 1.085-.864 2.019-2.723 2.019a4.151 4.151 0 0 1-2.692-.893z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_767"
            d="M160.959 51.7h-2.411v-.882h5.816v.882h-2.41v6.218h-1z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_768"
            d="M165.985 54.369A3.739 3.739 0 1 1 169.733 58a3.591 3.591 0 0 1-3.748-3.631zm6.47 0a2.733 2.733 0 1 0-2.722 2.728 2.652 2.652 0 0 0 2.722-2.728z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_769"
            d="M186.145 56c0 1.217-.914 1.917-2.692 1.917H180.1v-7.1h3.154c1.617 0 2.521.69 2.521 1.836a1.682 1.682 0 0 1-.944 1.582A1.713 1.713 0 0 1 186.145 56zm-5.045-4.359v2.271h2.06c1.024 0 1.607-.384 1.607-1.135s-.583-1.136-1.607-1.136zm4.029 4.28c0-.822-.6-1.187-1.718-1.187H181.1V57.1h2.311c1.117 0 1.72-.358 1.72-1.179z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_770"
            d="M194.005 57.037v.882h-5.1v-7.1h4.963v.881h-3.958v2.181h3.526v.863h-3.526v2.292z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
          <path
            id="Path_771"
            d="M196.341 57.28a.674.674 0 0 1 .682-.69.664.664 0 0 1 .663.69.672.672 0 0 1-.663.7.682.682 0 0 1-.682-.7z"
            className="cls-2"
            transform="translate(0 -50.522)"
          />
        </g>
      </g>
    </svg>
  )
}

export default PhysicalText
