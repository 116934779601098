import { Action } from 'redux'

export enum SigninActionsTypes {
  GET_SIGNIN_ATTEMPT = 'GET_SIGNIN_ATTEMPT',
  GET_SIGNIN_SUCCESS = 'GET_SIGNIN_SUCCESS',
  GET_SIGNIN_FAIL = 'GET_SIGNIN_FAIL',
  GET_SIGNIN = 'GET_SIGNIN',
  CHANGE_SIGNIN_STATE = 'CHANGE_SIGNIN_STATE',
  SIGN_OUT = 'SIGN_OUT',
  SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS',
  SET_USER_DETAIL = 'SET_USER_DETAIL',
  SET_LANG = 'SET_LANG',
}
export interface IGetAuth extends Action {
  readonly type: SigninActionsTypes.GET_SIGNIN
  readonly username: string
  readonly password: string
}

export const getAuth = (username: string, password: string): IGetAuth => ({
  type: SigninActionsTypes.GET_SIGNIN,
  username,
  password,
})

export const checkSignin = (payload: boolean) => ({
  type: SigninActionsTypes.CHANGE_SIGNIN_STATE,
  payload,
})

export const setUserDetail = (payload: boolean) => ({
  type: SigninActionsTypes.SET_USER_DETAIL,
  payload,
})

export const setLang = (payload: any) => ({
  type: SigninActionsTypes.SET_LANG,
  payload,
})
