import { useEffect, useRef, useCallback } from 'react';

type ClickOutsideProps = {
  onClickOutside: Function;
  className?: string;
  children: any;
};

export default function ClickOutside({ className, onClickOutside, children }: ClickOutsideProps) {
  const container = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  const handleClick = useCallback(
    (event: any) => {
      const { current }: any = container;
      const { target } = event;

      if ((current && current === target) || (current && !current.contains(target))) {
        onClickOutside(event);
      }
    },
    [onClickOutside],
  );
  

  return (
    <div className={className} ref={container}>
      {children}
    </div>
  );
}
