import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconHair10: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.6947 10H35.6682C20.0969 10 6.12105 24.065 12.6418 42.6063C16.1488 52.6137 26.6003 61.0229 35.6682 61.038H35.6978C44.7657 61.038 55.6096 55.9427 59.1165 45.9424C65.6148 27.3941 51.2569 10 35.6947 10Z"
        fill="#111947"
      />
      <path
        d="M53.8409 33.4645C52.3758 21.9366 45.4149 15.5059 35.6755 15.5059C25.9361 15.5059 18.9762 21.9366 17.5213 33.4645C13.3246 33.6426 12.2109 36.5842 12.2109 40.3984C12.2109 44.2125 16.5208 48.6053 17.9472 48.7844C18.533 48.876 19.0557 48.9294 19.5774 48.9726C22.9967 56.6009 29.4064 62.0001 34.7208 62.0001H36.7045C42.0098 62.0001 48.4287 56.6251 51.848 48.9968C52.3703 48.9672 52.89 48.9037 53.4038 48.8066C54.8058 48.5993 59.1095 44.3866 59.1095 40.3964C59.1095 36.4061 58.0223 33.6436 53.8409 33.4645Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M29.7275 43.5722C31.3244 43.5722 32.619 42.2935 32.619 40.7162C32.619 39.1388 31.3244 37.8601 29.7275 37.8601C28.1305 37.8601 26.8359 39.1388 26.8359 40.7162C26.8359 42.2935 28.1305 43.5722 29.7275 43.5722Z"
        fill="#111947"
      />
      <path
        d="M42.3994 43.5722C43.9963 43.5722 45.2909 42.2935 45.2909 40.7162C45.2909 39.1388 43.9963 37.8601 42.3994 37.8601C40.8024 37.8601 39.5078 39.1388 39.5078 40.7162C39.5078 42.2935 40.8024 43.5722 42.3994 43.5722Z"
        fill="#111947"
      />
      <path
        d="M19.2206 25.4406C21.7504 30.9172 32.3467 36.1604 38.7309 39.7954C47.7988 44.9581 49.1998 51.6313 51.0592 58.531C63.7604 48.4673 63.8043 30.0628 53.9508 22.1155C25.3654 -0.996705 18.4187 22.0622 19.2206 25.4406Z"
        fill="#111947"
      />
    </svg>
  )
}

export default IconHair10
