export const options = [
  {
    id: 1,
    title: 'booking.booking',
  },
  {
    id: 2,
    title: 'booking.booking_history',
  },
]

export const selectInputStyler = (isError?: boolean) => {
  return {
    control: (base: any) => ({
      ...base,
      // This line disable the blue border
      borderColor: isError ? '#ff0000' : '#9f9f9f',
      borderWidth: 1,
      borderRadius: 5,
      height: 45,
      width: '100%',
      boxShadow: null,
      fontFamily: 'Roboto',
      fontSize: 14,
    }),
    menu: (base: any) => ({
      ...base,
      fontFamily: 'Roboto',
      fontSize: 14,
      width: '100%',
      color: '#aaaaaa',
      overflow: 'hidden',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#aaaaaa',
    }),
    singleValue: (base: any) => ({
      ...base,
      color: '#111947',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: '#111947',
    }),
    option: (styles: any, { isFocused }: any) => ({
      ...styles,
      backgroundColor: isFocused ? '#12C4C4' : '#F1F3F8',
      color: isFocused ? '#ffffff' : '#111947',
      wordBreak: 'break-word',
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: '170px',
    }),
  }
}