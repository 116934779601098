import Wrapper from '../../components/modules/_wrapper'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { setupHead } from '../../../../utils/index'
import { useParams } from 'react-router-dom'
import MoonLoader from 'react-spinners/MoonLoader'
import { CONFIRM_PROVISIONAL_USER } from '../../../../utils/endpoints'
import { POST } from '../../../../utils/const'

interface ProvisionalUserConfirmProps {
  callApi: any
}

const ProvisionalUserConfirm: React.FC<ProvisionalUserConfirmProps> = ({ callApi }) => {

  const title = 'メールアドレス登録確認 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'メールアドレス登録完了のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';
  setupHead(title, description, keywords, og_image);

  const apiResultDefault: { 'loading': boolean, 'error': any, 'result': any } = {
    'loading': true,
    'error': null,
    'result': null
  }
  const [apiResult, setApiResult] = useState(apiResultDefault)

  const [, i18n] = useTranslation('translation')
  const params = useParams()
  const register_key = params['key']

  useEffect(() => {
    const getKeyConfirmEmail = async () => {
      try {
        const res: any = await callApi(CONFIRM_PROVISIONAL_USER, POST, { register_key }, false)
        setApiResult({ 'loading': false, 'error': null, 'result': res.data })
      } catch (error: any) {
        setApiResult({ 'loading': false, 'error': error, 'result': null })
        // console.error(error.name + ": " + error.message)
      }
    }
    getKeyConfirmEmail()
  }, [])

  // パンくずを非表示にするため、とりあえず空にしています。。
  const breadCrumbs: object[] = []
  if (apiResult.loading) {
    return (
      <div
        className="default-padding"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <MoonLoader size={200} loading={true} color={'#12c4c4'} />
      </div>
    )
  } else if (apiResult.error) {
    return (
      <Wrapper
        breadcrum={breadCrumbs}
        className="password"
        typeBreadcrums={1}
        isNoMarginTop={false}
      >
        <div className="active-mail__container distance-h2-to-text">
          <h2 className="title-h2 ">
            {i18n.t('provisional_user.confirm_error_title')}
          </h2>
        </div>
        <div className="password__describe-success distance-text-to-btn">
          <p className="password__describe-content1">{ apiResult.error?.message }</p>
        </div>
      </Wrapper>
    )
  } else {
    const batch_id = apiResult.result.data?.batch_id;
    const serialNumber = `${batch_id}`.split('_')[1];
    const serialDate = `${batch_id}`.split('_')[0].replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3');
    const isRegisteredCustomer = apiResult.result.data?.registered_customer;

    return (
      <Wrapper
        breadcrum={breadCrumbs}
        className="password"
        typeBreadcrums={1}
        isNoMarginTop={false}
      >
        <div className="active-mail__container distance-h2-to-text">
          <h2 className="title-h2 ">
            {i18n.t('provisional_user.success_title')}
          </h2>
        </div>
        <div className="password__describe-success distance-text-to-btn">
        <div>[date {serialDate}]</div>
          <div className="provisional-user__serial-number">{i18n.t('provisional_user.serial_number')} {serialNumber}</div>
          {isRegisteredCustomer ? (
            <br />
          ) : (
            <p className="password__describe-content1">{i18n.t('provisional_user.success_description1')}<br />{i18n.t('provisional_user.success_description2')}</p>
          )}
        </div>
      </Wrapper>
    )
  }
}
export default ProvisionalUserConfirm
