export enum FriendTypes {
    GET_ALL_LIST_FRIEND = 'GET_ALL_LIST_FRIEND',
    GET_RECEIVED_FRIEND = 'GET_RECEIVED_FRIEND',
    GET_SENT_FRIEND = 'GET_SENT_FRIEND'
}

export const getAllListFriend = (payload:any[]) => ({
    type: FriendTypes.GET_ALL_LIST_FRIEND,
    payload
});

export const getReceivedFriend = (payload:any[]) => ({
    type: FriendTypes.GET_RECEIVED_FRIEND,
    payload
});

export const getSentFriend = (payload:any[]) => ({
    type: FriendTypes.GET_SENT_FRIEND,
    payload
});
