import React from 'react';

interface AppleLogoProps {
    height?: string;
    width?: string;
};

const AppleLogo: React.FC<AppleLogoProps> = ({ height, width }) => {
    return (
        <svg width={width || '26'} height={height || '25'} viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.17674 2.39432C8.63256 1.83596 8.94884 1.0694 8.94884 0.302839C8.94884 0.198738 8.93954 0.0851735 8.92093 0C8.18605 0.0283912 7.29302 0.501577 6.76279 1.14511C6.34419 1.62776 5.95349 2.39432 5.95349 3.17035C5.95349 3.28391 5.97209 3.40694 5.9814 3.4448C6.02791 3.45426 6.10233 3.46372 6.17674 3.46372C6.84651 3.46372 7.68372 3.00946 8.17674 2.39432ZM8.69767 3.62461C7.5814 3.62461 6.67907 4.31546 6.10233 4.31546C5.48837 4.31546 4.66977 3.66246 3.70233 3.66246C1.86977 3.66246 0 5.21451 0 8.11987C0 9.94637 0.697674 11.858 1.54419 13.0978C2.27907 14.1483 2.92093 15 3.83256 15C4.74419 15 5.15349 14.3849 6.28837 14.3849C7.43256 14.3849 7.69302 14.9811 8.69767 14.9811C9.70233 14.9811 10.3628 14.0536 10.986 13.1356C11.693 12.0852 11.9907 11.0631 12 11.0063C11.9442 10.9874 10.0279 10.2019 10.0279 7.96845C10.0279 6.04732 11.5349 5.18612 11.6186 5.11987C10.6326 3.66246 9.11628 3.62461 8.69767 3.62461Z" fill="white"/>
        </svg>
    ); 
};

export default AppleLogo;
