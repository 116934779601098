import * as React from 'react'

interface EditIconProps {
  height?: string
  width?: string
}
const EditIcon: React.FC<EditIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.625 2.375C16.125 1.875 15.375 1.875 14.875 2.375L2.375 14.875C2.125 15.125 2 15.375 2 15.75V20.75C2 21.5 2.5 22 3.25 22H8.25C8.625 22 8.875 21.875 9.125 21.625L21.625 9.125C22.125 8.625 22.125 7.875 21.625 7.375L16.625 2.375ZM7.75 19.5H4.5V16.25L12 8.75L15.25 12L7.75 19.5ZM17 10.25L13.75 7L15.75 5L19 8.25L17 10.25Z"
        fill="#9496AB"
      />
    </svg>
  )
}

export default EditIcon
