import { useTranslation } from 'react-i18next'
import { Modal } from '.'
import { CloseIcon } from '../../../../../assets/images'

interface MessageModalProps {
  toggleModal: () => void,
  show: boolean,
  custom_title: any,
  custom_message: any,
}

const MessageModal: React.FC<MessageModalProps> = ({ toggleModal, show, custom_title, custom_message }) => {
  const [, i18n] = useTranslation('translation')
  return (
    <Modal
        modalStyles="booking__modal-booking padding-modal-in-h2"
        show={show}
        toggleModal={toggleModal}
      >
      <div className="detail-avatar__delete-modal padding-modal-in-h2">
        <div className="detail-avatar__delete-modal_header distance-h2-to-line">
          <div className="detail-avatar__delete-modal_header-text">
            <div className="pop-text-bold-big">{i18n.t(custom_title)}</div>
          </div>
          <div className="detail-avatar__delete-modal_header-icon" onClick={toggleModal}>
            <CloseIcon />
          </div>
        </div>
        <div className="detail-avatar__delete-modal_content2 distance-line-to-text padding-modal-content-to-btn">
          <div className="distance-text-to-text pop-large-body-text text-pre-line"> </div>
          <div className="detail-avatar__convert-modal_name distance-margin-modal-h2">
            <div className="distance-text-to-text pop-large-body-text text-pre-line">
              {i18n.t(custom_message)}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MessageModal
