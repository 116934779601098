import { Action } from 'redux';

export type IMessageType = 'success' | 'error' | 'warning';

export enum SystemTypes {
    SYSTEM_ERROR = 'SYSTEM_ERROR',
    SYSTEM_SHOW_MESSAGE = 'SYSTEM_SHOW_MESSAGE',
    SYSTEM_HIDE_MESSAGE = 'SYSTEM_HIDE_MESSAGE',
}
export interface IDisplayMessage extends Action {
    readonly type: SystemTypes.SYSTEM_SHOW_MESSAGE;
    readonly message: string;
    readonly msgType: IMessageType;
}

export interface IHideMessage extends Action {
    readonly type: SystemTypes.SYSTEM_HIDE_MESSAGE;
}
export const displayMessage = (message: string, msgType: IMessageType): IDisplayMessage => ({
    type: SystemTypes.SYSTEM_SHOW_MESSAGE,
    message,
    msgType
});
export const hideMessage = (): IHideMessage => ({
    type: SystemTypes.SYSTEM_HIDE_MESSAGE,
});