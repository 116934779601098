import {
  MenuAvatar,
  MenuExport,
  MenuCalendar,
  MenuCard,
  MenuEmail,
  MenuSecurity,
  MenuSetting,
  MenuSignUp,
} from '../../../../../assets/images'
import { ROUTER_URL } from '../../../../../utils/const'

export const userData = {
  name: 'Pocket RDG Number 1',
  img: '',
  rank: 'menu.rank',
  status: 'menu.status',
  avatar: '00',
}

export const bodyFirst = [
  {
    icon: MenuCalendar,
    title: 'menu.scan_booking',
    goTo: ROUTER_URL.SCAN_BOOKING,
  },
  {
    icon: MenuAvatar,
    title: 'menu.manage_avatar',
    goTo: ROUTER_URL.CONTROL_AVATAR,
  },
  {
    icon: MenuExport,
    title: 'menu.manage_export',
    goTo: ROUTER_URL.CONTROL_EXPORT,
  },
]

export const bodySecond = [
  {
    icon: MenuSetting,
    title: 'menu.account_setting',
    goTo: ROUTER_URL.SETTINGS,
  },
  {
    icon: MenuCard,
    title: 'menu.credit_card',
    goTo: ROUTER_URL.MANAGE_CREDIT,
  },
  {
    icon: MenuSecurity,
    title: 'menu.about_privacy',
    goTo: ROUTER_URL.PRIVACY_POLICY,
  },
  {
    icon: MenuEmail,
    title: 'menu.contact',
    goTo: ROUTER_URL.CONTACT,
  },
  {
    icon: MenuSignUp,
    title: 'menu.log_out',
    goTo: ROUTER_URL.SIGNIN,
  },
]
