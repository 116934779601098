import { useTranslation } from 'react-i18next'
import { bodyFirst, bodySecond } from './constants'
import Item from './body-item'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'
import { messaging } from '../../../../../utils/firebase'
import { useEffect, useRef } from 'react'

interface BodyProps {
  toggleMenu?: () => void
  onLogout?: any
}

const Body: React.FC<BodyProps> = ({ toggleMenu, onLogout }) => {

  const [, i18n] = useTranslation('translation')
  const history = useHistory()

  const menuRef: any = useRef()

  useEffect(() => {
    menuRef?.current?.addEventListener(
      'wheel',
      function (e: any) {
        const height = menuRef?.current?.clientHeight,
          scrollHeight = menuRef?.current?.scrollHeight,
          scrollTop = menuRef?.current?.scrollTop,
          d = e.wheelDelta
        if (
          (Math.ceil(scrollTop) === scrollHeight - height && d < 0) ||
          (scrollTop === 0 && d > 0)
        ) {
          e.preventDefault()
        }
      },
      false
    )
  }, [menuRef?.current])

  const handleClick = (title: string, goTo: string) => async () => {
    if (title === 'menu.log_out') {
      await onLogout()
      try {
        if (messaging && messaging.deleteToken) messaging.deleteToken()
      } catch (error) {
      }
    }
    history.push(goTo)
    if (toggleMenu) {
      toggleMenu()
    }
  }

  const renderBody = (item?: string) => {
    let listItem: any[]
    if (item === 'first') {
      listItem = bodyFirst
    } else {
      listItem = bodySecond
    }
    return listItem.map((props) => {
      return (
        <div
          onClick={handleClick(props.title, props.goTo)}
          className={cx('content__body--part__item')}
          key={props.title}
        >
          <Item title={i18n.t(props.title)} icon={<props.icon />} />
        </div>
      )
    })
  }

  return (
    <div className="content__body" ref={menuRef}>
      <div className="content__body--part">{renderBody('first')}</div>
      <div className="content__body--part">{renderBody()}</div>
    </div>
  )
}

export default Body
