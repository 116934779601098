import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { EMAIL_PATTERN } from '../../../../utils/const'

export const textFields = [
  {
    name: 'email',
    type: 'email',
    label: 'provisional_user.label_email',
    isRequire: true,
    placeholder: 'provisional_user.label_email',
  },
]

export const validator = (t: any) => {
  return yupResolver(
    Yup.object().shape({
      email: Yup.string()
        .matches(EMAIL_PATTERN, t('validations.email_valid'))
        .notOneOf([''], t('validations.email_require'))
        .required(t('validations.email_require'))
        .trim(),
    })
  )
}
