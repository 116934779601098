export const dataTest = [
  {
    id: 1,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content:
      localStorage.getItem('i18nextLng') === 'ja'
        ? 'アバター名を記の記載'
        : 'Description of avatar name'
  },
  {
    id: 2,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content:
      localStorage.getItem('i18nextLng') === 'ja'
        ? 'アバター名を記の記載'
        : 'Description of avatar name'
  },
  {
    id: 3,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content:
      localStorage.getItem('i18nextLng') === 'ja'
        ? 'アバター名を記の記載'
        : 'Description of avatar name'
  },
  {
    id: 4,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content:
      localStorage.getItem('i18nextLng') === 'ja'
        ? 'アバター名を記の記載'
        : 'Description of avatar name'
  },
  {
    id: 5,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content:
      localStorage.getItem('i18nextLng') === 'ja'
        ? 'アバター名を記の記載'
        : 'Description of avatar name'
  },
  {
    id: 6,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content:
      localStorage.getItem('i18nextLng') === 'ja'
        ? 'アバター名を記の記載'
        : 'Description of avatar name'
  },
  {
    id: 7,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content:
      localStorage.getItem('i18nextLng') === 'ja'
        ? 'アバター名を記の記載'
        : 'Description of avatar name'
  },
  {
    id: 8,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content:
      localStorage.getItem('i18nextLng') === 'ja'
        ? 'アバター名を記の記載'
        : 'Description of avatar name'
  },
]

export const radioFields = [
  {
    name: 'sex',
    type: 'radio',
    label: 'signup.sex_male',
    value: 'male',
    isRequire: true,
  },
  {
    name: 'sex',
    type: 'radio',
    label: 'signup.sex_female',
    value: 'female',
    isRequire: true,
  },
]

export const editOptionStyles = () => ({
  control: (base: any) => ({
    ...base,
    // This line disable the blue border
    minHeight: 32,
    height: 32,
    borderColor: '#9F9F9F',
    borderWidth: 1,
    borderRadius: 5,
    boxShadow: '0 !important',
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#111947',
  }),
  menu: (base: any) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: 14,
    width: '100%',
    color: '#111947',
    overflow: 'hidden',
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#aaaaaa',
  }),
  singleValue: (base: any) => ({
    ...base,
    color: '#111947',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    padding: 0,
  }),

  dropdownIndicator: (base: any) => ({
    ...base,
    color: '#111947',
    padding: '0 8px',
  }),
  option: (styles: any, { isFocused }: any) => ({
    ...styles,
    backgroundColor: isFocused ? '#12C4C4' : '#F1F3F8',
    color: isFocused ? '#ffffff' : '#111947',
    overflow: 'hidden',
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    boxShadow: '0 !important',
    overflow: 'hidden',
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: '0 8px',
  }),
})
