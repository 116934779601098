import React from 'react'

interface DownArrowProps {
  height?: string
  width?: string
}

const DownArrow: React.FC<DownArrowProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="arrow_back_black_24dp" transform="translate(0 752) rotate(-90)">
        <path
          id="Path_243"
          data-name="Path 243"
          d="M0,0H20V20H0Z"
          transform="translate(732)"
          fill="none"
        />
        <path
          id="Path_244"
          data-name="Path 244"
          d="M13.333,5.833H3.192L7.85,1.175,6.667,0,0,6.667l6.667,6.667,1.175-1.175L3.192,7.5H13.333Z"
          transform="translate(735.333 3.333)"
          fill="#12c4c4"
        />
      </g>
    </svg>
  )
}

export default DownArrow
