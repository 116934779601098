import { useForm, FormProvider } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { validator, textFields, defaultValues } from './constants'
import { FORGET_PASSWORD } from '../../../../utils/endpoints'
import { useHistory } from 'react-router-dom'
import { WarningIcon } from '../../../../assets/images/svgs'
import React, { useEffect, useState } from 'react'
import Wrapper from '../../components/modules/_wrapper'
import { Button } from '../../components/atoms/buttons'
import { TextInput } from './../../components/atoms/text-input/index'
import { isEmpty } from 'lodash'
import { POST, ROUTER_URL } from '../../../../utils/const'

interface ForgotPasswordProps {
  callApi: any
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ callApi }) => {

  const title = 'パスワード再発行 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'パスワード再発行のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [t, i18n] = useTranslation('translation')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const methods = useForm({
    defaultValues,
    resolver: validator(t),
  })
  const { handleSubmit, errors } = methods

  useEffect(() => {
    if (!isEmpty(errors)) handleSubmit(onSubmit)()
  }, [localStorage.getItem('i18nextLng')])

  const onSubmit = async (values: any) => {
    setLoading(true)
    try {
      // const result = await axios.post(FORGET_PASSWORD, values)
      await callApi(FORGET_PASSWORD, POST, values)
      history.push({ pathname: ROUTER_URL.FORGOT_PASSWORD_NOTIFICATION, state: { email: values.email } })
      setLoading(false)
    } catch (error) {
      setError(error?.message)
      setLoading(false)
    }
  }

  const renderBtnPassword = (
    <div className="password__send">
      <Button type="submit">{i18n.t('forgot_password.button')}</Button>
    </div>
  )

  const renderTextFields = (
    <>
      <div className="password__field-wrapper distance-input-to-btn">
        <span>
          {textFields.map(({ name, type, isRequire, placeholder, label }) => {
            return (
              <TextInput
                key={name}
                type={type}
                name={name}
                isRequire={isRequire}
                placeholder={i18n.t(placeholder)}
                label={i18n.t(label)}
                errorsBorder={!!error}
                handleChange={() => setError('')}
              />
            )
          })}
        </span>
        {error && (
          <div className="error-message">
            <span>※{error}</span>
          </div>
        )}
      </div>
    </>
  )
  const list = [
    {
      name: i18n.t('forgot_password.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('signin.login'),
      link: ROUTER_URL.SIGNIN,
    },
    {
      name: i18n.t('forgot_password.bread_crumb_active'),
      link: ROUTER_URL.FORGOT_PASSWORD,
    },
  ]
  return (
    <Wrapper className="password" breadcrum={list} typeBreadcrums={1} isNoMarginTop={false}>
      <h2 className="password__title title-h2 distance-h2-to-text">
        {i18n.t('forgot_password.title_header')}
      </h2>
      <div className="password__describe distance-text-to-noti">
        <div id="forgot-title" className="password__describe-content1">
          <p>
            {i18n.t('forgot_password.title_content')}
            <br />
            {i18n.t('forgot_password.title_content2')}
          </p>
        </div>
      </div>
      <div className="password__warning distance-noti-to-form padding-content-noti" id="forgotPw">
        <div className="password__warning-icon" style={{ height: '2rem' }}>
          <WarningIcon />
        </div>
        <div className="password__warning-content">
          <p>
            <Trans
              i18nKey="forgot_password.message"
              components={{
                br: <br />,
                span: <span className="text-link" onClick={() => history.push(ROUTER_URL.CONTACT)} />,
              }}
            />
          </p>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          className="password__warning distance-noti-to-form padding-content-noti"
          id="forgotPwMobile"
        >
          <div className="password__warning-icon" style={{ height: '2rem', width: '100%' }}>
            <div>
              <WarningIcon />
            </div>
            <span>{i18n.t('forgot_password.warning_textMb1')}</span>
          </div>
          <div className="password__warning-content">
            <p>
              <Trans
                i18nKey="forgot_password.message_mobile"
                components={{
                  span: <span className="text-link" onClick={() => history.push(ROUTER_URL.CONTACT)} />,
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(onSubmit)}
          className="password__form"
        >
          {renderTextFields}
          {renderBtnPassword}
        </form>
      </FormProvider>
    </Wrapper>
  )
}
export default ForgotPassword
