import classNames from 'classnames'
import { Fragment, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useWindowSize from '../../../../../customize/hooks/useWindowSize'
import { breakPoint, fiveCustomizationBar } from '../constants'
import {
  customizationPic1,
  customizationPic2,
  customizationPic3,
  customizationPic4,
  customizationPic5,
} from '../images/export'
import {
  Dots9x4Customization,
  Shield,
  Cosmetics,
  Dancing,
  PlayingHoop,
  Woman,
} from '../svgs/export'

interface CustomizationImagesProps {}
const CustomizationImages: React.FC<CustomizationImagesProps> = () => {
  const [customizationSelected, setCustomizationSelected] = useState(1)
  const [t] = useTranslation('translation')
  const screenSize = useWindowSize()

  const listCustomizationImages = [
    {
      id: 1,
      image: customizationPic1,
      svgComponent: <Woman />,
      title: t('top.customization_item_1_label'),
      text: t('top.customization_item_1_text'),
    },
    {
      id: 2,
      image: customizationPic2,
      svgComponent: <Cosmetics />,
      title: t('top.customization_item_2_label'),
      text: t('top.customization_item_2_text'),
    },
    {
      id: 3,
      image: customizationPic3,
      svgComponent: <Shield />,
      title: t('top.customization_item_3_label'),
      text: t('top.customization_item_3_text'),
    },
    {
      id: 4,
      image: customizationPic4,
      svgComponent: <Dancing />,
      title: t('top.customization_item_4_label'),
      text: t('top.customization_item_4_text'),
    },
    {
      id: 5,
      image: customizationPic5,
      svgComponent: <PlayingHoop />,
      title: t('top.customization_item_5_label'),
      text: t('top.customization_item_5_text'),
    },
  ]

  useEffect(() => {
    let newCustomizationSelected = customizationSelected
    const interval = setInterval(() => {
      setCustomizationSelected((newCustomizationSelected % 5) + 1)
    }, 3000)
    return () => clearInterval(interval)
  }, [customizationSelected])

  return (
    <>
      <div className="top__customization-display-wrapper">
        <div className="top__customization-display">
          {listCustomizationImages.map((item) => (
            <Fragment key={item.id}>
              <img
                src={item.image}
                alt={item.title}
                height="530"
                width="292"
                className={classNames({
                  'top__customization-display--active': item.id === customizationSelected,
                })}
              />
            </Fragment>
          ))}
          {screenSize.width <= breakPoint && (
            <>
              {listCustomizationImages
                .filter((item) => item.id === customizationSelected)
                .map((item) => (
                  <Fragment key={item.id}>
                    <div className="top__customization-makeUp">{item.svgComponent}</div>
                  </Fragment>
                ))}
            </>
          )}
        </div>
      </div>
      {listCustomizationImages
        .filter((item) => item.id === customizationSelected)
        .map((item) => (
          <Fragment key={item.id}>
            <div className="top__customization-content">
              {screenSize.width > breakPoint && (
                <div className="top__customization-makeUp">{item.svgComponent}</div>
              )}
              <div className="top__customization-bars">
                {fiveCustomizationBar.map((eachBar) => (
                  <span
                    key={eachBar}
                    className={classNames('top__customization-bar')}
                    onClick={() => setCustomizationSelected(eachBar)}
                  >
                    <span
                      className={classNames({
                        'top__customization-bar--active': eachBar === customizationSelected,
                      })}
                    ></span>
                  </span>
                ))}
              </div>
              <div className="top__customization-info">
                <h4 className="top__customization-info-title">{item.title}</h4>
                <p className="top__customization-info-text">{item.text}</p>
              </div>
            </div>
            <div className="top__customization-dots">
              <Dots9x4Customization />
            </div>
          </Fragment>
        ))}
    </>
  )
}

export default CustomizationImages
