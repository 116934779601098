import React from 'react';

interface TwitterLogoProps {
    width?: string;
    height?: string;
}
const UsIcon:React.FC<TwitterLogoProps> = ({width, height}) => {
    return (
        <svg
        width={width || "18"}
        height={height || "18"}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9 18A9 9 0 109 0a9 9 0 000 18z" fill="#F0F0F0" />
        <path
          d="M8.61 9H18c0-.813-.108-1.6-.31-2.349H8.61V9zM8.61 4.304h8.07a9.047 9.047 0 00-2.077-2.348H8.609v2.348zM9 18a8.962 8.962 0 005.603-1.957H3.398A8.961 8.961 0 009.001 18zM1.32 13.695h15.358a8.946 8.946 0 001.01-2.347H.309a8.946 8.946 0 001.012 2.347z"
          fill="#D80027"
        />
        <path
          d="M4.169 1.405h.82l-.763.555.292.897-.763-.555-.763.555.252-.775a9.049 9.049 0 00-1.746 1.945h.263l-.486.353a8.984 8.984 0 00-.217.385l.231.713-.432-.314c-.108.228-.206.46-.295.699l.256.786h.943l-.763.554.291.897-.762-.554-.457.332C.024 8.245 0 8.62 0 9h9V0a8.957 8.957 0 00-4.831 1.405zM4.518 8.1l-.763-.554-.763.554.291-.897-.763-.554h.943l.292-.897.291.897h.943l-.763.554.292.897zm-.292-3.519l.292.897-.763-.554-.763.554.291-.897-.763-.554h.943l.292-.897.291.897h.943l-.763.554zM7.746 8.1l-.763-.554-.763.554.292-.897-.763-.554h.943l.291-.897.291.897h.943l-.763.554.292.897zm-.292-3.519l.292.897-.763-.554-.763.554.292-.897-.763-.554h.943l.291-.897.291.897h.943l-.763.554zm0-2.621l.292.897-.763-.555-.763.555.292-.897-.763-.555h.943L6.983.51l.291.896h.943l-.763.555z"
          fill="#0052B4"
        />
      </svg>
    );
};

export default UsIcon;