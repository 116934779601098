import * as React from 'react'

interface BookIconProps {
  height?: string
  width?: string
}
const BookIcon: React.FC<BookIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 2H7V30H4V2ZM25 2H8.5V30H25C26.65 30 28 28.6 28 26.8889V5.11111C28 3.4 26.65 2 25 2ZM22 11.3333H13V9.77778H22V11.3333ZM22 8.22222H13V6.66667H22V8.22222Z"
        fill="#9496AB"
      />
    </svg>
  )
}

export default BookIcon
