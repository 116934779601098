import * as React from 'react'

interface MenuProps {
  height?: string
  width?: string
}
const MenuAvatar: React.FC<MenuProps> = ({ height, width }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C6.48613 2 2 6.48613 2 12C2 17.5139 6.48613 22 12 22C17.5139 22 22 17.5139 22 12C22 6.48613 17.5139 2 12 2ZM12 20.3226C7.87097 20.3226 3.67742 17.1584 3.67742 12C3.67742 11.7252 3.69194 11.4535 3.71516 11.1845C4.92161 9.64161 5.50194 9.63097 7.52806 9.64065C9.79129 9.63645 12.5858 9.64323 15.4519 7.20935C17.7058 10.5626 20.3016 11.4042 20.3016 11.4042C20.3016 11.4042 20.3226 11.7997 20.3226 12C20.3226 17.1584 16.129 20.3226 12 20.3226Z"
        fill=""
      />
      <path
        d="M7.64466 15.2249C8.26821 15.2249 8.77369 14.7194 8.77369 14.0958C8.77369 13.4723 8.26821 12.9668 7.64466 12.9668C7.02111 12.9668 6.51562 13.4723 6.51562 14.0958C6.51562 14.7194 7.02111 15.2249 7.64466 15.2249Z"
        fill=""
      />
      <path
        d="M16.3556 15.2249C16.9791 15.2249 17.4846 14.7194 17.4846 14.0958C17.4846 13.4723 16.9791 12.9668 16.3556 12.9668C15.732 12.9668 15.2266 13.4723 15.2266 14.0958C15.2266 14.7194 15.732 15.2249 16.3556 15.2249Z"
        fill=""
      />
    </svg>
  )
}

export default MenuAvatar
