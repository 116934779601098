import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconHair7: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.1173 43.4035C55.0722 40.3248 61.3429 45.1167 62.7584 51.3487C63.9645 56.6476 60.1378 64.9536 62.2226 70.2181C62.2652 70.3213 62.276 70.4345 62.2536 70.5437C62.2312 70.6529 62.1767 70.7532 62.0968 70.8322C62.0169 70.9112 61.9151 70.9654 61.804 70.9881C61.693 71.0108 61.5776 71.001 61.4722 70.9598C44.9431 64.1433 44.3704 48.6338 51.1173 43.4035Z"
        fill="#111947"
      />
      <path
        d="M62.9239 27.4436C63.2616 15.8674 56.0373 5.86934 45.9339 3.7611C41.362 -0.150032 32.8783 0.613853 26.349 2.85008C15.6307 6.53749 10.5128 14.9523 9.07575 26.5597C7.88609 36.2212 20.9529 46.4288 31.0872 52.5066C31.896 52.9964 32.6412 53.5587 33.408 54.1271C34.0772 54.4997 34.8333 54.6956 35.6026 54.6956C36.3718 54.6956 37.128 54.4997 37.7971 54.1271C38.4667 53.6002 39.1845 53.1353 39.9414 52.7384C50.2655 46.9871 62.6395 37.2048 62.9239 27.4436Z"
        fill="#111947"
      />
      <path
        d="M55.4125 26.1135C54.0679 13.8742 46.7574 6.93878 36.3809 6.78862C26.0045 6.63846 18.4641 13.2897 16.7058 25.4766C12.214 25.6026 10.9925 28.6914 10.9227 32.7325C10.8529 36.7736 15.3662 41.4839 16.8874 41.7147C17.5146 41.8155 18.0668 41.888 18.6242 41.9414C22.108 50.0822 28.8611 55.8909 34.521 55.9806L36.6324 56.0179C42.2872 56.1076 49.2384 50.5296 52.9952 42.5048C53.5526 42.4884 54.108 42.4321 54.6571 42.3365C56.168 42.1521 60.8415 37.7794 60.9185 33.5538C60.9955 29.3283 59.8715 26.3816 55.4125 26.1135Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M29.5853 36.3992C31.2872 36.3992 32.6668 35.0448 32.6668 33.3739C32.6668 31.7031 31.2872 30.3486 29.5853 30.3486C27.8835 30.3486 26.5039 31.7031 26.5039 33.3739C26.5039 35.0448 27.8835 36.3992 29.5853 36.3992Z"
        fill="#111947"
      />
      <path
        d="M43.0892 36.6209C44.7911 36.6209 46.1707 35.2665 46.1707 33.5956C46.1707 31.9248 44.7911 30.5703 43.0892 30.5703C41.3874 30.5703 40.0078 31.9248 40.0078 33.5956C40.0078 35.2665 41.3874 36.6209 43.0892 36.6209Z"
        fill="#111947"
      />
      <path
        d="M54.4838 27.3068C51.4445 25.7287 44.7324 16.8986 43.0788 11.1C40.332 14.5203 24.5943 26.7294 17.3906 26.6175C18.0763 23.9862 17.6585 5.96943 36.1492 3.68584C47.1795 2.32838 59.8594 17.8772 54.4838 27.3068Z"
        fill="#111947"
      />
    </svg>
  )
}

export default IconHair7
