import { useFormContext } from 'react-hook-form'
import classnames from 'classnames'
interface RadioInputProps {
  name: string
  type: string
  label?: string
  isRequire: boolean
  value: string
  onChange?: any
  disabled?: boolean
}

export const RadioInput: React.FC<RadioInputProps> = ({
  name,
  label,
  isRequire,
  type,
  value,
  onChange,
  disabled,
}) => {
  const { errors, register } = useFormContext()
  const isError = !!errors?.[name] ?? false
  const message = errors?.[name]?.message ?? null
  return (
    <div className="input">
      <span className="radio__input">
        <input
          type={type}
          ref={register}
          className={classnames('input__radio', { error: isError }, 'radio__control')}
          value={value}
          name={name}
          disabled={disabled}
          onClick={onChange}
        />
        <span className="radio__control"></span>
      </span>
      {message && <span className="form-field-error-message">{message}</span>}
    </div>
  )
}
