interface JacketAndPantsProps {
  height?: string
  width?: string
}
const JacketAndPants: React.FC<JacketAndPantsProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '124'}
      height={height || '84'}
      viewBox="0 0 124 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.2272 60.1331C95.7646 45.4343 96.1844 31.7354 96.2517 29.9939C96.3933 26.0655 97.2633 25.9547 98.0067 25.9547C98.7501 25.9547 99.594 26.0655 99.7412 29.9939C99.8042 31.7347 100.233 45.4316 100.774 60.1331H119.67L113.612 5H82.3905L76.3516 60.1331H95.2272Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
      />
      <path
        d="M118.353 64.317C118.352 66.7188 117.673 69.0783 116.383 71.1583C115.093 73.2383 113.238 74.9655 111.004 76.1664C113.537 77.3778 116.345 78.0066 119.192 78.0001C119.86 78.0001 121.355 77.9533 122.001 77.8749L119.65 60.1328H117.647C118.114 61.4851 118.352 62.8967 118.353 64.317Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
      />
      <path
        d="M116.217 71.4067C116.624 70.7826 116.975 70.1288 117.267 69.4517C118.486 66.4684 119.141 63.311 119.203 60.1196H100.772C101.066 66.6016 100.546 72.9432 100.848 77.8617C101.5 77.9431 102.156 77.9849 102.813 77.9869C105.661 77.9897 108.468 77.3609 111.002 76.1525C112.922 75.1171 114.567 73.6927 115.816 71.9848C115.957 71.8082 116.087 71.6124 116.217 71.4067Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
      />
      <path
        d="M77.6432 64.3169C77.6435 66.7188 78.3229 69.0783 79.6131 71.1583C80.9034 73.2383 82.7589 74.9655 84.9932 76.1662C82.4605 77.3781 79.6526 78.0069 76.8049 77.9999C76.1373 77.9999 74.6421 77.9532 74 77.8747L76.3504 60.1333H78.3384C77.8763 61.486 77.6425 62.8974 77.6453 64.3169H77.6432Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
      />
      <path
        d="M79.7824 71.4065C79.3764 70.7816 79.0239 70.128 78.7282 69.4515C77.5098 66.4691 76.8564 63.3125 76.7969 60.1221H95.223C94.9292 66.6041 95.4538 72.9456 95.1473 77.8641C94.4958 77.9456 93.8393 77.9874 93.1819 77.9894C90.3346 77.9909 87.5278 77.3612 84.9937 76.1524C83.0752 75.1148 81.4303 73.6908 80.1795 71.9846C80.0429 71.808 79.9126 71.6122 79.7824 71.4065Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
      />
      <path
        d="M69.2983 8.92766C66.5444 6.43803 62.9766 5.04126 59.266 5H56.6031C56.5944 5.0135 56.5846 5.02628 56.5739 5.03823L50.7092 11.9587C50.6418 12.0396 50.5574 12.1046 50.4621 12.1493C50.3668 12.1939 50.2628 12.217 50.1576 12.217C50.0523 12.217 49.9483 12.1939 49.853 12.1493C49.7577 12.1046 49.6733 12.0396 49.6059 11.9587L43.7391 5.03823C43.7304 5.02473 43.7207 5.01195 43.71 5H40.8228C37.1082 5.03829 33.536 6.43528 30.7793 8.92766L5.6875 34.9561L14.4366 43.6969L34.1512 24.461L34.8991 37.6793H65.1786L65.9229 24.461L85.6507 43.6969L94.3742 34.9617L69.2983 8.92766Z"
        fill="white"
      />
      <path
        d="M69.2983 8.92766C66.5444 6.43803 62.9766 5.04126 59.266 5H56.6031C56.5944 5.0135 56.5846 5.02628 56.5739 5.03823L50.7092 11.9587C50.6418 12.0396 50.5574 12.1046 50.4621 12.1493C50.3668 12.1939 50.2628 12.217 50.1576 12.217C50.0523 12.217 49.9483 12.1939 49.853 12.1493C49.7577 12.1046 49.6733 12.0396 49.6059 11.9587L43.7391 5.03823C43.7304 5.02473 43.7207 5.01195 43.71 5H40.8228C37.1082 5.03829 33.536 6.43528 30.7793 8.92766L5.6875 34.9561L14.4366 43.6969L34.1512 24.461L34.8991 37.6793H65.1786L65.9229 24.461L85.6508 43.6969L94.3742 34.9617L69.2983 8.92766Z"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
      />
      <path
        d="M65.1786 43.6968H34.9039L30.7891 52.4285C32.7139 52.4285 32.7139 55.0002 34.6394 55.0002C36.5649 55.0002 36.5642 52.4285 38.4897 52.4285C40.4151 52.4285 40.4145 55.0002 42.3399 55.0002C44.2654 55.0002 44.2648 52.4285 46.1902 52.4285C48.1157 52.4285 48.115 55.0002 50.0405 55.0002C51.966 55.0002 51.9653 52.4285 53.8901 52.4285C55.8149 52.4285 55.8149 55.0002 57.7404 55.0002C59.6659 55.0002 59.6652 52.4285 61.59 52.4285C63.5148 52.4285 63.5148 55.0002 65.4403 55.0002C67.3658 55.0002 67.3651 52.4285 69.2906 52.4285L65.1786 43.6968Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2.1"
        strokeLinejoin="round"
      />
      <path
        d="M65.1786 37.6855H34.9039L30.7891 46.4076C32.7139 46.4076 32.7139 48.9792 34.6394 48.9792C36.5649 48.9792 36.5642 46.4076 38.4897 46.4076C40.4151 46.4076 40.4145 48.9792 42.3399 48.9792C44.2654 48.9792 44.2648 46.4076 46.1902 46.4076C48.1157 46.4076 48.115 48.9792 50.0405 48.9792C51.966 48.9792 51.9653 46.4076 53.8901 46.4076C55.8149 46.4076 55.8149 48.9792 57.7404 48.9792C59.6659 48.9792 59.6652 46.4076 61.59 46.4076C63.5148 46.4076 63.5148 48.9792 65.4403 48.9792C67.3658 48.9792 67.3651 46.4076 69.2906 46.4076L65.1786 37.6855Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2.1"
        strokeLinejoin="round"
      />
      <path
        d="M14.4418 43.6964L10.0721 39.326L5.6892 34.9521L2 36.2818C2.5555 36.8378 1.81391 37.5662 2.37218 38.1347C2.93046 38.7033 3.65538 37.9519 4.22338 38.5073C4.79138 39.0626 4.03729 39.7917 4.59209 40.3602C5.1469 40.9288 5.87529 40.174 6.44676 40.7328C7.01823 41.2916 6.26067 42.0172 6.81547 42.5857C7.37028 43.1543 8.09867 42.3995 8.66667 42.9548C9.23467 43.5101 8.48058 44.2392 9.03538 44.8078C9.59019 45.3763 10.3186 44.6215 10.8901 45.1803C11.4615 45.7391 10.704 46.4647 11.2588 47.0333C11.8136 47.6018 12.542 46.847 13.1134 47.4058L14.4418 43.6964Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2.1"
        strokeLinejoin="round"
      />
      <path
        d="M74.8555 32.9L79.2252 28.5213L83.5948 24.1509L87.2972 25.4805C86.7417 26.0365 87.4799 26.7649 86.9251 27.3335C86.3703 27.902 85.6419 27.1507 85.0739 27.706C84.5059 28.2613 85.2599 28.9904 84.7017 29.559C84.1434 30.1275 83.4185 29.3727 82.8505 29.928C82.2825 30.4834 83.0366 31.2125 82.4818 31.7845C81.927 32.3565 81.1986 31.5982 80.6306 32.1535C80.0626 32.7089 80.8132 33.438 80.2584 34.0065C79.7036 34.575 78.9752 33.8202 78.4072 34.3756C77.8392 34.9309 78.5933 35.66 78.0385 36.232C77.4837 36.804 76.7393 36.0457 76.1838 36.6011L74.8555 32.9Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2.1"
        strokeLinejoin="round"
      />
      <path
        d="M25.0594 32.9001L20.6932 28.5213L16.3235 24.1523L12.6211 25.482C13.1766 26.038 12.435 26.7664 12.9898 27.3349C13.5446 27.9035 14.273 27.1521 14.8445 27.7075C15.4159 28.2628 14.6584 28.9919 15.2132 29.5604C15.768 30.129 16.4964 29.3742 17.0644 29.9295C17.6324 30.4848 16.8818 31.2139 17.4366 31.7859C17.9914 32.358 18.7198 31.5997 19.2878 32.155C19.8558 32.7103 19.1017 33.4394 19.6565 34.008C20.2113 34.5765 20.9397 33.8217 21.5077 34.377C22.0757 34.9324 21.3251 35.6615 21.8799 36.2335C22.4347 36.8055 23.1631 36.0472 23.7311 36.6025L25.0594 32.9001Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2.1"
        strokeLinejoin="round"
      />
      <path
        d="M85.5586 43.6129L89.9283 39.2342L94.298 34.8638L98.0004 36.1934C97.4449 36.7494 98.183 37.4778 97.6282 38.0463C97.0734 38.6149 96.345 37.8635 95.777 38.4189C95.209 38.9742 95.9596 39.7033 95.4048 40.2719C94.85 40.8404 94.1216 40.0856 93.5536 40.6409C92.9856 41.1963 93.7397 41.9253 93.1849 42.4974C92.6301 43.0694 91.9017 42.3111 91.3337 42.8664C90.7657 43.4218 91.5163 44.1509 90.9615 44.7194C90.4067 45.2879 89.6783 44.5331 89.1103 45.0885C88.5423 45.6438 89.2964 46.3729 88.7416 46.9449C88.1868 47.5169 87.4424 46.7586 86.8869 47.314L85.5586 43.6129Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2.1"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default JacketAndPants
