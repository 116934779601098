import Wrapper from '../../components/modules/_wrapper'
import { Trans, useTranslation } from 'react-i18next'
import { WarningIcon } from '../../../../assets/images/svgs'
import { useLocation } from 'react-router-dom'
import React from 'react'
import { ROUTER_URL } from '../../../../utils/const'

interface SignupConfirmProps {}

const SignupConfirm: React.FC<SignupConfirmProps> = () => {

  const title = '仮登録完了 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = '仮登録完了のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [, i18n] = useTranslation('translation')
  const location: any = useLocation()

  const list = [
    {
      name: i18n.t('forgot_password.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('signup.confirm.breadcrum1'),
      link: ROUTER_URL.SIGNUP,
    },
    {
      name: i18n.t('signup.confirm.breadcrum2'),
      link: ROUTER_URL.SIGNUP_CONFIRM,
    },
  ]

  return (
    <Wrapper
      buttonText={i18n.t('forgot_password_noti.back_to_home')}
      buttonLink={ROUTER_URL.HOME}
      breadcrum={list}
      className="password "
    >
      <h2 className="password__title title-h2 distance-h2-to-text ">
        {i18n.t('signup.confirm.title')}
      </h2>
      <div className="password__describe distance-text-to-noti" id="active-title-email">
        <p>
          <Trans
            i18nKey="signup.confirm.message"
            values={{ email: location?.state?.email }}
            components={{
              span: <span className="password__describe-content1_link" />,
              br: <br />,
              b: <b />,
            }}
          />
        </p>
        <p className="password__describe-content3 distance-p-to-p">
          {i18n.t('signup.confirm.detail_2')}
        </p>
      </div>
      <div className="password__warning password__warning-noti  distance-text-to-btn">
        <div
          className="password__warning-icon password__warning-icon_noti padding-title-noti"
          id="forgot-warning-icon"
        >
          <WarningIcon />
          <div className="text-header-noti  distance-h4-to-icon">
            {i18n.t('forgot_password_noti.title_email_err')}
          </div>
        </div>
        <div
          className="password__warning-content password__warning-content_noti padding-content-noti padding-content-confirm-email"
          id="fgPW">
          <p className="password__warning-content_noti-text1">
            1.「入力したメールアドレス」が誤っていないか、メールアドレスをご確認ください。<br/>
            2.「迷惑メールフォルダ」に振り分けられていないかご確認ください。<br/>
            3.キャリアメール(docomo、au、SoftBankなど)は、パソコンからのメールの受信を拒否する設定がされている場合があります。<br/>
            受信許可リストに「<b>noreply@pocket-rd.com</b>」を受信できるように設定を変更してください。<br/>
            ※キャリアメールは、受信拒否設定や迷惑メールフィルターが影響する場合があるため、フリーメールアドレス（@gmailなど）の登録を推奨します。
          </p>

          <div className="section">
            <div className="title_h4">■ キャリアメールでの認証メール受信のための手順</div>
            <div className="title_h5">[ STEP1 ] メールアドレスの受信許可設定</div>
            <p className="password__warning-content_noti-text1">
              アカウント登録をする際には「【AVATARIUM】仮登録完了メール」が送信されます。<br/>
              下記のメールアドレスからの受信を許可いただくようお願いいたします。<br/>
              <b className="red">noreply@pocket-rd.com</b>
            </p>

            <div className="title_h6">● docomo</div>
            <p className="password__warning-content_noti-text1">
              My docomoへアクセスし、【受信リスト設定】より上記アドレスをご登録ください。<br/>
              （参考）迷惑メール対策の受信リスト／拒否リスト設定 | お知らせ | NTTドコモ<br/>
              <a className="text-link" href="https://www.nttdocomo.co.jp/info/spam_mail/domain/"
                 target="_blank" rel="noopener noreferrer" >https://www.nttdocomo.co.jp/info/spam_mail/domain/</a>
            </p>

            <div className="title_h6">● au</div>
            <p className="password__warning-content_noti-text1">
              au IDでログインし、［受信リストに登録／アドレス帳受信設定をする］より上記アドレスをご登録ください。<br/>
              （参考）受信リスト設定 | 迷惑メールフィルター設定 | au<br/>
              <a className="text-link" href="https://www.au.com/support/service/mobile/trouble/mail/email/filter/detail/domain/"
                 target="_blank" rel="noopener noreferrer" >https://www.au.com/support/service/mobile/trouble/mail/email/filter/detail/domain/</a>
            </p>

            <div className="title_h6">● SoftBank</div>
            <p className="password__warning-content_noti-text1">
              My SoftBank へアクセスし、「許可するメールの登録」より上記アドレスをご登録ください。<br/>
              （参考）受信許可リスト設定 | スマートフォン・携帯電話 | ソフトバンク<br/>
              <a className="text-link" href="https://www.softbank.jp/mobile/support/mail/antispam/email-i/white/"
                 target="_blank" rel="noopener noreferrer" >https://www.softbank.jp/mobile/support/mail/antispam/email-i/white/</a>
            </p>
          </div>

          <div className="section">
            <div className="title_h5">[ STEP2 ] URL付きメールの受信設定</div>
            <p className="password__warning-content_noti-text1">
              仮登録完了メールには、認証URLが記載されています。URL付メールに関する受信設定をご確認ください。
            </p>

            <div className="title_h6">● docomo</div>
            <p className="password__warning-content_noti-text1">
              「特定URL付メール拒否設定」が「拒否しない」となっているかご確認ください。<br/>
              （参考）特定URL付メール拒否設定 | お知らせ | NTTドコモ<br/>
              <a className="text-link" href="https://www.nttdocomo.co.jp/info/spam_mail/spmode/url/"
                 target="_blank" rel="noopener noreferrer" >https://www.nttdocomo.co.jp/info/spam_mail/spmode/url/</a>
            </p>

            <div className="title_h6">● au</div>
            <p className="password__warning-content_noti-text1">
              「URLリンク」「HTMLメール規制」のいずれも「規制しない」となっているかご確認ください。<br/>
              （参考）URLリンク／HTMLメール規制 | 迷惑メールフィルター機能 | au<br/>
              <a className="text-link" href="https://www.au.com/support/service/mobile/trouble/mail/email/filter/detail/antiurl/"
                 target="help_booking-attention.html">https://www.au.com/support/service/mobile/trouble/mail/email/filter/detail/antiurl/</a>
            </p>

            <div className="title_h6">● SoftBank</div>
            <p className="password__warning-content_noti-text1">
              「URLリンク付き メール拒否設定」が「利用しない」となっているか確認してください。<br/>
              （参考）URLリンク付きメール拒否設定（MMS／SMS） | スマートフォン・携帯電話 | ソフトバンク<br/>
              <a className="text-link" href="https://www.softbank.jp/mobile/support/mail/antispam/mms/antiurl/"
                 target="_blank" rel="noopener noreferrer" >https://www.softbank.jp/mobile/support/mail/antispam/mms/antiurl/</a><br/><br/>

              上記をご確認いただいても改善されない場合は、お手数ですが<a className="text-link" href={ROUTER_URL.CONTACT}>【問い合わせフォーム】</a>からご連絡ください。
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default SignupConfirm
