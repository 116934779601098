import React from 'react'

interface TakenCheckProps {
  height?: string
  width?: string
  color?: string
}

const TakenCheck: React.FC<TakenCheckProps> = ({ height, width, color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7346 2.66699H8.26797C5.17957 2.66699 2.66797 5.17859 2.66797 8.26699V20.4003C2.66797 20.6479 2.7663 20.8853 2.94134 21.0603C3.11637 21.2353 3.35377 21.3337 3.6013 21.3337H15.7346C18.823 21.3337 21.3346 18.8221 21.3346 15.7337V8.26699C21.3346 5.17859 18.823 2.66699 15.7346 2.66699ZM11.068 16.1201L7.6081 12.6602L8.92783 11.3405L11.068 13.4806L15.5414 9.00713L16.8612 10.3269L11.068 16.1201Z"
        fill="#12C4C4"
      />
    </svg>
  )
}

export default TakenCheck
