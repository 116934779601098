import * as React from 'react';

interface AvatarIconProps {
   height?: string;
   width?: string;
};
const AvatarIconHover: React.FC<AvatarIconProps> = ({ height, width }) => {
   return (
      <svg width={width || "16"} height={height || "16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0.25C3.72675 0.25 0.25 3.72675 0.25 8C0.25 12.2733 3.72675 15.75 8 15.75C12.2733 15.75 15.75 12.2733 15.75 8C15.75 3.72675 12.2733 0.25 8 0.25ZM8 14.45C4.8 14.45 1.55 11.9978 1.55 8C1.55 7.787 1.56125 7.5765 1.57925 7.368C2.51425 6.17225 2.964 6.164 4.53425 6.1715C6.28825 6.16825 8.454 6.1735 10.6753 4.28725C12.422 6.886 14.4338 7.53825 14.4338 7.53825C14.4338 7.53825 14.45 7.84475 14.45 8C14.45 11.9978 11.2 14.45 8 14.45Z" fill="white"/>
      <path d="M4.625 10.5C5.10825 10.5 5.5 10.1082 5.5 9.625C5.5 9.14175 5.10825 8.75 4.625 8.75C4.14175 8.75 3.75 9.14175 3.75 9.625C3.75 10.1082 4.14175 10.5 4.625 10.5Z" fill="white"/>
      <path d="M11.375 10.5C11.8582 10.5 12.25 10.1082 12.25 9.625C12.25 9.14175 11.8582 8.75 11.375 8.75C10.8918 8.75 10.5 9.14175 10.5 9.625C10.5 10.1082 10.8918 10.5 11.375 10.5Z" fill="white"/>
      </svg>
   );
};

export default AvatarIconHover