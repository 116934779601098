import { AnyAction, Reducer } from 'redux';
import { initialSaveInfoCreadit,  SaveInfoCreaditState} from '../states';
import { CreaditTypes } from '../actions';

export const SaveInfoReducer: Reducer = (state: SaveInfoCreaditState = initialSaveInfoCreadit, action: AnyAction) => {
    switch (action.type) {
        case  CreaditTypes.SAVE_INFO_CREADIT:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state           
    }
};
