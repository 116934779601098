import * as React from 'react'

interface JapaneseFlagTopProps {
  height?: string
  width?: string
}
const JapaneseFlagTop: React.FC<JapaneseFlagTopProps> = ({ height, width }) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="33" height="33" fill="url(#japanFlag)" />
      <defs>
        <pattern id="japanFlag" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#japan-flag-top" transform="scale(0.030303)" />
        </pattern>
        <image
          id="japan-flag-top"
          width="33"
          height="33"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAABHNCSVQICAgIfAhkiAAABKhJREFUWEfFWEtvU0cUPvdePxPF8St2IE1NqtiqIkRpBGlTJNSqqBIbJDaQAGqkqCs2LFm05dV0AeIHsEGRmkRFQiILFiAFWrqgEFq1hUUFMq1xQ+vXdew4TuLndb8JsWXwvXccq6gjxZY1Z2a+Oec73zkTgTYxKpWKKZFIDBiNxoOiKPYJgvARlm/BXxRz3+M7lM/nZ5eWln73+/35ZrcWmjFMpVLbYDdjNps/sFgshMM1lwEM5XI5ApgHhUJh1Ov1/sk7QxdEMBi0OZ3Onzo7OwOSJPH2apgvl8uUTqefraysDPp8vpTWBpog4vH4YZvNNmMymSS9m/OQMc8Ui8UywIzBKzNq9qogksnkqY6OjrOIPe+MpucBhOCR0w6H49yrixpAMABw/9lW3M9DxMKTyWS+Roi/qLd9CQSYPwIA3/6XHngVWKlUosXFRRaab6pzNRCMhL29vYvIAC4DK3CtkslQJY8sxO1IFElA1og2GwlNhBBZo4BzXew8BqQGAmF4gngFeCRcu32bUidPkpJOE+FWBOIhZ4mQPaLdTvaJCWrbv183MoysSPugy+UK1EAwHQARQ3o8KCeTlDx+nArz8y8O1hmmXbvIdekSSV1dmlaMHwhLwOPxBNc9gR8/wgvDWivKqRTJIyNUfPyYx73avKG/n7quXiXJ6dRcg3Pn4I1PBCbFa2treavVqmkcP3KECnfvNg2gamgcHCTv7KzmOqas0WjUKiAUO5ERv2pxIXfvHsmjo9wQaJ3knpoiy969qtOMG/DGewzEObvd/qWaVQVxiwwPkxKLbdoLtfRrb6etDx9qZg2K3XkBH1OQ52NqpzAuRIaGiAqFlkGwhd3375NhCyu2jQPidUXAxwIy4w01g1IkQlF4gpcNPITeW7fI6PermmWz2X8YiBJAqApU6flziu7ZwzuDO++5cYNMAwNaIBQG4m+A2PpaPTE3R8bAui41DHgiwjgxDU4c1eTE7t2EWsy9rZ6BHicA4grLjq+QHS9VteqGFcjyenbE462DaGujnkeP9LLjgoBC8q7b7f5FUyfu3CF5bKxlEK7Ll8m6b5+eTrwvoHqae3p6crqKeejQi5qxyWHcsYO8169rrqopJrNABX2ARgPBVx+seCUApPT0adMwJJ+PPNeukeR2a65By/cdatbH6wUsFou9hULyh24VlWWSx8epCPXjDeP27eSenCTJ49E0ZVUU576NKDyp9ROyLD+DN3y8fmL15k1KnThBFRSfhmE2k+PiRWo/cEAX50Y/EcbFtzHDGohwOOzo7u5OsO6ad1PWUZUTCapks8Tqi4CGRkCNENE/iOiweIN1Vqurqy6EAp1RHQj2Az3mUVTU6dfdY0Igx+GFySpYtW77NICc+d+67Soy1PgJSPnnBoOB59mm51mXvby83NDuN4Sjfkcw91Mo6SRCI/LIqodk4wWmQJ4/qw9B/Rrdt+jCwoITIjYPMP2thGfjsRPGJXZWSagGuKlXOaTdD49M40U+hHcJ91WOFzl7lf+MZ98xpgO8mDUForpJKBSywDPvAMxB3O5N/I/iQ8x5md4pivIDXP8XpHgWjfNvfX19KkKiDudf4n8hfY6Bj4EAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  )
}

export default JapaneseFlagTop
