import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { setLang } from '../../../../stores'
import Select from 'react-select'
import JapaneseIcon from '../../../../../assets/images/svgs/japanese-icon'
import UsIcon from '../../../../../assets/images/svgs/us-icon'
import ChervonIcon from '../../../../../assets/images/svgs/chervon-icon'
import { useDispatch } from 'react-redux'
import { useBreakpoint } from '../../../../../customize/hooks/useBreakpoint'
import axios from '../../../../../utils/axios'
import { GET_DEFAULT_LANGUAGE, UPDATE_DEFAULT_LANGUAGE  } from '../../../../../utils/endpoints'

interface SwitchLanguagesProps {
  position?: string
}

export const SwitchLanguages: React.FC<SwitchLanguagesProps> = ({ position }) => {
  const [, i18n] = useTranslation('translation')
  const [selectInput, setSelectInput] = useState(true)
  const dispatch = useDispatch()
  const device = useBreakpoint()

  const setDefaultLanguage = async () => {
    try {
      await axios.get(GET_DEFAULT_LANGUAGE).then(res => {
        let dbLang = res?.data.data
        let i18nextLng = 'ja'
        if (dbLang === 'JP') {
          i18nextLng = 'ja'
          setSelected('日本語')
        } else if (dbLang === 'EN') {
          i18nextLng = 'en'
          setSelected('US')
        }
        localStorage.setItem('i18nextLng', i18nextLng)
        i18n.changeLanguage(i18nextLng)
        dispatch(setLang(i18nextLng))
      })
   } catch (error) {
   }
  }

  const getSelected = () => {
    const i18nextLng = localStorage.getItem('i18nextLng') || i18n.language
    if (i18nextLng === 'en') {
      return 'US'
    }
    return '日本語'
  }

  const [selected, setSelected] = useState(getSelected())

  const jp = () => {
    return (
      <span style={{ display: 'flex', alignItems: 'center', margin: 0, padding: 0 }}>
        <JapaneseIcon />
        <span className="switch-languages__name switch-languages__japan">日本語</span>
      </span>
    )
  }
  const uS = () => {
    return (
      <span style={{ display: 'flex', alignItems: 'center', margin: 0, padding: 0 }}>
        <UsIcon />
        <span className="switch-languages__name switch-languages__us">English</span>
      </span>
    )
  }
  const DropdownIndicator = (props: any) => {
    return <ChervonIcon {...props} check={position === 'header'} />
  }
  const options = [
    { value: '日本語', label: jp() },
    { value: 'US', label: uS() },
  ]

  const customStyle = {
    control: (base: any) => ({
      ...base,
      //width: 146,
      border: '0 !important',
      outline: 'none',
      fontFamily: 'Roboto',
      //fontSize: 12,
      boxShadow: 'none',
      background: position === 'header' ? 'transparent' : '',
      color: position === 'header' ? 'white' : '',
      '&:hover': {
        border: 0,
      },
      cursor: 'pointer',
    }),
    menu: (base: any) => ({
      ...base,
      fontFamily: 'Roboto',
      fontSize: 12,
      border: '0 !important',
      outline: 'none',
      boxShadow: 'none',
      overflow: 'hidden',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: position === 'header' ? '#FFF !important' : '',
    }),
    singleValue: (base: any) => ({
      ...base,
      color: position === 'header' ? '#FFF !important' : '',
      position: 'relative',
      maxWidth: 'initial',
      transform: 'none',
    }),
    option: (styles: any, { isFocused }: any) => ({
      ...styles,
      backgroundColor: isFocused ? '#12C4C4' : '#ccc',
      color: isFocused ? '#ffffff' : '#000',
      cursor: 'pointer',
    }),
    valueContainer: (base: any) => ({
      ...base,
      //padding: '4px',
      justifyContent: 'flex-end',
    }),
    indicatorsContainer: () => ({
      display: 'inline-block',
      marginRight: 0,
    }),

    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      border: '0 !important',
      outline: 'none',
      boxShadow: 'none',
    }),
  }
  useEffect(() => {
    if (localStorage.getItem('access_token')){
      setDefaultLanguage()
    } else {
      let i18nextLng = localStorage.getItem('i18nextLng') || 'ja'
      if (selected === '日本語') {
        i18nextLng = 'ja'
      } else if (selected === 'US') {
        i18nextLng = 'en'
      }
      i18n.changeLanguage(i18nextLng)
      dispatch(setLang(i18nextLng))
    }
  }, [selected])

  async function updateLanguage(language: string, value: any){
    if (localStorage.getItem('access_token')) {
      await axios.post(UPDATE_DEFAULT_LANGUAGE, {"language": language})
      localStorage.setItem('i18nextLng', language)
    }
    setSelected(value)
    setSelectInput(!selectInput)
  }
  const handleChange = (e: any) => {
    let language = ''
    switch(e.value){
      case 'US':
        language = 'EN'
        break;
      default:
        language = 'JP'
        break;
    }
    updateLanguage(language, e.value)
  }

  return (
    <div className="demo-wrapper">
      <Select
        options={options}
        menuPlacement={!device || device === 'mobiles' ? 'top' : 'bottom'}
        value={options.find((o: any) => o.value === selected)}
        onChange={handleChange}
        styles={customStyle}
        components={{ DropdownIndicator }}
        isSearchable={false}
      />
    </div>
  )
}
