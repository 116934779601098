interface CardProps {
    width?:string,
    height?:string
}

const Card: React.FC<CardProps> = ({width,height}) => {
   return (
    <svg
    width={width || 32}
    height={height || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.668 13.334h26.667v10.667a2.667 2.667 0 01-2.667 2.666H5.335a2.667 2.667 0 01-2.667-2.666V13.334zm18.111 8.806a3.333 3.333 0 110-4.28 3.333 3.333 0 110 4.28z"
      fill="#9496AB"
    />
    <path
      d="M2.668 8a2.667 2.667 0 012.667-2.666h21.333a2.667 2.667 0 012.667 2.667v2.666H2.668V8.001z"
      fill="#9496AB"
    />
  </svg>
   )
}

export default Card;