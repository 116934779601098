import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Button } from '../../atoms/buttons'
import { ACCEPT_REQUEST_FRIEND, REJECT_REQUEST_FRIEND } from '../../../../../utils/endpoints'
import { POST, ROUTER_URL } from '../../../../../utils/const'
import { useHistory } from 'react-router-dom'

interface NotificationProps {
  date: string
  textTitle: string
  textContent: string
  status?: number
  friend_id?: number
  getNotisDB?: any
  toggleNoti?: any
  friend_status?: string
  first?: boolean
  callApi: any
}

const NotificationComponent: React.FC<NotificationProps> = ({
  date,
  textTitle,
  textContent,
  status,
  friend_id,
  getNotisDB,
  friend_status,
  toggleNoti,
  first,
  callApi,
}) => {
  const [t, i18n] = useTranslation('translation')
  const history = useHistory()
  // Remove sent request
  async function onRejectRequest(e: any) {
    e.stopPropagation()
    try {
      const params = {
        customer_id: friend_id,
      }
      const result = await callApi(REJECT_REQUEST_FRIEND, POST, params)
      if (result) {
        getNotisDB()
      }
    } catch (error: any) {
      if (error?.message) {
      }
    }
  }

  // Accept friend request
  async function onAcceptRequest(e: any) {
    e.stopPropagation()
    try {
      const params = {
        customer_id: friend_id,
      }
      const result = await callApi(ACCEPT_REQUEST_FRIEND, POST, params)
      if (result) {
        getNotisDB()
      }
    } catch (error: any) {
      if (error?.message) {
      }
    }
  }

  const RenderStatus1 = ({ status }: { status: number | undefined }) => {
    switch (status) {
      case 2:
        return (
          <div className="box-status">
            <span className="new">{i18n.t('notifications.new')}</span>
          </div>
        )
      case 1:
        return (
          <div className="box_circle">
            <div className="circle"></div>
          </div>
        )
      default:
        return (
          <div className="box-status" style={{ visibility: 'hidden' }}>
            <span className="new">{i18n.t('notifications.new')}</span>
          </div>
        )
    }
  }

  const goToLink = (link: any) => (e: any) => {
    e.stopPropagation()
    history.push(link ? link : ROUTER_URL.HOME)
  }

  function contentToJsx(content: string) {
    if (!content) return ''
    let elementList: any = []
    let replaceTextList = content.split('\n')
    for (let replaceText of replaceTextList) {
      if (replaceText.match(/[・].*/)) {
        elementList.push((<>{replaceText}</>))
          continue
      }
      if (replaceText.match(/.*<a.*/)) {
        let linkFirstText = replaceText.substring(0, replaceText.indexOf('<a '))

        let urlStartIndex = replaceText.indexOf('http', 0)
        let url = replaceText.substring(
          urlStartIndex, replaceText.indexOf('"', urlStartIndex)
        )
        let domain: any = url.match(/http.*\//)
        url = url.replace(domain[0], '/')

        let linkText: any = replaceText.match(/>.*</)
        linkText = linkText[0].substring(1, linkText[0].length - 1)
        elementList.push((
        <>
          {linkFirstText}<a className="text-link" onClick={goToLink(url)}>{linkText}</a>
        </>))
        continue
      }
      elementList.push((<>{replaceText}</>))
    }
    return (<>{elementList.map((element: any, index: number) => <span key={index}>{element}<br /></span>)}</>)
  }

  const renderDate = (
    <div className="box_date medium-text-dark">
      <span>
        {localStorage.getItem('i18nextLng') === 'ja'
          ? moment(new Date(date).toISOString()).format('YYYY年MM月DD日 HH:mm')
          : moment(new Date(date).toISOString()).format('MMM DD, YYYY hh:mma')}
      </span>
    </div>
  )

  const renderTextContent = (
    <div className="text_content">
      <p className="content1 noti_title">
        {textTitle}
      </p>
      <p className="content2 noti_content">
        {contentToJsx(textContent)}
      </p>
    </div>
  )

  return (
    <div className="noti-wrap_content-noti">
      <div className="wrap-header">
        <RenderStatus1 status={status} />
      </div>
      <div className="wrap-content">
        {renderDate}
        {renderTextContent}
        {!!friend_id && friend_status === 'received_request' && (
          <div className="wrap-content__btn">
            <Button onClick={onRejectRequest} className="cancel">
              {t('friends.btn_delete')}
            </Button>
            <Button onClick={onAcceptRequest}>{t('friends.btn_accept')}</Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default NotificationComponent
