import * as React from 'react';

interface FriendIconProps {
   height?: string;
   width?: string;
};
const FriendIconSearch: React.FC<FriendIconProps> = ({ height, width }) => {
   return (
      <svg width={width || "20"} height={height || "20"} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
         <path d="M19.6337 16.6163L16.25 13.2325C17.0375 11.92 17.5 10.3888 17.5 8.75C17.5 3.925 13.5737 0 8.75 0C3.92625 0 0 3.925 0 8.75C0 13.575 3.92625 17.5 8.75 17.5C10.39 17.5 11.92 17.0387 13.2325 16.25L16.6163 19.6337C16.86 19.8775 17.18 20 17.5 20C17.82 20 18.14 19.8775 18.3837 19.6337L19.6337 18.3837C20.1225 17.895 20.1225 17.105 19.6337 16.6163ZM8.75 15C5.29875 15 2.5 12.2013 2.5 8.75C2.5 5.29875 5.29875 2.5 8.75 2.5C12.2013 2.5 15 5.29875 15 8.75C15 12.2013 12.2013 15 8.75 15Z" fill="#727488"/>

      </svg>
   );
};

export default FriendIconSearch