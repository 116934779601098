import { AnyAction, Reducer } from 'redux';
import { initialListAvatar, AvatarState } from '../states';
import { AvatarTypes } from '../actions';

export const ListAvatarReducer: Reducer = (state: AvatarState = initialListAvatar, action: AnyAction) => {
    switch (action.type) {
        case AvatarTypes.GET_LIST_AVATAR_EMPTY:
            return {
                empty: true,
                origin: false,
                edited: false,
                data: action.payload
            }
        case AvatarTypes.GET_LIST_AVATAR_ORIGINAL:
            return {
                empty: false,
                origin: true,
                edited: false,
                data: action.payload
            }
        case AvatarTypes.GET_LIST_AVATAR_EDITED:
            return {
                empty: false,
                origin: false,
                edited: true,
                data: action.payload
            }
        default:
            return state
    }
};
