import React from 'react'

interface NewsBannerIconProps {
  height?: string
  width?: string
}

const NewsBannerIcon: React.FC<NewsBannerIconProps> = ({ height, width }) => {
  return (
    <svg width={width || "39"} height={height || "40"} viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.5" cy="32.6055" r="6.5" fill="#12C4C4" />
      <circle cx="32.5" cy="32.6055" r="6.5" fill="#FF6C6C" />
      <circle cx="6.5" cy="6.60547" r="6.5" fill="#FF6C6C" />
      <circle cx="32.5" cy="6.60547" r="6.5" fill="#12C4C4" />
    </svg>
  )
}

export default NewsBannerIcon
