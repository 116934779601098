import * as React from 'react'

interface NewsBannerBackgroundProps {
  height?: string
  width?: string
}
const NewsBannerBackground: React.FC<NewsBannerBackgroundProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '445'}
      height={height || '110'}
      viewBox="0 0 445 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2">
        <path
          d="M87.2422 108.105H65.2695L22.4961 37.9395V108.105H0.523438V1.46484H22.4961L65.3428 71.7773V1.46484H87.2422V108.105Z"
          fill="#9496AB "
        />
        <path
          d="M185.592 61.8896H143.404V90.4541H192.916V108.105H121.432V1.46484H192.77V19.2627H143.404V44.6777H185.592V61.8896Z"
          fill="#9496AB "
        />
        <path
          d="M303.863 77.3438L318.219 1.46484H340.118L316.461 108.105H294.342L276.983 36.7676L259.625 108.105H237.506L213.849 1.46484H235.748L250.177 77.1973L267.755 1.46484H286.358L303.863 77.3438Z"
          fill="#9496AB "
        />
        <path
          d="M422.354 80.127C422.354 75.9766 420.89 72.8027 417.96 70.6055C415.03 68.3594 409.757 66.0156 402.14 63.5742C394.522 61.084 388.492 58.6426 384.049 56.25C371.939 49.707 365.885 40.8936 365.885 29.8096C365.885 24.0479 367.496 18.9209 370.719 14.4287C373.99 9.8877 378.653 6.34766 384.708 3.80859C390.812 1.26953 397.647 0 405.216 0C412.833 0 419.62 1.3916 425.577 4.1748C431.534 6.90918 436.148 10.791 439.42 15.8203C442.74 20.8496 444.4 26.5625 444.4 32.959H422.428C422.428 28.0762 420.89 24.292 417.813 21.6064C414.737 18.8721 410.416 17.5049 404.85 17.5049C399.479 17.5049 395.304 18.6523 392.325 20.9473C389.347 23.1934 387.857 26.1719 387.857 29.8828C387.857 33.3496 389.591 36.2549 393.058 38.5986C396.573 40.9424 401.725 43.1396 408.512 45.1904C421.012 48.9502 430.118 53.6133 435.831 59.1797C441.544 64.7461 444.4 71.6797 444.4 79.9805C444.4 89.209 440.909 96.46 433.927 101.733C426.944 106.958 417.545 109.57 405.729 109.57C397.525 109.57 390.055 108.081 383.316 105.103C376.578 102.075 371.427 97.9492 367.862 92.7246C364.347 87.5 362.589 81.4453 362.589 74.5605H384.635C384.635 86.3281 391.666 92.2119 405.729 92.2119C410.953 92.2119 415.03 91.1621 417.96 89.0625C420.89 86.9141 422.354 83.9355 422.354 80.127Z"
          fill="#9496AB "
        />
      </g>
    </svg>
  )
}

export default NewsBannerBackground
