import { useTranslation } from 'react-i18next'
import { Button } from '../../components/atoms/buttons'
import { CloseIcon } from '../../../../assets/images'

interface DownloadModalProps {
  toggleModal: () => void
  dowloadAvatar: any
  price?: any
  cardNumberDefault?: any
  cardNumber?: any
  cardBandDefault?: any
  cardBand?: any
}

const DownloadModal2: React.FC<DownloadModalProps> = ({
  toggleModal,
  dowloadAvatar,
  price,
  cardNumberDefault,
  cardNumber,
  cardBandDefault,
  cardBand,
}) => {
  const [t] = useTranslation('translation')
  return (
    <>
      <div className="detail-avatar__delete-modal padding-modal-in-h2">
        <div className="detail-avatar__delete-modal_header distance-h2-to-line">
          <div className="detail-avatar__delete-modal_header-text">
            <div className="pop-text-bold-big">{t('download_avatar.confirm_title')}</div>
          </div>
          <button
            className="detail-avatar__delete-modal_header-icon close-icon"
            onClick={toggleModal}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="detail-avatar__delete-modal_content2 distance-line-to-text padding-modal-content-to-btn">
          <div className="distance-text-to-text pop-large-body-text text-pre-line">
            {t('download_avatar.title_download')}
          </div>
          <div className="modal1-download-avatar">
            <div className="modal1-download-avatar__title pop-big-bold-text">
              {t('download_avatar.method')}
            </div>
            <div className="modal1-download-avatar__content pop-big-bold-text">
              {cardBand === '' ? cardBandDefault : cardBand} &nbsp; &nbsp;
              {cardNumber === '' ? cardNumberDefault.slice(12, 16) : cardNumber.slice(12, 16)}
            </div>
          </div>
          <div className="modal1-download-avatar">
            <div className="modal1-download-avatar__title pop-big-bold-text">
              {t('download_avatar.download_fee')}
            </div>
            <div className="modal1-download-avatar__content2 pop-text-bold-big text-error">
              {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(
                Number(price)
              )}
            </div>
          </div>
        </div>
        <div className="detail-avatar__delete-modal_button">
          <Button className="normal" onClick={dowloadAvatar}>
            {t('download_avatar.button_download')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default DownloadModal2
