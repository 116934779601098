import React from 'react'

interface BoxContentProps {
  height?: string
  width?: string
}

const BoxContent: React.FC<BoxContentProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width || "429"} height={height || "360"} viewBox="0 0 429 360">
  <defs>
    <clipPath id="clipPath">
      <rect width="429" height="360" fill="none"/>
    </clipPath>
  </defs>
  <g id="BOXS1_アートボード_1" data-name="BOXS1_アートボード 1" clipPath="url(#clipPath)">
    <g id="BOXS1_アートボード_1-2" data-name="BOXS1_アートボード 1" transform="translate(-0.99 -0.979)">
      <path id="Path_666" data-name="Path 666" d="M425.99,292H17V1H425.99ZM18,291H424.99V2H18Z" fill="#12c4c4" opacity="0.5" style={{isolation: "isolate"}} />
      <rect id="長方形_73-4" data-name="長方形 73-4" width="3" height="3" transform="translate(17 1)" fill="#12c4c4"/>
      <rect id="長方形_74-4" data-name="長方形 74-4" width="3" height="3" transform="translate(422.99 1)" fill="#12c4c4"/>
      <rect id="長方形_75-4" data-name="長方形 75-4" width="3" height="3" transform="translate(422.99 288.979)" fill="#12c4c4"/>
      <rect id="長方形_88-4" data-name="長方形 88-4" width="3" height="3" transform="translate(17 288.979)" fill="#12c4c4"/>
      <rect id="Rectangle_3443" data-name="Rectangle 3443" width="499.614" height="0.991" transform="translate(18.017 290.21) rotate(-35.374)" fill="#12c4c4" opacity="0.5" style={{isolation: "isolate"}} />
      <g id="Group_10633" data-name="Group 10633" transform="translate(0.99 243.827)" style={{isolation: "isolate"}}>
        <path id="Path_667" data-name="Path 667" d="M4.668,249.251C2.279,249.251,1,247.9,1,245.942a3.369,3.369,0,0,1,.41-1.739l.779.38a2.463,2.463,0,0,0-.33,1.35c0,1.419.89,2.318,2.639,2.318h.25a2.335,2.335,0,0,1-.92-2.019,2.153,2.153,0,0,1,2.119-2.388,2.254,2.254,0,0,1,2.209,2.468C8.156,248.191,6.9,249.251,4.668,249.251ZM6,248.021c.72,0,1.35-.58,1.35-1.668a1.376,1.376,0,0,0-1.36-1.54c-.819,0-1.349.61-1.349,1.589A1.455,1.455,0,0,0,6,248.021Z" transform="translate(-0.99 -243.844)" fill="#12c4c4"/>
        <path id="Path_668" data-name="Path 668" d="M3.209,256.82A2.263,2.263,0,0,1,1,254.342c0-1.879,1.26-2.939,3.488-2.939,2.389,0,3.668,1.35,3.668,3.309a3.363,3.363,0,0,1-.41,1.738l-.779-.38a2.422,2.422,0,0,0,.33-1.339c0-1.419-.89-2.329-2.639-2.329a1.98,1.98,0,0,0-.25.011,2.326,2.326,0,0,1,.92,2.009,2.161,2.161,0,0,1-2.119,2.4Zm-.04-.969c.819,0,1.35-.62,1.35-1.6a1.452,1.452,0,0,0-1.36-1.609c-.72,0-1.349.58-1.349,1.659a1.385,1.385,0,0,0,1.359,1.55Z" transform="translate(-0.99 -243.844)" fill="#12c4c4"/>
        <path id="Path_669" data-name="Path 669" d="M3.139,263.551C1.96,263.551,1,262.681,1,260.9a3.967,3.967,0,0,1,.85-2.569l.79.46a3.145,3.145,0,0,0-.75,2.109c0,1.049.479,1.649,1.239,1.649.739,0,1.22-.53,1.22-1.729v-.559h.7l2.159,1.758v-3.368h.869v4.618H7.4l-2.258-1.83C5.008,262.841,4.219,263.551,3.139,263.551Z" transform="translate(-0.99 -243.844)" fill="#12c4c4"/>
        <path id="Path_670" data-name="Path 670" d="M1.949,270.54H1.08v-5.027h.68l2.748,2.848c.72.741,1.16.88,1.6.88.72,0,1.16-.51,1.16-1.489a2.2,2.2,0,0,0-.76-1.789l.59-.68a3.225,3.225,0,0,1,1.059,2.559c0,1.459-.75,2.4-1.949,2.4a2.83,2.83,0,0,1-2.2-1.149l-2.06-2.139Z" transform="translate(-0.99 -243.844)" fill="#12c4c4"/>
        <path id="Path_671" data-name="Path 671" d="M8.076,274.722h-7v-.99H7.207v-1.579h.869Z" transform="translate(-0.99 -243.844)" fill="#12c4c4"/>
        <path id="Path_672" data-name="Path 672" d="M4.618,282.86v.959H1.89A4.132,4.132,0,0,1,1,281.171a3.546,3.546,0,0,1,3.578-3.729,3.55,3.55,0,0,1,3.578,3.748,3.509,3.509,0,0,1-1.079,2.689l-.62-.619a2.773,2.773,0,0,0,.81-2.03,2.647,2.647,0,0,0-2.689-2.788,2.656,2.656,0,0,0-2.688,2.779,2.959,2.959,0,0,0,.45,1.639Z" transform="translate(-0.99 -243.844)" fill="#12c4c4"/>
        <path id="Path_673" data-name="Path 673" d="M1.949,292H1.08v-5.077h7v4.937H7.207v-3.938H5.058v3.509h-.85v-3.509H1.949Z" transform="translate(-0.99 -243.844)" fill="#12c4c4"/>
      </g>
      <path id="Path_674" data-name="Path 674" d="M299,360H262V323h37Zm-36-1h35V324H263Z" transform="translate(-0.007 -0.021)" fill="#12c4c4" opacity="0.5" style={{isolation: "isolate"}} />
      <rect id="長方形_73-3" data-name="長方形 73-3" width="2" height="2" transform="translate(261.994 322.979)" fill="#12c4c4"/>
      <rect id="長方形_73-3-2" data-name="長方形 73-3" width="2" height="2" transform="translate(296.993 322.979)" fill="#12c4c4"/>
      <rect id="長方形_73-3-3" data-name="長方形 73-3" width="2" height="2" transform="translate(261.994 357.979)" fill="#12c4c4"/>
      <rect id="長方形_73-3-4" data-name="長方形 73-3" width="2" height="2" transform="translate(296.993 357.979)" fill="#12c4c4"/>
      <path id="Path_675" data-name="Path 675" d="M299,310H262V273h37Zm-36-1h35V274H263Z" transform="translate(-0.007 -0.021)" fill="#12c4c4" opacity="0.5" style={{isolation: "isolate"}} />
      <rect id="長方形_73-3-5" data-name="長方形 73-3" width="2" height="2" transform="translate(261.994 272.984)" fill="#12c4c4"/>
      <rect id="長方形_73-3-6" data-name="長方形 73-3" width="2" height="2" transform="translate(296.993 272.984)" fill="#12c4c4"/>
      <rect id="長方形_73-3-7" data-name="長方形 73-3" width="2" height="2" transform="translate(261.994 307.979)" fill="#12c4c4"/>
      <rect id="長方形_73-3-8" data-name="長方形 73-3" width="2" height="2" transform="translate(296.993 307.979)" fill="#12c4c4"/>
      <path id="Path_676" data-name="Path 676" d="M299,260H262V223h37Zm-36-1h35V224H263Z" transform="translate(-0.007 -0.015)" fill="#12c4c4" opacity="0.5" style={{isolation: "isolate"}} />
      <rect id="長方形_73-3-9" data-name="長方形 73-3" width="2" height="2" transform="translate(261.994 222.987)" fill="#12c4c4"/>
      <rect id="長方形_73-3-10" data-name="長方形 73-3" width="2" height="2" transform="translate(296.993 222.987)" fill="#12c4c4"/>
      <rect id="長方形_73-3-11" data-name="長方形 73-3" width="2" height="2" transform="translate(261.994 257.985)" fill="#12c4c4"/>
      <rect id="長方形_73-3-12" data-name="長方形 73-3" width="2" height="2" transform="translate(296.993 257.985)" fill="#12c4c4"/>
    </g>
  </g>
</svg> 
  )
}

export default BoxContent
