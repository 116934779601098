import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconGroup2: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
    width={285}
    height={150}
    viewBox="0 0 285 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.456 11h263.088c4.047 0 7.456 3.566 7.456 8.13v121.74c0 4.563-3.409 8.13-7.456 8.13H13.456C9.41 149 6 145.433 6 140.87V19.13C6 14.567 9.41 11 13.456 11z"
      fill="#fff"
      stroke="#FF6C6C"
      strokeWidth={2}
    />
    <path
      d="M16.5 33C25.613 33 33 25.613 33 16.5S25.613 0 16.5 0 0 7.387 0 16.5 7.387 33 16.5 33z"
      fill="#FF6C6C"
    />
    <path
      d="M18.243 16.47l5.338-5.337a1.25 1.25 0 10-1.767-1.77l-5.34 5.339-5.338-5.338a1.25 1.25 0 10-1.769 1.769l5.338 5.338-5.338 5.34a1.251 1.251 0 001.77 1.769l5.337-5.338 5.34 5.336a1.251 1.251 0 001.768-1.767l-5.339-5.34z"
      fill="#fff"
    />
    <path
      d="M89.38 78.223l-.093-.091S75.066 63.007 71.523 59.185c-3.543-3.822-7.086-4.724-8.546-4.724H50.402c-1.46 0-5 .9-8.546 4.724C38.31 63.009 24.09 78.132 24.09 78.132l-.093.09a3.117 3.117 0 104.326 4.49l.119-.112c1.4-1.395 16.18-16.14 16.468-16.4.287-.26 1.554-1.543 1.52-.206-.02 1.1-.171 12.05-.171 12.168v-4.346l-.4 42.718v4.563a3.912 3.912 0 003.892 3.718 3.801 3.801 0 003.9-3.592v-.126s1.632-28.03 1.7-29.733c.068-1.703.969-1.827 1.32-1.827.353 0 1.25.124 1.32 1.827.07 1.703 1.7 29.733 1.7 29.733v.126a3.8 3.8 0 003.9 3.592 3.911 3.911 0 003.892-3.718v-4.563l-.4-42.718v4.346c0-.113-.154-11.06-.174-12.168-.026-1.336 1.27-.024 1.52.206.25.23 15.07 15 16.469 16.4l.12.113a3.116 3.116 0 004.409-.08 3.15 3.15 0 00-.047-4.41zM56.688 51.97a8.485 8.485 0 100-16.97 8.485 8.485 0 000 16.97z"
      fill="#fff"
      stroke="#111947"
      strokeWidth={2}
    />
    <path
      d="M72.739 57.522A10.8 10.8 0 0064.57 53.5H48.808a10.78 10.78 0 00-8.166 4.022L28.5 70.429l7.679 7.579 4.809-5.2-1.107 18.582h33.612l-1.1-18.582 4.809 5.215 7.679-7.579-12.142-12.922z"
      fill="#85C3C3"
      stroke="#111947"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M71.784 91.606a.256.256 0 00-.255-.217H41.846a.256.256 0 00-.254.217l-2.724 23.378a.613.613 0 00.64.652h15.805a.62.62 0 00.652-.578l.476-18.34a.256.256 0 01.511 0l.477 18.34a.618.618 0 00.64.578h15.808a.62.62 0 00.466-.184.614.614 0 00.173-.468l-2.733-23.378z"
      fill="#85C3C3"
      stroke="#111947"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M150.375 119.148l.111 1.921v.126a3.804 3.804 0 003.9 3.592 3.907 3.907 0 002.687-1.083 3.907 3.907 0 001.205-2.635v-.217l-.017-1.706-7.886.002zM180.155 78.2l-.093-.09-3.259-3.477-4.522 4.52 3.43 3.42.119.113a3.12 3.12 0 005.307-2.295 3.12 3.12 0 00-.979-2.194l-.003.004zM136.654 119.149l-.017 1.706v.217a3.906 3.906 0 003.892 3.718 3.798 3.798 0 003.9-3.592v-.129l.111-1.921-7.886.001zM118.128 74.637l-3.259 3.477-.093.09a3.12 3.12 0 00.901 5.132 3.121 3.121 0 003.427-.642l.119-.113 3.431-3.42-4.526-4.524z"
      fill="#000"
    />
    <path
      d="M180.158 78.201l-.1-.09s-14.217-15.126-17.761-18.948c-3.544-3.821-7.089-4.723-8.544-4.723h-12.576c-1.458 0-5 .9-8.544 4.724-3.544 3.823-17.765 18.947-17.765 18.947l-.1.09a3.119 3.119 0 104.328 4.49l.122-.114c1.4-1.394 16.179-16.14 16.466-16.4.287-.26 1.554-1.543 1.521-.206-.022 1.1-.172 12.051-.174 12.168l-.4 42.718v.217a3.912 3.912 0 003.894 3.718 3.796 3.796 0 003.9-3.592v-.126s1.632-28.03 1.7-29.733c.068-1.703.969-1.826 1.321-1.826s1.249.123 1.319 1.826c.07 1.704 1.7 29.733 1.7 29.733v.126a3.8 3.8 0 003.9 3.592 3.909 3.909 0 003.894-3.718v-.217l-.4-42.718c0-.113-.152-11.06-.174-12.167-.026-1.337 1.271-.024 1.521.206.25.23 15.071 15 16.468 16.4l.122.112a3.12 3.12 0 005.038-3.49 3.106 3.106 0 00-.71-.999h.034zM147.465 51.947a8.484 8.484 0 008.485-8.485 8.485 8.485 0 10-8.485 8.485z"
      fill="#fff"
      stroke="#111947"
      strokeWidth={2}
    />
    <path
      d="M181.663 73.64l-4.059-2.55a.165.165 0 01-.037-.03l-1.612-1.585a.2.2 0 01-.054-.093 12.645 12.645 0 00-3.322-5.326 12.462 12.462 0 00-5.473-3.36.2.2 0 01-.139-.116 12.432 12.432 0 00-5.184-5.391 13.134 13.134 0 00-6.33-1.9 10.664 10.664 0 00-.745-.026h-1.67a.214.214 0 00-.2.133c-.565 1.2-2.755 4.1-5.373 4.1-2.618 0-4.809-2.9-5.373-4.1a.22.22 0 00-.2-.133h-1.676a6.98 6.98 0 00-.745.026 13.166 13.166 0 00-6.332 1.9 12.53 12.53 0 00-5.176 5.39.201.201 0 01-.139.117 12.425 12.425 0 00-5.473 3.36 12.6 12.6 0 00-3.322 5.326.209.209 0 01-.054.093l-1.612 1.586-.037.03-4.052 2.549a.215.215 0 00-.022.352c1.158.93 2.412.126 3.318 1.047.965.98-.046 2.3 1.086 3.448 1.132 1.148 2.466.159 3.431 1.136.906.92.083 2.173 1 3.338a.215.215 0 00.18.082.219.219 0 00.172-.1l2.607-4.02a.135.135 0 01.028-.034l2.408-2.371a.215.215 0 01.067-.046 16.228 16.228 0 004.956-2.71 15.954 15.954 0 002.936-4.022.218.218 0 01.245-.114c.048.012.09.04.12.078.03.039.046.087.046.136l.435 13.852a.22.22 0 00.063.153c.041.041.096.064.154.064h21.919a.217.217 0 00.217-.217l.435-13.852a.215.215 0 01.165-.214.218.218 0 01.246.114 15.894 15.894 0 002.942 4.022 16.205 16.205 0 004.956 2.71.228.228 0 01.067.046l2.408 2.37c.01.012.02.023.028.036l2.607 4.02a.21.21 0 00.074.07.211.211 0 00.197.01.218.218 0 00.081-.063c.91-1.176.089-2.416 1-3.338.965-.978 2.3.01 3.431-1.136 1.131-1.147.119-2.468 1.086-3.448.908-.921 2.16-.117 3.32-1.047a.215.215 0 00.082-.18.222.222 0 00-.097-.172h-.005z"
      fill="#85C3C3"
      stroke="#111947"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M267.508 78.225l-.093-.091s-14.221-15.125-17.764-18.947c-3.543-3.822-7.086-4.724-8.546-4.724H228.53c-1.46 0-5 .9-8.546 4.724-3.546 3.824-17.766 18.947-17.766 18.947l-.093.09a3.112 3.112 0 00-.995 2.2 3.118 3.118 0 005.321 2.292l.119-.114c1.4-1.395 16.181-16.14 16.468-16.4.287-.26 1.554-1.543 1.521-.206-.02 1.1-.172 12.05-.172 12.168v-4.346l-.4 42.718v4.563a3.915 3.915 0 003.892 3.718 3.802 3.802 0 003.9-3.592v-.126s1.632-28.03 1.7-29.733c.068-1.703.969-1.827 1.321-1.827s1.249.124 1.319 1.827c.07 1.703 1.7 29.733 1.7 29.733v.126a3.8 3.8 0 003.9 3.592 3.912 3.912 0 003.892-3.718v-4.563l-.4-42.718v4.346c0-.113-.154-11.06-.174-12.168-.026-1.336 1.271-.024 1.521.206.25.23 15.069 15 16.468 16.4l.12.113a3.122 3.122 0 003.41.614c.376-.163.715-.4.999-.694a3.15 3.15 0 00-.047-4.41z"
      fill="#fff"
      stroke="#111947"
      strokeWidth={2}
    />
    <path
      d="M234.817 51.97a8.485 8.485 0 100-16.97 8.485 8.485 0 000 16.97z"
      stroke="#111947"
      strokeWidth={2}
    />
    <path
      d="M252.971 123.271c-.037-.35.485-.923-.12-2.053-.422-.789-1.178-.826-1.3-1.173a.843.843 0 01.028-.628 2.262 2.262 0 00-.361-2.173 1.76 1.76 0 01-.652-1.443c-.435-5.867-1.2-15.812-1.971-22.57a168.448 168.448 0 00-3.175-18.215.278.278 0 00-.274-.217h-20.644a.284.284 0 00-.276.217 168.733 168.733 0 00-3.179 18.217c-.771 6.758-1.543 16.707-1.971 22.57a1.769 1.769 0 01-.652 1.443 2.263 2.263 0 00-.361 2.173c.09.198.101.423.028.628-.128.348-.884.385-1.3 1.173-.6 1.13-.083 1.7-.12 2.053-.037.353-.354.445-.539.652a.283.283 0 00.2.472l15.758.467a.28.28 0 00.237-.113c.288-.44.429-.96.4-1.484.03-.869-.435-1.076-.2-1.662a8.314 8.314 0 00.356-2.89l1.66-22.354a.287.287 0 01.088-.189.285.285 0 01.387 0c.053.05.084.117.088.189l1.66 22.354a8.437 8.437 0 00.359 2.89c.235.587-.23.789-.2 1.662a2.47 2.47 0 00.4 1.484.288.288 0 00.237.113l15.758-.467a.282.282 0 00.2-.472c-.195-.208-.51-.271-.549-.654z"
      fill="#85C3C3"
      stroke="#111947"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M217.871 120.275a6.932 6.932 0 011.6-.732l-1.6.732z"
      fill="#0FF"
    />
    <path
      d="M217.871 120.275a6.932 6.932 0 011.6-.732"
      stroke="#111947"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M251.764 120.275a6.932 6.932 0 00-1.6-.732l1.6.732z"
      fill="#0FF"
    />
    <path
      d="M251.764 120.275a6.932 6.932 0 00-1.6-.732"
      stroke="#111947"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
  )
}

export default IconGroup2
