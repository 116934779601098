import React from 'react'

interface Dots9x4CustomizationProps {
  height?: string
  width?: string
}

const Dots9x4Customization: React.FC<Dots9x4CustomizationProps> = ({ height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '69.5'}
      height={height || '27'}
      viewBox="0 0 69.5 27"
    >
      <defs>
        <style>{'.cls-9949-1,.cls-9949-2{fill:#12c4c4}.cls-9949-2{opacity:0}'}</style>
      </defs>
      <g id="Group_10658" transform="rotate(90 1403.75 149.75)">
        <g id="Group_433" transform="translate(1254 1484)">
          <g id="Group_471" transform="translate(25.5)">
            <path
              id="Rectangle_355"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 68)"
            />
            <path
              id="Rectangle_354"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 59.5)"
            />
            <path
              id="Rectangle_353"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 51)"
            />
            <path
              id="Rectangle_347"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 42.5)"
            />
            <path
              id="Rectangle_346"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 34)"
            />
            <path
              id="Rectangle_345"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 25.5)"
            />
            <path
              id="Rectangle_344"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 17)"
            />
            <path
              id="Rectangle_343"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 8.5)"
            />
            <path id="Rectangle_342" d="M0 0H1.5V1.5H0z" className="cls-9949-2" />
          </g>
          <g id="Group_470" transform="translate(17)">
            <path
              id="Rectangle_355-2"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 68)"
            />
            <path
              id="Rectangle_354-2"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 59.5)"
            />
            <path
              id="Rectangle_353-2"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 51)"
            />
            <path
              id="Rectangle_347-2"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 42.5)"
            />
            <path
              id="Rectangle_346-2"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 34)"
            />
            <path
              id="Rectangle_345-2"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 25.5)"
            />
            <path
              id="Rectangle_344-2"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 17)"
            />
            <path
              id="Rectangle_343-2"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 8.5)"
            />
            <path id="Rectangle_342-2" d="M0 0H1.5V1.5H0z" className="cls-9949-2" />
          </g>
          <g id="Group_432" transform="translate(8.5)">
            <path
              id="Rectangle_355-3"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 68)"
            />
            <path
              id="Rectangle_354-3"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 59.5)"
            />
            <path
              id="Rectangle_353-3"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 51)"
            />
            <path
              id="Rectangle_347-3"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 42.5)"
            />
            <path
              id="Rectangle_346-3"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 34)"
            />
            <path
              id="Rectangle_345-3"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 25.5)"
            />
            <path
              id="Rectangle_344-3"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 17)"
            />
            <path
              id="Rectangle_343-3"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 8.5)"
            />
            <path id="Rectangle_342-3" d="M0 0H1.5V1.5H0z" className="cls-9949-1" />
          </g>
          <g id="Group_426">
            <path
              id="Rectangle_355-4"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-2"
              transform="translate(0 68)"
            />
            <path
              id="Rectangle_354-4"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 59.5)"
            />
            <path
              id="Rectangle_353-4"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 51)"
            />
            <path
              id="Rectangle_347-4"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 42.5)"
            />
            <path
              id="Rectangle_346-4"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 34)"
            />
            <path
              id="Rectangle_345-4"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 25.5)"
            />
            <path
              id="Rectangle_344-4"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 17)"
            />
            <path
              id="Rectangle_343-4"
              d="M0 0H1.5V1.5H0z"
              className="cls-9949-1"
              transform="translate(0 8.5)"
            />
            <path id="Rectangle_342-4" d="M0 0H1.5V1.5H0z" className="cls-9949-1" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Dots9x4Customization
