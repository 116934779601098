import * as React from 'react'

interface InspirationTextProps {
  height?: string
  width?: string
}
const InspirationText: React.FC<InspirationTextProps> = ({ height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '164'}
      height={height || '56.005'}
      viewBox="0 0 164 56.005"
    >
      <defs>
        <style>{'.cls-1{isolation:isolate}.cls-2{fill:#12c4c4}'}</style>
      </defs>
      <g
        id="When_inspiration_does_not_come_to_me_I_go_halfway_to_meet_it."
        className="cls-1"
        transform="translate(0 .001)"
      >
        <g id="Group_10483" className="cls-1">
          <path
            id="Path_562"
            d="M10.54.08L8.218 7.027H7.176L5.28 1.479l-1.9 5.548H2.323L0 .08h1.022L2.9 5.727 4.853.08h.913l1.916 5.677L9.6.08z"
            className="cls-2"
          />
          <path
            id="Path_563"
            d="M18.914.08v6.947h-.993V3.93h-3.99v3.1h-.992V.08h.992v2.986h3.99V.08z"
            className="cls-2"
          />
          <path
            id="Path_564"
            d="M27.226 6.164v.863h-5.042V.08h4.9v.863h-3.91v2.134h3.484v.844h-3.481v2.243z"
            className="cls-2"
          />
          <path
            id="Path_565"
            d="M35.994.08v6.947h-.813l-4.169-5.181v5.181h-.993V.08h.815L35 5.26V.08z"
            className="cls-2"
          />
          <path id="Path_566" d="M43.118.08h.992v6.947h-.992z" className="cls-2" />
          <path
            id="Path_567"
            d="M53.353.08v6.947h-.814L48.37 1.846v5.181h-.992V.08h.814l4.169 5.18V.08z"
            className="cls-2"
          />
          <path
            id="Path_568"
            d="M55.988 6.233l.367-.774a3.578 3.578 0 0 0 2.293.8c1.2 0 1.717-.467 1.717-1.072 0-1.7-4.209-.625-4.209-3.206C56.156.914 56.99 0 58.816 0a4.077 4.077 0 0 1 2.233.616l-.327.793a3.589 3.589 0 0 0-1.906-.565c-1.181 0-1.687.5-1.687 1.1 0 1.7 4.208.636 4.208 3.186 0 1.062-.853 1.976-2.689 1.976a4.123 4.123 0 0 1-2.66-.873z"
            className="cls-2"
          />
          <path
            id="Path_569"
            d="M69.579 2.5c0 1.509-1.092 2.422-2.9 2.422h-1.715v2.1h-.993V.08h2.71c1.806 0 2.898.913 2.898 2.42zm-.993 0c0-.992-.664-1.558-1.935-1.558h-1.687v3.117h1.687c1.271 0 1.935-.565 1.935-1.559z"
            className="cls-2"
          />
          <path id="Path_570" d="M72.224.08h.992v6.947h-.992z" className="cls-2" />
          <path
            id="Path_571"
            d="M81.129 7.027l-1.5-2.134c-.139.01-.288.02-.437.02h-1.716v2.114h-.992V.08h2.709c1.807 0 2.9.913 2.9 2.421a2.192 2.192 0 0 1-1.53 2.2l1.648 2.332zM81.1 2.5c0-.992-.665-1.558-1.935-1.558h-1.689v3.127h1.688c1.27 0 1.936-.575 1.936-1.569z"
            className="cls-2"
          />
          <path
            id="Path_572"
            d="M89.311 5.29H85.62l-.765 1.737h-1.022L86.979.08h.983l3.156 6.947h-1.042zm-.347-.79l-1.5-3.4-1.5 3.4z"
            className="cls-2"
          />
          <path
            id="Path_573"
            d="M94.368.943h-2.382V.08h5.746v.863h-2.381v6.084h-.983z"
            className="cls-2"
          />
          <path id="Path_574" d="M100 .08h.992v6.947H100z" className="cls-2" />
          <path
            id="Path_575"
            d="M103.694 3.553a3.695 3.695 0 0 1 7.384 0 3.695 3.695 0 0 1-7.384 0zm6.391 0a2.7 2.7 0 1 0-2.7 2.7 2.7 2.7 0 0 0 2.7-2.7z"
            className="cls-2"
          />
          <path
            id="Path_576"
            d="M119.757.08v6.947h-.814l-4.168-5.181v5.181h-.993V.08h.814l4.169 5.18V.08z"
            className="cls-2"
          />
        </g>
        <g id="Group_10484" className="cls-1">
          <path
            id="Path_577"
            d="M.724 24.906h2.929a3.484 3.484 0 1 1 0 6.947H.724zm2.869 6.084a2.618 2.618 0 1 0 0-5.221H1.717v5.221z"
            className="cls-2"
          />
          <path
            id="Path_578"
            d="M9.543 28.379a3.531 3.531 0 0 1 3.7-3.553 3.556 3.556 0 1 1 0 7.107 3.532 3.532 0 0 1-3.7-3.554zm6.391 0a2.608 2.608 0 0 0-2.689-2.669 2.67 2.67 0 1 0 0 5.34 2.608 2.608 0 0 0 2.689-2.671z"
            className="cls-2"
          />
          <path
            id="Path_579"
            d="M24.674 30.99v.863h-5.042v-6.947h4.9v.863h-3.91V27.9h3.484v.844h-3.481v2.246z"
            className="cls-2"
          />
          <path
            id="Path_580"
            d="M26.832 31.059l.367-.774a3.574 3.574 0 0 0 2.293.8c1.2 0 1.717-.466 1.717-1.072 0-1.7-4.209-.625-4.209-3.206 0-1.071.834-1.985 2.661-1.985a4.076 4.076 0 0 1 2.232.616l-.327.793a3.585 3.585 0 0 0-1.9-.565c-1.182 0-1.688.5-1.688 1.1 0 1.7 4.209.635 4.209 3.185 0 1.062-.854 1.976-2.69 1.976a4.123 4.123 0 0 1-2.665-.868z"
            className="cls-2"
          />
          <path
            id="Path_581"
            d="M44.643 24.906v6.947h-.814l-4.168-5.18v5.18h-.993v-6.947h.814l4.169 5.18v-5.18z"
            className="cls-2"
          />
          <path
            id="Path_582"
            d="M47.348 28.379a3.695 3.695 0 1 1 3.7 3.554 3.531 3.531 0 0 1-3.7-3.554zm6.392 0a2.7 2.7 0 1 0-2.69 2.67 2.608 2.608 0 0 0 2.69-2.67z"
            className="cls-2"
          />
          <path
            id="Path_583"
            d="M58.717 25.769h-2.381v-.863h5.746v.863H59.7v6.084h-.983z"
            className="cls-2"
          />
          <path
            id="Path_584"
            d="M67.636 28.379a3.515 3.515 0 0 1 3.682-3.553 3.432 3.432 0 0 1 2.64 1.083l-.644.624a2.58 2.58 0 0 0-1.956-.823 2.67 2.67 0 1 0 0 5.34 2.583 2.583 0 0 0 1.956-.834l.644.626a3.445 3.445 0 0 1-2.649 1.092 3.513 3.513 0 0 1-3.673-3.554z"
            className="cls-2"
          />
          <path
            id="Path_585"
            d="M75.849 28.379a3.694 3.694 0 1 1 3.7 3.554 3.531 3.531 0 0 1-3.7-3.554zm6.391 0a2.7 2.7 0 1 0-2.69 2.67 2.608 2.608 0 0 0 2.69-2.67z"
            className="cls-2"
          />
          <path
            id="Path_586"
            d="M92.379 31.853l-.01-5.062-2.511 4.219H89.4l-2.51-4.189v5.032h-.952v-6.947h.814l2.9 4.883 2.858-4.883h.814l.01 6.947z"
            className="cls-2"
          />
          <path
            id="Path_587"
            d="M101.645 30.99v.863H96.6v-6.947h4.9v.863h-3.9V27.9h3.484v.844H97.6v2.246z"
            className="cls-2"
          />
          <path
            id="Path_588"
            d="M109.67 25.769h-2.381v-.863h5.746v.863h-2.382v6.084h-.983z"
            className="cls-2"
          />
          <path
            id="Path_589"
            d="M114.637 28.379a3.695 3.695 0 1 1 3.7 3.554 3.531 3.531 0 0 1-3.7-3.554zm6.391 0a2.7 2.7 0 1 0-2.689 2.67 2.608 2.608 0 0 0 2.689-2.67z"
            className="cls-2"
          />
          <path
            id="Path_590"
            d="M135.02 31.853l-.01-5.062-2.51 4.219h-.456l-2.512-4.189v5.032h-.952v-6.947h.814l2.9 4.883 2.858-4.883h.814l.01 6.947z"
            className="cls-2"
          />
          <path
            id="Path_591"
            d="M144.286 30.99v.863h-5.042v-6.947h4.9v.863h-3.91V27.9h3.484v.844h-3.484v2.246z"
            className="cls-2"
          />
          <path
            id="Path_592"
            d="M147.923 31.228a2 2 0 0 1-.169.714l-.466 1.38h-.626l.368-1.478a.632.632 0 0 1-.417-.616.649.649 0 0 1 .665-.675.639.639 0 0 1 .645.675z"
            className="cls-2"
          />
        </g>
        <g id="Group_10485" className="cls-1">
          <path id="Path_593" d="M.724 48.973h.993v6.948H.724z" className="cls-2" />
          <path
            id="Path_594"
            d="M13.652 52.407h.948v2.71a4.11 4.11 0 0 1-2.63.883 3.556 3.556 0 1 1 .02-7.106 3.483 3.483 0 0 1 2.669 1.071l-.615.616a2.752 2.752 0 0 0-2.015-.8 2.672 2.672 0 1 0-.009 5.34 2.941 2.941 0 0 0 1.628-.447z"
            className="cls-2"
          />
          <path
            id="Path_595"
            d="M17.12 52.447a3.531 3.531 0 0 1 3.7-3.553 3.555 3.555 0 1 1 0 7.106 3.531 3.531 0 0 1-3.7-3.553zm6.391 0a2.7 2.7 0 1 0-2.689 2.67 2.608 2.608 0 0 0 2.689-2.67z"
            className="cls-2"
          />
          <path
            id="Path_596"
            d="M37.037 48.973v6.948h-.992v-3.1h-3.991v3.1h-.992v-6.948h.992v2.987h3.991v-2.987z"
            className="cls-2"
          />
          <path
            id="Path_597"
            d="M44.734 54.184h-3.692l-.764 1.737h-1.023l3.145-6.948h.983l3.156 6.948H45.5zm-.347-.795l-1.5-3.4-1.5 3.4z"
            className="cls-2"
          />
          <path id="Path_598" d="M48.758 48.973h.992v6.084h3.762v.864h-4.754z" className="cls-2" />
          <path
            id="Path_599"
            d="M56.831 49.836v2.423h3.484v.853h-3.484v2.809h-.993v-6.948h4.9v.863z"
            className="cls-2"
          />
          <path
            id="Path_600"
            d="M73.142 48.973l-2.323 6.948h-1.042l-1.9-5.549-1.895 5.549h-1.058L62.6 48.973h1.022L65.5 54.62l1.955-5.647h.913l1.915 5.677 1.917-5.677z"
            className="cls-2"
          />
          <path
            id="Path_601"
            d="M79.748 54.184h-3.692l-.764 1.737H74.27l3.146-6.948h.984l3.156 6.948h-1.043zm-.347-.795l-1.5-3.4-1.5 3.4z"
            className="cls-2"
          />
          <path
            id="Path_602"
            d="M86.016 53.518v2.4h-.983V53.5l-2.759-4.526h1.062l2.223 3.662 2.233-3.662h.983z"
            className="cls-2"
          />
          <path
            id="Path_603"
            d="M96.2 49.836h-2.387v-.863h5.746v.863h-2.381v6.085H96.2z"
            className="cls-2"
          />
          <path
            id="Path_604"
            d="M101.162 52.447a3.694 3.694 0 1 1 3.7 3.553 3.53 3.53 0 0 1-3.7-3.553zm6.391 0a2.7 2.7 0 1 0-2.69 2.67 2.609 2.609 0 0 0 2.69-2.67z"
            className="cls-2"
          />
          <path
            id="Path_605"
            d="M121.544 55.921l-.009-5.063-2.512 4.219h-.456l-2.511-4.189v5.033h-.956v-6.948h.814l2.9 4.883 2.859-4.883h.813l.01 6.948z"
            className="cls-2"
          />
          <path
            id="Path_606"
            d="M130.81 55.057v.864h-5.041v-6.948h4.9v.863h-3.911v2.135h3.484v.843h-3.484v2.243z"
            className="cls-2"
          />
          <path
            id="Path_607"
            d="M138.645 55.057v.864H133.6v-6.948h4.9v.863h-3.9v2.135h3.484v.843H134.6v2.243z"
            className="cls-2"
          />
          <path
            id="Path_608"
            d="M142.818 49.836h-2.382v-.863h5.747v.863H143.8v6.085h-.983z"
            className="cls-2"
          />
          <path id="Path_609" d="M152.3 48.973h.992v6.948h-.992z" className="cls-2" />
          <path
            id="Path_610"
            d="M157.942 49.836h-2.381v-.863h5.746v.863h-2.382v6.085h-.983z"
            className="cls-2"
          />
          <path
            id="Path_611"
            d="M162.67 55.3a.663.663 0 0 1 .675-.675.653.653 0 0 1 .655.675.661.661 0 0 1-.655.685.671.671 0 0 1-.675-.685z"
            className="cls-2"
          />
        </g>
      </g>
    </svg>
  )
}

export default InspirationText
