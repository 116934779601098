import React, { useEffect } from 'react'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'

interface BreadcrumbProps {
  crumbs: any[]
  selected?: (crumb: any) => void
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ crumbs }) => {
  const history = useHistory()
  const checkIsLast = (index: number) => {
    return index === crumbs.length - 1
  }

  const goToLink = (isLast: any, link: any) => () => {
    if (!isLast) history.push(link)
  }

  const renderListCrumbs = crumbs.map((crumb, index) => {
    const isLast = checkIsLast(index)
    return (
      // vnext change
      <span key={index}>
        <div
          // aminext add
          key={crumb}
          className={classnames('breadcrumb__item', {
            'black-color2': !isLast,
            opacity: isLast,
            opacityNotLast: !isLast,
            'text-content-color': isLast,
          })}
          onClick={goToLink(isLast, crumb.link)}
        >
          {crumb.name}
          {isLast ? null : <span className="breadcrumb__split">/</span>}
        </div>
      </span>
    )
  })

  useEffect(() => {
  }, [])

  return (
    // aminext change
    // update distance breadcrumb to h2 : 1-> 50px , 2 -> 30px, 0-> 35px
    <div className={'breadcrumb'} style={{ color: '#000' }}>
      {renderListCrumbs}
    </div>
  )
}
