import classnames from 'classnames'
import Header from './header'
import Body from './body'
import Footer from './footer'
import { TABLET_MIN_SIZE } from '../../../../../utils/const'
import useWindowSize from '../../../../../customize/hooks/useWindowSize'
interface MenuProps {
  toggleMenu: () => void
  onLogout: any
  open: boolean
}

export const Menu: React.FC<MenuProps> = ({ open, toggleMenu, onLogout }) => {
  const size = useWindowSize()
  size.width < TABLET_MIN_SIZE && open
    ? (document.body.style.overflow = 'hidden')
    : (document.body.style.overflow = 'unset')
  

  return open ? (
    <div
      className={classnames('menu', { 'fade-in': open, 'fade-out': !open })}
    >
      <div
        onClick={() => toggleMenu()}
        className="opacity"
      ></div>
      <div className="triangle"></div>
      <div className="content">
        <Header toggleMenu={() => toggleMenu()} />
        <Body toggleMenu={() => toggleMenu()} onLogout={onLogout} />
        {size.width < TABLET_MIN_SIZE && <Footer />}
      </div>
    </div>
  ) : null
}
