import { useTranslation } from 'react-i18next'
import QuestionIcon from "../../../../assets/images/svgs/question-icon";
import Wrapper from '../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../utils/const'
import {useHistory} from "react-router-dom";

interface HelpExportProps {}


const HelpExport: React.FC<HelpExportProps> = () => {

    const title = 'エクスポートについて | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
    const description = 'エクスポートについてのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
    const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
    const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

    // title
    document.title = title;

    // title以外のmeta
    const headData = document.head.children;

    for (let i = 0; i < headData.length; i++) {
        const nameVal = headData[i].getAttribute('name');
        const propertyVal = headData[i].getAttribute('property');
        if (nameVal !== null) {
            if (nameVal.indexOf('keywords') !== -1) {
                headData[i].setAttribute('content', keywords);
            }
            if (nameVal.indexOf('description') !== -1) {
                headData[i].setAttribute('content', description);
            }
        }
        if (propertyVal !== null) {
            // OGP(og:title)の設定
            if (propertyVal.indexOf('og:title') !== -1) {
                headData[i].setAttribute('content', title);
            }
            if (propertyVal.indexOf('og:description') !== -1) {
                headData[i].setAttribute('content', description);
            }
            if (propertyVal.indexOf('og:image') !== -1) {
                headData[i].setAttribute('content', og_image);
            }
        }
    }
    
    const [t] = useTranslation('translation')

    const history = useHistory()

    const onGoTo = (path: any) => () => {
        history.push(path)
    }
    const list = [
        {
            name: t('help.home'),
            link: ROUTER_URL.HOME,
        },
        {
            name: t('help.export.parent'),
            link: ROUTER_URL.HELP_INDEX,
        },
        {
            name: t('help.export.breadcrum'),
        },
    ]

    return (
        <Wrapper
            breadcrum={list}
            title={t('help.export.title')}
            className="base_style help-wrap"
            Icon={QuestionIcon}
            classNameBtn={'normal'}
        >

            <div className="wrapper__content">
                <div className="help-wrap_box distance-content-to-button">
                    <ul className="toc">
                        <li><a href="#export_about">エクスポートとは</a></li>
                        <li><a href="#export_cooperation_service">連携サービスについて</a></li>
                        <li><a href="#export_howto">エクスポート方法について</a></li>
                        <li><a href="#export_avatar">エクスポートが可能なアバターについて</a></li>
                        <li><a href="#export_optimisation">アバターの最適化について</a></li>
                    </ul>

                    <div id="export_about" className="section">
                        <h2>エクスポートとは</h2>
                        <p>
                            AVATARIUMで作成したアバターをAVATARIUM連携サービス内で利用するために、アバターのエクスポートを行います。<br />
                            エクスポートを行うと、連携サービス内でアバターを呼び出し、共通のアバターとして利用することが可能となります。
                        </p>
                    </div>

                    <div id="export_cooperation_service" className="section">
                        <h2>連携サービスについて</h2>
                        <p>
                            アバターのエクスポートはAVATARIUM連携サービスで可能です。<br />
                            連携サービスのご確認は下記ページから可能です。<br />
                            <a className="text-link" onClick={onGoTo(ROUTER_URL.CONTROL_EXPORT)}>【エクスポート管理】</a><br /><br />

                            連携サービスは随時更新します。
                        </p>
                    </div>

                    <div id="export_howto" className="section">
                        <h2>エクスポート方法について</h2>
                        <p>
                            エクスポート方法詳細は下記ページからご確認いただけます。<br />
                            <a className="text-link" onClick={onGoTo(ROUTER_URL.CONTROL_EXPORT)}>【エクスポート管理】</a><br /><br />

                            ※アバターのエクスポートはWEBからのみ可能です。<br />
                            アプリからエクスポートはできませんのでご注意ください。
                        </p>
                    </div>

                    <div id="export_avatar" className="section">
                        <h2>エクスポートが可能なアバターについて</h2>
                        <p>
                            連携サービスによってエクスポート可能なアバターは異なります。<br />
                            エクスポート可能アバターの確認は、下記ページからご確認いただけます。<br />
                            <a className="text-link" onClick={onGoTo(ROUTER_URL.CONTROL_EXPORT)}>【エクスポート管理】</a><br /><br />

                            または、<a className="text-link" onClick={onGoTo(ROUTER_URL.CONTROL_AVATAR)}>アバター管理</a>からエクスポートしたいアバターを選択してください。<br />
                            アバター詳細ページの「エクスポート」ボタンをクリックすると、エクスポートの可否がご確認いただけます。
                        </p>
                    </div>

                    <div id="export_optimisation" className="section">
                        <h2>アバターの最適化について</h2>
                        <p>
                            エクスポートしたアバターを連携サービス内で問題なく使用（表示速度の向上や通信量の削減）するために、最適化したアバターを生成します。<br />
                            そのため、AVATARIUMサービスで表示されるアバターと連携先でアバターの見え方に差が出る場合もございます。
                        </p>
                    </div>

                </div>

                <div className="wrapper__button">
                    <a className="button-custom" onClick={onGoTo(ROUTER_URL.HELP_INDEX)}>ヘルプページTOP</a>
                </div>

            </div>
        </Wrapper>
    );
}
export default HelpExport
