import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

export const dataTest = [
  {
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  }
]

export const validator = (t: any) => {
  return yupResolver(
    Yup.object().shape({
      name: Yup.string()
      .max(50,t('detail-avatar.name_max'))
      .required(t('detail-avatar.validate_name'))
      .trim(),
    })
  )
}
export const defaultValues = {
  name: localStorage.getItem('i18nextLng') === 'ja' ? '20210308（AVATAR名）' : '20210308（AVATAR NAME）',
}
