import * as React from 'react'
import { GOOGLE_PLAY_URL } from '../../../utils/const'
interface GooglePlayProps {
  height?: string;
  width?: string;
};

const GooglePlay: React.FC<GooglePlayProps> = ({ height, width }) => {
  return (
    <a href={GOOGLE_PLAY_URL} target="_blank" rel="noopener noreferrer">
    <svg
      width={width || 128}
      height={height || 40}
      viewBox="0 0 128 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M124 39.5H4A3.512 3.512 0 01.5 36V4C.5 2.076 2.076.5 4 .5h120c1.924 0 3.5 1.576 3.5 3.5v32c0 1.924-1.576 3.5-3.5 3.5z"
        fill="#000"
        stroke="#A6A6A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.8 19.6V9.1c0-.6.5-1.1 1-1.1s.7.1 1 .3l19.3 10.6c.4.2.6.5.6.8 0 .3-.2.6-.6.8L11.8 31.1c-.2.1-.5.3-1 .3s-1-.5-1-1.1V19.6z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.302 19.7L10.602 8h.2c.5 0 .7.1 1 .3l14.2 7.8-3.7 3.6z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9 23.2l-3.6-3.6 3.7-3.7 5.1 2.8c.4.2.6.5.6.8 0 .3-.2.6-.6.8l-5.2 2.9z"
        fill="url(#prefix__paint2_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7 31.2l11.6-11.6 3.6 3.6-14.1 7.7c-.3.2-.5.3-1.1.3.1 0 .1 0 0 0z"
        fill="url(#prefix__paint3_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.098 33.9c-.5-.4-.9-1.1-1.1-1.5l1.6-.7c.1.2.3.5.5.8.4.4 1 .8 1.6.8.6 0 1.3-.3 1.7-.8.3-.5.5-1 .5-1.7v-.6c-1.2 1.5-3.7 1.3-5.1-.3-1.5-1.6-1.5-4.3 0-5.9 1.5-1.5 3.7-1.7 5-.3V23h1.7v7.5c0 1.9-.7 3-1.6 3.7-.6.5-1.5.7-2.3.7-.9-.1-1.8-.4-2.5-1zm41.9.4l1.7-4-3-6.9h1.7l2.1 4.9 2.1-4.9h1.7l-4.6 10.9h-1.7zm-8.1-3.8c-.5-.5-.7-1.2-.7-1.9 0-.6.2-1.2.6-1.6.7-.7 1.7-1 2.8-1 .7 0 1.3.1 1.8.4 0-1.2-1-1.7-1.8-1.7-.7 0-1.4.4-1.7 1.1l-1.5-.6c.3-.7 1.1-2 3.1-2 1 0 2 .3 2.6 1 .6.7.8 1.5.8 2.6V31h-1.7v-.7c-.2.3-.6.5-.9.7-.4.2-.9.3-1.4.3-.6-.1-1.5-.3-2-.8zm-53.3-3.6c0-2 1.5-4.2 4.2-4.2 2.6 0 4.2 2.2 4.2 4.2s-1.5 4.2-4.2 4.2-4.2-2.2-4.2-4.2zm9 0c0-2 1.5-4.2 4.2-4.2 2.6 0 4.2 2.2 4.2 4.2s-1.5 4.2-4.2 4.2c-2.6 0-4.2-2.2-4.2-4.2zm-20.3 2.2c-2.5-2.5-2.4-6.6.1-9.2 1.3-1.3 2.9-1.9 4.6-1.9 1.6 0 3.2.6 4.4 1.8l-1.2 1.3c-1.8-1.8-4.7-1.7-6.4.1-1.8 1.9-1.8 4.8 0 6.7 1.8 1.9 4.8 2 6.6.1.6-.6.8-1.4.9-2.2h-4.2V24h5.9c.1.4.1.9.1 1.4 0 1.5-.6 3-1.6 4-1.1 1.1-2.7 1.7-4.3 1.7-1.8-.1-3.6-.7-4.9-2zm42.3.7c-1.5-1.6-1.5-4.3 0-6 1.5-1.6 4-1.6 5.4 0 .5.5.8 1.2 1.1 1.9l-5.5 2.3c.3.7 1 1.3 2 1.3.9 0 1.5-.3 2.1-1.2l1.5 1c-.2.2-.4.4-.5.6-1.7 1.7-4.6 1.7-6.1.1zm10.4 1.2V19.9h3.6c2.1 0 3.8 1.5 3.8 3.3 0 1.8-1.5 3.3-3.4 3.3h-2.2v4.4h-1.8v.1zm8.4 0V19.9h1.7V31h-1.7zm-22.6-.2V18.4h1.8v12.4h-1.8zm30.5-3.1c-.4-.3-1-.4-1.6-.4-1.2 0-1.9.6-1.9 1.3 0 .7.7 1.1 1.4 1.1 1 0 2.1-.8 2.1-2zm-51.2-.8c0-1.2-.8-2.5-2.3-2.5-1.5 0-2.3 1.3-2.3 2.5s.8 2.5 2.3 2.5c1.4 0 2.3-1.3 2.3-2.5zm9 0c0-1.2-.8-2.5-2.3-2.5-1.5 0-2.3 1.3-2.3 2.5s.8 2.5 2.3 2.5c1.5 0 2.3-1.3 2.3-2.5zm8.9.7c0-.1 0-.2.1-.3v-.4-.5c0-.1-.1-.3-.1-.4-.3-1-1.2-1.7-2.1-1.7-1.2 0-2.2 1.2-2.2 2.5 0 1.4 1 2.5 2.3 2.5.8.1 1.6-.6 2-1.7zm7.3-.9l3.7-1.6c-.4-.7-1-.9-1.5-.9-1.5.1-2.4 1.7-2.2 2.5zm15.3-3.4c0-1-.8-1.7-1.9-1.7h-2v3.5h2.1c1 0 1.8-.8 1.8-1.8z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.299 32.3h-.1v-.1h.1-.1v.1h.1zM114.501 32.3c0-.1 0-.1 0 0-.1-.1-.1-.1 0 0-.1 0-.1 0 0 0zm0-.1c0 .1 0 .1 0 0 0 .1-.1.1 0 0-.1 0 0 0 0 0z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.6 32.2h-.1.1v.1-.1z"
        fill="#fff"
      />
      <path
        d="M38.898 10.1c0-1.8 1.3-2.9 2.9-2.9 1.1 0 1.8.5 2.3 1.2l-.8.5c-.3-.4-.8-.7-1.5-.7-1.1 0-1.9.8-1.9 2s.8 2 1.9 2c.6 0 1.1-.3 1.3-.5v-.9h-1.6v-.9h2.7V12c-.5.6-1.3 1-2.3 1-1.7 0-3-1.2-3-2.9zM45.398 12.9V7.3h3.8v.9h-2.9v1.4h2.8v.9h-2.8v1.6h2.9v.9l-3.8-.1zM52 12.9V8.2h-1.7v-.9h4.4v.9H53v4.7h-1zM58.102 12.9V7.3h1v5.6h-1zM61.802 12.9V8.2h-1.7v-.9h4.4v.9h-1.7v4.7h-1zM67.7 10.1c0-1.7 1.2-2.9 2.9-2.9s2.9 1.2 2.9 2.9-1.2 2.9-2.9 2.9-2.9-1.3-2.9-2.9zm4.7 0c0-1.2-.7-2-1.9-2-1.1 0-1.9.9-1.9 2 0 1.2.7 2 1.9 2s1.9-.9 1.9-2zM78.502 12.9l-2.9-4v4h-1V7.3h1l2.9 3.9V7.3h1v5.6h-1z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={29.834}
          y1={9.157}
          x2={16.607}
          y2={33.206}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF" />
          <stop offset={0.01} stopColor="#00A1FF" />
          <stop offset={0.26} stopColor="#00BEFF" />
          <stop offset={0.51} stopColor="#00D2FF" />
          <stop offset={0.76} stopColor="#00DFFF" />
          <stop offset={1} stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={7.703}
          y1={1.679}
          x2={16.939}
          y2={11.058}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32A071" />
          <stop offset={0.07} stopColor="#2DA771" />
          <stop offset={0.48} stopColor="#15CF74" />
          <stop offset={0.8} stopColor="#06E775" />
          <stop offset={1} stopColor="#00F076" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={32.423}
          y1={19.55}
          x2={10.021}
          y2={19.55}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE000" />
          <stop offset={0.41} stopColor="#FFBD00" />
          <stop offset={0.78} stopColor="orange" />
          <stop offset={1} stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={23.812}
          y1={21.673}
          x2={3.299}
          y2={42.403}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A44" />
          <stop offset={1} stopColor="#C31162" />
        </linearGradient>
      </defs>
    </svg>
    </a>
  );
};
export default GooglePlay;
