import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconHair3: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.9982 41.2917C61.1278 30.6309 54.2729 21.5256 44.8686 19.7225C40.5683 16.2134 32.7098 17.0472 26.6957 19.204C16.8011 22.7506 12.1959 30.5915 11.0523 41.2917C10.0985 50.207 22.3756 59.4144 31.8752 64.856C32.6362 65.2926 33.3266 65.8253 34.0524 66.3094C34.6791 66.6444 35.3853 66.8128 36.0999 66.7975C36.8146 66.7823 37.5125 66.5839 38.1236 66.2225C38.7306 65.7268 39.3847 65.2885 40.0768 64.9136C49.5858 59.4963 60.8883 50.2869 60.9982 41.2917Z"
        fill="#111947"
      />
      <path
        d="M54.0155 40.1853C52.5745 28.9343 45.6895 22.668 36.0613 22.668C26.4331 22.668 19.5502 28.9343 18.1123 40.1853C13.9653 40.3642 12.8633 43.2285 12.8633 46.9691C12.8633 50.7096 17.1139 54.9404 18.5301 55.1345C19.1117 55.2173 19.629 55.2699 20.1453 55.3133C23.5002 62.7561 29.8585 67.9996 35.1075 67.9996H37.0659C42.3097 67.9996 48.667 62.7561 52.0229 55.3133C52.54 55.2878 53.0547 55.228 53.5635 55.1345C54.9704 54.9404 59.2148 50.8461 59.2148 46.9478C59.2148 43.0496 58.1511 40.3652 54.0155 40.1853Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M30.1747 50.0476C31.7533 50.0476 33.033 48.8 33.033 47.2611C33.033 45.7222 31.7533 44.4746 30.1747 44.4746C28.5961 44.4746 27.3164 45.7222 27.3164 47.2611C27.3164 48.8 28.5961 50.0476 30.1747 50.0476Z"
        fill="#111947"
      />
      <path
        d="M42.7098 50.0476C44.2884 50.0476 45.5681 48.8 45.5681 47.2611C45.5681 45.7222 44.2884 44.4746 42.7098 44.4746C41.1313 44.4746 39.8516 45.7222 39.8516 47.2611C39.8516 48.8 41.1313 50.0476 42.7098 50.0476Z"
        fill="#111947"
      />
      <path
        d="M53.165 41.2955C50.3223 39.8805 43.964 31.8748 42.3384 26.5444C39.8502 29.7331 25.4396 41.2176 18.7578 41.2176C19.3539 38.792 18.6832 22.1974 35.7873 19.8233C46.0002 18.4083 58.0045 32.5338 53.165 41.2955Z"
        fill="#111947"
      />
      <path
        d="M38.474 23.2032C43.9135 23.2032 48.323 18.9044 48.323 13.6016C48.323 8.29877 43.9135 4 38.474 4C33.0345 4 28.625 8.29877 28.625 13.6016C28.625 18.9044 33.0345 23.2032 38.474 23.2032Z"
        fill="#111947"
      />
    </svg>
  )
}

export default IconHair3
