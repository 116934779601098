import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import 'semantic-ui-css/semantic.min.css'
import reportWebVitals from './reportWebVitals'
import i18n from './i18n'
import store from './tsx/stores'
import './index.scss'
import './top_style_v2.css'
import './style_v2.css'
import { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ROUTER_URL } from './utils/const'
import PageImage from './tsx/views/components/modules/page-image'
import Page from './tsx/views/components/modules/page'

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store()}>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route
              path={ROUTER_URL.IMAGE}
              exact={true}
              render={(props) => <PageImage {...props} />}
            />
            <Route path={ROUTER_URL.HOME} render={(props) => <Page {...props} />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
