import React from 'react'

interface CalendarIconProps {
  height?: string
  width?: string
}

const CalendarIcon: React.FC<CalendarIconProps> = ({ height, width }) => {
  return (
    <svg width={width || 28} height={height || 28} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.2 6.4248H4.8C4.05762 6.42557 3.34586 6.72997 2.82092 7.27122C2.29598 7.81246 2.00074 8.54633 2 9.31176V27.1147C2.00074 27.8801 2.29598 28.614 2.82092 29.1552C3.34586 29.6965 4.05762 30.0009 4.8 30.0016H27.2C27.9424 30.0009 28.6541 29.6965 29.1791 29.1552C29.704 28.614 29.9993 27.8801 30 27.1147V9.31176C29.9993 8.54633 29.704 7.81246 29.1791 7.27122C28.6541 6.72997 27.9424 6.42557 27.2 6.4248ZM29.0667 27.1147C29.0667 27.6251 28.87 28.1146 28.5199 28.4756C28.1699 28.8365 27.6951 29.0393 27.2 29.0393H4.8C4.30493 29.0393 3.83014 28.8365 3.48007 28.4756C3.13 28.1146 2.93333 27.6251 2.93333 27.1147V12.6799H29.0667V27.1147Z"
        fill="#9496AB"
      />
      <path
        d="M24.0096 2.93771C24.0096 2.68902 23.9103 2.45051 23.7336 2.27465C23.5569 2.09879 23.3172 2 23.0673 2C22.8174 2 22.5777 2.09879 22.401 2.27465C22.2243 2.45051 22.125 2.68902 22.125 2.93771V5.282H24.0096V2.93771Z"
        fill="#9496AB"
      />
      <path
        d="M9.8729 2.93771C9.8729 2.68902 9.77362 2.45051 9.5969 2.27465C9.42018 2.09879 9.1805 2 8.93059 2C8.68067 2 8.44099 2.09879 8.26428 2.27465C8.08756 2.45051 7.98828 2.68902 7.98828 2.93771V5.282H9.8729V2.93771Z"
        fill="#9496AB"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M13.9885 21.1762L14.9665 22.1934C16.4809 21.4519 17.7431 20.4007 18.6338 19.1391L17.4112 18.3241C17.268 18.2285 17.1667 18.0961 17.1233 17.9475C17.0799 17.7989 17.0967 17.6425 17.1712 17.5027L18.2856 15.4115C18.3655 15.2616 18.5067 15.1401 18.6841 15.0688C18.8615 14.9975 19.0634 14.981 19.2541 15.0222L21.3756 15.4809C21.5735 15.524 21.7456 15.6261 21.8592 15.7678C21.9728 15.9096 22.0201 16.0811 21.9921 16.25C21.6199 18.4592 20.3946 20.5073 18.5017 22.0847C16.6087 23.6622 14.1509 24.6832 11.4999 24.9934C11.2973 25.0168 11.0914 24.9774 10.9213 24.8827C10.7512 24.788 10.6287 24.6446 10.5769 24.4796L10.0266 22.7119C9.97721 22.553 9.99703 22.3848 10.0825 22.2369C10.168 22.0891 10.3137 21.9714 10.4936 21.9046L13.0026 20.976C13.1704 20.9139 13.3581 20.8998 13.5365 20.936C13.7149 20.9723 13.8738 21.0567 13.9885 21.1762Z"
          fill="#9496AB"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="14" height="11" fill="white" transform="matrix(-1 0 0 1 23 14)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CalendarIcon
