import { useTranslation } from 'react-i18next'
import { BookIcon } from '../../../../../assets/images/svgs'
import Wrapper from '../../../components/modules/_wrapper'
import { useSelector } from 'react-redux'
import { IApplicationState } from '../../../../stores'
import { ROUTER_URL } from '../../../../../utils/const'
import TermsOfServiceConstants from './constants'

interface TermsOfServiceProps {}

const TermsOfService: React.FC<TermsOfServiceProps> = () => {
  const [t] = useTranslation('translation')

  const isSignedIn = useSelector((state: IApplicationState) => state.signin.isSignedIn)

    const title = '利用規約 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
    const description = '利用規約のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
    const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
    const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

    // title
    document.title = title;

    // title以外のmeta
    const headData = document.head.children;

    for (let i = 0; i < headData.length; i++) {
        const nameVal = headData[i].getAttribute('name');
        const propertyVal = headData[i].getAttribute('property');
        if (nameVal !== null) {
            if (nameVal.indexOf('keywords') !== -1) {
                headData[i].setAttribute('content', keywords);
            }
            if (nameVal.indexOf('description') !== -1) {
                headData[i].setAttribute('content', description);
            }
        }
        if (propertyVal !== null) {
            // OGP(og:title)の設定
            if (propertyVal.indexOf('og:title') !== -1) {
                headData[i].setAttribute('content', title);
            }
            if (propertyVal.indexOf('og:description') !== -1) {
                headData[i].setAttribute('content', description);
            }
            if (propertyVal.indexOf('og:image') !== -1) {
                headData[i].setAttribute('content', og_image);
            }
        }
    }

  const list = [
    {
      name: t('policy.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('policy.term_of_service.breadcrum'),
      link: ROUTER_URL.TERM_OF_SERVICE,
    },
  ]
  return (
    <Wrapper
      breadcrum={list}
      className="policy"
      title={t('policy.term_of_service.title')}
      buttonText={t(`policy.term_of_service.button${isSignedIn ? '2' : ''}`)}
      buttonLink={isSignedIn ? ROUTER_URL.SCAN_BOOKING : ROUTER_URL.HOME}
      classNameBtn={'normal'}
      Icon={BookIcon}
    >
      <TermsOfServiceConstants>
      </TermsOfServiceConstants>
    </Wrapper>
  )
}
export default TermsOfService
