import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AppStore, GooglePlay, LogoDark } from '../../../../../../assets/images'
import { useHistory } from 'react-router-dom'
import LogoImage from '../../../../../../assets/images/svgs/logo_beta_white.svg'
import { ROUTER_URL } from '../../../../../../utils/const'
// tslint:disable-next-line:no-empty-interface
interface footerLanguagesProps {
  logo?: string
  onReload?: any
}
const Footer: React.FC<footerLanguagesProps> = ({ logo, onReload }) => {
  const [, i18n] = useTranslation('translation')
  const history = useHistory()

  const onGoTo = (path: any) => () => {
    history.push(path)
  }

  return (
    <div className="footerC-wrap">
      <div className="footerC-wrap__content">
        <div className="footerC-wrap__content--logo">
          {logo === 'logo-dark' ? (
            <span onClick={onReload}>
              <LogoDark width="23.33rem" height="4rem" />
            </span>
          ) : (
            <span onClick={onReload}>
              <img src={LogoImage} alt="" />
            </span>
          )}
        </div>
        <div className="footerC-wrap__content--wrap">
          <div className="footerC-wrap__content--wrap1">
            <div className="footerC-wrap__content--detail">
              <span>{i18n.t('home.footer_detail')}</span>
            </div>
            <div className="footerC-wrap__content--company">
              <span>{i18n.t('home.footer_company')}</span>
            </div>
          </div>
          <div className="footerC-wrap__content--linkApp">
            <div className="footerC-wrap__content--link">
              <a onClick={onGoTo(ROUTER_URL.HOME)} className="text-link">
                {i18n.t('home.title_home')}
              </a>
              <a onClick={onGoTo(ROUTER_URL.SCAN_BOOKING)} className="text-link">
                {i18n.t('home.title_my_page')}
              </a>
              <a onClick={onGoTo(ROUTER_URL.PRIVACY_POLICY)} className="text-link">
                {i18n.t('home.title_policy')}
              </a>
              <a onClick={onGoTo(ROUTER_URL.TERM_OF_SERVICE)} className="text-link">
                {i18n.t('home.terms_use')}
              </a>
              <a onClick={onGoTo(ROUTER_URL.COMMERCE)} className="text-link">
                {i18n.t('home.law')}
              </a>
              <a onClick={onGoTo(ROUTER_URL.HELP_INDEX)} className="text-link">
                {i18n.t('home.help')}
              </a>
              <a onClick={onGoTo(ROUTER_URL.CONTACT)} className="text-link">
                {i18n.t('home.Contact_Us')}
              </a>
            </div>
            <div className="footerC-wrap__content--app">
              <div className="footerC-wrap__content--app_store">
                <AppStore />
              </div>
              <div className="footerC-wrap__content--app_play">
                <GooglePlay />
              </div>
            </div>
            <div className="footerC-wrap__content--app_attention">
              <span>
                <Trans
                  i18nKey="top.content_register_customer"
                  components={{
                    a: <a href={ROUTER_URL.SIGNUP} className="text-link-signup" />,
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="footerC-wrap__content2">
        <span>{i18n.t('home.copy_right_mark') + (new Date()).getFullYear() + i18n.t('home.copy_right_pocket_rd')}</span>
      </div>
    </div>
  )
}

export default Footer
