import * as React from 'react';

interface MenuIconProps {
   height?: string;
   width?: string;
};
const MenuIcon: React.FC<MenuIconProps> = ({ height, width }) => {
   return (
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
         <rect y="28" width="30" height="2" fill="#12C4C4"/>
         <rect y="14" width="30" height="2" fill="#12C4C4"/>
         <rect width="30" height="2" fill="#12C4C4"/>
      </svg>
      
   );
};

export default MenuIcon
