import { useTranslation } from 'react-i18next'
import { Button } from '../../../../components/atoms/buttons/index'
import { CloseIcon } from '../../../../../../assets/images'

interface ConfirmModalProps {
  toggleModal: () => void
  deleteAccount: () => void
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ toggleModal, deleteAccount }) => {
  const [t] = useTranslation('translation')
  return (
    <>
      <div className="detail-avatar__delete-modal">
        <div className="detail-avatar__delete-modal_header distance-h2-to-line">
          <div className="detail-avatar__delete-modal_header-text">
            <div className="title-h2">{t('withdraw.title_confirm')}</div>
          </div>
          <button
            className="detail-avatar__delete-modal_header-icon close-icon"
            onClick={toggleModal}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="detail-avatar__delete-modal_content distance-padding-content ">
          <p className="pop-large-body-text">{t('withdraw.modal_content1')}</p>
          <p className="pop-large-body-text">{t('withdraw.modal_content2')}</p>
        </div>
        <div className="detail-avatar__delete-modal_button">
          <Button className="cancel shortnormal " onClick={toggleModal}>
            {t('detail-avatar.delete_btn1')}
          </Button>
          <Button
            className="shortnormal"
            onClick={() => {
              deleteAccount()
              toggleModal()
            }}
          >
            {t('detail-avatar.delete_btn2')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default ConfirmModal
