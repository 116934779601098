export enum DownloadTypes {
    GET_LIST_ALL = 'GET_LIST_ALL',
    GET_LIST_FPX = 'GET_LIST_FPX',
    GET_LIST_PLY = 'GET_LIST_PLY'
}

export const checkTypeDownload = (payload:any[]) => ({
    type: DownloadTypes.GET_LIST_ALL,
    payload
});

export const checkTypeDownloadFPX = (payload:any[]) => ({
    type: DownloadTypes.GET_LIST_FPX,
    payload
});

export const checkTypeDownloadPLY = (payload:any[]) => ({
    type: DownloadTypes.GET_LIST_PLY,
    payload
});
