import * as React from 'react';

interface AddIconProps {
	height?: string;
	width?: string;
};
const AddIcon: React.FC<AddIconProps> = ({ height, width }) => {
	return (
	<svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12} cy={12} r={11.5} stroke="#12C4C4" />
      <path fill="#12C4C4" d="M11 6h2v12h-2z" />
      <path fill="#12C4C4" d="M18 11v2H6v-2z" />
    </svg>
	);
};

export default AddIcon;