import Header from '../../modules/templates/header'
import Footer from '../../modules/templates/footer'
import { HeaderAuthentication, HeaderTop } from '../templates'
import { useSelector, useDispatch } from 'react-redux'
import { IApplicationState } from '../../../../stores'
import { useLocation } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { routes, routesNonLogin, routesRequired } from '../../../../routes/routes'
import { ERROR, GET, ROUTER_URL, SUCCESS } from '../../../../../utils/const'
import { DETAIL_ACCOUNT, GET_DEFAULT_LANGUAGE, LOGOUT } from '../../../../../utils/endpoints'
import { setUserDetail } from '../../../../stores/actions/signin'
import axios from '../../../../../utils/axios'
import { Modal } from '../modal'
import { useTranslation } from 'react-i18next'
import { CloseIcon, Loading } from '../../../../../assets/images/svgs'
import cx from 'classnames'
import { checkSignin } from '../../../../stores'
import { onMessageListener } from '../../../../../utils/firebase'
import { clearStorage } from '../../../../../utils'
import { setLang } from '../../../../stores'

interface PageProps {
  children?: any
  history?: any
}

export const Page: React.FC<PageProps> = (props: any) => {
  const location = useLocation()
  const { pathname } = location
  const dispatch: any = useDispatch()
  const { t, i18n } = useTranslation()

  const [show, setShow] = useState(false)
  const [dataModal, setDataModal] = useState<any>()

  const token = localStorage.getItem('access_token')
  const isSignedIn = useSelector((state: IApplicationState) => state.signin.isSignedIn) || !!token

  useEffect(() => {
    dispatch(checkSignin(!!token))
  }, [token])

  let userData: any = localStorage?.getItem('userData')
  if (userData) userData = JSON.parse(userData)
  const [stateNotify, setStateNotify] = useState(false)
  const [isLoading, setIsLoading] = useState(0)

  // Scroll to top when reload
  window.onbeforeunload = function () {
    document.body.scrollTo(0, 0)
  }

  onMessageListener().then((payload: any) => {
    if (payload) {
      onGetUserData()
    }
  })

  const isStateChange = useCallback(() => {
    let state = document.visibilityState
    if (state === 'visible') onGetUserData()
  }, [isSignedIn])

  useEffect(() => {
    if (isSignedIn) document.addEventListener('visibilitychange', isStateChange, true)
    else document.removeEventListener('visibilitychange', isStateChange, true)
    return () => document.removeEventListener('visibilitychange', isStateChange, true)
  }, [isSignedIn])

  useEffect(() => {
    if (token) {
      onGetUserData()
      setDefaultLangate()
    }
  }, [token])

  // Scroll to top when change route
  useEffect(() => {
    if (location?.hash) {
      try {
        let element = document.querySelector(location?.hash)
        element?.scrollIntoView()
      } catch (error) {
        setTimeout(() => document.body.scrollTo(0, 0), 20)
      }
    } else {
      setTimeout(() => document.body.scrollTo(0, 0), 20)
    }
    const html: any = document.getElementsByTagName('html')
    if (pathname === ROUTER_URL.HOME) html[0].classList.add('home')
    else html[0].classList.remove('home')
  }, [pathname])

  async function onLogout() {
    try {
      if (localStorage.getItem('access_token')) {
        await axios.get(LOGOUT, {
          params: { device_token: localStorage.getItem('device_token') },
        })
      }
      document.removeEventListener('visibilitychange', isStateChange, true)
      clearStorage()
      dispatch(checkSignin(false))
    } catch (error) {
      document.removeEventListener('visibilitychange', isStateChange, true)
      clearStorage()
      dispatch(checkSignin(false))
    }
  }

  async function setDefaultLangate() {
    try {
      await axios.get(GET_DEFAULT_LANGUAGE).then(res => {
        let dbLang = res?.data.data
        let i18nextLng = localStorage.getItem('i18nextLng') || 'ja'
        if (dbLang === 'JP') {
          i18nextLng = 'ja'
        } else if (dbLang === 'EN') {
          i18nextLng = 'en'
        }
        i18n.changeLanguage(i18nextLng)
        dispatch(setLang(i18nextLng))
      })
    } catch (error) {
    }
  }

  async function onGetUserData() {
    try {
      const result = await callApi(DETAIL_ACCOUNT, GET, null, false)
      const data = result?.data?.data
      if (data) {
        dispatch(setUserDetail(data || {}))
        localStorage.setItem('userData', JSON.stringify(data))
      }
    } catch (error) {
    }
  }

  const notify = (message: string, type = SUCCESS, title: string) => {
    if (!!message)
      notifyModal(
        message !== t('home.modal_title_error')
          ? {
              content: message,
              type,
              title: title || (type === ERROR ? t('home.modal_title_error') : ''),
            }
          : message
      )
  }

  function notifyModal(data: string | any, type?: 'success' | 'error' | undefined) {
    setDataModal(data)
    setShow(true)
  }

  function toggleModal() {
    setShow(false)
    setDataModal({})
  }

  function isAuth() {
    const result = [
      ROUTER_URL.ACTIVE_EMAIL,
      ROUTER_URL.CHANGE_EMAIL,
      ROUTER_URL.CONFIRM_EMAIL,
    ].some((path: string) => pathname.includes(path.split('/')[1]))
    return !result
  }
  function isMobile() {
    const result = [
      ROUTER_URL.SIGNUP_MOBILE,
      ROUTER_URL.SIGNUP_CONFIRM_MOBILE,
      ROUTER_URL.TERM_OF_SERVICE_MOBILE,
      ROUTER_URL.PRIVACY_POLICY_MOBILE,
    ].some((path: string) => pathname.includes(path.split('/')[1]))
    return result
  }

  async function callApi(url: string, method = GET, params?: any, isLoading = true, isThrow = true) {
    if (!url) return
    let response = null
    try {
      if (isLoading) setIsLoading((prevState) => prevState + 1)
      const callback = axios[method]
      response = await callback(url, params)
      if (isLoading) setIsLoading((prevState) => prevState - 1)
      return response
    } catch (error) {
      if (isLoading) setIsLoading((prevState) => prevState - 1)
      if (isThrow) throw error
    }
  }

  return (
    <>
      {!!isLoading && (
        <div className="loading">
          <span>
            <Loading />
          </span>
        </div>
      )}
      <div className="page-wrapper">
        <div className="page">
          {pathname !== ROUTER_URL.HOME && pathname !== ROUTER_URL.ABOUT_USER_STATUS_FOR_ANDROID && pathname !== ROUTER_URL.ABOUT_USER_STATUS_FOR_IOS &&
            (pathname.includes(ROUTER_URL.NEWS) ? (
              <HeaderTop />
              ) : isMobile() ? (
                <div></div>
              ) : isSignedIn && isAuth() ? (
              <Header stateNotify={stateNotify} callApi={callApi} onLogout={onLogout} />
            ) : (
              <HeaderAuthentication />
            ))}
          <div className="flex-1">
            <Switch>
              {isSignedIn
                ? [...routesRequired, ...routes].map(
                    (route: any, idx: number) =>
                      (userData?.status ? userData?.status: true) && (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          render={(props) => (
                            <route.component
                              {...props}
                              notify={notify}
                              notifyModal={notifyModal}
                              isSignedIn={isSignedIn}
                              callApi={callApi}
                              onLogout={onLogout}
                              isLoading={!!isLoading}
                              setIsLoading={setIsLoading}
                              setStateNotify={() => setStateNotify((prevState) => !prevState)}
                            />
                          )}
                        />
                      )
                  )
                : [...routesNonLogin, ...routes].map((route: any, idx: number) => (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      render={(props) => (
                        <route.component
                          {...props}
                          notify={notify}
                          callApi={callApi}
                          onLogout={onLogout}
                          notifyModal={notifyModal}
                          isSignedIn={isSignedIn}
                        />
                      )}
                    />
                  ))}

              <Redirect
                to={(pathname === ROUTER_URL.SCAN_BOOKING) || (pathname === ROUTER_URL.SCANNER)  || (pathname > ROUTER_URL.CONTROL_AVATAR) ? ROUTER_URL.SIGNIN : ROUTER_URL.HOME}
              />
            </Switch>
          </div>
          {pathname !== ROUTER_URL.HOME && pathname !== ROUTER_URL.ABOUT_USER_STATUS_FOR_ANDROID && pathname !== ROUTER_URL.ABOUT_USER_STATUS_FOR_IOS && pathname !== ROUTER_URL.SIGNUP_MOBILE && 
           pathname !== ROUTER_URL.SIGNUP_CONFIRM_MOBILE && pathname !== ROUTER_URL.TERM_OF_SERVICE_MOBILE && pathname !== ROUTER_URL.PRIVACY_POLICY_MOBILE &&  (
            <footer>
              <Footer />
            </footer>
          )}
        </div>
      </div>
      {show && (
        <Modal
          modalStyles={cx(
            'booking__modal-booking booking__modal-booking--notify padding-modal-in-h2',
            {
              isBooking: dataModal?.isBooking,
            }
          )}
          show={show}
          toggleModal={toggleModal}
        >
          <div className="detail-avatar__delete-modal">
            <div className="detail-avatar__delete-modal_header">
              {dataModal?.title && (
                <div className="detail-avatar__delete-modal_header-text">
                  <div
                    className={cx('pop-text-bold-big', {
                      'text-error': dataModal?.type === ERROR,
                    })}
                  >
                    {t(dataModal?.title)}
                  </div>
                </div>
              )}
              <button
                className="detail-avatar__delete-modal_header-icon close-icon"
                onClick={toggleModal}
              >
                <CloseIcon />
              </button>
            </div>
            {!!dataModal && (
              <div
                className="detail-avatar__delete-modal_content pop-large-body-text distance-line-to-text "
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {typeof dataModal === 'string' || dataModal instanceof String ? (
                  dataModal
                ) : (
                  <>
                    {dataModal?.content &&
                      (typeof dataModal?.content === 'string' ||
                      dataModal?.content instanceof String ? (
                        dataModal?.content?.replace('\\n', '\n')
                      ) : (
                        <dataModal.content />
                      ))}
                  </>
                )}
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}

export default Page
