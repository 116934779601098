import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import BookingForm from './booking-form'
import BookingHistory from './booking-history'
import { CalendarIcon } from './svg'
import { useSelector, useDispatch } from 'react-redux'
import { IApplicationState } from '../../../stores/states'
import { TabTypes } from '../../../stores/actions/checkTab'
import { getAddressByCodeArea, getCountries } from '../../../../apis/booking'
import Wrapper from '../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../utils/const'

interface BookingProps {
  notify?: any
  notifyModal?: any
  callApi: any
  disabled: any
}

const optiontTab = [
  {
    id: 1,
    msg: true,
    title: 'booking.booking',
  },
  {
    id: 2,
    msg: false,
    title: 'booking.booking_history',
  },
]

const Booking: React.FC<BookingProps> = ({ notify, notifyModal, callApi, disabled }) => {

  const title = '撮影予約 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = '撮影予約のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [t] = useTranslation('translation')
  const location: any = useLocation()
  const tab = useSelector((state: IApplicationState) => state.tab.message)

  const [addressList, setAddressList] = useState<any>([])
  const [selectedAddress, setSelectedAddress] = useState<any>({})
  const [areas, setAreas] = useState<any>([])
  const [selectedArea, setSelectedArea] = useState<any>({})

  const dispatch = useDispatch()

  useEffect(() => {
    fetchArea()
    return () => {
      dispatch({ type: TabTypes.CHECK_TAB, payload: 1 })
    }
  }, [localStorage.getItem('i18nextLng')])

  const fetchArea = async () => {
    try {
      const { state } = location
      const result = await getCountries()
      if (result) {
        const apiAreas = result.data.data
        const areaOptions = apiAreas.map((item: any) => {
          return { label: item.text, value: item.value, default: item.default }
        })

        setAreas(areaOptions)
        let selectArea = areaOptions.find((area: any) =>
          state?.area ? area.value === state.area : !!area?.default
        )
        if (!selectArea && !!areaOptions?.length) selectArea = areaOptions[0]
        setSelectedArea(selectArea)
        fetchAddressList(selectArea)
      }
    } catch (error) {
    }
  }

  const fetchAddressList = async (selectArea?: any) => {
    const { state } = location
    try {
      const value = selectArea?.value || selectedArea?.value
      const result = await getAddressByCodeArea(value)
      if (result) {
        const apiAddresses = result.data.data
        const addressOptions = apiAddresses.map((item: any) => ({
          label: `${item?.scanner_name} : ${item?.address}`,
          value: item?.scanner_id,
          lat: item?.latitude,
          lng: item?.longitude,
          establish_date: item?.establish_date,
          expiration_date: item?.expiration_date,
          address: item?.address,
          scanner_name: item?.scanner_name,
        }))
        setAddressList(addressOptions)
        setSelectedAddress(
          state
            ? addressOptions.find((address: any) => address.value === state.scanner_id)
            : addressOptions[0]
        )
        delete location.state
      }
    } catch (error) {
    }
  }

  function onResetArea() {
    fetchArea()
  }

  function onChangeArea(data: any) {
    const areaChange = areas.find((area: any) => area?.value === data?.value)
    setSelectedArea(areaChange)
    fetchAddressList(areaChange)
  }

  const list = [
    {
      name: t('signup.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('booking.scan_booking'),
      link: ROUTER_URL.SCAN_BOOKING,
    },
  ]

  return (
    <Wrapper title={t('booking.scan_booking')} Icon={CalendarIcon} breadcrum={list} isNoMarginTop={true}>
      <div className="booking__describe">
        <p>{t('booking.describe')}<br />{t('booking.describe2')}<br />{t('booking.describe3')}</p>
      </div>
      <div className="booking__options">
        {optiontTab.map(({ title, msg, id }) => {
          return (
            <span
              key={id}
              className={classnames('booking__options--item', { active: id === tab })}
              onClick={() => dispatch({ type: TabTypes.CHECK_TAB, payload: id })}
            >
              {t(title)}
            </span>
          )
        })}
      </div>
      {Number(tab) === 1 ? (
        <BookingForm
          setSelectedAddress={setSelectedAddress}
          selectedAddress={selectedAddress}
          areas={areas}
          selectedArea={selectedArea}
          addressList={addressList}
          setSelectedArea={onChangeArea}
          onResetArea={onResetArea}
          callApi={callApi}
          notify={notify}
          notifyModal={notifyModal}
        />
      ) : (
        <BookingHistory callApi={callApi} notify={notify} />
      )}
    </Wrapper>
  )
}

export default Booking
