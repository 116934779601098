import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconGroup4: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" y="11" width="138" height="138" rx="9" fill="white" />
      <rect x="11" y="11" width="138" height="138" rx="9" stroke="#FF6C6C" strokeWidth="2" />
      <path
        d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33Z"
        fill="#FF6C6C"
      />
      <path
        d="M18.2434 16.471L23.5814 11.133C23.7047 11.0186 23.8035 10.8806 23.8722 10.7271C23.9409 10.5736 23.9779 10.4079 23.9811 10.2398C23.9842 10.0717 23.9535 9.90466 23.8906 9.74871C23.8278 9.59276 23.7342 9.45107 23.6154 9.33212C23.4965 9.21317 23.355 9.11938 23.1991 9.05638C23.0432 8.99337 22.8762 8.96243 22.7081 8.96541C22.54 8.96838 22.3742 9.00521 22.2207 9.0737C22.0671 9.14219 21.929 9.24092 21.8144 9.36401L16.4744 14.702L11.1364 9.36401C11.0209 9.24511 10.8829 9.15036 10.7304 9.08528C10.5779 9.02019 10.414 8.98607 10.2482 8.98488C10.0824 8.98369 9.91808 9.01547 9.76468 9.07837C9.61129 9.14127 9.47192 9.23403 9.35469 9.35126C9.23746 9.46849 9.1447 9.60785 9.0818 9.76125C9.01891 9.91465 8.98713 10.079 8.98831 10.2448C8.9895 10.4106 9.02363 10.5745 9.08871 10.727C9.1538 10.8794 9.24854 11.0175 9.36744 11.133L14.7054 16.471L9.36744 21.811C9.13837 22.0467 9.01128 22.3631 9.01363 22.6918C9.01598 23.0205 9.14759 23.335 9.38001 23.5674C9.61242 23.7999 9.92697 23.9315 10.2556 23.9338C10.5843 23.9362 10.9007 23.8091 11.1364 23.58L16.4744 18.242L21.8144 23.578C22.052 23.7959 22.3645 23.9136 22.6868 23.9067C23.0091 23.8997 23.3162 23.7687 23.5442 23.5408C23.7722 23.3129 23.9035 23.0058 23.9106 22.6836C23.9177 22.3613 23.8002 22.0487 23.5824 21.811L18.2434 16.471Z"
        fill="white"
      />
      <path
        d="M42.8281 40.3926H69.9281V45.4716H42.8281V40.3926Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2"
      />
      <path
        d="M42.828 48.3176L34 121.965C34.2251 122.521 34.6519 122.971 35.195 123.226C36.534 123.618 38.618 121.185 39.995 121.499C41.372 121.813 42.166 124.908 43.562 125.144C44.958 125.38 46.729 122.723 48.128 122.878C49.527 123.033 50.673 126.016 52.087 126.094C53.501 126.172 54.966 123.333 56.374 123.333C57.782 123.333 59.274 126.172 60.664 126.094C62.054 126.016 63.235 123.034 64.62 122.878C66.005 122.722 67.82 125.378 69.189 125.144C70.558 124.91 71.4 121.81 72.756 121.499C74.112 121.188 76.211 123.615 77.556 123.226C78.1005 122.973 78.5292 122.523 78.756 121.968L69.92 48.3176H42.828Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M94.9351 101.223V100.743L87.3271 126.472C86.9951 127.413 87.4881 128.041 88.7621 128.252C92.7753 128.753 96.8182 128.977 100.862 128.924C111.744 128.447 125.305 124.699 124.128 124.701C108.002 124.71 94.9351 116.67 94.9351 101.223Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M98.2464 88.8193L94.9464 100.743V101.223C94.9137 104.962 95.621 108.669 97.0275 112.133C98.434 115.597 100.512 118.748 103.142 121.405C105.771 124.062 108.901 126.172 112.35 127.615C115.799 129.057 119.499 129.802 123.237 129.808C123.76 129.808 124.283 129.795 124.806 129.768C125.007 129.757 125.203 129.702 125.379 129.605C125.555 129.509 125.708 129.374 125.825 129.211C125.943 129.048 126.022 128.861 126.057 128.663C126.093 128.465 126.084 128.262 126.03 128.068L116.181 88.8193H98.2464Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M117.222 34.2402H95.207V39.7442H117.222V34.2402Z"
        fill="white"
        stroke="#111948"
        strokeWidth="2"
      />
      <path
        d="M118.665 48.6942L117.221 42.9152H95.2052L93.7662 48.6942C92.9012 52.1461 92.7144 55.7331 93.2162 59.2562L95.2062 81.4422H103.949L105.683 66.1882C105.683 66.0424 105.741 65.9025 105.844 65.7993C105.947 65.6962 106.087 65.6382 106.233 65.6382C106.379 65.6382 106.519 65.6962 106.622 65.7993C106.725 65.9025 106.783 66.0424 106.783 66.1882L108.517 81.4422H117.217L119.207 59.2592C119.712 55.7357 119.528 52.1476 118.665 48.6942Z"
        fill="white"
        stroke="#111948"
        strokeWidth="2"
      />
    </svg>
  )
}

export default IconGroup4
