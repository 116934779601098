import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { LoginIconTop, LogoDark, MenuIcon, SignupIconTop } from '../../../../../../assets/images'
import { IApplicationState } from '../../../../../stores'
import { MyPageButton } from '../../../atoms/buttons'
import { MenuMobile } from '../../../../components/block/menuMobile'
import SwitchLanguagesTop from '../../switch-languages-top'
import { Button } from '../../../atoms/buttons'
import { ROUTER_URL } from '../../../../../../utils/const'
import cx from 'classnames'

interface HeaderTopProps {
  onReload?: any
}

const HeaderTop: React.FC<HeaderTopProps> = ({ onReload }) => {
  const [, i18n] = useTranslation('translation')
  const history = useHistory()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const isSignedIn = useSelector((state: IApplicationState) => state.signin.isSignedIn)

  const toggleMenuMobiles = () => {
    setOpen((open) => !open)
  }

  const renderRegisterButton = () => (
    <Button
      onClick={() => history.push({ pathname: ROUTER_URL.SIGNUP, state: { email: 111 } })}
      className="outline-1"
    >
      <SignupIconTop />
      <span>{i18n.t('header_top.signup')}</span>
    </Button>
  )

  const renderLoginButton = () => (
    <Button onClick={() => history.push(ROUTER_URL.SIGNIN)} className="secondary ">
      <LoginIconTop />
      <span>Log In</span>
    </Button>
  )

  const renderMyPageButton = () => <MyPageButton />

  const renderGroupAuthButtons = () => {
    return (
      <React.Fragment>
        {isSignedIn ? (
          <>{renderMyPageButton()}</>
        ) : (
          <>
            {renderRegisterButton()}
            {renderLoginButton()}
          </>
        )}
      </React.Fragment>
    )
  }

  const renderMenuIcon = (
    <div onClick={toggleMenuMobiles} className="header-top__menu">
      <MenuIcon />
    </div>
  )

  return (
    <React.Fragment>
      {open ? (
        <div className="offset"></div>
      ) : (
        <div
          className={cx('header-top', { 'is-news': location?.pathname?.includes(ROUTER_URL.NEWS) })}
        >
          <div
            className="header-top__logo"
            onClick={onReload ? onReload : () => history.push(ROUTER_URL.HOME)}
          >
            <LogoDark />
          </div>
          <div className="header-top__more-selection">
            <div className="header-top__select-language">
              <SwitchLanguagesTop />
            </div>
            <div className="header-top__group-auth-buttons">{renderGroupAuthButtons()}</div>
            {renderMenuIcon}
          </div>
        </div>
      )}
      <div>
        <MenuMobile open={open} toggleMenuMobiles={toggleMenuMobiles} />
      </div>
    </React.Fragment>
  )
}

export default HeaderTop
