import React from 'react'

interface Dots8x7SpreadProps {
  height?: string
  width?: string
}

const Dots8x7Spread: React.FC<Dots8x7SpreadProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "122"} height={height || "105"} viewBox="0 0 122 105">
      <defs>
        <clipPath id="clipPath">
          <path id="Rectangle_3355" d="M0 0H122V105H0z" className="cls-9959-1" />
        </clipPath>
        <clipPath id="clipPath-3">
          <path
            id="Rectangle_3298"
            d="M0 0H3V3H0z"
            className="cls-9959-1"
            transform="translate(119 85)"
          />
        </clipPath>
        <clipPath id="clipPath-5">
          <path id="Rectangle_3353" d="M0 0H3V3H0z" className="cls-9959-1" />
        </clipPath>
        <style>
          {
            '.cls-9959-1{fill:none}.cls-9959-2{clipPath:url(#clipPath)}.cls-9959-3{opacity:0}.cls-9959-5{fill:#12c4c4}'
          }
        </style>
      </defs>
      <g id="DOTS3" transform="translate(61 52.5)">
        <g id="Group_10609" className="cls-9959-2" transform="translate(-61 -52.5)">
          <g id="Group_10605" className="cls-9959-2">
            <g id="Group_10604" className="cls-9959-3">
              <g id="Group_10603" clipPath="url(#clipPath-3)">
                <path
                  id="Rectangle_3297"
                  d="M0 0H3V3H0z"
                  className="cls-9959-5"
                  transform="translate(119 85)"
                />
              </g>
            </g>
          </g>
          <path
            id="Rectangle_3300"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(119 68)"
          />
          <path
            id="Rectangle_3301"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(119 51)"
          />
          <path
            id="Rectangle_3302"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(119 34)"
          />
          <path
            id="Rectangle_3303"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(119 17)"
          />
          <path id="Rectangle_3304" d="M0 0H3V3H0z" className="cls-9959-5" transform="translate(119)" />
          <path
            id="Rectangle_3305"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(102 85)"
          />
          <path
            id="Rectangle_3306"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(102 68)"
          />
          <path
            id="Rectangle_3307"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(102 51)"
          />
          <path
            id="Rectangle_3308"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(102 34)"
          />
          <path
            id="Rectangle_3309"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(102 17)"
          />
          <path id="Rectangle_3310" d="M0 0H3V3H0z" className="cls-9959-5" transform="translate(102)" />
          <path
            id="Rectangle_3311"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(85 102)"
          />
          <path
            id="Rectangle_3312"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(85 85)"
          />
          <path
            id="Rectangle_3313"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(85 68)"
          />
          <path
            id="Rectangle_3314"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(85 51)"
          />
          <path
            id="Rectangle_3315"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(85 34)"
          />
          <path
            id="Rectangle_3316"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(85 17)"
          />
          <path id="Rectangle_3317" d="M0 0H3V3H0z" className="cls-9959-5" transform="translate(85)" />
          <path
            id="Rectangle_3318"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(68 102)"
          />
          <path
            id="Rectangle_3319"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(68 85)"
          />
          <path
            id="Rectangle_3320"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(68 68)"
          />
          <path
            id="Rectangle_3321"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(68 51)"
          />
          <path
            id="Rectangle_3322"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(68 34)"
          />
          <path
            id="Rectangle_3323"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(68 17)"
          />
          <path id="Rectangle_3324" d="M0 0H3V3H0z" className="cls-9959-5" transform="translate(68)" />
          <path
            id="Rectangle_3325"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(51 102)"
          />
          <path
            id="Rectangle_3326"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(51 85)"
          />
          <path
            id="Rectangle_3327"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(51 68)"
          />
          <path
            id="Rectangle_3328"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(51 51)"
          />
          <path
            id="Rectangle_3329"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(51 34)"
          />
          <path
            id="Rectangle_3330"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(51 17)"
          />
          <path id="Rectangle_3331" d="M0 0H3V3H0z" className="cls-9959-5" transform="translate(51)" />
          <path
            id="Rectangle_3332"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(34 102)"
          />
          <path
            id="Rectangle_3333"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(34 85)"
          />
          <path
            id="Rectangle_3334"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(34 68)"
          />
          <path
            id="Rectangle_3335"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(34 51)"
          />
          <path
            id="Rectangle_3336"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(34 34)"
          />
          <path
            id="Rectangle_3337"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(34 17)"
          />
          <path id="Rectangle_3338" d="M0 0H3V3H0z" className="cls-9959-5" transform="translate(34)" />
          <path
            id="Rectangle_3339"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(17 102)"
          />
          <path
            id="Rectangle_3340"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(17 85)"
          />
          <path
            id="Rectangle_3341"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(17 68)"
          />
          <path
            id="Rectangle_3342"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(17 51)"
          />
          <path
            id="Rectangle_3343"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(17 34)"
          />
          <path
            id="Rectangle_3344"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(17 17)"
          />
          <path id="Rectangle_3345" d="M0 0H3V3H0z" className="cls-9959-5" transform="translate(17)" />
          <path
            id="Rectangle_3346"
            d="M0 0H3V3H0z"
            className="cls-9959-5"
            transform="translate(0 102)"
          />
          <path id="Rectangle_3347" d="M0 0H3V3H0z" className="cls-9959-5" transform="translate(0 85)" />
          <path id="Rectangle_3348" d="M0 0H3V3H0z" className="cls-9959-5" transform="translate(0 68)" />
          <path id="Rectangle_3349" d="M0 0H3V3H0z" className="cls-9959-5" transform="translate(0 51)" />
          <path id="Rectangle_3350" d="M0 0H3V3H0z" className="cls-9959-5" transform="translate(0 34)" />
          <path id="Rectangle_3351" d="M0 0H3V3H0z" className="cls-9959-5" transform="translate(0 17)" />
          <g id="Group_10608" className="cls-9959-2">
            <g id="Group_10607" className="cls-9959-3">
              <g id="Group_10606" clipPath="url(#clipPath-5)">
                <path id="Rectangle_3352" d="M0 0H3V3H0z" className="cls-9959-5" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Dots8x7Spread
