import { useTranslation } from 'react-i18next'
import { ROUTER_URL } from '../../../../../utils/const';
import Wrapper from '../../../components/modules/_wrapper'

interface expiredResetPassword {}

const ExpiredResetPassword: React.FC<expiredResetPassword> = () => {

  const title = '認証期限切れ | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = '認証期限切れのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [, i18n] = useTranslation()

  const list = [
    {
      name: i18n.t('signup.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('reset_password.loginBreacrum-url-exp'),
      link: ROUTER_URL.SIGNIN,
    },
    {
      name: i18n.t('reset_password.url-password-expired'),
      link: ROUTER_URL.RESET_PASSWORD_EXPIRED,
    },
  ]

  return (
    <Wrapper
      buttonLink={ROUTER_URL.SIGNIN}
      buttonText={i18n.t('reset_password.button-exp')}
      breadcrum={list}
      className="active-mail"
      typeBreadcrums={1}
      isNoMarginTop={false}
    >
      <div className="active-mail__container" id="exp-container">
        <h2 className="title-h2-error distance-h2-to-text">
          {i18n.t('reset_password.title-pass-exp')}
        </h2>
        <div className="active-mail__content-err distance-text-to-btn" style={{ paddingBottom: 0 }} id="exp-content">
          <p>{i18n.t('reset_password.exp-content1')}<br />{i18n.t('reset_password.exp-content2')}</p>
        </div>
      </div>
    </Wrapper>
  )
}

export default ExpiredResetPassword
