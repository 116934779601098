import { useTranslation } from 'react-i18next'
import { ShieldIcon } from '../../../../../assets/images/svgs'
import Wrapper from '../../../components/modules/_wrapper'
import { useSelector } from 'react-redux'
import { IApplicationState } from '../../../../stores'
import { ROUTER_URL } from '../../../../../utils/const'
import PrivacyPolicyConstants from './constants'

interface PrivacyPolicyProps {}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = () => {
  const [t] = useTranslation('translation')
  const isSignedIn = useSelector((state: IApplicationState) => state.signin.isSignedIn)

  const title = 'プライバシーポリシー | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'プライバシーポリシーのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name');
      const propertyVal = headData[i].getAttribute('property');
      if (nameVal !== null) {
          if (nameVal.indexOf('keywords') !== -1) {
              headData[i].setAttribute('content', keywords);
          }
          if (nameVal.indexOf('description') !== -1) {
              headData[i].setAttribute('content', description);
          }
      }
      if (propertyVal !== null) {
          // OGP(og:title)の設定
          if (propertyVal.indexOf('og:title') !== -1) {
              headData[i].setAttribute('content', title);
          }
          if (propertyVal.indexOf('og:description') !== -1) {
              headData[i].setAttribute('content', description);
          }
          if (propertyVal.indexOf('og:image') !== -1) {
              headData[i].setAttribute('content', og_image);
          }
      }
  }


  const list = [
    {
      name: t('policy.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('policy.privacy_policy.breadcrum'),
      link: ROUTER_URL.PRIVACY_POLICY,
    },
  ]
  return (
    <Wrapper
      breadcrum={list}
      title={t('policy.privacy_policy.title')}
      buttonText={t(`policy.privacy_policy.button${isSignedIn ? '2' : ''}`)}
      buttonLink={isSignedIn ? ROUTER_URL.SCAN_BOOKING : ROUTER_URL.HOME}
      className="policy"
      Icon={ShieldIcon}
      classNameBtn={'normal'}
    >
      <PrivacyPolicyConstants>
      </PrivacyPolicyConstants>
    </Wrapper>
  )
}
export default PrivacyPolicy
