import classnames from 'classnames'
import { useFormContext } from 'react-hook-form'
import { EyeHideIcon, EyeIcon } from '../../../../../assets/images/svgs'
import { useState } from 'react'
interface TextInputProps {
  name: string
  type?: string
  label?: string
  placeholder?: string
  isRequire: boolean
  isDisabled?: boolean
  isFocus?: boolean
  errorApi?: any
  handleChange?: any
  errorsBorder?: boolean
  isNoMarginH4?: boolean
  isModal?: boolean
}

export const TextInputPassword: React.FC<TextInputProps> = ({
  name,
  label,
  placeholder = 'Typing here...',
  isRequire,
  type,
  isDisabled,
  isFocus,
  errorApi,
  errorsBorder,
  handleChange,
  isNoMarginH4,
  isModal
}) => {
  const [isPass, setIsPass] = useState(true)
  const { errors, register } = useFormContext()
  const isError = (errorsBorder || !!errors?.[name]) ?? false
  const message = errors?.[name]?.message ?? null

  function _handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <div
      className={classnames('input-password_component', { 'input-label-gutter_password': label })}
    >
      {label ? (
        <div className={isNoMarginH4 ? 'input-password_component__label--wrapper ' : "input-password_component__label--wrapper distance-line-to-h4"}>
          <p className="text-bold-small">{label}</p>
          {isRequire ? <span className="input-password_component__asterisk">*</span> : null}
        </div>
      ) : null}
      <div className={isModal ? "group-input-modal" : "group-input"}>
      <input
        autoComplete="off"
        type={isPass ? type : 'text'}
        ref={register}
        className={classnames('input-password_component__field', { error: isError })}
        placeholder={placeholder}
        name={name}
        disabled={isDisabled}
        onKeyDown={_handleKeyDown}
        autoFocus={isFocus}
        style={{ fontSize: '1.4rem', height: '4.5rem' }}
        onChange={handleChange}
      />
      {type === 'password' ? (
        <div onClick={() => setIsPass(!isPass)} className="eye_password">
          {isPass ? <EyeIcon /> : <EyeHideIcon />}
        </div>
      ) : (
        ''
      )}
      </div>
      {message ? <span className="form-field-error-message">{message}</span> : null}
      {errorApi ? <span className="form-field-error-message">{errorApi}</span> : null}
    </div>
  )
}
