import React from 'react'

interface CheckCircleProps {
  height?: string
  width?: string
}

const CheckCircle: React.FC<CheckCircleProps> = ({ height, width }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#12C4C4" />
      <path
        d="M4 8L7.5 11L12 5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckCircle
