import { useTranslation } from 'react-i18next'
import { TextInputPassword } from '../../../../components/atoms/text-input-password'
import { Button } from '../../../../components/atoms/buttons'
import { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { validatorChangePassword, passwordFields, passwordChangeDefault } from './constants'
import { POST, SUCCESS } from '../../../../../../utils/const'
import { CloseIcon } from '../../../../../../assets/images'
import { CHANGE_PASSWORD } from '../../../../../../utils/endpoints'
interface passwordModalProps {
  onTogglePasswordModal?: () => void
  notify: any
  callApi: any
}

const PasswordModal: React.FC<passwordModalProps> = ({
  onTogglePasswordModal,
  notify,
  callApi,
}) => {
  const [t, i18n] = useTranslation('translation')
  const [error, setError] = useState<any>()
  const methods = useForm({
    defaultValues: passwordChangeDefault,
    resolver: validatorChangePassword(t),
  })
  const { handleSubmit } = methods

  const onSubmit = async (values: any) => {
    try {
      const params = {
        old_password: values.old_password,
        new_password: values.new_password,
        confirm_password: values.confirm_password,
      }
      const response = await callApi(CHANGE_PASSWORD, POST, params)
      if (response) {
        if (onTogglePasswordModal) {
          onTogglePasswordModal()
        }
        notify(response?.data?.message, SUCCESS, t('password_modal.title_success'))
      }
    } catch (error) {
      const { message, name } = error || {}
      if (message) {
        setError(
          name === 'E000023' ? { field: 'old_password', message } : error?.message || ''
        )
      }
    }
  }

  const renderPasswordFields = (
    <>
      <div className="password__field-wrapper">
        {passwordFields.map(({ name, type, isRequire, placeholder, label }) => {
          const msg = () => {
            if (error) {
              if (error['field'] === name) {
                return error['message']
              }
            }
          }
          return (
            <TextInputPassword
              key={name}
              type={type}
              name={name}
              isRequire={isRequire}
              placeholder={i18n.t(placeholder)}
              label={i18n.t(label)}
              errorApi={msg()}
              isModal={true}
            />
          )
        })}
      </div>
      {!!error && typeof error !== 'object' && (
        <div className="error-message">
          <span>{error}</span>
        </div>
      )}
    </>
  )

  const renderBtnPassword = (
    <div className="button-register">
      <Button
        className="button-custom cancel shortnormal cancel-button text-button-medium"
        onClick={onTogglePasswordModal}
      >
        {i18n.t('password_modal.btn-cancel')}
      </Button>
      <Button className="button-custom shortnormal text-button-medium" type="submit">
        {i18n.t('password_modal.btn-accept')}
      </Button>
    </div>
  )
  const checkKeyDown = (e: any) => {
    if (e.code === 'Enter') handleSubmit(onSubmit)()
  }

  return (
    <div className="wrap-setting_modal padding-modal-in-h2">
      <div className="wrap-setting_modal__modal-password__header">
        <div className="wrap-setting_modal__modal-password__header--space"></div>
        <div className="wrap-setting_modal__modal-password__header--title title-h2 distance-h2-to-line">
          {i18n.t('password_modal.title')}
        </div>
        <button className="md-close_modal close-icon" onClick={onTogglePasswordModal}>
          <CloseIcon />
        </button>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
          <div className="wrap-setting_modal__password-modal distance-form-to-btn">
            {renderPasswordFields}
          </div>
          <div className="wrap-setting_modal_button">{renderBtnPassword}</div>
        </form>
      </FormProvider>
    </div>
  )
}

export default PasswordModal
