import * as React from 'react'

interface MapIconProps {
  height?: string | number
  width?: string | number
  color?: string
}
const MapIcon: React.FC<MapIconProps> = ({ height, width, color }) => {
  return (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66667 2C3.19391 2 2 3.19391 2 4.66667V6.7199L30 11.6571V4.66667C30 3.19391 28.8061 2 27.3333 2H4.66667ZM30 13.5525L15.9026 11.0668L10.8295 30H27.3333C28.8061 30 30 28.8061 30 27.3333V13.5525ZM2 8.61537L14.0573 10.7414L8.89696 30H4.66667C3.19391 30 2 28.8061 2 27.3333V8.61537ZM16.9365 19.3436C16.9365 16.4707 19.2393 14.1334 22.0698 14.1334C24.9003 14.1334 27.2031 16.4707 27.2032 19.3436C27.2032 21.0363 26.3873 23.0134 24.7783 25.2201C23.5995 26.8366 22.4372 27.9584 22.3883 28.0053C22.2994 28.0908 22.1846 28.1334 22.0699 28.1334C21.9518 28.1334 21.8338 28.0882 21.7441 27.9982C21.6954 27.9492 20.536 26.7801 19.3594 25.1409C17.7517 22.9011 16.9365 20.9506 16.9365 19.3436ZM19.5602 19.0907C19.5602 20.4704 20.686 21.5929 22.0698 21.5929C23.4536 21.5929 24.5794 20.4704 24.5794 19.0907C24.5794 17.7109 23.4536 16.5884 22.0698 16.5884C20.686 16.5884 19.5602 17.7109 19.5602 19.0907Z"
        fill=""
      />
    </svg>
  )
}

export default MapIcon
