import * as React from 'react'

interface TickProps {
  height?: string
  width?: string
}
const TickIcon: React.FC<TickProps> = ({ height, width }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.65838 19.6918C9.46746 19.8896 9.20699 20 8.93643 20C8.66586 20 8.40539 19.8896 8.21447 19.6918L2.44877 13.7505C1.85041 13.134 1.85041 12.1344 2.44877 11.5191L3.17072 10.775C3.76926 10.1586 4.73841 10.1586 5.33676 10.775L8.93643 14.4839L18.6632 4.46236C19.2618 3.84588 20.2319 3.84588 20.8293 4.46236L21.5512 5.20636C22.1496 5.82284 22.1496 6.82229 21.5512 7.4378L9.65838 19.6918Z"
        fill="#9496AB"
      />
    </svg>
  )
}

export default TickIcon
