import * as React from 'react'

interface MapSmallIconProps {
  height?: string | number
  width?: string | number
}
const MapSmallIcon: React.FC<MapSmallIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox={`0 0 ${width || 16} ${height || 16}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66667 0C1.19391 0 0 1.19391 0 2.66667V2.69709L16 5.51832V2.66667C16 1.19391 14.8061 0 13.3333 0H2.66667ZM16 6.60144L7.94439 5.18102L5.04545 16H13.3333C14.8061 16 16 14.8061 16 13.3333V6.60144ZM0 3.78021L6.88991 4.99509L3.94115 16H2.66667C1.19391 16 0 14.8061 0 13.3333V3.78021ZM8.53516 9.91062C8.53516 8.26895 9.85103 6.93335 11.4685 6.93335C13.0859 6.93335 14.4018 8.26895 14.4018 9.91061C14.4018 10.8779 13.9356 12.0077 13.0162 13.2686C12.3426 14.1924 11.6784 14.8334 11.6505 14.8602C11.5996 14.909 11.5341 14.9333 11.4685 14.9333C11.401 14.9333 11.3336 14.9076 11.2824 14.8561C11.2545 14.8281 10.592 14.1601 9.91967 13.2234C9.00096 11.9435 8.53516 10.8289 8.53516 9.91062ZM10.0344 9.76609C10.0344 10.5545 10.6777 11.196 11.4685 11.196C12.2592 11.196 12.9025 10.5545 12.9025 9.76609C12.9025 8.97767 12.2592 8.33623 11.4685 8.33623C10.6777 8.33623 10.0344 8.97767 10.0344 9.76609Z"
        fill=""
      />
    </svg>
  )
}

export default MapSmallIcon
