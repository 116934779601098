import { AnyAction, Reducer } from 'redux';
import { initialHistoryState, SaveHistoryBookingState } from '../states';
import {  SaveHistoryTypes } from '../actions';

export const HistoryReducer: Reducer = (state: SaveHistoryBookingState = initialHistoryState, action: AnyAction) => {
    switch (action.type) {
        case SaveHistoryTypes.SAVE_DATA: {
            return {
                ...state,
                history: action.payload,
            };
        }
        default:
            return state;
    }
};