import * as React from 'react'

interface SignupIconTopProps {
  height?: string
  width?: string
}
const SignupIconTop: React.FC<SignupIconTopProps> = ({ height, width }) => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 9.50196V12.002H2.5L9.873 4.62896L7.373 2.12896L0 9.50196ZM11.807 2.69496C11.8688 2.63329 11.9178 2.56003 11.9513 2.47938C11.9847 2.39873 12.002 2.31227 12.002 2.22496C12.002 2.13765 11.9847 2.0512 11.9513 1.97055C11.9178 1.8899 11.8688 1.81664 11.807 1.75496L10.247 0.194963C10.1853 0.13316 10.1121 0.0841285 10.0314 0.0506742C9.95077 0.0172199 9.86431 0 9.777 0C9.68969 0 9.60323 0.0172199 9.52258 0.0506742C9.44194 0.0841285 9.36868 0.13316 9.307 0.194963L8.087 1.41496L10.587 3.91496L11.807 2.69496Z"
        fill="#12C4C4"
      />
    </svg>
  )
}

export default SignupIconTop
