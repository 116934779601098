import { SwitchLanguagesTop } from '../../modules/switch-languages-top'

interface MobileHeaderProps {}

const MobileHeader: React.FC<MobileHeaderProps> = () => {
  return (
    <div className="mobile-content__header">
      <SwitchLanguagesTop />
    </div>
  )
}

export default MobileHeader
