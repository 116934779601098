import * as React from 'react';

export default class Bell extends React.PureComponent<any, {}> {
    render() {
        const { width, height, className } = this.props;
        return (
            <svg className={className || ''} width={width || "20"} height={height || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.80078 18.0672C8.74028 18.0672 7.74728 18.043 6.81128 18C6.81128 18.0227 6.80078 18.044 6.80078 18.0672C6.80078 18.5798 7.11685 19.0714 7.67946 19.4339C8.24207 19.7964 9.00513 20 9.80078 20C10.5964 20 11.3595 19.7964 11.9221 19.4339C12.4847 19.0714 12.8008 18.5798 12.8008 18.0672C12.8008 18.044 12.7918 18.0227 12.7903 18C11.8543 18.043 10.8613 18.0672 9.80078 18.0672Z" fill="white" />
                <path d="M16.4184 9.18915V6.49792C16.3446 4.75848 15.6167 3.11431 14.3852 1.90504C13.1537 0.695777 11.5125 0.0138043 9.80078 0C8.08245 0.0283831 6.43983 0.724029 5.20948 1.94441C3.97913 3.16479 3.2542 4.81752 3.18321 6.56396V9.18915C3.18321 12.1426 0.800781 12.6683 0.800781 14.3088C0.800781 15.8185 4.30821 17 9.80078 17C15.2934 17 18.8008 15.8185 18.8008 14.3088C18.8008 12.6683 16.4184 12.1426 16.4184 9.18915Z" fill="white" />
            </svg>
        );
    }
}