import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  EMAIL_PATTERN,
  FORGOT_PASSWORD_PATTERN,
} from '../../../../../../utils/const'

export const radioFields = [
  {
    name: 'sex',
    type: 'radio',
    label: 'account_setting.sex_male',
    value: 'male',
    isRequire: true,
  },
  {
    name: 'sex',
    type: 'radio',
    label: 'account_setting.sex_female',
    value: 'female',
    isRequire: true,
  },
]

export const nameFields = [
  {
    name: 'user_name',
    type: 'text',
    isRequire: true,
    placeholder: 'account_setting.user_name',
    title: 'User name',
    isForcus: true,
  },
]

export const emailFields = [
  {
    name: 'email',
    type: 'email',
    //label: 'account_setting.email_label',
    isRequire: true,
    placeholder: 'account_setting.email_address',
    title: 'Mail Address',
    isForcus: true,
  },
]

export const passwordFields = [
  {
    name: 'old_password',
    type: 'password',
    label: 'password_modal.current',
    isRequire: true,
    placeholder: 'password_modal.placeholder_current',
    errorApi: '',
  },
  {
    name: 'new_password',
    type: 'password',
    label: 'password_modal.new',
    isRequire: true,
    placeholder: 'password_modal.placeholder_new',
    errorApi: '',
  },
  {
    name: 'confirm_password',
    type: 'password',
    label: 'password_modal.confirm',
    isRequire: true,
    placeholder: 'password_modal.placeholder_confirm',
    errorApi: '',
  },
]

export const validatorMailAndSex = (t: any) => {
  return yupResolver(
    Yup.object().shape({
      sex: Yup.string().required(),
      email: Yup.string()
        .required(t('account_setting.email_required'))
        .matches(EMAIL_PATTERN, t('account_setting.invalid_email'))
        .notOneOf([''], t('account_setting.email_required')),
    })
  )
}

export const validatorChangePassword = (t: any) => {
  return yupResolver(
    Yup.object().shape({
      old_password: Yup.string().required(t('password_modal.error_current_password')),
      new_password: Yup.string()
        .when('old_password', {
          is: (old_password: any) => !!old_password,
          then: Yup.string().notOneOf([Yup.ref('old_password')], t('password_modal.error_newpass')),
        })
        .required(t('password_modal.error_new_password'))
        .matches(FORGOT_PASSWORD_PATTERN, t('validations.pass_new'))
        .max(32, t('password_modal.error_password_max')),
      confirm_password: Yup.string()
        .required(t('password_modal.error_confirm_password'))
        .oneOf([Yup.ref('new_password'), ''], t('password_modal.error_not_equal_new')),
    })
  )
}

export const defaultValues = {
  email: '',
  sex: '',
  password: '',
  confirm: '',
}

export const passwordChangeDefault = {
  old_password: '',
  new_password: '',
  confirm_password: '',
}
