import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SearchIcon } from '../../../../../assets/images/svgs'
import { ROUTER_URL } from '../../../../../utils/const'
import { IApplicationState } from '../../../../stores'
import Wrapper from '../../../components/modules/_wrapper'

interface SitePolicyProps {}



const SitePolicy: React.FC<SitePolicyProps> = () => {
  const [t] = useTranslation('translation')

  const isSignedIn = useSelector((state: IApplicationState) => state.signin.isSignedIn)

  function getTrans(text: string){
    return (

    <Trans 
    i18nKey={text}
    components={{
      br: <br />,
    }}
    />
    )
  }

	const title = '特定商取引法表示 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
	const description = '特定商取引法表示のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
	const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

	// title
	document.title = title;

	// title以外のmeta
	const headData = document.head.children;

	for (let i = 0; i < headData.length; i++) {
		const nameVal = headData[i].getAttribute('name');
		const propertyVal = headData[i].getAttribute('property');
		if (nameVal !== null) {
			if (nameVal.indexOf('keywords') !== -1) {
				headData[i].setAttribute('content', keywords);
			}
			if (nameVal.indexOf('description') !== -1) {
				headData[i].setAttribute('content', description);
			}
		}
		if (propertyVal !== null) {
			// OGP(og:title)の設定
			if (propertyVal.indexOf('og:title') !== -1) {
				headData[i].setAttribute('content', title);
			}
			if (propertyVal.indexOf('og:description') !== -1) {
				headData[i].setAttribute('content', description);
			}
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
		}
	}
  
  const list = [
    {
      name: t('policy.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('policy.site_policy.breadcrum'),
      link: ROUTER_URL.COMMERCE,
    },
  ]
  return (
    <Wrapper
      breadcrum={list}
      title={t('policy.site_policy.title')}
      buttonText={t(`policy.site_policy.button${isSignedIn ? '2' : ''}`)}
      buttonLink={isSignedIn ? ROUTER_URL.SCAN_BOOKING : ROUTER_URL.HOME}
      Icon={SearchIcon}
      className="policy"
      classNameBtn={'normal'}
    >
      <div className="policy distance-line-to-content distance-content-to-button">
        <div className="policy__lead">
        	<p>{getTrans(t('policy.site_policy.sub_title'))}</p>
				</div>
        
				<div className="policy__sec">
					<h2 className="policy__label">{getTrans(t('policy.site_policy.describe.article_1.title'))}</h2>
					<div className="policy__content">
						<p>{getTrans(t('policy.site_policy.describe.article_1.des'))}</p>
					</div>
				</div>
				
				<div className="policy__sec">
					<h2 className="policy__label">{getTrans(t('policy.site_policy.describe.article_2.title'))}</h2>
					<div className="policy__content">
						<p>{getTrans(t('policy.site_policy.describe.article_2.des'))}</p>
					</div>
				</div>


				<div className="policy__sec">
					<h2 className="policy__label">{getTrans(t('policy.site_policy.table1.title'))}</h2>
					<div className="policy__content">
            <table className="policy__table is-fixed">
            	<thead>
	              <tr>
	                <th >{getTrans(t('policy.site_policy.table1.row_1_col_1'))}</th>
	                <th>{getTrans(t('policy.site_policy.table1.row_1_col_2'))}</th>
	              </tr>
            	</thead>
            	<tbody>
	              <tr>
	                <td>{getTrans(t('policy.site_policy.table1.row_2_col_1'))}</td>
	                <td>{getTrans(t('policy.site_policy.table1.row_2_col_2'))}</td>
	              </tr>
            	</tbody>
            </table>
					</div>
				</div>


				<div className="policy__sec">
					<h2 className="policy__label">{getTrans(t('policy.site_policy.describe.article_3.title'))}</h2>
					<div className="policy__content">
						<p>{getTrans(t('policy.site_policy.describe.article_3.des'))}</p>
					</div>
				</div>


				<div className="policy__sec">
					<h2 className="policy__label">{getTrans(t('policy.site_policy.table2.title'))}</h2>
					<div className="policy__content">
            <table className="policy__table is-fixed">
            	<thead>
	              <tr>
	                <th >{getTrans(t('policy.site_policy.table2.row_1_col_1'))}</th>
	                <th>{getTrans(t('policy.site_policy.table2.row_1_col_2'))}</th>
	              </tr>
            	</thead>
            	<tbody>
	              <tr>
	                <td>{getTrans(t('policy.site_policy.table2.row_2_col_1'))}</td>
	                <td>{getTrans(t('policy.site_policy.table2.row_2_col_2'))}</td>
	              </tr>
            	</tbody>
            </table>
					</div>
				</div>


				<div className="policy__sec">
					<h2 className="policy__label">{getTrans(t('policy.site_policy.describe.article_4.title'))}</h2>
					<div className="policy__content">
						<p>{getTrans(t('policy.site_policy.describe.article_4.des'))}</p>
					</div>
				</div>


				<div className="policy__sec">
					<h2 className="policy__label">{getTrans(t('policy.site_policy.describe.article_5.title'))}</h2>
					<div className="policy__content">
						<p>{getTrans(t('policy.site_policy.describe.article_5.des'))}</p>
					</div>
				</div>


				<div className="policy__sec">
					<h2 className="policy__label">{getTrans(t('policy.site_policy.describe.article_6.title'))}</h2>
					<div className="policy__content">
						<p>{getTrans(t('policy.site_policy.describe.article_6.des'))}</p>
					</div>
				</div>

				<div className="policy__sec">
					<h2 className="policy__label">{getTrans(t('policy.site_policy.describe.article_7.title'))}</h2>
					<div className="policy__content">
						<p>{getTrans(t('policy.site_policy.describe.article_7.des'))}</p>
					</div>
				</div>

				<div className="policy__sec">
					<h2 className="policy__label">{getTrans(t('policy.site_policy.describe.article_8.title'))}</h2>
					<div className="policy__content">
						<p>{getTrans(t('policy.site_policy.describe.article_8.des'))} <br/>{getTrans(t('policy.site_policy.describe.article_8.phone'))}<a href="tel:0364279027">03-6427-9027</a></p>
						<p>{getTrans(t('policy.site_policy.describe.article_8.sub_des_1', {
                  add: `"confirming your identity"`,
                }))}
            </p>
            <p>
            {getTrans(t('policy.site_policy.describe.article_8.sub_des_2', {
                  add: `"confirming your identity"`,
                }))}
            </p>
            <p>
            {getTrans(t('policy.site_policy.describe.article_8.sub_des_3', {
                  add: `"confirming your identity"`,
                }))}
						</p>
					</div>
				</div>

        
      </div>
    </Wrapper>
  )
}
export default SitePolicy
