import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NewsBannerBackground, NewsBannerIcon } from '../../../../assets/images'
import NewsGridComponent from './news-grid-component'
import { useHistory } from 'react-router-dom'
import useWindowSize from '../../../../customize/hooks/useWindowSize'
import { NEWS_LIST } from '../../../../utils/endpoints'
import Wrapper from '../../components/modules/_wrapper'
import { ERROR_TYPE, GET, ROUTER_URL } from '../../../../utils/const'
interface NewsProps {
  notify?: Function
  callApi: any
}

const title = '記事タイトル記事タイトル記事タイト記事'
const content =
  '通知内容入ります通知内容入ります通知内容入ります通知内容入ります通知内容入ります通知内容入ります通知内容入ります通知内容入り通知内容入ります通知内容入ります通知内容入ります通知内容入ります通知内容入ります通知内容入ります通知内容入ります通知内容入り'
const currentDate = '2021/20/11'

export const fakeData = [
  { id: 0, date: currentDate, title: title, content: '0' + content },
  { id: 1, date: currentDate, title: title, content: '1' + content },
  { id: 2, date: currentDate, title: title, content: '2' + content },
  { id: 3, date: currentDate, title: title, content: '3' + content },
  { id: 4, date: currentDate, title: title, content: '4' + content },
  { id: 5, date: currentDate, title: title, content: '5' + content },
  { id: 6, date: currentDate, title: title, content: '6' + content },
  { id: 7, date: currentDate, title: title, content: '7' + content },
  { id: 8, date: currentDate, title: title, content: '8' + content },
  { id: 9, date: currentDate, title: title, content: '9' + content },
]

const News: React.FC<NewsProps> = ({ notify = () => {}, callApi }) => {

  const title = 'ニュース | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'ニュースのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [, i18n] = useTranslation('translation')
  const history = useHistory()
  const screenSize = useWindowSize()
  const tabletSize = 768
  const [listNews, setListNews] = useState([])
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const access_token = urlParams.get('access_token') ?? ''
    const refresh_token = urlParams.get('refresh_token') ?? ''
    if (access_token) {
      localStorage.setItem('access_token', access_token)
      window.location.replace('/')
    }
    if (refresh_token) {
      localStorage.setItem('refresh_token', refresh_token)
    }
  }, [])

  useEffect(() => {
    const getListNew = async () => {
      const params = {
        page: 1,
        per_page: 10,
      }
      try {
        const result = await callApi(NEWS_LIST, GET, { params })

        if (result.status === 200) {
          setListNews(result.data.data.list_news)
        }
      } catch (error) {
        notify(error?.message, ERROR_TYPE)
      }
    }
    getListNew()
  }, [localStorage.getItem('i18nextLng')])

  const list = [
    {
      name: i18n.t('news.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('news.news-list'),
      link: ROUTER_URL.NEWS_DETAIL,
    },
  ]

  const renderBannerArea = (
    <div className="news__banner-area">
      <div className="news__banner-wrapper">
        <div className="news__banner-background">
          {screenSize.width > tabletSize ? (
            <NewsBannerBackground />
          ) : (
            <NewsBannerBackground height="60" width="251" />
          )}
        </div>
        <div className="news__banner-content">
          <NewsBannerIcon height="20" width="20" />
          <div className="news__banner-title">NEWS</div>
        </div>
      </div>
    </div>
  )
  const onDirect = (id: any) => () => {
    if (window.navigator.onLine) history.push(`${ROUTER_URL.NEWS}/${id}`)
    else {
      notify(i18n.t('notifications.err_network'), ERROR_TYPE)
    }
  }

  const renderContainer = (
    <div className="news__container">
      <div className="news__content">
        {listNews?.map((item) => (
          <NewsGridComponent
            key={item['id']}
            id={item['id']}
            date={item['display_from_date']}
            title={item['title']}
            content={item['content']}
            thumbnail={item['thumbnail']}
            onDirect={onDirect(item['id'])}
          />
        ))}
      </div>
    </div>
  )

  return (
    <Wrapper className="news" breadcrum={list}>
      {renderBannerArea}
      {renderContainer}
    </Wrapper>
  )
}

export default News
