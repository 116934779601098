export const FORGOT_PASSWORD_PATTERN =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])[\w~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]{8,}$/
export const PASSWORD_PATTERN = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/

export const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const TOKEN_PATTERN = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/

export const NONE_AUTH = 0
export const REQUIRED_LOGIN = 1
export const NONE_LOGIN = 2

export const ERROR_TYPE = 'error'

export const ROUTER_URL = {
  HOME: '/',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  SIGNUP_CONFIRM: '/signup-confirm',
  NEWS_DETAIL: '/news/:id',
  NEWS: '/news',
  SCAN_BOOKING: '/scan-booking',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  USER_STATUS: '/user-status',
  ABOUT_USER_STATUS: '/about-user-status',
  ABOUT_USER_STATUS_FOR_ANDROID: '/about-user-status-android',
  ABOUT_USER_STATUS_FOR_IOS: '/about-user-status-ios',
  EDIT_USER: '/edit-user',
  CONTROL_AVATAR_DETAIL: '/control-avatar/:model_type/:id',
  CONTROL_AVATAR: '/control-avatar',
  CONTROL_EXPORT: '/control-export',
  EXPORT_COMPLETE: '/export-complete',
  REGIST_CLUSTER_COMPLETE: '/regist-cluster-complete',
  EXPORT_AVATAR: '/export-avatar',
  DOWNLOAD_AVATAR: '/download-avatar',
  NOTIFICATIONS: '/notifications',
  DETAIL_NOTIFICATION: '/detail-notification',
  TERM_OF_SERVICE: '/term-of-service',
  TERM_OF_SERVICE_MOBILE: '/term-of-service-mobile',
  COMMERCE: '/commerce',
  WITHDRAW: '/withdraw',
  MANAGE_CREDIT: '/manage-credit',
  SCANNER: '/scanner',
  ADD_CREADIT: '/add-creadit',
  CONTROL_AVATAR_EDITED: '/control-avatar-edited',
  DOWNLOAD_AVATAR_EDITED: '/download-avatar-edited',
  BOOKING_ATTENTION: '/booking-attention',
  FRIENDS: '/friends',
  PRIVACY_POLICY: '/privacy-policy',
  PRIVACY_POLICY_MOBILE: '/privacy-policy-mobile',
  CONTACT: '/contact',
  ACTIVE_EMAIL: '/active-email',
  ACTIVE_EMAIL_SUCCESS: '/active-email-success',
  ACTIVE_EMAIL_ERR: '/active-email-err',
  CHANGE_EMAIL: '/change-email',
  SIGNUP_SUCCESS: '/signup-success',
  SIGNUP_ERROR: '/signup-error',
  SIGNUP_MOBILE: '/signup-mobile',
  SIGNUP_CONFIRM_MOBILE: '/signup-confirm-mobile',
  CONFIRM_EMAIL: '/confirm-email/:key',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_EXPIRED: '/reset-password-expired',
  FORGOT_PASSWORD_NOTIFICATION: '/forgot-password-notification',
  CHECK_RESET_PASSWORD: '/check-reset-password',
  RESET_PASSWORD_SUCCESS: '/reset-password-success',
  WITHDRAW_NOTI: '/withdraw-noti',
  RE_DOWNLOAD: '/re-download',
  IMAGE: '/image',
  HELP_INDEX : '/help',
  HELP_ABOUT_AVATARIUM : '/help-about-avatarium',
  HELP_QA : '/help-qa',
  HELP_HOWTO : '/help-howto',
  HELP_FLOW_SCANNER : '/help-flow-scanner',
  HELP_FLOW_SCANNER_MOBILE : '/help-flow-scanner-mobile',
  HELP_EXPORT : '/help-export',
  HELP_BOOKING_ATTENTION :'/help-booking-attention',
  PROVISIONAL_USER: '/provisional-user',
  PROVISIONAL_USER_CONFIRM: '/provisional-user-confirm/:key',
}

export const GET = 'get'
export const POST = 'post'
export const DELETE = 'delete'

export const PUBLIC = 'public'
export const PRIVATE = 'private'

export const SUCCESS = 'success'
export const ERROR = 'error'

export const ORIGINAL = 'original'
export const EDITED = 'edited'
export const SCANNER = 'scanner'
export const MOBILE = 'mobile'
export const EDITABLE = 'Editable'

export const MAX_AVATAR_COUNT = 20

export const APP_STORE_URL = 'https://apps.apple.com/jp/app/id1553869276?mt=8'
export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.pocketrd.avatarium'


export const TABLET_MIN_SIZE: Number = 768