import { Button } from '../../atoms/buttons'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'
import moment from 'moment'
import { Breadcrumb } from './../../atoms/breadcrumb/index'
import { ROUTER_URL } from '../../../../../utils/const'
interface WrapperProps {
  breadcrum: any[]
  Icon?: any
  title?: string
  describe?: string
  buttonText?: string
  buttonLink?: string
  className?: string
  state?: any
  subTitle?: string
  classNameBtn?: string
  typeBreadcrums?: number
  type?: string
  isNoMarginTop?: boolean
}

const Wrapper: React.FC<WrapperProps> = ({
  breadcrum,
  Icon,
  title,
  buttonText,
  children,
  buttonLink,
  className,
  state,
  subTitle,
  classNameBtn,
  typeBreadcrums,
  type,
  isNoMarginTop,
}) => {
  const history = useHistory()

  function onRedirect() {
    history.push({ pathname: buttonLink || ROUTER_URL.HOME, state })
  }

  return (
    <div className={cx('wrapper default-padding', className)}>
      <Breadcrumb crumbs={breadcrum} />
      {title && (
        <div className="wrapper__header">
          <div
            className={cx(
              'wrapper__header__title ',
              type === 'dropdown' ? 'distance-h1-to-dropdown' : 'distance-h1-to-line'
            )}
          >
            {Icon && <Icon width={28} height={28} />}
            <h1 className={Icon ? 'title-h1 distance-h1-to-icon' : 'title-h1'}>{title || ''}</h1>
          </div>
          {subTitle && (
            <div className="wrapper__header__sub distance-wrapper-to-line">
              {localStorage.getItem('i18nextLng') === 'ja'
                ? moment(new Date(subTitle).toISOString()).format('YYYY年MM月DD日 HH:mm')
                : moment(new Date(subTitle).toISOString()).format('MMM DD, YYYY hh:mma')}
            </div>
          )}
        </div>
      )}
      <div
        className={
          //isNoMarginTop ? 'wrapper__content' : 'wrapper__content distance-line-to-content '
          isNoMarginTop ? 'wrapper__content  distance-line-to-content' : 'wrapper__content'
          //'wrapper__content distance-line-to-content'
        }
      >
        {children}
      </div>
      {buttonText && (
        <div className="wrapper__button ">
          <Button onClick={onRedirect} className={classNameBtn}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  )
}
export default Wrapper
