import React from 'react'

interface FourDotsIconSmallProps {
  height?: string
  width?: string
}

const FourDotsIconSmall: React.FC<FourDotsIconSmallProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "25"} height={height || "25"} viewBox="0 0 25 25">
      <defs>
        <style>{'.cls-1902{fill:#12c4c4}.cls-1903{fill:#ff6c6c}'}</style>
      </defs>
      <g id="icon_dots_m" transform="translate(-542 -953)">
        <circle
          id="Ellipse_10"
          cx="4"
          cy="4"
          r="4"
          className="cls-1902"
          transform="translate(542 953)"
        />
        <circle
          id="Ellipse_13"
          cx="4"
          cy="4"
          r="4"
          className="cls-1902"
          transform="translate(559 970)"
        />
        <circle
          id="Ellipse_11"
          cx="4"
          cy="4"
          r="4"
          className="cls-1903"
          transform="translate(559 953)"
        />
        <circle
          id="Ellipse_12"
          cx="4"
          cy="4"
          r="4"
          className="cls-1903"
          transform="translate(542 970)"
        />
      </g>
    </svg>
  )
}

export default FourDotsIconSmall
