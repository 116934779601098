import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconHair8: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.9981 35.0078C63.1353 23.435 55.8686 13.5487 45.9005 11.5942C41.3422 7.77991 33.0119 8.68461 26.6362 11.026C16.1504 14.8765 11.2673 23.3876 10.0553 35.0078C9.04616 44.6795 22.0578 54.6767 32.1269 60.5835C32.9342 61.057 33.6709 61.6353 34.4348 62.1612C35.0991 62.5247 35.8476 62.7073 36.605 62.6907C37.3624 62.6741 38.1021 62.4588 38.7498 62.0665C39.3934 61.5285 40.0867 61.0528 40.8205 60.6459C50.9007 54.7663 62.882 44.7712 62.9981 35.0078Z"
        fill="#111947"
      />
      <path
        d="M55.5952 33.8031C54.0673 21.5885 46.7693 14.7871 36.563 14.7871C26.3567 14.7871 19.0648 21.5885 17.537 33.8031C13.1372 33.9976 11.9727 37.1066 11.9727 41.1677C11.9727 45.2289 16.4834 49.8209 17.979 50.0335C18.5956 50.1231 19.1435 50.1806 19.6945 50.2279C23.2507 58.3078 29.9876 64.0001 35.5539 64.0001H37.6297C43.189 64.0001 49.9279 58.3078 53.4841 50.2279C54.0324 50.2001 54.5783 50.1352 55.1178 50.0335C56.6093 49.8229 61.109 45.378 61.109 41.1486C61.109 36.9192 59.9788 33.9986 55.5952 33.8031Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M30.3224 44.5078C31.9955 44.5078 33.3518 43.1538 33.3518 41.4834C33.3518 39.8131 31.9955 38.459 30.3224 38.459C28.6493 38.459 27.293 39.8131 27.293 41.4834C27.293 43.1538 28.6493 44.5078 30.3224 44.5078Z"
        fill="#111947"
      />
      <path
        d="M43.6075 44.5078C45.2806 44.5078 46.6369 43.1538 46.6369 41.4834C46.6369 39.8131 45.2806 38.459 43.6075 38.459C41.9344 38.459 40.5781 39.8131 40.5781 41.4834C40.5781 43.1538 41.9344 44.5078 43.6075 44.5078Z"
        fill="#111947"
      />
      <path
        d="M54.6945 35.0077C51.6802 33.4663 44.9413 24.7819 43.2187 18.9949C40.5839 22.4566 25.3037 34.9231 18.2227 34.9231C18.8554 32.2926 18.1439 14.276 36.2739 11.6978C47.1018 10.1624 59.8269 25.5022 54.6945 35.0077Z"
        fill="#111947"
      />
    </svg>
  )
}

export default IconHair8
