import React from 'react'

interface AAndVProps {
  height?: string
  width?: string
}

const AAndV: React.FC<AAndVProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "170"} height={height || "160"} viewBox="0 0 170 160">
    <defs>
        <style>
            {".cls-1{fill:#12c4c4}"}
        </style>
    </defs>
    <g id="アートワーク_5" transform="translate(85 80)">
        <g id="アートワーク_5-2" transform="translate(-85 -80)">
            <path id="Path_287" d="M53.2 0L0 120h1.1L53.9 1h15V0z" className="cls-1"/>
            <path id="Path_288" d="M68.6 110.8H36.1l-3.5 9.2h1.1l3.1-8.2h31.8z" className="cls-1"/>
            <path id="Path_289" d="M68.6 87.2v-1h-21l21-50.8h-1.1L46.1 87.2z" className="cls-1"/>
            <path id="Path_290" d="M168.9 95.3l-28 64.7h1.1l28-64.7z" className="cls-1"/>
            <path id="Path_291" d="M91.6 82l25.8 60.6 44-101.8h-1.1L117.4 140 92.7 82z" className="cls-1"/>
        </g>
    </g>
</svg>

  )
}

export default AAndV
