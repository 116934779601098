import { useTranslation } from 'react-i18next'
import cx from 'classnames'

interface ButtonCustomProps {
  className?: any
  onClick?: any
  children?: any
  disabled?: boolean
  type?: any
  style?: any
}
const ButtonCustom: React.FC<ButtonCustomProps> = ({
  className,
  onClick,
  children,
  disabled,
  type,
  style,
}) => {
  const { t } = useTranslation()
  return (
    <button
      className={cx('button-custom', className)}
      onClick={onClick}
      disabled={disabled}
      type={type || 'button'}
      style={style}
    >
      {children?.type === 'string' ? t(children) : children}
    </button>
  )
}

export { ButtonCustom }
