import { useEffect } from 'react'
import { GET_IS_VALUE_TOKEN } from '../../../../utils/endpoints'
import { useHistory } from 'react-router-dom'
import MoonLoader from 'react-spinners/MoonLoader'
import { GET, ROUTER_URL } from '../../../../utils/const'
interface ConfirmEmailProps {
  callApi: any
}

const CheckResetPassword: React.FC<ConfirmEmailProps> = ({ callApi }) => {
  const history = useHistory()
  const url_string = window.location.href
  const url = new URL(url_string)
  const token = url.searchParams.get('key')
  useEffect(() => {
    const params = {
      token: token,
    }
    const getCheckReset = async () => {
      try {
        const result = await callApi(GET_IS_VALUE_TOKEN, GET, { params }, false)
        if (result) {
          const switchURL = result.data.data
          if (switchURL) {
            history.push(`${ROUTER_URL.RESET_PASSWORD}?key=${token}`)
          }
        }
      } catch (error) {
        history.push(ROUTER_URL.RESET_PASSWORD_EXPIRED)
      }
    }
    getCheckReset()
  }, [])
  return (
    <div
      className="default-padding"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <MoonLoader size={200} loading={true} color={'#12c4c4'} />
    </div>
  )
}

export default CheckResetPassword
