export const selectStyles = {
  control: (base: any) => {
    return {
      ...base,
      // This line disable the blue border
      borderColor: '#CCCCCC',
      borderWidth: 1,
      borderRadius: 5,
      minHeight: 45,
      boxShadow: 0,
      fontFamily: 'Roboto',
      fontSize: 14,
    }
  },
  menu: (base: any) => ({
    ...base,
    fontFamily: 'Roboto',
    overflow: 'hidden',
  }),
}

export const styler = (isError = false, point: any, height?: number | string) => {
  let minHeight: number
  let width: number
  if (!point) {
    minHeight = Number(height) || 38
    width = 300
  } else if (point === 'mobiles') {
    minHeight = Number(height) || 48
    width = 345
  } else {
    minHeight = Number(height) || 48
    width = 500
  }
  return {
    control: (base: any, { isDisabled }: any) => ({
      ...base,
      // This line disable the blue border
      borderColor: !isError ? '#9f9f9f' : 'red',
      borderWidth: 1,
      borderRadius: 5,
      minHeight,
      boxShadow: 0,
      fontFamily: 'Roboto',
      fontSize: 14,
      paddingLeft: '0.8rem',
      width,
      cursor: isDisabled ? 'pointer' : 'default',
    }),
    menu: (base: any) => ({
      ...base,
      fontFamily: 'Roboto',
      fontSize: 14,
      width,
      color: '#aaaaaa',
      overflow: 'hidden',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#aaaaaa',
    }),
    singleValue: (base: any) => ({
      ...base,
      color: '#111947',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: '#111947',
    }),
    option: (styles: any, { isFocused }: any) => ({
      ...styles,
      backgroundColor: isFocused ? '#12C4C4' : '#F1F3F8',
      color: isFocused ? '#ffffff' : '#111947',
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    valueContainer: (base: any) => ({
      ...base,
      paddingLeft: 5,
    }),
  }
}
