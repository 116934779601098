import GoogleMapReact from 'google-map-react'
import { MarkerIcon } from '../../../../../assets/images/svgs'

interface MapsProps {
  lat?: any
  lng?: any
}

const MAX_LATITUDE = 90
const MAX_LONGITUDE = 180

const AnyReactComponent: React.FC<MapsProps> = ({ lat, lng }) => <MarkerIcon />

export const Maps = ({ lat, lng }: MapsProps) => {
  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDIJ2dnu63Y5XNuF99i-75ECtAAH6n_sM4' /* YOUR KEY HERE */ }}
        center={{
          lat: Number.isNaN(lat * 1) || lat > MAX_LATITUDE || lat < MAX_LATITUDE * -1 ? 0 : lat * 1,
          lng:
            Number.isNaN(lng * 1) || lng > MAX_LONGITUDE || lng < MAX_LONGITUDE * -1 ? 0 : lng * 1,
        }}
        defaultZoom={15}
      >
        {!(
          Number.isNaN(lat * 1) ||
          lat > MAX_LATITUDE ||
          lat < MAX_LATITUDE * -1 ||
          Number.isNaN(lng * 1) ||
          lng > MAX_LONGITUDE ||
          lng < MAX_LONGITUDE * -1
        ) && <AnyReactComponent lat={lat * 1} lng={lng * 1} />}
      </GoogleMapReact>
    </>
  )
}
