import { useState, useEffect } from 'react'
import { TextInput } from '../../components/atoms/text-input'
import { SelectInput3 } from '../../components/atoms/select-input-3'
import { Textarea } from '../../components/atoms//textarea'
import { CheckboxInput } from '../../components/atoms/checkbox-input'
import { MailIcon } from '../../../../assets/images'
import { useForm, FormProvider } from 'react-hook-form'
import { textFields, defaultValues } from './constants'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useYupValidationResolver } from '../signup/constants'
import * as Yup from 'yup'
import { SEND_CONTACT } from '../../../../utils/endpoints'
import { Button } from '../../components/atoms/buttons'
import { useSelector } from 'react-redux'
import Wrapper from '../../components/modules/_wrapper'
import { EMAIL_PATTERN, POST, ROUTER_URL } from '../../../../utils/const'

interface ContactProps {
  breadcrum: any[]
  title: string
  describe?: string
  buttonText: string
  buttonLink?: string
  notify: Function
  notifyModal: Function
  callApi: any
}

const Contact: React.FC<ContactProps> = ({ notify, notifyModal, callApi }) => {

  const title = '問い合わせフォーム | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = '問い合わせフォームのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [t] = useTranslation('translation')

  const [isLogin, setIsLogin] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false)

  const lang = useSelector((state: any) => state.signin.lang)

  useEffect(() => {
    onInit()
  }, [])

  async function onInit() {
    const isLogin = localStorage.getItem('access_token')
    setIsLogin(!!isLogin)
  }

  useEffect(() => {
    if (isFirstLoad) {
      clearErrors()
      handleSubmit(onSubmit)()
    }
  }, [lang])

  const options = [
    { value: '001', name: 'item', label: t('contact.item1') },
    { value: '002', name: 'item', label: t('contact.item2') },
    { value: '003', name: 'item', label: t('contact.item3') },
    { value: '004', name: 'item', label: t('contact.item4') },
    { value: '005', name: 'item', label: t('contact.item5') },
  ]

  const validate: any = {
    name: Yup.string()
      .max(20, t('validations.user_name_max'))
      .required(t('validations.user_name'))
      .trim(),
    email: Yup.string()
      .notOneOf([''], t('validations.email_require'))
      .matches(EMAIL_PATTERN, t('validations.email_valid'))
      .required(t('validations.email_require')),
    itemRequired: Yup.object().nullable().required(t('validations.item_require')),
    contentRequired: Yup.string()
      .max(600, t('validations.content_max'))
      .required(t('validations.content_required')),
    accept: Yup.bool().oneOf([true], t('contact.contact_validate_accept')),
  }

  const methods = useForm<any>({
    defaultValues,
    resolver: useYupValidationResolver(
      Yup.object(
        isLogin
          ? {
              itemRequired: validate.itemRequired,
              contentRequired: validate.contentRequired,
              accept: validate.accept,
            }
          : { ...validate }
      )
    ),
  })

  const { handleSubmit, errors, reset, clearErrors, getValues, setValue } = methods

  const list = [
    {
      name: t('contact.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('contact.breadcrum'),
      link: ROUTER_URL.CONTACT,
    },
  ]

  const onSubmit = async (data: any) => {
    if (isLoading) return
    setIsLoading(true)
    const {
      contentRequired: contact_content,
      email,
      itemRequired: { value: contact_type },
      name: customer_name,
    } = data

    const payload = { contact_content, email, contact_type, customer_name }
    if (isLogin) {
      delete payload.email
      delete payload.customer_name
    }
    try {
      const result = await callApi(SEND_CONTACT, POST, payload)
      const message = result?.data?.message
      if (message) {
        notifyModal({
          title: t(
            isLogin ? 'contact.modal_success.title' : 'contact.modal_success.title_without_login'
          ),
          content: t(
            isLogin
              ? 'contact.modal_success.content'
              : 'contact.modal_success.content_without_login'
          ),
        })
        reset()
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      const err = error?.message
      if (err) notify(err, 'error')
    }
  }

  function onValidate() {
    setIsFirstLoad(true)

    const listField = ['name', 'email', 'contentRequired']
    listField.forEach((item: any) => {
      const value = getValues(item) + ''
      setValue(item, value.trim() || '')
    })
    handleSubmit(onSubmit)()
  }

  return (
    <Wrapper
      title={t('contact.title')}
      breadcrum={list}
      className="contact-form"
      Icon={MailIcon}
      isNoMarginTop={true}
    >
      <FormProvider {...methods}>
        <form>
          <div className="contact__describe">
            {!isLogin &&
              textFields.map(({ name, type, isRequire, placeholder, label }) => (
                <div
                  className={classnames('form-item distance-input-to-h4', {
                    isRequired: errors?.[name],
                  })}
                  key={name}
                >
                  <TextInput
                    key={name}
                    type={type}
                    name={name}
                    isRequire={isRequire}
                    placeholder={t(placeholder)}
                    label={t(label)}
                  />
                </div>
              ))}
            <div className={classnames('form-item', { isRequired: errors?.['itemRequired'] })}>
              <div className="input__label--wrapper">
                <p className="input__label">{t('contact.item_required_label')}</p>
                <span className="input__asterisk">*</span>
              </div>

              <SelectInput3
                name="itemRequired"
                placeholder={t('contact.item_required_placeholder')}
                options={options}
              />
            </div>
            <div
              className={classnames('form-item distance-form-to-text', {
                isRequired: errors?.['itemRequired'],
              })}
            >
              <Textarea
                placeholder={t('contact.content_required_placeholder')}
                name="contentRequired"
                isRequire
                isFull
                rows={4}
                label={t('contact.content_required_placeholder')}
              />
            </div>
          </div>
          <div className="contact__form--footer__button">
            <p className="contact__message">
              {t('contact.message1')}
              <a href={ROUTER_URL.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer" className="text-link">
                {t('contact.message2')}
              </a>
              {t('contact.message3')}
            </p>
            <div className="contact__checkbox">
              <div className="contact__checkbox-label ">
                <CheckboxInput label={t('contact.message4')} name="accept" />
              </div>
            </div>

            <Button type="button" onClick={onValidate}>
              {t('contact.button')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Wrapper>
  )
}
export default Contact
