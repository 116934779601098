import { AnyAction, Reducer } from 'redux';
import { initialTabState , TabBookingState } from '../states';
import { TabTypes } from '../actions';

export const TabReducer: Reducer = (state: TabBookingState = initialTabState, action: AnyAction) => {
    switch (action.type) {
        case TabTypes.CHECK_TAB: {
            return {
                ...state,
                message: action.payload,
            };
        }
        default:
            return state;
    }
};