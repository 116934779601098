import React from 'react'

interface LinesContentProps {
  height?: string
  width?: string
}

const LinesContent: React.FC<LinesContentProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="240" height="187" viewBox="0 0 240 187">
      <defs>
        <clipPath id="clipPath">
          <path fill="none" d="M0 0H240V187H0z" />
        </clipPath>
        <style>{'.cls-3,.cls-4{fill:#12c4c4}.cls-4{opacity:.5;isolation:isolate}'}</style>
      </defs>
      <g id="LINES1" clipPath="url(#clipPath)">
        <g id="LINES1-2">
          <g id="Group_10638" transform="translate(-40)">
            <path
              id="長方形_356"
              d="M0 0H3V3H0z"
              className="cls-3"
              transform="translate(277 184)"
            />
            <path
              id="長方形_356-2"
              d="M0 0H3V3H0z"
              className="cls-3"
              transform="translate(277 136)"
            />
            <path id="Path_776" d="M0 1.143L277.779 138l.442-.9L0 0z" className="cls-4" />
            <path
              id="Path_777"
              d="M197.912 158.677l.855.436-1.239 2.432a4.136 4.136 0 0 1-2.763-.411 3.58 3.58 0 1 1 5.171-4.185l-.833.271a2.777 2.777 0 0 0-1.44-1.642 2.69 2.69 0 1 0-2.45 4.786 2.976 2.976 0 0 0 1.665.344z"
              className="cls-3"
            />
            <path
              id="Path_778"
              d="M204.691 164.139l-3.313-1.687-1.48 1.209-.917-.468 6-4.8.882.449-.344 7.677-.935-.476zm.052-.871l.211-3.739-2.9 2.369z"
              className="cls-3"
            />
            <path
              id="Path_779"
              d="M212.426 169.068l-.4.775-4.523-2.3 3.176-6.234 4.4 2.242-.394.774-3.509-1.788-.976 1.916 3.126 1.593-.386.757-3.126-1.593-1.025 2.012z"
              className="cls-3"
            />
            <path
              id="Path_780"
              d="M219.124 171.146c-.535 1.05-1.746 1.511-3.331.7a3.963 3.963 0 0 1-1.9-1.924l.768-.494a3.147 3.147 0 0 0 1.539 1.625c.935.477 1.687.322 2.031-.355.336-.658.083-1.327-.986-1.872l-.5-.254.318-.623 2.547-1.125-3-1.529.394-.775 4.115 2.1-.308.606-2.656 1.182a2 2 0 0 1 .969 2.738z"
              className="cls-3"
            />
            <path
              id="Path_781"
              d="M225.316 174.256c-.548 1.078-1.755 1.551-3.349.74a4.012 4.012 0 0 1-1.9-1.924l.769-.5a3.14 3.14 0 0 0 1.529 1.621c.945.481 1.7.317 2.046-.359.359-.705.135-1.367-1.307-2.1l-1.416-.722 1.99-3.092 3.517 1.792-.394.775-2.769-1.411-1.041 1.624.7.353c1.853.952 2.188 2.099 1.625 3.203z"
              className="cls-3"
            />
            <path
              id="Path_782"
              d="M232.6 176.351l.855.437-1.239 2.43a4.126 4.126 0 0 1-2.764-.41 3.582 3.582 0 1 1 3.267-6.367 3.51 3.51 0 0 1 1.9 2.182l-.833.271a2.777 2.777 0 0 0-1.44-1.642 2.691 2.691 0 1 0-2.451 4.787 2.96 2.96 0 0 0 1.665.342z"
              className="cls-3"
            />
            <path
              id="Path_783"
              d="M243.149 177.846l-3.176 6.234-.89-.453 1.415-2.779-3.58-1.824-1.418 2.776-.891-.454 3.176-6.233.891.453-1.366 2.681 3.581 1.824 1.365-2.681z"
              className="cls-3"
            />
            <path id="Path_784" d="M0 48.842L277.779 185.7l.442-.9L0 47.726z" className="cls-4" />
            <path id="長方形_356-2-2" d="M0 0H3V3H0z" className="cls-3" />
            <path
              id="長方形_356-2-3"
              d="M0 0H3V3H0z"
              className="cls-3"
              transform="translate(0 47)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LinesContent
