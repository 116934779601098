import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const AvatarIconDate: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 2.40625H1.75C1.40201 2.4066 1.06837 2.54499 0.822306 2.79106C0.57624 3.03712 0.437847 3.37076 0.4375 3.71875V11.8125C0.437847 12.1605 0.57624 12.4941 0.822306 12.7402C1.06837 12.9863 1.40201 13.1247 1.75 13.125H12.25C12.598 13.1247 12.9316 12.9863 13.1777 12.7402C13.4238 12.4941 13.5622 12.1605 13.5625 11.8125V3.71875C13.5622 3.37076 13.4238 3.03712 13.1777 2.79106C12.9316 2.54499 12.598 2.4066 12.25 2.40625ZM13.125 11.8125C13.125 12.0446 13.0328 12.2671 12.8687 12.4312C12.7046 12.5953 12.4821 12.6875 12.25 12.6875H1.75C1.51794 12.6875 1.29538 12.5953 1.13128 12.4312C0.967187 12.2671 0.875 12.0446 0.875 11.8125V5.25H13.125V11.8125Z"
        fill="white"
      />
      <path
        d="M10.7188 0.875C10.7188 0.758968 10.6727 0.647688 10.5906 0.565641C10.5086 0.483594 10.3973 0.4375 10.2812 0.4375C10.1652 0.4375 10.0539 0.483594 9.97189 0.565641C9.88984 0.647688 9.84375 0.758968 9.84375 0.875V1.96875H10.7188V0.875Z"
        fill="white"
      />
      <path
        d="M4.15625 0.875C4.15625 0.758968 4.11016 0.647688 4.02811 0.565641C3.94606 0.483594 3.83478 0.4375 3.71875 0.4375C3.60272 0.4375 3.49144 0.483594 3.40939 0.565641C3.32734 0.647688 3.28125 0.758968 3.28125 0.875V1.96875H4.15625V0.875Z"
        fill="white"
      />
    </svg>
  )
}

export default AvatarIconDate
