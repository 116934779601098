import * as React from 'react'

interface SearchIconProps {
  height?: string
  width?: string
}
const SearchIcon: React.FC<SearchIconProps> = ({ height, width }) => {
  return (
    <div>
      <svg
        width={width || 32}
        height={height || 32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.0362 4.48264L17.0418 1.18677C16.3673 0.937745 15.6177 0.937745 14.9432 1.18677L5.94878 4.48264C4.77951 4.92209 4 6.00606 4 7.23652V14.1212C4 21.1964 8.69207 27.8467 15.138 29.8682C15.6927 30.0439 16.2923 30.0439 16.847 29.8682C19.5603 29.0186 21.9438 27.3487 23.8176 25.1807L19.0206 20.4932C16.727 21.8116 13.7289 21.5333 11.7502 19.5997C10.6271 18.4992 9.9965 17.0084 9.9965 15.4542C9.9965 13.9001 10.6271 12.4093 11.7502 11.3088C12.8764 10.2114 14.402 9.59514 15.9925 9.59514C17.583 9.59514 19.1086 10.2114 20.2349 11.3088C21.1717 12.2263 21.7699 13.4221 21.9354 14.7082C22.1009 15.9942 21.8244 17.2975 21.1493 18.4132L25.5865 22.7491C27.155 20.1312 27.9877 17.1548 28 14.1212V7.23652C27.985 6.00606 27.2055 4.92209 26.0362 4.48264Z"
          fill="#9496AB "
        />
        <path
          d="M18.1129 13.3775C16.9451 12.2075 15.0437 12.2075 13.8759 13.3775C12.708 14.5475 12.708 16.4525 13.8759 17.6225C15.0437 18.7925 16.9451 18.7925 18.1129 17.6225C19.2957 16.4375 19.2957 14.5475 18.1129 13.3775Z"
          fill="#9496AB "
        />
      </svg>
    </div>
  )
}

export default SearchIcon
