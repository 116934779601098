import * as React from 'react'

interface NotificationIconProps {
  height?: string
  width?: string
}
const NotificationIcon: React.FC<NotificationIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8234 14.9202V11.3319C24.725 9.01261 23.7546 6.82038 22.1126 5.20803C20.4705 3.59567 18.2823 2.68637 16 2.66797C13.7089 2.70581 11.5187 3.63334 9.87827 5.26052C8.2378 6.88769 7.27123 9.09132 7.17657 11.4199V14.9202C7.17657 18.8581 4 19.559 4 21.7463C4 23.7593 8.67657 25.3346 16 25.3346C23.3234 25.3346 28 23.7593 28 21.7463C28 19.559 24.8234 18.8581 24.8234 14.9202Z"
        fill="#9496AB"
      />
      <path
        d="M16 26.7575C14.586 26.7575 13.262 26.7253 12.014 26.668C12.014 26.6982 12 26.7266 12 26.7575C12 27.441 12.4214 28.0965 13.1716 28.5798C13.9217 29.0631 14.9391 29.3346 16 29.3346C17.0609 29.3346 18.0783 29.0631 18.8284 28.5798C19.5786 28.0965 20 27.441 20 26.7575C20 26.7266 19.988 26.6982 19.986 26.668C18.738 26.7253 17.414 26.7575 16 26.7575Z"
        fill="#9496AB"
      />
    </svg>
  )
}

export default NotificationIcon
