import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconGroup1: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '145'}
      height={height || '150'}
      viewBox="0 0 145 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0652 11H135.935C140.382 11 144 14.6333 144 19.1304V140.87C144 145.367 140.382 149 135.935 149H15.0652C10.6177 149 7 145.367 7 140.87V19.1304C7 14.6333 10.6177 11 15.0652 11Z"
        fill="white"
        stroke="#12C4C4"
        strokeWidth="2"
      />
      <path
        d="M107.337 78.179L107.244 78.088C107.244 78.088 93.0226 62.963 89.4796 59.141C85.9366 55.319 82.3936 54.417 80.9336 54.417H68.3586C66.8986 54.417 63.3586 55.317 59.8126 59.141C56.2666 62.965 42.0466 78.088 42.0466 78.088L41.9536 78.179C41.65 78.4609 41.4055 78.8005 41.2346 79.1779C41.0637 79.5553 40.9698 79.963 40.9582 80.3772C40.9467 80.7913 41.0178 81.2037 41.1674 81.59C41.3171 81.9764 41.5422 82.329 41.8296 82.6274C42.1171 82.9258 42.4612 83.1639 42.8417 83.3277C43.2222 83.4916 43.6316 83.578 44.0459 83.5819C44.4602 83.5858 44.8711 83.507 45.2546 83.3503C45.6382 83.1935 45.9866 82.9619 46.2796 82.669L46.3986 82.556C47.7986 81.161 62.5796 66.416 62.8666 66.156C63.1536 65.896 64.4206 64.613 64.3876 65.95C64.3676 67.05 64.2156 78.001 64.2156 78.118V73.772L63.8156 116.49V121.053C63.865 122.053 64.2962 122.996 65.0203 123.688C65.7444 124.38 66.7062 124.767 67.7076 124.771C68.6975 124.799 69.6591 124.438 70.3876 123.768C71.116 123.097 71.5538 122.168 71.6076 121.179V121.053C71.6076 121.053 73.2396 93.023 73.3076 91.32C73.3756 89.617 74.2766 89.493 74.6286 89.493C74.9806 89.493 75.8776 89.617 75.9476 91.32C76.0176 93.023 77.6476 121.053 77.6476 121.053V121.179C77.7014 122.168 78.1392 123.097 78.8676 123.768C79.5961 124.438 80.5576 124.799 81.5476 124.771C82.549 124.767 83.5108 124.38 84.2349 123.688C84.959 122.996 85.3902 122.053 85.4396 121.053V116.49L85.0396 73.772V78.118C85.0396 78.005 84.8856 67.058 84.8656 65.95C84.8396 64.614 86.1366 65.926 86.3866 66.156C86.6366 66.386 101.456 81.156 102.855 82.556L102.975 82.669C103.269 82.9533 103.617 83.1767 103.998 83.3265C104.379 83.4763 104.786 83.5496 105.196 83.5422C105.605 83.5348 106.009 83.4468 106.384 83.2832C106.76 83.1197 107.099 82.8838 107.384 82.589C107.954 81.9939 108.269 81.1989 108.26 80.3744C108.251 79.5499 107.92 78.7618 107.337 78.179Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2"
      />
      <path
        d="M74.6452 51.9241C79.3313 51.9241 83.1302 48.1252 83.1302 43.4391C83.1302 38.753 79.3313 34.9541 74.6452 34.9541C69.959 34.9541 66.1602 38.753 66.1602 43.4391C66.1602 48.1252 69.959 51.9241 74.6452 51.9241Z"
        stroke="#111947"
        strokeWidth="2"
      />
      <path
        d="M87.6648 56.1677C85.8009 54.4843 83.3863 53.5394 80.8748 53.5107H79.0748C79.0675 53.5188 79.0608 53.5275 79.0548 53.5367L75.0768 58.2207C75.0307 58.2748 74.9735 58.3181 74.909 58.3479C74.8445 58.3776 74.7744 58.393 74.7033 58.393C74.6323 58.393 74.5622 58.3776 74.4977 58.3479C74.4332 58.3181 74.3759 58.2748 74.3298 58.2207L70.3468 53.5377C70.3395 53.5296 70.3328 53.5209 70.3268 53.5117H68.3588C65.8501 53.5424 63.4387 54.4871 61.5768 56.1687L44.5898 73.7787L50.5088 79.6957L63.8608 66.6827L63.3668 79.9827H85.8748L85.3808 66.6957L98.7398 79.6977L104.65 73.7897L87.6648 56.1677Z"
        fill="#85C3C3"
        stroke="#111947"
        strokeWidth="2"
      />
      <path
        d="M64.3664 79.9785L62.9414 118.997H72.5804C73.0884 110.223 73.6564 93.1395 73.7124 91.7705C73.7884 89.9195 74.2384 89.8645 74.6204 89.8645C75.0024 89.8645 75.4444 89.9185 75.5204 91.7705C75.5764 93.1415 76.1524 110.223 76.6634 118.997H86.3004L84.8744 79.9785H64.3664Z"
        fill="#85C3C3"
        stroke="#111947"
        strokeWidth="2"
      />
      <path
        d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33Z"
        fill="#12C4C4"
      />
      <path
        d="M24.4622 11.0254L13.5122 21.9744L8.53516 16.9974"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconGroup1
