import { useTranslation } from 'react-i18next'
import QuestionIcon from "../../../../assets/images/svgs/question-icon";
import Wrapper from '../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../utils/const'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

interface HelpHowToProps {}

const iconStyle: React.CSSProperties = { color:'#12c4c4',marginRight:'0.5em' };

const HelpHowTo: React.FC<HelpHowToProps> = () => {

    const title = 'ご利用について | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
    const description = 'ご利用についてのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
    const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
    const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

    // title
    document.title = title;

    // title以外のmeta
    const headData = document.head.children;

    for (let i = 0; i < headData.length; i++) {
        const nameVal = headData[i].getAttribute('name');
        const propertyVal = headData[i].getAttribute('property');
        if (nameVal !== null) {
            if (nameVal.indexOf('keywords') !== -1) {
                headData[i].setAttribute('content', keywords);
            }
            if (nameVal.indexOf('description') !== -1) {
                headData[i].setAttribute('content', description);
            }
        }
        if (propertyVal !== null) {
            // OGP(og:title)の設定
            if (propertyVal.indexOf('og:title') !== -1) {
                headData[i].setAttribute('content', title);
            }
            if (propertyVal.indexOf('og:description') !== -1) {
                headData[i].setAttribute('content', description);
            }
            if (propertyVal.indexOf('og:image') !== -1) {
                headData[i].setAttribute('content', og_image);
            }
        }
    }
    
    const [t] = useTranslation('translation')

    const history = useHistory()

    const onGoTo = (path: any) => () => {
        history.push(path)
    }
    const list = [
        {
            name: t('help.home'),
            link: ROUTER_URL.HOME,
        },
        {
            name: t('help.howto.parent'),
            link: ROUTER_URL.HELP_INDEX,
        },
        {
            name: t('help.howto.breadcrum'),
        },
    ]

    return (
        <Wrapper
            breadcrum={list}
            title={t('help.howto.title')}
            className="base_style help-wrap"
            Icon={QuestionIcon}
            classNameBtn={'normal'}
        >

            <div className="wrapper__content">
                <div className="help-wrap_box distance-content-to-button">
                    <ul className="toc">
                        <li><a href="#about_resist">会員登録について</a></li>
                        <li><a href="#about_dl">アバターダウンロードについて</a></li>
                        <li><a href="#about_requirement">AVATARIUMの推奨環境について</a></li>
                    </ul>
                    <div id="about_resist" className="section">
                        <h2>会員登録について</h2>
                        <p>
                            AVATARIUMサービスをご利用するには会員登録が必須となります。<br />
                            会員登録はWEBサイトからのみとなりますのでご注意ください。<br /><br />

                            会員登録がお済みでない方は<a className="text-link" onClick={onGoTo(ROUTER_URL.SIGNUP)}>コチラ</a>
                        </p>

                        <div id="about_resist_1" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight}/>利用料金について</h3>
                            <p>
                                AVATARIUMの会員登録、一部サービスは無料でご利用いただけます。<br />
                                
                                イベント情報は<a className="text-link" onClick={onGoTo(ROUTER_URL.NEWS)}>AVATARIUMニュース</a>、または通知から随時お知らせいたします。
                            </p>
                        </div>
                    </div>

                    <div id="about_dl" className="section">
                        <h2>アバターダウンロードについて</h2>
                        <p>
                            AVATARIUMサービスを利用して作成したアバターは一部ダウンロードが可能です。<br />
                            各アバターのダウンロード可否はアバター管理画面からご確認できます。<br /><br />

                            アバターのダウンロードには料金が発生します。（購入の確認ができたアバターは何度でもダウンロードが可）
                            購入されたアバターは<a className="text-link" onClick={onGoTo(ROUTER_URL.TERM_OF_SERVICE)}>利用規約</a>に則り、ご利用が可能です。<br /><br />

                            ※本サービス内データの著作権は全て弊社にあります。<br />
                            本サービス内データをご使用の際は、よろしければ可能な箇所に「AVATARIUM」の表記をお願い致します。<br />
                            ※ ダウンロードアバターに本サービス以外のデータの組み込み、または改変を行った際の動作保証は致しません。<br />
                            ※ ダウンロードアバターを他サービス（AVATARIUM連携サービス以外）で使用する場合の動作保証は致しません。
                        </p>
                    </div>


                    <div id="about_requirement" className="section">
                        <h2>AVATARIUMの推奨環境について</h2>
                        <p>
                            AVATARIUMは、パソコン、スマートフォン問わず以下のブラウザ、またはモバイルアプリでご利用いただけます。<br />
                            AVATARIUMを快適に閲覧いただくために、以下の環境でのご利用を推奨いたします。<br />
                            JavaScript、Cookieが使用できる状態でご利用ください。
                        </p>

                        <div id="about_requirement_1" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight}/>パソコン (Windows &nbsp; Mac)</h3>
                            <p>
                                ・Google Chrome 最新バージョン<br />
                                ・Apple Safari 最新バージョン<br />
                                ・Microsoft Edge 最新バージョン<br />
                                ・Mozilla Firefox 最新バージョン
                            </p>
                        </div>

                        <div id="about_requirement_2" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight}/>スマートフォン</h3>
                            <p>
                                ・iOS 14 以上 (Apple Safari)<br />
                                ・Android 9 以上 (Google Chrome)
                            </p>
                            <p>※上記推奨ブラウザ・アプリ以外の、Android標準ブラウザや、ゲーム機などでの動作は保証していません。</p>
                        </div>
                    </div>
                </div>

                <div className="wrapper__button">
                    <a className="button-custom" onClick={onGoTo(ROUTER_URL.HELP_INDEX)}>ヘルプページTOP</a>
                </div>

            </div>
        </Wrapper>
    );
}
export default HelpHowTo
