import { put, takeLatest } from '@redux-saga/core/effects'
import { SigninActionsTypes, SystemTypes } from '../../actions'

function* signout(): any {
  try {
    window.localStorage.removeItem('access_token')
    yield put({ type: SigninActionsTypes.SIGN_OUT_SUCCESS })
  } catch {
    yield put({ type: SystemTypes.SYSTEM_ERROR, error: 'Something wrong' })
  }
}

export function* watchSignout() {
  yield takeLatest(SigninActionsTypes.SIGN_OUT, signout)
}
