import * as React from 'react'

interface DropdownIconTopProps {
  height?: string
  width?: string
}
const DropdownIconTop: React.FC<DropdownIconTopProps> = ({ height, width }) => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 8L0 0H12L6 8Z" fill="#B1B1B1" />
    </svg>
  )
}

export default DropdownIconTop
