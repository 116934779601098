import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { validator, textFields } from './constants'
import { CREATE_PROVISIONAL_USER } from '../../../../utils/endpoints'
import { setupHead } from '../../../../utils/index'
import React, { useEffect, useState } from 'react'
import Wrapper from '../../components/modules/_wrapper'
import { Button } from '../../components/atoms/buttons'
import { TextInput } from './../../components/atoms/text-input/index'
import { isEmpty } from 'lodash'
import { POST } from '../../../../utils/const'

interface ProvisionalUserFormProps {
  callApi: any
}

/**
 * フォームで送信される値の型
 */
type FormValues = {
  /**
   * 予約ID
   */
  reservation_id: string | null

  /**
   * 筐体のID
   */
  scanner_id: string | null

  /**
   * バッチID 次の形式の日時です。20231015_100712
   * hhmmss 部分を整理番号として使います。
   */
  batch_id: string | null

  /**
   * HMAC-SHA256 で生成したメッセージ認証コード
   */
  sig: string | null

  /**
   * 入力されたメールアドレス
   */
  email: string
}

/**
 * フォームのデフォルト値を返します。
 * @returns FormValues
 */
const getDefaultValues = (): FormValues => {
  // クエリパラメータを取得します。
  const location = window.location.search
  const urlParams = new URLSearchParams(location)
  const defaultValues: FormValues = {
    reservation_id: urlParams.get('reservation_id'),
    scanner_id: urlParams.get('scanner_id'),
    batch_id: urlParams.get('batch_id'),
    sig: urlParams.get('sig'),
    email: '',
  }
  return defaultValues
}

/**
 * クエリパラメータのバリデーションを行います。
 * 今のところ batch_id 以外は厳しく制限していません。サーバ側で sig の検証が行われます。
 * @param defaultValues
 * @returns valid or not
 */
const validateQuery = (query: FormValues): boolean => {
  const reservation_id = `${query.reservation_id || ''}`
  if (!/^.+$/.test(reservation_id)) return false

  const scanner_id = `${query.scanner_id || ''}`
  if (!/^.+$/.test(scanner_id)) return false

  const batch_id = `${query.batch_id || ''}`
  if (!/^\d{8}_\d{6}$/.test(batch_id)) return false

  const sig = `${query.sig || ''}`
  if (!/^.+$/.test(sig)) return false

  return true
}

const ProvisionalUserForm: React.FC<ProvisionalUserFormProps> = ({ callApi }) => {

  const title = 'メールアドレス登録 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'メールアドレス登録のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';
  setupHead(title, description, keywords, og_image);

  const [t, i18n] = useTranslation('translation')

  // API エラー表示の state
  const [error, setError] = useState('')

  // 二重送信を禁止する state
  const [loading, setLoading] = useState(false)

  // 送信済みの時に true になる state
  const [isSent, setIsSent] = useState(false)

  const defaultValues = getDefaultValues()
  const methods = useForm({
    defaultValues,
    resolver: validator(t),
  })
  const { handleSubmit, errors, register } = methods

  // バリデーションエラーメッセージの言語切り替え処理(だと思います)
  useEffect(() => {
    if (!isEmpty(errors)) handleSubmit(onSubmit)()
  }, [localStorage.getItem('i18nextLng')])

  // パンくずを非表示にするため、とりあえず空にしています。。
  const breadCrumbs: object[] = []

  const isValidQuery = validateQuery(defaultValues)

  if (!isValidQuery) {
    return (
      <Wrapper className="password provisional-user" breadcrum={breadCrumbs} typeBreadcrums={1} isNoMarginTop={false}>
      <h2 className="password__title title-h2 distance-h2-to-text">
      {i18n.t('provisional_user.query_error_title')}
      </h2>
      <div className="password__describe distance-text-to-noti">
        <div id="forgot-title" className="password__describe-content1">
          <p>
          {i18n.t('provisional_user.query_error_description')}
      </p>
      </div>
      </div>
    </Wrapper>
    )
  }

  // バッチID の末尾6桁を整理番号として使います。
  const serialNumber = defaultValues.batch_id?.slice(9)
  const serialDate = defaultValues.batch_id?.slice(0, 8).replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3')

  const onSubmit = async (values: any) => {
    setLoading(true)
    try {
      await callApi(CREATE_PROVISIONAL_USER, POST, values)
      setLoading(false)
      setIsSent(true)
    } catch (error: any) {
      setError(error?.message)
      setLoading(false)
    }
  }

  const renderBtnSend = (
    <div className="password__send">
      <Button type="submit">{i18n.t('forgot_password.button')}</Button>
    </div>
  )

  const backButtonClick = () => {
    setIsSent(false)
  }

  const renderBtnBack = (
    <div className="password__send">
      <Button className="outline" onClick={backButtonClick}>{i18n.t('provisional_user.back_button')}</Button>
    </div>
  )

  const renderTextFields = (
      <div className="password__field-wrapper distance-input-to-btn">
        <span>
          {textFields.map(({ name, type, isRequire, placeholder, label }) => {
            return (
              <TextInput
                key={name}
                type={type}
                name={name}
                isRequire={isRequire}
                placeholder={i18n.t(placeholder)}
                label={i18n.t(label)}
                errorsBorder={!!error}
                handleChange={() => setError('')}
              />
            )
          })}
        </span>
        {error && (
          <div className="error-message">
            <span>※{error}</span>
          </div>
        )}
      </div>
  )

  // フォーム。 isSent が true の時は非表示にします。
  const renderForm = (
    <FormProvider {...methods}>
      <form
        onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(onSubmit)}
        className="password__form"
      >
        {renderTextFields}
        <input type="hidden" name="reservation_id" ref={register} />
        <input type="hidden" name="scanner_id" ref={register} />
        <input type="hidden" name="batch_id" ref={register} />
        <input type="hidden" name="sig" ref={register} />
        {renderBtnSend}
      </form>
    </FormProvider>
  )

  return (
    <Wrapper className="password" breadcrum={breadCrumbs} typeBreadcrums={1} isNoMarginTop={false}>
      <h2 className="password__title title-h2 distance-h2-to-text">
        {i18n.t('provisional_user.form_title')}
      </h2>
      <div className="password__describe distance-text-to-noti">
        <div>[date {serialDate}]</div>
        <div className="provisional-user__serial-number">{i18n.t('provisional_user.serial_number')} {serialNumber}</div>
        <div id="forgot-title" className="password__describe-content1">
          {isSent ? (
          <p>{i18n.t('provisional_user.sent_description')}<br/>{i18n.t('provisional_user.sent_description2')}</p>
          ) : (
          <p>{i18n.t('provisional_user.form_description')}</p>
          )}
        </div>
      </div>
      {isSent ? renderBtnBack : renderForm}
    </Wrapper>
  )
}
export default ProvisionalUserForm
