import * as React from 'react';

interface CreaditIconProps {
   height?: string;
   width?: string;
};
const CreaditIconNew: React.FC<CreaditIconProps> = ({ height, width }) => {
   return (
      <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <circle cx="12" cy="12" r="11.5" stroke="#12C4C4" />
         <rect x="11" y="6" width="2" height="12" fill="#12C4C4" />
         <rect x="18" y="11" width="2" height="12" transform="rotate(90 18 11)" fill="#12C4C4" />
      </svg>
   );
};

export default CreaditIconNew