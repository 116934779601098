import * as React from 'react'

interface KimonoProps {
  height?: string
  width?: string
}
const Kimono: React.FC<KimonoProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '86'}
      height={height || '86'}
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.9281 3.86639L33.9705 5.34278L36.0297 2.3999L56.5339 31.7241H23.1493V54.8457H12.9201C10.1301 54.8457 7.45427 53.7338 5.48116 51.7547C3.50804 49.7755 2.39918 47.0911 2.39844 44.2917V20.7381L26.9281 3.86639Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M59.0781 3.86639L52.0308 5.34278L49.9765 2.3999L29.4688 31.7241H62.8731V54.8457H73.0988C75.8865 54.8419 78.5588 53.7279 80.5283 51.7485C82.4979 49.769 83.6037 47.086 83.6028 44.2889V20.7381L59.0781 3.86639Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M62.8556 31.728H23.1523V83.6003H62.8556V31.728Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M62.8556 38.9258H23.1523V44.7281H62.8556V38.9258Z"
        fill="#C2C2C2"
        stroke="#111947"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M23.1523 33.2956V23.0811"
        stroke="#111947"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1489 32.8198L17.1016 26.8711"
        stroke="#111947"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.8516 33.2956V23.0811"
        stroke="#111947"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.8516 32.8198L68.8953 26.8711"
        stroke="#111947"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Kimono
