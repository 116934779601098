import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/atoms/buttons'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Cleave from 'cleave.js/react'
import { ADD_PAYMENT_CARD } from '../../../../utils/endpoints'
import { ERROR_TYPE, POST } from '../../../../utils/const'
import { CloseIcon } from '../../../../assets/images'
interface CreditModalProps {
  toggleModal?: () => void
  detailListCard?: any
  notify: Function
  callApi: any
}

const CreditModal: React.FC<CreditModalProps> = ({
  toggleModal,
  detailListCard,
  notify = () => {},
  callApi,
}) => {
  const [t] = useTranslation('translation')

  const [isLoading, setIsLoading] = useState(false)

  const validator = (t: any) => {
    return yupResolver(
      Yup.object().shape({
        series: Yup.string().required(t('manage-credit.error_field_1')),
        'expired-date': Yup.string().required(t('manage-credit.error_field_2')),
        'security-number': Yup.string().required(t('manage-credit.error_field_3')),
      })
    )
  }
  const defaultValues = {
    'user-name': '',
    series: '',
    'expired-date': '',
    'security-number': '',
  }
  const methods = useForm({
    defaultValues,
    resolver: validator(t),
  })
  const { handleSubmit, control, errors } = methods
  const errorMessageSeries = errors?.series?.message
  const errorMessageExpiredDate = errors?.['expired-date']?.message
  const errorMessageSecurityNumber = errors?.['security-number']?.message
  const registerInformationList = [
    {
      name: 'series',
      label: t('manage_credit.series'),
      placeholder: '1234 - 1234 - 1234 - 1234',
      isRequired: true,
      options: { creditCard: true, delimiter: ' - ' },
      errorMessage: errorMessageSeries,
    },
    {
      name: 'expired-date',
      label: t('manage_credit.expired_date'),
      placeholder: 'MM/YY',
      isRequired: true,
      options: { date: true, datePattern: ['m', 'y'] },
      errorMessage: errorMessageExpiredDate,
    },
    {
      name: 'security-number',
      label: t('manage_credit.security_number'),
      placeholder: '0000',
      isRequired: true,
      options: { numericOnly: true, blocks: [4] },
      errorMessage: errorMessageSecurityNumber,
    },
  ]
  const onSubmit = async (data: any) => {
    setIsLoading(true)
    const params = {
      card_cvv: data['security-number'],
      card_exp_month: data['expired-date'],
      card_number: data['series'].split(' - ').join(''),
    }
    try {
      const result = await callApi(ADD_PAYMENT_CARD, POST, params)
      if (result.status === 200) {
        if (toggleModal) {
          toggleModal()
        }
        detailListCard()
      }
    } catch (error) {
      notify(error?.message, ERROR_TYPE)
    }
    setIsLoading(false)
  }
  const renderRegisterInformation = (
    <div className="manage-credit__register-information credit-modal__register-information">
      {registerInformationList.map((item, index) => (
        <div key={item.name}>
          {item.label && (
            <div
              className={
                index === 0
                  ? 'credit-text-input__wrapper'
                  : 'credit-text-input__wrapper distance-input-to-label'
              }
            >
              <label className="credit-text-input__label pop-text-bold-normal distance-text-to-input">
                {item.label}
              </label>
              {item.isRequired && <span className="input__asterisk">*</span>}
            </div>
          )}
          <Controller
            name={item.name}
            control={control}
            as={
              <Cleave
                placeholder={item.placeholder}
                className="credit-text-input__field"
                options={item.options}
                type="tel"
              />
            }
          />
          {item.errorMessage && (
            <span className="form-field-error-message">{item.errorMessage}</span>
          )}
        </div>
      ))}
    </div>
  )

  const renderRegisterArea = (
    <div className="manage-credit__register_modal credit-modal__register distance-line-to-text distance-modal-content-to-btn">
      <div className="manage-credit__registerBox">
        <div className="manage-credit__register-content credit-modal__register-content">
          {renderRegisterInformation}
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div className="detail-avatar__delete-modal">
        <div
          className="detail-avatar__delete-modal_header"
          style={{ borderBottom: '1px solid #ccc' }}
        >
          <div className="detail-avatar__delete-modal_header-text  distance-h2-to-line">
            <div className="pop-text-bold-big">{t('manage_credit.register_title')}</div>
          </div>
          <button
            className="detail-avatar__delete-modal_header-icon close-icon"
            onClick={toggleModal}
          >
            <CloseIcon />
          </button>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {renderRegisterArea}
            <div className="detail-avatar__delete-modal_button">
              <Button className="cancel" onClick={toggleModal} disabled={isLoading}>
                {t('booking_history.cancel')}
              </Button>
              <Button type="submit">
                {t('manage_credit.add')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  )
}

export default CreditModal
