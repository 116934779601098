import * as Yup from 'yup'
import { useCallback } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { EMAIL_PATTERN, FORGOT_PASSWORD_PATTERN } from '../../../../utils/const'
export const CHECK_PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
export const textFields = [
  {
    name: 'login_id',
    type: 'text',
    isRequire: true,
    placeholder: 'signup.username_placeholder',
    helpText: false,
    errorApi: '',
    disable: false,
    backgroundColor: '',
  },
  {
    name: 'email',
    type: 'email',
    isRequire: true,
    placeholder: 'signup.email_placeholder',
    helpText: false,
    errorApi: '',
    disable: true,
    backgroundColor: '',
  },
  {
    name: 'login_pw',
    type: 'password',
    isRequire: true,
    placeholder: 'signup.password_placeholder',
    helpText: true,
    errorApi: '',
    disable: false,
    backgroundColor: '',
  },
  {
    name: 'login_pw_cf',
    type: 'password',
    placeholder: 'signup.confirmPassword_label',
    isRequire: true,
    helpText: false,
    errorApi: '',
    disable: false,
    backgroundColor: '',
  },
]

export const radioFields = [
  {
    name: 'sex',
    type: 'radio',
    label: 'signup.sex_male',
    value: 'male',
    isRequire: true,
  },
  {
    name: 'sex',
    type: 'radio',
    label: 'signup.sex_female',
    value: 'female',
    isRequire: true,
  },
  {
    name: 'sex',
    type: 'radio',
    label: 'signup.sex_other',
    value: 'homosexuality',
    isRequire: true,
  },
]

export const validator = (t: any) => {
  return yupResolver(
    Yup.object({
      login_id: Yup.string()
        .max(32, t('validations.user_max'))
        .required(t('validations.username_require'))
        .trim(),
      email: Yup.string()
        .max(254, t('validations.email_max'))
        .matches(EMAIL_PATTERN, t('validations.email_valid'))
        .notOneOf([''], t('validations.email_require'))
        .required(t('validations.email_require'))
        .trim(),
      login_pw: Yup.string()
        .max(32, t('validations.password_max'))
        .required(t('validations.password_require'))
        .matches(FORGOT_PASSWORD_PATTERN, t('validations.pass_new')),
      login_pw_cf: Yup.string()
        .oneOf([Yup.ref('login_pw'), ''], t('validations.cf_pass_same_1'))
        .required(t('validations.confirmPassword_require')),
      sex: Yup.string().required(t('validations.sex_require')),
      accept: Yup.bool().oneOf([true], t('validations.provisions_require')),
    })
  )
}

export function trimParamObject(params: any) {
  return Object.entries(params).reduce(
    (accum: any, [k, v]: any) => ({ ...accum, [k]: typeof v === 'string' ? v?.trim() : v }),
    {}
  )
}

export const useYupValidationResolver = (validationSchema: any) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(trimParamObject(data), {
          abortEarly: false,
        })

        return {
          values,
          errors: {},
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors?.inner?.reduce(
            (allErrors: any, currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        }
      }
    },
    [validationSchema]
  )

export const defaultValues = {
  login_id: '',
  email: '',
  login_pw: '',
  login_pw_cf: '',
  accept: false,
  sex: '',
}
