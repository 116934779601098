import React from 'react'

interface Dots5x7ContentProps {
  height?: string
  width?: string
}

const Dots5x7Content: React.FC<Dots5x7ContentProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "71"} height={height || "105"} viewBox="0 0 71 105">
      <defs>
        <clipPath id="clipPath">
          <path id="Rectangle_2911" d="M0 0H71V105H0z" className="cls-9969-1" />
        </clipPath>
        <clipPath id="clipPath-3">
          <path
            id="Rectangle_2872"
            d="M0 0H3V3H0z"
            className="cls-9969-1"
            transform="translate(68 85)"
          />
        </clipPath>
        <clipPath id="clipPath-5">
          <path
            id="Rectangle_2880"
            d="M0 0H3V3H0z"
            className="cls-9969-1"
            transform="translate(51 102)"
          />
        </clipPath>
        <clipPath id="clipPath-7">
          <path id="Rectangle_2909" d="M0 0H3V3H0z" className="cls-9969-1" />
        </clipPath>
        <style>
          {
            '.cls-9969-1{fill:none}.cls-9969-2{clipPath:url(#clipPath)}.cls-9969-3{opacity:0}.cls-9969-5{fill:#12c4c4}'
          }
        </style>
      </defs>
      <g id="DOTS2" transform="translate(35.5 52.5)">
        <g id="Group_10535" className="cls-9969-2" transform="translate(-35.5 -52.5)">
          <g id="Group_10528" className="cls-9969-2">
            <g id="Group_10527" className="cls-9969-3">
              <g id="Group_10526" clipPath="url(#clipPath-3)">
                <path
                  id="Rectangle_2871"
                  d="M0 0H3V3H0z"
                  className="cls-9969-5"
                  transform="translate(68 85)"
                />
              </g>
            </g>
          </g>
          <path
            id="Rectangle_2874"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(68 68)"
          />
          <path
            id="Rectangle_2875"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(68 51)"
          />
          <path
            id="Rectangle_2876"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(68 34)"
          />
          <path
            id="Rectangle_2877"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(68 17)"
          />
          <path id="Rectangle_2878" d="M0 0H3V3H0z" className="cls-9969-5" transform="translate(68)" />
          <g id="Group_10531" className="cls-9969-2">
            <g id="Group_10530" className="cls-9969-3">
              <g id="Group_10529" clipPath="url(#clipPath-5)">
                <path
                  id="Rectangle_2879"
                  d="M0 0H3V3H0z"
                  className="cls-9969-5"
                  transform="translate(51 102)"
                />
              </g>
            </g>
          </g>
          <path
            id="Rectangle_2882"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(51 85)"
          />
          <path
            id="Rectangle_2883"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(51 68)"
          />
          <path
            id="Rectangle_2884"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(51 51)"
          />
          <path
            id="Rectangle_2885"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(51 34)"
          />
          <path
            id="Rectangle_2886"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(51 17)"
          />
          <path id="Rectangle_2887" d="M0 0H3V3H0z" className="cls-9969-5" transform="translate(51)" />
          <path
            id="Rectangle_2888"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(34 102)"
          />
          <path
            id="Rectangle_2889"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(34 85)"
          />
          <path
            id="Rectangle_2890"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(34 68)"
          />
          <path
            id="Rectangle_2891"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(34 51)"
          />
          <path
            id="Rectangle_2892"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(34 34)"
          />
          <path
            id="Rectangle_2893"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(34 17)"
          />
          <path id="Rectangle_2894" d="M0 0H3V3H0z" className="cls-9969-5" transform="translate(34)" />
          <path
            id="Rectangle_2895"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(17 102)"
          />
          <path
            id="Rectangle_2896"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(17 85)"
          />
          <path
            id="Rectangle_2897"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(17 68)"
          />
          <path
            id="Rectangle_2898"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(17 51)"
          />
          <path
            id="Rectangle_2899"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(17 34)"
          />
          <path
            id="Rectangle_2900"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(17 17)"
          />
          <path id="Rectangle_2901" d="M0 0H3V3H0z" className="cls-9969-5" transform="translate(17)" />
          <path
            id="Rectangle_2902"
            d="M0 0H3V3H0z"
            className="cls-9969-5"
            transform="translate(0 102)"
          />
          <path id="Rectangle_2903" d="M0 0H3V3H0z" className="cls-9969-5" transform="translate(0 85)" />
          <path id="Rectangle_2904" d="M0 0H3V3H0z" className="cls-9969-5" transform="translate(0 68)" />
          <path id="Rectangle_2905" d="M0 0H3V3H0z" className="cls-9969-5" transform="translate(0 51)" />
          <path id="Rectangle_2906" d="M0 0H3V3H0z" className="cls-9969-5" transform="translate(0 34)" />
          <path id="Rectangle_2907" d="M0 0H3V3H0z" className="cls-9969-5" transform="translate(0 17)" />
          <g id="Group_10534" className="cls-9969-2">
            <g id="Group_10533" className="cls-9969-3">
              <g id="Group_10532" clipPath="url(#clipPath-7)">
                <path id="Rectangle_2908" d="M0 0H3V3H0z" className="cls-9969-5" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Dots5x7Content
