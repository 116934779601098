import { combineReducers } from 'redux'
import { SigninReducer } from './signinReducer'
import { IApplicationState } from '../states'
import { SystemReducer } from './systemReducer'
import { TabReducer } from './tabReducer'
import { HistoryReducer } from './historyReducer'
import { ListAvatarReducer } from './listAvatarReducer'
import { ListDownLoadReducer } from './listTypeDownloadReducer'
import { SaveInfoReducer } from './saveInfoCreaditReducer'
import { ListFriendReducer } from './listFriendReducer'


export const rootReducer = combineReducers<IApplicationState>({
  // rest of reducers by containers here below...
  signin: SigninReducer,
  systemMessage: SystemReducer,
  tab: TabReducer,
  history: HistoryReducer,
  download: ListDownLoadReducer,
  listAvatar: ListAvatarReducer,
  creadit: SaveInfoReducer,
  listFriend: ListFriendReducer
})

export const ApplicationState = (state: any, action: any) => {
  return rootReducer(state, action)
}
