import classnames from 'classnames'
import { useFormContext } from 'react-hook-form'
import React, { useLayoutEffect, useRef } from 'react'
interface TextareaProps {
  name: string
  type?: string
  label?: string
  placeholder?: string
  isRequire: boolean
  isDisabled?: boolean
  isFocus?: boolean
  helpText?: boolean
  id?: number
  rows?: number | undefined
  isFull?: boolean
}

export const Textarea: React.FC<TextareaProps> = ({
  name,
  label,
  placeholder = 'アバター名を入力してください。',
  isRequire,
  isDisabled,
  isFocus,
  rows,
  isFull,
}) => {
  const { errors, register } = useFormContext()
  const isError = !!errors?.[name] ?? false
  const message = errors?.[name]?.message ?? null
  const inputRef = useRef<HTMLInputElement>(null)
  useLayoutEffect(() => {
    if (isFocus) {
      if (null !== inputRef.current) {
        inputRef.current.focus()
      }
    }
  })
  return (
    <div className={classnames('textarea', { 'input-label-gutter': label })}>
      {label && (
        <div className={isFull ? 'input__label--wrapper' : 'input__label--field'}>
          <p className="input__label">{label}</p>
          {isRequire ? <span className="input__asterisk">*</span> : null}
        </div>
      )}

      <textarea
        rows={rows || 5}
        autoComplete="off"
        ref={register}
        className={classnames('textarea__field', { full: isFull, error: isError })}
        placeholder={placeholder}
        name={name}
        disabled={isDisabled}
        autoFocus={isFocus}
      />
      {message && <span className="form-field-error-message">{message}</span>}
    </div>
  )
}
