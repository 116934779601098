import { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { validator, textFields, defaultValues } from './constants'
import { useTranslation } from 'react-i18next'
import axios from '../../../../utils/axios'
import { LOGIN, REGISTER_DEVICE, DETAIL_ACCOUNT } from '../../../../utils/endpoints'
import { useDispatch } from 'react-redux'
import { checkSignin, setUserDetail } from '../../../stores/actions'
import { getFirebaseToken } from '../../../../utils/firebase'
import { useSelector } from 'react-redux'
import { POST, ROUTER_URL } from '../../../../utils/const'
import { isEmpty } from 'lodash'
import {
  Button
} from '../../components/atoms/buttons'
import { TextInput } from './../../components/atoms/text-input/index'
import { Breadcrumb } from './../../components/atoms/breadcrumb/index'

interface SigninProps {
  notify: any
  callApi: any
}

const Signin: React.FC<SigninProps> = ({ notify, callApi }) => {

  const title = 'ログイン | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'ログインのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [t, i18n] = useTranslation('translation')
  const [loading, setLoading] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false)

  const lang = useSelector((state: any) => state.signin.lang)

  const [errNoti, setErrNoti] = useState('')

  const history = useHistory()
  const location: any = useLocation()
  const dispatch = useDispatch()
  const methods = useForm({
    defaultValues,
    resolver: validator(t),
  })
  const { handleSubmit, clearErrors, getValues, setError } = methods

  const onSubmit = async (values: any) => {
    if (loading) return
    setLoading(true)
    try {
      const result = await callApi(LOGIN, POST, values)
      const access_token = result.data.data.access_token
      const refresh_token = result.data.data.refresh_token
      if (access_token && refresh_token) {
        await dispatch(checkSignin(true))
        localStorage.setItem('access_token', access_token)
        localStorage.setItem('refresh_token', refresh_token)
        const response = await callApi(DETAIL_ACCOUNT)
        const { data } = response?.data?.data || {}
        if (!isEmpty(data)) {
          dispatch(setUserDetail(data || {}))
          localStorage.setItem('userData', JSON.stringify(data))
        }
      }
      const getToken = localStorage.getItem('access_token')

      // Connect to Firebase to get the device token.
      try {
        getFirebaseToken().then(async (deviceToken: any) => {
          if (deviceToken && !deviceToken?.includes(' ')) {
            let response = await axios.post(
              REGISTER_DEVICE,
              {
                device_token: deviceToken,
              },
              {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                },
              }
            )
            if (response?.data?.data) {
              localStorage.setItem('device_token', deviceToken)
            }
          }
        })
      } catch (error: any) {
        if (error?.message) {
          notify(error?.message)
        }
        setLoading(false)
      }

      if (getToken !== '') {
        const { param } = location?.state || {}
        if (!isEmpty(param)) {
          const query = new URLSearchParams(param).toString()
          history.push(ROUTER_URL.RE_DOWNLOAD + '?' + query)
        } else {
          history.push(
            ROUTER_URL.SCAN_BOOKING
          )
        }
      }
      setLoading(false)
    } catch (error: any) {
      if (error?.message) {
        setError('password', { message: error?.message })
        setErrNoti(error?.message)
      }
      setLoading(false)
    }
  }

  const selected = (crumb: any) => {}

  const renderTextFields = (
    <>
      <div className="signin__block distance-form-to-link">
        {textFields.map(({ name, type, isRequire, placeholder }) => {
          return (
            <TextInput
              key={name}
              type={type}
              name={name}
              isRequire={isRequire}
              errorsBorder={!!errNoti}
              handleChange={() => setErrNoti('')}
              placeholder={i18n.t(placeholder)}
            />
          )
        })}
      </div>
    </>
  )

  const renderLogin = (
    <div className="signin__register">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button type="button" onClick={onValidate}>
          {i18n.t('signin.login')}
        </Button>
      </div>
    </div>
  )

  const list = [
    {
      name: i18n.t('signin.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('signin.login'),
      link: ROUTER_URL.SIGNIN,
    },
  ]
  const renderBreadcrumb = (
    <div className="signin__breadcrumb">
      <Breadcrumb crumbs={list} selected={selected} />
    </div>
  )
  const checkKeyDown = (e: any) => {
    const email = getValues('email')
    const password = getValues('password')
    if (e.code === 'Enter' && email && password) onValidate()
  }

  function onValidate() {
    handleSubmit(onSubmit)()
    setIsFirstLoad(true)
  }

  useEffect(() => {
    if (isFirstLoad) {
      clearErrors()
      handleSubmit(onSubmit)()
    }
  }, [lang])

  return (
    <div className="signin default-padding">
      {renderBreadcrumb}
      <h2 className="title-h2 distance-h2-to-form signin__description title">
        {i18n.t('signin.describe')}
      </h2>
      <FormProvider {...methods}>
        <form
          onSubmit={onValidate}
          autoComplete="off"
          onKeyDown={checkKeyDown}
        >
          {renderTextFields}
          <div className="signin__forgot distance-text-to-btn">
            <Link to={ROUTER_URL.FORGOT_PASSWORD} className="text-link">
              <span>{i18n.t('signin.refer_forgot')}</span>
            </Link>
          </div>
          {renderLogin}
        </form>
      </FormProvider>
      <div className="signin__aside">
        <div className="text-normal-second" style={{ textAlign: 'center', fontWeight: 400 }}>
          <span>{i18n.t('signin.refer_signup')}</span>
          <Link to={ROUTER_URL.SIGNUP} className="text-link">
            <span>
              {i18n.t('signin.register')}
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Signin
