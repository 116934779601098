import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

export const dataTest = [
  {
    id: 0,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    id: 1,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    id: 2,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    id: 3,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    id: 4,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    id: 5,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    id: 6,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    id: 7,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    id: 8,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    id: 9,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    id: 10,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  },
  {
    id: 11,
    image: 'https://picsum.photos/255/340',
    date: localStorage.getItem('i18nextLng') === 'ja' ? '2021年03月08日' : '2021/03/08',
    content: localStorage.getItem('i18nextLng') === 'ja' ? 'アバター名を記の記載' : 'Description of avatar name'
  }
]

export const validator = (t: any) => {
  return yupResolver(
    Yup.object().shape({
      name: Yup.string()
        .max(50,t('detail-avatar.name_max'))
        .required(t('detail-avatar.validate_name'))
        .trim(),
    })
  )
}

export const defaultValues = {
  name: localStorage.getItem('i18nextLng') === 'ja' ? '20210308（AVATAR名)' : '20210308（AVATAR NAME)',
} 