import { MenuIconClose } from '../../../../../assets/images'

interface HeaderProps {
  toggleMenuMobiles?: () => void
}

const Header: React.FC<HeaderProps> = ({ toggleMenuMobiles }) => {
  return (
    <div className="menuMobiles__header">
      <div onClick={toggleMenuMobiles} className="menuMobiles__header--wrap">
        <div className="menuMobiles__header--wrap-close">
          <MenuIconClose />
        </div>
      </div>
    </div>
  )
}
export default Header
