import React from 'react'
import Wrapper from '../../../components/modules/_wrapper'
import { Trans, useTranslation } from 'react-i18next'
import { AvatarIcon } from '../../../../../assets/images/svgs'
import { ROUTER_URL } from '../../../../../utils/const'

interface ExportCompleteProps {}

const ExportComplete: React.FC<ExportCompleteProps> = () => {
  const [t] = useTranslation('translation')

  const list = [
    {
      name: t('export-complete.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('export-complete.bread_crumb2'),
      link: ROUTER_URL.CONTROL_AVATAR,
    },
    {
      name: t('export-complete.bread_crumb_active'),
      link: ROUTER_URL.EXPORT_COMPLETE,
    },
  ]

  return (
    <Wrapper 
      breadcrum={list}
      Icon={AvatarIcon}
      title={t('export-complete.title_header')}
      className="status-wrap"
      buttonText={t(`export-complete.button_to_control_avatar`)}
      buttonLink={ROUTER_URL.CONTROL_AVATAR}
      classNameBtn={'normal'}
    >
      <div className="export-complete base_style center_wrap comp_wrap">
        <h2 className="distance-h2-to-text">{t(`export-complete.export_complete`)}</h2>
        <div className="distance-text-to-noti">
          <p>
            <Trans
              i18nKey="export-complete.export_description"
              components={{
                br: <br />,
              }}
            />
          </p>
        </div>
      </div>
    </Wrapper>
  )
}

export default ExportComplete
