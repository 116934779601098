import * as React from 'react'

interface MenuProps {
  height?: string
  width?: string
}
const MenuEmail: React.FC<MenuProps> = ({ height, width }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6443 13.8685C12.4469 13.9711 12.2252 14.0249 11.9998 14.0249C11.7744 14.0249 11.5527 13.9711 11.3553 13.8685L2 9V18.1134C2.00053 18.6136 2.21141 19.0932 2.58637 19.4469C2.96133 19.8006 3.46973 19.9995 4 20H20C20.5303 19.9995 21.0387 19.8006 21.4136 19.4469C21.7886 19.0932 21.9995 18.6136 22 18.1134V9L12.6443 13.8685Z"
        fill=""
      />
      <path
        d="M20 4H4C3.46973 4.00051 2.96133 4.20386 2.58637 4.56542C2.21141 4.92698 2.00053 5.41722 2 5.92855V7.53567C1.99998 7.59317 2.01595 7.64963 2.04627 7.69916C2.07658 7.74868 2.12011 7.78946 2.17233 7.81723L11.839 12.96C11.8883 12.9862 11.9437 13 12 13C12.0563 13 12.1117 12.9862 12.161 12.96L21.8277 7.81723C21.8799 7.78946 21.9234 7.74868 21.9537 7.69916C21.984 7.64963 22 7.59317 22 7.53567V5.92855C21.9995 5.41722 21.7886 4.92698 21.4136 4.56542C21.0387 4.20386 20.5303 4.00051 20 4Z"
        fill=""
      />
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MenuEmail
