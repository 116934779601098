import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export const editableOptions = [
  { value: 'FBX', name: 'editable', label: 'FBX' },
  { value: 'VRM', name: 'editable', label: 'VRM' },
]

export const validator = (t: any) => {
  return yupResolver(
    Yup.object().shape({
      editable: Yup.object().required(t('download_avatar.select_format_error')).nullable(),
    })
  )
}

export const listCreditCards = [
  { brand: 'VISA', value: '************5168' },
]

export const defaultValues = {
  edit: 'Custom',
  custom: '',
  editable: '',
  voxel: 'FBX',
  'custom-label': 'Customアバター',
  'voxel-label': 'Voxelアバター',
  credit: '************5168',
}
