import axios from 'axios'
const token = localStorage.getItem('access_token')
const refresh_token = localStorage.getItem('refresh_token')
const headers = { 'Content-Type': 'application/json', 'Content-Language': 'JP' }
if (token) {
  headers['Authorization'] = `Bearer ${token}`
}
const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
})
const refreshTokenResponse = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${refresh_token}`,
      'Content-Language': 'JP',
    },
  }
  const result = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/customer/refresh',
    null,
    config
  )
  if (result) {
    localStorage.setItem('access_token', result.data.data.access_token)
    localStorage.setItem('refresh_token', result.data.data.refresh_token)
    localStorage.setItem('content-language', 'JP')
  }
}

instance.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem('access_token')
    if (accessToken) {
      config.headers.common['Authorization'] = 'Bearer ' + accessToken
    }
    config.headers.common['Content-Type'] = 'application/json'
    config.headers.common['Access-Control-Expose-Headers'] = 'Content-Disposition, Content-Name'
    config.headers.common['Content-Language'] =
      localStorage.getItem('i18nextLng') === 'ja' ? 'JP' : 'EN'
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (res) {
    return res
  },
  async function (err) {
    if (
      err.request.responseType === 'blob' &&
      err.response.data instanceof Blob &&
      err.response.data.type &&
      err.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      await new Promise((resolve, reject) => {
        let reader: any = new FileReader()
        reader.onload = () => {
          err.response.data = JSON.parse(reader.result)
          resolve(Promise.reject(err))
        }

        reader.onerror = () => {
          reject(err)
        }

        reader.readAsText(err.response.data)
      })
        .then((err: any) => {
        })
        .catch((error: any) => {
          throw error?.response?.data
        })
    } else if (err?.response?.status === 401) {
      refreshTokenResponse()
    } else
      throw (
        err?.response?.data || {
          message:
            localStorage.getItem('i18nextLng') === 'ja'
              ? 'システムエラーが発生しました。'
              : 'A system error has occurred.',
        }
      )
  }
)
export default instance
