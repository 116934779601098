import React from 'react'
import { Link } from 'react-router-dom'
import { defaultAvatar } from '../../../../../../assets/images'
import { useState, useEffect } from 'react'
import { ROUTER_URL } from '../../../../../../utils/const'
import { GET_PROFILE_IMAGE } from '../../../../../../utils/endpoints'
import axios from '../../../../../../utils/axios'

interface MyPageButtonProps {}
const MyPageButton: React.FC<MyPageButtonProps> = () => {
  const [profileImage, setProfileImage] = useState('')
  useEffect(() => {
    try {
      axios.get(GET_PROFILE_IMAGE, {responseType: 'blob'}).then(res => {
        setProfileImage(URL.createObjectURL(res?.data))
    }) } catch (error) {
    }
  }, [])
  return (
    <Link to={ROUTER_URL.SCAN_BOOKING}>
      <button className="my-page-button">
        <img
          className="my-page-button__represent"
          src={profileImage || defaultAvatar}
          height="35"
          width="35"
          alt="represent"
        />
        <p className="my-page-button__label">My Page</p>
      </button>
    </Link>
  )
}

export default MyPageButton
