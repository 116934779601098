import React from 'react'

interface DropdownIconUpProps {
  height?: string
  width?: string
}

const DropdownIconUp: React.FC<DropdownIconUpProps> = ({ height, width }) => {
  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 7L7 0.999999L1 7"
        stroke="#111947"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DropdownIconUp
