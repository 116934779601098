import React from 'react'

interface LogoDarkProps {
  height?: string
  width?: string
}

const LogoDark: React.FC<LogoDarkProps> = ({ height, width }) => {
  return (
    <svg
    width={width || '151'}
    height={height || '24'}
    viewBox="0 0 151 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
<g>
	<g>
		<path fill="#323333" d="M113.1,6.7c-0.8,0-1.4,0.6-1.4,1.4v8.6c0,1,0,2.1-0.5,2.9c-0.5,0.8-1.6,1.7-3.1,1.7c-1.5,0-2.6-0.9-3.1-1.7
			c-0.5-0.8-0.5-1.9-0.5-2.9V8.1c0-0.8-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4v9.3c0,1.9,0.5,3.3,1.6,4.5c0.9,0.9,2.4,2.1,4.9,2.1
			c2.5,0,4-1.1,4.9-2.1c1.1-1.2,1.6-2.7,1.6-4.5V8.1C114.5,7.3,113.9,6.7,113.1,6.7z"/>
		<path fill="#323333" d="M30.9,7.4c-0.3-0.4-0.7-0.7-1.2-0.7c-0.6,0-1.2,0.4-1.4,0.9l-3.4,8.5c-0.2,0.5-0.6,0.8-1.2,0.8
			c-0.5,0-1-0.3-1.2-0.8l-3.4-8.5c-0.2-0.6-0.8-0.9-1.4-0.9c-0.5,0-1,0.2-1.2,0.7c-0.3,0.4-0.3,0.9-0.1,1.4l6,14.3
			c0.2,0.6,0.8,0.9,1.4,0.9c0.6,0,1.1-0.4,1.4-0.9l6-14.3C31.3,8.3,31.2,7.8,30.9,7.4z"/>
		<path fill="#323333" d="M58.1,6.7h-10c-0.8,0-1.4,0.6-1.4,1.3s0.6,1.3,1.4,1.3h2.3c0.7,0,1.2,0.5,1.2,1.1v12.1c0,0.7,0.6,1.3,1.4,1.3
			h0.2c0.8,0,1.4-0.6,1.4-1.3V10.5c0-0.6,0.5-1.1,1.2-1.1h2.3c0.8,0,1.4-0.6,1.4-1.3S58.9,6.7,58.1,6.7z"/>
		<path fill="#323333" d="M89.3,21.8l-3.1-3.9c-0.2-0.3-0.3-0.6-0.2-0.9c0.1-0.3,0.3-0.6,0.6-0.7c1.7-0.9,2.7-2.4,2.7-4.4
			C89.2,11,89,9,87,7.7c-1.1-0.7-2.5-1-4.8-1h-1.9c-0.8,0-1.5,0.6-1.5,1.4v14.5c0,0.8,0.7,1.4,1.5,1.4h0.1c0.8,0,1.5-0.6,1.5-1.4V17
			h0.3l4.8,6.4c0.3,0.4,0.7,0.6,1.2,0.6c0.6,0,1.1-0.3,1.3-0.8C89.7,22.7,89.6,22.2,89.3,21.8z M86.3,11.9c0,2.3-2.3,2.7-3.7,2.7
			h-0.7V9.4h0.8C85.1,9.4,86.3,10.2,86.3,11.9z"/>
		<path fill="none" d="M40.9,13.6c-0.2-0.5-0.7-0.8-1.2-0.8c-0.5,0-1,0.3-1.2,0.8l-0.7,1.5C37.3,16,38,17,39,17h0.7h0.7
			c1,0,1.6-1,1.2-1.9L40.9,13.6z"/>
		<g>
			<path fill="#12C4C4" d="M39.1,5.3c1.5,0,2.7-1.2,2.7-2.6c0-1.4-1.2-2.6-2.7-2.6c-1.5,0-2.7,1.2-2.7,2.6C36.5,4.1,37.7,5.3,39.1,5.3z
				"/>
			<path fill="#12C4C4" d="M47.2,21.9L40.4,7.5c-0.3-0.5-0.7-0.8-1.2-0.8s-1,0.3-1.2,0.8l-6.9,14.4c-0.2,0.4-0.2,1,0.1,1.4
				c0.3,0.4,0.7,0.7,1.2,0.7c0.6,0,1.1-0.3,1.3-0.9l1.3-2.7c0.2-0.4,0.6-0.7,1.1-0.7h6.1c0.5,0,0.9,0.3,1.1,0.7l1.3,2.7
				c0.2,0.5,0.8,0.9,1.3,0.9c0.5,0,1-0.3,1.2-0.7C47.4,22.9,47.4,22.4,47.2,21.9z M37.4,15.2l0.7-1.5c0.2-0.4,0.6-0.7,1.1-0.7
				c0.5,0,0.9,0.3,1.1,0.7l0.7,1.5c0.2,0.4,0.1,0.8-0.1,1.1c-0.2,0.4-0.6,0.6-1,0.6h-1.3c-0.4,0-0.8-0.2-1-0.6
				C37.2,16,37.2,15.5,37.4,15.2z"/>
		</g>
		<g>
			<path fill="#FF6C6C" d="M8.2,5.3c1.5,0,2.7-1.2,2.7-2.6c0-1.4-1.2-2.6-2.7-2.6S5.6,1.3,5.6,2.7C5.6,4.1,6.8,5.3,8.2,5.3z"/>
			<path fill="#FF6C6C" d="M16.3,21.9L9.4,7.5C9.2,7,8.8,6.7,8.2,6.7C7.7,6.7,7.3,7,7,7.5L0.1,21.9c-0.2,0.4-0.2,1,0.1,1.4
				C0.5,23.7,1,24,1.5,24c0.6,0,1.1-0.3,1.3-0.9l1.3-2.7c0.2-0.4,0.6-0.7,1.1-0.7h6.1c0.5,0,0.9,0.3,1.1,0.7l1.3,2.7
				c0.2,0.5,0.8,0.9,1.3,0.9c0.5,0,1-0.3,1.2-0.7C16.5,22.9,16.5,22.4,16.3,21.9z M9.9,16.3c-0.2,0.4-0.6,0.6-1,0.6H7.6
				c-0.4,0-0.8-0.2-1-0.6c-0.2-0.3-0.2-0.8-0.1-1.1l0.7-1.5C7.3,13.3,7.7,13,8.2,13c0.5,0,0.9,0.3,1.1,0.7l0.7,1.5
				C10.2,15.5,10.1,16,9.9,16.3z"/>
		</g>
		<g>
			<path fill="#FF6C6C" d="M67.5,5.3c1.5,0,2.7-1.2,2.7-2.6c0-1.4-1.2-2.6-2.7-2.6c-1.5,0-2.7,1.2-2.7,2.6C64.9,4.1,66,5.3,67.5,5.3z"
				/>
			<path fill="#FF6C6C" d="M75.6,21.9L68.7,7.5c-0.3-0.5-0.7-0.8-1.2-0.8c-0.5,0-1,0.3-1.2,0.8l-6.9,14.4c-0.2,0.4-0.2,1,0.1,1.4
				c0.3,0.4,0.7,0.7,1.2,0.7c0.6,0,1.1-0.3,1.3-0.9l1.3-2.7c0.2-0.4,0.6-0.7,1.1-0.7h6.1c0.5,0,0.9,0.3,1.1,0.7l1.3,2.7
				c0.2,0.5,0.8,0.9,1.3,0.9c0.5,0,1-0.3,1.2-0.7C75.8,22.9,75.8,22.4,75.6,21.9z M69.2,16.3c-0.2,0.4-0.6,0.6-1,0.6h-1.3
				c-0.4,0-0.8-0.2-1-0.6c-0.2-0.3-0.2-0.8-0.1-1.1l0.7-1.5c0.2-0.4,0.6-0.7,1.1-0.7c0.5,0,0.9,0.3,1.1,0.7l0.7,1.5
				C69.5,15.5,69.4,16,69.2,16.3z"/>
		</g>
		<g>
			<path fill="#12C4C4" d="M95.4,6.8c-0.8,0-1.5,0.7-1.5,1.5v14.1c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5V8.3
				C96.9,7.5,96.3,6.8,95.4,6.8z"/>
			<path fill="#12C4C4" d="M95.3,0.1c-1.5,0-2.7,1.2-2.7,2.6c0,1.4,1.2,2.6,2.7,2.6c1.5,0,2.7-1.2,2.7-2.6C97.9,1.3,96.8,0.1,95.3,0.1z
				"/>
		</g>
		<path fill="#323333" d="M139.5,21.9l-5.6-14.3c-0.2-0.6-0.7-0.9-1.3-0.9c0,0,0,0,0,0c-0.6,0-1.1,0.4-1.3,0.9l-5.6,14.3
			c-0.2,0.5-0.1,1,0.2,1.4c0.3,0.4,0.7,0.6,1.1,0.6c0.6,0,1.1-0.4,1.3-0.9l3.2-8.5c0.2-0.5,0.6-0.8,1.1-0.8s0.9,0.3,1.1,0.8l3.2,8.5
			c0.2,0.6,0.7,0.9,1.3,0.9c0.5,0,0.9-0.2,1.1-0.6C139.6,22.9,139.7,22.4,139.5,21.9z"/>
		<g>
			<ellipse fill="#FF6C6C" cx="124.1" cy="2.7" rx="2.7" ry="2.6"/>
			<path fill="#FF6C6C" d="M131,21.9l-5.6-14.3c-0.2-0.6-0.7-0.9-1.3-0.9c0,0,0,0,0,0c-0.6,0-1.1,0.4-1.3,0.9l-5.6,14.3
				c-0.2,0.5-0.1,1,0.2,1.4c0.3,0.4,0.7,0.6,1.1,0.6c0.6,0,1.1-0.4,1.3-0.9l3.2-8.5c0.2-0.5,0.6-0.8,1.1-0.8h0
				c0.5,0,0.9,0.3,1.1,0.8l3.2,8.5c0.2,0.6,0.7,0.9,1.3,0.9c0.5,0,0.9-0.2,1.1-0.6C131.2,22.9,131.2,22.4,131,21.9z"/>
		</g>
	</g>
	<path  fill="#323333" d="M150.4,7.3l0,16.1c0,0.3-0.3,0.6-0.6,0.6l-7,0c-0.3,0-0.6-0.3-0.6-0.6V7.3c0-0.3,0.3-0.6,0.6-0.6l7,0
		C150.2,6.7,150.4,7,150.4,7.3z"/>
	<g>
		<g>
			<path  fill="#FFFFFF" d="M148.5,20.3v1.4c0,0.2,0,0.4-0.1,0.4s-0.2,0.1-0.4,0.1h-3.5c-0.2,0-0.4,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4
				v-1.5c0-0.2,0-0.4,0-0.6s0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3-0.1,0.4-0.1
				c0.5,0,0.8,0.2,1,0.7c0.2-0.6,0.6-0.9,1.2-0.9c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.1,0.1,0.3,0.1,0.5
				C148.5,19.8,148.5,20,148.5,20.3z M144.5,21.3h1.2v-0.9c0-0.2,0-0.4-0.1-0.6c0-0.1-0.1-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
				c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.2-0.1,0.4-0.1,0.8V21.3z M146.4,20.4v1h1.4v-1c0-0.6-0.2-1-0.7-1c-0.2,0-0.4,0.1-0.5,0.3
				C146.5,19.8,146.4,20,146.4,20.4z"/>
			<path fill="#FFFFFF" d="M147,15v1.7c0.2,0,0.4,0,0.5-0.1c0.2-0.1,0.3-0.2,0.3-0.3s0.1-0.3,0.1-0.4c0-0.1,0-0.2,0-0.3
				c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.1-0.2-0.2-0.2s-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
				c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.3s0.2,0.3,0.2,0.5s0.1,0.4,0.1,0.7c0,0.6-0.2,1-0.5,1.3
				c-0.3,0.3-0.8,0.5-1.3,0.5c-0.3,0-0.5,0-0.7-0.1s-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.7
				c0-0.3,0.1-0.6,0.2-0.9s0.3-0.4,0.6-0.6c0.2-0.1,0.5-0.2,0.7-0.2c0.2,0,0.4,0.1,0.4,0.2S147,14.8,147,15z M146.5,16.8v-1.6
				c-0.3,0-0.5,0.1-0.7,0.2c-0.1,0.1-0.2,0.3-0.2,0.6c0,0.2,0.1,0.4,0.2,0.5C146,16.6,146.2,16.7,146.5,16.8z"/>
			<path fill="#FFFFFF" d="M145.1,13.5L145.1,13.5l-0.5-0.1c-0.1,0-0.3,0-0.3,0s-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.1
				c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0h0.6v-0.3c0-0.1,0-0.2,0.1-0.3
				s0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.1c0,0.1,0.1,0.2,0.1,0.4v0.2h1.6c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2-0.1c0,0,0.1-0.1,0.1-0.2
				c0-0.1,0-0.1,0-0.2s0-0.2,0-0.2c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.2,0.1,0.4,0.1,0.7
				c0,0.3,0,0.5-0.1,0.6c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0-0.4,0.1-0.7,0.1h-1.7v0.1c0,0.1,0,0.2-0.1,0.3s-0.1,0.1-0.2,0.1
				s-0.2,0-0.2-0.1C145.1,13.7,145.1,13.6,145.1,13.5z"/>
			<path fill="#FFFFFF" d="M148.1,8.7c0.2,0.2,0.3,0.4,0.4,0.6s0.1,0.4,0.1,0.7c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.4
				c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.1,0-0.2-0.1-0.4c0-0.2-0.1-0.4-0.1-0.6
				s-0.1-0.3-0.1-0.5c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.3-0.1-0.5-0.2
				c-0.1-0.1-0.3-0.3-0.4-0.5S145,9.7,145,9.4c0-0.4,0-0.7,0.1-0.9c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.5-0.1,0.8-0.1
				c0.2,0,0.4,0,0.5,0c0.1,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5-0.1c0.2-0.1,0.3-0.1,0.3-0.1c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.3
				c0,0.1,0,0.2-0.1,0.3C148.4,8.5,148.3,8.6,148.1,8.7z M146.8,8.7c0,0.1,0.1,0.3,0.1,0.6c0.1,0.2,0.1,0.4,0.1,0.5
				c0,0.1,0.1,0.2,0.1,0.3s0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.3-0.2s0.1-0.2,0.1-0.4c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.3
				c-0.1-0.1-0.4-0.1-0.7-0.1H146.8z"/>
		</g>
	</g>
</g>
</svg>
  )
}

export default LogoDark
