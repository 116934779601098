import React from 'react'

interface AAndRProps {
  height?: string
  width?: string
}

const AAndR: React.FC<AAndRProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="AR" width={width || "158"} height={height || "247"} viewBox="0 0 158 247">
      <defs>
        <style>{'.cls-9987-1{fill:#12c4c4}'}</style>
      </defs>
      <path id="Path_860" d="M33 84h46L56 29zm1.5-.993L56 31.594l21.5 51.413z" className="cls-9987-1" />
      <path id="Path_861" d="M0 84h1.112L39 0h-1.112z" className="cls-9987-1" />
      <path id="Path_862" d="M73 0l37.605 84H112L74.1 0z" className="cls-9987-1" />
      <path
        id="Path_863"
        d="M125 166c0-16.121-10.723-25-30.193-25H68v50h26.807c19.47 0 30.193-8.878 30.193-25zm-30.193 24H69v-48h25.81c19.1 0 29.2 8.3 29.2 24-.01 15.474-10.376 24-29.203 24z"
        className="cls-9987-1"
      />
      <path
        id="Path_864"
        d="M158 166.339C158 134.672 134.494 115 96.655 115H56v1h40.655C133.877 116 157 135.29 157 166.339c0 20.347-10.127 35.892-28.518 43.774l-.565.242L153.485 247h1.221l-25.267-36.212C147.863 202.664 158 186.9 158 166.339z"
        className="cls-9987-1"
      />
      <path id="Path_865" d="M98.589 216H68v31h1v-30h29.065l20.718 30H120z" className="cls-9987-1" />
    </svg>
  )
}

export default AAndR
