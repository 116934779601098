import { useForm, FormProvider } from 'react-hook-form'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { RadioInput } from '../../components/atoms/radio-input'
import {  Button } from '../../components/atoms/buttons'
import {
  radioFields,
  defaultValues,
  validator,
  dataTestCreadit,
  dataTest,
  dataTest2,
} from './constants'
import { CreaditIconNew } from '../../../../assets/images/svgs'
import React, { useState } from 'react'
import classnames from 'classnames'
import Wrapper from '../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../utils/const'

interface ManageCreaditProps {}
const AddCreadit: React.FC<ManageCreaditProps> = () => {
  const [t] = useTranslation('translation')
  const [selectOption, setSelectOption] = useState('Custom')
  const [activeVisa, setActiveVisa] = useState('1')
  const handleChange = (e: any) => {
    setSelectOption(e.value)
  }
  const options = [
    { value: 'Custom', name: 'Custom', label: 'Custom' },
    { value: 'FBX', name: 'FBX', label: 'FBX' },
  ]

  const methods = useForm({
    defaultValues,
    resolver: validator(),
  })

  const { handleSubmit } = methods

  const onSubmit = (values: any, errors: any) => {}

  const renderTableData = dataTest.map(({ type, value }) => {
    return (
      <div key={type} className="box-table__avatar-table__group">
        <div className="box-table__avatar-table__group-th">{t(type)}</div>
        <div className="box-table__avatar-table__group-td">{value}</div>
      </div>
    )
  })

  const renderTableData2 = dataTest2.map(({ type, value }) => {
    return (
      <div key={type} className="box-table__avatar-table__group">
        <div className="box-table__avatar-table__group-th">{t(type)}</div>
        <div className="box-table__avatar-table__group-td">{value}</div>
      </div>
    )
  })

  const renderSelectFields = (
    <div className="input_sort">
      <Select
        onChange={handleChange}
        placeholder={t('download_avatar.select_format')}
        options={options}
        isSearchable={false}
      />
    </div>
  )

  const renderCreaditVisa = (
    <>
      {dataTestCreadit.map(({ name, number }) => {
        return (
          <div className={classnames('box-creadit_visa1', { activeVisa: activeVisa === number })}>
            {/* render radio input  */}
            <div className="radio_input" onClick={() => setActiveVisa(number)}>
              <div className="box-input">
                <RadioInput
                  isRequire={radioFields.isRequire}
                  name={radioFields.name}
                  type={radioFields.type}
                  value={radioFields.value}
                />
              </div>
            </div>
            <span className="visa">{name}</span>
            <span className="visa-card">
              <span className="visa-number">****</span>
              <span className="visa-number">-</span>
              <span className="visa-number">****</span>
              <span className="visa-number">-</span>
              <span className="visa-number">****</span>
              <span className="visa-number">-</span>
              <span className="visa-show">*</span>
              <span className="visa-number_show">{number}</span>
            </span>
          </div>
        )
      })}
    </>
  )
  const list = [
    {
      name: t('download_avatar.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('download_avatar.avatar_management'),
      link: ROUTER_URL.DOWNLOAD_AVATAR,
    },
    {
      name: t('download_avatar.download'),
      link: ROUTER_URL.DOWNLOAD_AVATAR_EDITED,
    },
  ]
  return (
    <Wrapper breadcrum={list} title={t('download_avatar.title')} className="add-creadit" isNoMarginTop={true}>
      <div className="add-creadit_content">
        <div className="add-creadit_content-img">
          <img src="https://picsum.photos/350/480" alt="" />
        </div>
        <div className="add-creadit_content-box">
          <div className="add-creadit_content-table">
            <h3 className="table-title">{t('download_avatar.avatar_name')}</h3>
            <div className="box-table">
              <div className="box-table__avatar-table">
                {selectOption === 'Custom' ? renderTableData : renderTableData2}
              </div>
            </div>
            <div className="box-select">
              <div className="box-select_title">
                <h3>{t('download_avatar.file_format_selection')}</h3>
              </div>
              <div className="box-select-input">{renderSelectFields}</div>
            </div>
            <div className="box-price">
              <div className="box-price_title">{t('download_avatar.download_fee')}</div>
              <div className="box-price_number">{t('download_avatar.money_sign')}5,000</div>
            </div>
          </div>
          <div className="box-creadit">
            <h3 className="box-creadit-title">{t('download_avatar.credit_information')}</h3>
            <div className="box-creadit-title_content">{t('download_avatar.credit_describe')}</div>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="box-creadit_visa">
                  {renderCreaditVisa}
                  <div className="box-creadit_visa1 box-new-visa">
                    <CreaditIconNew />
                    <span className="visa new-visa_icon">{t('download_avatar.add_card')}</span>
                  </div>
                </div>
              </form>
            </FormProvider>
            <div className="box-creadit__send">
              <Button type="submit">{t('download_avatar.download')}</Button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default AddCreadit
