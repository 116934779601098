import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import { setLang } from '../../../../stores'
import { AmericanFlagTop, DropdownIconTop, JapaneseFlagTop } from '../../../../../assets/images/svgs'
import { useDispatch } from 'react-redux'
import axios from '../../../../../utils/axios'
import { GET_DEFAULT_LANGUAGE, UPDATE_DEFAULT_LANGUAGE } from '../../../../../utils/endpoints'

interface SwitchLanguagesTopProps {}

export const SwitchLanguagesTop: React.FC<SwitchLanguagesTopProps> = () => {
  const [, i18n] = useTranslation('translation')
  const [selectInput, setSelectInput] = useState(true)
  const dispatch = useDispatch()


  const setDefaultLanguage = async () => {
    try {
      await axios.get(GET_DEFAULT_LANGUAGE).then(res => {
        let dbLang = res?.data.data
        let i18nextLng = 'ja'
        if (dbLang === 'JP') {
          i18nextLng = 'ja'
          setSelected('日本語')
        } else if (dbLang === 'EN') {
          i18nextLng = 'en'
          setSelected('US')
        }
        localStorage.setItem('i18nextLng', i18nextLng)
        i18n.changeLanguage(i18nextLng)
        dispatch(setLang(i18nextLng))
      })
    } catch (error) {
    }
  }
  const getLanguage = () => {
    const currentLang = localStorage.getItem('i18nextLng') || i18n.language
    if (currentLang === 'en') return 'US'
    return '日本語'
  }
  const [selected, setSelected] = useState(getLanguage())

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      setDefaultLanguage()
    } else {
      let country
      switch (selected) {
        case 'US':
          country = 'en'
          break
        case '日本語':
          country = 'ja'
      }
      i18n.changeLanguage(country)
      dispatch(setLang(country))
  }
  }, [selected])

  const jp = () => {
    return (
      <span className="switch-languages-top__group">
        <JapaneseFlagTop />
        <span className="switch-languages-top__name switch-languages-top__japan">日本語</span>
      </span>
    )
  }
  const uS = () => {
    return (
      <span className="switch-languages-top__group">
        <AmericanFlagTop />
        <span className="switch-languages-top__name switch-languages-top__us">English</span>
      </span>
    )
  }

  const options = [
    { value: '日本語', label: jp() },
    { value: 'US', label: uS() },
  ]

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownIconTop />
      </components.DropdownIndicator>
    )
  }

  const customStyle = {
    control: (base: any) => ({
      ...base,
      width: '140px',
      height: '43px',
      borderWidth: 0,
      borderRadius: '24px',
      outline: 'none',
      fontSize: '16px',
      lineHeight: '23.76px',
      boxShadow: 'none',
      background: 'white',
      color: '#313232',
      '&:hover': {
        border: 0,
      },
      cursor: 'pointer',
    }),
    menu: (base: any) => ({
      ...base,
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '23.76px',
      border: '0 !important',
      outline: 'none',
      boxShadow: 'none',
      overflow: 'hidden',
      padding: 0,
      width: '180px',
      borderRadius: '5px',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: '#b1b1b1',
    }),
    singleValue: (base: any) => ({
      ...base,
      color: '#313232',
      margin: 0,
      height: '100%',
      paddingLeft: '6px',
    }),
    option: (styles: any, { isFocused }: any) => ({
      ...styles,
      backgroundColor: isFocused ? '#12C4C4' : '#ccc',
      color: isFocused ? '#ffffff' : '#000',
      height: '50px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: 0,
      height: '100%',
      width: '100%',
    }),
    indicatorsContainer: () => ({
      display: 'inline-block',
      padding: 0,
      paddingRight: '4px',
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      border: '0 !important',
      outline: 'none',
      boxShadow: 'none',
    }),
  }

  async function updateLanguage(language: string, value: any){
    if (localStorage.getItem('access_token')) {
      await axios.post(UPDATE_DEFAULT_LANGUAGE, {"language": language})
      localStorage.setItem('i18nextLng', language)
    }
    setSelected(value)
    setSelectInput(!selectInput)
  }

  const handleChange = (e: any) => {
    let language = ''
    switch(e.value){
      case 'US':
        language = 'EN'
        break;
      default:
        language = 'JP'
        break;
    }
    updateLanguage(language, e.value)
  }

  return (
    <div className="switch-languages-top">
      <Select
        options={options}
        value={options.find((o: any) => o.value === selected)}
        onChange={handleChange}
        styles={customStyle}
        isSearchable={false}
        components={{ DropdownIndicator }}
        menuPlacement="auto"
      />
    </div>
  )
}

export default SwitchLanguagesTop
