import React from 'react'

interface DancingProps {
  height?: string
  width?: string
}

const Dancing: React.FC<DancingProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="72" viewBox="0 0 80 72">
      <g id="Group_10884" data-name="Group 10884" transform="translate(-460 -1978.699)">
        <g id="長方形_3522" data-name="長方形 3522">
          <rect
            id="Rectangle_3549"
            data-name="Rectangle 3549"
            width="80"
            height="72"
            transform="translate(460 1978.699)"
            fill="none"
          />
        </g>
        <g id="Group_10883" data-name="Group 10883">
          <path
            id="Path_2015"
            data-name="Path 2015"
            d="M523.344,1986.8l-18.922,10.621a10.5,10.5,0,1,0-14.8,8.306l-18.585,10.429a4.433,4.433,0,0,0-1.834,5.754c.04.086.084.17.136.265a4.425,4.425,0,0,0,6.016,1.7l13.437-7.518v8.511a14.822,14.822,0,0,1-1.791,7.052l-6.177,11.383,2.636,1.431,6.178-11.382a17.843,17.843,0,0,0,2.154-8.484v-13.629l-17.9,10.018a1.418,1.418,0,0,1-1.928-.536l-.049-.1a1.425,1.425,0,0,1,.591-1.85l21.526-12.078a10.493,10.493,0,0,0,8.9-4.994l21.887-12.285a1.446,1.446,0,0,1,1.941.527,1.44,1.44,0,0,1-.486,1.954l-18.5,10.672V2025.2a14.871,14.871,0,0,1-1.431,6.346l-4.816,10.136a2.839,2.839,0,0,1-5.159-2.37l3.661-7.345-2.685-1.34-6.552,13.134,2.684,1.34.669-1.34a5.805,5.805,0,0,0,2.456,2.044,5.894,5.894,0,0,0,2.381.507,5.81,5.81,0,0,0,5.255-3.343l4.816-10.136a17.9,17.9,0,0,0,1.721-7.633v-20.9l16.908-9.756.089-.049a4.437,4.437,0,0,0,1.577-6.074A4.46,4.46,0,0,0,523.344,1986.8ZM494,2003.7a7.5,7.5,0,1,1,7.5-7.5A7.508,7.508,0,0,1,494,2003.7Z"
            fill="#12c4c4"
          />
          <rect
            id="Rectangle_3550"
            data-name="Rectangle 3550"
            width="12.088"
            height="2"
            transform="translate(517.512 2004.387) rotate(-30.326)"
            fill="#12c4c4"
          />
          <rect
            id="Rectangle_3551"
            data-name="Rectangle 3551"
            width="7.796"
            height="2"
            transform="matrix(0.861, -0.508, 0.508, 0.861, 521.424, 2007.462)"
            fill="#12c4c4"
          />
        </g>
      </g>
    </svg>
  )
}

export default Dancing
