import * as React from 'react'

interface QuestionIconProps {
  height?: string
  width?: string
}
const QuestionIcon: React.FC<QuestionIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14,0C6.3,0,0,6.3,0,14s6.3,14,14,14s14-6.3,14-14S21.7,0,14,0z M14,25.3c-6.2,0-11.3-5-11.3-11.3
                                        C2.7,7.8,7.8,2.7,14,2.7S25.3,7.8,25.3,14S20.2,25.3,14,25.3z M20.1,10.9c0,3.8-4.1,3.8-4.1,5.2v0.4c0,0.4-0.3,0.7-0.7,0.7h-2.6
                                        c-0.4,0-0.7-0.3-0.7-0.7V16c0-2,1.5-2.8,2.7-3.5c1-0.6,1.6-0.9,1.6-1.7c0-1-1.2-1.6-2.2-1.6c-1.3,0-1.9,0.6-2.8,1.7
                                        c-0.2,0.3-0.6,0.3-0.9,0.1L8.8,9.9c-0.3-0.3-0.4-0.7-0.2-1c1.3-2,3-3.1,5.7-3.1C17.1,5.9,20.1,8,20.1,10.9z M16.4,20.3
                                        c0,1.3-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4S16.4,19,16.4,20.3z"
        fill="#9496AB"
      />
    </svg>
  )
}

export default QuestionIcon
