import { SignupIconTop, LoginIconTop } from '../../../../../assets/images'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MyPageButton } from '../../atoms/buttons'
import { useSelector } from 'react-redux'
import { IApplicationState } from '../../../../stores'
import { ROUTER_URL } from '../../../../../utils/const'

interface HeaderProps {
  toggleMenuMobiles: () => void
}

const Body: React.FC<HeaderProps> = ({ toggleMenuMobiles }) => {
  const isSignedIn = useSelector((state: IApplicationState) => state.signin.isSignedIn)
  const history = useHistory()
  const [t] = useTranslation('translation')

  const linkList = [
    {
      id: 0,
      label: [t('top.content_label_a'), t('top.content_label_b')],
      section: '#top__content',
    },
    {
      id: 1,
      label: [t('top.spread_label_a'), t('top.spread_label_b') + t('top.spread_label_c')],
      section: '#top__spread',
    },
    {
      id: 2,
      label: [
        t('top.customization_label_a'),
        t('top.customization_label_b') + t('top.customization_label_c'),
      ],
      section: '#top__customization',
    },
    { id: 3, label: [t('top.news')], section: '#top__news' },
  ]

  const renderRegisterButton = () => (
    <Link to={{ pathname: ROUTER_URL.SIGNUP, state: { email: 111 } }}>
      <button className="menuMobiles__body--button-redirect">
        <SignupIconTop />
        <span>{t('header_top.signup')}</span>
      </button>
    </Link>
  )

  const renderLoginButton = () => (
    <Link to={ROUTER_URL.SIGNIN}>
      <button className="menuMobiles__body--button-auth">
        <LoginIconTop />
        <span>Log In</span>
      </button>
    </Link>
  )

  return (
    <div className="menuMobiles__body">
      <div onClick={toggleMenuMobiles} className="menuMobiles__body--button">
        {isSignedIn ? (
          <MyPageButton />
        ) : (
          <>
            {renderRegisterButton()}
            {renderLoginButton()}
          </>
        )}
      </div>
      <div className="menuMobiles__body--link">
        {linkList.map((item) => (
          <div
            className="menuMobiles__body--link-content"
            key={item.id}
            onClick={() => {
              toggleMenuMobiles()
              history.push({
                pathname: ROUTER_URL.HOME,
                state: { section: item.section },
              })
            }}
          >
            {item.label.map((eachLabel) => (
              <span key={eachLabel}>{eachLabel}</span>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
export default Body
