export const saveBlobAsFile = (blobObject: any, fName: string) => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    const fileUrl = URL.createObjectURL(blobObject);
    a.href = fileUrl;
    a.download = decodeURI(fName);
    a.click();
    window.URL.revokeObjectURL(fileUrl);
    a.remove();
}
