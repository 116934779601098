import Wrapper from '../../components/modules/_wrapper'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { ROUTER_URL } from '../../../../utils/const'

interface SignupSuccessProps {}

const SignupSuccess: React.FC<SignupSuccessProps> = () => {

  const title = '会員登録完了 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = '会員登録完了のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }


  const [, i18n] = useTranslation('translation')

  const list = [
    {
      name: i18n.t('signup.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('signup.breadcrumb_active_success'),
      link: ROUTER_URL.SIGNUP_SUCCESS,
    },
  ]

  return (
    <Wrapper
      breadcrum={list}
      buttonLink={ROUTER_URL.SIGNIN}
      buttonText={i18n.t('reset_password.success_btn')}
      className="password default-padding"
      isNoMarginTop={false}
    >
      <h2 id="signup-title-success" className="password__title title-h2 distance-h2-to-text">
        {i18n.t('signup.success_title')}
      </h2>
      <div className="password__describe-success distance-text-to-btn">
        <div className="password__describe-content1">{i18n.t('signup.success_content1')}</div>
      </div>
    </Wrapper>
  )
}
export default SignupSuccess
