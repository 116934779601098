import { useFormContext, Controller } from 'react-hook-form'
import Select from 'react-select'
import { styler } from './constants'
import { useBreakpoint } from '../../../../../customize/hooks/useBreakpoint'

interface SelectInputProps {
  name: string
  label?: string
  placeholder?: string
  options: any[]
  asterisk?: boolean
  styles?: any
  errorsBorder?: boolean
  onChangeProps?: Function | any
}

export const SelectInput: React.FC<SelectInputProps> = ({
  name,
  label,
  placeholder = 'Typing here...',
  options,
  asterisk,
  styles,
  errorsBorder,
  onChangeProps,
}) => {
  const point = useBreakpoint()
  const { errors, control } = useFormContext()
  const isError = (errorsBorder || !!errors?.[name]) ?? false
  const message = errors?.[name]?.message ?? null

  return (
    <div className="input">
      {label ? <span className="input__label">{label}</span> : null}
      {asterisk && <span className="input__asterisk">*</span>}
      <Controller
        name={name}
        control={control}
        render={({ onChange, value, ref }) => (
          <Select
            inputRef={ref}
            isSearchable={false}
            options={options}
            placeholder={placeholder}
            styles={styles || styler(isError, point)}
            value={options.find((c) => c.value === value)}
            onChange={(val) => {
              onChange(val.value)
              onChangeProps()
            }}
          />
        )}
      />
      {message ? <span className="form-field-error-message">{message}</span> : null}
    </div>
  )
}
