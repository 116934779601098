import axios from '../utils/interceptors'
import { ADDRESS_SCANNER, GET_COUNTRY, HOUR_AVAILABLE } from '../utils/endpoints'

const generateFakeWrapper = (fakeData: any): Promise<any> => {
  return new Promise((resolve) => {
    resolve({
      data: {
        data: fakeData,
      },
    })
  })
}

const fakeCountries = [
  { value: '001', text: 'ベトナム:test', default: false },
  { value: '002', text: '日本:test', default: true },
  { value: '002', text: 'France:test', default: false },
]

export const getCountries = async (): Promise<any> => {
  try {
    const result = await axios.get(GET_COUNTRY)
    return result
  } catch (error) {
    return generateFakeWrapper(fakeCountries)
  }
}

export const getAddressByCodeArea = async (codeArea: string): Promise<any> => {
  try {
    const result = await axios.get(ADDRESS_SCANNER, {
      params: {
        area: codeArea,
      },
    })
    return result
  } catch (error) {
    return []
  }
}
export const getHour = async (scannerId: string, date: string): Promise<any> => {
  try {
    const result = await axios.get(HOUR_AVAILABLE, {
      params: {
        reservation_scanner_id: scannerId,
        reservation_date: date,
      },
    })
    return result
  } catch (error) {
  }
}
