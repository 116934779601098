import { useTranslation } from 'react-i18next'
import Wrapper from '../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../utils/const'
import QuestionIcon from "../../../../assets/images/svgs/question-icon";
import {useHistory} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { helpCancel1 } from '../../../../assets/images'
import { helpCancel2 } from '../../../../assets/images'

interface HelpQaProps {}

const iconStyle: React.CSSProperties = { color:'#12c4c4',marginRight:'0.5em' };

const HelpQa: React.FC<HelpQaProps> = () => {

    const title = 'よくある質問 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
    const description = 'よくある質問についてのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
    const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
    const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

    // title
    document.title = title;

    // title以外のmeta
    const headData = document.head.children;

    for (let i = 0; i < headData.length; i++) {
        const nameVal = headData[i].getAttribute('name');
        const propertyVal = headData[i].getAttribute('property');
        if (nameVal !== null) {
            if (nameVal.indexOf('keywords') !== -1) {
                headData[i].setAttribute('content', keywords);
            }
            if (nameVal.indexOf('description') !== -1) {
                headData[i].setAttribute('content', description);
            }
        }
        if (propertyVal !== null) {
            // OGP(og:title)の設定
            if (propertyVal.indexOf('og:title') !== -1) {
                headData[i].setAttribute('content', title);
            }
            if (propertyVal.indexOf('og:description') !== -1) {
                headData[i].setAttribute('content', description);
            }
            if (propertyVal.indexOf('og:image') !== -1) {
                headData[i].setAttribute('content', og_image);
            }
        }
    }
    
    const [t] = useTranslation('translation')

    const history = useHistory()

    const onGoTo = (path: any) => () => {
        history.push(path)
    }
    const list = [
        {
            name: t('help.home'),
            link: ROUTER_URL.HOME,
        },
        {
            name: t('help.qa.parent'),
            link: ROUTER_URL.HELP_INDEX,
        },
        {
            name: t('help.qa.breadcrum'),
        },
    ]
    return (
        <Wrapper
            breadcrum={list}
            title={t('help.qa.title')}
            className="base_style help-wrap"
            Icon={QuestionIcon}
            classNameBtn={'normal'}
        >
            <div className="wrapper__content">
                <div className="help-wrap_box distance-content-to-button">
                    <ul className="toc">
                        <li>
                            <a href="#qa_signin">会員登録について</a>
                            <ul>
                                <li><a href="#qa_signin_1">会員登録時に仮登録完了メールが届きません。</a></li>
                                <li><a href="#qa_signin_2">本登録URLの有効期限が切れました。</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#qa_login">ログインについて</a>
                            <ul>
                                <li><a href="#qa_login_1">パスワードを忘れてしまいました。</a></li>
                                <li><a href="#qa_login_2">メールアドレスを忘れてしまいました。</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#qa_account">アカウントについて</a>
                            <ul>
                                <li><a href="#qa_account_1">アカウント情報の確認、変更がしたいです。</a></li>
                                <li><a href="#qa_account_3">AVATARIUMを退会したいです。</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#qa_photography_scanner">撮影について（スキャナ撮影）</a>
                            <ul>
                                <li><a href="#qa_photography_scanner_1">撮影に失敗した / 作成されたアバターに納得がいかない場合、撮影のやり直しはできますか？</a>
                                </li>
                                <li><a href="#qa_photography_scanner_2">予約した内容はどこで確認できますか？</a></li>
                                <li><a href="#qa_photography_scanner_3">撮影予約内容のキャンセル、変更がしたいです。</a></li>
                                <li><a href="#qa_photography_scanner_4">撮影受付の時間に間に合いませんでした。</a></li>
                                <li><a href="#qa_photography_scanner_5">予約とは違うスキャナ設置場所に行ってしまいました。</a></li>
                            </ul>
                        </li>
                    </ul>

                    <div id="qa_signin" className="section">
                        <h2>会員登録について</h2>

                        <div id="qa_signin_1" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight} />会員登録時に仮登録完了メールが届きません。</h3>
                            <p>
                                仮登録完了メールは「<b>noreply@pocket-rd.com</b>」から会員登録時に入力されたメールアドレスに届きます。<br />
                                上記メールアドレスの受信を許可していることをご確認ください。<br /><br />

                                また、登録時に入力されたメールアドレスに誤りがないかご確認ください。<br />
                                その他、迷惑メールフィルタなどの設定によりメールが遮断されていたり、迷惑メールフォルダへ振り分けられてしまっている場合があります。<br />
                                しばらく時間をおいてから再度メールが届いてないかご確認ください。<br /><br />

                                上記をご確認のうえ、メールが見つからない場合は、お手数ですが<a className="text-link" onClick={onGoTo(ROUTER_URL.CONTACT)}>【問い合わせフォーム】</a>からご連絡ください。
                            </p>
                        </div>
                        <div id="qa_signin_2" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight} />本登録URLの有効期限が切れました。</h3>
                            <p>
                                本登録URLの有効期限は30分です。30分経過後は、プライバシー保持のため本登録へのアクセスはできません。<br />
                                お手数をおかけしますが、会員登録画面に戻り最初から登録をやり直してください。
                            </p>
                        </div>
                    </div>

                    <div id="qa_login" className="section">
                        <h2>ログインについて</h2>

                        <div id="qa_login_1" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight} />パスワードを忘れてしまいました。</h3>
                            <p>
                                パスワードをお忘れの場合は、下記からパスワードの再設定を行ってください。<br />
                                <a className="text-link" onClick={onGoTo(ROUTER_URL.FORGOT_PASSWORD)}>【パスワードをお忘れの方】</a>
                            </p>
                        </div>
                        <div id="qa_login_2" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight} />メールアドレスを忘れてしまいました。</h3>
                            <p>
                                メールアドレスをお忘れの場合は、お手数ですが<a className="text-link" onClick={onGoTo(ROUTER_URL.CONTACT)}>【問い合わせフォーム】</a>からご連絡ください。
                            </p>
                        </div>
                    </div>

                    <div id="qa_account" className="section">
                        <h2>アカウントについて</h2>

                        <div id="qa_account_1" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight} />アカウント情報の確認、変更がしたいです。</h3>
                            <p>
                                AVATARIUMアプリにログイン後、右上にあるメニューをクリックし、メニュー画面を表示してください。<br />
                                メニュー内の「<a className="text-link" onClick={onGoTo(ROUTER_URL.SETTINGS)}>アカウント設定</a>」から下記情報の確認、変更が可能です。<br /><br />
                                ・プロフィール画像<br />
                                ・ユーザー名<br />
                                ・メールアドレス<br />
                                ・パスワード<br />
                                ・性別<br />
                            </p>
                        </div>

                        <div id="qa_account_3" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight} />AVATARIUMを退会したいです。</h3>
                            <p>
                                退会をご希望の方は下記から退会手続きを行ってください。<br />
                                <a className="text-link" onClick={onGoTo(ROUTER_URL.WITHDRAW)}>【退会を希望の方はこちら】</a>
                            </p>
                        </div>
                    </div>

                    <div id="qa_photography_scanner" className="section">
                        <h2>撮影について（スキャナ撮影）</h2>

                        <div id="qa_photography_scanner_1" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight} />撮影に失敗した / 作成されたアバターに納得がいかない場合、撮影のやり直しはできますか？</h3>
                            <p>
                                撮影時の注意事項、撮影時のガイダンス内容に則った撮影を行っていない場合の撮影やり直しはできません。<br /><br />

                                撮影には、必ず事前に撮影時の注意事項をよくお読みになってお越し下さい。<br />
                                また、撮影時にも諸注意が案内されますので、そちらをよくご確認のうえ撮影を行うようにお願いいたします。<br />
                                <a className="text-link" onClick={onGoTo(ROUTER_URL.HELP_BOOKING_ATTENTION)}>【撮影時の注意事項はこちら】</a><br /><br />

                                その他、スキャナーの不具合等でアバター生成が正常にできなかった場合は、お手数ですが下記よりお問い合わせください。<br />
                                <a className="text-link" onClick={onGoTo(ROUTER_URL.CONTACT)}>【お問い合わせはこちら】</a>
                            </p>
                        </div>

                        <div id="qa_photography_scanner_2" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight} />予約した内容はどこで確認できますか？</h3>
                            <p>
                                AVATARIUMアプリにログイン後、右上にあるメニューをクリックし、メニュー画面を表示してください。<br />
                                メニュー内の「<a className="text-link" onClick={onGoTo(ROUTER_URL.SCAN_BOOKING)}>撮影予約</a>」の予約履歴タブから確認することができます。<br /><br />

                                または、撮影予約の完了後にお届けしている「予約完了」通知メールからご確認いただけます。<br /><br />

                                「予約履歴」の各ステータスについては下記をご確認ください。
                            </p>

                            <table className="base_table">
                                <tbody>
                                <tr>
                                    <th>スキャン前</th>
                                    <td>撮影前の予約履歴です。</td>
                                </tr>
                                <tr>
                                    <th>撮影済み</th>
                                    <td>撮影済みの予約履歴です。</td>
                                </tr>
                                <tr>
                                    <th>キャンセル</th>
                                    <td>予約がキャンセルされた予約履歴です。</td>
                                </tr>
                                <tr>
                                    <th>期限切れ</th>
                                    <td>撮影が行われず、自動キャンセルとなった予約履歴です。</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div id="qa_photography_scanner_3" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight} />撮影予約内容のキャンセル、変更がしたいです。</h3>

                            <div id="qa_photography_scanner_3-1" className="section">
                                <h4>予約のキャンセル</h4>
                                <p>
                                    <a className="text-link" onClick={onGoTo(ROUTER_URL.SCAN_BOOKING)}>撮影予約</a>の予約履歴タブから、予約のキャンセルができます。<br />
                                    キャンセルしたい撮影予約からゴミ箱アイコンをクリックをしてください。
                                </p>
                                <div className="image_wrap">
                                    <img src={helpCancel1} alt="予約のキャンセル1" width="800px" height="99px" />
                                </div>
                                <p>
                                    キャンセル内容の確認が表示されますので、キャンセルする場合はそのまま「はい」ボタンを選択し、キャンセルを確定してください。
                                </p>
                                <div className="image_wrap image_wrap_m">
                                    <img src={helpCancel2} alt="予約のキャンセル2" width="500px" height="294px" />
                                </div>
                                <p>
                                    正常に撮影予約のキャンセルが完了した場合、会員登録時のメールアドレスにキャンセルの確認メールをお送り致します。
                                </p>
                            </div>

                            <div id="qa_photography_scanner_3-2" className="section">
                                <h4>撮影予約の変更</h4>
                                <p>
                                    確定された撮影予約は変更ができません。<br />
                                    予約内容を変更したい場合は、変更したい予約を一度キャンセルし、再度予約を取り直してください。
                                </p>
                            </div>
                        </div>


                        <div id="qa_photography_scanner_4" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight} />撮影受付の時間に間に合いませんでした。</h3>
                            <p>
                                撮影受付の時間に間に合わなかった場合、予約は自動的にキャンセルとなります。<br />
                                <a className="text-link" onClick={onGoTo(ROUTER_URL.SCAN_BOOKING)}>予約フォーム</a>から再度、撮影予約をしてください。
                            </p>
                        </div>

                        <div id="qa_photography_scanner_5" className="section">
                            <h3><FontAwesomeIcon style={iconStyle} icon={faChevronCircleRight} />予約とは違うスキャナ設置場所に行ってしまいました。</h3>
                            <p>
                                撮影は撮影予約時に選択されたスキャナ設置場所でのみ可能です。<br />
                                撮影場所を変更する場合は、<a className="text-link" onClick={onGoTo(ROUTER_URL.SCAN_BOOKING)}>撮影予約フォーム</a>から再度、撮影予約をしてください。
                            </p>
                        </div>
                    </div>
                    
                </div>

                <div className="wrapper__button">
                    <a className="button-custom" onClick={onGoTo(ROUTER_URL.HELP_INDEX)}>ヘルプページTOP</a>
                </div>
                
            </div>
            {/*<!-- /wrapper__content -->*/}
        </Wrapper>
    )
}
export default HelpQa
