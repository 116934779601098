import classnames from 'classnames'
import { useFormContext } from 'react-hook-form'
import { EyeIcon, EyeHideIcon } from '../../../../../assets/images'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
interface TextInputProps {
  name: string
  type?: string
  label?: string
  placeholder?: string
  isRequire: boolean
  isDisabled?: boolean
  isFocus?: boolean
  helpText?: boolean
  id?: any
  errorApi?: any
  backgroundColorInput?: boolean
  handleChange?: any
  isShowBorder?: boolean
  errorsBorder?: boolean
}

export const TextInput: React.FC<TextInputProps> = ({
  name,
  label,
  placeholder = 'アバター名を入力してください。',
  isRequire,
  type,
  isDisabled,
  isFocus,
  helpText,
  id,
  errorApi,
  backgroundColorInput,
  handleChange,
  isShowBorder = true,
  errorsBorder,
}) => {
  const { errors, register } = useFormContext()
  const [t] = useTranslation('translation')
  const [isPass, setIsPass] = useState(true)
  const [focusPass, isFocusPass] = useState(false)
  const isError = (errorsBorder || !!errors?.[name]) ?? false
  const message = errors?.[name]?.message ?? null
  const inputRef = useRef<any>(null)

  useEffect(() => {
    if (isFocus) {
      if (null !== inputRef.current && type === 'text') {
        inputRef?.current?.setSelectionRange(0, 0)
        inputRef.current.focus()
      }
    }
  }, [isFocus])

  useEffect(() => {
    if (isDisabled) {
      if (inputRef?.current?.blur) inputRef?.current?.blur()
    }
  }, [isDisabled])

  // Prevent enter input close modal
  function _handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <div className={classnames('input', { 'input-label-gutter': label })}>
      {label ? (
        <p className="input__label--wrapper">
          <span className="input__label">{label}</span>
          {isRequire ? <span className="input__asterisk">*</span> : null}
        </p>
      ) : null}
      <div>
        <input
          autoComplete="off"
          type={isPass ? type : 'text'}
          ref={(e) => {
            register(e)
            inputRef.current = e
          }}
          className={classnames('input__field', { error: isError && isShowBorder })}
          placeholder={placeholder}
          name={name}
          readOnly={isDisabled}
          onKeyDown={_handleKeyDown}
          onChange={handleChange}
          autoFocus={isFocus}
          style={{
            borderColor: '#9f9f9f',
            backgroundColor: backgroundColorInput ? '#E0E0E0' : '',
          }}
          onFocus={() => helpText && isFocusPass(true)}
          onBlur={() => helpText && isFocusPass(false)}
        />
        {type === 'password' && (
          <div className="eye" onClick={() => setIsPass(!isPass)}>
            {isPass ? <EyeIcon /> : <EyeHideIcon />}
          </div>
        )}
      </div>
      {message ? <span className="form-field-error-message">{message}</span> : null}
      {type === 'password' && helpText && focusPass && message === null ? (
        <span className="helptext" style={{ whiteSpace: 'pre-wrap' }}>
          {t('helptext.helptext')}
        </span>
      ) : null}
      {errorApi ? <span className="form-field-error-message">{errorApi}</span> : null}
    </div>
  )
}
