import { useTranslation } from 'react-i18next'
import { BookIcon } from '../../../../../assets/images/svgs'
import Wrapper from '../../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../../utils/const'
import TermsOfServiceConstants from './constants'
import MobileHeader from '../../../components/block/mobile/header'

interface TermsOfServiceMobileProps {}

const TermsOfServiceMobile: React.FC<TermsOfServiceMobileProps> = () => {
  const [t] = useTranslation('translation')

  return (
    <>
      <MobileHeader />
      <Wrapper
        breadcrum={[]}
        className="policy"
        title={t('policy.term_of_service.title')}
        buttonText={''}
        buttonLink={ROUTER_URL.HOME}
        classNameBtn={'normal'}
        Icon={BookIcon}
      >
        <TermsOfServiceConstants>
        </TermsOfServiceConstants>
      </Wrapper>
    </>
  )
}
export default TermsOfServiceMobile
