import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import MoonLoader from 'react-spinners/MoonLoader'
import { CONFIRM_EMAIL } from '../../../../utils/endpoints'
import { GET, ROUTER_URL, ERROR_TYPE } from '../../../../utils/const'

interface ConfirmEmailProps {
  callApi: Function
  onLogout: Function
  notify: Function
}

const ConFirmEmail: React.FC<ConfirmEmailProps> = ({ callApi, onLogout, notify }) => {
  const history = useHistory()
  const string = window.location.href.split('/')
  const key = string.slice(-1)[0]
  useEffect(() => {
    const getKeyConfirmEmail = async () => {
      try {
        await callApi(`${CONFIRM_EMAIL}/${key}`, GET, null, false)
        history.push({pathname: ROUTER_URL.ACTIVE_EMAIL_SUCCESS, state: {already_flag: false}})
        onLogout()
      } catch (error: any) {
        if (error.name === 'E000069') {
          history.push(ROUTER_URL.ACTIVE_EMAIL_ERR)
        } else if (error.name === 'E000070'){
          history.push({pathname: ROUTER_URL.ACTIVE_EMAIL_SUCCESS, state: {already_flag: true}})
        } else {
          notify(error.message, ERROR_TYPE)
        }
        onLogout()
      }
    }
    getKeyConfirmEmail()
  }, [])
  return (
    <div
      className="default-padding"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <MoonLoader size={200} loading={true} color={'#12c4c4'} />
    </div>
  )
}

export default ConFirmEmail
