import { useState, useEffect } from 'react'
import _ from 'lodash'
import { TABLET_MIN_SIZE } from '../../utils/const'

const getDeviceConfig = (width: any) => {
  if (width >= 375 && width < 576) {
    return 'mobiles'
  } else if (width >= 576 && width < TABLET_MIN_SIZE) {
    return 'large-mobiles'
  } else if (width >= TABLET_MIN_SIZE && width < 992) {
    return 'tablets'
  } else if (width >= 992 && width < 1200) {
    return 'small-desktops'
  } else if (width >= 1200 && width < 1400) {
    return 'desktops'
  } else if (width >= 1400) {
    return 'large-desktops'
  }
  return null
}

export const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth))

  useEffect(() => {
    const calcInnerWidth = _.throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth))
    }, 100)
    window.addEventListener('resize', calcInnerWidth)
    return () => window.removeEventListener('resize', calcInnerWidth)
  }, [])

  return brkPnt
}
