import { AnyAction, Reducer } from 'redux';
import { initialListTypeDownLoad,  DownloadState} from '../states';
import { DownloadTypes } from '../actions';

export const ListDownLoadReducer: Reducer = (state: DownloadState = initialListTypeDownLoad, action: AnyAction) => {
    switch (action.type) {
        case DownloadTypes.GET_LIST_ALL :
            return {
                FPX: false,
                All: true,
                PLY: false,
                data: action.payload
            }
        case DownloadTypes.GET_LIST_FPX: 
            return {
                FPX: true,
                All: false,
                PLY: false,
                data: action.payload
            }
        case DownloadTypes.GET_LIST_PLY: 
            return {
                FPX: false,
                All: false,
                PLY: true,
                data: action.payload
            }   
        default:
            return state           
    }
};
