import React from 'react'

interface TimeExpiredProps {
  height?: string
  width?: string
  color?: string
}

const TimeExpired: React.FC<TimeExpiredProps> = ({ height, width, color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0833 6.6V8.4C10.0043 8.40004 8.94917 8.71169 8.04923 9.29614C7.14928 9.8806 6.44432 10.712 6.0221 11.687C5.59988 12.6619 5.47906 13.7372 5.67468 14.779C5.8703 15.8208 6.37373 16.7832 7.12227 17.5462C7.87081 18.3092 8.83141 18.8391 9.88446 19.0701C10.9375 19.3011 12.0365 19.2228 13.0446 18.8451C14.0527 18.4674 14.9253 17.8068 15.5539 16.9458C16.1825 16.0847 16.5392 15.0611 16.5797 14.0025L16.5833 13.8H18.4167C18.4167 15.7096 17.6441 17.5409 16.2688 18.8912C14.8935 20.2414 13.0283 21 11.0833 21C9.13841 21 7.27315 20.2414 5.89788 18.8912C4.52262 17.5409 3.75 15.7096 3.75 13.8C3.75 9.8985 7.03167 6.6 11.0833 6.6ZM20.25 3V4.8L15.3669 10.2H20.25V12H12.9167V10.2L17.7988 4.8H12.9167V3H20.25Z"
        fill="#9496AB"
      />
    </svg>
  )
}

export default TimeExpired
