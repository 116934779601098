import { useTranslation } from 'react-i18next'
import { Button } from '../../components/atoms/buttons'
import { CloseIcon } from '../../../../assets/images'

interface DownloadModalProps {
  toggleModal: () => void
  numberCard: any
  defaultNumber: any
  deleteCard: any
  brandCard: any
  defaultBrandCard: any
}

const DeleteCredit: React.FC<DownloadModalProps> = ({
  toggleModal,
  numberCard,
  defaultNumber,
  deleteCard,
  brandCard,
  defaultBrandCard,
}) => {
  const [t] = useTranslation('translation')
  return (
    <>
      <div className="detail-avatar__delete-modal">
        <div className="detail-avatar__delete-modal_header" style={{ textAlign: 'center' }}>
          <div className="detail-avatar__delete-modal_header-text distance-margin-modal-h2">
            <div className="mb">
              <div style={{ marginBottom: '1rem' }} className="mobile pop-text-bold-big">
                {t('delete-card.content1')}
              </div>
            </div>
            <div className="pop-big-body-text">
              「 {brandCard === '' ? defaultBrandCard : brandCard}{' '}
            </div>
            <div className="pop-big-body-text">
              {' '}
              <span style={{ letterSpacing: '3px' }}>
                ****-****-****-
                {numberCard === '' ? defaultNumber.slice(12, 16) : numberCard.slice(12, 16)}
              </span>
              」
            </div>
          </div>
          <button
            className="detail-avatar__delete-modal_header-icon close-icon"
            onClick={toggleModal}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="detail-avatar__delete-modal_content distance-modal-content-to-btn padding-modal-content-to-line">
          <p className="mbp pop-large-body-text">{t('delete-card.content2')}</p>
        </div>
        <div className="detail-avatar__delete-modal_button">
          <Button className="cancel shortnormal" type="button" onClick={toggleModal}>
            {t('detail-avatar.delete_btn1')}
          </Button>
          <Button className="shortnormal" onClick={deleteCard}>
            {t('detail-avatar.delete_btn2')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default DeleteCredit
