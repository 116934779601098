import * as React from 'react'

interface LoginIconTopProps {
  height?: string
  width?: string
}
const LoginIconTop: React.FC<LoginIconTopProps> = ({ height, width }) => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.33203 2.667L5.39903 3.6L7.13203 5.333H0.332031V6.667H7.13203L5.39903 8.4L6.33203 9.333L9.66503 6L6.33203 2.667ZM12.332 10.667H6.99903V12H12.332C12.6852 11.9989 13.0237 11.8582 13.2734 11.6084C13.5232 11.3587 13.664 11.0202 13.665 10.667V1.333C13.664 0.979791 13.5232 0.64135 13.2734 0.391592C13.0237 0.141835 12.6852 0.00105515 12.332 0H6.99903V1.333H12.332V10.667Z"
        fill="white"
      />
    </svg>
  )
}

export default LoginIconTop
