import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Wrapper from '../../../components/modules/_wrapper'
import { GET_NOTI_DETAIL } from '../../../../../utils/endpoints'
import { useParams } from 'react-router-dom'
import { ERROR_TYPE, ROUTER_URL } from '../../../../../utils/const'
import { useHistory } from 'react-router-dom'
interface DetailNotificationProps {
  id: number
  notify?: Function
  setStateNotify?: any
  callApi: any
}
export const DetailNotification: React.FC<DetailNotificationProps> = ({
  id,
  notify = () => {},
  setStateNotify,
  callApi,
}) => {
  const [, i18n] = useTranslation('translation')
  const [notiDetail, setNotiDetail] = useState<any>({})
  const params: any = useParams()
  const history: any = useHistory()

  const list = [
    {
      name: i18n.t('notifications.bread_crumb_home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('notifications.bread_crumb_list'),
      link: ROUTER_URL.NOTIFICATIONS,
    },
    {
      name: notiDetail?.title,
      link: `${ROUTER_URL.DETAIL_NOTIFICATION}/${id}`,
    },
  ]

  useEffect(() => {
    const fetchListNoti = async () => {
      try {
        const result = await callApi(`${GET_NOTI_DETAIL}/${params['id']}`)
        const data = result?.data?.data
        if (data) {
          setNotiDetail(data)
          setStateNotify()
        }
      } catch (error: any) {
        notify(error?.message, ERROR_TYPE)
      }
    }
    fetchListNoti()
  }, [params?.id, localStorage.getItem('i18nextLng')])

  const goToLink = (link: any) => () => {
    history.push(link ? link : ROUTER_URL.HOME)
  }

  function contentToJsx(content: string) {
    if (!content) return ''
    let elementList: any = []
    let replaceTextList = content.split('\n')
    for (let replaceText of replaceTextList) {
      if (replaceText.match(/[・].*/)) {
        elementList.push((<>{replaceText}</>))
          continue
      }
      if (replaceText.match(/.*<a.*/)) {
        let linkFirstText = replaceText.substring(0, replaceText.indexOf('<a '))

        let urlStartIndex = replaceText.indexOf('http', 0)
        let url = replaceText.substring(
          urlStartIndex, replaceText.indexOf('"', urlStartIndex)
        )
        let domain: any = url.match(/http.*\//)
        url = url.replace(domain[0], '/')

        let linkText: any = replaceText.match(/>.*</)
        linkText = linkText[0].substring(1, linkText[0].length - 1)
        elementList.push((
        <>
          {linkFirstText}<a className="text-link" onClick={goToLink(url)}>{linkText}</a>
        </>))
        continue
      }
      elementList.push((<>{replaceText}</>))
    }
    return (<>{elementList.map((element: any, index: number) => <span key={index}>{element}<br /></span>)}</>)
  }

  return (
    <Wrapper
      title={notiDetail?.title}
      breadcrum={list}
      className="wrap-detail-noti"
      subTitle={notiDetail && notiDetail['notify_time']}
      buttonText={i18n.t('notifications.button_detail')}
      buttonLink={ROUTER_URL.NOTIFICATIONS}
    >
      <div className="noti-wrap distance-line-to-content">
        <div className="noti-wrap_content distance-content-to-button">
          <p>
            {contentToJsx(notiDetail?.message)}
          </p>
        </div>
      </div>
    </Wrapper>
  )
}

export default DetailNotification
