import * as React from 'react';

interface CreaditIconProps {
   height?: string;
   width?: string;
   check:boolean
};
const ChervonIcon: React.FC<CreaditIconProps> = ({ height, width, check }) => {
   return (
    <svg
    width={11}
    height={7}
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.793 5.793L.707 1.707C.077 1.077.523 0 1.414 0h8.172c.89 0 1.337 1.077.707 1.707L6.207 5.793a1 1 0 01-1.414 0z"
      fill={check ? "#fff" : "#727488"}
    />
  </svg>
   );
};

export default ChervonIcon;