import React from 'react'

interface BoxContentMobileProps {
  height?: string
  width?: string
}

const BoxContentMobile: React.FC<BoxContentMobileProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="180" viewBox="0 0 90 180">
      <defs>
        <clipPath id="clipPath">
          <path fill="none" d="M0 0H90V180H0z" />
        </clipPath>
        <style>{'.cls-3,.cls-4{fill:#12c4c4}.cls-3{opacity:.5;isolation:isolate}'}</style>
      </defs>
      <g id="img_decor1" clipPath="url(#clipPath)">
        <g id="BOXS1_アートボード_1" transform="translate(-.99 -.979)">
          <path
            id="Path_677"
            d="M221.495 146.5H17V1h204.495zM17.5 146h203.495V1.5H17.5z"
            className="cls-3"
            transform="translate(-8.005 -.011)"
          />
          <path
            id="長方形_73-4"
            d="M0 0H1.5V1.5H0z"
            className="cls-4"
            transform="translate(8.995 .989)"
          />
          <path
            id="長方形_74-4"
            d="M0 0H1.5V1.5H0z"
            className="cls-4"
            transform="translate(211.99 .989)"
          />
          <path
            id="長方形_75-4"
            d="M0 0H1.5V1.5H0z"
            className="cls-4"
            transform="translate(211.99 144.979)"
          />
          <path
            id="長方形_88-4"
            d="M0 0H1.5V1.5H0z"
            className="cls-4"
            transform="translate(8.995 144.979)"
          />
          <path
            id="Rectangle_3444"
            d="M0 0H249.807V0.496H0z"
            className="cls-3"
            transform="rotate(-35.374 233.033 57.897)"
          />
          <g id="Group_10634" transform="translate(.99 122.403)" style={{ isolation: 'isolate' }}>
            <path
              id="Path_678"
              d="M2.833 246.548A1.614 1.614 0 0 1 1 244.893a1.685 1.685 0 0 1 .2-.87l.39.19a1.232 1.232 0 0 0-.165.675c0 .71.445 1.159 1.319 1.159h.125a1.167 1.167 0 0 1-.46-1.01 1.076 1.076 0 0 1 1.059-1.194 1.127 1.127 0 0 1 1.1 1.234c.009.94-.619 1.471-1.735 1.471zm.666-.615c.36 0 .675-.29.675-.834a.688.688 0 0 0-.68-.77c-.41 0-.675.305-.675.795a.727.727 0 0 0 .679.809z"
              className="cls-4"
              transform="translate(-.994 -243.844)"
            />
            <path
              id="Path_679"
              d="M2.1 254.112a1.132 1.132 0 0 1-1.1-1.239c0-.939.63-1.469 1.744-1.469a1.614 1.614 0 0 1 1.834 1.654 1.682 1.682 0 0 1-.2.869l-.39-.19a1.211 1.211 0 0 0 .165-.67c0-.71-.445-1.165-1.319-1.165a.988.988 0 0 0-.125.006 1.163 1.163 0 0 1 .46 1 1.081 1.081 0 0 1-1.059 1.2zm-.02-.484c.409 0 .675-.31.675-.8a.726.726 0 0 0-.68-.8c-.36 0-.675.29-.675.83a.692.692 0 0 0 .679.775z"
              className="cls-4"
              transform="translate(-.99 -247.624)"
            />
            <path
              id="Path_680"
              d="M2.069 260.941c-.589 0-1.069-.435-1.069-1.326a1.984 1.984 0 0 1 .425-1.285l.4.23a1.573 1.573 0 0 0-.375 1.055c0 .525.24.824.62.824s.61-.265.61-.865v-.28h.35l1.079.879v-1.684h.435v2.311H4.2l-1.129-.915c-.071.701-.462 1.056-1.002 1.056z"
              className="cls-4"
              transform="translate(-.994 -251.087)"
            />
            <path
              id="Path_681"
              d="M1.515 267.911H1.08V265.4h.34l1.374 1.424c.36.371.58.44.8.44.36 0 .58-.255.58-.745a1.1 1.1 0 0 0-.38-.895l.3-.34a1.613 1.613 0 0 1 .529 1.28c0 .729-.375 1.2-.974 1.2a1.415 1.415 0 0 1-1.1-.574l-1.03-1.07z"
              className="cls-4"
              transform="translate(-1.035 -254.563)"
            />
            <path
              id="Path_682"
              d="M4.576 273.438h-3.5v-.495h3.065v-.79h.435z"
              className="cls-4"
              transform="translate(-1.033 -257.998)"
            />
            <path
              id="Path_683"
              d="M2.807 280.151v.48H1.443A2.066 2.066 0 0 1 1 279.306a1.773 1.773 0 0 1 1.789-1.865 1.775 1.775 0 0 1 1.789 1.874 1.755 1.755 0 0 1-.539 1.345l-.31-.31a1.387 1.387 0 0 0 .4-1.015 1.323 1.323 0 0 0-1.344-1.394 1.328 1.328 0 0 0-1.344 1.389 1.479 1.479 0 0 0 .225.819z"
              className="cls-4"
              transform="translate(-.992 -260.643)"
            />
            <path
              id="Path_684"
              d="M1.515 289.461H1.08v-2.538h3.5v2.469h-.437v-1.969H3.069v1.754h-.425v-1.754H1.515z"
              className="cls-4"
              transform="translate(-1.035 -265.383)"
            />
          </g>
          <path
            id="Path_685"
            d="M280.5 341.5H262V323h18.5zm-18-.5H280v-17.5h-17.5z"
            className="cls-3"
            transform="translate(-130.509 -161.021)"
          />
          <path
            id="長方形_73-3"
            d="M0 0H1V1H0z"
            className="cls-4"
            transform="translate(131.492 161.979)"
          />
          <path
            id="長方形_73-3-2"
            d="M0 0H1V1H0z"
            className="cls-4"
            transform="translate(148.991 161.979)"
          />
          <path
            id="長方形_73-3-3"
            d="M0 0H1V1H0z"
            className="cls-4"
            transform="translate(131.492 179.479)"
          />
          <path
            id="長方形_73-3-4"
            d="M0 0H1V1H0z"
            className="cls-4"
            transform="translate(148.991 179.479)"
          />
          <path
            id="Path_686"
            d="M280.5 291.5H262V273h18.5zm-18-.5H280v-17.5h-17.5z"
            className="cls-3"
            transform="translate(-130.509 -136.021)"
          />
          <path
            id="長方形_73-3-5"
            d="M0 0H1V1H0z"
            className="cls-4"
            transform="translate(131.492 136.981)"
          />
          <path
            id="長方形_73-3-6"
            d="M0 0H1V1H0z"
            className="cls-4"
            transform="translate(148.991 136.981)"
          />
          <path
            id="長方形_73-3-7"
            d="M0 0H1V1H0z"
            className="cls-4"
            transform="translate(131.492 154.479)"
          />
          <path
            id="長方形_73-3-8"
            d="M0 0H1V1H0z"
            className="cls-4"
            transform="translate(148.991 154.479)"
          />
          <path
            id="Path_687"
            d="M280.5 241.5H262V223h18.5zm-18-.5H280v-17.5h-17.5z"
            className="cls-3"
            transform="translate(-130.509 -111.018)"
          />
          <path
            id="長方形_73-3-9"
            d="M0 0H1V1H0z"
            className="cls-4"
            transform="translate(131.492 111.983)"
          />
          <path
            id="長方形_73-3-10"
            d="M0 0H1V1H0z"
            className="cls-4"
            transform="translate(148.991 111.983)"
          />
          <path
            id="長方形_73-3-11"
            d="M0 0H1V1H0z"
            className="cls-4"
            transform="translate(131.492 129.482)"
          />
          <path
            id="長方形_73-3-12"
            d="M0 0H1V1H0z"
            className="cls-4"
            transform="translate(148.991 129.482)"
          />
        </g>
      </g>
    </svg>
  )
}

export default BoxContentMobile
