import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconGroup5: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" y="11" width="138" height="138" rx="9" fill="white" />
      <rect x="11" y="11" width="138" height="138" rx="9" stroke="#FF6C6C" strokeWidth="2" />
      <path
        d="M96.2349 51.1203C92.4133 47.7057 87.4623 45.7901 82.3132 45.7337H78.3067L78.2664 45.7865L70.1104 55.2786C69.8655 55.4955 69.5484 55.6154 69.2198 55.6154C68.8911 55.6154 68.574 55.4955 68.3291 55.2786L60.1791 45.7865L60.1389 45.7337H56.1323C50.9787 45.7869 46.0226 47.7027 42.1976 51.1203L28 66.4354L40.1223 78.43L46.8767 72.4327L47.9098 99.3718H90.5186L91.5517 72.4327L98.3051 78.4271L110.427 66.4354L96.2349 51.1203Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2"
      />
      <path
        d="M80.2056 117.99H84.344V102.847C84.3387 102.279 84.487 101.72 84.7734 101.228C85.0599 100.735 85.4741 100.328 85.9732 100.047C86.694 99.6782 87.4982 99.4981 88.3092 99.524C91.9201 99.524 86.2833 89.1532 86.2833 89.1532L76.6914 90.2829C76.6914 90.2829 76.6914 94.5319 78.0004 96.5592C79.2399 98.4729 80.2035 99.3287 80.2035 103.424L80.2056 117.99Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M83.6576 78.8796C81.9056 80.1827 76.2497 84.8839 76.719 91.0147C76.7289 91.149 76.7849 91.2759 76.8778 91.3743C76.9706 91.4727 77.0947 91.5367 77.2295 91.5557C79.1507 91.8546 82.7917 93.5212 88.3056 99.5255C94.2947 106.06 99.135 111.978 100.987 114.513C102.838 117.047 102.045 118 108.563 118H126.97C128.514 118.013 130.034 117.622 131.375 116.866C132.342 116.256 132.52 114.774 129.438 113.12C126.356 111.466 120.454 108.502 118.605 107.541C118.011 107.165 117.308 106.996 116.607 107.059C115.905 107.122 115.245 107.414 114.729 107.889C113.76 108.586 108.386 109.37 105.834 106.844C103.283 104.317 96.0589 96.5617 92.2708 90.7278C89.1182 85.8602 85.602 80.639 84.5095 79.0231C84.4644 78.9567 84.4062 78.9001 84.3383 78.8567C84.2704 78.8132 84.1944 78.7839 84.1147 78.7705C84.035 78.7571 83.9534 78.7598 83.8748 78.7786C83.7963 78.7974 83.7224 78.8317 83.6576 78.8796Z"
        fill="white"
        stroke="#111947"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M95.989 65.4097C97.2023 65.3553 98.3478 64.8484 99.1888 63.9937C100.03 63.139 100.502 62.0019 100.508 60.8174C100.504 62.0003 100.969 63.1389 101.806 63.9947C102.644 64.8506 103.788 65.3573 105 65.4087C103.788 65.4601 102.644 65.9668 101.806 66.8226C100.969 67.6784 100.504 68.817 100.508 70C100.519 69.4116 100.408 68.8271 100.182 68.2816C99.9555 67.736 99.6184 67.2408 99.1908 66.8256C98.7795 66.3949 98.2848 66.048 97.7353 65.805C97.1857 65.5619 96.5922 65.4276 95.989 65.4097ZM86 50.9765C87.1733 50.9362 88.3269 50.67 89.3943 50.1932C90.4617 49.7165 91.4219 49.0385 92.2197 48.1984C93.0451 47.3818 93.6959 46.4133 94.1341 45.3492C94.5723 44.2851 94.7891 43.1466 94.7721 42C94.755 43.1466 94.9719 44.2851 95.41 45.3492C95.8482 46.4133 96.499 47.3818 97.3244 48.1984C98.9462 49.892 101.189 50.8907 103.564 50.9765C101.989 51.0365 100.459 51.4991 99.1264 52.3178C97.7956 53.1274 96.696 54.2519 95.9298 55.5868C95.1572 56.9313 94.7565 58.4487 94.767 59.9899C94.784 58.8406 94.5672 57.6995 94.129 56.6324C93.6909 55.5654 93.0402 54.5937 92.2146 53.7735C91.4211 52.9281 90.4629 52.2454 89.3959 51.7651C88.3288 51.2849 87.1744 51.0168 86 50.9765Z"
        fill="white"
        stroke="#111947"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34 96.1328C35.2467 96.074 36.4234 95.5361 37.2872 94.63C38.1509 93.7239 38.6358 92.5187 38.6417 91.2634C38.6375 92.5177 39.116 93.725 39.9769 94.6325C40.8379 95.5399 42.0144 96.0772 43.2603 96.1317C42.0144 96.1862 40.8379 96.7235 39.9769 97.6309C39.116 98.5384 38.6375 99.7457 38.6417 101C38.6533 100.376 38.5393 99.7563 38.3066 99.1779C38.0739 98.5995 37.7273 98.0743 37.2876 97.6341C36.8653 97.1779 36.3574 96.8104 35.7931 96.5527C35.2288 96.295 34.6194 96.1523 34 96.1328ZM44.4591 81.1731C46.2876 81.0867 48.0118 80.2913 49.27 78.954C50.5281 77.6166 51.2225 75.8411 51.2075 74C51.2067 75.8425 51.9123 77.6143 53.1775 78.9467C54.4428 80.279 56.1701 81.0693 58 81.1531C56.1699 81.2368 54.4424 82.0272 53.1772 83.3598C51.9119 84.6924 51.2064 86.4645 51.2075 88.3072C51.221 86.4696 50.5244 84.6986 49.2646 83.3678C48.0048 82.037 46.2802 81.2505 44.4549 81.1742L44.4591 81.1731Z"
        fill="white"
        stroke="#111947"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33Z"
        fill="#FF6C6C"
      />
      <path
        d="M18.2434 16.471L23.5814 11.133C23.7047 11.0186 23.8035 10.8806 23.8722 10.7271C23.9409 10.5736 23.9779 10.4079 23.9811 10.2398C23.9842 10.0717 23.9535 9.90466 23.8906 9.74871C23.8278 9.59276 23.7342 9.45107 23.6154 9.33212C23.4965 9.21317 23.355 9.11938 23.1991 9.05638C23.0432 8.99337 22.8762 8.96243 22.7081 8.96541C22.54 8.96838 22.3742 9.00521 22.2207 9.0737C22.0671 9.14219 21.929 9.24092 21.8144 9.36401L16.4744 14.702L11.1364 9.36401C11.0209 9.24511 10.8829 9.15036 10.7304 9.08528C10.5779 9.02019 10.414 8.98607 10.2482 8.98488C10.0824 8.98369 9.91808 9.01547 9.76468 9.07837C9.61129 9.14127 9.47192 9.23403 9.35469 9.35126C9.23746 9.46849 9.1447 9.60785 9.0818 9.76125C9.01891 9.91465 8.98713 10.079 8.98831 10.2448C8.9895 10.4106 9.02363 10.5745 9.08871 10.727C9.1538 10.8794 9.24854 11.0175 9.36744 11.133L14.7054 16.471L9.36744 21.811C9.13837 22.0467 9.01128 22.3631 9.01363 22.6918C9.01598 23.0205 9.14759 23.335 9.38001 23.5674C9.61242 23.7999 9.92697 23.9315 10.2556 23.9338C10.5843 23.9362 10.9007 23.8091 11.1364 23.58L16.4744 18.242L21.8144 23.578C22.052 23.7959 22.3645 23.9136 22.6868 23.9067C23.0091 23.8997 23.3162 23.7687 23.5442 23.5408C23.7722 23.3129 23.9035 23.0058 23.9106 22.6836C23.9177 22.3613 23.8002 22.0487 23.5824 21.811L18.2434 16.471Z"
        fill="white"
      />
    </svg>
  )
}

export default IconGroup5
