import React from 'react'

interface ChangeArticleIconProps {
  height?: string
  width?: string
}

const ChangeArticleIcon: React.FC<ChangeArticleIconProps> = ({ height, width }) => {
  return (
    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L4 4L1 7" stroke="#12C4C4" />
      <path d="M5 1L8 4L5 7" stroke="#12C4C4" />
    </svg>
  )
}

export default ChangeArticleIcon
