import { useEffect } from 'react'
import { ExportIcon } from '../../../../../assets/images/svgs'
import { useTranslation } from 'react-i18next'
import Wrapper from '../../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../../utils/const'

interface RegistClusterCompleteProps {
  callApi: any
}
const RegistClusterComplete: React.FC<RegistClusterCompleteProps> = ({ callApi }) => {
  const [t, i18n] = useTranslation('translation')


  useEffect(() => {
  }, [])

  const list = [
    {
      name: t('control-export.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('control-export.bread_crumb_active'),
      link: ROUTER_URL.CONTROL_EXPORT,
    },
    {
      name: t('control-export.bread_crumb_regist'),
      link: ROUTER_URL.REGIST_CLUSTER_COMPLETE,
    },
  ]

  return (
    <Wrapper
      Icon={ExportIcon}
      title={t('control-export.title_header')}
      breadcrum={list}
      className="control-export"
      buttonLink={ROUTER_URL.CONTROL_AVATAR}
      buttonText={i18n.t('control-export.cluster_completed_to_avatar')}
      classNameBtn="btn_wrap token__send"
    >
      <div className="wrapper__content distance-line-to-content">
        <div className="export-complete base_style center_wrap comp_wrap">
          <h2 className="distance-h2-to-text">{i18n.t('control-export.cluster_completed_header')}</h2>
          <div className="distance-text-to-noti">
            <p>
            {i18n.t('control-export.cluster_completed_detail1')}<br />
            {i18n.t('control-export.cluster_completed_detail2')}
            </p>
          </div>
        </div>
      </div>
                                     
    </Wrapper>
  )
}

export default RegistClusterComplete
