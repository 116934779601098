import { useState, useEffect } from 'react'
import { Button } from '../../components/atoms/buttons'
import { Maps } from '../../components/modules/maps'
import { useTranslation } from 'react-i18next'
import { MapIcon, MapSmallIcon } from '../../../../assets/images'
import classnames from 'classnames'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { useBreakpoint } from '../../../../customize/hooks/useBreakpoint'
import { styler } from '../../components/atoms/select-input/constants'
import { useHistory, useLocation } from 'react-router-dom'
import {
  GET_SCANNER_BY_COUNTRY,
  GET_COUNTRY,
  GET_SCANNER_STATUS,
} from '../../../../utils/endpoints'
import Wrapper from '../../components/modules/_wrapper'
import { ERROR_TYPE, ROUTER_URL } from '../../../../utils/const'

interface ScannerProps {
  breadcrum?: any[]
  title?: string
  describe?: string
  buttonText?: string
  buttonLink?: string
  notify?: any
  callApi: any
}

const MAX_LATITUDE = 90
const MAX_LONGITUDE = 180

const Scanner: React.FC<ScannerProps> = ({ notify, callApi }) => {

  const title = 'スキャナ設置場所 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'スキャナ設置場所のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [t] = useTranslation('translation')
  const device = useBreakpoint()
  const history = useHistory()
  const location: any = useLocation()

  const isSignedIn = useSelector((state: any) => state.signin.isSignedIn)

  const [country, setCountry] = useState([])
  const [countrySelect, setCountrySelect] = useState<string>()
  const [scannerSelect, setScannerSelect] = useState<any>()
  const [area, setArea] = useState([])

  useEffect(() => {
    fetchArea()
  }, [])

  const fetchArea = async () => {
    const { state: { area } = { area: 0 } } = location

    try {
      const result = await callApi(GET_COUNTRY)
      let data = result?.data?.data
      if (data) {
        data = data?.map((r: any) => ({ ...r, label: r.text }))
        setCountry(data || [])
        setCountrySelect(area ? area : data[0]?.value)
        if (area || data[0]?.value) getScannerByCountry(area ? area : data[0]?.value)
      }
    } catch (error:any) {
      const err = error?.message
      if (err) notify(err, ERROR_TYPE)
    }
  }

  const getScannerByCountry = async (country: string) => {
    try {
      const { state: { value } = { value: 0 } } = location
      const result = await callApi(GET_SCANNER_BY_COUNTRY + '/' + country + '?page=1&per_page=1000')
      let data = result?.data?.data?.list_scanner
      if (data) {
        setArea(data || [])
        setScannerSelect(value ? data.find((d: any) => d.scanner_id === value) : data[0])
        if (value) delete location.state
      }
    } catch (error:any) {
      const err = error?.message
      if (err) notify(err, ERROR_TYPE)
    }
  }

  const list = [
    {
      name: t('scanner.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('menu.scan_booking'),
      link: ROUTER_URL.SCAN_BOOKING,
    },
    {
      name: t('scanner.breadcrum'),
      link: ROUTER_URL.SCANNER,
    },
  ]

  const changeCountry = (value: string) => {
    if (value !== countrySelect) {
      setCountrySelect(value)
      getScannerByCountry(value)
    }
  }

  const onBookingScanner = (scanner: any) => async () => {
    if (!isSignedIn) {
      history.push(ROUTER_URL.SIGNIN)
      return
    }
    try {
      const result = await callApi(GET_SCANNER_STATUS + '/' + scanner?.scanner_id)
      let data = result?.data
      if (data) {
        history.push({ pathname: ROUTER_URL.SCAN_BOOKING, state: scanner })
      }
    } catch (error:any) {
      const err = error?.message
      if (err) notify(err, ERROR_TYPE)
    }
  }

  return (
    <Wrapper
      breadcrum={list}
      Icon={MapIcon}
      title={t('scanner.title')}
      className="scanner-location"
      isNoMarginTop={true}
    >
      <div className="scanner__describe">
        <div className="select-box">
          <Select
            isSearchable={false}
            options={country}
            placeholder={t('booking.choose_location')}
            styles={styler(false, device, 45)}
            value={country.find((c: any) => c.value === countrySelect) || country[0]}
            onChange={(val: any) => changeCountry(val.value)}
            noOptionsMessage={() => t('booking_form.no_option_country')}
          />
        </div>
        <div className="box-scanner">
          <div className="box-scanner__col">
            <div className="list-scanner">
              {area.map((locale: any, index: number) => (
                <div key={index}>
                  <div
                    className={classnames('scanner-item', {
                      selected: locale.scanner_id === scannerSelect?.scanner_id,
                    })}
                    onClick={() => setScannerSelect(locale)}
                  >
                    <div className="scanner-item__info">
                      <div className="scanner-item__info__icon">
                        <MapSmallIcon />
                      </div>
                      <div className="scanner-item__info__des">
                        <p className="text-bold-small distance-padding-h4">{locale.scanner_name}</p>
                        <p>{locale.address}</p>
                      </div>
                    </div>
                    {locale.scanner_id === scannerSelect?.scanner_id && (
                      <Button className="small" onClick={onBookingScanner(locale)}>
                        {t('scanner.booking')}
                      </Button>
                    )}
                  </div>
                  {(device === 'large-mobiles' || device === 'mobiles' || device === null) &&
                    locale.scanner_id === scannerSelect?.scanner_id && (
                      <div className="scanner-map">
                        <div className="scanner-map__map">
                          <Maps lat={scannerSelect?.latitude} lng={scannerSelect?.longitude} />
                        </div>
                        {!(
                          Number.isNaN(scannerSelect?.latitude * 1) ||
                          scannerSelect?.latitude > MAX_LATITUDE ||
                          scannerSelect?.latitude < MAX_LATITUDE * -1 ||
                          Number.isNaN(scannerSelect?.longitude * 1) ||
                          scannerSelect?.longitude > MAX_LONGITUDE ||
                          scannerSelect?.longitude < MAX_LONGITUDE * -1
                        ) ? (
                          <a className="text-link"
                            target="_blank" rel="noopener noreferrer" 
                            href={`http://www.google.com/maps/place/${scannerSelect?.latitude},${scannerSelect?.longitude}`}
                          >
                            {t('scanner.detail_map')}
                          </a>
                        ) : (
                          <span className="form-field-error-message">
                            {t('scanner.errror_message')}
                          </span>
                        )}
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div>
          {device !== 'large-mobiles' && device !== 'mobiles' && device !== null && (
            <div className="box-scanner__col">
              <div className="scanner-map">
                <div className="scanner-map__map">
                  <Maps lat={scannerSelect?.latitude} lng={scannerSelect?.longitude} />
                </div>
                {!(
                  Number.isNaN(scannerSelect?.latitude * 1) ||
                  scannerSelect?.latitude > MAX_LATITUDE ||
                  scannerSelect?.latitude < MAX_LATITUDE * -1 ||
                  Number.isNaN(scannerSelect?.longitude * 1) ||
                  scannerSelect?.longitude > MAX_LONGITUDE ||
                  scannerSelect?.longitude < MAX_LONGITUDE * -1
                ) ? (
                  <a className="text-link"
                    target="_blank" rel="noopener noreferrer" 
                    href={`http://www.google.com/maps/place/${scannerSelect?.latitude},${scannerSelect?.longitude}`}
                  >
                    {t('scanner.detail_map')}
                  </a>
                ) : (
                  <span className="form-field-error-message">{t('scanner.errror_message')}</span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}
export default Scanner
