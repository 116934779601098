import { CloseIcon } from '../../../../assets/images'
import i18n from '../../../../i18n'

interface ConvertModalProps {
  toggleModal: () => void
}

const ConvertModal: React.FC<ConvertModalProps> = ({ toggleModal }) => {
  return (
    <>
      <div className="detail-avatar__delete-modal padding-modal-in-h2">
        <div className="detail-avatar__delete-modal_header distance-h2-to-line">
          <div className="detail-avatar__delete-modal_header-text">
            <div className="pop-text-bold-big">{i18n.t('home.modal_title_error')}</div>
          </div>
          <div className="detail-avatar__delete-modal_header-icon" onClick={toggleModal}>
            <CloseIcon />
          </div>
        </div>
        <div className="detail-avatar__delete-modal_content2 distance-line-to-text padding-modal-content-to-btn">
          <div className="distance-text-to-text pop-large-body-text text-pre-line"> </div>
          <div className="detail-avatar__convert-modal_name distance-margin-modal-h2">
            <div className="distance-text-to-text pop-large-body-text text-pre-line">
              {i18n.t('notifications.err_network')}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConvertModal
