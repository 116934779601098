import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconHair11: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.0538 53.9443H24.3438L24.4438 56.3993C24.5597 59.2912 25.6126 62.067 27.4438 64.3083L32.3368 70.3083L33.9218 69.1633L36.0507 70.3083L37.8708 69.1633L39.7647 70.3083L44.6578 64.3083C46.4889 62.067 47.5418 59.2912 47.6578 56.3993L47.7577 53.9443H36.0538Z"
        fill="#111947"
      />
      <path
        d="M64.5665 30.2962C64.7155 17.7012 56.8235 6.95121 45.9955 4.82221C41.0445 0.673212 31.9955 1.65721 25.0715 4.20121C13.6825 8.39321 8.37646 17.6542 7.06046 30.2962C5.96046 40.8212 20.0975 51.6962 31.0345 58.1222C31.9105 58.6372 32.7115 59.2672 33.5415 59.8392C34.2628 60.2352 35.0756 60.4344 35.8983 60.4169C36.721 60.3993 37.5247 60.1656 38.2285 59.7392C38.9274 59.1541 39.6805 58.6367 40.4775 58.1942C51.4265 51.7922 64.4405 40.9182 64.5665 30.2962Z"
        fill="#111947"
      />
      <path
        d="M56.5265 28.9849C54.8665 15.6959 46.9405 8.2959 35.8545 8.2959C24.7685 8.2959 16.8475 15.6959 15.1885 28.9849C10.4095 29.1959 9.14453 32.5789 9.14453 36.9959C9.14453 41.4129 14.0445 46.4109 15.6685 46.6399C16.3385 46.7399 16.9335 46.7999 17.5285 46.8519C21.3945 55.6439 28.7115 61.8359 34.7555 61.8359H37.0105C43.0485 61.8359 50.3685 55.6439 54.2315 46.8529C54.827 46.8225 55.4197 46.7517 56.0055 46.6409C57.6305 46.4119 62.5125 41.5759 62.5125 36.9749C62.5125 32.3739 61.2885 29.1959 56.5265 28.9849Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M29.0801 40.6318C30.8976 40.6318 32.3711 39.1584 32.3711 37.3408C32.3711 35.5232 30.8976 34.0498 29.0801 34.0498C27.2625 34.0498 25.7891 35.5232 25.7891 37.3408C25.7891 39.1584 27.2625 40.6318 29.0801 40.6318Z"
        fill="#111947"
      />
      <path
        d="M43.5058 40.6318C45.3234 40.6318 46.7968 39.1584 46.7968 37.3408C46.7968 35.5232 45.3234 34.0498 43.5058 34.0498C41.6883 34.0498 40.2148 35.5232 40.2148 37.3408C40.2148 39.1584 41.6883 40.6318 43.5058 40.6318Z"
        fill="#111947"
      />
      <path
        d="M55.5457 30.2938C52.2717 28.6168 44.9517 19.1678 43.0807 12.8728C40.2197 16.6408 23.6197 30.2048 15.9297 30.2048C16.6167 27.3428 15.8437 7.74182 35.5377 4.93682C47.2987 3.26582 61.1197 19.9538 55.5457 30.2938Z"
        fill="#111947"
      />
      <path
        d="M36.3734 49.5486C36.3759 49.1071 36.255 48.6737 36.0243 48.2972C35.7937 47.9208 35.4624 47.6163 35.0679 47.4181C34.6734 47.2199 34.2313 47.1359 33.7916 47.1756C33.3519 47.2152 32.9321 47.377 32.5794 47.6425L31.2794 48.5816C30.4154 49.1996 30.1064 49.5546 27.3594 49.5546C29.4544 51.7066 30.7184 51.9296 34.0044 51.9296C34.1041 51.9396 34.2046 51.9396 34.3044 51.9296C34.8814 51.8559 35.4112 51.5726 35.7928 51.1335C36.1743 50.6944 36.381 50.1302 36.3734 49.5486Z"
        fill="#111947"
      />
      <path
        d="M35.7268 49.5488C35.7268 49.1078 35.8496 48.6754 36.0815 48.3002C36.3133 47.925 36.6451 47.6218 37.0396 47.4246C37.4341 47.2273 37.8758 47.1438 38.3151 47.1834C38.7543 47.223 39.1739 47.3842 39.5268 47.6488L40.8268 48.5878C41.6848 49.2058 41.9718 49.5608 44.7468 49.5608C42.6518 51.7128 41.3878 51.9358 38.1018 51.9358C38.002 51.9459 37.9015 51.9459 37.8018 51.9358C37.2231 51.8622 36.6918 51.5782 36.3091 51.138C35.9264 50.6978 35.7192 50.1321 35.7268 49.5488Z"
        fill="#111947"
      />
      <path d="M40.9402 62.1231H31.1602L33.4492 56.0791H38.6492L40.9402 62.1231Z" fill="#111947" />
    </svg>
  )
}

export default IconHair11
