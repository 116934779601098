const prefix = '__MY_AVATAR__'

class BrowserPersistent {
  setItem = (key: string, value: any) => {
    try {
      window.localStorage.setItem(`${prefix}${key}`, JSON.stringify(value))
    } catch (error) {
    }
  }

  getItem = (key: string) => {
    try {
      const item = window.localStorage.getItem(`${prefix}${key}`)
      return item ? JSON.parse(item) : null
    } catch (error) {
    }
  }

  removeItem = (key: string) => {
    try {
      window.localStorage.removeItem(`${prefix}${key}`)
    } catch (error) {
    }
  }
}

export default BrowserPersistent
