import * as React from 'react'

interface FriendIconProps {
  height?: string
  width?: string
}
const FriendIcon: React.FC<FriendIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '28'}
      height={height || '24'}
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 13C14.0899 13 17 10.0899 17 6.5C17 2.91015 14.0899 0 10.5 0C6.91015 0 4 2.91015 4 6.5C4 10.0899 6.91015 13 10.5 13Z"
        fill="#9C9EB8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9402 16.9572C8.6339 15.6925 13.2 15.6655 18.0488 16.9692C18.2263 17.0169 18.3993 17.0718 18.5677 17.1337C17.61 17.2682 16.6522 17.5035 15.6865 17.8375C14.0793 18.3926 13 20.0805 13 22.0372V23.1245C13 23.6078 13.3352 24 13.75 24H1.1C0.4917 24 0 23.5518 0 22.9995V21.7568C0 19.5206 1.5829 17.5915 3.9402 16.9572Z"
        fill="#9C9EB8"
      />
      <path
        d="M25.6652 18.7269C22.7994 17.7491 20.1012 17.7693 17.3283 18.7179C15.9354 19.1936 15 20.6404 15 22.3176V23.2496C15 23.6638 15.2905 24 15.65 24H27.35C27.7094 24 28 23.6638 28 23.2496V22.3281C28 20.6509 27.062 19.2034 25.6652 18.7269Z"
        fill="#9C9EB8"
      />
      <path
        d="M21.5 17C23.9853 17 26 14.9853 26 12.5C26 10.0147 23.9853 8 21.5 8C19.0147 8 17 10.0147 17 12.5C17 14.9853 19.0147 17 21.5 17Z"
        fill="#9C9EB8"
      />
    </svg>
  )
}

export default FriendIcon
