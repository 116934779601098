import React from 'react'

interface CircleContentProps {
  height?: string
  width?: string
}

const CircleContent: React.FC<CircleContentProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "367"} height={height || "329"} viewBox="0 0 367 329">
    <defs>
        <style>
            {".cls-3{fill:#12c4c4}.cls-2{isolation:isolate}"}
        </style>
    </defs>
    <g id="グループ_445" transform="translate(-1028 -636)">
        <path id="Path_935" d="M1210.332 947.31a160 160 0 0 1-52.782-8.8l.328-.945c27.253 9.467 56.537 11.262 87.04 5.332a169.952 169.952 0 0 0 77.9-37.311q.765-.656 1.526-1.319l.658.754q-.764.666-1.535 1.326a170.957 170.957 0 0 1-78.363 37.532 182.1 182.1 0 0 1-34.772 3.431z" style={{isolation:"isolate"}} fill="#12c4c4" opacity="0.23"/>
        <g id="_06" className="cls-2">
            <g id="Group_10647" className="cls-2">
                <path id="Path_936" d="M1207.671 925.239c0-2.509 1.342-3.938 3.146-3.938s3.136 1.429 3.136 3.938-1.343 3.939-3.136 3.939-3.146-1.43-3.146-3.939zm5.182 0c0-1.969-.836-2.959-2.036-2.959s-2.046.99-2.046 2.959.836 2.959 2.046 2.959 2.036-.989 2.036-2.959z" className="cls-3"/>
                <path id="Path_937" d="M1221.19 926.746a2.491 2.491 0 0 1-2.727 2.432c-2.068 0-3.235-1.386-3.235-3.84 0-2.629 1.485-4.037 3.641-4.037a3.7 3.7 0 0 1 1.915.451l-.418.858a2.673 2.673 0 0 0-1.474-.363c-1.563 0-2.564.979-2.564 2.9a2.421 2.421 0 0 0 .011.276 2.565 2.565 0 0 1 2.212-1.013 2.378 2.378 0 0 1 2.639 2.336zm-1.066.044c0-.9-.683-1.484-1.761-1.484a1.6 1.6 0 0 0-1.771 1.5c0 .792.638 1.485 1.827 1.485a1.524 1.524 0 0 0 1.705-1.501z" className="cls-3"/>
            </g>
        </g>
        <path id="Path_938" d="M1206.394 963.523a177.966 177.966 0 0 1-177.968-177.965h1a176.966 176.966 0 0 0 176.968 176.965z" className="cls-3"/>
        <path id="Path_939" d="M1313.786 938.9l-.544-.839a177.99 177.99 0 0 0 55.708-57.851 177.017 177.017 0 0 0-61.381-242.7l.511-.86a178.019 178.019 0 0 1 61.729 244.071 179.015 179.015 0 0 1-56.023 58.179z" style={{isolation: "isolate"}} fill="#12c4c4" opacity="0.8"/>
        <path id="長方形_357" d="M0 0H3V3H0z" className="cls-3" transform="translate(1205 962)"/>
        <path id="長方形_359" d="M0 0H3V3H0z" className="cls-3" transform="translate(1311.541 937.524)"/>
        <path id="長方形_358" d="M0 0H3V3H0z" className="cls-3" transform="translate(1028 784.524)"/>
        <path id="長方形_360" d="M0 0H3V3H0z" className="cls-3" transform="translate(1306 636)"/>
        <g id="グループ_444">
            <g id="_3lihel" className="cls-2">
                <g id="Group_10648" className="cls-2">
                    <path id="Path_940" d="M1192.4 800.856c0 1.179-.87 2.139-2.648 2.139a3.97 3.97 0 0 1-2.569-.849l.46-.791a3.142 3.142 0 0 0 2.109.75c1.049 0 1.648-.479 1.648-1.239 0-.739-.529-1.22-1.728-1.22h-.56v-.7l1.759-2.159h-3.371v-.869h4.617v.68l-1.829 2.258c1.403.131 2.112.92 2.112 2z" className="cls-3"/>
                    <path id="Path_941" d="M1195.093 795.919h1v6.127h3.788v.869h-4.788z" className="cls-3"/>
                    <path id="Path_942" d="M1202.224 795.919h1v7h-1z" className="cls-3"/>
                    <path id="Path_943" d="M1212.529 795.919v7h-1V799.8h-4.017v3.118h-1v-7h1v3.008h4.017v-3.008z" className="cls-3"/>
                    <path id="Path_944" d="M1220.9 802.046v.869h-5.077v-7h4.938v.869h-3.939v2.15h3.509v.849h-3.509v2.259z" className="cls-3"/>
                    <path id="Path_945" d="M1223.713 795.919h1v6.127h3.788v.869h-4.788z" className="cls-3"/>
                </g>
            </g>
            <path id="長方形_72" d="M0 0H60V1H0z" className="cls-3" transform="translate(1178.643 784.132)"/>
            <g id="B-t41" className="cls-2">
                <g id="Group_10649" className="cls-2">
                    <path id="Path_946" d="M1199.96 771.243c0 1.2-.909 1.889-2.679 1.889h-3.338v-7h3.138c1.609 0 2.509.679 2.509 1.808a1.659 1.659 0 0 1-.94 1.56 1.69 1.69 0 0 1 1.31 1.743zm-5.018-4.3v2.239h2.049c1.02 0 1.6-.379 1.6-1.12s-.58-1.119-1.6-1.119zm4.008 4.218c0-.809-.6-1.17-1.709-1.17h-2.3v2.329h2.3c1.11.002 1.709-.347 1.709-1.157z" className="cls-3"/>
                    <path id="Path_947" d="M1202.224 769.974h2.688v.829h-2.688z" className="cls-3"/>
                    <path id="Path_948" d="M1208.782 767.005h-2.4v-.869h5.786v.869h-2.4v6.127h-.989z" className="cls-3"/>
                    <path id="Path_949" d="M1219.1 771.413h-1.33v1.719h-.969v-1.719h-3.888v-.7l3.578-4.577h1.079l-3.428 4.418h2.689v-1.52h.939v1.52h1.33z" className="cls-3"/>
                    <path id="Path_950" d="M1222.813 766.136v7h-.99v-6.127h-1.579v-.869z" className="cls-3"/>
                </g>
            </g>
        </g>
    </g>
</svg>
  )
}

export default CircleContent
