import React from 'react'

interface Dots2x9ContentProps {
  height?: string
  width?: string
}

const Dots2x9Content: React.FC<Dots2x9ContentProps> = ({ height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '20'}
      height={height || '139'}
      viewBox="0 0 20 139"
    >
      <defs>
        <clipPath id="clippath-999">
          <path id="Rectangle_2848" d="M0 0H20V139H0z" className="cls-9989-1" />
        </clipPath>
        <clipPath id="clippath-999-3">
          <path
            id="Rectangle_2836"
            d="M0 0H3V3H0z"
            className="cls-9989-1"
            transform="translate(17)"
          />
        </clipPath>
        <clipPath id="clippath-999-4">
          <path
            id="Rectangle_2838"
            d="M0 0H3V3H0z"
            className="cls-9989-1"
            transform="translate(0 136)"
          />
        </clipPath>
        <style>
          {
            '.cls-9989-1{fill:none}.cls-9989-2{clippath-999:url(#clippath-999)}.cls-9989-3{fill:#12c4c4}.cls-9989-4{opacity:0}'
          }
        </style>
      </defs>
      <g id="DOTS1" transform="translate(10 69.5)">
        <g id="Group_10519" className="cls-9989-2" transform="translate(-10 -69.5)">
          <path
            id="Rectangle_2827"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(17 136)"
          />
          <path
            id="Rectangle_2828"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(17 119)"
          />
          <path
            id="Rectangle_2829"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(17 102)"
          />
          <path
            id="Rectangle_2830"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(17 85)"
          />
          <path
            id="Rectangle_2831"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(17 68)"
          />
          <path
            id="Rectangle_2832"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(17 51)"
          />
          <path
            id="Rectangle_2833"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(17 34)"
          />
          <path
            id="Rectangle_2834"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(17 17)"
          />
          <g id="Group_10518" className="cls-9989-2">
            <g id="Group_10515" className="cls-9989-4">
              <g id="Group_10514" clippath-999="url(#clippath-999-3)">
                <path
                  id="Rectangle_2835"
                  d="M0 0H3V3H0z"
                  className="cls-9989-3"
                  transform="translate(17)"
                />
              </g>
            </g>
            <g id="Group_10517" className="cls-9989-4">
              <g id="Group_10516" clippath-999="url(#clippath-999-4)">
                <path
                  id="Rectangle_2837"
                  d="M0 0H3V3H0z"
                  className="cls-9989-3"
                  transform="translate(0 136)"
                />
              </g>
            </g>
          </g>
          <path
            id="Rectangle_2840"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(0 119)"
          />
          <path
            id="Rectangle_2841"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(0 102)"
          />
          <path
            id="Rectangle_2842"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(0 85)"
          />
          <path
            id="Rectangle_2843"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(0 68)"
          />
          <path
            id="Rectangle_2844"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(0 51)"
          />
          <path
            id="Rectangle_2845"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(0 34)"
          />
          <path
            id="Rectangle_2846"
            d="M0 0H3V3H0z"
            className="cls-9989-3"
            transform="translate(0 17)"
          />
          <path id="Rectangle_2847" d="M0 0H3V3H0z" className="cls-9989-3" />
        </g>
      </g>
    </svg>
  )
}

export default Dots2x9Content
