import React from 'react'

interface AManTextProps {
  height?: string
  width?: string
}

const AManText: React.FC<AManTextProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "240"} height={height || "56"} viewBox="0 0 240 56">
      <defs>
        <style>{'.cls-1{isolation:isolate}.cls-2{fill:#12c4c4}'}</style>
      </defs>
      <g
        id="A_man_should_not_strive_to_eliminate_his_complexes_but_to_get_into_accord_with_them."
        className="cls-1"
      >
        <g id="Group_10643" className="cls-1">
          <path
            id="Path_866"
            d="M5.5 5.219H1.8l-.774 1.714H0L3.157.078h.986L7.31 6.933H6.264zm-.348-.783l-1.5-3.359-1.5 3.359z"
            className="cls-2"
          />
          <path
            id="Path_867"
            d="M19.865 6.933l-.009-5-2.52 4.167h-.458l-2.52-4.133v4.966H13.4V.078h.817l2.91 4.822L20 .078h.817l.01 6.855z"
            className="cls-2"
          />
          <path
            id="Path_868"
            d="M28.546 5.219h-3.7l-.767 1.714h-1.03L26.2.078h.987l3.167 6.855h-1.041zm-.346-.783l-1.5-3.359-1.5 3.359z"
            className="cls-2"
          />
          <path
            id="Path_869"
            d="M38.579.078v6.855h-.816L33.58 1.822v5.111h-1V.078h.82l4.184 5.112V.078z"
            className="cls-2"
          />
          <path
            id="Path_870"
            d="M45.09 6.149l.369-.763a3.624 3.624 0 0 0 2.3.793c1.205 0 1.723-.46 1.723-1.057 0-1.675-4.223-.617-4.223-3.163C45.259.9 46.1 0 47.929 0a4.14 4.14 0 0 1 2.24.608l-.328.782a3.646 3.646 0 0 0-1.912-.558c-1.186 0-1.694.49-1.694 1.087 0 1.675 4.223.627 4.223 3.143 0 1.048-.856 1.949-2.7 1.949a4.187 4.187 0 0 1-2.668-.862z"
            className="cls-2"
          />
          <path
            id="Path_871"
            d="M59.1.078v6.855h-1V3.878h-4v3.055h-1V.078h1v2.947h4V.078z"
            className="cls-2"
          />
          <path
            id="Path_872"
            d="M61.811 3.506a3.711 3.711 0 1 1 3.715 3.505 3.517 3.517 0 0 1-3.715-3.505zm6.414 0a2.71 2.71 0 0 0-5.418 0 2.71 2.71 0 0 0 5.418 0z"
            className="cls-2"
          />
          <path
            id="Path_873"
            d="M71.875 3.976V.078h1v3.858c0 1.519.707 2.2 1.962 2.2s1.972-.685 1.972-2.2V.078h.966v3.9a2.949 2.949 0 1 1-5.9 0z"
            className="cls-2"
          />
          <path id="Path_874" d="M81 .078h1v6h3.775v.852H81z" className="cls-2" />
          <path
            id="Path_875"
            d="M88.108.078h2.938c2.241 0 3.765 1.391 3.765 3.428s-1.524 3.427-3.765 3.427h-2.938zm2.878 6a2.552 2.552 0 0 0 2.829-2.575A2.552 2.552 0 0 0 90.986.93H89.1v5.151z"
            className="cls-2"
          />
          <path
            id="Path_876"
            d="M107.386.078v6.855h-.817l-4.183-5.111v5.111h-1V.078h.817l4.187 5.112V.078z"
            className="cls-2"
          />
          <path
            id="Path_877"
            d="M110.1 3.506a3.711 3.711 0 1 1 3.715 3.505 3.517 3.517 0 0 1-3.715-3.505zm6.414 0a2.71 2.71 0 0 0-5.418 0 2.71 2.71 0 0 0 5.418 0z"
            className="cls-2"
          />
          <path
            id="Path_878"
            d="M121.509.93h-2.39V.078h5.766V.93H122.5v6h-.986z"
            className="cls-2"
          />
          <path
            id="Path_879"
            d="M130.389 6.149l.368-.763a3.629 3.629 0 0 0 2.3.793c1.205 0 1.723-.46 1.723-1.057 0-1.675-4.223-.617-4.223-3.163C130.558.9 131.4 0 133.227 0a4.144 4.144 0 0 1 2.241.608l-.329.782a3.641 3.641 0 0 0-1.912-.558c-1.185 0-1.693.49-1.693 1.087 0 1.675 4.223.627 4.223 3.143 0 1.048-.856 1.949-2.7 1.949a4.183 4.183 0 0 1-2.668-.862z"
            className="cls-2"
          />
          <path
            id="Path_880"
            d="M139.684.93h-2.39V.078h5.767V.93h-2.39v6h-.987z"
            className="cls-2"
          />
          <path
            id="Path_881"
            d="M150 6.933l-1.5-2.105c-.14.01-.289.019-.439.019h-1.723v2.086h-1V.078h2.719c1.813 0 2.909.9 2.909 2.39a2.166 2.166 0 0 1-1.534 2.164l1.653 2.3zm-.03-4.465c0-.979-.668-1.538-1.942-1.538h-1.694v3.085h1.694c1.272 0 1.939-.568 1.939-1.547z"
            className="cls-2"
          />
          <path id="Path_882" d="M153.765.078h1v6.855h-1z" className="cls-2" />
          <path
            id="Path_883"
            d="M164.026.078l-3.058 6.855h-.986L156.915.078h1.075l2.51 5.631 2.53-5.631z"
            className="cls-2"
          />
          <path
            id="Path_884"
            d="M171.161 6.081v.852H166.1V.078h4.92V.93h-3.92v2.106h3.5v.832h-3.5v2.213z"
            className="cls-2"
          />
        </g>
        <g id="Group_10644" className="cls-1">
          <path
            id="Path_885"
            d="M2.44 25.424H.05v-.851h5.766v.851h-2.39v6H2.44z"
            className="cls-2"
          />
          <path
            id="Path_886"
            d="M7.423 28a3.517 3.517 0 0 1 3.715-3.506 3.511 3.511 0 1 1 0 7.012A3.517 3.517 0 0 1 7.423 28zm6.414 0a2.6 2.6 0 0 0-2.7-2.634 2.635 2.635 0 1 0 0 5.268 2.6 2.6 0 0 0 2.7-2.634z"
            className="cls-2"
          />
          <path
            id="Path_887"
            d="M26.473 30.576v.851h-5.06v-6.854h4.92v.851h-3.924v2.106h3.5v.833h-3.5v2.213z"
            className="cls-2"
          />
          <path id="Path_888" d="M29.276 24.573h1v6h3.774v.851h-4.77z" className="cls-2" />
          <path id="Path_889" d="M36.38 24.573h1v6.854h-1z" className="cls-2" />
          <path
            id="Path_890"
            d="M47.119 31.427l-.01-4.994-2.52 4.162h-.458l-2.519-4.133v4.965h-.957v-6.854h.817l2.909 4.817 2.868-4.817h.816l.01 6.854z"
            className="cls-2"
          />
          <path id="Path_891" d="M51.357 24.573h1v6.854h-1z" className="cls-2" />
          <path
            id="Path_892"
            d="M61.628 24.573v6.854h-.817l-4.183-5.111v5.111h-1v-6.854h.817l4.183 5.111v-5.111z"
            className="cls-2"
          />
          <path
            id="Path_893"
            d="M69.352 29.714h-3.7l-.767 1.713h-1.031l3.157-6.854H68l3.167 6.854h-1.049zM69 28.93l-1.5-3.358L66 28.93z"
            className="cls-2"
          />
          <path
            id="Path_894"
            d="M74.425 25.424h-2.39v-.851H77.8v.851h-2.39v6h-.987z"
            className="cls-2"
          />
          <path
            id="Path_895"
            d="M85.136 30.576v.851h-5.06v-6.854H85v.851h-3.928v2.106h3.5v.833h-3.5v2.213z"
            className="cls-2"
          />
          <path
            id="Path_896"
            d="M97.8 24.573v6.854h-1v-3.055h-4v3.055h-1v-6.854h1v2.947h4v-2.947z"
            className="cls-2"
          />
          <path id="Path_897" d="M101.082 24.573h1v6.854h-1z" className="cls-2" />
          <path
            id="Path_898"
            d="M104.719 30.644l.369-.764a3.627 3.627 0 0 0 2.3.793c1.205 0 1.723-.46 1.723-1.057 0-1.674-4.223-.617-4.223-3.163 0-1.057.837-1.959 2.669-1.959a4.152 4.152 0 0 1 2.241.608l-.329.782a3.649 3.649 0 0 0-1.912-.557c-1.185 0-1.693.49-1.693 1.087 0 1.674 4.223.626 4.223 3.143 0 1.047-.857 1.949-2.7 1.949a4.188 4.188 0 0 1-2.668-.862z"
            className="cls-2"
          />
          <path
            id="Path_899"
            d="M116.029 28a3.736 3.736 0 0 1 6.344-2.438l-.647.616a2.64 2.64 0 1 0 0 3.633l.647.617A3.728 3.728 0 0 1 116.029 28z"
            className="cls-2"
          />
          <path
            id="Path_900"
            d="M124.271 28a3.71 3.71 0 1 1 3.714 3.506A3.516 3.516 0 0 1 124.271 28zm6.413 0a2.71 2.71 0 1 0-2.7 2.634 2.6 2.6 0 0 0 2.7-2.634z"
            className="cls-2"
          />
          <path
            id="Path_901"
            d="M140.858 31.427l-.01-4.994-2.52 4.162h-.458l-2.52-4.133v4.965h-.956v-6.854h.817l2.908 4.817 2.868-4.817h.817l.01 6.854z"
            className="cls-2"
          />
          <path
            id="Path_902"
            d="M150.723 26.962c0 1.489-1.1 2.389-2.908 2.389h-1.723v2.076h-1v-6.854h2.719c1.816 0 2.912.901 2.912 2.389zm-1 0c0-.979-.667-1.538-1.942-1.538h-1.693V28.5h1.693c1.279 0 1.946-.558 1.946-1.538z"
            className="cls-2"
          />
          <path id="Path_903" d="M153.377 24.573h1v6h3.775v.851h-4.771z" className="cls-2" />
          <path
            id="Path_904"
            d="M165.541 30.576v.851h-5.059v-6.854h4.92v.851h-3.924v2.106h3.5v.833h-3.5v2.213z"
            className="cls-2"
          />
          <path
            id="Path_905"
            d="M172.646 31.427l-2.091-2.83-2.062 2.83h-1.145l2.619-3.515-2.459-3.339h1.135l1.952 2.634 1.932-2.634h1.086l-2.46 3.309 2.639 3.545z"
            className="cls-2"
          />
          <path
            id="Path_906"
            d="M180.987 30.576v.851h-5.06v-6.854h4.92v.851h-3.924v2.106h3.5v.833h-3.5v2.213z"
            className="cls-2"
          />
          <path
            id="Path_907"
            d="M183.152 30.644l.369-.764a3.627 3.627 0 0 0 2.3.793c1.2 0 1.722-.46 1.722-1.057 0-1.674-4.222-.617-4.222-3.163 0-1.057.836-1.959 2.669-1.959a4.149 4.149 0 0 1 2.24.608l-.328.782a3.654 3.654 0 0 0-1.912-.557c-1.185 0-1.693.49-1.693 1.087 0 1.674 4.222.626 4.222 3.143 0 1.047-.856 1.949-2.7 1.949a4.188 4.188 0 0 1-2.667-.862z"
            className="cls-2"
          />
        </g>
        <g id="Group_10645" className="cls-1">
          <path
            id="Path_908"
            d="M7.051 54.071c0 1.175-.906 1.851-2.669 1.851H1.055v-6.855h3.128c1.6 0 2.5.666 2.5 1.772a1.622 1.622 0 0 1-.936 1.528 1.661 1.661 0 0 1 1.304 1.704zm-5-4.211v2.193h2.042c1.016 0 1.593-.371 1.593-1.1s-.577-1.1-1.593-1.1zm3.994 4.133c0-.794-.6-1.147-1.7-1.147H2.051v2.282h2.291c1.106 0 1.703-.342 1.703-1.135z"
            className="cls-2"
          />
          <path
            id="Path_909"
            d="M9.724 52.964v-3.9h1v3.858c0 1.518.708 2.2 1.963 2.2s1.971-.685 1.971-2.2v-3.855h.967v3.9A2.712 2.712 0 0 1 12.673 56a2.717 2.717 0 0 1-2.949-3.036z"
            className="cls-2"
          />
          <path
            id="Path_910"
            d="M20.107 49.919h-2.39v-.852h5.767v.852h-2.39v6h-.987z"
            className="cls-2"
          />
          <path
            id="Path_911"
            d="M31.009 49.919h-2.39v-.852h5.766v.852H32v6h-.986z"
            className="cls-2"
          />
          <path
            id="Path_912"
            d="M35.992 52.494A3.711 3.711 0 1 1 39.707 56a3.516 3.516 0 0 1-3.715-3.506zm6.414 0a2.71 2.71 0 1 0-2.7 2.634 2.6 2.6 0 0 0 2.7-2.634z"
            className="cls-2"
          />
          <path
            id="Path_913"
            d="M54.813 52.455h.956v2.673a4.165 4.165 0 0 1-2.64.872 3.51 3.51 0 1 1 2.7-5.954l-.617.607a2.791 2.791 0 0 0-2.022-.793 2.638 2.638 0 1 0-.009 5.268 2.984 2.984 0 0 0 1.633-.441z"
            className="cls-2"
          />
          <path
            id="Path_914"
            d="M63.92 55.07v.852h-5.059v-6.855h4.92v.852h-3.924v2.1h3.5v.833h-3.5v2.218z"
            className="cls-2"
          />
          <path
            id="Path_915"
            d="M68.107 49.919h-2.39v-.852h5.767v.852h-2.39v6h-.987z"
            className="cls-2"
          />
          <path id="Path_916" d="M77.625 49.067h1v6.855h-1z" className="cls-2" />
          <path
            id="Path_917"
            d="M87.895 49.067v6.855h-.816L82.9 50.81v5.112h-1v-6.855h.816l4.184 5.111v-5.111z"
            className="cls-2"
          />
          <path
            id="Path_918"
            d="M92.561 49.919h-2.39v-.852h5.766v.852h-2.39v6h-.986z"
            className="cls-2"
          />
          <path
            id="Path_919"
            d="M97.545 52.494A3.71 3.71 0 1 1 101.259 56a3.516 3.516 0 0 1-3.714-3.506zm6.413 0a2.71 2.71 0 1 0-2.7 2.634 2.6 2.6 0 0 0 2.7-2.634z"
            className="cls-2"
          />
          <path
            id="Path_920"
            d="M115.977 54.208h-3.7l-.767 1.714h-1.026l3.157-6.855h.986l3.168 6.855h-1.047zm-.349-.784l-1.5-3.358-1.5 3.358z"
            className="cls-2"
          />
          <path
            id="Path_921"
            d="M119.348 52.494a3.737 3.737 0 0 1 6.344-2.438l-.647.616a2.64 2.64 0 1 0 0 3.633l.647.618a3.729 3.729 0 0 1-6.344-2.429z"
            className="cls-2"
          />
          <path
            id="Path_922"
            d="M127.589 52.494a3.737 3.737 0 0 1 6.344-2.438l-.647.616a2.64 2.64 0 1 0 0 3.633l.647.618a3.729 3.729 0 0 1-6.344-2.429z"
            className="cls-2"
          />
          <path
            id="Path_923"
            d="M135.829 52.494A3.711 3.711 0 1 1 139.544 56a3.516 3.516 0 0 1-3.715-3.506zm6.414 0a2.71 2.71 0 1 0-2.7 2.634 2.6 2.6 0 0 0 2.7-2.634z"
            className="cls-2"
          />
          <path
            id="Path_924"
            d="M150.615 55.922l-1.5-2.1c-.14.009-.289.019-.439.019h-1.723v2.086h-1v-6.86h2.719c1.813 0 2.908.9 2.908 2.389a2.165 2.165 0 0 1-1.533 2.164l1.653 2.3zm-.031-4.466c0-.979-.667-1.537-1.941-1.537h-1.694V53h1.694c1.274 0 1.941-.564 1.941-1.544z"
            className="cls-2"
          />
          <path
            id="Path_925"
            d="M154.384 49.067h2.938c2.241 0 3.764 1.39 3.764 3.427s-1.523 3.428-3.764 3.428h-2.938zm2.878 6a2.587 2.587 0 1 0 0-5.151h-1.882v5.154z"
            className="cls-2"
          />
          <path
            id="Path_926"
            d="M177.516 49.067l-2.33 6.855h-1.046l-1.9-5.474-1.9 5.474h-1.07l-2.331-6.855h1.026l1.883 5.571 1.961-5.571h.917l1.922 5.6 1.922-5.6z"
            className="cls-2"
          />
          <path id="Path_927" d="M179.923 49.067h1v6.855h-1z" className="cls-2" />
          <path
            id="Path_928"
            d="M185.582 49.919h-2.39v-.852h5.766v.852h-2.39v6h-.986z"
            className="cls-2"
          />
          <path
            id="Path_929"
            d="M197.229 49.067v6.855h-1v-3.056h-4v3.056h-1v-6.855h1v2.947h4v-2.947z"
            className="cls-2"
          />
          <path
            id="Path_930"
            d="M205.761 49.919h-2.39v-.852h5.766v.852h-2.39v6h-.986z"
            className="cls-2"
          />
          <path
            id="Path_931"
            d="M217.407 49.067v6.855h-.995v-3.056h-4v3.056h-1v-6.855h1v2.947h4v-2.947z"
            className="cls-2"
          />
          <path
            id="Path_932"
            d="M225.748 55.07v.852h-5.059v-6.855h4.92v.852h-3.924v2.1h3.5v.833h-3.5v2.218z"
            className="cls-2"
          />
          <path
            id="Path_933"
            d="M235.015 55.922l-.01-5-2.52 4.162h-.458l-2.52-4.132v4.965h-.956v-6.85h.817l2.908 4.817 2.869-4.817h.816l.01 6.855z"
            className="cls-2"
          />
          <path
            id="Path_934"
            d="M238.666 55.3a.66.66 0 0 1 .677-.666.65.65 0 0 1 .657.666.657.657 0 0 1-.657.676.667.667 0 0 1-.677-.676z"
            className="cls-2"
          />
        </g>
      </g>
    </svg>
  )
}

export default AManText
