import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const CancelBooking: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '33'}
      height={height || '33'}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33Z"
        fill="#FF6C6C"
      />
      <path
        d="M18.2595 16.5215L23.6 11.1723C23.7233 11.0577 23.8222 10.9194 23.8909 10.7656C23.9596 10.6118 23.9966 10.4457 23.9998 10.2772C24.0029 10.1088 23.9722 9.94142 23.9093 9.78514C23.8465 9.62886 23.7528 9.48688 23.6339 9.36768C23.515 9.24848 23.3734 9.1545 23.2174 9.09136C23.0615 9.02822 22.8944 8.99721 22.7263 9.0002C22.5581 9.00318 22.3922 9.04009 22.2386 9.10872C22.085 9.17735 21.9467 9.27629 21.8322 9.39963L16.4897 14.7488L11.1492 9.39963C11.0336 9.28049 10.8955 9.18554 10.7429 9.12032C10.5904 9.0551 10.4264 9.0209 10.2605 9.01971C10.0947 9.01852 9.93023 9.05037 9.77676 9.1134C9.6233 9.17642 9.48387 9.26938 9.36658 9.38686C9.24929 9.50433 9.15649 9.64399 9.09356 9.79771C9.03064 9.95142 8.99885 10.1161 9.00003 10.2823C9.00122 10.4484 9.03536 10.6126 9.10048 10.7654C9.16559 10.9182 9.26038 11.0566 9.37934 11.1723L14.7198 16.5215L9.37934 21.8727C9.15016 22.1089 9.02301 22.426 9.02536 22.7554C9.02772 23.0847 9.15939 23.3999 9.39191 23.6328C9.62443 23.8657 9.93913 23.9976 10.268 24C10.5968 24.0023 10.9133 23.875 11.1492 23.6454L16.4897 18.2962L21.8322 23.6434C22.0699 23.8617 22.3825 23.9797 22.7049 23.9727C23.0274 23.9658 23.3347 23.8345 23.5628 23.6061C23.7909 23.3778 23.9222 23.07 23.9293 22.7471C23.9364 22.4242 23.8188 22.1109 23.601 21.8727L18.2595 16.5215Z"
        fill="white"
      />
    </svg>
  )
}

export default CancelBooking
