import React from 'react'
import { useHistory } from 'react-router'
import { POST, ROUTER_URL } from '../../../../utils/const'
import { CHANGE_CUSTOMER_EMAIL } from '../../../../utils/endpoints'
import { useDispatch } from 'react-redux'
import { checkSignin } from '../../../stores'
import MoonLoader from 'react-spinners/MoonLoader'

interface ChangeEmailProps {
  callApi: any
  onLogout: any
}

const ChangeEmail: React.FC<ChangeEmailProps> = ({ callApi, onLogout }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const url_string = window.location.href
  const url = new URL(url_string)
  const key = url.searchParams.get('key')
  const customer_id = url.searchParams.get('customer_id')
  React.useEffect(() => {
    const params = {
      key: key,
      customer_id: customer_id,
    }
    const getCheckChange = async () => {
      try {
        const result = await callApi(CHANGE_CUSTOMER_EMAIL, POST, params, false)
        if (result?.status === 200) {
          dispatch(checkSignin(false))
          history.push(ROUTER_URL.SIGNUP_SUCCESS)
        } else history.push(ROUTER_URL.SIGNUP_ERROR)
        onLogout()
      } catch (error) {
        onLogout()
        history.push(ROUTER_URL.SIGNUP_ERROR)
      }
    }
    getCheckChange()
  }, [])
  return (
    <div
      className="default-padding"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <MoonLoader size={200} loading={true} color={'#12c4c4'} />
    </div>
  )
}

export default ChangeEmail
