import React from 'react'
import { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Modal } from '../../components/modules/modal'
import DeleteBookingModal from './delete-booking-modal'
import { HISTORY_BOOKING } from '../../../../utils/endpoints'
import useWindowSize from '../../../../customize/hooks/useWindowSize'
import {
  CalendarCancel,
  ClockIcon,
  DropdownIconDown,
  DropdownIconUp,
  TakenCheck,
  TimeExpired,
  TrashBinDisable,
  TrashBinEnable,
} from './svg'
import classNames from 'classnames'
import { GET } from '../../../../utils/const'
interface BookingHistoryProps {
  callApi: any
  notify: any
}

interface DropdownListProps {
  reservation_id: number
  is_open: boolean
}

const BookingHistory: React.FC<BookingHistoryProps> = ({ callApi, notify }) => {
  const [show, setShow] = useState(false)
  const [selected, setSelected] = useState(null)
  const size = useWindowSize()
  const [items, setItems] = useState([])
  const [t] = useTranslation('translation')
  const [dataHistory, setDataHistory] = useState([])
  const [idOpen, setIdOpen] = useState<any>()

  const [msgNoDataHistory, setMsgNoDataHistory] = useState('')

  const toggleModal = (id?: any) => {
    setSelected(id)
    setShow((show) => !show)
  }

  const getArrayOfHistory = async (result: any) => {
    const response = await result?.data?.data
    return response
  }

  const getDropdownList = async (result: DropdownListProps[]) => {
    const list = await result?.map((item: DropdownListProps) => ({
      reservation_id: item.reservation_id,
      is_open: false,
    }))
    return list || []
  }

  useEffect(() => {
    onInit()
  }, [localStorage.getItem('i18nextLng')])

  function onInit() {
    const fetchItem = async () => {
      try {
        const params = {
          per_page: 100,
          page: 2,
        }
        const result = await callApi(HISTORY_BOOKING, GET, { params: params })
        let data = result?.data?.data
        if (data) {
          if (!data?.length) {
            setMsgNoDataHistory('booking.booking_history_no_data')
          }
          setDataHistory(data)
          setIdOpen(data[0].reservation_id)
          setItems(data)
        }
        return result
      } catch (error) {
        return error
      }
    }
    fetchItem()
      .then((result) => getArrayOfHistory(result))
      .then((result) => getDropdownList(result))
  }

  const renderBookingTab = (status: string) => {
    switch (status) {
      case '001':
        return (
          <>
            <ClockIcon />
            <span>{t('booking_history.before_scanning')}</span>
          </>
        )
      case '004':
        return (
          <>
            <CalendarCancel />
            <span>{t('booking_history.cancel')}</span>
          </>
        )
      case '003':
        return (
          <>
            <TimeExpired />
            <span>{t('booking_history.expired')}</span>
          </>
        )
      case '002':
        return (
          <>
            <TakenCheck />
            <span>{t('booking_history.shot_already')}</span>
          </>
        )
      default:
        return null
    }
  }

  const renderWideTable = (
    <>
      <div style={{ fontSize: '1.6rem', color: '#111947' }} className="distance-text-to-line">
        <p style={{ lineHeight: '3.2rem' }}>
          <Trans
            i18nKey="booking_history.des"
            components={{
              br: <br />,
            }}
          />
        </p>
      </div>
      <div style={{ borderBottom: '1px solid #ccc' }} className="distance-line-to-table"></div>
      <table className="booking__history__wide-table">
        <thead>
          <tr>
            <th>{t('booking_history.status')}</th>
            <th>{t('booking_history.shooting_day')}</th>
            <th>{t('booking_history.place_name')}</th>
            <th>{t('booking_history.address')}</th>
            <th>{t('booking_history.booking_day')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dataHistory &&
            dataHistory.map((item: any) => {
              return (
                <tr key={item.reservation_id}>
                  <td>
                    <div
                      className={classNames('booking__history__booking-tab', {
                        'booking__history__active-text': item.status === '001',
                      })}
                    >
                      {renderBookingTab(item.status)}
                    </div>
                  </td>
                  <td
                    className={classNames({
                      'booking__history__active-text': item.status === '001',
                    })}
                  >
                    {item['shooting_time']}
                  </td>
                  <td
                    className={classNames({
                      'booking__history__active-text': item.status === '001',
                    })}
                  >
                    <p>{item.scanner_name}</p>
                  </td>
                  <td
                    className={classNames({
                      'booking__history__active-text': item.status === '001',
                    })}
                  >
                    <p className="text-pre-line">{item.address}</p>
                  </td>
                  <td>{item['date']}</td>
                  <td>
                    <button
                      className="booking__history__trash-button"
                      onClick={() => toggleModal(item.reservation_id)}
                      disabled={item.status !== '001'}
                    >
                      {item.status === '001' ? <TrashBinEnable /> : <TrashBinDisable />}
                    </button>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </>
  )

  const renderNarrowTable = (
    <div className="booking__history__narrow-table">
      <div className="booking__history__lead">
        <p>
          <Trans
            i18nKey="booking_history.des"
            components={{
              br: <br />,
            }}
          />
        </p>
      </div>
      {dataHistory?.map((item: any) => {
        return (
          <React.Fragment key={item.reservation_id}>
            <table
              className={classNames({
                'dropdown-close': item.reservation_id !== idOpen,
              })}
            >
              <tbody>
                <tr>
                  <th>{t('booking_history.status')}</th>
                  <td>
                    <div
                      className={classNames('booking__history__booking-tab', {
                        'booking__history__active-text': item.status === '001',
                      })}
                    >
                      {renderBookingTab(item.status)}
                    </div>
                    <div
                      className="booking__history__dropdown-icon"
                      onClick={() =>
                        setIdOpen((prevState: any) =>
                          prevState === item['reservation_id'] ? 0 : item['reservation_id']
                        )
                      }
                    >
                      {
                        item.reservation_id === idOpen ? <DropdownIconUp /> : <DropdownIconDown />
                      }
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{t('booking_history.shooting_day')}</th>
                  <td
                    className={classNames({
                      'booking__history__active-text': item.status === '001',
                    })}
                  >
                    {item['shooting_time']}
                  </td>
                </tr>
                <tr>
                  <th>{t('booking_history.place_name')}</th>
                  <td
                    className={classNames({
                      'booking__history__active-text': item.status === '001',
                    })}
                  >
                    <p>{item.scanner_name}</p>
                  </td>
                </tr>
                <tr>
                  <th>{t('booking_history.address')}</th>
                  <td
                    className={classNames({
                      'booking__history__active-text': item.status === '001',
                    })}
                  >
                    <p className="text-pre-line">{item.address}</p>
                  </td>
                </tr>
                <tr>
                  <th>{t('booking_history.booking_day')}</th>
                  <td>{item['date']}</td>
                </tr>
                <tr>
                  <th></th>
                  <td>
                    <button
                      className="booking__history__trash-button"
                      onClick={() => toggleModal(item.reservation_id)}
                      disabled={item.status !== '001'}
                    >
                      {item.status === '001' ? <TrashBinEnable /> : <TrashBinDisable />}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </React.Fragment>
        )
      })}
    </div>
  )

  return (
    <div className="booking__history">
      <Modal
        modalStyles="booking__delete-modal-booking__container padding-modal-in-h2"
        toggleModal={toggleModal}
        show={show}
      >
        <DeleteBookingModal
          onInit={onInit}
          callApi={callApi}
          selected={selected}
          closeModal={() => setShow(false)}
          items={items}
          notify={notify}
        />
      </Modal>

      <div className="booking__history__inner-container">
        <div className="booking__history__content">
          {size.width > 1201 ? renderWideTable : renderNarrowTable}
          {msgNoDataHistory && (
            <p style={{ textAlign: 'center', paddingTop: '1rem', color: '#111947' }}>
              {t(msgNoDataHistory)}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default BookingHistory
