import { useTranslation } from 'react-i18next'
import { ShieldIcon } from '../../../../../assets/images/svgs'
import Wrapper from '../../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../../utils/const'
import PrivacyPolicyConstants from './constants'
import MobileHeader from '../../../components/block/mobile/header'

interface PrivacyPolicyMobileProps {}

const PrivacyPolicyMobile: React.FC<PrivacyPolicyMobileProps> = () => {
  const [t] = useTranslation('translation')
  const list = [
    {
      name: t('policy.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('policy.privacy_policy.breadcrum'),
      link: ROUTER_URL.PRIVACY_POLICY,
    },
  ]
  return (
    <div>
      <MobileHeader />
      <Wrapper
        breadcrum={list}
        title={t('policy.privacy_policy.title')}
        buttonText={''}
        buttonLink={ROUTER_URL.HOME}
        className="policy"
        Icon={ShieldIcon}
        classNameBtn={'normal'}
      >
        <PrivacyPolicyConstants>
        </PrivacyPolicyConstants>
      </Wrapper>
    </div>
  )
}
export default PrivacyPolicyMobile
