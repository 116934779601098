import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { WarningIcon } from '../../../../assets/images/svgs'
import Wrapper from '../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../utils/const'

interface WithDrawNotiProps {}

const WithDrawNoti: React.FC<WithDrawNotiProps> = () => {
  const [, i18n] = useTranslation()
  const history: any = useHistory()
  const location: any = useLocation()

  const list = [
    {
      name: i18n.t('signup.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('menu.account_setting'),
      link: ROUTER_URL.SETTINGS,
    },
    {
      name: i18n.t('withdraw.title'),
      link: ROUTER_URL.WITHDRAW,
    },
    {
      name: i18n.t('withdraw.completed'),
      link: ROUTER_URL.WITHDRAW_NOTI,
    },
  ]

  return (
    <Wrapper
      breadcrum={list}
      buttonLink={ROUTER_URL.HOME}
      buttonText={i18n.t('forgot_password_noti.back_to_home')}
      className="active-mail"
    >
      <div className="active-mail__container">
        <h2 className="title-h2 distance-h2-to-text">{i18n.t('withdraw.completed_title')}</h2>
        <div className="active-mail__content">
          <div className="active-mail__already-sent distance-text-to-noti" id="active-title-email">
            <p className="active-message">
              <Trans
                i18nKey="withdraw.message"
                values={{ email: location?.state?.email }}
                components={{
                  span: <span className="password__describe-content1_link" />,
                  br: <br />,
                  b: <b />,
                }}
              />
            </p>
          </div>
          <div className="password__warning password__warning-noti distance-text-to-btn">
            <div
              className="password__warning-icon password__warning-icon_noti padding-title-noti"
              id="active-warning-icon"
            >
              <WarningIcon />
              <div className="title-h4 distance-h4-to-icon">
                {i18n.t('active_email_update.content1')}
              </div>
            </div>
            <div className="password__warning-content password__warning-content_noti padding-content-noti padding-content-confirm-email">
              <p className="password__warning-content_noti-text1">
                <Trans
                  i18nKey="withdraw.warning_1"
                  values={{ value: `"Spam mail folder"` }}
                  components={{ br: <br /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey="withdraw.warning_2"
                  components={{
                    br: <br />,
                    span: <span className="text-link" onClick={() => history.push(ROUTER_URL.CONTACT)} />,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default WithDrawNoti
