import React from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTER_URL } from '../../../../utils/const'
import Wrapper from '../../components/modules/_wrapper'

interface SignupErrorProps {}

const SignupError: React.FC<SignupErrorProps> = () => {
  const [, i18n] = useTranslation()

  const list = [
    {
      name: i18n.t('signup.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('signup.active_email_error.breadcrum'),
      link: ROUTER_URL.ACTIVE_EMAIL_ERR,
    },
  ]

  return (
    <Wrapper
      breadcrum={list}
      buttonLink={ROUTER_URL.SIGNIN}
      buttonText={i18n.t('signup.active_email_error.button_err')}
      className="active-mail"
    >
      <div className="active-mail__container">
        <h2 className="title-h2-error distance-h2-to-text">{i18n.t('signup.active_email_error.title')}</h2>
        <div className="active-mail__content-err distance-text-to-btn" style={{ paddingBottom: 0 }}>
          <p className="text-pre-line" style={{ fontWeight: 400 }}>
            {i18n.t('signup.active_email_error.content')}
          </p>
        </div>
      </div>
    </Wrapper>
  )
}

export default SignupError
