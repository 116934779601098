import Wrapper from '../../components/modules/_wrapper'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { ROUTER_URL } from '../../../../utils/const';

interface ReDownloadResultProps {
  btn_text: string,
  btn_link: string,
  content_title: string,
  content1: string,
  content2: string
}

const ReDownloadResult: React.FC<ReDownloadResultProps> = ({btn_text, btn_link, content_title, content1, content2}) => {

  const title = '再ダウンロードに関するお知らせ | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = '再ダウンロードに関するお知らせのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
  }
  
  const [, i18n] = useTranslation('translation')

  const list = [
    {
      name: i18n.t('re_download.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('re_download.title'),
      link: ROUTER_URL.RE_DOWNLOAD,
    },
  ]

  return (
    <Wrapper
      buttonText={i18n.t(btn_text)}
      buttonLink={btn_link}
      breadcrum={list}
      className="password"
      typeBreadcrums={1}
      isNoMarginTop={false}
    >
      <div className="re-download__container distance-h2-to-text">
        <h2 className="title-h2">
          {i18n.t(content_title)}
        </h2>
      </div>
      <div className="re-download__describe-content-outline distance-text-to-btn">
        <p className="re-download__describe-content">
          {i18n.t(content1)}<br />
          {i18n.t(content2)}
        </p>
      </div>
    </Wrapper>
  )
}
export default ReDownloadResult
