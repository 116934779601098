import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { defaultValues, editableOptions, validator } from './constants'
import { IoIosAddCircleOutline } from 'react-icons/io'
import classNames from 'classnames'
import { useTranslation, Trans } from 'react-i18next'
import { TextInput } from '../../../../components/atoms/text-input'
import { SelectInput3 } from '../../../../components/atoms/select-input-3'
import useWindowSize from '../../../../../../customize/hooks/useWindowSize'
import { fileFormatSelectorStyles } from '../../download-avatar/constants'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  DOWNLOAD_AVATAR,
  INFO_CREADIT,
  AVATAR_TYPE,
  CONVERT_AVATAR,
  AVATAR_PRICE,
} from '../../../../../../utils/endpoints'
import { saveBlobAsFile } from '../../../../../../utils/file'
import Wrapper from '../../../../components/modules/_wrapper'
import { Button } from '../../../../components/atoms/buttons/index'
import { Modal } from '../../../../components/modules/modal'
import AvatarDetailComponent from '../../../../components/modules/control-avatar/detail'
import DownloadModal2 from './../../download-modal-2'
import { EDITABLE, EDITED, ERROR_TYPE, GET, ROUTER_URL } from '../../../../../../utils/const'
import { GET_AVATAR_DETAIL } from '../../../../../../utils/endpoints'
import CreditModal from '../../credit-modal'
import { CloseIcon, WarningIcon } from '../../../../../../assets/images'
import { RadioCheck, RadioUnCheck } from '../../../../../../assets/images/svgs/radio-icon'
import { isEmpty } from 'lodash'
import ConvertModal from '../../convert-modal'
import axios from '../../../../../../utils/axios'

interface DownloadAvatarEditedProps {
  editType: string //'voxel' | 'custom'
  notify?: Function
  callApi: any
}

interface CardType {
  value: string
  brand: string
}

const DownloadAvatarEdited: React.FC<DownloadAvatarEditedProps> = ({
  notify = () => {},
  callApi,
}) => {
  const [creditValue, setCreditValue] = React.useState('')
  const [listCardCreadit, setListCardCreadit] = React.useState([])
  const [t] = useTranslation()
  const [price, setPrice] = React.useState('')
  const [showModal, setShowModal] = React.useState(false)
  const size = useWindowSize()
  const location: any = useLocation()
  const history: any = useHistory()
  const params: any = useParams()
  const [imageAvatar, setImageAvatar] = useState('')
  const [showModalCredit, setShowModalCredit] = useState(false)
  const [showModalConvert, setShowModalConvert] = useState(false)

  const [numberCard, setNumberCard] = useState('')
  const [defaultNumberCard, setDefaultNumberCard] = useState('')
  const [nameCardBand, setNameCardBand] = useState('')
  const [defaultCardBand, setDefaultCardBand] = useState('')
  const [isDownloaded, setIsDownloaded] = useState(false)

  const editType = EDITABLE
  const userData = useSelector((state: any) => state.signin.userData)

  const methods = useForm({
    defaultValues,
    resolver: validator(t),
  })

  React.useEffect(() => {
    renderFileFormatSelect(editType)
  }, [editType])

  const toggleModal = () => {
    setShowModal((showModal) => !showModal)
  }

  const toggleModalCredit = () => {
    setShowModalCredit((showModalCredit) => !showModalCredit)
  }

  const toggleModalConvert = () => {
    setShowModalConvert((showModalConvert) => !showModalConvert)
  }

  const getValueCard = (cardName: any, numberCard: any) => {
    setNameCardBand(cardName)
    setNumberCard(numberCard)
  }

  const { handleSubmit, errors, setValue, getValues } = methods
  const isErrorFormat = !!errors?.editable
  React.useEffect(() => {
    getInfoCreadit()
    getDetailAvatar()
  }, [])

  const convertAvatar = async (values: any) => {
    try {
      const editable = getValues('editable')
      let file_format = editable?.value
      const params = {
        model_type:  location?.state?.model_type,
        file_format: file_format,
        avatar_id: location?.state?.id,
      }
      const result = await callApi(CONVERT_AVATAR, GET, { params: params }, true, false)
      if (result.data["data"] === true)
      {        
        toggleModal()
      }
      else
      {
        toggleModalConvert()
      }
    } catch (error) {
    }
  }
  
  const getInfoCreadit = async () => {
    try {
      const result = await callApi(INFO_CREADIT)
      if (result.status === 200) {
        setListCardCreadit(result.data.data)
        for (let card of result.data.data) {
          if (card.card_default) {
            setCreditValue(card.card_id)
            setDefaultNumberCard(card.card_number)
            setDefaultCardBand(card.card_brand)
          }
        }
      }
    } catch (error) {
    }
  }

  const setAvatarImage = (url: string) => {
    axios.get(url, {responseType: 'blob',}).then(res => {
      setImageAvatar(URL.createObjectURL(res?.data))
    })
  }

  React.useEffect(() => {
    const getListDownloadInfo = async () => {
      try {
        const param = {
          avatar_type: EDITED,
          avatar_id: location?.state?.id || params?.id,
        }
        const result = await callApi(AVATAR_TYPE, GET, { params: param })
        if (result.status === 200) {
          if (result.data.data.avatar_type === 'Custom') {
            setValue('custom', '')
            setValue(`custom-label`, result.data.data.avatar_format_name, { shouldValidate: false })
          } else {
            setValue('custom', editableOptions[0])
            setValue(`voxel-label`, result.data.data.avatar_format_name, { shouldValidate: false })
          }
        }
      } catch (error) {
      }
    }
    getListDownloadInfo()
  }, [])

  React.useEffect(() => {
    if (!isEmpty(errors)) {
      handleSubmit(toggleModal)()
    }
  }, [localStorage.getItem('i18nextLng')])

  React.useEffect(() => {
    const getPrice = async () => {
      try {
        const result = await callApi(AVATAR_PRICE)
        if (result.status === 200) {
          const price = result.data.data
          setPrice(price)
        }
      } catch (error) {
      }
    }
    getPrice()
  }, [])


  const onSubmit = async () => {
    toggleModal()
    const editable = getValues('editable')
    const params = {
      avatar_id: location?.state?.id,
      avatar_type: EDITED,
      file_format: editable?.value,
      edit_type: editType,
      card_id: creditValue,
      
    }
    try {
      const response = await callApi(DOWNLOAD_AVATAR, GET, { params, responseType: 'blob' })
      if (response) {
        saveBlobAsFile(response.data, response.headers['content-disposition'].split('=').pop())
        setIsDownloaded(true)
      }
    } catch (error) {
      if (error) notify(error?.message, ERROR_TYPE)
    }
  }

  const list = [
    {
      name: t('download_avatar.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('download_avatar.avatar_management'),
      link: ROUTER_URL.CONTROL_AVATAR,
    },
    {
      name: t('download_avatar.avatar_details'),
      link: `${ROUTER_URL.CONTROL_AVATAR_EDITED}/${params['model_type']}/${location?.state?.id}`,
    },
    {
      name: t('download_avatar.download'),
      link: ROUTER_URL.DOWNLOAD_AVATAR_EDITED,
    },
  ]

  async function getDetailAvatar() {
    const { id } = params
    if (!id) {
      history.push(ROUTER_URL.CONTROL_AVATAR)
    }
    const param = {
      avatar_type: EDITED,
      avatar_id: id,
      model_type: location?.state?.model_type? location?.state?.model_type : params['model_type']
    }

    try {
      const result = await callApi(GET_AVATAR_DETAIL, GET, { params: param })
      if (result?.status === 200) {
        setAvatarImage(result?.data?.data?.avatar.s3_path)
      }
    } catch (err) {
      history.push(ROUTER_URL.CONTROL_AVATAR)
      notify(t('detail-avatar.no_avatar_edit'), ERROR_TYPE)
    }
  }

  const renderSidebarArea = () => (
    <div className="download-avatar-user__sidebar-area">
      <AvatarDetailComponent image={imageAvatar}  />
    </div>
  )

  const renderFileFormatSelect = (editType: string) => {
    switch (editType) {
      case EDITABLE:
        return (
          <SelectInput3
            name="editable"
            styles={fileFormatSelectorStyles(isErrorFormat)}
            options={editableOptions}
            placeholder={t('download_avatar.select_format')}
          />
        )
      case 'Custom':
        return (
          <SelectInput3
            name="custom"
            styles={fileFormatSelectorStyles(isErrorFormat)}
            options={editableOptions}
            placeholder={t('download_avatar.select_format')}
          />
        )
      case 'Voxel':
        return <TextInput name="voxel" isRequire={true} isDisabled={true} />
      default:
        return null
    }
  }

  const renderAvatarInfo = (
    <div className="download-avatar-user__avatar-info">
      <div className="download-avatar-user__avatar-name">
        <p className="download-avatar-user__avatar-name-ttle">{location?.state?.name}</p>
      </div>
      <div className="download-avatar-user__file-format">
        <h2 className="download-avatar-user__file-format-label">
          {t('download_avatar.file_format_selection')}
        </h2>
        <div className="download-avatar-user__file-format-select">
          {renderFileFormatSelect(editType)}
          <div style={{ display: 'none' }}>
            <SelectInput3
              name="custom"
              styles={fileFormatSelectorStyles(isErrorFormat)}
              options={editableOptions}
              placeholder={t('download_avatar.select_format')}
            />
          </div>
        </div>
      </div>
      <div className="download-avatar-user__price-area">
        <p className="download-avatar-user__price-label">{t('download_avatar.download_fee')}</p>
        <div className="download-avatar-user__price">
          {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(
            Number(price)
          )}
        </div>
      </div>
    </div>
  )

  const renderAvatarCredit = (
    <div className="download-avatar-user__credit-info">
      <h2 className="download-avatar-user__credit-label">
        {t('download_avatar.credit_information')}
      </h2>
      <div className="download-avatar-user__credit-describe">
        {t('download_avatar.credit_describe')}
      </div>
      <div className="download-avatar-user__credit-list">
        {listCardCreadit?.map((card: CardType) => (
          <div
            key={card['card_id']}
            className={classNames('download-avatar-user__credit-bar', {
              'credit-active': card['card_default'] === true,
            })}
          >
            <div className="download-avatar-user__credit-couple">
              <div
                className={classNames({ 'credit-active': card['card_default'] === true })}
                onClick={() => {
                  setCreditValue(card['card_id'])
                  getValueCard(card['card_brand'], card['card_number'])
                }}
              >
                {card['card_id'] === creditValue ? <RadioCheck /> : <RadioUnCheck />}
              </div>
              <label className="download-avatar-user__credit-brand">{card['card_brand']}</label>
            </div>
            {size.width < 576 ? (
              <div className="download-avatar-user__credit-series">
                <span>*</span>
                <span>{card?.value?.slice(12, 16)}</span>
              </div>
            ) : (
              <span className="download-avatar-user__credit-series">
                <span>**** - **** - **** - {card['card_number'].slice(12, 16)}</span>
              </span>
            )}
          </div>
        ))}
        <div className="download-avatar-user__credit-add" onClick={toggleModalCredit}>
          <IoIosAddCircleOutline size="30" color="#12c4c4" />
          <div className="download-avatar-user__credit-add-content">
            {/* カードを追加する */}
            {t('download_avatar.add_card')}
          </div>
        </div>
      </div>
    </div>
  )

  const renderSubmit = (
    <div className="download-avatar-user__submit">
      <Button type="submit" className="download-avatar-user__submit-button" disabled={!creditValue}>
        {t('download_avatar.download')}
      </Button>
    </div>
  )
  function toggleDownloaded() {
    setIsDownloaded((prevState: any) => !prevState)
  }
  return (
    <Wrapper
      breadcrum={list}
      title={t('download_avatar.title')}
      className="download-avatar-user__container"
      type="dropdown"
    >
      <Modal
        modalStyles="booking__modal-booking padding-modal-in-h2"
        show={showModalCredit}
        toggleModal={toggleModalCredit}
      >
        <CreditModal
          toggleModal={toggleModalCredit}
          detailListCard={getInfoCreadit}
          callApi={callApi}
          notify={notify}
        />
      </Modal>
      <Modal
        modalStyles="booking__modal-booking padding-modal-in-h2"
        show={isDownloaded}
        toggleModal={toggleDownloaded}
      >
        <div className="detail-avatar__delete-modal">
          <div className="detail-avatar__delete-modal_header distance-h2-to-line">
            <div className="detail-avatar__delete-modal_header-text">
              <div className="pop-text-bold-big">{t('download_avatar.modal_download.title')}</div>
            </div>
            <button
              className="detail-avatar__delete-modal_header-icon close-icon"
              onClick={toggleDownloaded}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="box-notification distance-line-to-text">
            <div className="download-message">
              <Trans
                i18nKey="download_avatar.modal_download.content" // optional -> fallbacks to defaults if not provided
                values={{ email: userData?.mail_address }}
                components={{ span: <span /> }}
              />
            </div>
            <div className="download-notification">
              <div className="download-notification__title">
                <WarningIcon /> {t('active_email_update.content1')}
              </div>
              <div className="download-notification__content">
                <p>※1 {t('active_email_update.content2')}</p>
                <p>※2 {t('active_email_update.content3')}</p>
                <br />
                <p>
                  <Trans
                    i18nKey="active_email_update.content7"
                    components={{
                      span: (
                        <span className="text-link" onClick={() => history.push(ROUTER_URL.CONTACT)} />
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="detail-avatar__delete-modal_button">
            <Button onClick={toggleDownloaded} className="normal">
              {t('detail-avatar.delete_btn2')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        modalStyles="booking__modal-booking pading-modal-in-h2"
        show={showModal}
        toggleModal={toggleModal}
      >
        <DownloadModal2
          price={price}
          toggleModal={toggleModal}
          dowloadAvatar={onSubmit}
          cardNumber={numberCard}
          cardNumberDefault={defaultNumberCard}
          cardBand={nameCardBand}
          cardBandDefault={defaultCardBand}
        />
      </Modal>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(convertAvatar)}>
        <Modal
            modalStyles="booking__modal-booking padding-modal-in-h2"
            show={showModalConvert}
            toggleModal={toggleModalConvert}
          >
            <ConvertModal
              toggleModal={toggleModalConvert}
            />
          </Modal>
          <div className="download-avatar-user__inner-container">
            {renderSidebarArea()}
            <div className="download-avatar-user__content">
              {renderAvatarInfo}
              {renderAvatarCredit}
              {renderSubmit}
            </div>
          </div>
        </form>
      </FormProvider>
    </Wrapper>
  )
}

export default DownloadAvatarEdited
