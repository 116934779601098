import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ExportIcon } from '../../../../assets/images/svgs'
import { Trans, useTranslation } from 'react-i18next'
import Wrapper from '../../components/modules/_wrapper'
import { GET, POST, ROUTER_URL, TOKEN_PATTERN } from '../../../../utils/const'
import { appimg_cluster } from '../../../../assets/images'
import { avatarChara } from '../../../../assets/images'
import { exportCluster1 } from '../../../../assets/images'
import { exportCluster2 } from '../../../../assets/images'
import { exportCluster3 } from '../../../../assets/images'
import { exportCluster9 } from '../../../../assets/images'
import { GET_REGISTER_TOKEN, REGISTER_EXPORT_TOKEN } from '../../../../utils/endpoints'
import { Modal } from '../../components/modules/modal'
import MessageModal from '../../components/modules/modal/message-modal'

// tslint:disable-next-line:no-empty-interface
interface ExportIconProps {
  callApi: any
}
// tslint:disable-next-line:no-empty-interface
const ControlExport: React.FC<ExportIconProps> = ({ callApi }) => {

  const title = 'エクスポート管理 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'エクスポート管理のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [t, i18n] = useTranslation('translation')
  const [token, setToken] = useState('')
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState('')
  const [firstLosd, setFirstLosd] = useState(true)


  useEffect(() => {
    getToken()
  }, [])

  useEffect(() => {
    if (!firstLosd) {
      checkError(token)
    setFirstLosd(false)
    }
  }, [localStorage.getItem('i18nextLng'), setToken])

  const getToken = async () => {
    const params = { url: 'https://cluster.mu/' }
    try {
      const result = await callApi(GET_REGISTER_TOKEN, GET, { params })
      setToken(String(result?.data?.data))
    } catch (error) {}
  }

  const list = [
    {
      name: t('control-export.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('control-export.bread_crumb_active'),
      link: ROUTER_URL.CONTROL_EXPORT,
    },
  ]

  const toggleModal = () => {
    setShowModal((showModal) => !showModal)
  }

  const onSubmit = async (values: any) => {
    if (checkError(token)) {
      const params = { url: 'https://cluster.mu/', token: token, }
      try {
        const result = await callApi(REGISTER_EXPORT_TOKEN, POST, params)
        if (result.data.data) {
          history.push(ROUTER_URL.REGIST_CLUSTER_COMPLETE)
        }
        else {
          toggleModal()
        }
      } catch (error)
      {
      }
    }
  }

  const checkError = (value: any) => {
    setToken(value)    
    if (value === '') {
      setError(i18n.t('control-export.export_error_input'))
      return false
    }
    else if (!TOKEN_PATTERN.test(value)) {
      setError(i18n.t('control-export.invalid_token'))
      return false
    } 
    else {
      setError('')
    return true
    }
  }
  const changeToken = (e: any) => {
    checkError(e.target.value)
  }

  return (
    <Wrapper
      Icon={ExportIcon}
      title={t('control-export.title_header')}
      breadcrum={list}
      className="control-export"
      isNoMarginTop={true}
    >
    <Modal
        modalStyles="booking__modal-booking padding-modal-in-h2"
        show={showModal}
        toggleModal={toggleModal}
      >
        <MessageModal
        custom_title = 'control-export.register_fail_title'
        custom_message = 'control-export.register_fail_message'
        show={showModal}
        toggleModal={toggleModal}
      >
      </MessageModal> 
    </Modal>
    <div className="export-wrap_box base_style">
      <div id="export_input" className="title_head">
        <div className="service_name">
          <div className="app_image">
            <div className="image_wrap">
              <img src={appimg_cluster}  alt="cluster" width="100" height="100" />
            </div>
          </div>
          <h2>cluster</h2>
        </div>

        <div className="input_wrap">
          <div className="token__form">
            <div className="token__field-wrapper">
              <div className="input input-label-gutter">
                <input autoComplete="off" type="text" className="input__field" placeholder={i18n.t('control-export.placeholder')} name="token"  onChange={changeToken} value={token} />
              </div>
            </div>
            <div className="btn_wrap token__send">
              <button className="button-custom" type="submit" onClick={onSubmit}>{i18n.t('control-export.regist_cluster_button')}</button>
            </div>
          </div>
          {error ? (
                <span className="form-field-error-message">{error}</span>
              ) : null}
        </div>
      </div> {/* /title_head */}

      <div className="content_wrap">
        <div id="export_input" className="section intro">
          <h3>{i18n.t('control-export.cluster_intro_title')}</h3>
          <p>{i18n.t('control-export.cluster_intro_detail1')}</p>
          <p>{i18n.t('control-export.cluster_intro_detail2')}</p>
          <p>
              <Trans
                  i18nKey="control-export.cluster_intro_detail3"
                  components={{
                      br: <br />,
                      b: <b />,
                  }}
              />
          </p>
        <div id="export_detail" className="section app_detail">
          <h3>{i18n.t('control-export.cluster_info_title')}</h3>
          <table className="base_table">
            <tbody>
              <tr>
                <th rowSpan={2}>{i18n.t('control-export.cluster_info_platform')}</th>
                <td>{i18n.t('control-export.cluster_info_platform_PC')}</td>
              </tr>
              <tr>
                <td>{i18n.t('control-export.cluster_info_platform_mobile')}</td>
              </tr>
              <tr>
                <th>{i18n.t('control-export.cluster_info_WEB_title')}</th>
                <td><a className="text-link" href="https://cluster.mu/" target="_blank" rel="noopener noreferrer">https://cluster.mu/</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="export_able" className="section app_detail">
        <h3>{i18n.t('control-export.cluster_about_AVATAR')}</h3>
          <div className="avatar_wrap2">
              <ul>
                  <li>
                      <div className="image_wrap">
                          <img src={avatarChara} alt="{i18n.t('control-export.cluster_about_chara_avatrar')}" width="100px" height="80px" />
                      </div>
                      <div className="tx_wrap">
                          <div className="title">{i18n.t('control-export.cluster_about_chara_avatrar')}</div>
                          <div className="label_wrap">
                              <span className="label scanner">{i18n.t('control-export.cluster_about_scanner')}</span>
                              <span className="label mobile">{i18n.t('control-export.cluster_about_mobile')}</span>
                          </div>
                          <p>
                              {i18n.t('control-export.cluster_about_description')}<br />
                              <a className="text-link" href={ROUTER_URL.HELP_ABOUT_AVATARIUM + "#avatar_make_about_avatar"}>{i18n.t('control-export.cluster_about_linktxt')}</a>
                          </p>
                      </div>
                  </li>
              </ul>
          </div>
          {/*}<!-- avatar_wrap -->*/}
      </div>
      <div id="export_howto" className="section howto">
        <h3>{i18n.t('control-export.cluster_way_connected')}</h3>
          <p>
              <Trans
                  i18nKey="control-export.cluster_way_connected_description"
                  components={{
                      br: <br />,
                      b: <b />,
                  }}
              />
          </p>
           <h4 className="service_title">{i18n.t('control-export.cluster_operation')}</h4>
          
          <div id="how_s1" className="section">
              <p>
                  <Trans
                      i18nKey="control-export.cluster_operation_s1"
                      components={{
                          br: <br />,
                          a: <a className="text-link" href="https://cluster.mu" target="_blank" rel="noopener noreferrer"/>,
                          b: <b />,
                      }}
                  />
              </p>
              <div className="image_wrap">
                  <img src={exportCluster1} width="800" height="319" loading="lazy"
                       alt={i18n.t('control-export.export_cluster_1.alt')} />
              </div>
          </div>
          {/*}<!--section-->*/}

          <div id="how_s2" className="section">
              <p>
                  <Trans
                      i18nKey="control-export.cluster_operation_s2"
                      components={{
                          b: <b />,
                      }}
                  />
              </p>
              <div className="image_wrap">
                  <img src={exportCluster2} width="800" height="319" loading="lazy"
                       alt={i18n.t('control-export.export_cluster_2.alt')} />
              </div>
              <div className="image_wrap">
                  <img src={exportCluster3} width="800" height="319" loading="lazy"
                       alt={i18n.t('control-export.export_cluster_3.alt')} />
              </div>
          </div>
          {/*<!--section-->*/}
          <h4 className="service_title">{i18n.t('control-export.avatarium_operation')}</h4>
          <div id="how_s3" className="section">
              <p>
                  <Trans
                      i18nKey="control-export.avatarium_operation_s3"
                      components={{
                          br: <br />,
                          b: <b />,
                      }}
                  />
              </p>
              <div className="image_wrap">
                  <img src={i18n.t('control-export.export_cluster_4.img')} width="800" height="319" loading="lazy"
                       alt={i18n.t('control-export.export_cluster_4.alt')} />
              </div>
          </div>
          {/*<!--section-->*/}

          <div id="how_s4" className="section">
              <p>
                  <Trans
                      i18nKey="control-export.avatarium_operation_s4"
                      components={{
                          br: <br />,
                          b: <b />,
                      }}
                  />
              </p>
              <div className="image_wrap">
                  <img src={i18n.t('control-export.export_cluster_5.img')} width="800" height="319" loading="lazy"
                       alt={i18n.t('control-export.export_cluster_5.alt')} />
              </div>
          </div>
          {/*<!--section-->*/}

          <div id="how_s5" className="section">
              <p>
                  <Trans
                      i18nKey="control-export.avatarium_operation_s5"
                      components={{
                          b: <b />,
                      }}
                  />
              </p>
              <div className="image_wrap">
                  <img src={i18n.t('control-export.export_cluster_6.img')} width="800" height="319" loading="lazy"
                       alt={i18n.t('control-export.export_cluster_6.alt')} />
              </div>
          </div>
          {/*<!--section-->*/}

          <div id="how_6" className="section">
              <p>
                  <Trans
                      i18nKey="control-export.avatarium_operation_s6"
                      components={{
                          b: <b />,
                      }}
                  />
              </p>
              <div className="image_wrap">
                  <img src={i18n.t('control-export.export_cluster_7.img')} width="800" height="319" loading="lazy"
                       alt={i18n.t('control-export.export_cluster_7.alt')} />
              </div>
          </div>
          {/*<!--section-->*/}

          <div id="how_s7" className="section">
              <p>
                  <Trans
                      i18nKey="control-export.avatarium_operation_s7"
                      components={{
                          br: <br />,
                          a: <a className="text-link" href="https://cluster.mu" target="_blank" rel="noopener noreferrer" />,
                          b: <b />,
                      }}
                  />
              </p>
              <div className="image_wrap">
                  <img src={i18n.t('control-export.export_cluster_8.img')} width="800" height="319" loading="lazy"
                       alt={i18n.t('control-export.export_cluster_8.alt')} />
              </div>
          </div>
          {/*<!--section-->*/}

          <h4 className="service_title">{i18n.t('control-export.cluster_operation')}</h4>
          <div id="how_s8" className="section">
              <p>
                  <Trans
                      i18nKey="control-export.avatarium_operation_s8"
                      components={{
                          br: <br />,
                          b: <b />,
                      }}
                  />
              </p>
              <div className="image_wrap">
                  <img src={exportCluster9} width="800" height="319" loading="lazy"
                       alt={i18n.t('control-export.export_cluster_9.alt')} />
              </div>
          </div>
          {/*<!--section-->*/}

      </div>{/*!--/#export_howto-->*/}
          
      </div> {/*<!-- content_wrap --> */}
      <div className="wrapper__button">
          <a href="#root" className="button-custom">{i18n.t('control-export.back_to_top')}</a>
      </div>
      </div> {/* <!-- export-wrap_box -->
      <!-- ▲エクスポート詳細--> */}
      <br />
                                     
    </Wrapper>
  )
}

export default ControlExport
