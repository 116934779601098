import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const AvatarUpIcon: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8108 8.76806C18.7518 8.66891 18.6691 8.58713 18.5705 8.5306C18.472 8.47408 18.3611 8.44471 18.2484 8.44532H15.1241V9.73627H17.8735L20.3105 14.2546H15.7489C15.6656 14.2494 15.5821 14.2626 15.504 14.2932C15.4259 14.3238 15.355 14.3711 15.296 14.4321C15.2369 14.4931 15.1911 14.5664 15.1614 14.647C15.1318 14.7277 15.1191 14.8139 15.1241 14.9001V16.8365H8.87536V14.9001C8.88037 14.8139 8.86765 14.7277 8.83802 14.647C8.80839 14.5664 8.76253 14.4931 8.70347 14.4321C8.64442 14.3711 8.57351 14.3238 8.49542 14.2932C8.41734 14.2626 8.33386 14.2494 8.25049 14.2546H3.68893L6.12593 9.73627H8.87536V8.44532H5.75101C5.63833 8.44471 5.52739 8.47408 5.42889 8.5306C5.33039 8.58713 5.24764 8.66891 5.18862 8.76806L2.06427 14.5773C2.01375 14.6764 1.99205 14.7885 2.00178 14.9001V21.3548C1.99677 21.4409 2.0095 21.5271 2.03913 21.6078C2.06876 21.6885 2.11461 21.7617 2.17367 21.8227C2.23272 21.8837 2.30364 21.9311 2.38172 21.9617C2.4598 21.9923 2.54328 22.0054 2.62665 22.0003H21.3728C21.4562 22.0054 21.5396 21.9923 21.6177 21.9617C21.6958 21.9311 21.7667 21.8837 21.8258 21.8227C21.8848 21.7617 21.9307 21.6885 21.9603 21.6078C21.9899 21.5271 22.0027 21.4409 21.9977 21.3548V14.9001C22.0074 14.7885 21.9857 14.6764 21.9352 14.5773L18.8108 8.76806Z"
        fill="#9496AB"
      />
      <path
        d="M8.87575 7.15203H11.3752V11.6703C11.3752 11.8415 11.4411 12.0057 11.5583 12.1268C11.6754 12.2478 11.8344 12.3158 12.0001 12.3158C12.1658 12.3158 12.3248 12.2478 12.442 12.1268C12.5591 12.0057 12.625 11.8415 12.625 11.6703V7.15203H15.1245C15.2433 7.1521 15.3596 7.11721 15.4599 7.05142C15.5601 6.98563 15.6402 6.89169 15.6906 6.78059C15.741 6.66949 15.7598 6.54585 15.7446 6.42415C15.7294 6.30245 15.681 6.18773 15.605 6.09345L12.4806 2.22061C12.4199 2.15116 12.3458 2.09564 12.263 2.05764C12.1803 2.01964 12.0907 2 12.0001 2C11.9095 2 11.82 2.01964 11.7372 2.05764C11.6544 2.09564 11.5803 2.15116 11.5196 2.22061L8.39522 6.09345C8.3192 6.18773 8.27077 6.30245 8.2556 6.42415C8.24044 6.54585 8.25917 6.66949 8.3096 6.78059C8.36003 6.89169 8.44007 6.98563 8.54034 7.05142C8.64062 7.11721 8.75696 7.1521 8.87575 7.15203Z"
        fill="#9496AB"
      />
    </svg>
  )
}

export default AvatarUpIcon
