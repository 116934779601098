import React from 'react'
import { useTranslation } from 'react-i18next'
import Wrapper from '../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../utils/const'

interface ActiveEmailProps {}

const ActiveEmailError: React.FC<ActiveEmailProps> = () => {

  const title = '認証期限切れ | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = '認証期限切れのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [, i18n] = useTranslation()

  const list = [
    {
      name: i18n.t('signup.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('signup.signup'),
      link: ROUTER_URL.SIGNUP,
    },
    {
      name: i18n.t('signup.login_URL_has_expired'),
      link: ROUTER_URL.ACTIVE_EMAIL_ERR,
    },
  ]

  return (
    <Wrapper
      buttonLink={ROUTER_URL.SIGNUP}
      buttonText={i18n.t('active_mail.button_err')}
      breadcrum={list}
      className="active-mail"
      typeBreadcrums={1} // distance-breadcrumb-to-h2-wide
      isNoMarginTop={false}
    >
      <div className="active-mail__container">
        <h2 className="title-h2-error distance-h2-to-text">{i18n.t('active_email_error.title')}</h2>
        <div className="active-mail__content-err ">
          <p className="active-message">
            {i18n.t('active_mail.content_err')}
            <br />
            {i18n.t('active_email_error.content')}
          </p>
        </div>
      </div>
    </Wrapper>
  )
}

export default ActiveEmailError
