import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FORGOT_PASSWORD_PATTERN } from '../../../../utils/const'

export const textFields = [
  {
    name: 'new_password',
    type: 'password',
    label: 'reset_password.label_password',
    isRequire: true,
    placeholder: 'reset_password.placeholder_password',
  },
  {
    name: 'confirm_password',
    type: 'password',
    label: 'reset_password.label_confirm',
    isRequire: true,
    placeholder: 'reset_password.placeholder_confirm',
  },
]

export const validator = (t: any) => {
  return yupResolver(
    Yup.object().shape({
      new_password: Yup.string()
        .required(t('reset_password.notification_password'))
        .matches(FORGOT_PASSWORD_PATTERN, t('reset_password.password_valid'))
        .max(32, t('reset_password.password_max')),
      confirm_password: Yup.string()
        .required(t('reset_password.notification_confirm'))
        .oneOf([Yup.ref('new_password'), ''], t('reset_password.notification_match')),
    })
  )
}

export const defaultValues = {
  new_password: '',
  confirm_password: '',
}
