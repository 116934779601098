import { useTranslation } from 'react-i18next'
import QuestionIcon from "../../../../assets/images/svgs/question-icon";
import Wrapper from '../../components/modules/_wrapper'
import { ROUTER_URL, APP_STORE_URL, GOOGLE_PLAY_URL } from '../../../../utils/const'
import {useHistory} from "react-router-dom";
import {
    helpTimelimit, imageClothes, imageGlasses, imageHair, imageMask,
    mail1,
    nimotsu,
    posture, posture2,
    posture4, posture5,
    scanBooking,
    uketsuke,
    uketsukeAttention,
    uketsukehukusou,
    uketsukeKanryou,
    uketsukePin
} from '../../../../assets/images'

interface HelpFlowScannerProps {}

const HelpFlowScanner: React.FC<HelpFlowScannerProps> = () => {

    const title = '「スキャナ撮影」でのアバター作成の流れ | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
    const description = '「スキャナ撮影」でのアバター作成の流れについてのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
    const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
    const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

    // title
    document.title = title;

    // title以外のmeta
    const headData = document.head.children;

    for (let i = 0; i < headData.length; i++) {
        const nameVal = headData[i].getAttribute('name');
        const propertyVal = headData[i].getAttribute('property');
        if (nameVal !== null) {
            if (nameVal.indexOf('keywords') !== -1) {
                headData[i].setAttribute('content', keywords);
            }
            if (nameVal.indexOf('description') !== -1) {
                headData[i].setAttribute('content', description);
            }
        }
        if (propertyVal !== null) {
            // OGP(og:title)の設定
            if (propertyVal.indexOf('og:title') !== -1) {
                headData[i].setAttribute('content', title);
            }
            if (propertyVal.indexOf('og:description') !== -1) {
                headData[i].setAttribute('content', description);
            }
            if (propertyVal.indexOf('og:image') !== -1) {
              headData[i].setAttribute('content', og_image);
            }
        }
    }
    
    const [t] = useTranslation('translation')

    const history = useHistory()

    const onGoTo = (path: any) => () => {
        history.push(path)
    }
    const list = [
        {
            name: t('help.home'),
            link: ROUTER_URL.HOME,
        },
        {
            name: t('help.flow-scanner.parent'),
            link: ROUTER_URL.HELP_INDEX,
        },
        {
            name: t('help.flow-scanner.breadcrum'),
        },
    ]

    return (
        <Wrapper
            breadcrum={list}
            title={t('help.flow-scanner.title')}
            className="base_style help-wrap"
            Icon={QuestionIcon}
            classNameBtn={'normal'}
        >

            <div className="wrapper__content distance-line-to-content">
                <div className="help-wrap_box distance-content-to-button">

                    <p>
                        AVATARIUMスキャナ撮影、またはモバイルアプリを使用したモバイル撮影の2つからアバターの作成が可能です。<br/>
                        各アバター作成方法の詳細については下記をご確認ください。
                    </p>

                    <ul className="tab1">
                        <li className="current"><a>スキャナ撮影</a></li>
                        <li><a onClick={onGoTo(ROUTER_URL.HELP_FLOW_SCANNER_MOBILE)}>モバイル撮影</a></li>
                    </ul>

                    <div className="title_area">
                        <h2 className="title_h2">「スキャナ撮影」での<br className="spb"/>アバター作成の流れ</h2>
                        <p>
                            スキャナで全身撮影をすることで、<br className="spb"/>撮影時の自分をそのまま<br className="spb"/>アバター化することができます。<br/>
                            アバター作成方法の流れは下記をご確認ください。
                        </p>
                    </div>
                    <ul className="toc">
                        <li>
                            <a href="#s1">1. 撮影予約をする</a>
                            <ul>
                                <li><a href="#s1-1">①WEBサイトから撮影予約をする</a></li>
                                <li><a href="#s1-2">②PINコードを確認する</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#s2">2. 撮影受付をする</a>
                            <ul>
                                <li><a href="#s2-1">①撮影受付をはじめる</a></li>
                                <li><a href="#s2-2">②PINコードの入力</a></li>
                                <li><a href="#s2-3">③筐体内に移動し撮影をはじめる</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#s3">3. 撮影をはじめる</a>
                            <ul>
                                <li><a href="#s3-1">①撮影の流れについて </a></li>
                                <li><a href="#s3-2">②撮影の準備について（荷物について）</a></li>
                                <li><a href="#s3-3">③撮影の準備について（撮影時の姿勢）</a></li>
                                <li><a href="#s3-4">④撮影について</a></li>
                                <li><a href="#s3-5">⑤プレビューモデルの選択について</a></li>
                            </ul>
                        </li>
                    </ul>

                    <div id="s1" className="section">
                        <h2>1. 撮影予約をする</h2>

                        <div id="s1-1" className="section">
                            <h3>①WEBサイトから撮影予約をする</h3>
                            <p>
                                AVATARIUMスキャナでの撮影は完全予約制です。<br/>
                                WEBサイトに<a className="text-link" onClick={onGoTo(ROUTER_URL.SIGNIN)}>ログイン</a>し、<a
                                className="text-link" onClick={onGoTo(ROUTER_URL.SCAN_BOOKING)}>予約フォーム</a>から<a
                                className="text-link" onClick={onGoTo(ROUTER_URL.HELP_BOOKING_ATTENTION)}>撮影時の注意事項</a>をご確認のうえ、撮影予約を行ってください。
                            </p>

                            <div className="image_wrap image_wrap_m">
                                <img src={scanBooking} alt="撮影予約ページ" width="1000" height="560"
                                     loading="lazy"/>
                            </div>

                            <p>
                                撮影は撮影予約時に指定したスキャナ設置場所で行います。撮影場所のお間違えがないようご注意ください。
                            </p>

                            <ul className="p">
                                <li>※撮影予約はWEBサイトからのみ可能です。</li>
                                <li>※撮影予約は<span className="red">1アカウント1枠の予約まで</span>となります。複数の予約はできませんのでご了承ください。撮影予約のキャンセル、または予約の期限切れ（自動キャンセル）となった場合は、撮影予約を再度取り直すことが可能です。
                                </li>
                                <li>※スキャナ撮影では、服装や髪型に制限があります。撮影に適さない服装や髪型で撮影をした場合、アバターが綺麗に生成されない可能性がありますので、<span
                                    className="red">事前に必ず撮影時の注意事項をよくお読みになってから撮影にお越し下さい。</span></li>
                            </ul>

                            <p>
                                <a className="text-link" onClick={onGoTo(ROUTER_URL.HELP_BOOKING_ATTENTION)}>【撮影の注意事項（必読）はこちら】</a>
                            </p>
                        </div>

                        <div id="s1-2" className="section">
                            <h3>②PINコードを確認する</h3>
                            <p>
                                正常に撮影予約が完了すると、会員登録時に登録されたメールアドレスに「予約完了」通知メールが届きます。<br/>
                                メール内に８桁の予約PINコードが記載されていますので、必ずご確認ください。
                            </p>

                            <div className="image_wrap image_wrap_m">
                                <img src={mail1} alt="「予約完了」通知メール" width="1000" height="560"
                                     loading="lazy"/>
                            </div>

                            <p>
                                予約PINコードは撮影受付時に必要となります。<br/>
                                当日ご確認ができるようにお手元にメモをするなどの準備をお願いいたします。
                            </p>
                        </div>
                    </div>

                    <div id="s2" className="section">
                        <h2>2.撮影受付をする</h2>
                        <p>
                            撮影当日、<span className="red">撮影予約の5分前〜撮影予約時間までに撮影受付を完了してください</span>。<br/>
                            それよりも早い時間での受付はできません。受付時間を過ぎても受付の確認がとれない場合、撮影は自動キャンセルとなりますのでご注意ください。<br/><br/>

                            受付は撮影予約時に指定したスキャナ設置場所で行います。<br/>
                            指定以外の場所で撮影受付は行えませんので、場所をお間違えのないようご注意ください。
                        </p>

                        <div id="s2-1" className="section">
                            <h3>①撮影受付をはじめる</h3>
                            <p>
                                撮影受付はスキャナ外の操作パネルから行います。<br/>
                                操作パネルは上下の2画面あります。操作は下画面から行ってください。
                            </p>

                            <div className="image_wrap image_wrap_m">
                                <img src={uketsuke} alt="撮影の受付をはじめる" width="1000" height="560"
                                     loading="lazy"/>
                            </div>

                            <p>
                                「撮影の受付をはじめる」ボタンから受付をはじめます。
                            </p>
                        </div>

                        <div id="s2-2" className="section">
                            <h3>②PINコードの入力</h3>
                            <p>
                                撮影注意事項を確認し、「次へ」ボタンからPINコード入力画面に進みます。<br/>
                                撮影予約時にお送りしている「予約完了」通知メールに記載している予約PINコードを入力してください。
                            </p>

                            <div className="image_wrap image_wrap_arrow image_wrap_arrow2">
                                <div className="wrap">
                                    <img src={uketsukeAttention} alt="注意事項" width="1000" height="560"
                                         loading="lazy"/>
                                </div>
                                <div className="arrow_wrap"><i className="fas fa-angle-double-right"></i></div>
                                <div className="wrap">
                                    <img src={uketsukePin} alt="PIN
                                                    コードを入力" width="1000" height="560" loading="lazy"/>
                                </div>
                            </div>
                        </div>

                        <div id="s2-3" className="section">
                            <h3>③筐体内に移動し撮影をはじめる</h3>
                            <p>
                                PINコード認証が確認された場合、女性の方は撮影時の洋服を選択してください。（男性の場合は選択画面は表示されません）<br/>
                                これで撮影受付は終了です。
                            </p>

                            <div className="image_wrap image_wrap_arrow image_wrap_arrow2">
                                <div className="wrap">
                                    <img src={uketsukehukusou} alt="女性は服装を選択" width="1000" height="560" loading="lazy"/>
                                </div>
                                <div className="arrow_wrap"><i className="fas fa-angle-double-right"></i></div>
                                <div className="wrap">
                                    <img src={uketsukeKanryou} alt="撮影準備完了" width="1000" height="560" loading="lazy"/>
                                </div>
                            </div>

                            <p>
                                「撮影の準備が整いました。スキャナ内に移動してください。」と画面に表示されたらスキャナ内に移動し、スキャナ内の操作パネルから撮影を始めてください。<br/>
                                ※ 撮影の受付完了後、2分たってもスキャナ内の操作パネルでの操作が始まらない場合は撮影が自動キャンセルとなりますのでご注意ください。
                            </p>
                        </div>
                    </div>

                    <div id="s3" className="section">
                        <h2>3.撮影をはじめる</h2>

                        <div id="s3-1" className="section">
                            <h3>撮影の流れについて</h3>
                            <p>
                                撮影は3ステップで行います。
                            </p>

                            <div id="s3-1-1" className="section">
                                <h4>【step.1】撮影の準備</h4>
                                <p>
                                    撮影時の身支度や姿勢をチェックします。<br/>
                                    撮影時の服装や髪型の注意事項の詳細は事前にお送りしている撮影注意事項をご確認ください。
                                </p>
                            </div>

                            <div id="s3-1-2" className="section">
                                <h4>【step.2】全身撮影をする</h4>
                                <p>
                                    3回、全身の撮影を行います。撮影はセルフ撮影となります。
                                </p>
                            </div>

                            <div id="s3-1-3" className="section">
                                <h4>【step.3】プレビューモデルを選択</h4>
                                <p>
                                    アバター化するプレビューモデルを選択します。<br/>
                                    選択されたプレビューモデルからアバターの作成をします。
                                </p>
                                <div className="image_wrap image_wrap_m">
                                    <img src={helpTimelimit} alt="制限時間にご注意ください" width="1000" height="560" loading="lazy"/>
                                </div>
                                <p>
                                    スキャナ内の操作パネルは上下の2画面あります。操作は下画面から行ってください。<br/>
                                    また、各操作には時間制限があります。画面左上の残り時間が「０」となった場合は自動で画面が遷移されますのでご注意ください。
                                </p>

                            </div>
                        </div>

                        <div id="s3-2" className="section">
                            <h3>撮影の準備について（荷物について）</h3>
                            <p>
                                お手持ちの荷物、または画像に記載のアイテムはスキャナ内の荷物置き場（縦30cm,横40cm,奥行き20cm）に収納していただきます。<br/>
                                当日は、荷物置き場に収納可能なお荷物でお越しください。
                            </p>

                            <div className="image_wrap image_wrap_l">
                                <img src={nimotsu} alt="撮影の準備について（荷物について）" width="1110" height="387" loading="lazy"/>
                            </div>
                        </div>

                        <div id="s3-3" className="section">
                            <h3>撮影の準備について（撮影時の姿勢）</h3>
                            <p>
                                綺麗にアバターを作るために、指定の姿勢で撮影をしていただきます。
                            </p>

                            <div id="s3-3-1" className="section">
                                <h4>姿勢の作り方</h4>

                                <div className="image_wrap image_wrap_m bg_color2">
                                    <img src={posture} alt="姿勢の作り方" width="1000" height="622" loading="lazy"/>
                                </div>

                                <p>
                                    ①撮影ブースの中央に気をつけの姿勢で立ってください。<br/>
                                    ②<span className="red">足を床の足形マークに合わせて開き</span>、背筋を伸ばします。<br/>
                                    ③<span className="red">手を軽く握り</span>、腕を真っ直ぐ伸ばします。そのまま脇を少し開いてください。
                                </p>

                                <div className="attention_wrap">
                                    <h4><i className="fas fa-exclamation-circle"></i>注意事項</h4>
                                    <div className="cont_wrap">
                                        <div className="wrap">
                                            <div className="title-h5">顔の向き</div>
                                            <div className="image_wrap">
                                                <img src={posture4} alt="顔の向き" width="1000" height="622" loading="lazy"/>
                                            </div>
                                            <p>
                                                アゴを後ろに少し引き、<br className="spb"/>おでこを正面に向けてください。<br/>
                                                <span className="red">目線は自然に前を見るように</span>してください。
                                            </p>
                                        </div>

                                        <div className="wrap">
                                            <div className="title-h5">姿勢</div>
                                            <div className="image_wrap">
                                                <img src={posture5} alt="姿勢" width="1000" height="622" loading="lazy"/>
                                            </div>
                                            <p>
                                                体は<span className="red">前後に反らないように<br className="spb"/>リラックス</span>して<br
                                                className="pcb"/>
                                                真っ直ぐ立ってください。
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="s3-4" className="section">
                                <h3>撮影について</h3>

                                <div id="s3-4-1" className="section">
                                    <h4>撮影方法</h4>
                                    <ul className="p">
                                        <li>
                                            ・撮影は3回行います。1回の撮影は30秒以内となります。<br/> 30秒を超えた場合は自動でシャッターは切られますのでご注意ください。
                                        </li>
                                        <li>
                                            ・撮影時は正しい姿勢をとる為のガイド線が表示されます。
                                        </li>
                                    </ul>

                                    <div className="image_wrap image_wrap_m bg_color2">
                                        <img src={posture2} alt="姿勢の作り方" width="1000" height="622" loading="lazy"/>
                                    </div>

                                    <p>
                                        ＞ガイド線が緑 → 正しい姿勢<br/>
                                        ＞ガイド線が赤 → 正しくない姿勢<br/><br/>

                                        ガイド線が全て緑色になり、そのまま姿勢をキープすると撮影カウントが開始されます。<br/>
                                        3カウントでシャッターは自動的に切られます。
                                    </p>
                                </div>

                                <div id="s3-4-2" className="section">
                                    <h4>撮影時の注意事項</h4>
                                    <p>
                                        下記内容を必ず守り、撮影を行ってください。
                                    </p>

                                    <div className="flex_wrap">
                                        <div className="flex_box">
                                            <img src={imageGlasses} alt="メガネは外してメガネ置きにしまう" width="160" height="160" loading="lazy"/>
                                            <p>メガネは外して<br/>メガネ置きにしまう</p>
                                        </div>

                                        <div className="flex_box">
                                            <img src={imageMask} alt="撮影時はマスクは外す" width="160"
                                                 height="160" loading="lazy"/>
                                            <p>撮影時は<br/>マスクは外す</p>
                                        </div>

                                        <div className="flex_box">
                                            <img src={imageClothes} alt="メガネは外してメガネ置きにしまう" width="160"
                                                 height="160" loading="lazy"/>
                                            <p>シャツ等の襟元は<br/>第一ボタンまで閉める</p>
                                        </div>

                                        <div className="flex_box">
                                            <img src={imageHair} alt="前髪は眉毛にかからないようにする" width="160"
                                                 height="160" loading="lazy"/>
                                            <p>前髪は眉毛に<br/>かからないようにする</p>
                                        </div>
                                    </div>

                                    <ul className="p">
                                        <li>・メガネは外してスキャナ内のメガネ置きにしまいましょう。</li>
                                        <li>・撮影時はマスクは必ず外してください。</li>
                                        <li>・シャツ等で襟が首元までかかる場合、襟元は第一ボタンまで閉めてください。</li>
                                        <li>・前髪は眉毛にかからないようにしましょう。</li>
                                    </ul>
                                </div>
                            </div>

                            <div id="s3-5" className="section">
                                <h4>プレビューモデルの選択について</h4>
                                <p>
                                    3回の撮影で3つのプレビューモデルが表示されます。<br/><br/>

                                    上画面 : 撮影時の顔写真<br/>
                                    下画面 : 全身のプレビューモデル<br/><br/>

                                    3つのプレビューモデルからアバターにしたいモデルを1体選択してください。<br/>
                                    選択したモデルからアバターを作成します。<br/>
                                    通常、アバターの作成は10分程度で完成します。
                                </p>
                            </div>

                            <div id="s3-6" className="section">
                                <h4>アバター完成！</h4>
                                <p>
                                    アバターが完成後、会員登録時に登録したメールアドレスに「アバター完成」の通知メールが届きます。<br/>
                                    完成したアバターはAVATARIUMモバイルアプリ、またはWEBサイトの<a className="text-link" onClick={onGoTo(ROUTER_URL.CONTROL_AVATAR)}>アバター管理画面</a>からご確認いただけます。
                                </p>

                                <h5>モバイルアプリのダウンロードはこちら</h5>
                                <div className="dl_btns">
                                    <a href={APP_STORE_URL} target="_blank" rel="noopener noreferrer">
                                        <svg version="1.1" id="US_UK_Download_on_the" xmlns="http://www.w3.org/2000/svg"
                                             x="0px" y="0px" width="13.5rem"
                                             height="4rem" viewBox="0 0 135 40" enableBackground="new 0 0 135 40"
                                        >
                                            <g>
                                                <path fill="#A6A6A6" d="M130.197,40H4.729C2.122,40,0,37.872,0,35.267V4.726C0,2.12,2.122,0,4.729,0h125.468
                                                 C132.803,0,135,2.12,135,4.726v30.541C135,37.872,132.803,40,130.197,40L130.197,40z"></path>
                                                <path d="M134.032,35.268c0,2.116-1.714,3.83-3.834,3.83H4.729c-2.119,0-3.839-1.714-3.839-3.83V4.725
                                                 c0-2.115,1.72-3.835,3.839-3.835h125.468c2.121,0,3.834,1.72,3.834,3.835L134.032,35.268L134.032,35.268z"></path>
                                                <g>
                                                    <g>
                                                        <path fill="#FFFFFF" d="M30.128,19.784c-0.029-3.223,2.639-4.791,2.761-4.864c-1.511-2.203-3.853-2.504-4.676-2.528
                                                 c-1.967-0.207-3.875,1.177-4.877,1.177c-1.022,0-2.565-1.157-4.228-1.123c-2.14,0.033-4.142,1.272-5.24,3.196
                                                 c-2.266,3.923-0.576,9.688,1.595,12.859c1.086,1.553,2.355,3.287,4.016,3.226c1.625-0.067,2.232-1.036,4.193-1.036
                                                 c1.943,0,2.513,1.036,4.207,0.997c1.744-0.028,2.842-1.56,3.89-3.127c1.255-1.78,1.759-3.533,1.779-3.623
                                                 C33.507,24.924,30.161,23.647,30.128,19.784z"></path>
                                                        <path fill="#FFFFFF" d="M26.928,10.306c0.874-1.093,1.472-2.58,1.306-4.089c-1.265,0.056-2.847,0.875-3.758,1.944
                                                 c-0.806,0.942-1.526,2.486-1.34,3.938C24.557,12.205,26.016,11.382,26.928,10.306z"></path>
                                                    </g>
                                                </g>
                                                <g>
                                                    <path fill="#FFFFFF" d="M53.645,31.504h-2.271l-1.244-3.909h-4.324l-1.185,3.909h-2.211l4.284-13.308h2.646L53.645,31.504z
                                                 M49.755,25.955L48.63,22.48c-0.119-0.355-0.342-1.191-0.671-2.507h-0.04c-0.131,0.566-0.342,1.402-0.632,2.507l-1.105,3.475
                                                 H49.755z"></path>
                                                    <path fill="#FFFFFF" d="M64.662,26.588c0,1.632-0.441,2.922-1.323,3.869c-0.79,0.843-1.771,1.264-2.942,1.264
                                                 c-1.264,0-2.172-0.454-2.725-1.362h-0.04v5.055h-2.132V25.067c0-1.026-0.027-2.079-0.079-3.159h1.875l0.119,1.521h0.04
                                                 c0.711-1.146,1.79-1.718,3.238-1.718c1.132,0,2.077,0.447,2.833,1.342C64.284,23.949,64.662,25.127,64.662,26.588z M62.49,26.666
                                                 c0-0.934-0.21-1.704-0.632-2.31c-0.461-0.632-1.08-0.948-1.856-0.948c-0.526,0-1.004,0.176-1.431,0.523
                                                 c-0.428,0.35-0.708,0.807-0.839,1.373c-0.066,0.264-0.099,0.48-0.099,0.65v1.6c0,0.698,0.214,1.287,0.642,1.768
                                                 s0.984,0.721,1.668,0.721c0.803,0,1.428-0.31,1.875-0.928C62.266,28.496,62.49,27.68,62.49,26.666z"></path>
                                                    <path fill="#FFFFFF" d="M75.699,26.588c0,1.632-0.441,2.922-1.324,3.869c-0.789,0.843-1.77,1.264-2.941,1.264
                                                 c-1.264,0-2.172-0.454-2.724-1.362H68.67v5.055h-2.132V25.067c0-1.026-0.027-2.079-0.079-3.159h1.875l0.119,1.521h0.04
                                                 c0.71-1.146,1.789-1.718,3.238-1.718c1.131,0,2.076,0.447,2.834,1.342C75.32,23.949,75.699,25.127,75.699,26.588z M73.527,26.666
                                                 c0-0.934-0.211-1.704-0.633-2.31c-0.461-0.632-1.078-0.948-1.855-0.948c-0.527,0-1.004,0.176-1.432,0.523
                                                 c-0.428,0.35-0.707,0.807-0.838,1.373c-0.065,0.264-0.099,0.48-0.099,0.65v1.6c0,0.698,0.214,1.287,0.64,1.768
                                                 c0.428,0.48,0.984,0.721,1.67,0.721c0.803,0,1.428-0.31,1.875-0.928C73.303,28.496,73.527,27.68,73.527,26.666z"></path>
                                                    <path fill="#FFFFFF" d="M88.039,27.772c0,1.132-0.393,2.053-1.182,2.764c-0.867,0.777-2.074,1.165-3.625,1.165
                                                 c-1.432,0-2.58-0.276-3.449-0.829l0.494-1.777c0.936,0.566,1.963,0.85,3.082,0.85c0.803,0,1.428-0.182,1.877-0.544
                                                 c0.447-0.362,0.67-0.848,0.67-1.454c0-0.54-0.184-0.995-0.553-1.364c-0.367-0.369-0.98-0.712-1.836-1.029
                                                 c-2.33-0.869-3.494-2.142-3.494-3.816c0-1.094,0.408-1.991,1.225-2.689c0.814-0.699,1.9-1.048,3.258-1.048
                                                 c1.211,0,2.217,0.211,3.02,0.632l-0.533,1.738c-0.75-0.408-1.598-0.612-2.547-0.612c-0.75,0-1.336,0.185-1.756,0.553
                                                 c-0.355,0.329-0.533,0.73-0.533,1.205c0,0.526,0.203,0.961,0.611,1.303c0.355,0.316,1,0.658,1.936,1.027
                                                 c1.145,0.461,1.986,1,2.527,1.618C87.77,26.081,88.039,26.852,88.039,27.772z"></path>
                                                    <path fill="#FFFFFF" d="M95.088,23.508h-2.35v4.659c0,1.185,0.414,1.777,1.244,1.777c0.381,0,0.697-0.033,0.947-0.099l0.059,1.619
                                                 c-0.42,0.157-0.973,0.236-1.658,0.236c-0.842,0-1.5-0.257-1.975-0.77c-0.473-0.514-0.711-1.376-0.711-2.587v-4.837h-1.4v-1.6h1.4
                                                 v-1.757l2.094-0.632v2.389h2.35V23.508z"></path>
                                                    <path fill="#FFFFFF" d="M105.691,26.627c0,1.475-0.422,2.686-1.264,3.633c-0.883,0.975-2.055,1.461-3.516,1.461
                                                 c-1.408,0-2.529-0.467-3.365-1.401s-1.254-2.113-1.254-3.534c0-1.487,0.43-2.705,1.293-3.652c0.861-0.948,2.023-1.422,3.484-1.422
                                                 c1.408,0,2.541,0.467,3.396,1.402C105.283,24.021,105.691,25.192,105.691,26.627z M103.479,26.696
                                                 c0-0.885-0.189-1.644-0.572-2.277c-0.447-0.766-1.086-1.148-1.914-1.148c-0.857,0-1.508,0.383-1.955,1.148
                                                 c-0.383,0.634-0.572,1.405-0.572,2.317c0,0.885,0.189,1.644,0.572,2.276c0.461,0.766,1.105,1.148,1.936,1.148
                                                 c0.814,0,1.453-0.39,1.914-1.168C103.281,28.347,103.479,27.58,103.479,26.696z"></path>
                                                    <path fill="#FFFFFF" d="M112.621,23.783c-0.211-0.039-0.436-0.059-0.672-0.059c-0.75,0-1.33,0.283-1.738,0.85
                                                 c-0.355,0.5-0.533,1.132-0.533,1.895v5.035h-2.131l0.02-6.574c0-1.106-0.027-2.113-0.08-3.021h1.857l0.078,1.836h0.059
                                                 c0.225-0.631,0.58-1.139,1.066-1.52c0.475-0.343,0.988-0.514,1.541-0.514c0.197,0,0.375,0.014,0.533,0.039V23.783z"></path>
                                                    <path fill="#FFFFFF" d="M122.156,26.252c0,0.382-0.025,0.704-0.078,0.967h-6.396c0.025,0.948,0.334,1.673,0.928,2.173
                                                 c0.539,0.447,1.236,0.671,2.092,0.671c0.947,0,1.811-0.151,2.588-0.454l0.334,1.48c-0.908,0.396-1.98,0.593-3.217,0.593
                                                 c-1.488,0-2.656-0.438-3.506-1.313c-0.848-0.875-1.273-2.05-1.273-3.524c0-1.447,0.395-2.652,1.186-3.613
                                                 c0.828-1.026,1.947-1.539,3.355-1.539c1.383,0,2.43,0.513,3.141,1.539C121.873,24.047,122.156,25.055,122.156,26.252z
                                                 M120.123,25.699c0.014-0.632-0.125-1.178-0.414-1.639c-0.369-0.593-0.936-0.889-1.699-0.889c-0.697,0-1.264,0.289-1.697,0.869
                                                 c-0.355,0.461-0.566,1.014-0.631,1.658H120.123z"></path>
                                                </g>
                                                <g>
                                                    <g>
                                                        <path fill="#FFFFFF" d="M49.05,10.009c0,1.177-0.353,2.063-1.058,2.658c-0.653,0.549-1.581,0.824-2.783,0.824
                                                 c-0.596,0-1.106-0.026-1.533-0.078V6.982c0.557-0.09,1.157-0.136,1.805-0.136c1.145,0,2.008,0.249,2.59,0.747
                                                 C48.723,8.156,49.05,8.961,49.05,10.009z M47.945,10.038c0-0.763-0.202-1.348-0.606-1.756c-0.404-0.407-0.994-0.611-1.771-0.611
                                                 c-0.33,0-0.611,0.022-0.844,0.068v4.889c0.129,0.02,0.365,0.029,0.708,0.029c0.802,0,1.421-0.223,1.857-0.669
                                                 S47.945,10.892,47.945,10.038z"></path>
                                                        <path fill="#FFFFFF" d="M54.909,11.037c0,0.725-0.207,1.319-0.621,1.785c-0.434,0.479-1.009,0.718-1.727,0.718
                                                 c-0.692,0-1.243-0.229-1.654-0.689c-0.41-0.459-0.615-1.038-0.615-1.736c0-0.73,0.211-1.329,0.635-1.794s0.994-0.698,1.712-0.698
                                                 c0.692,0,1.248,0.229,1.669,0.688C54.708,9.757,54.909,10.333,54.909,11.037z M53.822,11.071c0-0.435-0.094-0.808-0.281-1.119
                                                 c-0.22-0.376-0.533-0.564-0.94-0.564c-0.421,0-0.741,0.188-0.961,0.564c-0.188,0.311-0.281,0.69-0.281,1.138
                                                 c0,0.435,0.094,0.808,0.281,1.119c0.227,0.376,0.543,0.564,0.951,0.564c0.4,0,0.714-0.191,0.94-0.574
                                                 C53.725,11.882,53.822,11.506,53.822,11.071z"></path>
                                                        <path fill="#FFFFFF" d="M62.765,8.719l-1.475,4.714h-0.96l-0.611-2.047c-0.155-0.511-0.281-1.019-0.379-1.523h-0.019
                                                 c-0.091,0.518-0.217,1.025-0.379,1.523l-0.649,2.047h-0.971l-1.387-4.714h1.077l0.533,2.241c0.129,0.53,0.235,1.035,0.32,1.513
                                                 h0.019c0.078-0.394,0.207-0.896,0.389-1.503l0.669-2.25h0.854l0.641,2.202c0.155,0.537,0.281,1.054,0.378,1.552h0.029
                                                 c0.071-0.485,0.178-1.002,0.32-1.552l0.572-2.202H62.765z"></path>
                                                        <path fill="#FFFFFF" d="M68.198,13.433H67.15v-2.7c0-0.832-0.316-1.248-0.95-1.248c-0.311,0-0.562,0.114-0.757,0.343
                                                 c-0.193,0.229-0.291,0.499-0.291,0.808v2.796h-1.048v-3.366c0-0.414-0.013-0.863-0.038-1.349h0.921l0.049,0.737h0.029
                                                 c0.122-0.229,0.304-0.418,0.543-0.569c0.284-0.176,0.602-0.265,0.95-0.265c0.44,0,0.806,0.142,1.097,0.427
                                                 c0.362,0.349,0.543,0.87,0.543,1.562V13.433z"></path>
                                                        <path fill="#FFFFFF"
                                                              d="M71.088,13.433h-1.047V6.556h1.047V13.433z"></path>
                                                        <path fill="#FFFFFF" d="M77.258,11.037c0,0.725-0.207,1.319-0.621,1.785c-0.434,0.479-1.01,0.718-1.727,0.718
                                                 c-0.693,0-1.244-0.229-1.654-0.689c-0.41-0.459-0.615-1.038-0.615-1.736c0-0.73,0.211-1.329,0.635-1.794s0.994-0.698,1.711-0.698
                                                 c0.693,0,1.248,0.229,1.67,0.688C77.057,9.757,77.258,10.333,77.258,11.037z M76.17,11.071c0-0.435-0.094-0.808-0.281-1.119
                                                 c-0.219-0.376-0.533-0.564-0.939-0.564c-0.422,0-0.742,0.188-0.961,0.564c-0.188,0.311-0.281,0.69-0.281,1.138
                                                 c0,0.435,0.094,0.808,0.281,1.119c0.227,0.376,0.543,0.564,0.951,0.564c0.4,0,0.713-0.191,0.939-0.574
                                                 C76.074,11.882,76.17,11.506,76.17,11.071z"></path>
                                                        <path fill="#FFFFFF" d="M82.33,13.433h-0.941l-0.078-0.543h-0.029c-0.322,0.433-0.781,0.65-1.377,0.65
                                                 c-0.445,0-0.805-0.143-1.076-0.427c-0.246-0.258-0.369-0.579-0.369-0.96c0-0.576,0.24-1.015,0.723-1.319
                                                 c0.482-0.304,1.16-0.453,2.033-0.446V10.3c0-0.621-0.326-0.931-0.979-0.931c-0.465,0-0.875,0.117-1.229,0.349l-0.213-0.688
                                                 c0.438-0.271,0.979-0.407,1.617-0.407c1.232,0,1.85,0.65,1.85,1.95v1.736C82.262,12.78,82.285,13.155,82.33,13.433z
                                                 M81.242,11.813v-0.727c-1.156-0.02-1.734,0.297-1.734,0.95c0,0.246,0.066,0.43,0.201,0.553c0.135,0.123,0.307,0.184,0.512,0.184
                                                 c0.23,0,0.445-0.073,0.641-0.218c0.197-0.146,0.318-0.331,0.363-0.558C81.236,11.946,81.242,11.884,81.242,11.813z"></path>
                                                        <path fill="#FFFFFF" d="M88.285,13.433h-0.93l-0.049-0.757h-0.029c-0.297,0.576-0.803,0.864-1.514,0.864
                                                 c-0.568,0-1.041-0.223-1.416-0.669s-0.562-1.025-0.562-1.736c0-0.763,0.203-1.381,0.611-1.853c0.395-0.44,0.879-0.66,1.455-0.66
                                                 c0.633,0,1.076,0.213,1.328,0.64h0.02V6.556h1.049v5.607C88.248,12.622,88.26,13.045,88.285,13.433z M87.199,11.445v-0.786
                                                 c0-0.136-0.01-0.246-0.029-0.33c-0.059-0.252-0.186-0.464-0.379-0.635c-0.195-0.171-0.43-0.257-0.701-0.257
                                                 c-0.391,0-0.697,0.155-0.922,0.466c-0.223,0.311-0.336,0.708-0.336,1.193c0,0.466,0.107,0.844,0.322,1.135
                                                 c0.227,0.31,0.533,0.465,0.916,0.465c0.344,0,0.619-0.129,0.828-0.388C87.1,12.069,87.199,11.781,87.199,11.445z"></path>
                                                        <path fill="#FFFFFF" d="M97.248,11.037c0,0.725-0.207,1.319-0.621,1.785c-0.434,0.479-1.008,0.718-1.727,0.718
                                                 c-0.691,0-1.242-0.229-1.654-0.689c-0.41-0.459-0.615-1.038-0.615-1.736c0-0.73,0.211-1.329,0.635-1.794s0.994-0.698,1.713-0.698
                                                 c0.691,0,1.248,0.229,1.668,0.688C97.047,9.757,97.248,10.333,97.248,11.037z M96.162,11.071c0-0.435-0.094-0.808-0.281-1.119
                                                 c-0.221-0.376-0.533-0.564-0.941-0.564c-0.42,0-0.74,0.188-0.961,0.564c-0.188,0.311-0.281,0.69-0.281,1.138
                                                 c0,0.435,0.094,0.808,0.281,1.119c0.227,0.376,0.543,0.564,0.951,0.564c0.4,0,0.715-0.191,0.941-0.574
                                                 C96.064,11.882,96.162,11.506,96.162,11.071z"></path>
                                                        <path fill="#FFFFFF" d="M102.883,13.433h-1.047v-2.7c0-0.832-0.316-1.248-0.951-1.248c-0.311,0-0.562,0.114-0.756,0.343
                                                 s-0.291,0.499-0.291,0.808v2.796h-1.049v-3.366c0-0.414-0.012-0.863-0.037-1.349h0.92l0.049,0.737h0.029
                                                 c0.123-0.229,0.305-0.418,0.543-0.569c0.285-0.176,0.602-0.265,0.951-0.265c0.439,0,0.805,0.142,1.096,0.427
                                                 c0.363,0.349,0.543,0.87,0.543,1.562V13.433z"></path>
                                                        <path fill="#FFFFFF" d="M109.936,9.504h-1.154v2.29c0,0.582,0.205,0.873,0.611,0.873c0.188,0,0.344-0.016,0.467-0.049
                                                 l0.027,0.795c-0.207,0.078-0.479,0.117-0.814,0.117c-0.414,0-0.736-0.126-0.969-0.378c-0.234-0.252-0.35-0.676-0.35-1.271V9.504
                                                 h-0.689V8.719h0.689V7.855l1.027-0.31v1.173h1.154V9.504z"></path>
                                                        <path fill="#FFFFFF" d="M115.484,13.433h-1.049v-2.68c0-0.845-0.316-1.268-0.949-1.268c-0.486,0-0.818,0.245-1,0.735
                                                 c-0.031,0.103-0.049,0.229-0.049,0.377v2.835h-1.047V6.556h1.047v2.841h0.02c0.33-0.517,0.803-0.775,1.416-0.775
                                                 c0.434,0,0.793,0.142,1.078,0.427c0.355,0.355,0.533,0.883,0.533,1.581V13.433z"></path>
                                                        <path fill="#FFFFFF" d="M121.207,10.853c0,0.188-0.014,0.346-0.039,0.475h-3.143c0.014,0.466,0.164,0.821,0.455,1.067
                                                 c0.266,0.22,0.609,0.33,1.029,0.33c0.465,0,0.889-0.074,1.271-0.223l0.164,0.728c-0.447,0.194-0.973,0.291-1.582,0.291
                                                 c-0.73,0-1.305-0.215-1.721-0.645c-0.418-0.43-0.625-1.007-0.625-1.731c0-0.711,0.193-1.303,0.582-1.775
                                                 c0.406-0.504,0.955-0.756,1.648-0.756c0.678,0,1.193,0.252,1.541,0.756C121.068,9.77,121.207,10.265,121.207,10.853z
                                                 M120.207,10.582c0.008-0.311-0.061-0.579-0.203-0.805c-0.182-0.291-0.459-0.437-0.834-0.437c-0.342,0-0.621,0.142-0.834,0.427
                                                 c-0.174,0.227-0.277,0.498-0.311,0.815H120.207z"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>

                                    <a href={GOOGLE_PLAY_URL}
                                       target="_blank"
                                       rel="noopener noreferrer">
                                        <svg width="13.5rem" height="4rem" viewBox="0 0 128 40" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M124 39.5H4A3.512 3.512 0 01.5 36V4C.5 2.076 2.076.5 4 .5h120c1.924 0 3.5 1.576 3.5 3.5v32c0 1.924-1.576 3.5-3.5 3.5z"
                                                fill="#000" stroke="#A6A6A6"></path>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M9.8 19.6V9.1c0-.6.5-1.1 1-1.1s.7.1 1 .3l19.3 10.6c.4.2.6.5.6.8 0 .3-.2.6-.6.8L11.8 31.1c-.2.1-.5.3-1 .3s-1-.5-1-1.1V19.6z"
                                                  fill="url(#prefix__paint0_linear)"></path>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M22.302 19.7L10.602 8h.2c.5 0 .7.1 1 .3l14.2 7.8-3.7 3.6z"
                                                  fill="url(#prefix__paint1_linear)"></path>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M25.9 23.2l-3.6-3.6 3.7-3.7 5.1 2.8c.4.2.6.5.6.8 0 .3-.2.6-.6.8l-5.2 2.9z"
                                                  fill="url(#prefix__paint2_linear)"></path>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M10.7 31.2l11.6-11.6 3.6 3.6-14.1 7.7c-.3.2-.5.3-1.1.3.1 0 .1 0 0 0z"
                                                  fill="url(#prefix__paint3_linear)"></path>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M71.098 33.9c-.5-.4-.9-1.1-1.1-1.5l1.6-.7c.1.2.3.5.5.8.4.4 1 .8 1.6.8.6 0 1.3-.3 1.7-.8.3-.5.5-1 .5-1.7v-.6c-1.2 1.5-3.7 1.3-5.1-.3-1.5-1.6-1.5-4.3 0-5.9 1.5-1.5 3.7-1.7 5-.3V23h1.7v7.5c0 1.9-.7 3-1.6 3.7-.6.5-1.5.7-2.3.7-.9-.1-1.8-.4-2.5-1zm41.9.4l1.7-4-3-6.9h1.7l2.1 4.9 2.1-4.9h1.7l-4.6 10.9h-1.7zm-8.1-3.8c-.5-.5-.7-1.2-.7-1.9 0-.6.2-1.2.6-1.6.7-.7 1.7-1 2.8-1 .7 0 1.3.1 1.8.4 0-1.2-1-1.7-1.8-1.7-.7 0-1.4.4-1.7 1.1l-1.5-.6c.3-.7 1.1-2 3.1-2 1 0 2 .3 2.6 1 .6.7.8 1.5.8 2.6V31h-1.7v-.7c-.2.3-.6.5-.9.7-.4.2-.9.3-1.4.3-.6-.1-1.5-.3-2-.8zm-53.3-3.6c0-2 1.5-4.2 4.2-4.2 2.6 0 4.2 2.2 4.2 4.2s-1.5 4.2-4.2 4.2-4.2-2.2-4.2-4.2zm9 0c0-2 1.5-4.2 4.2-4.2 2.6 0 4.2 2.2 4.2 4.2s-1.5 4.2-4.2 4.2c-2.6 0-4.2-2.2-4.2-4.2zm-20.3 2.2c-2.5-2.5-2.4-6.6.1-9.2 1.3-1.3 2.9-1.9 4.6-1.9 1.6 0 3.2.6 4.4 1.8l-1.2 1.3c-1.8-1.8-4.7-1.7-6.4.1-1.8 1.9-1.8 4.8 0 6.7 1.8 1.9 4.8 2 6.6.1.6-.6.8-1.4.9-2.2h-4.2V24h5.9c.1.4.1.9.1 1.4 0 1.5-.6 3-1.6 4-1.1 1.1-2.7 1.7-4.3 1.7-1.8-.1-3.6-.7-4.9-2zm42.3.7c-1.5-1.6-1.5-4.3 0-6 1.5-1.6 4-1.6 5.4 0 .5.5.8 1.2 1.1 1.9l-5.5 2.3c.3.7 1 1.3 2 1.3.9 0 1.5-.3 2.1-1.2l1.5 1c-.2.2-.4.4-.5.6-1.7 1.7-4.6 1.7-6.1.1zm10.4 1.2V19.9h3.6c2.1 0 3.8 1.5 3.8 3.3 0 1.8-1.5 3.3-3.4 3.3h-2.2v4.4h-1.8v.1zm8.4 0V19.9h1.7V31h-1.7zm-22.6-.2V18.4h1.8v12.4h-1.8zm30.5-3.1c-.4-.3-1-.4-1.6-.4-1.2 0-1.9.6-1.9 1.3 0 .7.7 1.1 1.4 1.1 1 0 2.1-.8 2.1-2zm-51.2-.8c0-1.2-.8-2.5-2.3-2.5-1.5 0-2.3 1.3-2.3 2.5s.8 2.5 2.3 2.5c1.4 0 2.3-1.3 2.3-2.5zm9 0c0-1.2-.8-2.5-2.3-2.5-1.5 0-2.3 1.3-2.3 2.5s.8 2.5 2.3 2.5c1.5 0 2.3-1.3 2.3-2.5zm8.9.7c0-.1 0-.2.1-.3v-.4-.5c0-.1-.1-.3-.1-.4-.3-1-1.2-1.7-2.1-1.7-1.2 0-2.2 1.2-2.2 2.5 0 1.4 1 2.5 2.3 2.5.8.1 1.6-.6 2-1.7zm7.3-.9l3.7-1.6c-.4-.7-1-.9-1.5-.9-1.5.1-2.4 1.7-2.2 2.5zm15.3-3.4c0-1-.8-1.7-1.9-1.7h-2v3.5h2.1c1 0 1.8-.8 1.8-1.8z"
                                                  fill="#fff"></path>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M114.299 32.3h-.1v-.1h.1-.1v.1h.1zM114.501 32.3c0-.1 0-.1 0 0-.1-.1-.1-.1 0 0-.1 0-.1 0 0 0zm0-.1c0 .1 0 .1 0 0 0 .1-.1.1 0 0-.1 0 0 0 0 0z"
                                                  fill="#fff"></path>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M114.6 32.2h-.1.1v.1-.1z"
                                                  fill="#fff"></path>
                                            <path
                                                d="M38.898 10.1c0-1.8 1.3-2.9 2.9-2.9 1.1 0 1.8.5 2.3 1.2l-.8.5c-.3-.4-.8-.7-1.5-.7-1.1 0-1.9.8-1.9 2s.8 2 1.9 2c.6 0 1.1-.3 1.3-.5v-.9h-1.6v-.9h2.7V12c-.5.6-1.3 1-2.3 1-1.7 0-3-1.2-3-2.9zM45.398 12.9V7.3h3.8v.9h-2.9v1.4h2.8v.9h-2.8v1.6h2.9v.9l-3.8-.1zM52 12.9V8.2h-1.7v-.9h4.4v.9H53v4.7h-1zM58.102 12.9V7.3h1v5.6h-1zM61.802 12.9V8.2h-1.7v-.9h4.4v.9h-1.7v4.7h-1zM67.7 10.1c0-1.7 1.2-2.9 2.9-2.9s2.9 1.2 2.9 2.9-1.2 2.9-2.9 2.9-2.9-1.3-2.9-2.9zm4.7 0c0-1.2-.7-2-1.9-2-1.1 0-1.9.9-1.9 2 0 1.2.7 2 1.9 2s1.9-.9 1.9-2zM78.502 12.9l-2.9-4v4h-1V7.3h1l2.9 3.9V7.3h1v5.6h-1z"
                                                fill="#fff"></path>
                                            <defs>
                                                <linearGradient id="prefix__paint0_linear" x1="29.834" y1="9.157"
                                                                x2="16.607" y2="33.206" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#00A0FF"></stop>
                                                    <stop offset="0.01" stopColor="#00A1FF"></stop>
                                                    <stop offset="0.26" stopColor="#00BEFF"></stop>
                                                    <stop offset="0.51" stopColor="#00D2FF"></stop>
                                                    <stop offset="0.76" stopColor="#00DFFF"></stop>
                                                    <stop offset="1" stopColor="#00E3FF"></stop>
                                                </linearGradient>
                                                <linearGradient id="prefix__paint1_linear" x1="7.703" y1="1.679"
                                                                x2="16.939" y2="11.058" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#32A071"></stop>
                                                    <stop offset="0.07" stopColor="#2DA771"></stop>
                                                    <stop offset="0.48" stopColor="#15CF74"></stop>
                                                    <stop offset="0.8" stopColor="#06E775"></stop>
                                                    <stop offset="1" stopColor="#00F076"></stop>
                                                </linearGradient>
                                                <linearGradient id="prefix__paint2_linear" x1="32.423" y1="19.55"
                                                                x2="10.021" y2="19.55" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#FFE000"></stop>
                                                    <stop offset="0.41" stopColor="#FFBD00"></stop>
                                                    <stop offset="0.78" stopColor="orange"></stop>
                                                    <stop offset="1" stopColor="#FF9C00"></stop>
                                                </linearGradient>
                                                <linearGradient id="prefix__paint3_linear" x1="23.812" y1="21.673"
                                                                x2="3.299" y2="42.403" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#FF3A44"></stop>
                                                    <stop offset="1" stopColor="#C31162"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="wrapper__button">
                    <a className="button-custom" onClick={onGoTo(ROUTER_URL.HELP_INDEX)}>ヘルプページTOP</a>
                </div>

            </div>
        </Wrapper>
    );
}
export default HelpFlowScanner
