import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconHair1: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.1499 1H35.9935C19.4908 1.10075 10.8624 16.6364 11.1516 33.3649C11.406 46.953 12.0284 67.9896 12.0284 67.9896C19.2609 72.0035 52.8835 72.0035 60.116 67.9896C60.116 67.9896 60.7384 46.953 60.9928 33.3649C61.3015 16.6364 52.6526 1.10075 36.1499 1Z"
        fill="#111947"
      />
      <path
        d="M55.3686 25.9656C53.8153 13.7003 46.3917 6.86035 36.0136 6.86035C25.6356 6.86035 18.213 13.7003 16.6545 25.9656C12.1855 26.1671 11 29.2903 11 33.3656C11 37.441 15.5917 42.0603 17.1052 42.288C17.7368 42.3797 18.2907 42.4382 18.8497 42.4895C22.4665 50.6009 29.3259 56.3154 34.9804 56.3154H37.0918C42.7514 56.3154 49.6058 50.6009 53.2225 42.4855C53.78 42.4547 54.3348 42.3874 54.8832 42.284C56.4018 42.0714 60.9935 37.6032 60.9935 33.3616C60.9935 29.12 59.8326 26.163 55.3686 25.9656Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M29.6682 36.7227C31.3705 36.7227 32.7504 35.3623 32.7504 33.6841C32.7504 32.0059 31.3705 30.6455 29.6682 30.6455C27.9659 30.6455 26.5859 32.0059 26.5859 33.6841C26.5859 35.3623 27.9659 36.7227 29.6682 36.7227Z"
        fill="#111947"
      />
      <path
        d="M43.176 36.7227C44.8783 36.7227 46.2582 35.3623 46.2582 33.6841C46.2582 32.0059 44.8783 30.6455 43.176 30.6455C41.4737 30.6455 40.0938 32.0059 40.0938 33.6841C40.0938 35.3623 41.4737 36.7227 43.176 36.7227Z"
        fill="#111947"
      />
      <path
        d="M58.8862 24.1896L49.2675 24.2238C49.3462 23.7412 47.4351 18.7461 43.3401 14.9881C43.3401 14.9881 46.3733 22.0305 46.1576 24.2238L13.1328 24.1896C14.5441 15.7266 24.819 3.77262 35.1429 3.76758C46.2608 3.76254 55.0262 8.1774 58.8862 24.1896Z"
        fill="#111947"
      />
    </svg>
  )
}

export default IconHair1
