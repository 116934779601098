import React from 'react'

interface DecorCustomizationProps {
  height?: string
  width?: string
}

const DecorCustomization: React.FC<DecorCustomizationProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "115.5"} height={height || "84.5"} viewBox="0 0 115.5 84.5">
      <defs>
        <style>
          {
            '.cls-1{isolation:isolate}.cls-2{fill:#12c4c4}.cls-3{opacity:.5}.cls-4{opacity:.2}.cls-6{opacity:.7}.cls-7{opacity:.4}.cls-8{opacity:.3}'
          }
        </style>
      </defs>
      <g id="Group_10651" transform="translate(-1236 -118)">
        <g id="_3sge3513aeg" className="cls-1" transform="translate(1257 118)">
          <g id="Group_10650" className="cls-1">
            <path
              id="Path_951"
              d="M1280.615 120.493c0 .577-.436 1.046-1.327 1.046a2.021 2.021 0 0 1-1.287-.415l.23-.387a1.6 1.6 0 0 0 1.057.367c.526 0 .826-.234.826-.606s-.266-.6-.866-.6h-.281v-.341l.881-1.056h-1.687v-.425h2.313v.332l-.917 1.105c.702.066 1.058.452 1.058.98z"
              className="cls-2"
              transform="translate(-1278 -118.039)"
            />
            <path
              id="Path_952"
              d="M1286.489 121.07l.185-.381a1.831 1.831 0 0 0 1.157.4c.606 0 .866-.23.866-.528 0-.836-2.124-.308-2.124-1.579 0-.528.421-.978 1.343-.978a2.1 2.1 0 0 1 1.126.3l-.165.391a1.843 1.843 0 0 0-.961-.279c-.6 0-.852.245-.852.543 0 .836 2.124.313 2.124 1.569 0 .523-.431.973-1.357.973a2.112 2.112 0 0 1-1.342-.431z"
              className="cls-2"
              transform="translate(-1282.245 -118)"
            />
            <path
              id="Path_953"
              d="M1297.893 119.73h.481v1.335a2.105 2.105 0 0 1-1.328.435 1.754 1.754 0 1 1 .01-3.5 1.78 1.78 0 0 1 1.348.528l-.311.3a1.41 1.41 0 0 0-1.017-.4 1.317 1.317 0 1 0 0 2.63 1.508 1.508 0 0 0 .822-.221z"
              className="cls-2"
              transform="translate(-1286.589 -118)"
            />
            <path
              id="Path_954"
              d="M1308.426 121.075v.425h-2.545v-3.422h2.474v.425h-1.973v1.051h1.758v.415h-1.758v1.1z"
              className="cls-2"
              transform="translate(-1291.94 -118.039)"
            />
            <path
              id="Path_955"
              d="M1316.484 120.493c0 .577-.436 1.046-1.327 1.046a2.021 2.021 0 0 1-1.287-.415l.23-.387a1.6 1.6 0 0 0 1.057.367c.526 0 .826-.234.826-.606s-.265-.6-.866-.6h-.28v-.341l.881-1.056h-1.687v-.425h2.314v.332l-.917 1.105c.7.066 1.056.452 1.056.98z"
              className="cls-2"
              transform="translate(-1295.935 -118.039)"
            />
            <path
              id="Path_956"
              d="M1324.632 120.474c0 .591-.431 1.065-1.327 1.065a2.04 2.04 0 0 1-1.287-.415l.231-.387a1.594 1.594 0 0 0 1.052.367c.531 0 .831-.24.831-.611s-.25-.625-1.062-.625h-.8l.186-1.789h1.978v.425h-1.557l-.1.939h.391c1.053-.001 1.464.425 1.464 1.031z"
              className="cls-2"
              transform="translate(-1300.009 -118.039)"
            />
            <path
              id="Path_957"
              d="M1331.363 118.078v3.422h-.5v-3h-.792v-.425z"
              className="cls-2"
              transform="translate(-1304.037 -118.039)"
            />
            <path
              id="Path_958"
              d="M1338.763 120.493c0 .577-.435 1.046-1.327 1.046a2.02 2.02 0 0 1-1.287-.415l.23-.387a1.6 1.6 0 0 0 1.057.367c.526 0 .826-.234.826-.606s-.265-.6-.866-.6h-.281v-.341l.885-1.057h-1.688v-.425h2.314v.332l-.917 1.105c.698.067 1.054.453 1.054.981z"
              className="cls-2"
              transform="translate(-1307.074 -118.039)"
            />
            <path
              id="Path_959"
              d="M1347.011 120.645h-1.863l-.385.855h-.516l1.588-3.422h.5l1.593 3.422h-.526zm-.175-.391l-.756-1.676-.756 1.676z"
              className="cls-2"
              transform="translate(-1311.124 -118.039)"
            />
            <path
              id="Path_960"
              d="M1357.584 121.075v.425h-2.544v-3.422h2.474v.425h-1.974v1.051h1.758v.415h-1.758v1.1z"
              className="cls-2"
              transform="translate(-1316.52 -118.039)"
            />
            <path
              id="Path_961"
              d="M1366.263 119.73h.481v1.335a2.1 2.1 0 0 1-1.327.435 1.754 1.754 0 1 1 .01-3.5 1.78 1.78 0 0 1 1.347.528l-.31.3a1.41 1.41 0 0 0-1.017-.4 1.317 1.317 0 1 0-.005 2.63 1.507 1.507 0 0 0 .822-.221z"
              className="cls-2"
              transform="translate(-1320.774 -118)"
            />
          </g>
        </g>
        <g id="グループ_399" transform="translate(1236 129)">
          <g id="グループ_4" className="cls-3" transform="translate(105)">
            <path
              id="パス_227"
              d="M1466.5 140v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1456 -140)"
            />
            <path
              id="パス_228"
              d="M1459.5 140l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1452.5 -140)"
            />
            <path
              id="パス_229"
              d="M1452.5 140l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1449 -140)"
            />
            <path
              id="パス_230"
              d="M1446 140l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1446 -140)"
            />
            <path
              id="パス_231"
              d="M1449.5 157.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1446 -147)"
            />
            <path
              id="パス_232"
              d="M1453 154h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1446 -143.5)"
            />
          </g>
          <g id="グループ_4-2" className="cls-3" transform="translate(94.5)">
            <path
              id="パス_227-2"
              d="M1445.5 140v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1435 -140)"
            />
            <path
              id="パス_228-2"
              d="M1438.5 140l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1431.5 -140)"
            />
            <path
              id="パス_229-2"
              d="M1431.5 140l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1428 -140)"
            />
            <path
              id="パス_230-2"
              d="M1425 140l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1425 -140)"
            />
            <path
              id="パス_231-2"
              d="M1428.5 157.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1425 -147)"
            />
            <path
              id="パス_232-2"
              d="M1432 154h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1425 -143.5)"
            />
          </g>
          <g id="グループ_4-3" className="cls-3" transform="translate(84)">
            <path
              id="パス_227-3"
              d="M1424.5 140v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1414 -140)"
            />
            <path
              id="パス_228-3"
              d="M1417.5 140l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1410.5 -140)"
            />
            <path
              id="パス_229-3"
              d="M1410.5 140l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1407 -140)"
            />
            <path
              id="パス_230-3"
              d="M1404 140l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1404 -140)"
            />
            <path
              id="パス_231-3"
              d="M1407.5 157.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1404 -147)"
            />
            <path
              id="パス_232-3"
              d="M1411 154h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1404 -143.5)"
            />
          </g>
          <g id="グループ_4-4" className="cls-3" transform="translate(73.5)">
            <path
              id="パス_227-4"
              d="M1403.5 140v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1393 -140)"
            />
            <path
              id="パス_228-4"
              d="M1396.5 140l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1389.5 -140)"
            />
            <path
              id="パス_229-4"
              d="M1389.5 140l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1386 -140)"
            />
            <path
              id="パス_230-4"
              d="M1383 140l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1383 -140)"
            />
            <path
              id="パス_231-4"
              d="M1386.5 157.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1383 -147)"
            />
            <path
              id="パス_232-4"
              d="M1390 154h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1383 -143.5)"
            />
          </g>
          <g id="グループ_4-5" className="cls-3" transform="translate(63)">
            <path
              id="パス_227-5"
              d="M1382.5 140v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1372 -140)"
            />
            <path
              id="パス_228-5"
              d="M1375.5 140l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1368.5 -140)"
            />
            <path
              id="パス_229-5"
              d="M1368.5 140l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1365 -140)"
            />
            <path
              id="パス_230-5"
              d="M1362 140l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1362 -140)"
            />
            <path
              id="パス_231-5"
              d="M1365.5 157.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1362 -147)"
            />
            <path
              id="パス_232-5"
              d="M1369 154h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1362 -143.5)"
            />
          </g>
          <g id="グループ_4-6" transform="translate(52.5)">
            <path
              id="パス_227-6"
              d="M1361.5 140v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1351 -140)"
            />
            <path
              id="パス_228-6"
              d="M1354.5 140l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1347.5 -140)"
            />
            <path
              id="パス_229-6"
              d="M1347.5 140l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1344 -140)"
            />
            <path
              id="パス_230-6"
              d="M1341 140l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1341 -140)"
            />
            <path
              id="パス_231-6"
              d="M1344.5 157.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1341 -147)"
            />
            <path
              id="パス_232-6"
              d="M1348 154h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1341 -143.5)"
            />
          </g>
          <g id="グループ_4-7" transform="translate(42)">
            <path
              id="パス_227-7"
              d="M1340.5 140v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1330 -140)"
            />
            <path
              id="パス_228-7"
              d="M1333.5 140l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1326.5 -140)"
            />
            <path
              id="パス_229-7"
              d="M1326.5 140l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1323 -140)"
            />
            <path
              id="パス_230-7"
              d="M1320 140l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1320 -140)"
            />
            <path
              id="パス_231-7"
              d="M1323.5 157.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1320 -147)"
            />
            <path
              id="パス_232-7"
              d="M1327 154h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1320 -143.5)"
            />
          </g>
          <g id="グループ_4-8" transform="translate(31.5)">
            <path
              id="パス_227-8"
              d="M1319.5 140v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1309 -140)"
            />
            <path
              id="パス_228-8"
              d="M1312.5 140l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1305.5 -140)"
            />
            <path
              id="パス_229-8"
              d="M1305.5 140l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1302 -140)"
            />
            <path
              id="パス_230-8"
              d="M1299 140l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1299 -140)"
            />
            <path
              id="パス_231-8"
              d="M1302.5 157.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1299 -147)"
            />
            <path
              id="パス_232-8"
              d="M1306 154h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1299 -143.5)"
            />
          </g>
          <g id="グループ_4-9" transform="translate(21)">
            <path
              id="パス_227-9"
              d="M1298.5 140v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1288 -140)"
            />
            <path
              id="パス_228-9"
              d="M1291.5 140l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1284.5 -140)"
            />
            <path
              id="パス_229-9"
              d="M1284.5 140l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1281 -140)"
            />
            <path
              id="パス_230-9"
              d="M1278 140l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1278 -140)"
            />
            <path
              id="パス_231-9"
              d="M1281.5 157.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1278 -147)"
            />
            <path
              id="パス_232-9"
              d="M1285 154h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1278 -143.5)"
            />
          </g>
          <g id="グループ_4-10" className="cls-4" transform="translate(10.5)">
            <path
              id="パス_227-10"
              d="M1277.5 140v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1267 -140)"
            />
            <path
              id="パス_228-10"
              d="M1270.5 140l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1263.5 -140)"
            />
            <path
              id="パス_229-10"
              d="M1263.5 140l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1260 -140)"
            />
            <path
              id="パス_230-10"
              d="M1257 140l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1257 -140)"
            />
            <path
              id="パス_231-10"
              d="M1260.5 157.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1257 -147)"
            />
            <path
              id="パス_232-10"
              d="M1264 154h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1257 -143.5)"
            />
          </g>
          <g id="グループ_4-11" className="cls-4">
            <path
              id="パス_227-11"
              d="M1256.5 140v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1246 -140)"
            />
            <path
              id="パス_228-11"
              d="M1249.5 140l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1242.5 -140)"
            />
            <path
              id="パス_229-11"
              d="M1242.5 140l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1239 -140)"
            />
            <path
              id="パス_230-11"
              d="M1236 140l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1236 -140)"
            />
            <path
              id="パス_231-11"
              d="M1239.5 157.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1236 -147)"
            />
            <path
              id="パス_232-11"
              d="M1243 154h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1236 -143.5)"
            />
          </g>
          <g id="グループ_4-12" className="cls-3" transform="translate(105 10.5)">
            <path
              id="パス_227-12"
              d="M1466.5 161v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1456 -161)"
            />
            <path
              id="パス_228-12"
              d="M1459.5 161l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1452.5 -161)"
            />
            <path
              id="パス_229-12"
              d="M1452.5 161l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1449 -161)"
            />
            <path
              id="パス_230-12"
              d="M1446 161l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1446 -161)"
            />
            <path
              id="パス_231-12"
              d="M1449.5 178.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1446 -168)"
            />
            <path
              id="パス_232-12"
              d="M1453 175h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1446 -164.5)"
            />
          </g>
          <g id="グループ_4-13" className="cls-3" transform="translate(94.5 10.5)">
            <path
              id="パス_227-13"
              d="M1445.5 161v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1435 -161)"
            />
            <path
              id="パス_228-13"
              d="M1438.5 161l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1431.5 -161)"
            />
            <path
              id="パス_229-13"
              d="M1431.5 161l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1428 -161)"
            />
            <path
              id="パス_230-13"
              d="M1425 161l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1425 -161)"
            />
            <path
              id="パス_231-13"
              d="M1428.5 178.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1425 -168)"
            />
            <path
              id="パス_232-13"
              d="M1432 175h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1425 -164.5)"
            />
          </g>
          <g id="グループ_4-14" className="cls-3" transform="translate(84 10.5)">
            <path
              id="パス_227-14"
              d="M1424.5 161v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1414 -161)"
            />
            <path
              id="パス_228-14"
              d="M1417.5 161l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1410.5 -161)"
            />
            <path
              id="パス_229-14"
              d="M1410.5 161l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1407 -161)"
            />
            <path
              id="パス_230-14"
              d="M1404 161l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1404 -161)"
            />
            <path
              id="パス_231-14"
              d="M1407.5 178.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1404 -168)"
            />
            <path
              id="パス_232-14"
              d="M1411 175h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1404 -164.5)"
            />
          </g>
          <g id="グループ_4-15" className="cls-3" transform="translate(73.5 10.5)">
            <path
              id="パス_227-15"
              d="M1403.5 161v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1393 -161)"
            />
            <path
              id="パス_228-15"
              d="M1396.5 161l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1389.5 -161)"
            />
            <path
              id="パス_229-15"
              d="M1389.5 161l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1386 -161)"
            />
            <path
              id="パス_230-15"
              d="M1383 161l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1383 -161)"
            />
            <path
              id="パス_231-15"
              d="M1386.5 178.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1383 -168)"
            />
            <path
              id="パス_232-15"
              d="M1390 175h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1383 -164.5)"
            />
          </g>
          <g id="グループ_4-16" className="cls-3" transform="translate(63 10.5)">
            <path
              id="パス_227-16"
              d="M1382.5 161v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1372 -161)"
            />
            <path
              id="パス_228-16"
              d="M1375.5 161l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1368.5 -161)"
            />
            <path
              id="パス_229-16"
              d="M1368.5 161l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1365 -161)"
            />
            <path
              id="パス_230-16"
              d="M1362 161l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1362 -161)"
            />
            <path
              id="パス_231-16"
              d="M1365.5 178.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1362 -168)"
            />
            <path
              id="パス_232-16"
              d="M1369 175h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1362 -164.5)"
            />
          </g>
          <g id="グループ_4-17" transform="translate(52.5 10.5)">
            <path
              id="パス_227-17"
              d="M1361.5 161v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1351 -161)"
            />
            <path
              id="パス_228-17"
              d="M1354.5 161l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1347.5 -161)"
            />
            <path
              id="パス_229-17"
              d="M1347.5 161l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1344 -161)"
            />
            <path
              id="パス_230-17"
              d="M1341 161l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1341 -161)"
            />
            <path
              id="パス_231-17"
              d="M1344.5 178.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1341 -168)"
            />
            <path
              id="パス_232-17"
              d="M1348 175h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1341 -164.5)"
            />
          </g>
          <g id="グループ_4-18" transform="translate(42 10.5)">
            <path
              id="パス_227-18"
              d="M1340.5 161v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1330 -161)"
            />
            <path
              id="パス_228-18"
              d="M1333.5 161l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1326.5 -161)"
            />
            <path
              id="パス_229-18"
              d="M1326.5 161l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1323 -161)"
            />
            <path
              id="パス_230-18"
              d="M1320 161l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1320 -161)"
            />
            <path
              id="パス_231-18"
              d="M1323.5 178.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1320 -168)"
            />
            <path
              id="パス_232-18"
              d="M1327 175h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1320 -164.5)"
            />
          </g>
          <g id="グループ_4-19" transform="translate(31.5 10.5)">
            <path
              id="パス_227-19"
              d="M1319.5 161v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1309 -161)"
            />
            <path
              id="パス_228-19"
              d="M1312.5 161l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1305.5 -161)"
            />
            <path
              id="パス_229-19"
              d="M1305.5 161l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1302 -161)"
            />
            <path
              id="パス_230-19"
              d="M1299 161l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1299 -161)"
            />
            <path
              id="パス_231-19"
              d="M1302.5 178.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1299 -168)"
            />
            <path
              id="パス_232-19"
              d="M1306 175h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1299 -164.5)"
            />
          </g>
          <g id="グループ_4-20" transform="translate(21 10.5)">
            <path
              id="パス_227-20"
              d="M1298.5 161v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1288 -161)"
            />
            <path
              id="パス_228-20"
              d="M1291.5 161l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1284.5 -161)"
            />
            <path
              id="パス_229-20"
              d="M1284.5 161l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1281 -161)"
            />
            <path
              id="パス_230-20"
              d="M1278 161l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1278 -161)"
            />
            <path
              id="パス_231-20"
              d="M1281.5 178.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1278 -168)"
            />
            <path
              id="パス_232-20"
              d="M1285 175h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1278 -164.5)"
            />
          </g>
          <g id="グループ_4-21" className="cls-4" transform="translate(10.5 10.5)">
            <path
              id="パス_227-21"
              d="M1277.5 161v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1267 -161)"
            />
            <path
              id="パス_228-21"
              d="M1270.5 161l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1263.5 -161)"
            />
            <path
              id="パス_229-21"
              d="M1263.5 161l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1260 -161)"
            />
            <path
              id="パス_230-21"
              d="M1257 161l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1257 -161)"
            />
            <path
              id="パス_231-21"
              d="M1260.5 178.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1257 -168)"
            />
            <path
              id="パス_232-21"
              d="M1264 175h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1257 -164.5)"
            />
          </g>
          <g id="グループ_4-22" className="cls-4" transform="translate(0 10.5)">
            <path
              id="パス_227-22"
              d="M1256.5 161v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1246 -161)"
            />
            <path
              id="パス_228-22"
              d="M1249.5 161l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1242.5 -161)"
            />
            <path
              id="パス_229-22"
              d="M1242.5 161l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1239 -161)"
            />
            <path
              id="パス_230-22"
              d="M1236 161l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1236 -161)"
            />
            <path
              id="パス_231-22"
              d="M1239.5 178.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1236 -168)"
            />
            <path
              id="パス_232-22"
              fill="#12c4c4"
              d="M1243 175h-.5l-6.5-6.5v-.5z"
              transform="translate(-1236 -164.5)"
              style={{ isolation: 'isolate' }}
              opacity="0.2"
            />
          </g>
          <g id="グループ_4-23" transform="translate(105 21)">
            <path
              id="パス_227-23"
              d="M1466.5 182v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1456 -182)"
            />
            <path
              id="パス_228-23"
              d="M1459.5 182l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1452.5 -182)"
            />
            <path
              id="パス_229-23"
              d="M1452.5 182l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1449 -182)"
            />
            <path
              id="パス_230-23"
              d="M1446 182l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1446 -182)"
            />
            <path
              id="パス_231-23"
              d="M1449.5 199.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1446 -189)"
            />
            <path
              id="パス_232-23"
              d="M1453 196h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1446 -185.5)"
            />
          </g>
          <g id="グループ_4-24" transform="translate(94.5 21)">
            <path
              id="パス_227-24"
              d="M1445.5 182v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1435 -182)"
            />
            <path
              id="パス_228-24"
              d="M1438.5 182l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1431.5 -182)"
            />
            <path
              id="パス_229-24"
              d="M1431.5 182l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1428 -182)"
            />
            <path
              id="パス_230-24"
              d="M1425 182l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1425 -182)"
            />
            <path
              id="パス_231-24"
              d="M1428.5 199.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1425 -189)"
            />
            <path
              id="パス_232-24"
              d="M1432 196h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1425 -185.5)"
            />
          </g>
          <g id="グループ_4-25" transform="translate(84 21)">
            <path
              id="パス_227-25"
              d="M1424.5 182v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1414 -182)"
            />
            <path
              id="パス_228-25"
              d="M1417.5 182l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1410.5 -182)"
            />
            <path
              id="パス_229-25"
              d="M1410.5 182l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1407 -182)"
            />
            <path
              id="パス_230-25"
              d="M1404 182l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1404 -182)"
            />
            <path
              id="パス_231-25"
              d="M1407.5 199.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1404 -189)"
            />
            <path
              id="パス_232-25"
              d="M1411 196h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1404 -185.5)"
            />
          </g>
          <g id="グループ_4-26" transform="translate(73.5 21)">
            <path
              id="パス_227-26"
              d="M1403.5 182v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1393 -182)"
            />
            <path
              id="パス_228-26"
              d="M1396.5 182l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1389.5 -182)"
            />
            <path
              id="パス_229-26"
              d="M1389.5 182l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1386 -182)"
            />
            <path
              id="パス_230-26"
              d="M1383 182l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1383 -182)"
            />
            <path
              id="パス_231-26"
              d="M1386.5 199.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1383 -189)"
            />
            <path
              id="パス_232-26"
              d="M1390 196h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1383 -185.5)"
            />
          </g>
          <g id="グループ_4-27" transform="translate(63 21)">
            <path
              id="パス_227-27"
              d="M1382.5 182v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1372 -182)"
            />
            <path
              id="パス_228-27"
              d="M1375.5 182l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1368.5 -182)"
            />
            <path
              id="パス_229-27"
              d="M1368.5 182l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1365 -182)"
            />
            <path
              id="パス_230-27"
              d="M1362 182l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1362 -182)"
            />
            <path
              id="パス_231-27"
              d="M1365.5 199.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1362 -189)"
            />
            <path
              id="パス_232-27"
              d="M1369 196h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1362 -185.5)"
            />
          </g>
          <g id="グループ_4-28" transform="translate(52.5 21)">
            <path
              id="パス_227-28"
              d="M1361.5 182v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1351 -182)"
            />
            <path
              id="パス_228-28"
              d="M1354.5 182l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1347.5 -182)"
            />
            <path
              id="パス_229-28"
              d="M1347.5 182l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1344 -182)"
            />
            <path
              id="パス_230-28"
              d="M1341 182l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1341 -182)"
            />
            <path
              id="パス_231-28"
              d="M1344.5 199.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1341 -189)"
            />
            <path
              id="パス_232-28"
              d="M1348 196h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1341 -185.5)"
            />
          </g>
          <g id="グループ_4-29" transform="translate(42 21)">
            <path
              id="パス_227-29"
              d="M1340.5 182v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1330 -182)"
            />
            <path
              id="パス_228-29"
              d="M1333.5 182l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1326.5 -182)"
            />
            <path
              id="パス_229-29"
              d="M1326.5 182l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1323 -182)"
            />
            <path
              id="パス_230-29"
              d="M1320 182l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1320 -182)"
            />
            <path
              id="パス_231-29"
              d="M1323.5 199.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1320 -189)"
            />
            <path
              id="パス_232-29"
              d="M1327 196h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1320 -185.5)"
            />
          </g>
          <g id="グループ_4-30" className="cls-6" transform="translate(31.5 21)">
            <path
              id="パス_227-30"
              d="M1319.5 182v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1309 -182)"
            />
            <path
              id="パス_228-30"
              d="M1312.5 182l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1305.5 -182)"
            />
            <path
              id="パス_229-30"
              d="M1305.5 182l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1302 -182)"
            />
            <path
              id="パス_230-30"
              d="M1299 182l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1299 -182)"
            />
            <path
              id="パス_231-30"
              d="M1302.5 199.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1299 -189)"
            />
            <path
              id="パス_232-30"
              d="M1306 196h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1299 -185.5)"
            />
          </g>
          <g id="グループ_4-31" className="cls-6" transform="translate(21 21)">
            <path
              id="パス_227-31"
              d="M1298.5 182v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1288 -182)"
            />
            <path
              id="パス_228-31"
              d="M1291.5 182l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1284.5 -182)"
            />
            <path
              id="パス_229-31"
              d="M1284.5 182l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1281 -182)"
            />
            <path
              id="パス_230-31"
              d="M1278 182l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1278 -182)"
            />
            <path
              id="パス_231-31"
              d="M1281.5 199.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1278 -189)"
            />
            <path
              id="パス_232-31"
              d="M1285 196h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1278 -185.5)"
            />
          </g>
          <g id="グループ_4-32" className="cls-6" transform="translate(10.5 21)">
            <path
              id="パス_227-32"
              d="M1277.5 182v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1267 -182)"
            />
            <path
              id="パス_228-32"
              d="M1270.5 182l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1263.5 -182)"
            />
            <path
              id="パス_229-32"
              d="M1263.5 182l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1260 -182)"
            />
            <path
              id="パス_230-32"
              d="M1257 182l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1257 -182)"
            />
            <path
              id="パス_231-32"
              d="M1260.5 199.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1257 -189)"
            />
            <path
              id="パス_232-32"
              d="M1264 196h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1257 -185.5)"
            />
          </g>
          <g id="グループ_4-33" className="cls-6" transform="translate(0 21)">
            <path
              id="パス_227-33"
              d="M1256.5 182v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1246 -182)"
            />
            <path
              id="パス_228-33"
              d="M1249.5 182l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1242.5 -182)"
            />
            <path
              id="パス_229-33"
              d="M1242.5 182l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1239 -182)"
            />
            <path
              id="パス_230-33"
              d="M1236 182l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1236 -182)"
            />
            <path
              id="パス_231-33"
              d="M1239.5 199.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1236 -189)"
            />
            <path
              id="パス_232-33"
              d="M1243 196h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1236 -185.5)"
            />
          </g>
          <g id="グループ_4-34" transform="translate(105 31.5)">
            <path
              id="パス_227-34"
              d="M1466.5 203v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1456 -203)"
            />
            <path
              id="パス_228-34"
              d="M1459.5 203l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1452.5 -203)"
            />
            <path
              id="パス_229-34"
              d="M1452.5 203l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1449 -203)"
            />
            <path
              id="パス_230-34"
              d="M1446 203l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1446 -203)"
            />
            <path
              id="パス_231-34"
              d="M1449.5 220.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1446 -210)"
            />
            <path
              id="パス_232-34"
              d="M1453 217h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1446 -206.5)"
            />
          </g>
          <g id="グループ_4-35" transform="translate(94.5 31.5)">
            <path
              id="パス_227-35"
              d="M1445.5 203v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1435 -203)"
            />
            <path
              id="パス_228-35"
              d="M1438.5 203l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1431.5 -203)"
            />
            <path
              id="パス_229-35"
              d="M1431.5 203l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1428 -203)"
            />
            <path
              id="パス_230-35"
              d="M1425 203l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1425 -203)"
            />
            <path
              id="パス_231-35"
              d="M1428.5 220.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1425 -210)"
            />
            <path
              id="パス_232-35"
              d="M1432 217h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1425 -206.5)"
            />
          </g>
          <g id="グループ_4-36" transform="translate(84 31.5)">
            <path
              id="パス_227-36"
              d="M1424.5 203v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1414 -203)"
            />
            <path
              id="パス_228-36"
              d="M1417.5 203l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1410.5 -203)"
            />
            <path
              id="パス_229-36"
              d="M1410.5 203l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1407 -203)"
            />
            <path
              id="パス_230-36"
              d="M1404 203l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1404 -203)"
            />
            <path
              id="パス_231-36"
              d="M1407.5 220.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1404 -210)"
            />
            <path
              id="パス_232-36"
              d="M1411 217h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1404 -206.5)"
            />
          </g>
          <g id="グループ_4-37" transform="translate(73.5 31.5)">
            <path
              id="パス_227-37"
              d="M1403.5 203v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1393 -203)"
            />
            <path
              id="パス_228-37"
              d="M1396.5 203l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1389.5 -203)"
            />
            <path
              id="パス_229-37"
              d="M1389.5 203l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1386 -203)"
            />
            <path
              id="パス_230-37"
              d="M1383 203l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1383 -203)"
            />
            <path
              id="パス_231-37"
              d="M1386.5 220.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1383 -210)"
            />
            <path
              id="パス_232-37"
              d="M1390 217h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1383 -206.5)"
            />
          </g>
          <g id="グループ_4-38" transform="translate(63 31.5)">
            <path
              id="パス_227-38"
              d="M1382.5 203v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1372 -203)"
            />
            <path
              id="パス_228-38"
              d="M1375.5 203l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1368.5 -203)"
            />
            <path
              id="パス_229-38"
              d="M1368.5 203l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1365 -203)"
            />
            <path
              id="パス_230-38"
              d="M1362 203l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1362 -203)"
            />
            <path
              id="パス_231-38"
              d="M1365.5 220.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1362 -210)"
            />
            <path
              id="パス_232-38"
              d="M1369 217h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1362 -206.5)"
            />
          </g>
          <g id="グループ_4-39" className="cls-7" transform="translate(52.5 31.5)">
            <path
              id="パス_227-39"
              d="M1361.5 203v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1351 -203)"
            />
            <path
              id="パス_228-39"
              d="M1354.5 203l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1347.5 -203)"
            />
            <path
              id="パス_229-39"
              d="M1347.5 203l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1344 -203)"
            />
            <path
              id="パス_230-39"
              d="M1341 203l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1341 -203)"
            />
            <path
              id="パス_231-39"
              d="M1344.5 220.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1341 -210)"
            />
            <path
              id="パス_232-39"
              d="M1348 217h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1341 -206.5)"
            />
          </g>
          <g id="グループ_4-40" className="cls-7" transform="translate(42 31.5)">
            <path
              id="パス_227-40"
              d="M1340.5 203v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1330 -203)"
            />
            <path
              id="パス_228-40"
              d="M1333.5 203l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1326.5 -203)"
            />
            <path
              id="パス_229-40"
              d="M1326.5 203l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1323 -203)"
            />
            <path
              id="パス_230-40"
              d="M1320 203l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1320 -203)"
            />
            <path
              id="パス_231-40"
              d="M1323.5 220.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1320 -210)"
            />
            <path
              id="パス_232-40"
              d="M1327 217h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1320 -206.5)"
            />
          </g>
          <g id="グループ_4-41" className="cls-6" transform="translate(31.5 31.5)">
            <path
              id="パス_227-41"
              d="M1319.5 203v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1309 -203)"
            />
            <path
              id="パス_228-41"
              d="M1312.5 203l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1305.5 -203)"
            />
            <path
              id="パス_229-41"
              d="M1305.5 203l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1302 -203)"
            />
            <path
              id="パス_230-41"
              d="M1299 203l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1299 -203)"
            />
            <path
              id="パス_231-41"
              d="M1302.5 220.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1299 -210)"
            />
            <path
              id="パス_232-41"
              d="M1306 217h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1299 -206.5)"
            />
          </g>
          <g id="グループ_4-42" className="cls-6" transform="translate(21 31.5)">
            <path
              id="パス_227-42"
              d="M1298.5 203v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1288 -203)"
            />
            <path
              id="パス_228-42"
              d="M1291.5 203l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1284.5 -203)"
            />
            <path
              id="パス_229-42"
              d="M1284.5 203l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1281 -203)"
            />
            <path
              id="パス_230-42"
              d="M1278 203l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1278 -203)"
            />
            <path
              id="パス_231-42"
              d="M1281.5 220.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1278 -210)"
            />
            <path
              id="パス_232-42"
              d="M1285 217h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1278 -206.5)"
            />
          </g>
          <g id="グループ_4-43" className="cls-6" transform="translate(10.5 31.5)">
            <path
              id="パス_227-43"
              d="M1277.5 203v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1267 -203)"
            />
            <path
              id="パス_228-43"
              d="M1270.5 203l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1263.5 -203)"
            />
            <path
              id="パス_229-43"
              d="M1263.5 203l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1260 -203)"
            />
            <path
              id="パス_230-43"
              d="M1257 203l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1257 -203)"
            />
            <path
              id="パス_231-43"
              d="M1260.5 220.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1257 -210)"
            />
            <path
              id="パス_232-43"
              d="M1264 217h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1257 -206.5)"
            />
          </g>
          <g id="グループ_4-44" className="cls-6" transform="translate(0 31.5)">
            <path
              id="パス_227-44"
              d="M1256.5 203v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1246 -203)"
            />
            <path
              id="パス_228-44"
              d="M1249.5 203l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1242.5 -203)"
            />
            <path
              id="パス_229-44"
              d="M1242.5 203l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1239 -203)"
            />
            <path
              id="パス_230-44"
              d="M1236 203l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1236 -203)"
            />
            <path
              id="パス_231-44"
              d="M1239.5 220.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1236 -210)"
            />
            <path
              id="パス_232-44"
              d="M1243 217h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1236 -206.5)"
            />
          </g>
          <g id="グループ_4-45" className="cls-8" transform="translate(105 42)">
            <path
              id="パス_227-45"
              d="M1466.5 224v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1456 -224)"
            />
            <path
              id="パス_228-45"
              d="M1459.5 224l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1452.5 -224)"
            />
            <path
              id="パス_229-45"
              d="M1452.5 224l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1449 -224)"
            />
            <path
              id="パス_230-45"
              d="M1446 224l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1446 -224)"
            />
            <path
              id="パス_231-45"
              d="M1449.5 241.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1446 -231)"
            />
            <path
              id="パス_232-45"
              d="M1453 238h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1446 -227.5)"
            />
          </g>
          <g id="グループ_4-46" className="cls-8" transform="translate(94.5 42)">
            <path
              id="パス_227-46"
              d="M1445.5 224v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1435 -224)"
            />
            <path
              id="パス_228-46"
              d="M1438.5 224l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1431.5 -224)"
            />
            <path
              id="パス_229-46"
              d="M1431.5 224l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1428 -224)"
            />
            <path
              id="パス_230-46"
              d="M1425 224l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1425 -224)"
            />
            <path
              id="パス_231-46"
              d="M1428.5 241.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1425 -231)"
            />
            <path
              id="パス_232-46"
              d="M1432 238h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1425 -227.5)"
            />
          </g>
          <g id="グループ_4-47" transform="translate(84 42)">
            <path
              id="パス_227-47"
              d="M1424.5 224v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1414 -224)"
            />
            <path
              id="パス_228-47"
              d="M1417.5 224l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1410.5 -224)"
            />
            <path
              id="パス_229-47"
              d="M1410.5 224l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1407 -224)"
            />
            <path
              id="パス_230-47"
              d="M1404 224l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1404 -224)"
            />
            <path
              id="パス_231-47"
              d="M1407.5 241.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1404 -231)"
            />
            <path
              id="パス_232-47"
              d="M1411 238h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1404 -227.5)"
            />
          </g>
          <g id="グループ_4-48" transform="translate(73.5 42)">
            <path
              id="パス_227-48"
              d="M1403.5 224v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1393 -224)"
            />
            <path
              id="パス_228-48"
              d="M1396.5 224l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1389.5 -224)"
            />
            <path
              id="パス_229-48"
              d="M1389.5 224l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1386 -224)"
            />
            <path
              id="パス_230-48"
              d="M1383 224l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1383 -224)"
            />
            <path
              id="パス_231-48"
              d="M1386.5 241.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1383 -231)"
            />
            <path
              id="パス_232-48"
              d="M1390 238h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1383 -227.5)"
            />
          </g>
          <g id="グループ_4-49" transform="translate(63 42)">
            <path
              id="パス_227-49"
              d="M1382.5 224v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1372 -224)"
            />
            <path
              id="パス_228-49"
              d="M1375.5 224l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1368.5 -224)"
            />
            <path
              id="パス_229-49"
              d="M1368.5 224l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1365 -224)"
            />
            <path
              id="パス_230-49"
              d="M1362 224l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1362 -224)"
            />
            <path
              id="パス_231-49"
              d="M1365.5 241.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1362 -231)"
            />
            <path
              id="パス_232-49"
              d="M1369 238h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1362 -227.5)"
            />
          </g>
          <g id="グループ_4-50" className="cls-7" transform="translate(52.5 42)">
            <path
              id="パス_227-50"
              d="M1361.5 224v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1351 -224)"
            />
            <path
              id="パス_228-50"
              d="M1354.5 224l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1347.5 -224)"
            />
            <path
              id="パス_229-50"
              d="M1347.5 224l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1344 -224)"
            />
            <path
              id="パス_230-50"
              d="M1341 224l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1341 -224)"
            />
            <path
              id="パス_231-50"
              d="M1344.5 241.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1341 -231)"
            />
            <path
              id="パス_232-50"
              d="M1348 238h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1341 -227.5)"
            />
          </g>
          <g id="グループ_4-51" className="cls-7" transform="translate(42 42)">
            <path
              id="パス_227-51"
              d="M1340.5 224v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1330 -224)"
            />
            <path
              id="パス_228-51"
              d="M1333.5 224l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1326.5 -224)"
            />
            <path
              id="パス_229-51"
              d="M1326.5 224l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1323 -224)"
            />
            <path
              id="パス_230-51"
              d="M1320 224l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1320 -224)"
            />
            <path
              id="パス_231-51"
              d="M1323.5 241.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1320 -231)"
            />
            <path
              id="パス_232-51"
              d="M1327 238h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1320 -227.5)"
            />
          </g>
          <g id="グループ_4-52" className="cls-6" transform="translate(31.5 42)">
            <path
              id="パス_227-52"
              d="M1319.5 224v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1309 -224)"
            />
            <path
              id="パス_228-52"
              d="M1312.5 224l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1305.5 -224)"
            />
            <path
              id="パス_229-52"
              d="M1305.5 224l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1302 -224)"
            />
            <path
              id="パス_230-52"
              d="M1299 224l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1299 -224)"
            />
            <path
              id="パス_231-52"
              d="M1302.5 241.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1299 -231)"
            />
            <path
              id="パス_232-52"
              d="M1306 238h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1299 -227.5)"
            />
          </g>
          <g id="グループ_4-53" className="cls-6" transform="translate(21 42)">
            <path
              id="パス_227-53"
              d="M1298.5 224v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1288 -224)"
            />
            <path
              id="パス_228-53"
              d="M1291.5 224l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1284.5 -224)"
            />
            <path
              id="パス_229-53"
              d="M1284.5 224l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1281 -224)"
            />
            <path
              id="パス_230-53"
              d="M1278 224l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1278 -224)"
            />
            <path
              id="パス_231-53"
              d="M1281.5 241.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1278 -231)"
            />
            <path
              id="パス_232-53"
              d="M1285 238h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1278 -227.5)"
            />
          </g>
          <g id="グループ_4-54" className="cls-6" transform="translate(10.5 42)">
            <path
              id="パス_227-54"
              d="M1277.5 224v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1267 -224)"
            />
            <path
              id="パス_228-54"
              d="M1270.5 224l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1263.5 -224)"
            />
            <path
              id="パス_229-54"
              d="M1263.5 224l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1260 -224)"
            />
            <path
              id="パス_230-54"
              d="M1257 224l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1257 -224)"
            />
            <path
              id="パス_231-54"
              d="M1260.5 241.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1257 -231)"
            />
            <path
              id="パス_232-54"
              d="M1264 238h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1257 -227.5)"
            />
          </g>
          <g id="グループ_4-55" className="cls-6" transform="translate(0 42)">
            <path
              id="パス_227-55"
              d="M1256.5 224v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1246 -224)"
            />
            <path
              id="パス_228-55"
              d="M1249.5 224l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1242.5 -224)"
            />
            <path
              id="パス_229-55"
              d="M1242.5 224l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1239 -224)"
            />
            <path
              id="パス_230-55"
              d="M1236 224l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1236 -224)"
            />
            <path
              id="パス_231-55"
              d="M1239.5 241.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1236 -231)"
            />
            <path
              id="パス_232-55"
              d="M1243 238h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1236 -227.5)"
            />
          </g>
          <g id="グループ_4-56" className="cls-8" transform="translate(105 52.5)">
            <path
              id="パス_227-56"
              d="M1466.5 245v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1456 -245)"
            />
            <path
              id="パス_228-56"
              d="M1459.5 245l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1452.5 -245)"
            />
            <path
              id="パス_229-56"
              d="M1452.5 245l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1449 -245)"
            />
            <path
              id="パス_230-56"
              d="M1446 245l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1446 -245)"
            />
            <path
              id="パス_231-56"
              d="M1449.5 262.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1446 -252)"
            />
            <path
              id="パス_232-56"
              d="M1453 259h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1446 -248.5)"
            />
          </g>
          <g id="グループ_4-57" className="cls-8" transform="translate(94.5 52.5)">
            <path
              id="パス_227-57"
              d="M1445.5 245v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1435 -245)"
            />
            <path
              id="パス_228-57"
              d="M1438.5 245l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1431.5 -245)"
            />
            <path
              id="パス_229-57"
              d="M1431.5 245l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1428 -245)"
            />
            <path
              id="パス_230-57"
              d="M1425 245l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1425 -245)"
            />
            <path
              id="パス_231-57"
              d="M1428.5 262.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1425 -252)"
            />
            <path
              id="パス_232-57"
              d="M1432 259h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1425 -248.5)"
            />
          </g>
          <g id="グループ_4-58" transform="translate(84 52.5)">
            <path
              id="パス_227-58"
              d="M1424.5 245v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1414 -245)"
            />
            <path
              id="パス_228-58"
              d="M1417.5 245l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1410.5 -245)"
            />
            <path
              id="パス_229-58"
              d="M1410.5 245l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1407 -245)"
            />
            <path
              id="パス_230-58"
              d="M1404 245l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1404 -245)"
            />
            <path
              id="パス_231-58"
              d="M1407.5 262.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1404 -252)"
            />
            <path
              id="パス_232-58"
              d="M1411 259h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1404 -248.5)"
            />
          </g>
          <g id="グループ_4-59" transform="translate(73.5 52.5)">
            <path
              id="パス_227-59"
              d="M1403.5 245v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1393 -245)"
            />
            <path
              id="パス_228-59"
              d="M1396.5 245l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1389.5 -245)"
            />
            <path
              id="パス_229-59"
              d="M1389.5 245l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1386 -245)"
            />
            <path
              id="パス_230-59"
              d="M1383 245l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1383 -245)"
            />
            <path
              id="パス_231-59"
              d="M1386.5 262.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1383 -252)"
            />
            <path
              id="パス_232-59"
              d="M1390 259h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1383 -248.5)"
            />
          </g>
          <g id="グループ_4-60" transform="translate(63 52.5)">
            <path
              id="パス_227-60"
              d="M1382.5 245v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1372 -245)"
            />
            <path
              id="パス_228-60"
              d="M1375.5 245l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1368.5 -245)"
            />
            <path
              id="パス_229-60"
              d="M1368.5 245l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1365 -245)"
            />
            <path
              id="パス_230-60"
              d="M1362 245l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1362 -245)"
            />
            <path
              id="パス_231-60"
              d="M1365.5 262.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1362 -252)"
            />
            <path
              id="パス_232-60"
              d="M1369 259h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1362 -248.5)"
            />
          </g>
          <g id="グループ_4-61" transform="translate(52.5 52.5)">
            <path
              id="パス_227-61"
              d="M1361.5 245v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1351 -245)"
            />
            <path
              id="パス_228-61"
              d="M1354.5 245l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1347.5 -245)"
            />
            <path
              id="パス_229-61"
              d="M1347.5 245l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1344 -245)"
            />
            <path
              id="パス_230-61"
              d="M1341 245l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1341 -245)"
            />
            <path
              id="パス_231-61"
              d="M1344.5 262.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1341 -252)"
            />
            <path
              id="パス_232-61"
              d="M1348 259h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1341 -248.5)"
            />
          </g>
          <g id="グループ_4-62" transform="translate(42 52.5)">
            <path
              id="パス_227-62"
              d="M1340.5 245v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1330 -245)"
            />
            <path
              id="パス_228-62"
              d="M1333.5 245l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1326.5 -245)"
            />
            <path
              id="パス_229-62"
              d="M1326.5 245l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1323 -245)"
            />
            <path
              id="パス_230-62"
              d="M1320 245l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1320 -245)"
            />
            <path
              id="パス_231-62"
              d="M1323.5 262.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1320 -252)"
            />
            <path
              id="パス_232-62"
              d="M1327 259h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1320 -248.5)"
            />
          </g>
          <g id="グループ_4-63" className="cls-3" transform="translate(31.5 52.5)">
            <path
              id="パス_227-63"
              d="M1319.5 245v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1309 -245)"
            />
            <path
              id="パス_228-63"
              d="M1312.5 245l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1305.5 -245)"
            />
            <path
              id="パス_229-63"
              d="M1305.5 245l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1302 -245)"
            />
            <path
              id="パス_230-63"
              d="M1299 245l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1299 -245)"
            />
            <path
              id="パス_231-63"
              d="M1302.5 262.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1299 -252)"
            />
            <path
              id="パス_232-63"
              d="M1306 259h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1299 -248.5)"
            />
          </g>
          <g id="グループ_4-64" className="cls-3" transform="translate(21 52.5)">
            <path
              id="パス_227-64"
              d="M1298.5 245v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1288 -245)"
            />
            <path
              id="パス_228-64"
              d="M1291.5 245l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1284.5 -245)"
            />
            <path
              id="パス_229-64"
              d="M1284.5 245l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1281 -245)"
            />
            <path
              id="パス_230-64"
              d="M1278 245l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1278 -245)"
            />
            <path
              id="パス_231-64"
              d="M1281.5 262.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1278 -252)"
            />
            <path
              id="パス_232-64"
              d="M1285 259h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1278 -248.5)"
            />
          </g>
          <g id="グループ_4-65" className="cls-3" transform="translate(10.5 52.5)">
            <path
              id="パス_227-65"
              d="M1277.5 245v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1267 -245)"
            />
            <path
              id="パス_228-65"
              d="M1270.5 245l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1263.5 -245)"
            />
            <path
              id="パス_229-65"
              d="M1263.5 245l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1260 -245)"
            />
            <path
              id="パス_230-65"
              d="M1257 245l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1257 -245)"
            />
            <path
              id="パス_231-65"
              d="M1260.5 262.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1257 -252)"
            />
            <path
              id="パス_232-65"
              d="M1264 259h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1257 -248.5)"
            />
          </g>
          <g id="グループ_4-66" className="cls-3" transform="translate(0 52.5)">
            <path
              id="パス_227-66"
              d="M1256.5 245v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1246 -245)"
            />
            <path
              id="パス_228-66"
              d="M1249.5 245l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1242.5 -245)"
            />
            <path
              id="パス_229-66"
              d="M1242.5 245l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1239 -245)"
            />
            <path
              id="パス_230-66"
              d="M1236 245l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1236 -245)"
            />
            <path
              id="パス_231-66"
              d="M1239.5 262.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1236 -252)"
            />
            <path
              id="パス_232-66"
              d="M1243 259h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1236 -248.5)"
            />
          </g>
          <g id="グループ_457" className="cls-8" transform="translate(105 63)">
            <path
              id="パス_227-67"
              d="M1466.5 266v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1456 -266)"
            />
            <path
              id="パス_228-67"
              d="M1459.5 266l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1452.5 -266)"
            />
            <path
              id="パス_229-67"
              d="M1452.5 266l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1449 -266)"
            />
            <path
              id="パス_230-67"
              d="M1446 266l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1446 -266)"
            />
            <path
              id="パス_231-67"
              d="M1449.5 283.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1446 -273)"
            />
            <path
              id="パス_232-67"
              d="M1453 280h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1446 -269.5)"
            />
          </g>
          <g id="グループ_458" className="cls-8" transform="translate(94.5 63)">
            <path
              id="パス_227-68"
              d="M1445.5 266v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1435 -266)"
            />
            <path
              id="パス_228-68"
              d="M1438.5 266l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1431.5 -266)"
            />
            <path
              id="パス_229-68"
              d="M1431.5 266l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1428 -266)"
            />
            <path
              id="パス_230-68"
              d="M1425 266l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1425 -266)"
            />
            <path
              id="パス_231-68"
              d="M1428.5 283.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1425 -273)"
            />
            <path
              id="パス_232-68"
              d="M1432 280h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1425 -269.5)"
            />
          </g>
          <g id="グループ_459" transform="translate(84 63)">
            <path
              id="パス_227-69"
              d="M1424.5 266v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1414 -266)"
            />
            <path
              id="パス_228-69"
              d="M1417.5 266l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1410.5 -266)"
            />
            <path
              id="パス_229-69"
              d="M1410.5 266l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1407 -266)"
            />
            <path
              id="パス_230-69"
              d="M1404 266l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1404 -266)"
            />
            <path
              id="パス_231-69"
              d="M1407.5 283.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1404 -273)"
            />
            <path
              id="パス_232-69"
              d="M1411 280h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1404 -269.5)"
            />
          </g>
          <g id="グループ_460" transform="translate(73.5 63)">
            <path
              id="パス_227-70"
              d="M1403.5 266v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1393 -266)"
            />
            <path
              id="パス_228-70"
              d="M1396.5 266l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1389.5 -266)"
            />
            <path
              id="パス_229-70"
              d="M1389.5 266l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1386 -266)"
            />
            <path
              id="パス_230-70"
              d="M1383 266l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1383 -266)"
            />
            <path
              id="パス_231-70"
              d="M1386.5 283.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1383 -273)"
            />
            <path
              id="パス_232-70"
              d="M1390 280h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1383 -269.5)"
            />
          </g>
          <g id="グループ_461" transform="translate(63 63)">
            <path
              id="パス_227-71"
              d="M1382.5 266v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1372 -266)"
            />
            <path
              id="パス_228-71"
              d="M1375.5 266l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1368.5 -266)"
            />
            <path
              id="パス_229-71"
              d="M1368.5 266l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1365 -266)"
            />
            <path
              id="パス_230-71"
              d="M1362 266l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1362 -266)"
            />
            <path
              id="パス_231-71"
              d="M1365.5 283.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1362 -273)"
            />
            <path
              id="パス_232-71"
              d="M1369 280h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1362 -269.5)"
            />
          </g>
          <g id="グループ_462" transform="translate(52.5 63)">
            <path
              id="パス_227-72"
              d="M1361.5 266v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1351 -266)"
            />
            <path
              id="パス_228-72"
              d="M1354.5 266l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1347.5 -266)"
            />
            <path
              id="パス_229-72"
              d="M1347.5 266l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1344 -266)"
            />
            <path
              id="パス_230-72"
              d="M1341 266l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1341 -266)"
            />
            <path
              id="パス_231-72"
              d="M1344.5 283.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1341 -273)"
            />
            <path
              id="パス_232-72"
              d="M1348 280h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1341 -269.5)"
            />
          </g>
          <g id="グループ_463" transform="translate(42 63)">
            <path
              id="パス_227-73"
              d="M1340.5 266v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1330 -266)"
            />
            <path
              id="パス_228-73"
              d="M1333.5 266l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1326.5 -266)"
            />
            <path
              id="パス_229-73"
              d="M1326.5 266l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1323 -266)"
            />
            <path
              id="パス_230-73"
              d="M1320 266l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1320 -266)"
            />
            <path
              id="パス_231-73"
              d="M1323.5 283.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1320 -273)"
            />
            <path
              id="パス_232-73"
              d="M1327 280h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1320 -269.5)"
            />
          </g>
          <g id="グループ_464" className="cls-3" transform="translate(31.5 63)">
            <path
              id="パス_227-74"
              d="M1319.5 266v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1309 -266)"
            />
            <path
              id="パス_228-74"
              d="M1312.5 266l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1305.5 -266)"
            />
            <path
              id="パス_229-74"
              d="M1305.5 266l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1302 -266)"
            />
            <path
              id="パス_230-74"
              d="M1299 266l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1299 -266)"
            />
            <path
              id="パス_231-74"
              d="M1302.5 283.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1299 -273)"
            />
            <path
              id="パス_232-74"
              d="M1306 280h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1299 -269.5)"
            />
          </g>
          <g id="グループ_465" className="cls-3" transform="translate(21 63)">
            <path
              id="パス_227-75"
              d="M1298.5 266v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1288 -266)"
            />
            <path
              id="パス_228-75"
              d="M1291.5 266l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1284.5 -266)"
            />
            <path
              id="パス_229-75"
              d="M1284.5 266l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1281 -266)"
            />
            <path
              id="パス_230-75"
              d="M1278 266l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1278 -266)"
            />
            <path
              id="パス_231-75"
              d="M1281.5 283.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1278 -273)"
            />
            <path
              id="パス_232-75"
              d="M1285 280h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1278 -269.5)"
            />
          </g>
          <g id="グループ_466" className="cls-3" transform="translate(10.5 63)">
            <path
              id="パス_227-76"
              d="M1277.5 266v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1267 -266)"
            />
            <path
              id="パス_228-76"
              d="M1270.5 266l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1263.5 -266)"
            />
            <path
              id="パス_229-76"
              d="M1263.5 266l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1260 -266)"
            />
            <path
              id="パス_230-76"
              d="M1257 266l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1257 -266)"
            />
            <path
              id="パス_231-76"
              d="M1260.5 283.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1257 -273)"
            />
            <path
              id="パス_232-76"
              d="M1264 280h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1257 -269.5)"
            />
          </g>
          <g id="グループ_467" className="cls-3" transform="translate(0 63)">
            <path
              id="パス_227-77"
              d="M1256.5 266v.5l-.5-.5z"
              className="cls-2"
              transform="translate(-1246 -266)"
            />
            <path
              id="パス_228-77"
              d="M1249.5 266l3.5 3.5v.5l-4-4z"
              className="cls-2"
              transform="translate(-1242.5 -266)"
            />
            <path
              id="パス_229-77"
              d="M1242.5 266l7 7v.5l-7.5-7.5z"
              className="cls-2"
              transform="translate(-1239 -266)"
            />
            <path
              id="パス_230-77"
              d="M1236 266l10.5 10.5h-.5l-10-10z"
              className="cls-2"
              transform="translate(-1236 -266)"
            />
            <path
              id="パス_231-77"
              d="M1239.5 283.5h-.5l-3-3v-.5z"
              className="cls-2"
              transform="translate(-1236 -273)"
            />
            <path
              id="パス_232-77"
              d="M1243 280h-.5l-6.5-6.5v-.5z"
              className="cls-2"
              transform="translate(-1236 -269.5)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default DecorCustomization
