import React from 'react'

interface CalendarLogoProps {
  height?: string
  width?: string
}

const CalendarLogo: React.FC<CalendarLogoProps> = ({ height, width }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2812 1.875H13.1562V1.40625C13.1562 0.630844 12.5254 0 11.75 0C10.9746 0 10.3438 0.630844 10.3438 1.40625V1.875H5.65625V1.40625C5.65625 0.630844 5.02541 0 4.25 0C3.47459 0 2.84375 0.630844 2.84375 1.40625V1.875H1.71875C0.771031 1.875 0 2.64603 0 3.59375V14.2812C0 15.229 0.771031 16 1.71875 16H14.2812C15.229 16 16 15.229 16 14.2812V3.59375C16 2.64603 15.229 1.875 14.2812 1.875ZM11.2812 1.40625C11.2812 1.14778 11.4915 0.9375 11.75 0.9375C12.0085 0.9375 12.2188 1.14778 12.2188 1.40625V3.28125C12.2188 3.53972 12.0085 3.75 11.75 3.75C11.4915 3.75 11.2812 3.53972 11.2812 3.28125V1.40625ZM3.78125 1.40625C3.78125 1.14778 3.99153 0.9375 4.25 0.9375C4.50847 0.9375 4.71875 1.14778 4.71875 1.40625V3.28125C4.71875 3.53972 4.50847 3.75 4.25 3.75C3.99153 3.75 3.78125 3.53972 3.78125 3.28125V1.40625ZM0.9375 3.59375C0.9375 3.16297 1.28797 2.8125 1.71875 2.8125H2.84375V3.28125C2.84375 4.05666 3.47459 4.6875 4.25 4.6875C5.02541 4.6875 5.65625 4.05666 5.65625 3.28125V2.8125H10.3438V3.28125C10.3438 4.05666 10.9746 4.6875 11.75 4.6875C12.5254 4.6875 13.1562 4.05666 13.1562 3.28125V2.8125H14.2812C14.712 2.8125 15.0625 3.16297 15.0625 3.59375V5.6875H0.9375V3.59375ZM15.0625 14.2812C15.0625 14.712 14.712 15.0625 14.2812 15.0625H1.71875C1.28797 15.0625 0.9375 14.712 0.9375 14.2812V6.625H15.0625V14.2812Z"
        fill="#111947"
      />
    </svg>
  )
}

export default CalendarLogo
