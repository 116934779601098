import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

export const editableOptions = [
  { value: 'FBX', name: 'editable', label: 'FBX' },
  { value: 'VRM', name: 'editable', label: 'VRM' },
]

export const editOptionStyles = (isError: any = false) => ({
  control: (base: any) => ({
    ...base,
    borderColor: isError ? 'red' : '#cccccc',
    borderWidth: 1,
    borderRadius: 5,
    boxShadow: null,
    fontFamily: 'Roboto',
    fontSize: 14,
    width: '100%',
    height: 45,
    color: '#111947',
  }),
  menu: (base: any) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: 14,
    width: '100%',
    color: '#111947',
    overflow: 'hidden',
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#aaaaaa',
    paddingLeft: 8,
  }),
  singleValue: (base: any) => ({
    ...base,
    color: '#111947',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: isError ? 'red' : '#111947',
  }),
  option: (styles: any, { isFocused }: any) => ({
    ...styles,
    backgroundColor: isFocused ? '#12C4C4' : '#F1F3F8',
    color: isFocused ? '#ffffff' : '#111947',
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
})

export const fileFormatSelectorStyles = (isError: any = false) => {
  return {
    container: (base: any) => ({
      ...base,
      width: '100%',
    }),
    ...editOptionStyles(isError),
    placeholder: (base: any) => ({
      ...base,
      display: isError ? 'none' : 'inline-block',
      color: '#aaaaaa',
      transform: 'translate(0, -50%)',
    }),
    indicatorSeparator: (styles: any) => ({ display: 'none' }),
    valueContainer: (css: any, state: any) => ({
      ...css,
      input: {
        /* your styles go here for both input and dummyInput */
      },
      'input[readonly]': {
        /* your styles go here for only the dummyInput */
      },
      maxHeight: '100%',
    }),
  }
}

export const validator = (t: any) => {
  return yupResolver(
    Yup.object().shape({
      editable: Yup.object().required(t('download_avatar.select_format_error')).nullable(),
    })
  )
}

export const listCreditCards = [
  { brand: 'VISA', value: '************5168' },
  { brand: 'MasterCard', value: '************5169' },
]

export const defaultValues = {
  edit: 'Editable',
  Preview: 'PLY',
  Instant: 'FBX',
  editable: '',
  credit: '************5168',
}
