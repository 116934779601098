import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

export const radioFields = 
	{
		name: 'visa',
		type: 'radio',
		label: 'account_setting.sex_male',
		value: 'male',
		isRequire: false,
	}

export const validator = () => {
	return yupResolver(
		Yup.object().shape({	
			visa: Yup.string().required('Sex name is required.')
		})
	)
}



export const dataTest = [
	{ type: "download_avatar.type", value: 'Custom' },
	{ type: "download_avatar.file_format", value: "FBX,VRM" },
	{ type: "download_avatar.framework", value: "あり" },
	{ type: "download_avatar.status", value: "5本指" },
	{ type: "download_avatar.facial_animation", value: "ブレンドシェイプ" }
]


export const dataTest2 = [
	{ type: "download_avatar.type", value: 'Voxel' },
	{ type: "download_avatar.file_format", value: "FBX" },
	{ type: "download_avatar.framework", value: "あり" },
	{ type: "download_avatar.status", value: "なし" },
	{ type: "download_avatar.facial_animation", value: "なし" }
]

export const dataTestCreadit = [
	{ name: "VISA", number: '5186' },
	{ name: "VISA", number: '5126' }
]




export const defaultValues = {
	visa: 'visa',
}

export const selectStyles = {
	control: (base: any) => {
		return {
			...base,
			// This line disable the blue border
			borderColor: '#CCCCCC',
			borderWidth: 1,
			borderRadius: 5,
			minHeight: 48,
			boxShadow: null,
			fontFamily: 'Roboto',
			fontSize: 14,
		}
	},
	menu: (base: any) => ({
		...base,
		fontFamily: 'Roboto',
		fontSize: 14,
	}),
}
