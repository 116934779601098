import React from 'react'
import { avatariumVideo } from '../videos/export'
import classNames from 'classnames'
import { HeaderTop } from '../../../components/modules/templates'
import { LogoDark } from '../../../../../assets/images'
import PlayButton from '../svgs/play-button'
import useWindowSize from '../../../../../customize/hooks/useWindowSize'
import Video from './video'
import { breakPoint } from '../constants'
import { MdClose } from 'react-icons/md'
interface TopHeaderProps {
  isVideoEnd: boolean
  isScrollOverRequiredPixels: (arg: number) => boolean
  setIsVideoEnd: (arg: boolean) => void
  onReload: any
  scrollY?: any
}
const TopHeader: React.FC<TopHeaderProps> = ({
  isVideoEnd,
  isScrollOverRequiredPixels,
  setIsVideoEnd,
  onReload,
  scrollY,
}) => {
  const [playVideo, setPlayVideo] = React.useState(false)
  const screenSize = useWindowSize()

  const togglePlayVideo = () => {
    setPlayVideo(!playVideo)
  }

  const renderHeaderPc = (
    <div className="top__header">
      <div
        className={classNames(
          'top__header-top','top__header-top--open',
        )}
      >
      <HeaderTop onReload={onReload} />
      </div>
      <Video
        src={avatariumVideo}
        setIsVideoEnd={setIsVideoEnd}
        autoPlay={true}
        muted={true}
        controls={false}
        className="top__player"
      />
    </div>
  )

  const renderHeaderMobile = (
    <div className="top__header">
      <div
        className={classNames(
          'top__header-top','top__header-top--open',
        )}
      >
      <HeaderTop onReload={onReload} />
      </div>
      <div className="top__header-mobile">
        {playVideo ? (
          <>
            <span className="top__header-mobile-close" onClick={() => setPlayVideo(false)}>
              <MdClose size="24" color="white" />
            </span>
            <Video
              src={avatariumVideo}
              setIsVideoEnd={setIsVideoEnd}
              controls={true}
              className="top__player-mobile"
              autoPlay={true}
              muted={true}
            />
          </>
        ) : (
          <div className="top__header-mobile-wrapper">
            <div className="top__header-mobile-inner">
              <p className="top__header-mobile-description"></p>
              <span className="top__header-mobile-logo">
                <LogoDark width="28.2rem" height="4.8rem" />
              </span>
              <span className="top__header-mobile-playButton" onClick={togglePlayVideo}>
                <PlayButton />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )

  return <>{screenSize.width > breakPoint ? renderHeaderPc : renderHeaderMobile}</>
}

export default TopHeader
