import {
  heroImage1b,
  heroImage2b,
  heroImage3b,
  heroImageTap1a,
  heroImageTap2a,
  heroImageTap3a,
  heroImageHover1a,
  heroImageHover2a,
  heroImageHover3a,
} from './images/export'

export const heroImageTapList = [
  { id: 0, list: [heroImageTap1a, heroImage1b] },
  { id: 1, list: [heroImageTap2a, heroImage2b] },
  { id: 2, list: [heroImageTap3a, heroImage3b] },
]

export const heroImageHoverList = [
  { id: 0, list: [heroImageHover1a, heroImage1b] },
  { id: 1, list: [heroImageHover2a, heroImage2b] },
  { id: 2, list: [heroImageHover3a, heroImage3b] },
]

export const initialNewsData = []

export const fiveCustomizationBar = [1, 2, 3, 4, 5]
export const breakPoint = 768
