import axios from 'axios'
import { clearStorage } from '.'
import { LOGOUT } from './endpoints'
import { messaging } from './firebase'
const token = localStorage.getItem('access_token')
const headers = { 'Content-Type': 'application/json', 'Content-Language': 'JP' }
if (token) {
  headers['Authorization'] = `Bearer ${token}`
}
const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
})

instance.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem('access_token')
    if (accessToken) {
      config.headers.common['Authorization'] = 'Bearer ' + accessToken
    }
    config.headers.common['Content-Type'] = 'application/json'
    config.headers.common['Access-Control-Expose-Headers'] = 'Content-Disposition, Content-Name'
    config.headers.common['Content-Language'] =
      localStorage.getItem('i18nextLng') === 'ja' ? 'JP' : 'EN'
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (res) {
    return res
  },
  async function (err) {
    if (
      err?.request?.responseType === 'blob' &&
      err?.response?.data instanceof Blob &&
      err?.response?.data?.type &&
      err?.response?.data?.type?.toLowerCase()?.indexOf('json') !== -1
    ) {
      await new Promise((resolve, reject) => {
        let reader: any = new FileReader()
        reader.onload = () => {
          err.response.data = JSON.parse(reader.result)
          resolve(Promise.reject(err))
        }

        reader.onerror = () => {
          reject(err)
        }

        reader.readAsText(err?.response?.data)
      })
      .catch((error: any) => {
        throw error?.response?.data
      })
    } else if (err?.response?.status === 401) {
      if (messaging && messaging.deleteToken) messaging.deleteToken()
      if (localStorage.getItem('access_token')) {
        await axios.get(LOGOUT, {
          params: { device_token: localStorage.getItem('device_token') },
        })
      }
      clearStorage()
    } else {
      let data = err?.response?.data
      let error = new Error()
      if (data) {
        error.message = data?.message?.replace('\\n', '\n')
        error.name = data?.message_code
      } else {
        let errorMessage = localStorage.getItem('i18nextLng') === 'ja'
          ? `ページを表示できません。\nネットワーク接続を確認してください。`
          : `This page cannot be displayed. \nPlease check your network connection.`
        error.name = 'S000001'
        error.message = errorMessage
      }
      throw error
    }
  }
)
export default instance
