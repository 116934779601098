import React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChangeArticleIcon } from '../../../../../assets/images'
import { placeholderImage } from '../../../../../assets/images'
import moment from 'moment'
interface SecondaryContentComponentProps {
  id: number
  date: string
  title: string
  thumbnail: string
  onDirect: Function
}
const SecondaryContentComponent: React.FC<SecondaryContentComponentProps> = ({
  id,
  date,
  title,
  thumbnail,
  onDirect,
}) => {
  const [, i18n] = useTranslation()

  const direct = (id: any) => () => {
    onDirect(id)
  }

  return (
    <div className="detail-news__item">
      <div onClick={direct(id)}>
        <div>
          <div className="detail-news__thumb">
            <ImageDetail thumbnail={thumbnail} />
          </div>
          <div className="detail-news__content">
            <p className="detail-news__other-article-date">
              {localStorage.getItem('i18nextLng') === 'ja'
                ? moment(new Date(date).toISOString()).format('YYYY年MM月DD日')
                : moment(new Date(date).toISOString()).format('MM/DD/YYYY')}
            </p>
            <h3 className="detail-news__ttle">{title}</h3>
            <span className="text-link">
              {i18n.t('detail-news.read-article')}
              <ChangeArticleIcon />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

function ImageDetail({ thumbnail }: any) {
  const [img, setImg] = useState<any>()

  useEffect(() => {
    setImg(thumbnail)
  }, [thumbnail])

  return (
    <img
      className="detail-news__other-article-hero"
      onError={() => setImg(placeholderImage)}
      src={img}
      alt="secondary-hero"
      width={40}
      height={40}
    />
  )
}

export default SecondaryContentComponent
