import React, { useEffect, useState } from 'react'
import { NotificationIcon } from '../../../../assets/images/svgs/'
import NotificationComponent from '../../components/modules/notification'
import { useTranslation } from 'react-i18next'
import Wrapper from '../../components/modules/_wrapper'
import { GET_NOTI } from '../../../../utils/endpoints'
import { ERROR_TYPE, GET, ROUTER_URL } from '../../../../utils/const'
import { useHistory } from 'react-router-dom'
import { onMessageListener } from '../../../../utils/firebase'

interface NotificationProps {
  notify?: Function
  callApi: any
}
export const Notifications: React.FC<NotificationProps> = ({ notify = () => {}, callApi }) => {
  const history = useHistory()
  const [listNotification, setListNotification] = useState([])
  const [, i18n] = useTranslation('translation')

  const list = [
    {
      name: i18n.t('notifications.bread_crumb_home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('notifications.bread_crumb_list'),
      link: ROUTER_URL.NOTIFICATIONS,
    },
  ]

  onMessageListener().then((payload: any) => {
    if (payload) {
      fetchListNoti()
    }
  })

  function isStateChange() {
    let state = document.visibilityState
    if (state === 'visible') fetchListNoti()
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', isStateChange, true)
    return () => document.removeEventListener('visibilitychange', isStateChange, true)
  }, [])

  useEffect(() => {
    fetchListNoti()
  }, [localStorage.getItem('i18nextLng')])

  const fetchListNoti = async () => {
    const params = {
      page: '1',
      per_page: '100',
    }
    const token = localStorage.getItem('access_token')
    if (token) {
      try {
        const result = await callApi(GET_NOTI, GET, { params })
        if (result) {
          setListNotification(result.data.data.list_notify)
        }
      } catch (error: any) {
        notify(error?.message, ERROR_TYPE)
      }
    }
  }

  const onDirect = (id: any, friend_id?: number) => () => {
    if (window.navigator.onLine) {
      history.push(friend_id ? `${ROUTER_URL.PROFILE}/${friend_id}` : `${ROUTER_URL.DETAIL_NOTIFICATION}/${id}`)
    } else {
      notify(i18n.t('notifications.err_network'), ERROR_TYPE)
    }
  }

  return (
    <Wrapper
      Icon={NotificationIcon}
      title={i18n.t('notifications.title_header')}
      breadcrum={list}
      className="wrap"
      buttonLink={ROUTER_URL.SCAN_BOOKING}
      buttonText={i18n.t('notifications.button_list')}
      classNameBtn="distance-line-content-to-btn"
      //isNoMarginTop={true}
    >
      <div className="noti-wrap">
        <div className="noti-wrap_content">
          {listNotification.length !== 0 ? (
            listNotification.map((item: any, index) => {
              return (
                <div onClick={onDirect(item.id, item.friend_id)} key={index}>
                  <NotificationComponent
                    textTitle={item.title}
                    date={item.notify_time}
                    textContent={item.message}
                    status={item.status}
                    key={index}
                    callApi={callApi}
                    first={index === 0 ? true : false}
                    friend_id={item.friend_id}
                    friend_status={item.friend_status}
                    getNotisDB={fetchListNoti}
                  />
                </div>
              )
            })
          ) : (
            <div className="no-notification">
              {i18n.t('notifications.null')}
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default Notifications
