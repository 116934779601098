import { useTranslation } from 'react-i18next'
import Wrapper from '../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../utils/const'
import QuestionIcon from "../../../../assets/images/svgs/question-icon";
import {useHistory} from "react-router-dom";
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface HelpIndexProps {}

const iconStyle: React.CSSProperties = { padding: 10, fontSize: 50 };

const HelpIndex: React.FC<HelpIndexProps> = () => {

    const title = 'ヘルプ | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
    const description = 'ヘルプについてのページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
    const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
    const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

    // title
    document.title = title;

    // title以外のmeta
    const headData = document.head.children;

    for (let i = 0; i < headData.length; i++) {
        const nameVal = headData[i].getAttribute('name');
        const propertyVal = headData[i].getAttribute('property');
        if (nameVal !== null) {
            if (nameVal.indexOf('keywords') !== -1) {
                headData[i].setAttribute('content', keywords);
            }
            if (nameVal.indexOf('description') !== -1) {
                headData[i].setAttribute('content', description);
            }
        }
        if (propertyVal !== null) {
            // OGP(og:title)の設定
            if (propertyVal.indexOf('og:title') !== -1) {
                headData[i].setAttribute('content', title);
            }
            if (propertyVal.indexOf('og:description') !== -1) {
                headData[i].setAttribute('content', description);
            }
            if (propertyVal.indexOf('og:image') !== -1) {
                headData[i].setAttribute('content', og_image);
            }
        }
    }
    
    const [t] = useTranslation('translation')

    const history = useHistory()

    const onGoTo = (path: any) => () => {
        history.push(path)
    }
    const list = [
        {
            name: t('help.home'),
            link: ROUTER_URL.HOME,
        },
        {
            name: t('help.index.breadcrum'),
            link: ROUTER_URL.HELP_INDEX,
        },
    ]
    return (
        <Wrapper
            breadcrum={list}
            title={t('help.index.title')}
            className="help"
            Icon={QuestionIcon}
            classNameBtn={'normal'}
        >

            <div className="wrapper__content">
                <div className="help-wrap_box distance-content-to-button">
                    <ul className="help_menu">
                        <li>
                            <a onClick={onGoTo(ROUTER_URL.HELP_QA)} style={{ color: '#12c4c4' }}>よくある質問<FontAwesomeIcon style={iconStyle} icon={faChevronRight} /></a>
                        </li>
                        <li>
                            <a onClick={onGoTo(ROUTER_URL.HELP_ABOUT_AVATARIUM)} style={{ color: '#12c4c4' }}>AVATARIUMについて<FontAwesomeIcon style={iconStyle} icon={faChevronRight} /></a>
                        </li>
                        <li>
                            <a onClick={onGoTo(ROUTER_URL.HELP_HOWTO)} style={{ color: '#12c4c4' }}>ご利用について<FontAwesomeIcon style={iconStyle} icon={faChevronRight} /></a>
                        </li>
                        <li>
                            <a onClick={onGoTo(ROUTER_URL.HELP_FLOW_SCANNER)} style={{ color: '#12c4c4' }}>アバター作成方法について<FontAwesomeIcon style={iconStyle} icon={faChevronRight} /></a>
                        </li>
                        <li>
                            <a onClick={onGoTo(ROUTER_URL.HELP_EXPORT)} style={{ color: '#12c4c4' }}>エクスポートについて<FontAwesomeIcon style={iconStyle} icon={faChevronRight} /></a>
                        </li>
                    </ul>
                </div>
                {/*<!-- /help-wrap_box -->*/}
            </div>
            {/*<!-- /wrapper__content -->*/}
        </Wrapper>
    )
}
export default HelpIndex
