import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { TextInput } from '../../../components/atoms/text-input'
import { Button } from '../../../components/atoms/buttons'
import { SelectInput3 } from '../../../components/atoms/select-input-3'
import { Modal } from '../../../components/modules/modal'
import AvatarDetailComponent from '../../../components/modules/control-avatar/detail'
import {
  defaultValues,
  editableOptions,
  fileFormatSelectorStyles,
  validator,
} from './constants'
import classNames from 'classnames'
import { useTranslation, Trans } from 'react-i18next'
import useWindowSize from '../../../../../customize/hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import { CloseIcon, AddIcon, WarningIcon } from '../../../../../assets/images'
import { IApplicationState } from '../../../../stores/states'
import {
  AVATAR_PRICE,
  AVATAR_TYPE,
  INFO_CREADIT,
  DOWNLOAD_AVATAR,
} from '../../../../../utils/endpoints'
import { CreaditTypes } from '../../../../stores/actions/saveCreadit'
import { DownloadTypes } from '../../../../stores/actions/download-avatar'
import { saveBlobAsFile } from '../../../../../utils/file'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import Wrapper from '../../../components/modules/_wrapper'
import DownloadModal2 from './../download-modal-2'
import { EDITABLE, ERROR_TYPE, GET, MOBILE, ORIGINAL, ROUTER_URL } from '../../../../../utils/const'
import { GET_AVATAR_DETAIL } from '../../../../../utils/endpoints'
import CreditModal from '../credit-modal'
import { RadioCheck, RadioUnCheck } from '../../../../../assets/images/svgs/radio-icon'
import axios from '../../../../../utils/axios'

interface DownloadAvatarUserProps {
  notify?: Function
  callApi: any
}

const editType = EDITABLE
const DownloadAvatarUser: React.FC<DownloadAvatarUserProps> = ({ notify = () => {}, callApi }) => {
  const listContentDownload = useSelector((state: IApplicationState) => state.download.data)
  const listCreadit = useSelector((state: IApplicationState) => state.creadit.data)
  const userData = useSelector((state: any) => state.signin.userData)

  const [cardId, setCardId] = useState('')
  const [t] = useTranslation()
  const [price, setPrice] = useState(0)
  const location: any = useLocation()
  const history: any = useHistory()
  const size = useWindowSize()
  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState(false)
  const [dataAvatar, setDataAvatar] = useState<any>()
  const [imageAvatar, setImageAvatar] = useState('')
  const [showModalCredit, setShowModalCredit] = useState(false)
  const [numberCard, setNumberCard] = useState('')
  const [defaultNumberCard, setDefaultNumberCard] = useState('')
  const [nameCardBand, setNameCardBand] = useState('')
  const [defaultCardBand, setDefaultCardBand] = useState('')
  const [isDownloaded, setIsDownloaded] = useState(false)

  const params: any = useParams()

  const methods = useForm({
    defaultValues,
    resolver: validator(t),
  })
  

  const getValueCard = (cardName: any, numberCard: any) => {
    setNameCardBand(cardName)
    setNumberCard(numberCard)
  }
  const setAvatarImage = (url: string) => {
    axios.get(url, {responseType: 'blob',}).then(res => {
      setImageAvatar(URL.createObjectURL(res?.data))
    })
  }
  useEffect(() => {
    renderFileFormatSelect(editType)
  }, [editType])

  const { handleSubmit, errors, setValue, getValues } = methods
  const isErrorFormat = !!errors?.editable

  const toggleModal = () => {
    setShowModal((showModal) => !showModal)
  }
  const toggleModalCredit = () => {
    setShowModalCredit((showModalCredit) => !showModalCredit)
  }

  useEffect(() => {
    getInfoCreadit()
    getDetailAvatar()
  }, [])
  const getInfoCreadit = async () => {
    try {
      const result = await callApi(INFO_CREADIT)
      if (result.status === 200) {
        for (let card of result.data.data) {
          if (card.card_default) {
            setCardId(card.card_id)
            setDefaultNumberCard(card.card_number)
            setDefaultCardBand(card.card_brand)
          }
        }
        dispatch({ type: CreaditTypes.SAVE_INFO_CREADIT, payload: result.data.data })
      }
    } catch (error) {
    }
  }
  useEffect(() => {
    const getListDownloadInfo = async () => {
      try {
        const id = location?.state?.id
        const param = {
          avatar_type: ORIGINAL,
          avatar_id: id || params?.id,
        }
        const result = await callApi(AVATAR_TYPE, GET, { params: param })
        if (result.status === 200) {
          dispatch({ type: DownloadTypes.GET_LIST_PLY, payload: result.data.data })
        }
      } catch (error) {
        notify(error?.message, ERROR_TYPE)
      }
    }
    getListDownloadInfo()
  }, [localStorage.getItem('i18nextLng')])

  useEffect(() => {
    const getPrice = async () => {
      try {
        const result = await callApi(AVATAR_PRICE)
        if (result.status === 200) {
          const price = result.data.data
          setPrice(price)
        }
      } catch (error) {
      }
    }
    getPrice()
  }, [])

  async function getDetailAvatar() {
    const { id } = params
    if (!id) {
      history.push(ROUTER_URL.CONTROL_AVATAR)
    }
    const param = {
      avatar_type: ORIGINAL,
      avatar_id: id,
      model_type: location?.state?.model_type? location.state.model_type: params['model_type']
    }
    try {
      const result = await callApi(GET_AVATAR_DETAIL, GET, { params: param })
      if (result?.status === 200) {
        setDataAvatar(result?.data?.data?.avatar)
        setAvatarImage(result?.data?.data?.avatar.s3_path)
      }
    } catch (err) {
      history.push(ROUTER_URL.CONTROL_AVATAR)
      notify(t('detail-avatar.no_avatar_origin'), ERROR_TYPE)
    }
  }

  function onValidate() {
    toggleModal()
  }

  const handleDownLoadAvatar = async () => {
    toggleModal()
    let params
    try {
      let file_format = ''
      if (editType === EDITABLE) {
        const editable = getValues('editable')
        file_format = editable?.value
      }

      params = {
        avatar_type: ORIGINAL,
        file_format: file_format,
        edit_type: editType,
        card_id: cardId,
        avatar_id: location?.state?.id,
      }
     
      const result = await callApi(DOWNLOAD_AVATAR, GET, { params, responseType: 'blob' })

      if (result.status === 200) {
        saveBlobAsFile(result.data, result.headers['content-disposition'].split('=').pop())
        setIsDownloaded(true)
      }
    } catch (error) {
      if (error) notify(error?.message, ERROR_TYPE)
    }
  }

  const list = [
    {
      name: t('download_avatar.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('download_avatar.avatar_management'),
      link: ROUTER_URL.CONTROL_AVATAR,
    },
    {
      name: t('download_avatar.avatar_details'),
      link: `${ROUTER_URL.CONTROL_AVATAR}/${params['model_type']}/${location?.state?.id}`,
    },
    {
      name: t('download_avatar.download'),
      link: ROUTER_URL.DOWNLOAD_AVATAR,
    },
  ]

  const renderSidebarArea = (
    <div className="download-avatar-user__sidebar-area">
      <AvatarDetailComponent image={imageAvatar} />
    </div>
  )

  const renderFileFormatSelect = (editType: string) => {
    switch (editType) {
      case EDITABLE:
        return (
          <SelectInput3
            name="editable"
            styles={fileFormatSelectorStyles(isErrorFormat)}
            options={editableOptions}
            placeholder={t('download_avatar.select_format')}
          />
        )
      case 'Preview':
        return (
          <>
            <TextInput name="Preview" isRequire={true} isDisabled={true} />
          </>
        )
      case 'Instant':
        return (
          <>
            <span>
              <TextInput name="Instant" isRequire={true} isDisabled={true} />
            </span>
          </>
        )

      default:
        return null
    }
  }

  useEffect(() => {
    if (editType !== EDITABLE)
      setValue(EDITABLE, { value: 'fbx', name: 'editable', label: 'FBX' })
    else setValue(EDITABLE, '')
  }, [editType])

  const renderAvatarInfo = (
    <div className="download-avatar-user__avatar-info">
      <div className="download-avatar-user__avatar-name">
        <p className="title-h4 distance-h4-to-table">{location?.state?.name}</p>
        {listContentDownload &&
          listContentDownload
            .filter((item: any) => {
              return item.avatar_type === editType
            })
            .map((item: any) => (
              <div className="download-avatar-user__avatar-table">
                <div className="download-avatar-user__avatar-table__group">
                  <div className="download-avatar-user__avatar-table__group-th">
                    {t('download_avatar.type')}
                  </div>
                  <div className="download-avatar-user__avatar-table__group-td">
                    {item.avatar_type}
                  </div>
                </div>
                <div className="download-avatar-user__avatar-table__group">
                  <div className="download-avatar-user__avatar-table__group-th">
                    {t('download_avatar.file_format')}
                  </div>
                  <div className="download-avatar-user__avatar-table__group-td">
                    {item.file_fmt}
                  </div>
                </div>
                <div className="download-avatar-user__avatar-table__group">
                  <div className="download-avatar-user__avatar-table__group-th">
                    {t('download_avatar.framework')}
                  </div>
                  <div className="download-avatar-user__avatar-table__group-td">
                    {item.has_bone}
                  </div>
                </div>
                <div className="download-avatar-user__avatar-table__group">
                  <div className="download-avatar-user__avatar-table__group-th">
                    {t('download_avatar.status')}
                  </div>
                  <div className="download-avatar-user__avatar-table__group-td">
                    {item.finger_shape}
                  </div>
                </div>
                <div className="download-avatar-user__avatar-table__group">
                  <div className="download-avatar-user__avatar-table__group-th">
                    {t('download_avatar.facial_animation')}
                  </div>
                  <div className="download-avatar-user__avatar-table__group-td download-avatar-user__avatar-table__group-td1">
                    {item.facial_anim}
                  </div>
                </div>
              </div>
            ))}
      </div>
      <div className="download-avatar-user__file-format">
        <p className="download-avatar-user__file-format-label title-h4">
          {t('download_avatar.file_format_selection')}
        </p>
        <div className="download-avatar-user__file-format-select">
          {renderFileFormatSelect(editType)}
          <div style={{ display: 'none' }}>
            <SelectInput3
              name="editable"
              styles={fileFormatSelectorStyles(isErrorFormat)}
              options={editableOptions}
              placeholder={t('download_avatar.select_format')}
            />
          </div>
        </div>
      </div>
      <div className="download-avatar-user__price-area distance-line-to-content">
        <p className="download-avatar-user__price-label">{t('download_avatar.download_fee')}</p>
        <div className="download-avatar-user__price">
          {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(
            Number(price)
          )}
        </div>
      </div>
    </div>
  )

  const renderAvatarCredit = (
    <div className="download-avatar-user__credit-info">
      <h2 className="download-avatar-user__credit-label">
        {t('download_avatar.credit_information')}
      </h2>
      <div className="download-avatar-user__credit-describe">
        {t('download_avatar.credit_describe')}
      </div>
      <div className="download-avatar-user__credit-list">
        {listCreadit &&
          listCreadit.map((card: any) => (
            <div
              key={card.card_id}
              className={classNames('download-avatar-user__credit-bar', {
                'credit-active': card['card_default'] === true,
              })}
            >
              <div className="download-avatar-user__credit-couple">
                <div
                  className={classNames({ 'credit-active': card['card_default'] === true })}
                  onClick={() => {
                    setCardId(card.card_id)
                    getValueCard(card['card_brand'], card['card_number'])
                  }}
                >
                  {card.card_id === cardId ? <RadioCheck /> : <RadioUnCheck />}
                </div>
                <label className="download-avatar-user__credit-brand">{card.card_brand}</label>
              </div>
              {size.width < 576 ? (
                <div className="download-avatar-user__credit-series">
                </div>
              ) : (
                <span className="download-avatar-user__credit-series" style={{ fontSize: '14px' }}>
                  <span>**** - **** - **** - {card.card_number.slice(12, 16)}</span>
                </span>
              )}
            </div>
          ))}
        <div className="download-avatar-user__credit-add" onClick={toggleModalCredit}>
          <AddIcon />
          <div className="download-avatar-user__credit-add-content">
            {t('download_avatar.add_card')}
          </div>
        </div>
      </div>
    </div>
  )

  function toggleDownloaded() {
    setIsDownloaded((prevState: any) => !prevState)
  }

  return (
    <Wrapper
      breadcrum={list}
      title={t('download_avatar.title')}
      className="download-avatar-user__container default-padding"
      type="dropdown"
    >
      <Modal
        modalStyles="booking__modal-booking padding-modal-in-h2"
        show={showModalCredit}
        toggleModal={toggleModalCredit}
      >
        <CreditModal
          toggleModal={toggleModalCredit}
          detailListCard={getInfoCreadit}
          callApi={callApi}
          notify={notify}
        />
      </Modal>
      <Modal
        modalStyles="booking__modal-booking padding-modal-in-h2"
        show={isDownloaded}
        toggleModal={toggleDownloaded}
      >
        <div className="detail-avatar__delete-modal">
          <div className="detail-avatar__delete-modal_header distance-h2-to-line">
            <div className="detail-avatar__delete-modal_header-text">
              <div className="pop-text-bold-big">{t('download_avatar.modal_download.title')}</div>
            </div>
            <button
              className="detail-avatar__delete-modal_header-icon close-icon"
              onClick={toggleDownloaded}             
            >
              <CloseIcon />
            </button>
          </div>
          <div className="box-notification distance-line-to-text">
            <div className="download-message">
              <Trans
                i18nKey="download_avatar.modal_download.content" // optional -> fallbacks to defaults if not provided
                values={{ email: userData?.mail_address }}
                components={{ span: <span /> }}
              />
            </div>
            <div className="download-notification">
              <div className="download-notification__title">
                <WarningIcon /> {t('active_email_update.content1')}
              </div>
              <div className="download-notification__content">
                <p>※1 {t('active_email_update.content2')}</p>
                <p>※2 {t('active_email_update.content3')}</p>
                <br />
                <p>
                  <Trans
                    i18nKey="active_email_update.content7"
                    components={{
                      span: (
                        <span className="text-link" onClick={() => history.push(ROUTER_URL.CONTACT)} />
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="detail-avatar__delete-modal_button">
            <Button onClick={toggleDownloaded} className="normal">
              {t('detail-avatar.delete_btn2')}
            </Button>
          </div>
        </div>
      </Modal>
      <div className="download-avatar-user__inner-container">
        <Modal
          modalStyles="booking__modal-booking pading-modal-in-h2"
          show={showModal}
          toggleModal={toggleModal}
        >
          <DownloadModal2
            price={price}
            toggleModal={toggleModal}
            dowloadAvatar={handleDownLoadAvatar}
            cardNumber={numberCard}
            cardNumberDefault={defaultNumberCard}
            cardBand={nameCardBand}
            cardBandDefault={defaultCardBand}
          />
        </Modal>
        {renderSidebarArea}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onValidate)}>
            <div className="download-avatar-user__content">
              {renderAvatarInfo}
              {renderAvatarCredit}
              <div className="download-avatar-user__submit">
                <Button
                  disabled={!cardId || dataAvatar.model_type===MOBILE}
                  type="submit"
                  className="download-avatar-user__submit-button"
                >
                  {t('download_avatar.download')}
                </Button>
                <span className="download-avatar-user__submit-spinner"></span>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </Wrapper>
  )
}

export default DownloadAvatarUser
