export const REGISTER = `/register-user`
export const LOGIN = '/customer/login'
export const LOGOUT = '/customer/logout'
export const ADDRESS_SCANNER = '/scanner/address-list'
export const TIME_BOOKING = '/reservation/time-available'
export const HISTORY_BOOKING = '/reservation/history'
export const CREATE_BOOKING = '/reservation/create'
export const CHECK_RESERVED = '/reservation/check-reserved'
export const CHECK_SCANNED = '/reservation/check-scanned'
export const REFRESH_TOKEN = '/customer/refresh'
export const FORGET_PASSWORD = '/send-mail-reset-pw'
export const RESET_PASSWORD = '/reset-password'
export const CONFIRM_EMAIL = '/confirm-email'
export const UPDATE_INFO = '/customer/update-info'
export const DELETE_ACCOUNT = '/customer/delete-account'
export const DETAIL_ACCOUNT = '/customer/detail-account'
export const CANCEL_BOOKING = '/reservation/cancel'
export const GET_LIST_KUBUN = '/scanner/country-available'
export const GET_COUNTRY = '/scanner/country-available'
export const GET_SCANNER_BY_COUNTRY = '/scanner/list'
export const GET_LIST_KUBUN_BY_NAME = '/get-list-kubun'
export const SEND_CONTACT = '/contact'
export const HOUR_AVAILABLE = '/reservation/hour-available'
export const GET_SCANNER_STATUS = '/scanner/status'
export const GET_IS_VALUE_TOKEN = '/is-valid-token'
export const CHANGE_CUSTOMER_EMAIL = '/customer/change-customer-email'
export const REGISTER_DEVICE = '/register-device'
export const GET_NOTI = '/notify/list'
export const CHANGE_PASSWORD = '/customer/change-password'

// イベント用のメールアドレスのみの仮ユーザを登録します。
export const CREATE_PROVISIONAL_USER = '/provisional-user/create'
export const CONFIRM_PROVISIONAL_USER = '/provisional-user/confirm'

export const GET_LIST_AVATAR = '/avatar/list'
export const GET_AVATAR_DETAIL = '/avatar/detail'
export const RENAME_USER = '/avatar/rename'
export const DELETE_AVATAR = '/avatar/delete'
export const AVATAR_PRICE = '/avatar/price'
export const AVATAR_TYPE = '/avatar/avatar-type'
export const CONVERT_AVATAR = '/avatar/convert-edit-data'
export const DOWNLOAD_AVATAR = '/avatar/download'
export const RE_DOWNLOAD_AVATAR = '/avatar/re-download'
export const SHARE_AVATAR = '/avatar/share'
export const INFO_CREADIT = '/payment/checkout'
export const CARD_DEFAULT = '/payment/card-default'
export const DELETE_CARD = '/payment/delete'
export const ADD_PAYMENT_CARD = '/payment/add'

export const GET_LIST_FRIENDS = '/friend/list'
export const REJECT_REQUEST_FRIEND = '/friend/reject-request'
export const REMOVE_FRIEND = '/friend/remove'
export const REMOVE_REQUEST_FRIEND = '/friend/remove-sent-request'
export const SEND_REQUEST_FRIEND = '/friend/send-request'
export const ACCEPT_REQUEST_FRIEND = '/friend/accept-request'
export const COUNT_FRIEND = '/friend/count'

export const NEWS_HOME = '/news/home'
export const NEWS_LIST = '/news/list'
export const NEWS_DETAIL = '/news/detail'
export const NEWS_RECOMMEND = '/news/recommend'

export const GET_NOTI_DETAIL = '/notify/detail'

export const PLAN_AVAILABLE = '/plan/available'
export const PLAN_MEMBERSHIP = '/plan/change-membership-plan'
export const CHECK_MEMBERSHIP = '/plan/check-membership-plan'
export const GET_CAMPAIGN_LIST = '/plan/get-campaign-event-list'

export const EDIT_USER_DETAIL = '/share/detail'
export const GET_PROFILE_IMAGE = '/customer/get-profile-image'
export const EDIT_PROFILE_IMAGE = '/customer/edit-profile-image'

export const PROFILE_CUSTOMER = '/friend/profile'
export const PROFILE_AVATAR_LIST = '/friend/avatar-list'
export const PROFILE_FRIEND_LIST = '/friend/friend-list'

export const REGISTER_EXPORT_TOKEN = '/export/register-token'
export const EXPORT_AVATAR = '/export/export-avatar'
export const IS_EXPORTED = '/export/is-exported'
export const GET_REGISTER_TOKEN= '/export/get-register-token'

export const SOCIAL_LOGIN='/social'

export const GET_DEFAULT_LANGUAGE= '/customer/get-default-language'
export const UPDATE_DEFAULT_LANGUAGE= '/customer/update-default-language'