import * as React from 'react'

interface AvatarIconReverseSortProps {
  height?: string
  width?: string
}
const AvatarIconReverseSort: React.FC<AvatarIconReverseSortProps> = ({ height, width }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 13L20 9L16 13"
        stroke="#9496AB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M20 9L20 24" stroke="#9496AB" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M12.3588 17.5439L14.6877 23.5001H14.0251L13.5835 22.186L13.469 21.8452H13.1095H10.3105H9.95105L9.83655 22.186L9.39494 23.5001H8.73154L11.0532 17.5439H11.3479L11.229 17.9626C11.1182 18.353 10.7924 19.3421 10.2444 20.9476L10.0186 21.6091H10.7176H12.7209H13.4199L13.194 20.9476L12.3264 18.4068C12.263 18.213 12.2202 18.0708 12.1928 17.9692L12.0783 17.5439H12.3588Z"
        fill="#9496AB"
        stroke="#9496AB"
      />
      <path
        d="M10.7884 14.2346H14.0668V14.452H9.35352V14.1664L12.9305 9.52243L13.5506 8.71733H12.5343H9.45337V8.5H13.9669V8.78089L10.392 13.4299L9.77319 14.2346H10.7884Z"
        fill="#9496AB"
        stroke="#9496AB"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="4.5" stroke="#9496AB" />
    </svg>
  )
}

export default AvatarIconReverseSort
