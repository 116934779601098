import React from 'react'
import { Button } from '../../../../components/atoms/buttons/index'
import { Modal } from '../../../../components/modules/modal'
import { WithDrawIcon } from '../../../../../../assets/images/svgs'
import { Trans, useTranslation } from 'react-i18next'
import { dataTest } from './constants'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { DELETE_ACCOUNT } from '../../../../../../utils/endpoints'
import Wrapper from '../../../../components/modules/_wrapper'
import ConfirmModal from './confirm-modal'
import { clearStorage } from '../../../../../../utils'
import { checkSignin } from '../../../../../stores'
import { DELETE, ROUTER_URL } from '../../../../../../utils/const'

interface UserStatusProps {
  callApi: any
}

const WithDraw: React.FC<UserStatusProps> = ({ callApi }) => {

  const title = '退会 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = '退会のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [, i18n] = useTranslation('translation')
  const history = useHistory()
  const dispatch = useDispatch()

  const [showConfirmModal, setShowConfirmModal] = React.useState(false)
  const { state }: any = useLocation()

  const toggleConfirmModal = () => {
    setShowConfirmModal((showConfirmModal) => !showConfirmModal)
  }

  const onWithDraw = async () => {
    try {
      await callApi(DELETE_ACCOUNT, DELETE, null)
      dispatch(checkSignin(false))
      clearStorage()
      history.push({
        pathname: ROUTER_URL.WITHDRAW_NOTI,
        state: {
          email: state['email'],
        },
      })
    } catch (error) {
      clearStorage()
    }
  }
  const renderContent = dataTest.map(({ title, content }, i) => {
    return (
      <div className="wrap-withdraw_content distance-content-to-line" key={i}>
        <h3
          className={
            i === 0
              ? 'wrap-withdraw_content-title title-h3 distance-h3-to-text'
              : 'wrap-withdraw_content-title title-h3 distance-h3-to-text distance-text-to-h3'
          }
          style={{ marginLeft: 0 }}
        >
          {i18n.t(title)}
        </h3>
        <div className="withdraw-text large-content-text">
          <Trans
            i18nKey={content}
            components={{
              br: <br />,
            }}
          />
        </div>
      </div>
    )
  })

  const renderListRequestButton = (
    <div className="button-register">
      <Button
        className="button-custom cancel cancel-button shortnormal"
        type="submit"
        onClick={toggleConfirmModal}
        style={{ fontSize: '1.4rem' }}
      >
        {i18n.t('withdraw.btn_withdraw')}
      </Button>
      <Link to={ROUTER_URL.SCAN_BOOKING} style={{ textDecoration: 'none' }}>
        <Button className="button-custom shortnormal" type="button" style={{ fontSize: '1.4rem' }}>
          {i18n.t('withdraw.btn_return')}
        </Button>
      </Link>
    </div>
  )
  const getAppleURL = () => {
    const currentLang = localStorage.getItem('i18nextLng') || i18n.language
    if (currentLang === 'en') return 'https://support.apple.com/en-us/HT202039'
    return 'https://support.apple.com/ja-jp/HT202039'
  }

  const list = [
    {
      name: i18n.t('withdraw.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('withdraw.scan_reservation'),
      link: ROUTER_URL.SETTINGS,
    },
    {
      name: i18n.t('withdraw.bread_crumb_active'),
      link: ROUTER_URL.WITHDRAW,
    },
  ]
  return (
    <Wrapper
      breadcrum={list}
      Icon={WithDrawIcon}
      title={i18n.t('withdraw.title')}
      className="wrap-withdraw "
      isNoMarginTop={true}
    >
      <Modal
        modalStyles="booking__modal-booking padding-modal-in-h2"
        show={showConfirmModal}
        toggleModal={toggleConfirmModal}
      >
        <ConfirmModal toggleModal={toggleConfirmModal} deleteAccount={onWithDraw} />
      </Modal>
      {renderContent}

      <div className="wrap-withdraw_content distance-content-to-line">
        <h3 className="wrap-withdraw_content-title title-h3 distance-h3-to-text distance-text-to-h3">
          {i18n.t('withdraw.about_phone_paid')}
        </h3>
        <div className="withdraw-text large-content-text">
          <div className="wrap-withdraw_content distance-content-to-line">
            <h4>{i18n.t('withdraw.about_apple_phone_paid')}</h4>
            <p className="indent">
              <Trans 
                i18nKey="withdraw.about_apple_phone_paid_message"
                components={{
                  br: <br />,
                  a: <a className="text-link" href={getAppleURL()} target="_blank" rel="noopener noreferrer" />,
                }}
              />
            </p>
          </div>
          <div className="wrap-withdraw_content">
            <h4>{i18n.t('withdraw.about_google_phone_paid')}</h4>
            <p className="indent">
              <Trans 
                i18nKey="withdraw.about_google_phone_paid_message"
                components={{
                  br: <br />,
                  a: <a className="text-link" href="https://support.google.com/googleplay/answer/7018481" target="_blank" rel="noopener noreferrer" />,
                }}
              />
            </p>
          </div>
        </div>
      </div>


      <div className="wrap-withdraw_content">
        <div className="bottom_tx center">
          <p><b>
          <Trans
            i18nKey="withdraw.title_confirm"
            components={{
              br1: <br className="spb" />,
              br: <br />,
            }}
          />
          </b></p>
        </div>

        <div className="wrap-withdraw_content_button">{renderListRequestButton}</div>
      </div>
    </Wrapper>
  )
}

export default WithDraw
