import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const AvatarNoData: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.667 5.89383e-06H3.31703C2.88065 -0.000819567 2.44846 0.0850705 2.04555 0.252688C1.64265 0.420305 1.27706 0.666307 0.970033 0.976406C0.663004 1.28651 0.420653 1.65452 0.257053 2.05907C0.093453 2.46363 0.0118667 2.89666 0.0170341 3.33301L3.39101e-05 26.667C-0.00194128 27.1039 0.0823896 27.5369 0.248188 27.9411C0.413986 28.3454 0.657987 28.7129 0.966182 29.0225C1.27438 29.3322 1.6407 29.578 2.04412 29.7457C2.44755 29.9135 2.88013 29.9999 3.31703 30H26.667C27.5502 29.9974 28.3964 29.6454 29.0209 29.0209C29.6454 28.3964 29.9974 27.5502 30 26.667V3.33301C30.0002 2.89527 29.914 2.46181 29.7466 2.05737C29.5791 1.65293 29.3336 1.28545 29.0241 0.975926C28.7146 0.666402 28.3471 0.420901 27.9427 0.253449C27.5382 0.0859963 27.1048 -0.000125498 26.667 5.89383e-06ZM26.667 20H20C20 21.3261 19.4732 22.5979 18.5356 23.5355C17.5979 24.4732 16.3261 25 15 25C13.674 25 12.4022 24.4732 11.4645 23.5355C10.5268 22.5979 10 21.3261 10 20H3.31703V3.33301H26.667V20Z"
        fill="#CCCCCC"
      />
    </svg>
  )
}

export default AvatarNoData
