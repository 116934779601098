import { Loading } from '../signup/export'

interface ReDownloadLoadingProps {
}

const ReDownloadLoading: React.FC<ReDownloadLoadingProps> = () => {
  return (
    <div className="re-download">
      <Loading size={100} />
    </div>
  )
}
export default ReDownloadLoading
