import * as React from 'react';

interface AvatarIconProps {
   height?: string;
   width?: string;
};
const AvatarIcon: React.FC<AvatarIconProps> = ({ height, width }) => {
   return (
      <svg width={width || "32"} height={height || "32"} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M14 0C6.28058 0 0 6.28058 0 14C0 21.7194 6.28058 28 14 28C21.7194 28 28 21.7194 28 14C28 6.28058 21.7194 0 14 0ZM14 25.6516C8.21936 25.6516 2.34839 21.2217 2.34839 14C2.34839 13.6152 2.36871 13.235 2.40123 12.8583C4.09026 10.6983 4.90271 10.6834 7.73929 10.6969C10.9078 10.691 14.8201 10.7005 18.8327 7.2931C21.9881 11.9876 25.6223 13.1659 25.6223 13.1659C25.6223 13.1659 25.6516 13.7195 25.6516 14C25.6516 21.2217 19.7806 25.6516 14 25.6516Z" fill="#9496AB" />
         <path d="M7.90315 18.5148C8.77612 18.5148 9.4838 17.8071 9.4838 16.9342C9.4838 16.0612 8.77612 15.3535 7.90315 15.3535C7.03019 15.3535 6.32251 16.0612 6.32251 16.9342C6.32251 17.8071 7.03019 18.5148 7.90315 18.5148Z" fill="#9496AB" />
         <path d="M20.0968 18.5148C20.9697 18.5148 21.6774 17.8071 21.6774 16.9342C21.6774 16.0612 20.9697 15.3535 20.0968 15.3535C19.2238 15.3535 18.5161 16.0612 18.5161 16.9342C18.5161 17.8071 19.2238 18.5148 20.0968 18.5148Z" fill="#9496AB" />
      </svg>
   );
};

export default AvatarIcon
