import { FC, Fragment, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseIcon } from '../../../../../../assets/images'
import { DeleteAvatarIcon } from '../../../../../../assets/images/svgs'
import { Modal } from '../../../../components/modules/modal'
import { Button } from '../../../../components/atoms/buttons'
import Croppie from 'croppie'
import 'croppie/croppie.css'
import { ORIGINAL } from '../../../../../../utils/const'
interface ProfileImageModalProps {
  isShowModal: boolean
  onSavingImage: (values: any) => void
  toggleModal?: any
}

const ProfileImageModal: FC<ProfileImageModalProps> = ({
  isShowModal,
  onSavingImage,
  toggleModal,
}) => {
  const [t, i18n] = useTranslation()
  let innerWindowWidth = window.innerWidth
  const [croppie, setCroppie] = useState<Croppie | null>(null)
  const [isError, setIsError] = useState<any>()
  const [isDisable, setIsDisable] = useState<any>(true)
  const [isSize, setIsSize] = useState<any>(false)

  const img: any = useRef()

  useEffect(() => {
  }, [isShowModal])

  useEffect(() => {
    let helper: any = document.getElementById('image-helper')
    helper?.addEventListener('update', function () {
      setIsSize(false)
    })
  }, [croppie])

  function handleImage(e: any) {
    e.preventDefault()
    setIsError(false)
    if (!e?.target?.files?.length) return

    let file = e.target.files[0]
    const fileType = file['type']
    const validImageTypes = ['image/jpeg', 'image/png']
    const isImageFile = validImageTypes.includes(fileType)
    if (!isImageFile || !e.target.value) {
      setIsError(true)
      return
    }
    const image = URL.createObjectURL(file)
    const el: any = document.getElementById('image-helper')
    if (el) {
      const croppieInstance = new Croppie(el, {
        showZoomer: false,
        enableOrientation: true,
        viewport: {
          width: innerWindowWidth > 768 ? 250 : 150,
          height: innerWindowWidth > 768 ? 250 : 150,
          type: 'circle',
        },
        boundary: {
          width: innerWindowWidth > 768 ? 410 : 310,
          height: innerWindowWidth > 768 ? 400 : 300,
        },
      })
      croppieInstance.bind({
        url: image,
      })
      setIsDisable(false)
      setCroppie(croppieInstance)
    }
  }

  function handleSubmit(event: any) {
    event.preventDefault()
    if (croppie !== null) {
      croppie
        .result({
          type: 'blob',
          size: ORIGINAL,
          format: 'jpeg',
          quality: 0.2,
        })
        .then((blob) => {
          if (blob?.size / 1024 / 1024 > 1) setIsSize(true)
          else {
            onSavingImage(blob)
            onClose()
          }
        })
    }
  }

  function handleDestroy() {
    const imageInput = img?.current
    if (!!imageInput?.value) {
      croppie?.destroy()
      imageInput.value = ''
      setIsDisable(true)
    }
    setIsSize(false)
  }

  const onClose = () => {
    handleDestroy()
    toggleModal()
  }

  return (
    <Fragment>
      <Modal
        modalStyles="account-setting__profile-image-modal padding-modal-in-h2"
        show={isShowModal}
        toggleModal={onClose}
      >
        <div className="account-setting__profile-image-modal__header distance-modal-line-to-content ">
          <div className="account-setting__profile-image-modal__title pop-text-bold-big distance-modal-h2-to-line">
            {i18n.t('account_setting.profile_image_modal.title')}
          </div>
          <button className="account-setting__profile-image-modal__close close-icon" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="account-setting__profile-image-modal__content">
          <div className="account-setting__profile-image-modal__image-area distance-modal-content-to-btn">
            <span
              className="account-setting__profile-image-modal__image-area__icon"
              onClick={handleDestroy}
            >
              <DeleteAvatarIcon />
            </span>
            <div
              id="image-helper"
              style={{
                width: innerWindowWidth > 768 ? 410 : 310,
                height: innerWindowWidth > 768 ? 400 : 300,
              }}
            ></div>
            <input
              type="file"
              id="files"
              onChange={handleImage}
              accept="image/jpeg,image/png"
              ref={img}
            />
            <label className="account-setting__profile-image-modal__image-area__label" htmlFor="files">
              {isDisable && t('edit_user.label')}
              {isError && <div className="error-image">{t('edit_user.format_image')}</div>}
            </label>
          </div>
          {!isDisable && (
            <div className="account-setting__profile-image-modal__describe-area distance-modal-content-to-btn">
              <div className="account-setting__profile-image-modal__describe pop-text-bold-normal">
                <span>{localStorage.getItem('i18nextLng') === 'ja' ? '※' : '*'}</span>
                {i18n.t('account_setting.profile_image_modal.describe')}
              </div>
            </div>
          )}
          {isSize && <span className="error-size-image">{t('edit_user.max_size_image')}</span>}
          <div className="account-setting__profile-image-modal__group-buttons">
            <Button className="shortnormal cancel " onClick={onClose}>
              {i18n.t('account_setting.cancel')}
            </Button>
            <Button disabled={isDisable} className="shortnormal" onClick={handleSubmit}>
              {i18n.t('account_setting.save')}
            </Button>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}

export default ProfileImageModal
