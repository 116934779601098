import Wrapper from '../../components/modules/_wrapper'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { ROUTER_URL } from '../../../../utils/const';

interface ForgotPasswordProps {}

const ResetPasswordSuccess: React.FC<ForgotPasswordProps> = () => {

  const title = 'パスワード再設定完了 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'パスワード再設定完了のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
  }
  
  const [, i18n] = useTranslation('translation')

  const list = [
    {
      name: i18n.t('forgot_password.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('signin.login'),
      link: ROUTER_URL.SIGNIN,
    },
    {
      name: i18n.t('reset_password.breadcrumb_active_success'),
      link: ROUTER_URL.RESET_PASSWORD_SUCCESS,
    },
  ]

  return (
    <Wrapper
      buttonText={i18n.t('reset_password.success_btn')}
      buttonLink={ROUTER_URL.SIGNIN}
      breadcrum={list}
      className="password"
      typeBreadcrums={1}
      isNoMarginTop={false}
    >
      <div className="active-mail__container distance-h2-to-text">
        <h2 className="title-h2 ">
          {i18n.t('reset_password.success_title')}
        </h2>
      </div>
      <div className="password__describe-success distance-text-to-btn">
        <p className="password__describe-content1">{i18n.t('reset_password.success_content1')}<br />{i18n.t('reset_password.success_content2')}</p>
      </div>
    </Wrapper>
  )
}
export default ResetPasswordSuccess
