export enum AvatarTypes {
    GET_LIST_AVATAR_EMPTY = 'GET_LIST_AVATAR_EMPTY',
    GET_LIST_AVATAR_ORIGINAL = 'GET_LIST_AVATAR_ORIGINAL',
    GET_LIST_AVATAR_EDITED = 'GET_LIST_AVATAR_EDITED'
}

export const checkTypeAvatarEmpty = (payload:any[]) => ({
    type: AvatarTypes.GET_LIST_AVATAR_EMPTY,
    payload
});

export const checkTypeAvatarOriginal = (payload:any[]) => ({
    type: AvatarTypes.GET_LIST_AVATAR_ORIGINAL,
    payload
});

export const checkTypeAvatarEdited = (payload:any[]) => ({
    type: AvatarTypes.GET_LIST_AVATAR_EDITED,
    payload
});
