import React from 'react'

interface PlayButtonProps {
  height?: string
  width?: string
}

const PlayButton: React.FC<PlayButtonProps> = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="80" viewBox="0 0 110 80">
      <g id="Group_10722" data-name="Group 10722" transform="translate(-350 -604)">
        <rect
          id="Rectangle_3499"
          data-name="Rectangle 3499"
          width="110"
          height="80"
          rx="18"
          transform="translate(350 604)"
          fill="#12c4c4"
          opacity="0.8"
        />
        <path
          id="Polygon_1"
          data-name="Polygon 1"
          d="M16.257,3.1a2,2,0,0,1,3.486,0l14.58,25.921A2,2,0,0,1,32.58,32H3.42a2,2,0,0,1-1.743-2.981Z"
          transform="translate(423 626) rotate(90)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default PlayButton
