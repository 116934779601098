import * as React from 'react';

interface EyeIconProps {
   height?: string;
   width?: string;
};
const EyeIcon: React.FC<EyeIconProps> = ({ height, width }) => {
   return (
      <svg width={width || "16"} height={height || "12"} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4749 5.38717C14.4862 3.9665 11.7789 0.666504 8.00019 0.666504C4.18852 0.666504 1.50019 3.97017 0.522191 5.39184C0.400316 5.57131 0.335406 5.78336 0.335941 6.0003C0.336476 6.21724 0.402432 6.42897 0.525191 6.60784C1.51252 8.02884 4.21686 11.3332 8.00019 11.3332C11.7489 11.3332 14.4735 8.03317 15.4715 6.61184C15.5965 6.43226 15.6638 6.21883 15.6644 6.00002C15.665 5.78122 15.5989 5.56743 15.4749 5.38717ZM8.00019 8.99984C7.40685 8.99984 6.82683 8.82389 6.33348 8.49425C5.84013 8.1646 5.45562 7.69607 5.22855 7.14789C5.00149 6.59971 4.94208 5.99651 5.05784 5.41457C5.17359 4.83262 5.45931 4.29807 5.87887 3.87852C6.29843 3.45896 6.83298 3.17324 7.41492 3.05748C7.99686 2.94173 8.60006 3.00114 9.14824 3.2282C9.69642 3.45526 10.165 3.83978 10.4946 4.33313C10.8242 4.82647 11.0002 5.40649 11.0002 5.99984C11.0002 6.79549 10.6841 7.55855 10.1215 8.12116C9.5589 8.68377 8.79584 8.99984 8.00019 8.99984Z" fill="#777"/>
      </svg>
      
   );
};

export default EyeIcon