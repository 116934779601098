import React, { useState, useEffect } from 'react'
import Wrapper from '../../../components/modules/_wrapper'
import { useTranslation } from 'react-i18next'
import {
  EditIcon,
  LocationIcon,
  AvatarDownIcon,
  AvatarUpIcon,
  AvatarIconSmall,
  TickIcon,
  AvatarNoData,
} from '../../../../../assets/images/svgs'
import { Button } from '../../../components/atoms/buttons'
import { Modal } from '../../../components/modules/modal'
import AvatarDetailComponent from '../../../components/modules/control-avatar/detail'
import { TextInput } from '../../../components/atoms/text-input'
import { FormProvider, useForm } from 'react-hook-form'
import { validator } from './constants'
import { Link, useLocation, useHistory, useParams } from 'react-router-dom'
import {
  DELETE_AVATAR,
  GET_AVATAR_DETAIL,
  RENAME_USER,
  EXPORT_AVATAR,
  IS_EXPORTED,
} from '../../../../../utils/endpoints'
import DeleteModal from './../delete-modal'
import { DELETE, EDITED, ERROR_TYPE, GET, MOBILE, ORIGINAL, POST, ROUTER_URL, SCANNER } from '../../../../../utils/const'
import cx from 'classnames'
import MessageModal from '../../../components/modules/modal/message-modal'
import axios from '../../../../../utils/axios'

interface DetailAvatarProps {
  notify: Function
  callApi: Function
  isLoading: boolean
  setIsLoading: Function
}

const DetailAvatar: React.FC<DetailAvatarProps> = ({ notify, callApi, isLoading, setIsLoading }) => {
  const [t] = useTranslation('translation')

  const [isInput, setInput] = useState(false)

  const location: any = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [showModalFail, setShowModalFail] = useState(false)
  const [errorTitle, setErrorTitle] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [addressAvatar, setAddressAvatar] = useState('')
  const [avatarName, setAvatarName] = useState('')
  const [imageAvatar, setImageAvatar] = useState('')
  const [modelType, setModelType] = useState('')
  const history = useHistory()
  const [valueInput, setValueInput] = useState('')
  const params: any = useParams()
  const [editAvatarMessage, setEditAvatarMessage] = useState('')

  const url_string = window.location.href
  const id = url_string.substring(url_string.lastIndexOf('/') + 1)
  const [listEditDataAvatar, setListEditDataAvatar] = useState([])
  const [checked, setChecked] = useState(false)
  const [exported, setExportd] = useState(false)

  const methods = useForm({
    mode: 'onChange',
    resolver: validator(t),
  })

  const { handleSubmit, setValue, getValues } = methods

  useEffect(() => {
    setIsLoading(true)
    if (!id) {
      notify(' 編集アバターが存在しません。', ERROR_TYPE)
    }
    if (!checked) {
      setChecked(true)
      checkExportd()         
    }
    const fetchListAvatar = async () => {
      const paramApi = {
        avatar_type: location?.state?.type ? EDITED : ORIGINAL,
        avatar_id: id,
        model_type: location?.state?.model_type?  location?.state?.model_type : params['model_type']
      }
      try {
        const result = await axios.get(GET_AVATAR_DETAIL, { params: paramApi })

        if (result) {
          const name = result.data.data.avatar.avatar_name
          const location = result.data.data.avatar.scanner_name
          
          const editAvatarList = result.data.data.avatar_list
          
          await axios.get(result.data.data.avatar.s3_path, {responseType: 'blob',}).then(res=> {
            setImageAvatar(URL.createObjectURL(res?.data))
          })
          for (let i=0; i < editAvatarList.length; i++) {
            await axios.get(editAvatarList[i]['s3_path'], {responseType: 'blob',}).then(res => {
              editAvatarList[i]['image_avatar'] = URL.createObjectURL(res?.data)
              }
            )
          }
          setListEditDataAvatar(editAvatarList)
          if (editAvatarList.length === 0) {setEditAvatarMessage(t('control-avatar.no_data'))}
          setAvatarName(name)
          setAddressAvatar(location)
          setValue('name', result.data.data.avatar.avatar_name || '', { shouldValidate: false })
          setModelType(params['model_type'])

          const title = name +' | アバター詳細 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
          const description = 'アバター詳細のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
          const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
          const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

          // title
          document.title = title;

          // title以外のmeta
          const headData = document.head.children;

          for (let i = 0; i < headData.length; i++) {
            const nameVal = headData[i].getAttribute('name');
            const propertyVal = headData[i].getAttribute('property');
            if (nameVal !== null) {
              if (nameVal.indexOf('keywords') !== -1) {
                headData[i].setAttribute('content', keywords);
              }
              if (nameVal.indexOf('description') !== -1) {
                headData[i].setAttribute('content', description);
              }
            }
            if (propertyVal !== null) {
              // OGP(og:title)の設定
              if (propertyVal.indexOf('og:title') !== -1) {
                headData[i].setAttribute('content', title);
              }
              if (propertyVal.indexOf('og:description') !== -1) {
                headData[i].setAttribute('content', description);
              }
              if (propertyVal.indexOf('og:image') !== -1) {
                headData[i].setAttribute('content', og_image);
              }
            }
          }
        }
      } catch (error) {
        if (error?.message) {
          history.push(ROUTER_URL.CONTROL_AVATAR)
          notify(t('detail-avatar.no_avatar_origin'), ERROR_TYPE)
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchListAvatar()
  }, [])


  const toggleModal = () => {
    setShowModal((showModal) => !showModal)
  }

  const toggleModalFail = () => {
    setShowModalFail((showModalFail) => !showModalFail)
  }


  const exportAvater = async (values: any) => {
    try {
      const params = {avatar_id: id, avatar_type: ORIGINAL, model_type: MOBILE}
      const result = await callApi(EXPORT_AVATAR, GET, { params })
      const state = result?.data.data
      if (state === 'success') {
        history.push(ROUTER_URL.EXPORT_COMPLETE)
        }
      else {
        let title
        let message
        if (state === 'converting') {
          title = 'home.modal_title_error'
          message = 'notifications.err_network'
        }
        else if (state === 'no_token') {
          title = 'control-export.export_fail_title'
          message = 'control-export.export_error_no_token'
        }
        else if (state === 'error') {
          title = 'control-export.export_fail_title'
          message = 'control-export.export_error'
        }
        else {
          title = 'control-export.export_fail_title'
          message = 'control-export.export_fail_message'
        }
        setErrorTitle(title)
        setErrorMessage(message)
        toggleModalFail()
        }
    } catch (error) {
    }
  }

  const checkExportd = async () => {    
    try {
      if (modelType !== MOBILE)
      {
        const params = {avatar_id: id, avatar_type: ORIGINAL}
        await axios.get(IS_EXPORTED, { params: params }).then(res => {setExportd(res?.data.data)})
      }
    } catch (error){
    }
  } 

  const deleteAvatar = async () => {
    if (isLoading) return
    const params = {
      avatar_type: ORIGINAL,
      avatar_id: id,
    }
    try {
      await callApi(DELETE_AVATAR, DELETE, { params })
      history.push(ROUTER_URL.CONTROL_AVATAR)
    } catch (error) {
      let message = error?.message || ''
      message = message.replace('\\n', '\n')
      if (message) {
        notify(message, ERROR_TYPE)
      }
    }
  }
  const onSubmit = async () => {
    setInput(true)
    const name = valueInput?.trim() || getValues('name')
    const params = {
      avatar_id: id,
      avatar_name: name,
      avatar_type: ORIGINAL,
    }
    try {
      if (name !== '') {
        const result = await callApi(RENAME_USER, POST, params)
        if (result?.data) {
          setAvatarName(name)
          setInput(false)
        }
      }
    } catch (error) {
      let message = error?.message || ''
      message = message.replace('\\n', '\n')
      if (message) {
        notify(message, ERROR_TYPE)
      }
    }
  }

  function onValidate(e?: any) {
    const listField = ['name']
    listField.forEach((item: any) => {
      const value = getValues(item) + ''
      setValue(item, value.trim() || '')
    })
    handleSubmit(onSubmit)()
  }
  const list = [
    {
      name: t('control-avatar.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('detail-avatar.bread_crumb2'),
      link: ROUTER_URL.CONTROL_AVATAR,
    },
    {
      name: t('detail-avatar.bread_crumb_active'),
      link: ROUTER_URL.CONTROL_AVATAR_DETAIL,
    },
  ]

  const renderImageMain = (
    <AvatarDetailComponent
      image={imageAvatar}
      date="2021年03月08日"
      content="アバター名を記の記載"
    />
  )

  const handleReName = (e: any) => {
    setValueInput(e.target.value || '')
  }

  const renderNameAvatar = (
    <div className="wrap-content1_avatar name_avatar">
      <div className="box-content">
        <div className="box-content_icon">
          <AvatarIconSmall />
        </div>
        <div
          className={cx('box-input', {
            isDisable: !isInput,
            isEN: localStorage.getItem('i18nextLng') === 'en',
          })}
        >
          <TextInput
            type="text"
            isRequire={true}
            name="name"
            isDisabled={!isInput}
            isFocus={isInput}
            id={1}
            handleChange={handleReName}
            isShowBorder={false}
            placeholder=""
          />
        </div>
      </div>
      <button
        className="button-edit"
        type="button"
        onClick={() => (isInput ? onValidate() : setInput(true))}
      >
        {isInput ? <TickIcon /> : <EditIcon />}
      </button>
    </div>
  )

  const renderLocationAvatar = (
    <div className="wrap-content1_location">
      <div className="box-content">
        <div className="box-content_icon">
          <LocationIcon />
        </div>
        <div className="location-content-box">
          <p>
            {addressAvatar}
          </p>
        </div>
      </div>
    </div>
  )


  const renderDownAvatar = (
    <div className="box-content">
      <div className="box-content_icon">
        <AvatarDownIcon />
      </div>
      <div className="box-input">
        <p className="title-h4">{t('detail-avatar.download')}</p>
      </div>
    </div>
  )

  const renderPlayRegister = (
    <div className="detail-avatar__register">
      <Button
        onClick={exportAvater} 
        disabled={exported || modelType !== MOBILE}
        type="button">{t('detail-avatar.button_play')}
      </Button>
    </div>
  )

  const renderDownloadRegister = (
    <div className="detail-avatar__register">
      <Button
      	className="small"
        onClick={() =>
          history.push({
            pathname: `${ROUTER_URL.DOWNLOAD_AVATAR}/${modelType}/${id}`,
            state: { id: id, img: imageAvatar, name: avatarName },
          })
        }
        disabled={modelType === MOBILE}
        type="button"
      >
        {t('detail-avatar.button_download')}
      </Button>
    </div>
  )

  const renderBtnDelete = (
    <Button className="small cancel" onClick={toggleModal} type="button" disabled={params['model_type'] === SCANNER}>
      {t('detail-avatar.btn_delete')}
    </Button>
  )


  const renderErr = (
    <div className="detail-avatar__nodata">
      <div className="detail-avatar__nodata--wrap">
        <AvatarNoData />
        <div className="detail-avatar__nodata--wrap__text">{editAvatarMessage}</div>
      </div>
    </div>
  )

  const renderBoxContent3 = (
    <div className="box-content2">
      <p className="title-h3 box-content2_title">{t('detail-avatar.title_edited')}</p>
      <div className="avator-edited">
        {listEditDataAvatar.length === 0 ? renderErr : null}
        <div className="wrap-content2 avator-edited-items">
          {listEditDataAvatar.length === 0
            ? null
            : listEditDataAvatar.map(
                (
                  { avatar_name, customer_id, edit_id, parent_id, s3_path, update_date, update_id, model_type, image_avatar},
                  index
                ) => {
                  return (
                    <Link key={index} to={`${ROUTER_URL.CONTROL_AVATAR_EDITED}/${model_type}/${edit_id}`}>
                      <AvatarDetailComponent
                        key={edit_id}
                        image={image_avatar}
                        date={update_date}
                        content={avatar_name}
                        isExistContent={true}
                      />
                    </Link>
                  )
                }
              )}
        </div>
      </div>
    </div>
  )

  return (
    <Wrapper breadcrum={list} className="detail-avatar" isNoMarginTop={true}>
      <Modal
        modalStyles="booking__modal-booking padding-modal-in-h2"
        show={showModal}
        toggleModal={toggleModal}
      >
        <DeleteModal name={avatarName} toggleModal={toggleModal} deleteAvatar={deleteAvatar} />
      </Modal>
      <MessageModal
        custom_title = {errorTitle}
        custom_message = {errorMessage}
        show={showModalFail}
        toggleModal={toggleModalFail}
      >
      </MessageModal>
      <div className="detail-avatar_wrap detail-avatar_wrap--avatar">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="wrap-content1">
              {renderImageMain}
              <div className="wrap-content1-box">
                {renderNameAvatar}
                {renderLocationAvatar}
                <div className="box-upload">
                  <div className="wrap-upload">
                    <div className="box-content1">
                      <div className="box-content_icon">
                        <AvatarUpIcon />
                      </div>
                      <div className="box-input">
                        <p className="title-h4 ">{t('detail-avatar.play')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="box-upload_button">
                    <div className="box-upload_content">
                      <p>{t('detail-avatar.detail_play')}</p>
                    </div>
                  </div>
                  <div className="box-upload_content_btn">{renderPlayRegister}</div>
                </div>
                <div>
                  <div className="box-down wrap-content1_avatar">
                    {renderDownAvatar}
                    <div className="box-down_button">{renderDownloadRegister}</div>
                    <div className="box-down_text">
                      <p>{t('detail-avatar.down_play_mobile')}</p>
                    </div>
                  </div>
                </div>
                <div className="wrap-content1_avatar box_delete">{renderBtnDelete}</div>
              </div>
            </div>
            <div className="wrap_content2">{renderBoxContent3}</div>
          </form>
        </FormProvider>
      </div>
    </Wrapper>
  )
}

export default DetailAvatar
