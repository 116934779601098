import { put, takeLatest } from 'redux-saga/effects';
import { SigninActionsTypes, IGetAuth, displayMessage, SystemTypes } from "../../actions";
import { SignFactory } from '../../factories';

function* getAuth(action: IGetAuth): any {
    yield put({ type: SigninActionsTypes.GET_SIGNIN_ATTEMPT });
    const { username, password } = action;
    try {
        const factory = new SignFactory();
        const data = yield factory.getAuth(username, password);
        if (!data.error) {
            yield put({ type: SigninActionsTypes.GET_SIGNIN_SUCCESS, data });
        } else {
            yield put({ type: SigninActionsTypes.GET_SIGNIN_FAIL, error: data.error });
            yield put(displayMessage(data.error, 'error'));
        }
    } catch {
        yield put({ type: SystemTypes.SYSTEM_ERROR, error: "Something went wrong!" });
    }
}

export function* signinSaga() {
    yield takeLatest(SigninActionsTypes.GET_SIGNIN, getAuth);
}


