import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const AvatarIconSort: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 19L20 23L24 19"
        stroke="#9496AB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M20 23L20 8" stroke="#9496AB" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M12.3588 8.5L14.6877 14.4561H14.0251L13.5835 13.142L13.469 12.8013H13.1095H10.3105H9.95105L9.83655 13.142L9.39494 14.4561H8.73154L11.0532 8.5H11.3479L11.229 8.91867C11.1182 9.30903 10.7924 10.2982 10.2444 11.9037L10.0186 12.5652H10.7176H12.7209H13.4199L13.194 11.9036L12.3264 9.36283C12.263 9.16904 12.2202 9.02689 12.1928 8.92522L12.0783 8.5H12.3588Z"
        fill="#9496AB"
        stroke="#9496AB"
      />
      <path
        d="M10.7865 23.1907H14.0648V23.408H9.35156V23.1225L12.9285 18.4785L13.5486 17.6734H12.5324H9.45142V17.4561H13.965V17.7369L10.3901 22.3859L9.77124 23.1907H10.7865Z"
        fill="#9496AB"
        stroke="#9496AB"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="4.5" stroke="#9496AB" />
    </svg>
  )
}

export default AvatarIconSort
