import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconHair2: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.9961 38.9267C70.4918 58.6479 56.0894 56.6382 56.0894 68.9997C44.1438 52.5141 71.7952 43.0756 56.7654 21.9474C53.9522 17.9895 55.9088 15.3724 59.4208 17.4879C65.3558 21.0609 71.2006 30.8814 70.9961 38.9267Z"
        fill="#111947"
      />
      <path
        d="M1.00501 38.9267C1.5063 58.6479 15.9107 56.6412 15.9107 68.9997C27.8563 52.5141 0.226771 43.0756 15.2347 21.9474C18.0479 17.9895 16.0913 15.3724 12.5793 17.4879C6.6542 21.0609 0.804496 30.8814 1.00501 38.9267Z"
        fill="#111947"
      />
      <path
        d="M35.9438 3H35.8624C20.9329 3.05244 10.8287 16.9852 12.5797 31.4641C14.2265 45.1188 27.1429 52.4468 35.8673 52.4903H35.9487C44.6682 52.4478 57.5886 45.1178 59.2314 31.4641C60.9735 16.9852 50.8733 3.05244 35.9438 3Z"
        fill="#111947"
      />
      <path
        d="M53.355 25.7722C51.9276 14.5912 45.2639 8.35156 35.8982 8.35156C26.5325 8.35156 19.8172 14.5852 18.4176 25.7722C14.3864 25.9523 13.3203 28.799 13.3203 32.5006C13.3203 36.2022 17.4547 40.4302 18.8256 40.6281C19.3924 40.7132 19.8966 40.7656 20.3959 40.8082C23.6608 48.2094 29.843 53.4229 34.9453 53.4229H36.8492C41.9464 53.4229 48.1287 48.2044 51.3935 40.8082C51.895 40.7815 52.3941 40.7213 52.8874 40.6281C54.2583 40.4302 58.3987 36.3585 58.3987 32.4917C58.3987 28.6248 57.3524 25.9424 53.355 25.7722Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M30.1505 35.5684C31.6856 35.5684 32.9299 34.328 32.9299 32.7979C32.9299 31.2677 31.6856 30.0273 30.1505 30.0273C28.6155 30.0273 27.3711 31.2677 27.3711 32.7979C27.3711 34.328 28.6155 35.5684 30.1505 35.5684Z"
        fill="#111947"
      />
      <path
        d="M42.3341 35.5684C43.8692 35.5684 45.1135 34.328 45.1135 32.7979C45.1135 31.2677 43.8692 30.0273 42.3341 30.0273C40.7991 30.0273 39.5547 31.2677 39.5547 32.7979C39.5547 34.328 40.7991 35.5684 42.3341 35.5684Z"
        fill="#111947"
      />
      <path
        d="M35.8651 5.53321C25.8323 5.53321 19.3553 10.3579 22.0057 24.154C26.5578 23.1454 31.2022 22.6079 35.8651 22.5501C40.5263 22.6081 45.1691 23.1456 49.7195 24.154C52.3709 10.3579 45.8929 5.52826 35.8651 5.53321Z"
        fill="#111947"
      />
    </svg>
  )
}

export default IconHair2
