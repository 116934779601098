import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { validator, textFields, defaultValues } from './constants'
import { Modal } from '../../components/modules/modal'
import SuccessPWModal from './modalChangeSuccess'
import { useEffect, useState } from 'react'
import { RESET_PASSWORD } from '../../../../utils/endpoints'
import { useHistory } from 'react-router-dom'
import Wrapper from '../../components/modules/_wrapper'
import { Button } from '../../components/atoms/buttons'
import { TextInputPassword } from '../../components/atoms/text-input-password'
import { POST, ROUTER_URL } from '../../../../utils/const'
import { isEmpty } from 'lodash'
interface ResetPasswordProps {
  notify: any
  callApi: any
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ callApi }) => {

  const title = 'パスワード再設定 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = 'パスワード再設定のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const url_string = window.location.href
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const history = useHistory()
  const url = new URL(url_string)
  const token = url.searchParams.get('key')

  const [, i18n] = useTranslation('translation')
  const [t] = useTranslation()
  const [show, setShow] = useState(false)

  const toggleModal = () => {
    setShow((show) => !show)
  }
  const methods = useForm({
    defaultValues,
    resolver: validator(t),
  })
  const { handleSubmit, errors } = methods

  useEffect(() => {
    if (!isEmpty(errors) || error) handleSubmit(onSubmit)()
  }, [localStorage.getItem('i18nextLng')])

  const onSubmit = async (values: any) => {
    setLoading(true)
    const params = { token: token, ...values }
    try {
      await callApi(RESET_PASSWORD, POST, params)
      history.push(ROUTER_URL.RESET_PASSWORD_SUCCESS)
      setLoading(false)
    } catch (error) {
      if (error?.message) setError(error?.message)
      setLoading(false)
    }
  }

  const renderTextFields = (
    <>
      <div className="password__field-wrapper  distance-form-to-btn">
        {textFields.map(({ name, type, isRequire, placeholder, label }, idx) => {
          return (
            <TextInputPassword
              key={name}
              type={type}
              name={name}
              isRequire={isRequire}
              placeholder={i18n.t(placeholder)}
              label={i18n.t(label)}
              errorsBorder={!!error}
              handleChange={() => setError('')}
              isNoMarginH4={idx === 0}
            />
          )
        })}
        {error && (
          <div className="error-message">
            <span>※{error}</span>
          </div>
        )}
      </div>
    </>
  )
  const list = [
    {
      name: i18n.t('forgot_password.bread_crumb'),
      link: ROUTER_URL.HOME,
    },
    {
      name: i18n.t('signin.login'),
      link: ROUTER_URL.SIGNIN,
    },
    {
      name: i18n.t('reset_password.breadcrumb_active'),
      link: ROUTER_URL.RESET_PASSWORD,
    },
  ]

  const renderBtnPassword = (
    <div className="password__send">
      <Button type="submit">{i18n.t('reset_password.button')}</Button>
    </div>
  )

  return (
    <Wrapper breadcrum={list} className="password" typeBreadcrums={1} isNoMarginTop={false}>
      <h2 className="password__title distance-h2-to-label title-h2">
        {i18n.t('reset_password.title_header')}
      </h2>
      <Modal modalStyles="password__modal-success" show={show} toggleModal={toggleModal}>
        <SuccessPWModal toggleChangeSuccess={toggleModal} />
      </Modal>
      <FormProvider {...methods}>
        <form
          className="reset-password__form"
          onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(onSubmit)}
        >
          {renderTextFields}
          {renderBtnPassword}
        </form>
      </FormProvider>
    </Wrapper>
  )
}
export default ResetPassword
