import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import SemanticDatepicker from '../../components/modules/react-semantic-ui-datepickers'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import Select from 'react-select'
import classNames from 'classnames'
import { selectInputStyler } from './constants'
import { CalendarLogo, CloseIcon } from '../../../../assets/images'
import { Button } from '../../components/atoms/buttons'
import { TIME_BOOKING } from '../../../../utils/endpoints'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { CREATE_BOOKING } from '../../../../utils/endpoints'
import { CheckCircle } from './svg'
import { useDispatch } from 'react-redux'
import { TabTypes } from '../../../stores/actions/checkTab'
import { getHour } from '../../../../apis/booking'
import { ERROR_TYPE, GET, POST, ROUTER_URL } from '../../../../utils/const'
const defaultValues = {
  scannerId: '',
}

const validator = (t: any) => {
  return yupResolver(
    Yup.object().shape({
    })
  )
}
interface BookingModalProps {
  toggleModal: (arg?: boolean) => void
  addressList: any[]
  selectedAddress: any
  onBookingSuccess: (arg?: any) => void
  notify?: any
  callApi: any
  available: any
}

const BookingModal: React.FC<BookingModalProps> = ({
  toggleModal,
  addressList,
  selectedAddress,
  onBookingSuccess,
  notify,
  callApi,
  available,
}) => {
  const toDay = moment((new Date()).toISOString()).format('YYYY/MM/DD')
  const [currentDate, setNewDate] = useState(toDay)
  const [errorMessageDate, setErrorMessageDate] = useState('')
  const [errorMessageHour, setErrorMessageHour] = useState('')
  const [errorMessageScannerId, setErrorMessageScannerId] = useState('')
  const [hour, setHour] = useState()
  const [hourTest, setHourTest] = useState([])
  const [periodOfTime, setPeriodOfTime] = useState(0)
  const [userSelectedTime, setUserSelectedTime] = useState({
    reservationStartTime: '',
    reservationFinalTime: '',
  })
  const [scanner, setScanner] = useState(selectedAddress)
  const [timeBookings, setTimeBookings] = useState([])
  const [, i18n] = useTranslation('translation')
  const [t] = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const sixPeriodOfTime = [1, 2, 3, 4, 5, 6]
  const [noDataTimeBooking, setDataEmplty] = useState('')
  const [establishDate, setEstablishDate] = useState('')
  const [expirationDate, setExpirationDate] = useState('')
  const [error, setError] = useState('')
  const methods = useForm({
    defaultValues,
    resolver: validator(i18n.t),
  })

  const { handleSubmit } = methods

  useEffect(() => {
    setNewDate(
      moment(scanner?.establish_date).startOf('day').isAfter(moment().startOf('day'))
        ? moment(new Date(scanner?.establish_date).toISOString()).format('YYYY/MM/DD')
        : moment().format('YYYY/MM/DD')
    )

    setEstablishDate(scanner?.establish_date)
    setExpirationDate(scanner?.expiration_date)
  }, [scanner])

  useEffect(() => {
    const fetchHour = async () => {
      try {
        const result = await getHour(scanner.value, currentDate)
        const hours = result.data.data
        setHourTest(!hours?.length ? [] : hours)
        setHour(!!hours?.length ? hours[0] : '')
      } catch (error) {
      }
    }
    fetchHour()
  }, [currentDate, scanner])

  useEffect(() => {
    const fetchTimeBooking = async () => {
      if (scanner && currentDate && (hour || hour === 0)) {
        const params = {
          reservation_scanner_id: scanner.value,
          reservation_date: currentDate,
          reservation_hour: hour,
        }
        const result = await callApi(TIME_BOOKING, GET, { params: params })
        if (result) {
          const { data, message } = result?.data
          if (!data?.length) {
            setDataEmplty(message)
          }
          setTimeBookings(data)
          setPeriodOfTime(0)
        }
      }
    }
    fetchTimeBooking()
  }, [scanner, currentDate, hour])

  useEffect(() => {
    if ((hour || hour === 0) && periodOfTime) {
      setErrorMessageHour('')
      setUserSelectedTime({
        reservationStartTime: `${hour}:${periodOfTime - 1}0:00`,
        reservationFinalTime:
          periodOfTime !== 6
            ? `${hour}:${periodOfTime}0:00`
            : hour !== 23
            ? `${hour! + 1}:00:00`
            : `00:00:00`,
      })
    }
  }, [hour, periodOfTime])

  const onSubmit = async (values: any) => {
    if (!(scanner && currentDate && hour !== null && hour !== undefined && periodOfTime)) {
      if (!scanner) {
        setErrorMessageScannerId('Scanner id required')
      }
      if (!currentDate) {
        setErrorMessageDate('Date is required')
      }
      if (!hour || !periodOfTime) {
        setErrorMessageHour('Hour is required')
      }
    } else {
      setErrorMessageScannerId('')
      setErrorMessageDate('')
      setErrorMessageHour('')
      setError('')
      try {
        const params = {
          language: i18n.language || window.localStorage.i18nextLng,
          reservation_date: currentDate,
          reservation_final_time: userSelectedTime.reservationFinalTime,
          reservation_start_time: userSelectedTime.reservationStartTime,
          reservation_scanner_id: scanner.value,
        }
        const response = await callApi(CREATE_BOOKING, POST, params)
        if (response) {
          if (response.data.message === 'Create reservation success') {
            const scannerSelect = addressList.find((address) => address.value === scanner.value)
            const { address, scanner_name }: any = scannerSelect
            const time =
              moment(new Date(currentDate).toISOString()).format(
                localStorage.getItem('i18nextLng') === 'ja' ? 'YYYY年MM月DD日' : 'MM DD, YYYY'
              ) +
              ' ' +
              moment(userSelectedTime.reservationStartTime, 'HH:mm:ss').format('HH:mm')
            onBookingSuccess({
              time,
              address: scanner_name + ', ' + address,
            })
            dispatch({ type: TabTypes.CHECK_TAB, payload: 2 })
          }
          history.push(ROUTER_URL.SCAN_BOOKING)
        }
      } catch (error: any) {
        const { message, name } = error || {}
        let msg = message.replace('\\n', '\n') || ''
        if (msg) {
          if (name === 'S000001') notify(msg, ERROR_TYPE)
          else setError(msg)
        }
      }
    }
  }

  const hourGenerator = () => {
    const date = moment(new Date(currentDate).toISOString()).format('YYYY/MM/DD')
    return date
      ? hourTest.map((i: any) => ({
          value: i,
          name: 'hourOption',
          label:
            i < 10
              ? `0${i}${t('booking.booking_modal.hour')}`
              : `${i}${t('booking.booking_modal.hour')}`,
        }))
      : []
  }

  const onChangeDate = (event?: React.SyntheticEvent, data?: any) => {
    const dataValue = data?.value || ''
    const date = moment(new Date(dataValue || currentDate).toISOString()).format('YYYY/MM/DD')
    setNewDate(date)
    setErrorMessageDate('')
  }

  const onChangeHour = (selected?: any) => {
    if (selected) {
      setHour(selected.value)
      setErrorMessageHour('')
    }
  }

  const convertTimeBookings = (timeBookings: string[]) => {
    return timeBookings
      .map((item: string) => item.split(':')[1])
      .map((item) => item.split('')[0])
      .map((item) => parseInt(item) + 1)
  }

  const renderSelectId = (
    <div className="booking__modal-booking__select-id">
      <div className="booking__modal-booking__select-id-label distance-text-to-input">
        <span className="pop-text-bold-normal">{i18n.t('booking.booking_modal.scannerId')}</span>
        <span className="input__asterisk">*</span>
      </div>
      <Select
        name="scannerId"
        isSearchable={false}
        styles={selectInputStyler(false)}
        onChange={setScanner}
        options={addressList}
        value={scanner}
        placeholder="スキャナ設置場所を選択してください。"
      />
      {errorMessageScannerId && (
        <span className="form-field-error-message">
          {i18n.t('booking.booking_modal.error_message_scanner')}
        </span>
      )}
    </div>
  )

  const renderSelectDate = (
    <div className="booking__modal-booking__select-date">
      <div className="booking__modal-booking__select-date-label distance-text-to-input">
        <span className="pop-text-bold-normal">
          {i18n.t('booking.booking_modal.reservation_date')}
        </span>
        <span className="input__asterisk">*</span>
      </div>
      <SemanticDatepicker
        datePickerOnly={true}
        onChange={onChangeDate}
        value={new Date(currentDate)}
        format={localStorage.getItem('i18nextLng') === 'ja' ? 'YYYY年MM月DD日' : 'MM DD, YYYY'}
        clearOnSameDateClick={false}
        placeholder="撮影日を入力してください。"
        clearable={false}
        locale={localStorage.getItem('i18nextLng') === 'ja' ? 'ja-JP' : 'en-US'}
        filterDate={(date) => {
          const yesterday = moment(new Date(establishDate).toISOString()).startOf('day').isBefore(moment().startOf('day'))
            ? new Date()
            : new Date(establishDate)
          yesterday.setDate(yesterday.getDate() - 1)

          const tommorow = moment(new Date(expirationDate).toISOString()).startOf('day').isBefore(moment().startOf('day'))
            ? new Date()
            : new Date(expirationDate)
          tommorow.setDate(tommorow.getDate() - 1)

        return (date > yesterday) && (date < tommorow)
        }}
        icon={
          <i
            className="icon"
            style={{
              top: 'calc(50% - 8px)',
              opacity: 1,
            }}
          >
            <CalendarLogo />
          </i>
        }
      />
      {errorMessageDate && !hour && (
        <span className="form-field-error-message">
          {i18n.t('booking.booking_modal.error_message_date')}
        </span>
      )}
    </div>
  )

  const renderSelectHour = (
    <div className="booking__modal-booking__select-hour">
      <div className="booking__modal-booking__select-hour-label pop-text-bold-normal distance-text-to-input">
        {i18n.t('booking.booking_modal.select_hour')}
      </div>
      <span className="input__asterisk">*</span>
      <Select
        name="hour"
        options={hourGenerator()}
        styles={selectInputStyler(!!errorMessageHour)}
        onChange={onChangeHour}
        placeholder={
          localStorage.getItem('i18nextLng') === 'ja'
            ? '時間帯を選択してください。'
            : 'Please select a time.'
        }
        isSearchable={false}
        noOptionsMessage={() =>
          localStorage.getItem('i18nextLng') === 'ja'
            ? '営業時間時間外です。'
            : 'It is out of business hours.'
        }
        value={hourGenerator().find((h) => h.value === hour) || null}
      />
    </div>
  )

  const renderSelectTimeArea = sixPeriodOfTime.map((eachPeriod) => (
    <button
      type="button"
      key={eachPeriod}
      className={classNames('booking__modal-booking__select-time-button', {
        'active-booking': periodOfTime === eachPeriod,
        'booking__modal-booking__not-display':
          !convertTimeBookings(timeBookings).includes(eachPeriod),
      })}
      onClick={() => setPeriodOfTime(eachPeriod)}
    >
      {/* render button with exact format */}
      <span>
        {hour! > 9
          ? eachPeriod !== 6
            ? `${hour}:${eachPeriod - 1}0 - ${hour}:${eachPeriod}0`
            : hour !== 23
            ? `${hour}:50 - ${hour! + 1}:00`
            : '23:50 - 00:00'
          : eachPeriod !== 6
          ? `0${hour}:${eachPeriod - 1}0 - 0${hour}:${eachPeriod}0`
          : hour !== 9
          ? `0${hour}:50 - 0${hour! + 1}:00`
          : '09:50 - 10:00'}
      </span>
      <span
        className={classNames('booking__modal-booking__select-time-check-icon', {
          hidden: periodOfTime !== eachPeriod,
        })}
      >
        <CheckCircle />
      </span>
    </button>
  ))

  const renderSelectTime = (
    <div>
      <div className="booking__modal-booking__select-time">
        {(hour || hour === 0) && (
          <>
            <div className="booking__modal-booking__select-time-label pop-text-bold-normal">
              {i18n.t('booking.booking_modal.select_time')}
            </div>
            <span className="input__asterisk">*</span>
            {!timeBookings?.length ? (
              <div style={{ textAlign: 'center', color: '#111947' }}>{noDataTimeBooking}</div>
            ) : (
              <div className="booking__modal-booking__select-time-area">{renderSelectTimeArea}</div>
            )}
          </>
        )}
      </div>{' '}
      {errorMessageDate && !periodOfTime && (
        <span className="form-field-error-message">
          {i18n.t('booking.booking_modal.error_message_date')}
        </span>
      )}
    </div>
  )

  const renderReservationArea = (
    <div className="booking__modal-booking__reservation">
      <Button type="submit">{i18n.t('booking.booking_modal.reservation')}</Button>
    </div>
  )

  const renderImpossibility = (
    <div className="detail-avatar__delete-modal_content2 distance-line-to-text padding-modal-content-to-btn">
      <div className="distance-text-to-text pop-large-body-text text-pre-line"> </div>
        <div className="detail-avatar__convert-modal_name distance-margin-modal-h2">
          <div className="distance-text-to-text pop-large-body-text text-pre-line">
          {i18n.t('booking.booking_modal.message_impossibility_reservation')}
          </div>
      </div>
    </div>
  )

  return (
    <div className="booking__modal-booking__container">
      <div className="booking__modal-booking__header ">
        <div className="booking__modal-booking__header-title distance-h2-to-line pop-text-bold-big">
        {Boolean(available) === true ? (
          <>
            {i18n.t('booking.booking_modal.header_title')}
          </>
          ) 
         : (   
          <>           
            {i18n.t('booking.booking_modal.title_impossibility_reservation')}
          </>
          )}
        </div>
        <button className="booking__modal-booking__close close-icon" onClick={() => toggleModal()}>
          <CloseIcon />
        </button>
      </div>
      <div className="booking__modal-booking__body">
        <FormProvider {...methods}>
        {Boolean(available) === true ? (
              <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="booking__modal-booking__content">            
              {renderSelectId}
              {renderSelectDate}
              {renderSelectHour}
              {renderSelectTime}
              {errorMessageHour && (
                <span className="form-field-error-message">
                  {i18n.t('booking.booking_modal.error_message_hour')}
              </span>
              )}
              {!!error && <span className="form-field-error-message">{error}</span>}
              {renderReservationArea}              
            </div>
          </form>
        </>                            
          ) : (              
          <>
          {renderImpossibility}
          </>
          )}
        </FormProvider>
      </div>
    </div>
  )
}

export default BookingModal
