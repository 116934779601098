interface TankTopProps {
  height?: string
  width?: string
}
const TankTop: React.FC<TankTopProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '92'}
      height={height || '86'}
      viewBox="0 0 92 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3828 64.8252V74.3882"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M25.332 64.3472V73.9102"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M28.2812 63.8682V73.4312"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M31.2266 62.9126V72.4756"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M34.1758 62.4351V71.9981"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M37.125 61.9565V71.5196"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M40.0742 61.479V71.042"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M43.0234 61V70.563"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M45.9727 61V70.563"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M48.9258 61V70.563"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M51.875 61.479V71.042"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M54.8242 61.9565V71.5196"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M57.7695 62.4351V71.9981"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M60.7188 62.9126V72.4756"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M63.668 63.8682V73.4312"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M66.6172 64.3472V73.9102"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M69.5703 64.8252V74.3882"
        stroke="#111947"
        strokeWidth="2.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M60.1179 2H55.6936L55.6493 2.05767L46.6343 12.3613C46.4672 12.556 46.2332 12.6851 45.9757 12.7247C45.7182 12.6851 45.4842 12.556 45.3171 12.3613L36.3507 2.05767C36.3376 2.03732 36.3228 2.01804 36.3064 2H31.8821C27.7579 2 18.61 6.30349 16 7.56522L21.6871 30.9947L21.8493 60.2382L34.9543 57.7043C42.2207 56.2061 49.7293 56.2061 56.9957 57.7043L70.155 60.2146L70.3121 30.9711L76 7.5416C73.4043 6.29377 64.2471 2 60.1179 2Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TankTop
