import { Trans, useTranslation } from 'react-i18next'
import cx from 'classnames'

interface TermsOfServiceConstantsProps {}

const TermsOfServiceConstants: React.FC<TermsOfServiceConstantsProps> = () => {
  const [t] = useTranslation('translation')

  function getTrans(text: string){
    return (

    <Trans 
    i18nKey={text}
    components={{
      br: <br />,
    }}
    />
    )
  }

  return (
    <div className="policy distance-line-to-content distance-content-to-button">
        <div className="policy__lead">
            <p>{getTrans(t('policy.term_of_service.sub_title_1', {
            add: `"Terms") is set out referring to AVATARIUM (the "Service" as defined in Article 2, Paragraph 1) provided by Pocket RD Co., Ltd.`,
            }))}</p>
        </div>
        
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_1.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_1._1`, { add: `"the Company"` }))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_1._2`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_1._3`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_1._4`))}</li>
                </ol>
            </div>
        </div>
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_2.title'))}</h2>
            <div className="policy__content">
                <p>{getTrans(t('policy.term_of_service.describe.article_2.sub_title'))}</p>
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_2._1`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_2._2`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_2._3`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_2._4`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_2._5`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_2._6`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_2._7`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_2._8`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_2._9`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_2._10`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_2._11`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_2._12`))}</li>
                </ol>
            </div>
        </div>

        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_3.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_3._1`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_3._2`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_3._3`))}</li>
                    <li>
                        {getTrans(t(`policy.term_of_service.describe.article_3._4`))}{' '}
                        <ol className={cx('policy__list--num-iroha', { alpha: localStorage.getItem('i18nextLng') === 'en', })}>
                            <li>{getTrans(t(`policy.term_of_service.describe.article_3._4_1`))}</li>
                            <li>{getTrans(t(`policy.term_of_service.describe.article_3._4_2`))}</li>
                            <li>{getTrans(t(`policy.term_of_service.describe.article_3._4_3`))}</li>
                            <li>{getTrans(t(`policy.term_of_service.describe.article_3._4_4`))}</li>
                        </ol>
                    </li>
                </ol>            
            </div>
        </div>

        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_4.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_4._1`))}</li>
                    <li>
                        {getTrans(t(`policy.term_of_service.describe.article_4._2`))}
                        <ol
                        className={cx('policy__list--num-iroha', {
                            alpha: localStorage.getItem('i18nextLng') === 'en',
                        })}
                        >
                        <li>{getTrans(t(`policy.term_of_service.describe.article_4._2_1`))}</li>
                        <li>{getTrans(t(`policy.term_of_service.describe.article_4._2_2`))}</li>
                        <li>{getTrans(t(`policy.term_of_service.describe.article_4._2_3`))}</li>
                        <li>{getTrans(t(`policy.term_of_service.describe.article_4._2_4`))}</li>
                        </ol>
                    </li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_4._3`))}</li>
                </ol>
            </div>
        </div>
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_5.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_5._1`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_5._2`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_5._3`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_5._4`))}</li>
                </ol>
            </div>
        </div>
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_6.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_6._1`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_6._2`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_6._3`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_6._4`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_6._5`))}</li>
                </ol>
            </div>
        </div>
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_7.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_7._1`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_7._2`))}</li>
                </ol>
            </div>
        </div>
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_8.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>
                    {getTrans(t(`policy.term_of_service.describe.article_8._1`, { add: `"Application"` }))}
                    </li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_8._2`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_8._3`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_8._4`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_8._5`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_8._6`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_8._7`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_8._8`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_8._9`))}</li>
                </ol>
            </div>
        </div>
                
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_9.title'))}</h2>
            <div className="policy__content">
                <p>{getTrans(t('policy.term_of_service.describe.article_9.des'))}</p>
            </div>
        </div>
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_10.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_10._1`))}</li>
                    <li>
                    {getTrans(t(`policy.term_of_service.describe.article_10._2`, {
                        add: `"Communication Charges Guide"`,
                    }))}
                    </li>
                </ol>
            </div>
        </div>
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_11.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_11._1`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_11._2`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_11._3`))}</li>
                </ol>
            </div>
        </div>
                
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_12.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_12._1`, { add: `"Materials, etc."` }))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_12._2`))}</li>
                </ol>
            </div>
        </div>

        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_13.title'))}</h2>
            <div className="policy__content">
                <p>{getTrans(t('policy.term_of_service.describe.article_13.des', { add: `"Privacy Policy"` }))}</p>
            </div>
        </div>

        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_14.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_14._1`))}</li>
                    <li>
                    {getTrans(t(`policy.term_of_service.describe.article_14._2`))}
                    <ol
                        className={cx('policy__list--num-iroha', {
                        alpha: localStorage.getItem('i18nextLng') === 'en',
                        })}
                    >
                        <li>{getTrans(t(`policy.term_of_service.describe.article_14._2_1`))}</li>
                        <li>{getTrans(t(`policy.term_of_service.describe.article_14._2_2`))}</li>
                        <li>{getTrans(t(`policy.term_of_service.describe.article_14._2_3`))}</li>
                        <li>{getTrans(t(`policy.term_of_service.describe.article_14._2_4`))}</li>
                    </ol>
                    </li>
                </ol>
            </div>
        </div>
                
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_15.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._1`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._2`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._3`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._4`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._5`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._6`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._7`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._8`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._9`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._10`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._11`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._12`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._13`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._14`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._15`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._16`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._17`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._18`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._19`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._20`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._21`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._22`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._23`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._24`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._25`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_15._26`))}</li>
                </ol>
            </div>
        </div>
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_16.title'))}</h2>
            <div className="policy__content">
                <p>{getTrans(t('policy.term_of_service.describe.article_16.des'))}</p>
            </div>
        </div>
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_17.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._1`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._2`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._3`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._4`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._5`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._6`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._7`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._8`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._9`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._10`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._11`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._12`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._13`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._14`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._15`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_17._16`))}</li>
                </ol>
            </div>
        </div>

        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_18.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_18._1`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_18._2`))}</li>
                </ol>
            </div>
        </div>
                
        <div className="policy__sec">
            <h2 className="policy__label">{getTrans(t('policy.term_of_service.describe.article_19.title'))}</h2>
            <div className="policy__content">
                <ol className="policy__list--num">
                    <li>{getTrans(t(`policy.term_of_service.describe.article_19._1`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_19._2`))}</li>
                    <li>{getTrans(t(`policy.term_of_service.describe.article_19._3`))}</li>
                </ol>
            </div>
        </div>   
    </div>
  )
}
export default TermsOfServiceConstants
