import React, { lazy } from 'react'
import {
  CancelBooking,
  JacketAndPants,
  Kimono,
  // LongDress,
  TankTop,
  TShirt,
} from '../../../../../assets/images'
import {
  IconHair1,
  IconHair11,
  IconHair2,
  IconHair3,
  IconHair4,
  IconHair5,
  IconHair6,
} from '../../../../../assets/images'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Wrapper from '../../../components/modules/_wrapper'
import { ROUTER_URL } from '../../../../../utils/const'

// Lazy loading していますが、SVG 画像を調整した方が良さそうでした。
const LongDress = lazy(() => import("../../../../../assets/images/svgs/booking/long-dress"));

interface BookingAttentionProps {}

const clothesList = [
  {
    id: 1,
    labels: ['booking_attention.clothes_list1'],
    image: <JacketAndPants width="100%" height="100%" />,
  },
  {
    id: 2,
    labels: ['booking_attention.clothes_list2'],
    image: <TankTop width="100%" height="100%" />,
  },
  {
    id: 3,
    labels: ['booking_attention.clothes_list3'],
    image: <TShirt width="100%" height="100%" />,
  },
  {
    id: 4,
    labels: ['booking_attention.clothes_list4', 'booking_attention.clothes_list5'],
    image: <LongDress width="100%" height="100%" />,
  },
  {
    id: 5,
    labels: ['booking_attention.clothes_list6'],
    image: <Kimono width="100%" height="100%" />,
  },
]

const removingItemsList = [
  ['booking_attention.list_remove', 'booking_attention.list_remove2'],
  ['booking_attention.list_remove3', 'booking_attention.list_remove4'],
  ['booking_attention.list_remove5', 'booking_attention.list_remove6'],
  ['booking_attention.list_remove7', 'booking_attention.list_remove8'],
]

const BookingAttention: React.FC<BookingAttentionProps> = () => {

  const title = '撮影時の注意事項 | AVATARIUM（アバタリウム）｜アバタープラットフォーム';
  const description = '撮影時の注意事項のページです。AVATARIUM(アバタリウム)は、かんたんにオリジナル3Dアバターが作成できるプラットフォームです。3Dアバターはメイクや着せ替えをして自由にアレンジ！オリジナルキャラクターを作ったり、カスタマイズしてメタバースや、VR/ARコンテンツ内でアバターとして利用もできます。';
  const keywords = 'アバター,アバター作成,オリジナルアバター,3Dアバター,3Dスキャン,AR,VR,XR,バーチャル,メタバース,3D,ゲーム,動画配信,ライブ配信,Avatar';
  const og_image = process.env.REACT_APP_WEB_URL + '/ogp.jpg';

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute('name');
    const propertyVal = headData[i].getAttribute('property');
    if (nameVal !== null) {
      if (nameVal.indexOf('keywords') !== -1) {
        headData[i].setAttribute('content', keywords);
      }
      if (nameVal.indexOf('description') !== -1) {
        headData[i].setAttribute('content', description);
      }
    }
    if (propertyVal !== null) {
      // OGP(og:title)の設定
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', og_image);
      }
    }
  }
  
  const [t] = useTranslation('translation')
  const localtion: any = useLocation()

  const list = [
    {
      name: t('booking_attention.home'),
      link: ROUTER_URL.HOME,
    },
    {
      name: t('booking_attention.booking'),
      link: ROUTER_URL.SCAN_BOOKING,
    },
    {
      name: t('booking_attention.attention'),
      link: ROUTER_URL.BOOKING_ATTENTION,
    },
  ]

  const renderClothesList = (
    <div className="booking-attention__clothes-list">
      <div className="booking-attention__clothes-wrapper">
        {clothesList.map((item) => (
          <div className="booking-attention__clothe">
            <div className="booking-attention__clothe-image">{item.image}</div>
            <p className="booking-attention__clothe-label">
              {item.labels.map((each, index) => (
                <>
                  {!!index && <br />}
                  <span>{t(each)}</span>
                </>
              ))}
            </p>
          </div>
        ))}
      </div>
      <span className="booking-attention__cancel-icon">
        <CancelBooking />
      </span>
    </div>
  )

  const renderOtherCautions = (
    <div className="booking-attention__other-cautions">
      <div className="booking-attention__other-cautions-title">
        <h3 className="title-h3">{t('booking_attention.title_cautions')}</h3>
      </div>
      {localStorage.getItem('i18nextLng') === 'ja' ? (
        <ul className="booking-attention__other-cautions-list">
          <li>
            {t('booking_attention.list_cautions1')}
            <span className="red-text">{t('booking_attention.list_cautions2')}</span>
            {t('booking_attention.list_cautions3')}
          </li>
          <li>{t('booking_attention.list_cautions4')}</li>
          <li>
            {t('booking_attention.list_cautions5')}
            <span className="red-text">{t('booking_attention.list_cautions6')}</span>
            {t('booking_attention.list_cautions7')}
          </li>
          <li>
            <span className="red-text">{t('booking_attention.list_cautions8')}</span>
            {t('booking_attention.list_cautions9')}
          </li>
          <li>
            {t('booking_attention.list_cautions10')}
            <span className="red-text">
              {t('booking_attention.list_cautions11')}
              <br />
              <span className="padding-attention-text">
                {t('booking_attention.list_cautions12')}
              </span>
            </span>
            {t('booking_attention.list_cautions13')}
          </li>
          <li>
            {t('booking_attention.list_cautions14')}
            <span className="red-text">{t('booking_attention.list_cautions15')}</span>
            {t('booking_attention.list_cautions16')}
          </li>
          <li>
            <span className="red-text">{t('booking_attention.list_cautions17')}</span>
            {t('booking_attention.list_cautions18')}
          </li>
          <li>{t('booking_attention.list_cautions19')}</li>
        </ul>
      ) : (
        <ul className="booking-attention__other-cautions-list">
          <li>
            When scanning, please wear<span className="red-text">well-fitting clothes </span>that
            show the lines of the body.
          </li>
          <li>
            In particular, if it is difficult to see the position and shape of the arms and legs
            when they are spread out, there is a high possibility of shape collapse.
          </li>
          <li>
            Avoid wearing<span className="red-text">{t('booking_attention.list_cautions6')}</span>
            <span>{t('booking_attention.list_cautions7')}</span>
          </li>
          <li>
            Do not wear<span className="red-text"> clothing that hides the neckline </span>
            (turtlenecks, hoodies, etc.)
          </li>
          <li>
            It is possible to photograph skirts and dresses, but please refrain from{' '}
            <span className="red-text">
              wearing items that are long (ankle-length),
              <br />
              have slits, or asymmetric lemmas.
            </span>
          </li>
          <li>
            Avoid wearing <span className="red-text">fur and other long-furred fabrics.</span>
          </li>
          <li>
            Please refrain from wearing{' '}
            <span className="red-text">heeled shoes (heels over 3cm).</span>
          </li>
          <li>
            Do not wear <span className="red-text">shiny clothes or shoes.</span> (Enamel fabric,
            shiny leather, satin fabric, etc.)
          </li>
        </ul>
      )}
    </div>
  )

  const renderClothesContent = (
    <div className="booking-attention__clothes-content">
      <p className="booking-attention__clothes-caution">{t('booking_attention.title_clothes')}</p>
      {renderClothesList}
      {renderOtherCautions}
      <div className="booking-attention__removing-items">
        <p className="booking-attention__removing-items-lead">
          {t('booking_attention.clothes_content1')}
        </p>
        <ul className="booking-attention__removing-items-list">
          {removingItemsList.map((item) => (
            <li>
              {item.map((each) => (
                <span>{t(each)}</span>
              ))}
            </li>
          ))}
        </ul>
        <ul className="booking-attention__removing-items-notes">
          <li>
            ※ {t('booking_attention.list_note')}
            <br />
            {t('booking_attention.list_note1')}
          </li>
          <li>
            ※ {t('booking_attention.list_note2')}
            <br />
            {t('booking_attention.list_note3')}
            {t('booking_attention.list_note4')}
          </li>
          <li>※ {t('booking_attention.list_note5')}</li>
        </ul>
      </div>
    </div>
  )
  return (
    <Wrapper
      breadcrum={list}
      title={t('booking_attention.title')}
      className="booking-attention__container"
      buttonText={t('booking_attention.link_to')}
      buttonLink={ROUTER_URL.SCAN_BOOKING}
      state={localtion?.state}
      isNoMarginTop={true}
    >
      <p className="booking-attention__description">{t('booking_attention.description')}</p>
      <div className="booking-attention__clothes">
        <h2 className="booking-attention__clothes-title">{t('booking_attention.clothes_title')}</h2>
        {renderClothesContent}
      </div>
      <div className="booking-attention__check">
        <div className="booking-attention__check-header">
          <h3 className="title-h3">{t('booking_attention.check_header')}</h3>
          <p>{t('booking_attention.check_content')}</p>
        </div>
        <div className="booking-attention__check-warning">
          <div className="booking-attention__title_warning">
            <h4 className="title-h4">{t('booking_attention.title_warning')}</h4>
          </div>
          {localStorage.getItem('i18nextLng') === 'ja' ? (
            <div className="booking-attention__check-warning--content">
              <div className="booking-attention__check-warning--content_box">
                <IconHair1 />
                <p>{t('booking_attention.warning_content')}</p>
              </div>
              <div className="booking-attention__check-warning--content_box">
                <IconHair2 />
                <p>{t('booking_attention.warning_content2')}</p>
              </div>
              <div className="booking-attention__check-warning--content_box">
                <IconHair3 />
                <div className="booking-attention__check-warning--content_box-content">
                  <p>{t('booking_attention.warning_content3')}</p>
                  <p>{t('booking_attention.warning_content4')}</p>
                </div>
              </div>
              <div className="booking-attention__check-warning--content_box">
                <IconHair4 />
                <p>{t('booking_attention.warning_content5')}</p>
              </div>
              <div className="booking-attention__check-warning--content_box">
                <IconHair5 />
                <p style={{ textAlign: 'center' }}>{t('booking_attention.warning_content6')}</p>
              </div>
              <div className="booking-attention__check-warning--content_box">
                <IconHair6 />
                <div className="booking-attention__check-warning--content_box-content">
                  <p>{t('booking_attention.warning_content7')}</p>
                  <p>{t('booking_attention.warning_content8')}</p>
                </div>
              </div>
              <div className="booking-attention__check-warning--content_box">
                <IconHair11 />
                <div className="booking-attention__check-warning--content_box-content">
                  <p>{t('booking_attention.warning_content9')}</p>
                  <p>{t('booking_attention.warning_content10')}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="booking-attention__check-warning--content">
              <div className="booking-attention__check-warning--content_box">
                <IconHair1 />
                <p>long hair</p>
              </div>
              <div className="booking-attention__check-warning--content_box">
                <IconHair2 />
                <p>pulled to the side</p>
              </div>
              <div className="booking-attention__check-warning--content_box">
                <IconHair3 />
                <div className="booking-attention__check-warning--content_box-content">
                  <p>high bun</p>
                </div>
              </div>
              <div className="booking-attention__check-warning--content_box">
                <IconHair4 />
                <p>outside splash</p>
              </div>
              <div className="booking-attention__check-warning--content_box">
                <IconHair5 />
                <p style={{ textAlign: 'center' }}>spreading out</p>
                <p style={{ textAlign: 'center' }}>to the sides</p>
              </div>
              <div className="booking-attention__check-warning--content_box">
                <IconHair6 />
                <div className="booking-attention__check-warning--content_box-content">
                  <p>permed. unruly hair</p>
                </div>
              </div>
              <div className="booking-attention__check-warning--content_box">
                <IconHair11 />
                <div className="booking-attention__check-warning--content_box-content">
                  <p>Beard that hides the</p>
                  <p>mouth and face line</p>
                </div>
              </div>
            </div>
          )}
          <div className="booking-attention__check-warning--footer">
            <p>※ {t('booking_attention.warning_content11')}</p>
          </div>
        </div>
        <div className="booking-attention__check-notification">
          <div className="booking-attention__check-notification--title">
            <p>{t('booking_attention.notification_title')}</p>
          </div>
          <div className="booking-attention__check-notification--box">
            <div className="booking-attention__check-notification--content">
              <div className="booking-attention__check-notification--content-header">
                {t('booking_attention.notification1')}
              </div>
              <ul className="booking-attention__check-notification--content-content">
                <li>{t('booking_attention.notification2')}</li>
                <li>{t('booking_attention.notification3')} </li>
                <li>{t('booking_attention.notification4')}</li>
                <li>{t('booking_attention.notification5')} </li>
                <li>{t('booking_attention.notification6')}</li>
              </ul>
            </div>
            <div className="booking-attention__check-notification--content">
              <div className="booking-attention__check-notification--content-header">
                {t('booking_attention.check_notification_title')}
              </div>
              {localStorage.getItem('i18nextLng') === 'ja' ? (
                <ul className="booking-attention__check-notification--content-content">
                  <li>{t('booking_attention.check_notification1')}</li>
                  <li>{t('booking_attention.check_notification2')} </li>
                  <li>{t('booking_attention.check_notification3')} </li>
                  <li>{t('booking_attention.check_notification4')} </li>
                  <li>{t('booking_attention.check_notification5')}</li>
                  <li>{t('booking_attention.check_notification6')}</li>
                </ul>
              ) : (
                <ul className="booking-attention__check-notification--content-content">
                  <li>{t('booking_attention.check_notification1')}</li>
                  <li>{t('booking_attention.check_notification2')} </li>
                  <li>{t('booking_attention.check_notification3')} </li>
                  <li>{t('booking_attention.check_notification4')} </li>
                  <li>{t('booking_attention.check_notification5')}</li>
                  <li>{t('booking_attention.check_notification6')}</li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default BookingAttention
