import * as React from 'react'

interface MenuProps {
  height?: string
  width?: string
}
const MenuSignUp: React.FC<MenuProps> = ({ height, width }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.66667 22C8.49288 22.0005 8.3234 21.9459 8.1825 21.8442L2.34917 17.6775C2.24128 17.6005 2.15332 17.4988 2.09259 17.381C2.03186 17.2632 2.00012 17.1326 2 17V2.83333C2 2.61232 2.0878 2.40036 2.24408 2.24408C2.40036 2.0878 2.61232 2 2.83333 2C3.15667 2 3.82083 2.52 3.93 2.59833L9.15167 6.33333C9.25975 6.41069 9.34776 6.51277 9.40836 6.63106C9.46895 6.74935 9.50037 6.88042 9.5 7.01333V21.1667C9.50016 21.3199 9.45809 21.4701 9.37841 21.601C9.29873 21.7318 9.18452 21.8382 9.04833 21.9083C8.93002 21.9683 8.7993 21.9997 8.66667 22Z"
        fill="#9496AB"
      />
      <path
        d="M11.168 12.833V17.833H13.668C13.889 17.833 14.1009 17.7452 14.2572 17.5889C14.4135 17.4327 14.5013 17.2207 14.5013 16.9997V12.833H11.168Z"
        fill="#9496AB"
      />
      <path
        d="M11.1684 7.01333V7.83333H14.5018V2.83333C14.5018 2.61232 14.414 2.40036 14.2577 2.24408C14.1014 2.0878 13.8895 2 13.6684 2H5.96094L10.1276 4.98C10.4495 5.21248 10.7117 5.51799 10.8926 5.87145C11.0736 6.22492 11.1681 6.61625 11.1684 7.01333Z"
        fill="#9496AB"
      />
      <path
        d="M22.0013 10.3337L17.0013 6.16699V9.50033H11.168V11.167H17.0013V14.5003L22.0013 10.3337Z"
        fill="#9496AB"
      />
    </svg>
  )
}

export default MenuSignUp
