import React from 'react'

interface TrashBinEnableProps {
  height?: string
  width?: string
  color?: string
}

const TrashBinEnable: React.FC<TrashBinEnableProps> = ({ height, width, color = '#ff6c6c' }) => {
  return (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 6H16V4C16 3.73478 15.8946 3.48043 15.7071 3.29289C15.5196 3.10536 15.2652 3 15 3H9C8.73478 3 8.48043 3.10536 8.29289 3.29289C8.10536 3.48043 8 3.73478 8 4V6H5C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7C4 7.26522 4.10536 7.51957 4.29289 7.70711C4.48043 7.89464 4.73478 8 5 8H19C19.2652 8 19.5196 7.89464 19.7071 7.70711C19.8946 7.51957 20 7.26522 20 7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6V6ZM10 6V5H14V6H10Z" fill="#FF6C6C"/>
    <path d="M6 10L7 19.9C7.02509 20.2025 7.14236 20.4828 7.3289 20.6862C7.51544 20.8896 7.75786 21.0015 8.00883 21H15.9962C16.2463 21 16.4875 20.8874 16.673 20.6842C16.8584 20.4809 16.975 20.2015 17 19.9L18 10H6Z" fill="#FF6C6C"/>
  </svg>
  )
}

export default TrashBinEnable
