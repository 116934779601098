import * as React from 'react'

interface ShieldIconProps {
  height?: string
  width?: string
}
const ShieldIcon: React.FC<ShieldIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.1203 6.30465C24.294 6.30465 19.7998 4.9581 16.6728 2.87221C16.2656 2.59951 15.7359 2.59951 15.3287 2.87221C12.2005 4.9581 7.70633 6.30465 3.87999 6.30465C3.21095 6.30465 2.66797 6.84642 2.66797 7.51667C2.66797 17.6661 7.47362 25.7854 15.5226 29.2348C15.6753 29.3003 15.8378 29.333 16.0002 29.333C16.1626 29.333 16.325 29.3003 16.4777 29.2348C24.5267 25.7854 29.3324 17.6661 29.3324 7.51667C29.3324 6.84642 28.7894 6.30465 28.1203 6.30465ZM16.0002 26.7938V16.0008H6.47492C5.68711 13.7707 5.22169 11.3139 5.11625 8.68869C8.81775 8.45234 12.8998 7.19185 16.0002 5.31685V16.0008H25.5254C23.7922 20.9034 20.4955 24.7104 16.0002 26.7938Z"
        fill="#9C9EB8"
      />
    </svg>
  )
}

export default ShieldIcon
