import * as React from 'react'

interface AvatarIconProps {
  height?: string
  width?: string
}
const IconHair9: React.FC<AvatarIconProps> = ({ height, width }) => {
  return (
    <svg
      width={width || '72'}
      height={height || '72'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.0151 9H35.9882C20.2149 9 6.05776 23.606 12.6631 42.8606C16.2155 53.2528 26.8027 61.9855 35.9882 62.0012H36.0182C45.2037 62.0012 55.7867 53.2528 59.3433 42.8606C65.9332 23.606 51.7792 9 36.0151 9Z"
        fill="#111947"
      />
      <path
        d="M54.3926 33.3667C52.9085 21.3953 45.8573 14.7173 35.9916 14.7173C26.1259 14.7173 19.0757 21.3953 17.6019 33.3667C13.3507 33.5517 12.2227 36.6065 12.2227 40.5673C12.2227 44.5282 16.5884 49.0899 18.0333 49.276C18.6267 49.3711 19.1562 49.4265 19.6846 49.4714C23.1483 57.3931 29.6412 62.9999 35.0245 62.9999H37.034C42.4081 62.9999 48.9102 57.4182 52.3739 49.4965C52.9029 49.4658 53.4294 49.3998 53.9499 49.299C55.37 49.0837 59.7296 44.709 59.7296 40.5652C59.7296 36.4215 58.6283 33.5527 54.3926 33.3667Z"
        fill="white"
        stroke="#111947"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M29.9681 43.8635C31.5858 43.8635 32.8972 42.5356 32.8972 40.8976C32.8972 39.2595 31.5858 37.9316 29.9681 37.9316C28.3504 37.9316 27.0391 39.2595 27.0391 40.8976C27.0391 42.5356 28.3504 43.8635 29.9681 43.8635Z"
        fill="#111947"
      />
      <path
        d="M42.8002 43.8635C44.4178 43.8635 45.7292 42.5356 45.7292 40.8976C45.7292 39.2595 44.4178 37.9316 42.8002 37.9316C41.1825 37.9316 39.8711 39.2595 39.8711 40.8976C39.8711 42.5356 41.1825 43.8635 42.8002 43.8635Z"
        fill="#111947"
      />
      <path
        d="M25.037 62.7509C21.3504 62.7509 16.867 54.6641 16.3324 53.11C13.649 45.3085 16.7679 34.3906 17.6483 32.2858C18.2641 30.8215 19.034 29.4288 19.9447 28.1316V28.1316C18.9208 36.3386 12.523 51.091 25.037 62.7509Z"
        fill="#111947"
      />
      <path
        d="M47.0547 62.7509C50.7413 62.7509 55.2247 54.6641 55.7593 53.11C58.4427 45.3085 55.3238 34.3895 54.4475 32.2858C53.8297 30.8225 53.0599 29.4299 52.1511 28.1316V28.1316C53.1605 36.3386 59.5739 51.0909 47.0547 62.7509Z"
        fill="#111947"
      />
      <path
        d="M35.5056 11.7061H35.1691C24.7812 11.8565 16.572 19.2223 12.9648 34.6602L13.1527 34.5902C18.2579 32.6606 22.6638 29.2059 25.7864 24.6839V24.6839L24.0794 31.6284H30.7105C31.5794 31.6284 32.4359 31.42 33.2099 31.0204C33.984 30.6208 34.6536 30.0412 35.164 29.3292L36.0247 28.1263L35.4808 31.6336H40.4544C41.1366 31.6335 41.8006 31.4114 42.3486 31C42.8966 30.5887 43.2996 30.0099 43.4981 29.3491L44.8934 24.6891C48.0145 29.2092 52.4176 32.663 57.52 34.5933L57.7078 34.6633C54.1058 19.2118 45.9162 11.8565 35.5056 11.7061Z"
        fill="#111947"
      />
    </svg>
  )
}

export default IconHair9
