import React from 'react'

interface VideoProps {
  src: string
  autoPlay?: boolean
  muted?: boolean
  controls?: boolean
  setIsVideoEnd: (arg: boolean) => void
  className: string
}

const Video: React.FC<VideoProps> = ({
  src,
  setIsVideoEnd,
  autoPlay = false,
  muted = false,
  controls = false,
  className,
}) => {
  return (
    <>
      <video
        width="1440"
        height="788"
        autoPlay={autoPlay}
        muted={muted}
        controls={controls}
        className={className}
        onEnded={() => setIsVideoEnd(true)}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  )
}

export default Video
