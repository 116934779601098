import * as React from 'react'

interface MenuProps {
  height?: string
  width?: string
}
const MenuSecurity: React.FC<MenuProps> = ({ height, width }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0893 4.7287C18.2195 4.7287 14.8489 3.71879 12.5037 2.15437C12.1982 1.94984 11.801 1.94984 11.4956 2.15437C9.14939 3.71879 5.77877 4.7287 2.90901 4.7287C2.40724 4.7287 2 5.13503 2 5.63771C2 13.2498 5.60424 19.3393 11.641 21.9263C11.7555 21.9754 11.8773 22 11.9991 22C12.121 22 12.2428 21.9754 12.3573 21.9263C18.3941 19.3393 21.9983 13.2498 21.9983 5.63771C21.9983 5.13503 21.5911 4.7287 21.0893 4.7287ZM11.9991 20.0956V12.0008H4.85521C4.26435 10.3282 3.91529 8.48565 3.83621 6.51673C6.61233 6.33947 9.67389 5.3941 11.9991 3.98785V12.0008H19.1431C17.8432 15.6778 15.3707 18.533 11.9991 20.0956Z"
        fill=""
      />
    </svg>
  )
}

export default MenuSecurity
