import React from 'react'
import { MdClose } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button } from '../../components/atoms/buttons'
import { ROUTER_URL } from '../../../../utils/const'

interface SuccessPWModalProps {
  toggleChangeSuccess?: () => void
}

const SuccessPWModal: React.FC<SuccessPWModalProps> = ({ toggleChangeSuccess }) => {
  const [, i18n] = useTranslation('translation')
  const history = useHistory()

  const renderBtnPassword = (
    <div className="password__modal-success__send">
      <Button onClick={() => history.push(ROUTER_URL.SIGNIN)}>{i18n.t('reset_password.login')}</Button>
    </div>
  )
  return (
    <div className="password__modal-success">
      <div className="password__modal-success__header">
        <span className="password__modal-success__header-modal">
          <MdClose onClick={toggleChangeSuccess} color="#111947" size={36} />
        </span>
      </div>
      <div className="password__modal-success__title">
        {i18n.t('reset_password.password-changed')}
      </div>
      <div className="password__modal-success__content">
        {i18n.t('reset_password.changed-successfully')}
      </div>
      {renderBtnPassword}
    </div>
  )
}

export default SuccessPWModal
